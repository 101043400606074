import { PolicyType } from "../graphqlTypes";

const sortedPolicyTypes = [
  PolicyType.LifeCover,
  PolicyType.SpecifiedIllnessCover,
  PolicyType.IncomeProtection,
  PolicyType.HealthInsurance,
];

export { sortedPolicyTypes };
