import classNames from "classnames";
import { FC } from "react";
import { Link } from "react-router-dom";
import { useWindowDimensions } from "@multiply/lib";

interface SingleRecommendationProps {
  icon: any;
  title: string;
  actions: number;
  className?: string;
  hasPlan?: boolean;
  to?: string;
}

const SingleRecommendation: FC<SingleRecommendationProps> = ({
  icon,
  title,
  actions,
  className,
  hasPlan,
  to,
}) => {
  const { isPhone } = useWindowDimensions();

  const label = `${actions} thing${actions !== 1 ? "s" : ""} to do`;
  if (isPhone) {
    return (
      <div
        className={classNames("flex items-center justify-between", className)}
        aria-label="single recommendation"
      >
        <p className="text-t16 text-font-primary">{title}</p>

        {hasPlan ? (
          <Link
            to={to ?? ""}
            className="text-t16 text-font-links"
            aria-label={`${label}: ${title}`}
          >
            {label}
          </Link>
        ) : (
          <p className="text-t16 text-font-secondary">0 actions</p>
        )}
      </div>
    );
  }

  return (
    <div
      className={classNames(
        "inline-flex",
        { "items-center": !hasPlan },
        className
      )}
      aria-label="single recommendation"
    >
      {icon}
      <div className="ml-16">
        <p
          className={classNames(
            "text-t21 text-font-primary whitespace-nowrap",
            { "mb-8": hasPlan }
          )}
        >
          {title}
        </p>

        {hasPlan ? (
          <Link
            to={to ?? ""}
            className="text-t16 text-font-links"
            aria-label={`${label}: ${title}`}
          >
            {label}
          </Link>
        ) : (
          <p className="text-t21 text-font-secondary">0 actions</p>
        )}
      </div>
    </div>
  );
};

export { SingleRecommendation };
