const keys = [
  "advisor",
  "teamName",
  "referrer",
  "userType",
  "userJourney",
  "sourceBranchName",
] as const;

type ReferralDetails = {
  [key in (typeof keys)[number]]: string | null;
};

const useReferral = () => {
  const saveReferralDetails = (referral: ReferralDetails) => {
    if (typeof window === "undefined") return;

    try {
      Object.entries(referral).forEach(([key, value]) => {
        if (value) {
          window.localStorage.setItem(key, value);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getReferralDetails = () => {
    let referralDetails: ReferralDetails = {
      advisor: null,
      teamName: null,
      referrer: null,
      userType: null,
      userJourney: null,
      sourceBranchName: null,
    };

    if (typeof window !== "undefined") {
      try {
        keys.forEach((key) => {
          referralDetails[key] = window.localStorage.getItem(key);
        });
      } catch (error) {
        console.log(error);
      }
    }

    return referralDetails;
  };

  const clearReferralDetails = () => {
    if (typeof window === "undefined") return;
    keys.forEach((key) => {
      window.localStorage.removeItem(key);
    });
  };

  return {
    saveReferralDetails,
    getReferralDetails,
    clearReferralDetails,
  };
};

export { useReferral };
