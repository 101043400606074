import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";
import {
  IconProps,
  getDimensionsFromSize,
  getColorFromVariant,
} from "./shared";

const GridIcon: FC<IconProps> = ({ size, variant, className, ...props }) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);

  return (
    <svg
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 2H3.333A1.333 1.333 0 0 0 2 3.333V6a1.333 1.333 0 0 0 1.333 1.333H6A1.333 1.333 0 0 0 7.333 6V3.333A1.333 1.333 0 0 0 6 2Zm6.667 0H10a1.333 1.333 0 0 0-1.333 1.333V6A1.333 1.333 0 0 0 10 7.333h2.667A1.333 1.333 0 0 0 14 6V3.333A1.334 1.334 0 0 0 12.667 2ZM6 8.667H3.333A1.333 1.333 0 0 0 2 10v2.667A1.333 1.333 0 0 0 3.333 14H6a1.334 1.334 0 0 0 1.333-1.333V10A1.333 1.333 0 0 0 6 8.667Zm6.667 0H10A1.333 1.333 0 0 0 8.667 10v2.667A1.333 1.333 0 0 0 10 14h2.667A1.334 1.334 0 0 0 14 12.667V10a1.334 1.334 0 0 0-1.333-1.333Z"
        fill={fill}
      />
    </svg>
  );
};

export { GridIcon };
