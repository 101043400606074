import classNames from "classnames";

const StepperTitle = ({
    title,
    className,
  }: {
    title: string;
    className?: string;
  }) => {
    return (
      <p className={classNames("text-font-secondary font-bold", className)}>
        {title}
      </p>
    );
  };

  export {StepperTitle}