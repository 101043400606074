import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { hasOwnProperty } from "../../utils";
import {} from "../../hooks";

const ScrollToTop = ({ children }: { children?: React.ReactNode }) => {
  const location = useLocation();

  const pathname = hasOwnProperty(location.state, "backgroundLocation")
    ? (location.state.backgroundLocation as Location)?.pathname
    : location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{children}</>;
};

export { ScrollToTop };
