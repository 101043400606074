import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export type IntroPhoneFormValues = {
  countryCode: string | null;
  mobileNumber: string | null;
};

const mobileNumberRegex = /^[0-9]{8,13}$/g;
const countryCodeRegex = /^[+]?[0-9]{1,3}$/g;

export const defaultIntroPhoneValidationSchema: yup.SchemaOf<IntroPhoneFormValues> =
  yup
    .object()
    .shape({
      countryCode: yup
        .string()
        .required("Please enter your country code")
        .matches(countryCodeRegex, "Please enter a valid country code")
        .nullable(),
      mobileNumber: yup
        .string()
        .required("Please enter your mobile number")
        .matches(mobileNumberRegex, "Please enter a valid mobile number")
        .nullable(),
    })
    .defined();

type UseIntroPhoneFormArgs = {
  defaultValues?: Partial<IntroPhoneFormValues>;
  validationSchema?: yup.SchemaOf<IntroPhoneFormValues>;
};

const useIntroPhoneForm = (args?: UseIntroPhoneFormArgs) => {
  const defaultValues = {
    countryCode: "",
    mobileNumber: "",
    ...(args?.defaultValues ?? {}),
  };
  const validationSchema =
    args?.validationSchema ?? defaultIntroPhoneValidationSchema;

  const form = useForm<IntroPhoneFormValues>({
    defaultValues: {
      countryCode: defaultValues?.countryCode,
      mobileNumber: defaultValues?.mobileNumber,
    },
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  return form;
};

export { useIntroPhoneForm };
