import { CheckIn, CheckInType } from "../graphqlTypes";

const getNextCheckInLink = (checkIns: Array<CheckIn>) => {
  const staleCheckIns = checkIns?.filter((checkIn) => checkIn.stale) ?? [];
  const checkInType =
    staleCheckIns.length > 0 ? staleCheckIns[0].checkInType : null;

  switch (checkInType) {
    case CheckInType.Products:
      return "/products";
    case CheckInType.Factfind:
      return "/settings/personal-details";
    default:
      return "/advice";
  }
};

export { getNextCheckInLink };
