import { TextInput } from "../../components";

type InputCountryCodeProps = {
  error?: string;
  value: string;
  onChange: (e: any) => void;
  onBlur: () => void;
  autoFocus?: boolean;
};

const InputCountryCode = ({
  error,
  value,
  onChange,
  onBlur,
}: InputCountryCodeProps) => {
  return (
    <TextInput
      className="w-80 flex-none"
      placeholder="+353"
      error={error}
      value={value ?? ""}
      onChange={(e) => onChange(e.target.value)}
      onBlur={onBlur}
      aria-label="Input country code"
      inputMode="numeric"
    />
  );
};

export { InputCountryCode };
