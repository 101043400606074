import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";
import { SVGProps } from "react";

const InfoCircle = ({ className, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    aria-hidden={true}
    className={classNames(defaultClassnames, className)}
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      stroke="#5261AC"
      strokeWidth={1.414}
      d="M9 16.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Z"
    />
    <path
      stroke="#5261AC"
      strokeLinecap="square"
      strokeWidth={1.414}
      d="M9 12.358v-4.5"
    />
    <path
      stroke="#5261AC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.414}
      d="M9 5.608h.008"
    />
  </svg>
);
export { InfoCircle };
