import classNames from "classnames";
import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import { IconProps } from "./shared";

const CalendarOutlineIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  return (
    <svg
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      viewBox="0 0 17 17"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="calendar outline icon"
      {...props}
    >
      <path d="M13.3 2.766H3.966c-.736 0-1.333.597-1.333 1.333v9.333c0 .737.597 1.334 1.333 1.334H13.3c.737 0 1.334-.597 1.334-1.334V4.1c0-.736-.597-1.333-1.333-1.333z" />
      <path d="M11.3 1.432V4.1M5.966 1.432V4.1" strokeLinecap="round" />
      <path d="M2.633 6.766h12" />
    </svg>
  );
};

export { CalendarOutlineIcon };
