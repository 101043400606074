import { Spinner } from "@multiply/lib";
import classNames from "classnames";
import { FC } from "react";

type ProductSectionProps = {
  title: string;
  subtitle?: string;
  balance?: string;
  loading?: boolean;
  children?: React.ReactNode;
  variant?: "continous" | "segmented";
};

const ProductSection: FC<ProductSectionProps> = ({
  title,
  subtitle,
  balance,
  children,
  loading,
  variant = "continous",
}) => {
  return (
    <div className="inline-block max-w-full w-full" aria-label="single product">
      <div className="flex justify-between mb-24 sm:mb-60 w-full">
        <div>
          <h2 className="text-t20 sm:text-t27  text-font-primary">{title}</h2>
          {subtitle && (
            <p className="text-t12 sm:text-t16 text-font-secondary">
              {subtitle}
            </p>
          )}
        </div>
        {balance && (
          <p className="text-t20 sm:text-t27 text-font-primary">{balance}</p>
        )}
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <div
          className={classNames("flex flex-col", {
            "shadow-primary overflow-hidden rounded-lg ":
              variant === "continous",
          })}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export { ProductSection };
