import { Provider } from "urql";
import { useEffect, useRef } from "react";
import { useAuthIfAvailable } from "../../auth";
import { createUrqlClient } from "./createUrqlClient";
import { useAnalyticsIfAvailable } from "../../hooks";
import { Queries } from "./queries";

const defaultGetToken = () => null;
const defaultRefreshToken = () => Promise.resolve();
const defaultLogout = () => Promise.resolve();

const MultiplyUrqlProvider = ({
  endpoint,
  schema,
  queries,
  children,
  onDowntime,
}: {
  endpoint: string;
  schema?: any;
  children?: React.ReactNode;
  onDowntime?: (isDown: boolean) => void;
  queries?: Queries;
}) => {
  const auth = useAuthIfAvailable();

  const loggedIn = auth?.loggedIn ?? false;
  const getToken = auth?.getToken ?? defaultGetToken;
  const refreshToken = auth?.refreshToken ?? defaultRefreshToken;
  const logOut = auth?.logOut ?? defaultLogout;

  const analytics = useAnalyticsIfAvailable();

  const client = useRef(
    createUrqlClient({
      endpoint,
      getToken,
      refreshToken,
      logOut,
      schema,
      analytics,
      onDowntime,
      queries,
    })
  );

  // reset the client when the user logs out, clears the cache and all auth state
  useEffect(() => {
    if (!loggedIn) {
      client.current = createUrqlClient({
        endpoint,
        getToken,
        refreshToken,
        logOut,
        schema,
        analytics,
        onDowntime,
        queries,
      });
    }
  }, [
    analytics,
    endpoint,
    getToken,
    loggedIn,
    refreshToken,
    schema,
    onDowntime,
    logOut,
    queries,
  ]);

  return <Provider value={client.current}>{children}</Provider>;
};

export { MultiplyUrqlProvider };
