import { ContainerMidSize } from "@multiply/lib";
import { Card, Spinner, useAuth, useToaster } from "@multiply/lib";
import { useNavigate, useParams } from "react-router-dom";
import { ModalNavLink } from "../../components";
import { DebtFieldName } from "../../globalTypes";
import { useGetDebtQuery, useUpdateDebtMutation } from "../../graphqlTypes";
import { DebtForm } from "./DebtForm";
import { parseProductDatesToISO } from "../../utils";

const UpdateDebt = () => {
  const { debtId } = useParams();
  const { userId } = useAuth();
  const { addToast } = useToaster();
  const navigate = useNavigate();

  const [getDebtResult] = useGetDebtQuery({
    variables: {
      userId: userId ?? "",
      debtId,
    },
  });

  const debt = getDebtResult?.data?.debt;

  const [updateDebtResult, updateDebt] = useUpdateDebtMutation();

  const handleUpdateDebt = async (values: any) => {
    const { provider, ...otherValues } = values;
    const newDebt = {
      productType: debt?.productType,
      ...otherValues,
      providerName: values.provider,
      ...parseProductDatesToISO(otherValues),
    };

    try {
      const result = await updateDebt({
        input: {
          userId: userId ?? "",
          debtId,
          debt: newDebt,
        },
      });

      if (result.data?.updateDebt?.success) {
        navigate("/products");
      }
    } catch (error) {}
    addToast({ message: "Success" });
  };

  const updateMutationError =
    updateDebtResult?.error || updateDebtResult?.data?.updateDebt?.error;

  const defaultValues: { [fieldName in DebtFieldName]: any } = {
    owners: (debt?.owners ?? []).map((person) => person?.id ?? ""),
    balance: debt?.balance?.float,
    provider: debt?.provider?.name ?? null,
    maturityDate: debt?.maturityDate ?? null,
    clearedOnDeath: debt?.clearedOnDeath ?? null,
    repaidMonthly: debt?.repaidMonthly ?? null,
    arePaymentsUpToDate: debt?.arePaymentsUpToDate ?? null,
    name: debt?.name ?? null,
    mortgageType: debt?.mortgageType ?? null,
    monthlyRepayment: debt?.monthlyRepayment?.float ?? null,
    mortgageYearsLeft: debt?.mortgageYearsLeft ?? null,
    isMainMortgage: debt?.isMainMortgage ?? null,
    hasPaymentProtectionInsurance: debt?.hasPaymentProtectionInsurance ?? null,
    endDate: debt?.endDate ?? null,
    arrears: debt?.arrears?.float,
  };

  return (
    <ContainerMidSize>
      <div className="flex-1 flex flex-col items-center justify-center">
        <Card className="p-32 pb-18 w-full flex flex-col items-center">
          {getDebtResult.fetching ? (
            <Spinner />
          ) : !debt ? (
            <p className="text-t21">Debt not found</p>
          ) : (
            <>
              <DebtForm
                debt={debt}
                onSubmit={handleUpdateDebt}
                defaultValues={defaultValues}
              />

              {debtId && (
                <ModalNavLink
                  to={`/cross/delete-debt/${debtId}`}
                  className="w-240 text-action-error text-center py-18 mt-8"
                >
                  Delete
                </ModalNavLink>
              )}
            </>
          )}
          {updateMutationError && (
            <p className="mt-12 text-action-error text-t16">
              {updateMutationError.message}
            </p>
          )}
        </Card>
      </div>
    </ContainerMidSize>
  );
};

export { UpdateDebt };
