import {
  GraduateCapOutlineIcon,
  HomeIcon,
  PiggyBankOutlineIcon,
  RingOutlineIcon,
  Size,
} from "@multiply/lib";
import classNames from "classnames";
import { GoalType } from "../../graphqlTypes";

type GoalIconProps = {
  goalType: GoalType;
  size: Size;
  className?: string;
};

const GoalIcon = ({ goalType, size, className }: GoalIconProps) => {
  switch (goalType) {
    case GoalType.BuyHome:
      return (
        <HomeIcon
          size={size}
          className={classNames("text-icon-display", className)}
        />
      );
    case GoalType.Wedding:
      return (
        <RingOutlineIcon
          size={size}
          className={classNames("text-icon-display", className)}
        />
      );
    case GoalType.ChildEducation:
      return (
        <GraduateCapOutlineIcon
          size={size}
          className={classNames("text-icon-display", className)}
        />
      );
    default:
      return (
        <PiggyBankOutlineIcon
          size={size}
          className={classNames("text-icon-display", className)}
        />
      );
  }
};

export { GoalIcon };
