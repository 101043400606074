import { FC } from "react";
import {
  getColorFromVariant,
  getDimensionsFromSize,
  IconProps,
} from "./shared";
import classNames from "classnames";
import { defaultClassnames } from "./defaultClassNames";

const AssetIcon: FC<IconProps> = ({ size, variant, className, ...props }) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);

  return (
    <svg
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 36 36"
      data-testid="asset icon"
      {...props}
    >
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M7.783 32.722h26.25M22.84 9.373l10.8 6.912a.832.832 0 0 1 .393.7v1.247a.823.823 0 0 1-.256.594.894.894 0 0 1-.619.246H7.783"
      />
      <rect
        width={9.844}
        height={14.7}
        x={1.527}
        y={15.069}
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        rx={1.515}
        transform="rotate(-90 1.527 15.069)"
      />
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m6.607 5.422.438-2.813c.067-.427.307-.811.67-1.068a1.75 1.75 0 0 1 1.26-.301l9.87 1.418a1.71 1.71 0 0 1 1.114.643c.268.348.38.783.313 1.21l-.771 4.944a1.685 1.685 0 0 1-1.2 1.322 1.78 1.78 0 0 1-1.767-.447"
      />
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3.8}
        d="M8.877 9.622c.302 0 .547.235.547.525 0 .29-.245.525-.547.525a.536.536 0 0 1-.546-.525c0-.29.244-.525.546-.525"
      />
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M30.752 32.722v-13.65M24.19 32.722v-13.65M17.627 32.722v-13.65M11.065 32.722v-13.65"
      />
    </svg>
  );
};
export { AssetIcon };
