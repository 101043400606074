import { PensionsPageTemplate } from "../../components";
import { ConfirmInvestmentFund } from "../ConfirmInvestmentFund";

const PensionsInvestmentFund = ({
  to,
  canRecommend,
}: {
  to: string;
  canRecommend?: boolean;
}) => {
  return (
    <PensionsPageTemplate className="pb-24 sm:pb-48" backButton={true}>
      <ConfirmInvestmentFund to={to} canRecommend={canRecommend} />
    </PensionsPageTemplate>
  );
};

export { PensionsInvestmentFund };
