import { Input, InputProps, Tooltip, hasOwnProperty } from "@multiply/lib";
import { FC } from "react";
import { convertNumberToEuroString, CURRENCY_SYMBOL } from "../../utils";
import { Money } from "../../graphqlTypes";

const MAX_CURRENCY_VALUE = 100000000;

export type CurrencyInputProps = {
  variant?: "border" | "plain";
  rightIcon?: React.ReactNode;
  onSubmit?: () => void;
  onChange?: (value: number | null) => void;
  value?: Money | string | number;
  inputSpecificClassName?: string;
  helperText?: React.ReactNode;
  tooltip?: React.ReactNode;
} & Omit<InputProps, "onChange" | "value">;

const CurrencyInput: FC<CurrencyInputProps> = ({
  children,
  className,
  error,
  helperText,
  onFocus,
  onKeyUp,
  onKeyDown,
  onChange,
  onSubmit,
  variant = "border",
  rightIcon,
  value,
  label,
  tooltip,
  ...inputProps
}) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value === "" || e.target.value === CURRENCY_SYMBOL) {
      onChange && onChange(null);
    } else {
      const removeCurrencySymbol =
        e.target.value?.replaceAll(CURRENCY_SYMBOL, "") ?? undefined;
      const cleanNumberValue = Number(
        removeCurrencySymbol.replaceAll(/\D/g, "")
      );

      cleanNumberValue < MAX_CURRENCY_VALUE &&
        onChange &&
        onChange(cleanNumberValue);
    }
  };

  let inputValue: number | string = "";

  if (hasOwnProperty(value, "float")) {
    inputValue = value.float;
  } else {
    inputValue = value ?? "";
  }

  if (
    inputValue !== undefined &&
    inputValue !== null &&
    inputValue.toString().length > 0
  ) {
    inputValue = convertNumberToEuroString(Number(inputValue));
  }

  const combinedLabel = (
    <div className="flex items-center">
      {label ? label : ""}
      {tooltip ? (
        <span>
          <Tooltip className="inline-block">{tooltip}</Tooltip>
        </span>
      ) : (
        ""
      )}
    </div>
  );

  return (
    <div className={className}>
      <Input
        {...inputProps}
        label={combinedLabel}
        value={inputValue}
        onChange={(e) => handleChange(e)}
        onFocus={onFocus}
        onSubmit={onSubmit}
        onWheel={(e) => {
          e.currentTarget.blur();
        }}
        helperText={helperText}
        type="text"
        inputMode="numeric"
        variant={variant ?? ""}
        align="left"
        error={error}
        iconRight={rightIcon}
        iconRightStatus="decorative"
      >
        {children}
      </Input>
    </div>
  );
};

export { CurrencyInput };
