import { AvatarIcon, getDimensionsFromSize } from "../Icons";

type AvatarProps = {
  name?: string;
  getInitials?: boolean;
  namePosition?: "none" | "top" | "bottom" | "left" | "right";
  showBorder?: boolean;
  size?: "xsm" | "sm" | "md" | "lg" | "xl" | "xxl" | "xxxl";
  variant?: "filled" | "ghost" | "icon";
  colorType?:
    | "gray"
    | "red"
    | "blue"
    | "green"
    | "yellow"
    | "orange"
    | "purple"
    | "pink"
    | "irish-life"
    | "sandbox";
};

/**
 * Avatar component
 * @param type {
 * name - name of the user
 * getInitials - whether to get the initials from the name
 * namePosition - position of the name relative to the avatar
 * showBorder - whether to show the border
 * size - size of the avatar
 * variant - variant of the avatar either filled, ghost or icon
 * colorType - type used to set colours and border for the avatar
 * }
 * @returns JSX Component
 */
const Avatar = ({
  name,
  getInitials = false,
  namePosition = "none",
  showBorder = true,
  size = "md",
  variant = "filled",
  colorType = "pink",
}: AvatarProps) => {
  const { width, height } = getDimensionsFromSize(size ?? "lg");

  const initials = getInitials ? getInitialsFromName(name ?? "") : "";

  const { avatarClasses, initialsClasses } = getAvatarClassesFromProps(
    colorType,
    size,
    variant,
    showBorder
  );

  const positionClasses = getNamePositionClass(namePosition);

  return (
    <section
      className={`flex ${positionClasses} gap-4 items-center justify-center`}
    >
      <div
        className={`rounded-full flex aspect-square items-center justify-center ${avatarClasses}`}
        style={{ width: width, height: height }}
      >
        {variant === "icon" ? (
          <div
            className={`w-full h-full flex items-center justify-center rounded-full ${avatarClasses}`}
          >
            <AvatarIcon size={size} />
          </div>
        ) : (
          <div className={initialsClasses}>{initials}</div>
        )}
      </div>
      {namePosition !== "none" && <p>{name}</p>}
    </section>
  );
};

export { Avatar };

/**
 * Gets the initials from the name
 * @param name - name of the user
 * @returns array of initials
 */
const getInitialsFromName = (name: string) => {
  const initials = name.match(/[A-Z]/g) || [];
  return initials;
};

/**
 * Determines the class name for the name position
 * @param namePosition - position of the name relative to the avatar
 * @returns
 */
const getNamePositionClass = (namePosition: string) => {
  switch (namePosition) {
    case "top":
      return "flex-col-reverse";
    case "left":
      return "flex-row-reverse";
    case "right":
      return "flex-row";
    default:
      return "flex-col";
  }
};

/**
 * Detects the avatar colours from the props supplied
 * @param colorType - list of predefined types
 * @returns - object containing the background, ring and text colours
 */
const getAvatarColours = (colorType: string) => {
  let backgroundColor, ringColor, textColor;

  switch (colorType) {
    case "gray":
      backgroundColor = "bg-gray-500";
      ringColor = "ring-gray-900";
      textColor = "text-gray-100";
      break;
    case "red":
      backgroundColor = "bg-red-500";
      ringColor = "ring-red-900";
      textColor = "text-red-100";
      break;
    case "blue":
      backgroundColor = "bg-blue-500";
      ringColor = "ring-blue-600";
      textColor = "text-blue-100";
      break;
    case "green":
      backgroundColor = "bg-green-500";
      ringColor = "ring-green-700";
      textColor = "text-green-100";
      break;
    case "yellow":
      backgroundColor = "bg-yellow-300";
      ringColor = "ring-yellow-500";
      textColor = "text-yellow-800";
      break;
    case "orange":
      backgroundColor = "bg-orange-500";
      ringColor = "ring-orange-700";
      textColor = "text-orange-900";
      break;
    case "purple":
      backgroundColor = "bg-purple-500";
      ringColor = "ring-purple-700";
      textColor = "text-purple-200";
      break;
    case "pink":
      backgroundColor = "bg-pink-500";
      ringColor = "ring-pink-700";
      textColor = "text-pink-200";
      break;
    case "irish-life":
      backgroundColor = "bg-brand-primary";
      ringColor = "ring-icon-icon-selected";
      textColor = "text-white";
      break;
    case "sandbox":
      backgroundColor = "bg-brand-primary";
      ringColor = "ring-icon-icon-selected";
      textColor = "text-white";
      break;
    default:
      backgroundColor = "bg-transparent";
      ringColor = "ring-transparent";
      textColor = "text-white";
  }
  return { backgroundColor, ringColor, textColor };
};

/**
 * Determines the avatar sizes from the size prop
 * @param size - from pre-defined size options of the avatar
 * @returns - object containing the ring width and text size
 */
const getAvatarSizes = (size: string) => {
  let ringWidth, textSize;

  switch (size) {
    case "xsm":
      ringWidth = "ring-1";
      textSize = "text-[0.3rem]";
      break;
    case "sm":
      ringWidth = "ring-1";
      textSize = "text-[0.5rem]";
      break;
    case "md":
      ringWidth = "ring-2";
      textSize = "text-base";
      break;
    case "lg":
      ringWidth = "ring-2";
      textSize = "text-lg";
      break;
    case "xl":
      ringWidth = "ring-2";
      textSize = "text-xl";
      break;
    case "xxl":
      ringWidth = "ring-2";
      textSize = "text-3xl";
      break;
    case "xxxl":
      ringWidth = "ring-4";
      textSize = "text-4xl";
      break;
    default:
      ringWidth = "ring-2";
      textSize = "text-xs";
  }
  return { ringWidth, textSize };
};

/**
 * Determines the avatar details from the props
 * @param colorType - type of the avatar
 * @param size - size of the avatar
 * @param variant - variant of the avatar
 * @returns - object containing the avatar and initials classes
 */
const getAvatarClassesFromProps = (
  colorType: string,
  size: string,
  variant?: string,
  showBorder?: boolean
) => {
  let { backgroundColor, ringColor, textColor } = getAvatarColours(colorType);
  let { ringWidth, textSize } = getAvatarSizes(size);

  let avatarClasses;

  switch (variant) {
    case "icon":
      ringColor = "ring-transparent";
      ringWidth = "ring-0";
      avatarClasses = `${backgroundColor} ${ringColor} ${textColor}`;
      break;
    case "ghost":
      backgroundColor = "bg-transparent";
      textColor = "text".concat(ringColor.slice(ringColor.indexOf("-")));
      avatarClasses = `${backgroundColor} ${ringColor}`;
      break;
    default:
      avatarClasses = `${backgroundColor} ${ringColor}`;
  }

  avatarClasses = `${avatarClasses} ${
    showBorder ? ringWidth : "ring-0"
  } ring-inset`;

  let initialsClasses = `${textColor} ${textSize} font-semibold`;
  return { avatarClasses, initialsClasses };
};
