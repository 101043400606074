import {
  CollegeCost,
  GoalType,
  useCalculateCollegeCostsMutation,
  useUpdateGoalMutation,
} from "../../graphqlTypes";
import {
  CollegeCostsValues,
  useLocallyStoreCollegeCostsCalcResults,
} from "@multiply-ai/college-costs-calculator";
import { useAuth } from "@multiply/lib";
import { useEffect } from "react";

const useSaveCollegeCostsValuesAndSavingsGoal = () => {
  const { userId } = useAuth();

  const [, updateGoal] = useUpdateGoalMutation();
  const { clearCalculationDetails } = useLocallyStoreCollegeCostsCalcResults();

  const [, calculateCollegeCosts] = useCalculateCollegeCostsMutation();

  useEffect(() => {
    const saveEducationGoal = async ({
      cccGoalValues,
    }: {
      cccGoalValues: CollegeCost;
    }) => {
      try {
        await updateGoal({
          input: {
            userId: userId ?? "",
            goal: {
              goalType: GoalType.ChildEducation,
              name: "Child's education",
              targetDate: cccGoalValues?.targetDate,
              valueToSave: cccGoalValues?.total?.float,
            },
            collegeCostId: cccGoalValues?.id,
          },
        });
      } catch (error) {
        console.error(error);
      }
    };

    const collegeCostsString = window.localStorage.getItem("collegeCostsCalc");

    const saveCollegeCosts = async (collegeCostsValue: CollegeCostsValues) => {
      const collegeCostsResult = await calculateCollegeCosts({
        input: {
          userId: userId ?? null,
          accommodation: collegeCostsValue?.accommodation,
          ageOfChild: collegeCostsValue?.ageOfChild,
          courseDuration: collegeCostsValue?.courseDuration,
          eligibleForFreeTuitionFees: collegeCostsValue?.freeFees,
          whatToInclude: collegeCostsValue?.costsInclude,
        },
      });

      if (collegeCostsResult?.data?.updateCollegeCosts) {
        await saveEducationGoal({
          cccGoalValues: collegeCostsResult?.data?.updateCollegeCosts,
        });
      }
    };

    if (collegeCostsString) {
      const collegeCostsValue = JSON.parse(atob(collegeCostsString));
      clearCalculationDetails();
      saveCollegeCosts(collegeCostsValue);
    }
  }, [calculateCollegeCosts, userId, clearCalculationDetails, updateGoal]);
};

export { useSaveCollegeCostsValuesAndSavingsGoal };
