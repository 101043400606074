import { Card, TrackedLink } from "@multiply/lib";
import { CollapsibleSection } from "../../components";
import { HealthInsuranceFeatures } from "./HealthInsuranceFeatures";
import { LevelPricing } from "./LevelPricing";

const IrishLifeHealthInsuranceOverview = () => {
  return (
    <Card className="px-60 py-60">
      <h2 className="text-font-primary text-t27 mb-32 ">
        Popular benefits available on our Health Plans
      </h2>
      <p className="text-font-secondary text-t16 mb-48">
        As an Irish Life Health member, you can get access to a range of
        innovative benefits and services. Your health is of the highest
        importance to us and that's why Irish Life Health offers a range of
        innovative benefits to help give you peace of mind.
      </p>

      <HealthInsuranceFeatures />

      <p className="text-font-secondary text-t16 my-32">
        For a detailed quote visit :
        <TrackedLink
          className="text-font-links"
          href="https://www.irishlifehealth.ie/?utm_source=digitaladvice&utm_medium=multiply&utm_campaign=crosslink_digitaladvice"
        >
          https://www.irishlifehealth.ie/?utm_source=digitaladvice&utm_medium=multiply&utm_campaign=crosslink_digitaladvice
        </TrackedLink>
      </p>

      <CollapsibleSection
        title="Lifetime community rating"
        defaultIsOpen={true}
      >
        Lifetime community rating legislation was brought in on the 1st May 2015
        whereby the premium that individuals pay for health insurance rises with
        the age they enter the private health insurance market. If you are 35
        years of age or over and you do not have health insurance, it will cost
        you more due to Lifetime Community Rating. If you take out private
        health insurance earlier in life, and retain it, you will pay lower
        premiums compared to someone who joins when they are older than 35.
      </CollapsibleSection>

      <CollapsibleSection
        title="You never know what's round the corner"
        defaultIsOpen={true}
      >
        Not having health insurance can leave you exposed and without the peace
        of mind of accessing the best medical care when your family needs it.
      </CollapsibleSection>

      <CollapsibleSection
        title="Irish Life Health offers various plans to choose from"
        defaultIsOpen={true}
      >
        <LevelPricing />
      </CollapsibleSection>

      <p className="text-t21 text-font-primary mt-16 mb-40">
        <TrackedLink
          href="https://www.irishlifehealth.ie/?utm_source=digitaladvice&utm_medium=multiply&utm_campaign=crosslink_digitaladvice"
          target="_blank"
          rel="noreferrer"
          className="text-font-links text-t21"
        >
          Click here{" "}
        </TrackedLink>
        to visit Irish Life Health and see all available plans
      </p>
    </Card>
  );
};

export { IrishLifeHealthInsuranceOverview };
