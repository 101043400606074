import { useAuth, HouseOutlineIcon } from "@multiply/lib";
import { useNavigate } from "react-router-dom";
import { SuggestedGoalCard } from "../../components";
import {
  GoalType,
  useGetBasicInfoFactFindQuery,
  useUpdateGoalMutation,
} from "../../graphqlTypes";
import { useShowSuggestedChildEducationGoal } from "../../hooks";

const SuggestedGoalHome = () => {
  const navigate = useNavigate();
  const [updateGoalResult, updateGoal] = useUpdateGoalMutation();
  const { userId } = useAuth();
  const [basicInfoResult] = useGetBasicInfoFactFindQuery({
    variables: { userId: userId ?? "" },
    pause: !userId,
  });

  const showChildEducationGoal = useShowSuggestedChildEducationGoal();

  const onAdd = async () => {
    const result = await updateGoal({
      input: {
        userId: userId ?? "",
        goal: { goalType: GoalType.BuyHome, name: "Buy home" },
      },
    });
    if (result.data?.updateGoal?.success) {
      if (
        basicInfoResult?.data?.factfind?.planningFor === "USER_AND_PARTNER" &&
        basicInfoResult?.data?.factfind?.maritalStatus !== "MARRIED"
      ) {
        navigate("/advice/plan/goals-wizard/wedding");
      } else if (showChildEducationGoal) {
        navigate("/advice/plan/goals-wizard/child-education");
      } else {
        navigate("/advice/plan/goals-wizard/add-goal/true");
      }
    }
  };

  const onSkip = () => {
    if (
      basicInfoResult?.data?.factfind?.planningFor === "USER_AND_PARTNER" &&
      basicInfoResult?.data?.factfind?.maritalStatus !== "MARRIED"
    ) {
      navigate("/advice/plan/goals-wizard/wedding");
    } else if (showChildEducationGoal) {
      navigate("/advice/plan/goals-wizard/child-education");
    } else {
      navigate("/advice/plan/goals-wizard/add-goal/true");
    }
  };

  return (
    <SuggestedGoalCard
      title="Do you want to buy a home?"
      subtitle="Whether it's your first, you're moving, or just thinking about it."
      icon={<HouseOutlineIcon className="text-icon-display" size="xxl" />}
      onAdd={onAdd}
      onSkip={onSkip}
      error={
        updateGoalResult?.error?.message ||
        updateGoalResult?.data?.updateGoal?.error?.message
      }
      disclaimer="Remember: you can always edit your goals later"
    />
  );
};

export { SuggestedGoalHome };
