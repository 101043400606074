const IntroFactFindMoreInfo = () => {
  return (
    <main>
      <h1 className="text-t20 sm:text-t27 text-font-primary font-bold">
        A quick ID check to open your Irish Life Account
      </h1>
      <p className="text-t12 sm:text-t16 text-font-secondary pb-32">
        Financial advice is a regulated financial service, so as an adviser
        Irish Life is regulated by the Financial Conduct Authority. That
        regulation means we need to verify your identity in order to open your
        Irish Life account. This check will not affect your credit rating and
        only you'll be able to see it on your credit report - it's not visible
        to lenders.{" "}
      </p>
      <h1 className="text-t20 sm:text-t27 text-font-primary font-bold">
        How it works
      </h1>
      <p className="text-t12 sm:text-t16 text-font-secondary pb-32">
        You'll need to provide us with some information to help us verify you're
        a real person and that nobody is impersonating you or your family. It's
        no different to providing ID to open a bank account. We use a
        third-party service called Infido to carry out this identity check. Both
        Multiply and Onfido use secure systems to protect your information.
        We'll use this information to check your identity, and we'll remove it
        from our systems when we no longer need it. If you'd like to know more,
        you can find the details in our Privacy policy
      </p>
    </main>
  );
};

export { IntroFactFindMoreInfo };
