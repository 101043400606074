import { Button, Tooltip } from "@multiply/lib";
import classNames from "classnames";
import { Controller } from "react-hook-form";
import {
  CurrencyInput,
  DateInput,
  DisplayGoalTitle,
  DisplayGoalSubTitle,
  TextInput,
} from "../../components";
import { GoalType } from "../../graphqlTypes";
import { GoalFormValues, useAddGoalForm } from "../../hooks";
import { getGoalTooltipLabelFromType } from "../../utils";

import { GoalIcon } from "../GoalIcon";

type AddGoalFormProps = {
  onSubmit: (values: GoalFormValues) => Promise<void>;
  goalType?: GoalType;
  className?: string;
};

const AddGoalForm = ({ onSubmit, goalType, className }: AddGoalFormProps) => {
  const { control, formState, handleSubmit, watch } = useAddGoalForm();
  const { isSubmitting, errors, isValid } = formState;
  const noErrors = Object.values(errors).every((v) => v === undefined);
  const goalName = watch("name");

  return (
    <form className={classNames("flex flex-col max-w-460 ", className)}>
      <GoalIcon size="xl" goalType={goalType ?? GoalType.Custom} />
      <DisplayGoalTitle
        goalType={goalType ?? null}
        className="text-t27 text-font-primary sm:text-t34 mt-12 mb-16"
      />
      <DisplayGoalSubTitle
        goalType={goalType ?? null}
        goalName={goalName ?? null}
      />

      <div className="space-y-24 w-full mt-40">
        <Controller
          control={control}
          name="name"
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <TextInput
                label="Name of goal"
                placeholder="Enter name"
                error={error?.message}
                value={value ?? ""}
                onChange={onChange}
              />
            );
          }}
        />

        <Controller
          control={control}
          name="targetDate"
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error },
          }) => {
            return (
              <DateInput
                format="MMYYYY"
                label="Target date"
                value={value ?? ""}
                onBlur={onBlur}
                onChange={onChange}
                error={error?.message}
              />
            );
          }}
        />

        <Controller
          control={control}
          name="valueToSave"
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error },
          }) => {
            return (
              <>
                <CurrencyInput
                  label={
                    <div
                      className="flex justify-between items-start"
                      onClick={(e) => e.preventDefault()}
                    >
                      <p className="mb-4">
                        {goalType === GoalType.BuyHome
                          ? "Deposit & fees needed"
                          : "Amount needed"}
                      </p>

                      {(goalType === GoalType.BuyHome ||
                        goalType === GoalType.Wedding) && (
                        <Tooltip>
                          {getGoalTooltipLabelFromType(goalType)}
                        </Tooltip>
                      )}
                    </div>
                  }
                  placeholder="Enter amount"
                  error={error?.message}
                  value={value ?? ""}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </>
            );
          }}
        />
      </div>
      <Button
        className="mt-40 w-full"
        disabled={!noErrors || isSubmitting || !isValid}
        onClick={handleSubmit(onSubmit)}
      >
        Continue
      </Button>
    </form>
  );
};

export { AddGoalForm };
