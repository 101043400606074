import type { SVGProps, FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";

const DropdownCaret: FC<SVGProps<SVGSVGElement>> = ({
  className,
  ...props
}) => {
  return (
    <svg
      data-testid="down caret"
      aria-label={props["aria-label"] ?? "down caret"}
      viewBox="0 0 16 16"
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(defaultClassnames, className)}
      {...props}
    >
      <path
        d="M3 6L8 11L13 6"
        stroke="#4E4670"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export { DropdownCaret };
