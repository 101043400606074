import { ButtonLink } from "@multiply/lib";
import { NavLink, useSearchParams } from "react-router-dom";

const RetirementGoalsUpdated = () => {
  const [searchParams] = useSearchParams();
  const updated = searchParams.get("updated");
  const targetMonthlyIncome = updated?.includes("targetMonthlyIncome") ?? false;
  const retirementAge = updated?.includes("retirementAge") ?? false;

  return (
    <div className="pt-48 pb-40">
      <h1 className="text-t21 text-font-primary font-semibold text-center">
        Pension goals updated
      </h1>
      <p className="text-t16 text-left mt-18">
        {`We've updated your ${
          targetMonthlyIncome ? "target monthly income" : ""
        }${retirementAge && targetMonthlyIncome ? " and " : ""}${
          retirementAge ? "retirement age" : ""
        } and worked ${
          targetMonthlyIncome && retirementAge ? "these" : "it"
        } into your plan. To reflect a lump sum contribution, you'll just need to `}
        <span>
          <NavLink
            to="/pensions"
            className="text-font-links hover:text-font-links-hovered"
          >
            update your pension details.
          </NavLink>
        </span>
      </p>
      <p className="mt-8">
        Your monthly contribution amount can't be changed as it's personalised
        to your financial situation.
      </p>
      <div className="mt-32 w-full flex justify-center">
        <ButtonLink to="/pensions/pension-details">Okay</ButtonLink>
      </div>
    </div>
  );
};

export { RetirementGoalsUpdated };
