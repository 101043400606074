import { Button, Card, Checkbox, RadioInputGroup } from "@multiply/lib";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PensionsPageTemplate } from "../../components";

enum PolicyType {
  REPLACE_IL_POLICY = "REPLACE_IL_POLICY",
  REPLACE_OTHER_POLICY = "REPLACE_OTHER_POLICY",
  NO_REPLACE = "NO_REPLACE",
}

const PensionsDeclaration = () => {
  const navigate = useNavigate();
  const [checkedPolicies, setCheckedPolicies] = useState<
    PolicyType | undefined
  >(undefined);
  const [declaration, setDeclaration] = useState(false);

  const onSubmit = () => {
    if (
      checkedPolicies === PolicyType.REPLACE_IL_POLICY ||
      checkedPolicies === PolicyType.REPLACE_OTHER_POLICY
    ) {
      navigate("/pensions/advice-changed");
    } else if (checkedPolicies === PolicyType.NO_REPLACE) {
      navigate("/pensions/employment-details-check");
    } else {
      return;
    }
  };

  return (
    <PensionsPageTemplate className="pt-[5%] px-12 sm:px-80 pb-24 sm:pb-80">
      <Card className="p-36 pb-52 mb-56">
        <h1 className="text-t27 text-font-primary font-semibold pb-16">
          Declaration under article 3(5) of the Personal Retirement Savings
          Account (Disclosure) Regulations 2002
        </h1>
        <p className="text-t18 text-font-primary pb-16">
          You the customer should read the following and indicate that you have
          done so.
        </p>
        <p className="text-t18 text-font-primary pb-28">
          I understand that the Declaration below relates to the PRSA contract
          proposed by me in this application. I confirm that I have received the
          relevant Preliminary Disclosure Certificate for the PRSA contract
          proposed in this application.
        </p>
        <div className="border-2 border-font-primary px-28 py-24">
          <p className="font-bold text-t18 text-font-primary">
            Warning: If you propose to enter into this PRSA contract in complete
            or partial replacement of an existing PRSA contract or a retirement
            annuity contract, please take special care to satisfy yourself that
            this PRSA contract meets your needs. In particular, please make sure
            that you are aware of the financial consequences of replacing your
            existing PRSA contract or retirement annuity contract. If you are in
            doubt about this, please contact your PRSA provider.
          </p>
        </div>
        <p className="text-t18 text-font-primary font-semibold pt-28 pb-12">
          Please select the appropriate option:
        </p>

        <RadioInputGroup
          onChange={(newValue: PolicyType | undefined) =>
            setCheckedPolicies(newValue)
          }
          value={checkedPolicies}
          options={[
            {
              label: "Yes, this policy does replace an Irish Life policy ",
              value: PolicyType.REPLACE_IL_POLICY,
            },
            {
              label:
                "Yes, this policy does replace an existing policy from another life company",
              value: PolicyType.REPLACE_OTHER_POLICY,
            },
            {
              label: "No, this policy does not replace an existing policy",
              value: PolicyType.NO_REPLACE,
            },
          ]}
        />

        <p className="text-t18 text-font-primary font-semibold pt-28 pb-12">
          Your declaration
        </p>
        <Checkbox
          variant="square"
          className="mb-16 items-center text-font-secondary"
          checked={declaration}
          onChange={() => setDeclaration(!declaration)}
          key="4"
        >
          I have read and agree to the above declaration
        </Checkbox>
      </Card>
      <Button
        disabled={!declaration || checkedPolicies === undefined}
        onClick={onSubmit}
      >
        Continue
      </Button>
    </PensionsPageTemplate>
  );
};

export { PensionsDeclaration };
