import classNames from "classnames";
import { ComponentPropsWithRef, ReactNode, forwardRef } from "react";
import { useId } from "../../hooks";
import "./input.css";

export type InputProps = {
  label?: React.ReactNode; // label for the input
  children?: ReactNode; // children to render inside the input
  error?: string; // optional error message to display
  helperText?: React.ReactNode; // optional helper text to display
  invalid?: boolean; // force validation style without using intrinsic validation
  className?: string; // optional class name to add to the whole input
  variant?: "border" | "plain"; // variant of the input
  align?: "left" | "center" | "right"; // text alignment
  iconLeft?: React.ReactNode; // icon to display on the left
  iconLeftStatus?: "decorative" | "action"; // is the icon left actionable?
  iconRight?: React.ReactNode; // icon to display on the right
  iconRightStatus?: "decorative" | "action"; // is the icon right actionable?
  isObfuscated?: boolean; // is the input obfuscated?
  onSubmit?: () => void; // callback for when the user presses enter
} & ComponentPropsWithRef<"input">; // all other intrinsic props are passed to the input

/**
 * This is a standard input component.
 * There is a fix in index.css to make this work.
 * -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
 *
 * @params see type above
 * @returns a standard input component
 */
const Input = forwardRef(
  (
    {
      label,
      children,
      error,
      helperText,
      invalid,
      className,
      variant = "border",
      align = "left",
      iconLeft,
      iconLeftStatus = "decorative",
      iconRight,
      iconRightStatus = "action",
      isObfuscated = false,
      onSubmit,
      onKeyUp,
      ...inputProps
    }: InputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const defaultId = useId();
    const id = inputProps.id ?? defaultId;

    const defaultClasses = getDefaultInputClasses(variant ?? "", align);
    const focusClasses = getFocusClasses(variant ?? "", invalid ?? false);
    return (
      <div
        className={classNames("text-t16 text-input-txt font-normal", className)}
      >
        {label && (
          <div className="pb-8">
            <label htmlFor={id} className="text-input-label font-semibold">
              {label}
            </label>
            {helperText && <p className="text-font-secondary">{helperText}</p>}
          </div>
        )}
        <div className="relative flex items-center placeholder:text-input-place focus-within:font-medium focus-within:text-input-txt-focus">
          {iconLeft && (
            <div
              className={classNames("absolute left-12 cursor-pointer", {
                "text-input-txt-disabled cursor-auto": inputProps.disabled,
                "pointer-events-none cursor-auto":
                  iconLeftStatus === "decorative",
              })}
            >
              {iconLeft}
            </div>
          )}
          <div className="w-full">
            <input
              ref={ref}
              type={isObfuscated ? "password" : "text"}
              className={classNames(defaultClasses, focusClasses, {
                "pl-48": iconLeft,
                "pl-18": !iconLeft,
                "pr-48": iconRight,
                "pr-18": !iconRight,
              })}
              id={id}
              onKeyUp={(event) => {
                if (event.key === "Enter" && onSubmit) {
                  onSubmit();
                }

                if (onKeyUp) {
                  onKeyUp(event);
                }
              }}
              {...inputProps}
            >
              {children}
            </input>
          </div>

          {iconRight && (
            <div
              className={classNames("absolute right-12", {
                "text-input-txt-disabled": inputProps.disabled,
                "pointer-events-none":
                  iconRightStatus === "decorative" || inputProps.disabled,
              })}
            >
              {iconRight}
            </div>
          )}
        </div>
        {error && <p className="text-action-error text-t16 mt-4">{error}</p>}
      </div>
    );
  }
);

const getDefaultInputClasses = (variant: string, align: string) => {
  const sharedVariantClasses = classNames(
    "h-full min-h-48 px-18 w-full text-t16",
    "placeholder:text-input-place bg-input-background",
    `text-${align}`,
    "disabled:text-input-txt-disabled disabled:placeholder:text-input-place-disabled disabled:bg-input-background-disabled disabled:outline-input-border-disabled disabled:cursor-not-allowed",
    "invalid:outline-input-border-error invalid:outline-2 invalid:outline-offset-[-2px]",
    "hover:outline-input-border-hover hover:outline-2 hover:outline-offset-[-2px]"
  );

  switch (variant) {
    case "plain":
      return classNames(
        sharedVariantClasses,
        "border-transparent outline outline-1 outline-transparent outline-offset-[-1px]"
      );

    case "border":
    default:
      return classNames(
        sharedVariantClasses,
        "border-1 border-solid border-transparent rounded outline outline-1 outline-input-border outline-offset-[-1px]"
      );
  }
};

const getFocusClasses = (variant: string, invalid: boolean) => {
  switch (variant) {
    case "plain":
    default:
      return classNames({
        "outline-input-border-error outline-2 outline-offset-[-2px] focus:outline-input-border-error focus:invalid:outline-input-border-error ":
          invalid,
        "outline-input-border outline-1 outline-offset-[-1px] focus:outline-input-border-focus focus:outline-2 outline-offset-[-1px] focus:outline-offset-[-2px]":
          !invalid,
      });
  }
};

export { Input };
