import { useNavigate, useParams } from "react-router-dom";
import { FactfindPage } from "../FactfindPage";

type EditPersonalDetailsProps = {
  onDoneRoute: string;
  cta: string;
};

const EditPersonalDetails = ({
  onDoneRoute,
  cta,
}: EditPersonalDetailsProps) => {
  const fieldName = useParams().fieldName;
  const navigate = useNavigate();

  const onDone = async () => {
    return navigate(onDoneRoute);
  };

  const context = `settings_${fieldName}`;

  return <FactfindPage context={context} onDone={onDone} cta={cta} />;
};

export { EditPersonalDetails };
