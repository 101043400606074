import { Input, InputProps } from "@multiply/lib";

export type TextInputProps = {
  children?: React.ReactNode;
  className?: string;
  error?: string;
} & InputProps;

const TextInput = ({
  children,
  className,
  error,
  ...inputProps
}: TextInputProps) => {
  return (
    <div className={className}>
      <Input {...inputProps} error={error}>
        {children}
      </Input>
    </div>
  );
};

export { TextInput };
