import { Card } from "@multiply/lib";

const GuidancePendingReviewCard = () => {
  return (
    <Card className="p-24 mb-48">
      <h1 className="text-font-primary text-t24 sm:text-t27 pb-12 font-bold">
        Your plan is being reviewed
      </h1>
      <p className="text-font-primary text-t12 sm:text-t16 pb-16">
        We're currently ensuring your plan is suitable, please come back to
        access it later. Thanks for your patience.
      </p>
    </Card>
  );
};

export { GuidancePendingReviewCard };
