import { PrsaFund } from "../graphqlTypes";

type PensionPortfolio = {
  name: string;
  investorType: string;
  detailsLink: string;
  isResponsible: boolean;
  isEditable: boolean;
};

export const getPensionPortfolios = (
  recommendedFund: PrsaFund
): PensionPortfolio => {
  switch (recommendedFund) {
    case PrsaFund.GlobalCashFund:
      return {
        name: "Global Cash Fund (PRSA)",
        investorType: "Safety First Investor - IL1",
        detailsLink:
          "https://www.irishlife.ie/investments/fund-details-irish-life-investments/?id=ICAQ",
        isResponsible: false,
        isEditable: true,
      };

    case PrsaFund.PensionPortfolio_2:
      return {
        name: "Pension Portfolio 2",
        investorType: "Careful Investor - IL2",
        detailsLink:
          "https://www.irishlife.ie/investments/fund-details-irish-life-investments/?id=P2W",
        isResponsible: true,
        isEditable: true,
      };

    case PrsaFund.PensionPortfolio_3:
      return {
        name: "Pension Portfolio 3",
        investorType: "Conservative Investor - IL3",
        detailsLink:
          "https://www.irishlife.ie/investments/fund-details-irish-life-investments/?id=P3W",
        isResponsible: true,
        isEditable: true,
      };

    case PrsaFund.PensionPortfolio_4:
      return {
        name: "Pension Portfolio 4",
        investorType: "Balanced Investor - IL4",
        detailsLink:
          "https://www.irishlife.ie/investments/fund-details-irish-life-investments/?id=P4W",
        isResponsible: true,
        isEditable: true,
      };

    case PrsaFund.PensionPortfolio_5:
      return {
        name: "Pension Portfolio 5",
        investorType: "Experienced Investor - IL5",
        detailsLink:
          "https://www.irishlife.ie/investments/fund-details-irish-life-investments/?id=P5W",
        isResponsible: true,
        isEditable: true,
      };

    case PrsaFund.PensionPortfolio_6:
      return {
        name: "Pension Portfolio 6",
        investorType: "Adventurous Investor - IL6",
        detailsLink:
          "https://www.irishlife.ie/investments/fund-details-irish-life-investments/?id=P6W",
        isResponsible: true,
        isEditable: true,
      };
    default:
      const unreachable: never = recommendedFund;
      return unreachable;
  }
};
