import { Card } from "@multiply/lib";

const TriageCard = ({ reason }: { reason?: string | null }) => {
  return (
    <Card className="p-24 mb-48">
      <h1 className="text-font-primary text-t24 sm:text-t27 pb-12 font-bold">
        App not available
      </h1>
      <p className="text-font-primary text-t12 sm:text-t16 pb-16">
        {reason
          ? `Reason: ${reason}`
          : "This could be that you are not over the age of 18 or you are not a resident of the Republic of Ireland."}
      </p>
    </Card>
  );
};

export { TriageCard };
