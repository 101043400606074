import { ContainerSmallSize } from "@multiply/lib";
import { useGetUserJourney } from "../../hooks";
import { InitialVulnerabilityCheck } from "../CreatePlanRightTime/InitialVulnerabilityCheck";
import { Loading } from "../Loading";

const PensionVulnerabilityCheck = () => {
  const userJourney = useGetUserJourney();
  return (
    <ContainerSmallSize className="h-screen flex flex-col items-center justify-center">
      {userJourney.loading ? (
        <Loading />
      ) : (
        <InitialVulnerabilityCheck
          to={userJourney.planRoute}
          call={userJourney.callRoute}
        />
      )}
    </ContainerSmallSize>
  );
};

export { PensionVulnerabilityCheck };
