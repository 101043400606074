import { Button } from "@multiply/lib";
import startCase from "lodash/startCase";
import { CollegeCost } from "../../graphqlTypes";
import { whatToIncludeLabel } from "./CollegeCostCalculatorUtils";

type CollegeCalculationYouToldUsProps = {
  collegeCostValues: CollegeCost;
  makeChangesHandler: () => void;
};

const CollegeCalculationYouToldUs = ({
  collegeCostValues,
  makeChangesHandler,
}: CollegeCalculationYouToldUsProps) => {
  return (
    <div className="rounded-sm border border-[#D8D6DF] border-1 w-full md:w-[30%] text-font-primary p-24 font-semibold ">
      <p className="text-t21 mb-8">You told us:</p>
      <p className="text-t16">
        Child's age:{" "}
        <span className="font-normal">
          {collegeCostValues.ageOfChild} years old
        </span>
      </p>
      <p className="text-t16">
        Course length:{" "}
        <span className="font-normal">
          {collegeCostValues.courseDuration} years
        </span>
      </p>
      <p className="text-t16">
        Accommodation:{" "}
        <span className="font-normal">
          {startCase(collegeCostValues.accommodationType?.toLowerCase())}
        </span>
      </p>
      <p className="text-t16">
        Free fees:{" "}
        <span className="font-normal">
          {collegeCostValues.eligibleForFreeTuitionFees
            ? "Eligible"
            : "Not eligible"}
        </span>
      </p>
      <p className="text-t16 mb-18">
        Include:{" "}
        <span className="font-normal">
          {whatToIncludeLabel(
            collegeCostValues.whatToInclude,
            collegeCostValues.accommodationType
          )}{" "}
        </span>
      </p>
      <Button
        variant="plain"
        size="sm"
        className="font-semibold pl-0"
        onClick={makeChangesHandler}
      >
        Make a change
      </Button>
    </div>
  );
};

export { CollegeCalculationYouToldUs };
