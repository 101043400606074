import { Icon } from "./ToasterContext";

type ToastIconProps = {
  icon: Icon;
};

const ToastIcon = ({ icon }: ToastIconProps) => {
  switch (icon) {
    case Icon.Success:
      return (
        <svg
          width={25}
          height={25}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m10.89 16-.992 1.125a1.5 1.5 0 0 0 2.053-.064L10.891 16Zm9.061-6.94a1.5 1.5 0 1 0-2.121-2.12l2.121 2.12ZM8.105 11.543a1.5 1.5 0 1 0-1.985 2.25l1.985-2.25Zm3.846 5.519 8-8-2.121-2.122-8 8 2.121 2.122Zm-.068-2.186-3.778-3.333-1.985 2.25 3.778 3.333 1.985-2.25Z"
            fill="#55CBB7"
          />
        </svg>
      );

    case Icon.Error:
      // TODO: Replace with real error icon

      return (
        <svg
          aria-hidden={true}
          width={25}
          height={25}
          viewBox="0 0 16 16"
          className="text-action-error"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="currentColor"
            d="M3.88 10.548a1.111 1.111 0 1 0 1.572 1.571l-1.571-1.571Zm8.239-5.096a1.111 1.111 0 1 0-1.572-1.571l1.572 1.571ZM5.452 12.12l6.667-6.667-1.572-1.571-6.666 6.667 1.571 1.571Z"
          />
          <path
            fill="currentColor"
            d="M5.452 3.881a1.111 1.111 0 0 0-1.57 1.571l1.57-1.571Zm5.096 8.238a1.111 1.111 0 0 0 1.571-1.571l-1.571 1.571ZM3.88 5.452l6.667 6.667 1.571-1.571L5.452 3.88l-1.57 1.571Z"
          />
        </svg>
      );

    default:
      return null;
  }
};

export { ToastIcon };
