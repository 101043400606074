import { ContainerMaxSize, TrackedLink, useAuth } from "@multiply/lib";
import { useGetMessagesQuery } from "../../graphqlTypes";

export function Inbox() {
  const { userId, token } = useAuth();

  const [messagesResult] = useGetMessagesQuery({
    variables: { userId: userId ?? "" },
    pause: !userId,
    requestPolicy: "cache-and-network",
  });

  return (
    <ContainerMaxSize>
      <div className="h-full py-40">
        <h1 className="text-t20 sm:text-t27 text-font-primary mb-32">Inbox</h1>
        {messagesResult?.data?.messages.length === 0 && (
          <p className="text-font-secondary sm:text-t21">
            You have no messages
          </p>
        )}
        {messagesResult?.data?.messages.map((message) => (
          <TrackedLink
            href={message?.link ? `${message?.link}?auth-token=${token}` : ""}
            key={message.id}
            target="_blank"
            rel="noreferrer"
          >
            <div className="p-32 flex justify-between mb-8 rounded-lg bg-white">
              <div>
                <h1 className="text-t16 sm:text-t20 text-font-primary">
                  {message?.title}
                </h1>
                <h2 className="text-t12 sm:text-t16 text-font-secondary">
                  {message?.subtitle}
                </h2>
              </div>
              <h3 className="text-t16 sm:text-t20 text-font-primary">
                {message?.dateReceived}
              </h3>
            </div>
          </TrackedLink>
        ))}
      </div>
    </ContainerMaxSize>
  );
}
