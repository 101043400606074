import classNames from "classnames";
import React from "react";
import { useId } from "../../hooks";

type ValueType = React.ComponentProps<"input">["value"] | boolean;

type RadioInputProps = {
  label?: string;
  children?: React.ReactNode | string;
  value: ValueType;
  onChange: (nextValue: ValueType) => void;
} & Omit<React.ComponentProps<"input">, "value" | "onChange">;

const RadioInput = ({
  className,
  label,
  onChange,
  value,
  disabled = false,
  checked,
  children,
  ...inputProps
}: RadioInputProps) => {
  const id = useId();

  return (
    <div className="flex justify-center relative">
      <div className={classNames("flex items-center ", className)}>
        <input
          {...inputProps}
          className={classNames(
            "appearance-none mr-4 h-24 w-24 rounded-full " +
              "border-2 " +
              "border-solid " +
              "border-radio-empty-border bg-radio-empty-fill " +
              "hover:cursor-pointer " +
              "hover:disabled:pointer-events-none " +
              "focus:border-radio-focus " +
              // BEFORE:
              "before:pointer-events-none before:absolute before:h-8 before:w-8 before:scale-0 before:rounded-full " +
              "before:bg-transparent before:opacity-0 before:content-[''] " +
              //AFTER:
              "after:absolute after:z-[1] after:block after:h-8 after:w-8 after:rounded-full after:content-[''] " +
              //CHECKED:
              "checked:hover:cursor-pointer " +
              "checked:border-none " +
              "checked:after:absolute checked:after:origin-center checked:after:content-[''] " +
              "checked:after:h-[24px] checked:after:w-[24px] checked:after:rounded-full " +
              "checked:after:border-radio-selected-border checked:after:bg-radio-selected-fill " +
              "checked:after:border checked:after:border-solid " +
              "checked:after:border-[8px] " +
              "focus:checked:after:ring-2 focus:checked:after:ring-blue-300 focus:checked:after:ring-offset " +
              "hover:checked:after:border-radio-hover" +
              // DISABLED:
              "disabled:bg-radio-empty-fill disabled:border-radio-disabled"
          )}
          type="radio"
          id={id}
          checked={checked}
          disabled={disabled}
          onChange={(e) => {
            const nextValue = e.currentTarget.value;
            e.currentTarget.focus();

            if (typeof value === "boolean") {
              onChange(nextValue === "true");
            } else {
              onChange(nextValue);
            }
          }}
          value={typeof value === "boolean" ? value.toString() : value}
        />
        <label
          className={classNames(
            "max-w-[80%] mt-px inline-block pl-4 text-font-secondary hover:cursor-pointer ",
            { "text-radio-disabled hover:cursor-default ": disabled }
          )}
          htmlFor={id}
        >
          {label ?? children}
        </label>
      </div>
    </div>
  );
};

export { RadioInput };
