import { useAuth, useToaster } from "@multiply/lib";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmDialogue } from "../../components";
import { useDeleteDebtMutation } from "../../graphqlTypes";

const ConfirmDeleteDebt = () => {
  const { debtId } = useParams();
  const { userId } = useAuth();
  const { addToast } = useToaster();
  const navigate = useNavigate();

  const [deleteDebtResult, deleteDebt] = useDeleteDebtMutation();

  const deletionError =
    deleteDebtResult?.error || deleteDebtResult?.data?.deleteDebt?.error;

  const handleDeleteDebt = async () => {
    try {
      const result = await deleteDebt({
        input: {
          userId: userId ?? "",
          debtId,
        },
      });

      if (result.data?.deleteDebt?.success) {
        navigate("/products");
      }
    } catch (error) {}
    addToast({ message: "Debt deleted" });
  };

  return (
    <ConfirmDialogue
      title="Are you sure you want to delete this debt?"
      onClickConfirm={handleDeleteDebt}
      onClickCancel={() => navigate(-1)}
      confirmCta="Yes, delete"
      cancelCta="No, keep"
      error={deletionError?.message}
    />
  );
};

export { ConfirmDeleteDebt };
