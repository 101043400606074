import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";
import {
  IconProps,
  getDimensionsFromSize,
  getColorFromVariant,
} from "./shared";
import { useId } from "../../hooks";

const SirenOutlineIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);

  const uuid = useId();

  return (
    <svg
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="siren icon"
      fill="none"
      {...props}
    >
      <g clipPath={`url(#${uuid})`}>
        <path
          fill="#fff"
          d="M24.581 28.45V17.67a6.932 6.932 0 0 0-6.934-6.934 6.932 6.932 0 0 0-6.934 6.934v10.775h-3.87v3.081h18.581"
        />
        <path
          stroke={fill}
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={2.6}
          d="M24.581 28.45V17.67a6.932 6.932 0 0 0-6.934-6.934 6.932 6.932 0 0 0-6.934 6.934v10.775h-3.87v3.081h18.581"
        />
        <path
          stroke={fill}
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={2.6}
          d="M14.639 17.676a3.012 3.012 0 0 1 3.013-3.013M17.652 3.453v2.554M3.41 17.676h2.575M29.324 17.676h2.523M25.909 9.413l1.927-1.95M9.39 9.413 7.42 7.1M28.423 31.526V28.47H10.718"
        />
      </g>
      <defs>
        <clipPath id={uuid}>
          <path fill="#fff" d="M.128.172h35v35h-35z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { SirenOutlineIcon };
