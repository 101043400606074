import { SVGProps } from "react";

const BackUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M18.004 36c9.941 0 18-8.059 18-18s-8.059-18-18-18-18 8.059-18 18 8.059 18 18 18Z"
        fill="url(#b)"
      />
      <path
        d="m16.03 16.92-.356 1.31-3.812 2.502-2.858 6.193 3.146 4.15"
        stroke="#50C9B5"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m14.29 30.061-2.024-3.335 2.144-2.62s3.334 3.098 1.786 8.219M19.453 16.92l.358 1.191 3.453.834 1.43-1.072M26.613 20.187l-3.215 2.024s-.952 6.55-.595 9.767M17.59 18.88s-.513 2.464.915 4.845c1.43 2.382 1.348 6.827.395 8.137"
        stroke="#50C9B5"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={18.004}
        y1={29.211}
        x2={18.004}
        y2={-1.601}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#EFF4FA" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);

export { BackUp };
