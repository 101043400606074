"use client";

import { ArrowRightNoOutline, Button, IrishLifeLogoText } from "@multiply/lib";
import { useNavigate } from "react-router-dom";
import { OnboardingIntroContainer } from "../../components";
import { motion } from "framer-motion";

const Welcome = () => {
  const navigate = useNavigate();

  const variants = {
    hidden: { opacity: 0, x: 1000, y: 0 },
    enter: { opacity: 1, x: 0, y: 0 },
  };

  const title = "Welcome to digital advice";
  const subtitle = "By Irish Life";

  return (
    <div className="bg-brand-primary">
      <motion.main
        variants={variants}
        initial="hidden"
        animate="enter"
        transition={{ type: "linear" }}
        className="bg-brand-primary"
      >
        <OnboardingIntroContainer>
          <div className="pt-28 md:pt-56 pl-22 md:pl-56">
            <IrishLifeLogoText />
          </div>
          <section className="flex-1 flex flex-col justify-center px-20">
            <div className="w-fit md:self-center">
              {title.split("").map((char, charIndex) => (
                <motion.h1
                  key={charIndex}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: charIndex * 0.1 }}
                  className="text-t72 md:text-t80 text-white font-bold inline md:text-center leading-[4rem]"
                >
                  {char}
                </motion.h1>
              ))}
            </div>
            <div className="w-fit md:self-center">
              {subtitle.split("").map((char, charIndex) => (
                <motion.h2
                  key={charIndex}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    duration: 0.5,
                    delay: title.length * 0.1 + 1 + charIndex * 0.1,
                  }} // Delay after h1 animation
                  className="text-t34 text-white inline pt-12 md:text-center leading-[4rem]"
                >
                  {char}
                </motion.h2>
              ))}
            </div>
          </section>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2, delay: 2 }}
            className="flex justify-center pb-48 md:pb-88"
          >
            <Button
              className="text-center"
              variant="secondary"
              iconRight={<ArrowRightNoOutline size="sm" />}
              onClick={() => navigate("/adviser-intro")}
            >
              Let's begin
            </Button>
          </motion.div>
        </OnboardingIntroContainer>
      </motion.main>
    </div>
  );
};

export { Welcome };
