import { hasOwnProperty } from "./hasOwnProperty";

function itemToKey(item: unknown) {
  if (!item) {
    return "";
  }

  if (typeof item === "string") {
    return item;
  }

  if (typeof item === "object" && hasOwnProperty(item, "value")) {
    return JSON.stringify(item.value);
  }

  throw new Error(`Could not convert item to key: ${JSON.stringify(item)}`);
}

export { itemToKey };
