import React, { FC, ReactNode } from "react";
import classNames from "classnames";
import { CheckboxIcon } from "./CheckboxIcon";

type CheckboxProps = {
  children?: React.ReactNode;
  onBlur?: () => void;
  variant?: "round" | "square";
  label?: ReactNode;
  error?: string;
} & Omit<React.ComponentProps<"input">, "onBlur">;

const Checkbox: FC<CheckboxProps> = ({
  children,
  onChange,
  checked,
  onBlur,
  className,
  label,
  variant,
  value,
  error,
  onSubmit,
  ...inputProps
}) => {
  const { "aria-label": ariaLabel } = inputProps;

  return (
    <>
      <label
        aria-label={ariaLabel ?? "checkbox"}
        className={classNames(
          "relative cursor-pointer flex flex-row items-start justify-start",
          className
        )}
      >
        <input
          type="checkbox"
          className="peer sr-only appearance-none"
          onChange={(event) => {
            onChange && onChange(event);
            onSubmit && onSubmit(event);
            onBlur && onBlur();
          }}
        />
        <div
          className={classNames(
            "bg-checkbox-background-unchecked rounded-full border-2 border-input-border",
            "w-24 h-24 p-2 flex flex-shrink-0 justify-center items-center focus-within:border-blue-500 mr-12",
            {
              "rounded-md": variant === "square",
              "rounded-full": variant === "round",
            }
          )}
        >
          <CheckboxIcon
            className={classNames("text-checkbox-tick opacity-0", {
              "opacity-100": checked,
            })}
          />
        </div>
        {children && <div>{children}</div>}
        {label && <p className="text-font-primary">{label}</p>}
      </label>
      {error && <p className="text-action-error text-t16 mt-12">{error}</p>}
    </>
  );
};

export { Checkbox };
