import { useAuth } from "./useAuth";

const UnauthenticatedTemplate = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { loggedIn } = useAuth();

  if (!loggedIn) {
    return <>{children}</>;
  }

  return null;
};

export { UnauthenticatedTemplate };
