import classNames from "classnames";
import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import {
  getColorFromVariant,
  getDimensionsFromSize,
  IconProps,
} from "./shared";

const HeartHealthCircleIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);
  return (
    <svg
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 59 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={29.692} cy={29.406} r={24.672} fill={fill} />
      <path
        d="M29.597 22.099s1.36-3.703 5.86-3.703 9.255 4.645 4.98 12.794c-3.618 6.896-9.63 9.364-10.81 9.798h-.002c-1.18-.434-7.255-2.902-10.873-9.798-4.275-8.15.415-12.794 4.914-12.794 4.5 0 5.926 3.703 5.926 3.703"
        stroke="#5463D6"
        strokeWidth={1.863}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.223 29.817c-1.312-1.968-.79-3.26-.223-3.813-.21.091-.453-.025-.52-.831-.117-1.413.743-1.579.743-3.065 0-1.487.836-1.858 1.486-2.88.65-1.022.743-.65 4.18-.65s2.88.464 3.901 1.672c.818.966 1.517 1.64 1.765 1.858.155-.248.539-.966.836-1.858.371-1.115 1.022-1.022 5.016-1.672 3.994-.65 4.83 1.3 6.223 5.016 1.393 3.715-1.58 8.917-4.273 12.354-2.155 2.75-4.86 4.118-5.944 4.458-.403.155-1.895.167-4.645-1.021-3.436-1.487-6.687-6.781-8.545-9.568Z"
        fill="#5463D6"
        fillOpacity={0.2}
      />
      <path
        d="M29.577 22.066s1.361-3.703 5.86-3.703c4.5 0 9.255 4.644 4.98 12.794-3.618 6.895-9.63 9.363-10.81 9.798h-.002c-1.18-.435-7.255-2.903-10.872-9.798-4.276-8.15.414-12.794 4.913-12.794 4.5 0 5.926 3.703 5.926 3.703"
        stroke="#5463D6"
        strokeOpacity={0.2}
        strokeWidth={2.229}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.996 28.925h4.573l1.927 3.48 3.2-6.956 3.778 7.834 3.616-7.265 1.51 3.161H41"
        stroke="#5463D6"
        strokeWidth={1.863}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx={29.512}
        cy={29.238}
        r={28.071}
        stroke="#BEC7FF"
        strokeWidth={1.863}
      />
    </svg>
  );
};

export { HeartHealthCircleIcon };
