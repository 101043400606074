import {
  Button,
  Card,
  Icon,
  Spinner,
  useAuth,
  useToaster,
} from "@multiply/lib";
import debounce from "lodash/debounce";
import get from "lodash/get";
import set from "lodash/set";
import { useMemo } from "react";
import { ModalNavLink } from "../../components";
import { ConversationForm } from "../../components/ConversationForm";
import { ConversationPageTemplate } from "../../components/ConversationPageTemplate";
import {
  QuestionGroup,
  Risk,
  useGetFieldsQuery,
  useGetRiskQuery,
  useUpdateRiskMutation,
} from "../../graphqlTypes";
import { flattenObject } from "../../utils";

const getDefaultValues = (
  risk: { risk: Partial<Risk> },
  questionGroups: QuestionGroup[]
) => {
  const defaultValues: { [field: string]: any } = {};

  questionGroups.forEach((questionGroup) => {
    questionGroup.questions.forEach((question) => {
      set(defaultValues, question.key, get(risk, question.key));
    });
  });

  return defaultValues;
};

type RiskProfileFactfindPageProps = {
  context?: string;
  onDone: (risk: Risk) => Promise<void>;
  title?: string;
  subTitle?: string;
  cta?: string;
};

const RiskProfileFactfindPage = ({
  context = "risk_profile",
  onDone,
  title,
  subTitle,
  cta = "Continue",
}: RiskProfileFactfindPageProps) => {
  const { userId, loggedIn } = useAuth();
  const { addToast } = useToaster();

  const [riskResult] = useGetRiskQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const [fieldsResult] = useGetFieldsQuery({
    variables: { userId: userId ?? "", context },
    pause: !loggedIn,
  });

  const [updateRiskResult, updateRisk] = useUpdateRiskMutation();

  const onChange = useMemo(
    () =>
      debounce(async (values) => {
        await updateRisk({
          input: {
            ...values,
            userId: userId ?? "",
          },
        });
      }, 300),
    [updateRisk, userId]
  );

  const onSubmit =
    (onValidationError: (errors: any) => void) => async (values: any) => {
      try {
        const result = await updateRisk({
          input: {
            ...values,
            userId: userId ?? "",
          },
        });

        if (result.data?.updateRisk.validationErrors) {
          onValidationError({
            risk: result.data?.updateRisk.validationErrors,
          });
          return;
        }

        if (!result.data?.updateRisk.success) {
          addToast({
            message:
              result.data?.updateRisk.error?.message ?? "Something went wrong",
            icon: Icon.Error,
          });
          return;
        }

        if (result.data?.updateRisk.risk) {
          onDone(result.data?.updateRisk.risk);
        }
      } catch (error) {}
    };

  const risk = riskResult?.data?.risk;
  const questionGroups = fieldsResult.data?.factfindFields.questionGroups;

  const error = riskResult.error ?? fieldsResult.error;

  const defaultValues = getDefaultValues(
    { risk: risk ?? {} },
    questionGroups ?? []
  );

  const calculatedProfile = riskResult.data?.risk?.calculatedProfile;
  const updateRiskError =
    updateRiskResult.error ?? updateRiskResult.data?.updateRisk.error;

  return (
    <ConversationPageTemplate title={title} subTitle={subTitle}>
      {error ? (
        <div className="w-full flex items-center justify-center h-[65vh]">
          <p className="text-action-error text-t12 sm:text-t18">
            {error.message}
          </p>
        </div>
      ) : risk && questionGroups ? (
        <ConversationForm
          className="my-[15vh]"
          onChange={onChange}
          onSubmit={onSubmit}
          loading={updateRiskResult.fetching}
          errors={flattenObject({
            factfind: updateRiskResult.data?.updateRisk?.validationErrors,
          })}
          defaultValues={defaultValues}
          questionGroups={questionGroups}
          renderSubmit={({ onSubmit, disabled, ref }) =>
            updateRiskResult.fetching ||
            calculatedProfile ||
            updateRiskError ? (
              <Card
                ref={ref}
                className="p-32 sm:p-40 w-11/12 sm:w-full max-w-960 min-h-[65vh] flex flex-col"
              >
                {updateRiskResult.fetching ? (
                  <Spinner />
                ) : updateRiskError ? (
                  <p className="text-t16 text-action-error">
                    {updateRiskError.message}
                  </p>
                ) : calculatedProfile ? (
                  <>
                    <p className="text-font-primary text-t16 sm:text-t21 mb-24 sm:mb-48">
                      Based on your answers we've assessed your risk profile as:
                    </p>

                    <h1 className="text-font-primary text-t21 sm:text-t27 mb-12">
                      {calculatedProfile?.name}
                    </h1>

                    <p className="text-font-secondary text-t16 sm:text-t21 mb-24 sm:mb-48">
                      {calculatedProfile?.description}
                    </p>

                    <ModalNavLink
                      to="/cross/risk-profile-options"
                      className="text-font-links hover:text-font-links-hovered text-t16 sm:text-t21 mb-16"
                    >
                      See other options
                    </ModalNavLink>

                    <Button
                      className="block self-start mt-auto"
                      onClick={onSubmit}
                      disabled={disabled}
                    >
                      {cta}
                    </Button>
                  </>
                ) : null}
              </Card>
            ) : null
          }
        />
      ) : (
        <div className="w-full flex items-center justify-center h-[65vh]">
          <Spinner />
        </div>
      )}
    </ConversationPageTemplate>
  );
};

export { RiskProfileFactfindPage };
