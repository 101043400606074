import { useGetOccupationsQuery } from "../../graphqlTypes";
import { Combobox, Spinner } from "@multiply/lib";

type InputOccupationProps = {
  value: string | null;
  onChange: (value: string) => void;
  onBlur: () => void;
  onSubmit?: () => void;
  error?: string;
  label?: React.ReactNode;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
};

const InputOccupation = ({
  onChange,
  label,
  placeholder,
  value,
  error,
  onSubmit,
  className,
  disabled,
}: InputOccupationProps) => {
  const [occupationsResult] = useGetOccupationsQuery();
  const occupations = occupationsResult?.data?.occupations;

  const items = (occupations ?? []).map((occupation) => ({
    label: occupation ?? "",
    value: occupation ?? "",
  }));

  const selectedItem = items?.find((item) => item.value === value);

  return occupationsResult.fetching ? (
    <Spinner />
  ) : (
    <Combobox
      className={className}
      value={selectedItem}
      onChange={(selectedItem) => {
        onChange(selectedItem?.value ?? "");
      }}
      onSubmit={onSubmit}
      label={label}
      placeholder={placeholder}
      error={error}
      items={items}
      disabled={disabled}
    />
  );
};

export { InputOccupation };
