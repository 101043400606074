import { useNavigate } from "react-router-dom";
import React from "react";
import {
  ArrowRightNoOutline,
  Button,
  useWindowDimensions,
} from "@multiply/lib";

type BackButtonProps = {
  backToDestination?: string | null | undefined;
} & React.ComponentPropsWithoutRef<"button">;

const BackButton = ({
  className,
  backToDestination,
  ...props
}: BackButtonProps) => {
  const navigate = useNavigate();
  const { isPhone } = useWindowDimensions();

  return (
    <Button
      iconLeft={<ArrowRightNoOutline size="sm" className="rotate-180" />}
      onClick={() =>
        backToDestination ? navigate(backToDestination) : navigate(-1)
      }
      variant="plain"
      size={isPhone ? "sm" : "md"}
      {...props}
      className={className}
    >
      Back
    </Button>
  );
};

export { BackButton };
