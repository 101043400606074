import { useAuth } from "./useAuth";

const AuthenticatedTemplate = ({ children }: { children: React.ReactNode }) => {
  const { loggedIn } = useAuth();

  if (loggedIn) {
    return <>{children}</>;
  }

  return null;
};

export { AuthenticatedTemplate };
