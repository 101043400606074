import {
  Card,
  Spinner,
  Button,
  useAuth,
  useWindowDimensions,
  ContainerIndentSize,
} from "@multiply/lib";
import { formatISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import { ModalNavLink } from "../../components";
import { InteractionFlags } from "../../globalTypes";
import { useGetGoalsQuery, useUpdateGoalsMutation } from "../../graphqlTypes";
import { GoalsFormValues, useGoalsForm, useInteractionFlag } from "../../hooks";
import { ViewGoalsForm } from "./ViewGoalsForm";
import { useMemo } from "react";

const ViewGoals = () => {
  const navigate = useNavigate();
  const { userId } = useAuth();
  const { isPhone } = useWindowDimensions();
  const completedViewGoals = useInteractionFlag(
    InteractionFlags.CompletedViewGoals
  );
  const completedGenerateLifePlan = useInteractionFlag(
    InteractionFlags.CompletedGenerateLifePlan
  );

  const [getGoalsResult, refetchGetGoals] = useGetGoalsQuery({
    variables: { userId: userId ?? "" },
  });

  const getGoalsError = getGoalsResult?.error;

  const [updateGoalsResult, updateGoals] = useUpdateGoalsMutation();
  const updateGoalsError =
    updateGoalsResult?.error || updateGoalsResult?.data?.updateGoals?.error;

  const shouldContinueToFactFind =
    !completedGenerateLifePlan.value && !completedGenerateLifePlan.loading;

  const onSubmit = async (values: GoalsFormValues) => {
    try {
      const updatedGoals = values.goals.map((goal) => ({
        goalId: goal.goalId,
        targetDate: goal.targetDate
          ? formatISO(goal.targetDate as unknown as Date, {
              representation: "date",
            })
          : null,
        valueToSave: goal.valueToSave ?? 0,
        goalType: goal.goalType,
      }));

      const result = await updateGoals({
        goals: updatedGoals,
        userId: userId ?? "",
      });

      if (result.data?.updateGoals?.success) {
        if (shouldContinueToFactFind) {
          await onDone();
        } else {
          navigate("/advice");
        }
      } else {
        refetchGetGoals({ requestPolicy: "network-only" });
      }
    } catch (error) {
      refetchGetGoals({ requestPolicy: "network-only" });
    }
  };

  const onDone = async () => {
    if (!completedViewGoals.loading && !completedViewGoals.value) {
      await completedViewGoals.update(true);
    }
    navigate("/advice/plan/financial-situation");
  };

  const defaultValues = useMemo(
    () => ({
      goals: (getGoalsResult.data?.goals ?? []).map((goal) => ({
        goalType: goal?.goalType,
        valueToSave: goal?.valueToSave?.float ?? null,
        goalId: goal?.id,
        targetDate: goal?.targetDate,
      })),
    }),
    [getGoalsResult.data?.goals]
  );

  const [
    { control, formState, handleSubmit },
    { goalsFields, handleChangeGoalIndex },
  ] = useGoalsForm({
    defaultValues,
  });

  const { isSubmitting, isSubmitted, isValid } = formState;

  return (
    <ContainerIndentSize>
      <section className="flex flex-col flex-1">
        <h1 className="text-t27 sm:text-t34 text-font-primary pb-56 font-semibold">
          Your savings goals
        </h1>
        {getGoalsResult?.data?.goals &&
        getGoalsResult?.data?.goals.length > 0 ? (
          <>
            <p className="text-t16 sm:text-t21 text-label-primary pb-16">
              Priority order
            </p>

            <ViewGoalsForm
              control={control}
              goalsFields={goalsFields}
              goals={getGoalsResult?.data?.goals}
              onChangeGoalIndex={handleChangeGoalIndex}
            />

            <div className="flex flex-col md:flex-row justify-between md:items-start sm:mt-32">
              {!completedGenerateLifePlan.value && (
                <div className="text-font-secondary text-center mb-40 md:mb-0 md:mr-12 md:text-t21 md:text-left">
                  You can always add or edit goals later.
                </div>
              )}
              <div>
                <Button
                  disabled={
                    isSubmitting ||
                    (isSubmitted && !isValid) ||
                    completedGenerateLifePlan.loading
                  }
                  onClick={handleSubmit(onSubmit)}
                  className="w-full px-48 text-t18"
                >
                  {shouldContinueToFactFind ? "Continue" : "Save"}
                </Button>
              </div>
            </div>
          </>
        ) : (
          <section className="flex flex-col flex-1 min-h-520">
            <ModalNavLink to="/cross/add-goal-type">
              <Card className="flex p-32 items-center gap-16 sm:gap-18 mb-24 h-80 sm:h-120">
                <div className="bg-action-primary h-32 sm:h-40 w-32 sm:w-40 rounded-lg flex items-center justify-center py-4 px-12">
                  <p className="text-white font-bold text-t27">+</p>
                </div>
                <p className="text-action-primary sm:text-t21 whitespace-nowrap	">
                  Add a goal
                </p>
              </Card>
            </ModalNavLink>

            <div className="flex flex-col lg:flex-row justify-between min-h-1/2 items-center lg:items-end flex-1 sm:pb-32">
              {!completedGenerateLifePlan.value && (
                <div className="text-font-secondary text-center lg:text-left text-t16 lg:text-t21 flex-auto w-full lg:w-2/3">
                  <p className="pb-16 lg:pb-24">
                    You can always add or edit goals later.
                  </p>
                </div>
              )}
              {shouldContinueToFactFind ? (
                <div className="flex justify-center lg:justify-end lg:items-center w-full lg:w-1/3 mt-16 lg:mt-0">
                  <Button
                    disabled={completedViewGoals.loading}
                    onClick={onDone}
                    className={isPhone ? "w-[80%]" : "w-[60%]"}
                  >
                    Continue
                  </Button>
                </div>
              ) : null}
            </div>
          </section>
        )}

        {getGoalsError && !getGoalsResult.fetching ? (
          <p className="text-action-error text-t16 mt-8">
            {getGoalsError.message}
          </p>
        ) : null}

        {getGoalsResult.fetching ? <Spinner data-testid="spinner" /> : null}

        {updateGoalsError ? (
          <p className="text-action-error text-t16 mt-8">
            {updateGoalsError.message}
          </p>
        ) : null}
      </section>
    </ContainerIndentSize>
  );
};

export { ViewGoals };
