import { useAuth } from "@multiply/lib";
import { useGetAssetsQuery } from "../../graphqlTypes";
import { getAssetNameFromType } from "../../utils";
import { AssetOrDebtCardGroup } from "./AssetOrDebtCardGroup";

type AssetCardGroupProps = {
  className?: string;
};

const AssetList = ({ className }: AssetCardGroupProps) => {
  const { userId, loggedIn } = useAuth();
  const [assetsResult] = useGetAssetsQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const assets = assetsResult?.data?.assets ?? [];
  const loading = assetsResult?.fetching;

  const result = assets.map((asset) => {
    return {
      balance: asset?.balance?.display,
      productType: "asset",
      name: getAssetNameFromType(asset?.productType) ?? "",
    } as const;
  });

  return (
    <AssetOrDebtCardGroup
      className={className}
      result={result}
      loading={loading}
      manageText="Manage assets"
      addText="Add an asset"
    />
  );
};

export { AssetList };
