import { yupResolver } from "@hookform/resolvers/yup";
import pick from "lodash/pick";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { DebtField } from "../../globalTypes";
import { MortgageType } from "../../graphqlTypes";
import { transformDate } from "../../utils";

export type DefaultDebtFormValues = {
  owners: string[] | null;
  balance: number | null;
};

const debtValidationFields = {
  owners: yup
    .array()
    .of(yup.string())
    .min(1, "Please provide an answer")
    .required("Please provide an answer")
    .nullable(),
  name: yup.string().nullable(),
  provider: yup.string().nullable(),
  balance: yup
    .number()
    .min(0, "Amount must be at least 0")
    .required("Please provide an answer")
    .nullable(),
  monthlyRepayment: yup
    .number()
    .min(0, "Amount must be at least 0")
    .required("Please provide an answer")
    .nullable(),
  clearedOnDeath: yup.boolean().required("Please provide an answer").nullable(),
  repaidMonthly: yup.boolean().required("Please provide an answer").nullable(),
  isMainMortgage: yup.boolean().required("Please provide an answer").nullable(),
  arePaymentsUpToDate: yup
    .boolean()
    .required("Please provide an answer")
    .nullable(),
  maturityDate: yup
    .date()
    .transform((value, originalValue) => transformDate(originalValue))
    .typeError('Please enter a valid date in the format "DD-MM-YYYY"')
    .min(new Date(), "The maturity date must be in the future")
    .required("Please provide an answer")
    .nullable(),
  endDate: yup
    .date()
    .transform((value, originalValue) => transformDate(originalValue))
    .typeError('Please enter a valid date in the format "DD-MM-YYYY"')
    .min(new Date(), "The end date must be in the future")
    .required("Please provide a target date")
    .nullable(),
  mortgageType: yup
    .string()
    .oneOf(Object.values(MortgageType))
    .required("Please provide an answer")
    .nullable(),
  mortgageYearsLeft: yup
    .number()
    .min(0, "Amount must be at least 0")
    .required("Please provide an answer")
    .nullable(),
  hasPaymentProtectionInsurance: yup
    .boolean()
    .required("Please provide an answer")
    .nullable(),
  arrears: yup
    .number()
    .nullable()
    .when("arePaymentsUpToDate", {
      is: (arePaymentsUpToDate: any) => arePaymentsUpToDate === false,
      then: yup
        .number()
        .min(0, "Amount must be at least 0")
        .required("Please provide an answer")
        .nullable(),
    }),
};

type UseDebtFormArgs = {
  fields: DebtField[];
  defaultValues?: any;
};

const useDebtForm = ({ fields, defaultValues }: UseDebtFormArgs) => {
  const fieldNames = fields.map((field) =>
    typeof field === "string" ? field : field.fieldName
  );

  const validationSchema = useMemo(() => {
    const validationFields = fieldNames.reduce((validators, fieldName) => {
      return { ...validators, [fieldName]: debtValidationFields[fieldName] };
    }, {});

    return yup.object().shape(validationFields).defined();
  }, [fieldNames]);

  return useForm({
    delayError: 1000,
    mode: "onChange",
    defaultValues: pick(defaultValues, fieldNames),
    resolver: yupResolver(validationSchema),
  });
};

export { useDebtForm };
