import classNames from "classnames";
import { ReactElement, ReactNode } from "react";
import { RadioInput } from "../RadioInput";

type Variant =
  | "stacked"
  | "wrapped"
  | "stacked-end"
  | "stacked-full"
  | "wrapped-end";

type RadioInputGroupProps<
  ValueType extends string | number | boolean | undefined
> = {
  value: ValueType;
  onChange: (value: ValueType) => void;
  onSubmit?: () => void;
  options: Array<{
    label: string | ReactElement;
    value: ValueType;
  }>;
  label?: ReactNode;
  className?: string;
  variant?: Variant | "auto" | "auto-end";
  disabled?: boolean;
  error?: string;
} & Pick<
  React.ComponentProps<"input">,
  "aria-label" | "aria-labelledby" | "onBlur"
>;

function RadioInputGroup<
  ValueType extends string | number | boolean | undefined
>({
  value,
  onChange,
  onBlur,
  label,
  options,
  className,
  "aria-labelledby": ariaLabelledBy,
  "aria-label": ariaLabel,
  variant: defaultVariant = "auto",
  disabled,
  error,
  onSubmit,
}: RadioInputGroupProps<ValueType>) {
  const handleChange = (newValue: ValueType) => () => {
    onChange && onChange(newValue);
    onSubmit && onSubmit();
  };

  const getVariant = (variant: Variant | "auto" | "auto-end"): Variant => {
    switch (variant) {
      case "auto":
        return options.length > 4 ? "wrapped" : "stacked";
      case "auto-end":
        return options.length > 4 ? "wrapped-end" : "stacked-end";
      default:
        return variant;
    }
  };

  const variant = getVariant(defaultVariant);

  const getVariantClasses = (variant: Variant) => {
    switch (variant) {
      case "stacked":
        return "flex-col";
      case "wrapped":
        return "flex-wrap";
      case "stacked-end":
        return "flex-col items-end";
      case "stacked-full":
        return "flex-col items-stretch";
      case "wrapped-end":
        return "flex-wrap justify-end";
    }
  };

  return (
      <div
        role="radiogroup"
        aria-labelledby={ariaLabelledBy}
        aria-label={ariaLabel}
        className={classNames(
          "flex gap-12 w-full",
          getVariantClasses(variant),
          className
          )}
          >
        {label && <legend className="mb-4">{label}</legend>}
        {options.map((option) => {
          const checked = value === option.value;

          return (
            <RadioInput
              disabled={disabled}
              role="radio"
              aria-checked={checked}
              key={String(option.value)}
              onChange={handleChange(option.value)}
              onBlur={onBlur}
              value={option.value}
              checked={checked}
              className={`text-t16 h-fit ${
                variant.includes("full") ? "w-full" : "w-fit"
              } ${variant.includes("stacked") ? "flex-1" : ""}`}
            >
              <p>{option.label}</p>
            </RadioInput>
          );
        })}
        {error && <p className="text-action-error text-t16 mt-12">{error}</p>}
      </div>
  );
}

export { RadioInputGroup };
