import {
  PinChildEducationIcon,
  PinHomeIcon,
  PinPiggyBankIcon,
  PinWeddingIcon,
  PinRetirementIcon,
  useWindowDimensions,
} from "@multiply/lib";
import { SVGProps, FC } from "react";
import { GoalType } from "../../graphqlTypes";

const BUBBLE_WIDTH = 46;
const BUBBLE_HEIGHT = 58;
const SHIFT_X = +10;
const SHIFT_Y = -20;

const IconBubble: FC<SVGProps<SVGSVGElement>> = (props: any) => {
  const { x, y, offset, goalType } = props;

  const { isPhone } = useWindowDimensions();
  let scaleValue = isPhone ? 1 : 1.3;

  let width = BUBBLE_WIDTH * scaleValue;
  let height = BUBBLE_HEIGHT * scaleValue;

  const xOrigin = x - width / 2 + offset * SHIFT_X;
  const yOrigin = y - height + 4 + offset * SHIFT_Y;
  return (
    <g transform={`translate(${xOrigin},${yOrigin}) scale(${scaleValue})`}>
      {goalType === GoalType.BuyHome ? (
        <PinHomeIcon variant="green" size="xl" />
      ) : goalType === GoalType.Wedding ? (
        <PinWeddingIcon variant="green" size="xl" />
      ) : goalType === GoalType.ChildEducation ? (
        <PinChildEducationIcon variant="green" size="xl" />
      ) : goalType === GoalType.Custom ? (
        <PinPiggyBankIcon variant="green" size="xl" />
      ) : goalType === "RETIREMENT" ? (
        <PinRetirementIcon variant="green" size="xl" />
      ) : null}
    </g>
  );
};

export { IconBubble };
