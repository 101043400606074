type MinusProps = {
  className?: string;
  onClick: () => void;
};

const Minus = ({ className, onClick }: MinusProps) => {
  return (
    <div onClick={onClick}>
      <svg
        width="49"
        height="49"
        viewBox="0 0 49 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M0.289062 24.9736C0.289062 11.7188 11.0342 0.973633 24.2891 0.973633H48.2891V48.9736H24.2891C11.0342 48.9736 0.289062 38.2285 0.289062 24.9736V24.9736Z"
          fill="#F1F3FD"
        />
        <path
          d="M31.2891 24.9736H17.2891"
          stroke="#717FE2"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Minus;
