const STATE_RETIREMENT_AGE = 66;

const bulletSections = [
  {
    title: "Assumptions",
    bullets: [
      "You won't withdraw your maximum allowance as a lump sum in retirement.",
      "Your other income and assets are not taken into account in this tool.",
      "Your pension projection is based on your selected retirement age.",
      "Regular contributions are assumed to increase by 3% over the term of your plan.",
      "Your plan has a fund management charge of 19 per annum and a contribution charge of 3.5% of each contribution.",
      "The projected values assume an investment return before retirement of 2.04% per annum.",
      "This rate is for illustrative purposes only and is not guaranteed. Actual investment growth will depend on the performance of the underlying investments and may be more or less than illustrated.",
      "For any pension arrangements included that are with other providers, the projected values assume contributions are invested in a Personal Pension with a fund management charge of 1%, and a contribution charge of 5% on each contribution. The projected growth on any such arrangements is 4.05% each year until your chosen retirement date. The projected values do not take account of any bonuses or special features that may be included with such arrangements. These arrangements have been included for illustrative purposes only and should not be relied upon. Please refer to your plan documentation for a more accurate illustration.",
      "An inflation rate of 2% per annum is used to express future values in today's terms.",
      "The estimated annuities quoted are payable monthly in advance and assumes that a retirement lump sum of 259 of the value of the pension is taken. The guaranteed period is 5 years, so in the event of early death during these five years, the income will continue to be paid for the balance of this period.",
      "Annuity payments increase by 2% per annum.",
      "The annuity rate at your retirement date will depend on long-term interest rates and life expectancy assumptions at that time and is likely to be different from the annuity rate us in the illustration.",
      "Different annuity options can be chosen at retirement.",
      `Under the Finance Act 2012, the age at which people qualify for the state pension is ${STATE_RETIREMENT_AGE} year of age.`,
      "The state pension (Contributory] for a single person is €13,171 per annum as of January 2023.",
      "We have made the following assumptions in relation to other sources of income, where applicable, as outlined below:",
    ],
  },
  {
    title: "Tax relief",
    bullets: [
      "Tax relief is not guaranteed. Rates are current as of January 2022.",
      "To claim tax relief you can apply to your inspector of taxes to adjust your tax credits.",
      "Contributions deducted from salary will receive immediate tax relief. To be eligible to claim income tax relief, your income must be taxable either Schedule E or Schedule D (Case I or",
      "If you are self-employed, you must include your pension contributions in your self-assessment tax returns in order to obtain income tax relief.",
      "Any employer contributions will receive tax relief in the year the contribution is made (subject to limits).",
      "Pension income in retirement is subject to income tax on withdrawal, Universal Social Charge, PRSI (if applicable) and any other charges or levies ('tax') applicable at this time.",
    ],
  },
];

export { bulletSections, STATE_RETIREMENT_AGE };
