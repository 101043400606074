import classNames from "classnames";
import { Outlet } from "react-router-dom";
import { useIsMIL } from "../../utils";
import { Sidebar } from "../Sidebar";

const MILLink = ({ className }: { className?: string }) => (
  <a
    href={process.env.REACT_APP_MY_IRISH_LIFE_URI}
    className={classNames(
      "text-[#5E6CDA] font-semibold text-t16 sm:text-t21 flex items-center",
      className
    )}
  >
    &#10094;&ensp;Back to MyIrishLife
  </a>
);

const SidebarLayout = () => {
  const isMIL = useIsMIL();

  return (
    <div className="flex flex-col lg:flex-row">
      {isMIL && <MILLink className="m-16 sm:hidden" />}
      <Sidebar className="lg:sticky lg:top-0 shrink-0" />
      <main className="min-h-screen flex-1 min-w-0">
        {isMIL && (
          <MILLink className="px-max-screen lg:px-max-screen-lg mt-24 hidden sm:block" />
        )}
        <Outlet />
      </main>
    </div>
  );
};

export { SidebarLayout };
