import { Card } from "@multiply/lib";
import { VictoryBar, VictoryStack } from "victory";
import { EggChart, ModalNavLink } from "../../components";
import { BreakdownSlice, Money } from "../../graphqlTypes";
import { CURRENCY_SYMBOL } from "../../utils";

type ThisMeansPensionsProps = {
  currentProvision: Money;
  targetProvision: Money;
  realisticProvision: Money;
  breakdown: BreakdownSlice[];
};

const ThisMeansPensions = ({
  currentProvision,
  targetProvision,
  realisticProvision,
  breakdown,
}: ThisMeansPensionsProps) => {
  const percentage = (100 * currentProvision.float) / targetProvision?.float;
  let barChartData = [percentage, 100 - percentage];

  if (currentProvision.float >= targetProvision.float) {
    barChartData = [100, 0];
  }

  if (percentage <= 0) {
    barChartData = [0, 100];
  }

  return (
    <Card className="px-18 py-32 sm:p-48">
      <section className="border-b-2 border-background-primary pb-16 sm:pb-48 text-t16 sm:text-t27">
        <p className="text-t16 sm:text-t27 text-font-primary font-bold mb-8">
          At your current contribution rate
        </p>
        <p className="text-font-primary text-t14 sm:text-t21 mb-16 sm:mb-24">
          You will receive {currentProvision.display} per month
        </p>
        <VictoryStack
          padding={{ top: 0, bottom: 0, right: 0, left: 0 }}
          height={20}
          horizontal
          colorScale={["#5261AC", "#F1F3FF"]}
        >
          {barChartData.map((portion) => (
            <VictoryBar
              key={portion}
              barRatio={1}
              data={[{ x: "a", y: portion }]}
            />
          ))}
        </VictoryStack>
        <p className="text-right text-font-primary text-t14 sm:text-t21 mt-16 sm:mt-24">
          Your target is {targetProvision?.display ?? `${CURRENCY_SYMBOL}0`} per
          month
        </p>
      </section>

      <section className="xl:flex mt-24 sm:mt-40">
        <div className="w-full xl:max-w-1/3 mr-40">
          <p className="text-t16 text-font-primary mb-8 sm:text-t27 font-bold">
            By following your plan
          </p>
          <p className="text-font-primary mb-16 sm:mb-24 text-t14 sm:text-t21">
            You could realistically expect to receive{" "}
            {realisticProvision.display} per month
          </p>
        </div>
        <EggChart slices={breakdown} />
      </section>

      <div className="flex">
        <p className="font-bold text-t10 sm:text-t16 text-font-tertiary mt-18 pr-8">
          These estimated figures are based on today's money and assumed rates.
          Find out more about{" "}
          <ModalNavLink to="/retirement-graph" className="text-font-links">
            our assumptions here
          </ModalNavLink>{" "}
          These figures are estimates only. They are not a reliable guide to the
          future performance of your investment.
        </p>
      </div>
    </Card>
  );
};

export { ThisMeansPensions };
