import {
  CalendarIcon,
  GridIcon,
  HouseIcon,
  MenuLink,
  SlidersIcon,
} from "@multiply/lib";

const HolisticNav = () => {
  return (
    <>
      <MenuLink isPill aria-label="go to plan page" to="/advice">
        <HouseIcon className="hidden lg:block h-auto w-24 lg:w-32" />
        <span>My plan</span>
      </MenuLink>

      <MenuLink isPill aria-label="go to products page" to="/products">
        <GridIcon className="hidden lg:block h-auto w-24 lg:w-32" />
        <span>My products</span>
      </MenuLink>

      <MenuLink isPill aria-label="go to tools page" to="/tools">
        <SlidersIcon
          className="hidden lg:block h-auto w-24 lg:w-32"
          aria-label="tools icon"
        />
        <span>Tools</span>
      </MenuLink>

      <MenuLink isPill aria-label="go to appointments page" to="/appointments">
        <CalendarIcon className="hidden lg:block h-auto w-24 lg:w-32" />
        <span>Appointments</span>
      </MenuLink>
    </>
  );
};

export { HolisticNav };
