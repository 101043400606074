import { Button, Card, Spinner, useAuth } from "@multiply/lib";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PensionPortfolioCard } from "../../components";
import {
  RecommendationType,
  RetirementSummary,
  useGetPlanRecommendationsQuery,
} from "../../graphqlTypes";
import classNames from "classnames";
import { useInteractionFlag } from "../../hooks";
import { InteractionFlags } from "../../globalTypes";

type ConfirmInvestmentFundProps = {
  to: string;
  className?: string;
  canRecommend?: boolean;
};
const ConfirmInvestmentFund = ({
  to,
  className,
  canRecommend = true,
}: ConfirmInvestmentFundProps) => {
  const { userId, loggedIn } = useAuth();
  const [planResult] = useGetPlanRecommendationsQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });
  const { redirectPage } = useParams();
  let toWithRedirect = to;

  const navigate = useNavigate();

  if (redirectPage !== undefined) toWithRedirect += `/${redirectPage}`;

  const recommendationGroups =
    planResult?.data?.plan?.recommendationGroups ?? [];

  const retirementRecommendation = recommendationGroups.find(
    (group) => group.recommendationType === RecommendationType.Retirement
  );

  const summary = retirementRecommendation?.summary as
    | RetirementSummary
    | null
    | undefined;

  const recommendedFunds = summary?.prsaRecommendedFund ?? [];
  const noRiskProfile = false;
  const error = planResult?.error;
  const loading = planResult?.fetching;

  const completedAcceptPRSAInvestmentFund = useInteractionFlag(
    InteractionFlags.CompletedPensionsOnboarding
  );

  const handleConfirm = async () => {
    if (!completedAcceptPRSAInvestmentFund.value)
      await completedAcceptPRSAInvestmentFund.update(true);

    navigate(toWithRedirect);
  };

  const WarningBlock = ({ text }: { text: string }) => {
    return (
      <div className="border-2 border-font-primary px-28 py-24 w-full mt-16">
        <p className="text-font-primary font-bold">Warning: {text}</p>
      </div>
    );
  };

  return (
    <div
      className={classNames(
        "flex flex-col items-center justify-center",
        className
      )}
    >
      <h1 className="text-font-primary text-t21 sm:text-t32 font-semibold pb-8">
        Your investment fund
      </h1>
      {completedAcceptPRSAInvestmentFund.value === null && canRecommend && (
        <p className="text-t16 text-font-secondary px-20 text-center pb-24">
          If you were to proceed with a PRSA this would be your fund
          recommendation.
        </p>
      )}
      {error ? (
        <p className="text-action-error">
          Something went wrong, please try again later
        </p>
      ) : noRiskProfile ? (
        <p className="text-brand-secondary block">
          You need to complete the risk questionnaire before we can recommend an
          investment fund for you.
        </p>
      ) : (
        <>
          <Card className="p-18 sm:py-36 sm:px-53 rounded-xl mt-28 mx-18 flex flex-col gap-12">
            {canRecommend && (
              <p className="text-brand-secondary block mb-20">
                Based on your risk profile, we recommend you invest your monthly
                contributions into:
              </p>
            )}

            {recommendedFunds?.length > 0 && !loading
              ? recommendedFunds.map((rec) => (
                  <PensionPortfolioCard recommendedFund={rec.fund} />
                ))
              : null}

            {loading && <Spinner className="mt-24" />}
          </Card>

          <Button
            onClick={handleConfirm}
            className="mt-24 sm:mt-53 text-t18"
            size="sm"
          >
            {completedAcceptPRSAInvestmentFund.value
              ? "Continue"
              : "Accept recommendation"}
          </Button>

          <Link
            to="/pensions/appointments"
            className="text-t18 mt-28 text-font-links pb-22"
          >
            Speak to an adviser
          </Link>

          <div className="mx-18 sm:max-w-[60%] pt-40">
            <p className="text-font-primary pb-24">
              Please see your Fund guide / Product booklet for information on{" "}
              {canRecommend ? "the" : "our"} investment strategies.{" "}
              {canRecommend
                ? "This fund recommendation includes the recommendation to fund for an annuity through the Annuity Lifestyle Option."
                : ""}
            </p>
            <WarningBlock text="The value of your investment may go down as well as up." />
            <WarningBlock text="If you invest in this product you may lose some or all of the money you invest." />
            <WarningBlock text=" If you invest in this product you will not have any access to your money until age 60 and/or you retire." />
            <WarningBlock text="These funds may be affected by changes in currency exchange rates." />
          </div>
        </>
      )}
    </div>
  );
};

export { ConfirmInvestmentFund };
