import EventEmitter from "eventemitter3";
import { AuthService, Events } from "../types";
import {
  AuthenticationResult,
  AuthError,
  BrowserAuthError,
  Configuration,
  LogLevel,
  PublicClientApplication,
} from "@azure/msal-browser";
import { hasOwnProperty } from "../../utils";

const loggerCallback = (
  level: LogLevel,
  message: string,
  containsPii: boolean
) => {
  if (containsPii) {
    return;
  }
  switch (level) {
    case LogLevel.Error:
      console.error(message);
      return;
    case LogLevel.Info:
      console.info(message);
      return;
    case LogLevel.Verbose:
      console.debug(message);
      return;
    case LogLevel.Warning:
      console.warn(message);
      return;
  }
};

type CreateServiceArgs = {
  clientId: string;
  baseAuthority: string;
  defaultAuthority: string;
  signInAuthority: string;
  passwordResetAuthority: string;
  captureException?: (exception: any) => void;
};

const createAzureAuthService = ({
  clientId,
  baseAuthority,
  defaultAuthority,
  signInAuthority,
  passwordResetAuthority,
  captureException = () => {},
}: CreateServiceArgs) => {
  const configuration: Configuration = {
    auth: {
      clientId: clientId,
      authority: baseAuthority,
      knownAuthorities: [defaultAuthority, passwordResetAuthority],
    },
    system: {
      loggerOptions: {
        loggerCallback,
      },
    },
  };

  const msalInstance = new PublicClientApplication(configuration);

  const eventEmitter = new EventEmitter<Events>();

  const loginWithPopup: AuthService["loginWithPopup"] = async () => {
    const loginResult = await msalInstance.loginPopup({
      authority: defaultAuthority,
      redirectUri: `${window.location.origin}/blank.html`,
      scopes: [],
    });

    const authState = {
      userId: loginResult?.uniqueId,
      token: loginResult?.idToken,
      refreshToken: null,
      username: (loginResult?.idTokenClaims as any).email ?? null,
    };

    eventEmitter.emit("authStateChange", authState);
    return authState;
  };

  const loginWithRedirect: AuthService["loginWithRedirect"] = async () => {
    try {
      await msalInstance.loginRedirect({
        redirectUri: window.location.origin,
        scopes: [],
        authority: defaultAuthority,
        state: JSON.stringify({
          postLoginRedirectUri: window.location.href,
        }),
      });
    } catch (error) {
      if (error instanceof BrowserAuthError) {
        if (error.errorCode === "interaction_in_progress") {
          if (handleRedirectResult) {
            await handleRedirectResult();
          }
          if (loginWithRedirect) {
            return loginWithRedirect();
          }
        }
      }
      throw error;
    }
  };

  const changePasswordWithRedirect: AuthService["changePasswordWithRedirect"] =
    async () => {
      await msalInstance.loginRedirect({
        redirectUri: window.location.origin,
        authority: passwordResetAuthority,
        scopes: [],
      });
    };

  const handleRedirectResult: AuthService["handleRedirectResult"] =
    async () => {
      let loginResult: AuthenticationResult | null = null;

      try {
        loginResult = await msalInstance.handleRedirectPromise();
      } catch (error) {
        if (error instanceof AuthError) {
          if (error?.errorMessage.includes("AADB2C90118")) {
            // RESET PASSWORD
            await msalInstance.loginRedirect({
              redirectUri: window.location.origin,
              scopes: [],
              authority: passwordResetAuthority,
            });
          } else if (error?.errorMessage.includes("AADB2C90091")) {
            // CANCEL SIGN UP FLOW
            await msalInstance.loginRedirect({
              redirectUri: window.location.origin,
              scopes: [],
              authority: signInAuthority,
            });
          } else {
            captureException(error);
            await logOut();
          }
        } else {
          throw error;
        }
      }

      if (loginResult !== null) {
        let postLoginRedirectUri: string | null = null;

        try {
          const parsedState = JSON.parse(loginResult?.state ?? "{}") as unknown;

          if (
            hasOwnProperty(parsedState, "postLoginRedirectUri") &&
            typeof parsedState?.postLoginRedirectUri === "string"
          ) {
            postLoginRedirectUri = parsedState?.postLoginRedirectUri;
          }
        } catch (error) {
          captureException(error);
        }

        const authState = {
          userId: loginResult?.uniqueId,
          token: loginResult?.idToken,
          refreshToken: null,
          username: (loginResult?.idTokenClaims as any).email ?? null,
          postLoginRedirectUri,
        };

        eventEmitter.emit("authStateChange", authState);
        return authState;
      }

      return null;
    };

  const logOut: AuthService["logOut"] = async (redirectUri) => {
    await msalInstance.logoutRedirect({
      authority: defaultAuthority,
      postLogoutRedirectUri: redirectUri ?? window.location.origin,
    });

    const authState = {
      userId: null,
      token: null,
      refreshToken: null,
      username: null,
    };
    eventEmitter.emit("authStateChange", authState);
  };

  const refreshJWT: AuthService["refreshJWT"] = async () => {
    try {
      await restoreSession();
    } catch (e) {
      console.error("Error refreshing JWT", e);
    }
  };

  const onAuthStateChange: AuthService["onAuthStateChange"] = (
    authStateChangeHandler
  ) => {
    eventEmitter.on("authStateChange", authStateChangeHandler);

    const unsubscribe = () =>
      eventEmitter.off("authStateChange", authStateChangeHandler);

    return () => {
      unsubscribe();
    };
  };

  const unsubscribeAll = () => {
    eventEmitter.removeAllListeners("authStateChange");
  };

  const restoreSession: AuthService["restoreSession"] = async () => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length > 0) {
      try {
        const loginResult = await msalInstance.acquireTokenSilent({
          authority: defaultAuthority,
          account: accounts[0],
          scopes: [],
        });

        const authState = {
          userId: loginResult?.uniqueId,
          token: loginResult?.idToken,
          refreshToken: null,
          username: (loginResult?.idTokenClaims as any).email ?? null,
        };

        eventEmitter.emit("authStateChange", authState);
        return authState;
      } catch (error) {
        if (error instanceof AuthError) {
          // grant expired
          if (error.errorMessage.includes("AADB2C90080")) {
            await logOut();
            return {
              userId: null,
              token: null,
              refreshToken: null,
              username: null,
            };
          }
        }
        captureException(error);
      }
    }

    return {
      userId: null,
      token: null,
      refreshToken: null,
      username: null,
    };
  };

  return {
    loginWithPopup,
    loginWithRedirect,
    changePasswordWithRedirect,
    handleRedirectResult,
    logOut,
    restoreSession,
    refreshJWT,
    onAuthStateChange,
    unsubscribeAll,
  };
};

export { createAzureAuthService, loggerCallback };
