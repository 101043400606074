import { Spinner, useAuth, useToaster } from "@multiply/lib";
import { useNavigate, useParams } from "react-router-dom";
import { Card, ContainerMidSize } from "@multiply/lib";
import {
  AssetInput,
  AssetType,
  Relationship,
  useGetAssetQuery,
  useGetPersonsQuery,
  useUpdateAssetMutation,
} from "../../graphqlTypes";
import { ModalNavLink } from "../../components";
import { AssetFieldName } from "../../globalTypes";
import { getAssetFieldsFromType } from "../UpdateAsset/getAssetFieldsFromType";
import pick from "lodash/pick";
import { FocusedPensionForm } from "./FocusedPensionForm";

const UpdatePension = () => {
  const { pensionId } = useParams();
  const { userId } = useAuth();
  const { addToast } = useToaster();
  const navigate = useNavigate();

  const [getPensionResult] = useGetAssetQuery({
    variables: {
      userId: userId ?? "",
      assetId: pensionId ?? "",
    },
  });

  const pension = getPensionResult?.data?.asset;

  const [personsResult] = useGetPersonsQuery({
    variables: { userId: userId ?? "" },
  });

  const user = personsResult.data?.persons?.find(
    (person) => person.relationship === Relationship.User
  );

  const [updateAssetResult, updateAsset] = useUpdateAssetMutation();

  const handleUpdateAsset = async (values: any) => {
    if (pension) {
      const fieldNames = getAssetFieldsFromType(AssetType.Pension)
        .map((field) => (typeof field === "string" ? field : field.fieldName))
        .filter((field) => field !== "isOverdrawn");

      const { provider, ...filteredValues } = pick(values, fieldNames);

      const newAsset: AssetInput = {
        productType: AssetType.Pension,
        ...filteredValues,
        owners: [user?.id as string],
      };

      if (typeof provider === "string") {
        newAsset.providerName = provider;
      }

      try {
        const result = await updateAsset({
          input: {
            userId: userId ?? "",
            assetId: pensionId ?? "",
            asset: newAsset,
          },
        });

        if (result.data?.updateAsset?.success) {
          navigate("/pensions");
        }
      } catch (error) {}

      addToast({ message: "Success" });
    }
  };

  const updateMutationError =
    updateAssetResult?.error || updateAssetResult?.data?.updateAsset?.error;

  const defaultValues: { [fieldName in AssetFieldName]: any } = {
    owners: [user?.id] ?? [],
    provider: pension?.provider?.name ?? null,
    isOverdrawn: null,
    balance: pension?.balance?.float,
    name: pension?.name ?? null,
    accessible: null,
    overdrawnBy: null,
    maturityDate: null,
    startDate: null,
    increasing: pension?.increasing ?? null,
    regularPaymentAmount: pension?.regularPaymentAmount?.float ?? null,
    valuationMethod: pension?.valuationMethod ?? null,
    whereFrom: null,
    propertyType: null,
    pensionType: pension?.pensionType ?? null,
    frequency: null,
    active: pension?.active ?? null,
    throughCurrentEmployer: pension?.throughCurrentEmployer ?? null,
    isIrishLifePension: pension?.isIrishLifePension ?? null,
  };

  return (
    <ContainerMidSize>
      <div className="flex-1 flex flex-col items-center justify-center">
        <Card className="w-full p-32 pb-18 flex flex-col items-center">
          {getPensionResult.fetching ? (
            <Spinner />
          ) : !pension ? (
            <p className="text-t21">Pension not found</p>
          ) : (
            <>
              <FocusedPensionForm
                onSubmit={handleUpdateAsset}
                defaultValues={defaultValues}
              />
              {pensionId && (
                <ModalNavLink
                  to={`/cross/delete-asset/${pensionId}`}
                  className="w-240 text-action-error text-center py-18 mt-8"
                >
                  Delete
                </ModalNavLink>
              )}
            </>
          )}
          {updateMutationError && (
            <p className="mt-12 text-action-error text-t16">
              {updateMutationError.message}
            </p>
          )}
        </Card>
      </div>
    </ContainerMidSize>
  );
};

export { UpdatePension };
