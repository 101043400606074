import { ArrowRightNoOutline, Button } from "@multiply/lib";
import { useNavigate } from "react-router-dom";
import team_adviser from "../../assets/images/intro-team.png";
import { OnboardingIntroContainer } from "../../components";
import { motion } from "framer-motion";

const TeamIntro = () => {
  const navigate = useNavigate();

  const variants = {
    hidden: { opacity: 0, x: 1000, y: 0 },
    enter: { opacity: 1, x: 0, y: 0 },
  };

  return (
    <div className="bg-brand-primary">
      <motion.main
        variants={variants}
        initial="hidden"
        animate="enter"
        transition={{ type: "linear" }}
      >
        <OnboardingIntroContainer>
          <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 3 }}
            className="flex-1 flex flex-col items-center justify-center px-20"
          >
            <h1 className="text-t27 md:text-t57 text-white font-bold text-center">
              Our advice team is here <br /> whenever you need
            </h1>
            <motion.div
              animate={{ y: [0, -10, 0] }}
              transition={{
                repeat: Infinity,
                duration: 1.5,
                ease: "easeInOut",
              }}
              style={{ display: "inline-block" }}
            >
              <div className="max-w-460 my-20">
                <img
                  src={team_adviser}
                  aria-label="team"
                  alt="team"
                  height="auto"
                />
              </div>
            </motion.div>
            <h2 className="text-white text-t15 md:text-t23 md:w-[50%] text-center">
              You’ll get your advice online but our advisers know the ins and
              outs of all our plans. They’re always on hand if you have any
              questions.
            </h2>
          </motion.section>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2, delay: 3 }}
            className="flex justify-center pb-48 md:pb-88"
          >
            <Button
              className="text-center"
              variant="secondary"
              iconRight={<ArrowRightNoOutline size="sm" />}
              onClick={() => navigate("/pick-a-plan")}
            >
              Get started
            </Button>
          </motion.div>
        </OnboardingIntroContainer>
      </motion.main>
    </div>
  );
};

export { TeamIntro };
