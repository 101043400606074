import classNames from "classnames";

type SectionHeadingProps = {
  title?: string;
  subtitle?: string;
  className?: string;
};

const SectionHeading = ({
  title,
  subtitle,
  className,
}: SectionHeadingProps) => {
  return (
    <div className={classNames("mb-14 sm:mb-20", className)}>
      <h1 className="text-t21 sm:text-t27 lg:text-t32 text-font-primary font-semibold mb-13">
        {title}
      </h1>

      <h2 className="text-t15 sm:text-t16 lg:text-t18 text-font-secondary">
        {subtitle}
      </h2>
    </div>
  );
};

export { SectionHeading };
