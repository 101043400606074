import { useNavigate } from "react-router-dom";
import { RiskProfileFactfindPage } from "../RiskProfileFactFind";
import { Risk } from "../../graphqlTypes";
import { InteractionFlags } from "../../globalTypes";
import { useInteractionFlag } from "../../hooks";

const PensionsRiskProfileFactFind = () => {
  const navigate = useNavigate();

  const completedPensionsRiskProfile = useInteractionFlag(
    InteractionFlags.CompletedPensionsRisk
  );

  const onDone = async (risk: Risk) => {
    if (!completedPensionsRiskProfile.value) {
      await completedPensionsRiskProfile.update(true);
    }
    navigate(`/pensions/accept-investment-fund`);
  };

  return (
    <div className="mt-120">
      <RiskProfileFactfindPage
        onDone={onDone}
        title="Finally, please tell us what type of investor you are."
        subTitle="This helps us to give you accurate advice."
        cta="Get my pension advice"
      />
    </div>
  );
};

export { PensionsRiskProfileFactFind };
