import classNames from "classnames";

const OnboardingIntroContainer = ({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={classNames(
        "min-h-screen flex flex-col bg-gradient-to-r from-[#424fcf] to-[#2e2684] via-[#605cdb] animate-gradient-x",
        className
      )}
      style={{
        backgroundSize: "200% 200%",
        backgroundPosition: "left center",
        animationDuration: "15s",
        animationTimingFunction: "ease",
        animationIterationCount: "infinite",
      }}
    >
      {children}
    </div>
  );
};

export { OnboardingIntroContainer };
