import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";
import {
  IconProps,
  getDimensionsFromSize,
  getColorFromVariant,
} from "./shared";

const ArrowRightNoOutline: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);

  return (
    <svg
      width={width}
      height={height}
      aria-hidden={true}
      viewBox="0 0 16 16"
      className={classNames(defaultClassnames, className)}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.71 8.472a1.005 1.005 0 0 0 0-1.422L9.313.652a1.005 1.005 0 1 0-1.421 1.422l5.687 5.687-5.687 5.688a1.005 1.005 0 1 0 1.421 1.422l6.399-6.399ZM0 8.767h15V6.756H0v2.01Z"
        fill={fill}
      />
    </svg>
  );
};

export { ArrowRightNoOutline };
