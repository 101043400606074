import { TrackedLink, BookOutlineIcon } from "@multiply/lib";
import { UrlBlock } from "../../graphqlTypes";

type UrlSectionProps = {
  contentBlock: UrlBlock;
};

const UrlSection = ({ contentBlock }: UrlSectionProps) => {
  return (
    <div className="mt-24">
      <h4 className="text-action-primary text-t21 mb-16">
        {contentBlock.title}
      </h4>

      <div className="flex flex-col gap-8">
        {contentBlock.links?.map((link, index) => (
          <TrackedLink
            key={index}
            href={link?.url}
            className="text-font-links text-t21 flex items-center"
            rel="noreferrer"
            target="_blank"
          >
            <BookOutlineIcon className="mr-12" />
            {link?.text}
          </TrackedLink>
        ))}
      </div>
    </div>
  );
};

export { UrlSection };
