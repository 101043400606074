const PensionsExplanation = () => {
  return (
    <section className="w-full h-full">
      <h1 className="text-t21 sm:text-t27 text-font-primary font-semibold  pt-48 pb-20 text-center">
        Pension types explained
      </h1>

      <h2 className="text-t18 font-semibold text-font-primary pb-4">
        Personal Retirement Savings Account (PRSA)
      </h2>
      <p className="text-t15 font-normal text-font-primary pb-24">
        A type of pension that's set up and managed by yourself, rather than an
        employer. You'll likely save into a PRSA if you're self-employed, or
        transfer into one to take your old pension fund with you when you change
        jobs.
      </p>

      <h2 className="text-t18 font-semibold text-font-primary pb-4">
        Company (Defined Contribution)
      </h2>
      <p className="text-t15 font-normal text-font-primary pb-24">
        A type of pension that's set up by your employer, who usually makes
        contributions on behalf of you. In addition, you'll usually contribute a
        set portion of your salary too. Typically, the money you save is
        invested into the markets, to grow and build up a fund you can live on
        in retirement.
      </p>

      <h2 className="text-t18 font-semibold text-font-primary pb-4">
        Company (Defined Benefit)
      </h2>
      <p className="text-t15 font-normal text-font-primary pb-24">
        A type of pension that's set up by your employer, who gives you a
        pension when you retire. The amount you receive is based upon your
        length of service and your salary, as defined in the scheme rules.
      </p>

      <h2 className="text-t18 font-semibold text-font-primary pb-4">
        Additional Voluntary Contribution (AVC)
      </h2>
      <p className="text-t15 font-normal text-font-primary pb-24">
        A type of pension that allows you to save additional funds into your
        company pension for when you retire.
      </p>

      <h2 className="text-t18 font-semibold text-font-primary pb-4">
        Approved Retirement Fund (ARF)
      </h2>
      <p className="text-t15 font-normal text-font-primary pb-24">
        A type of fund that may be available to you at retirement. With an
        approved retirement fund you can manage and control your pension fund
        and withdraw as much of this as you wish, but any withdrawals taken will
        be subject to tax.
      </p>

      <h2 className="text-t18 font-semibold text-font-primary pb-4">
        Executive Pension
      </h2>
      <p className="text-t15 font-normal text-font-primary pb-24">
        A type of pension that's specifically designed for company directors and
        senior management. It allows you to contribute a higher percentage of
        your income whilst receiving tax relief.
      </p>

      <h2 className="text-t18 font-semibold text-font-primary pb-4">
        Personal Retirement Bond
      </h2>
      <p className="text-t15 font-normal text-font-primary pb-24">
        Your pension may be invested into one of these when you leave a job. The
        trustees of your pension scheme will use the bond as a method of
        managing and investing your old pension.
      </p>

      <h2 className="text-t18 font-semibold text-font-primary pb-4">
        Retirement Annuity Contract
      </h2>
      <p className="text-t15 font-normal text-font-primary pb-24">
        A retirement annuity contract (RAC) is the formal name for what is more
        commonly called a personal pension or PRSA. A RAC provides a tax-free
        lump sum, within certain limits, and a pension or other benefits at
        retirement, usually in the form of an income for the rest of your life.
      </p>
    </section>
  );
};

export { PensionsExplanation };
