import { DebtField } from "../globalTypes";
import { DebtType } from "../graphqlTypes";

const getDebtFieldsFromType = (
  assetType: DebtType | null | undefined
): DebtField[] => {
  switch (assetType) {
    case DebtType.CreditCard:
      return [
        "provider",
        "owners",
        "balance",
        "monthlyRepayment",
        "clearedOnDeath",
        "repaidMonthly",
      ];

    case DebtType.Loan:
      return [
        "owners",
        { fieldName: "balance", label: "Estimated balance" },
        "monthlyRepayment",
        "endDate",
        "clearedOnDeath",
        "arePaymentsUpToDate",
        "arrears",
      ];

    case DebtType.CarLoan:
      return [
        "owners",
        "balance",
        "maturityDate",
        "monthlyRepayment",
        "clearedOnDeath",
        "arePaymentsUpToDate",
        "arrears",
      ];

    case DebtType.Mortgage:
      return [
        "provider",
        "owners",
        { fieldName: "balance", label: "Amount still owed" },
        "mortgageType",
        { fieldName: "monthlyRepayment", label: "Monthly mortgage repayment" },
        "mortgageYearsLeft",
        "isMainMortgage",
        "clearedOnDeath",
        "hasPaymentProtectionInsurance",
        "arePaymentsUpToDate",
        "arrears",
      ];

    case DebtType.HirePurchaseAgreement:
      return [
        "owners",
        "balance",
        "maturityDate",
        "monthlyRepayment",
        "clearedOnDeath",
        "arePaymentsUpToDate",
        "arrears",
      ];

    case DebtType.PersonalLoan:
      return [
        "owners",
        "balance",
        "maturityDate",
        "monthlyRepayment",
        "clearedOnDeath",
        "arePaymentsUpToDate",
        "arrears",
      ];

    case DebtType.OtherDebt:
      return [
        "owners",
        "balance",
        "maturityDate",
        "monthlyRepayment",
        "clearedOnDeath",
        "name",
        "arePaymentsUpToDate",
        "arrears",
      ];

    case undefined:
    case null:
      return [];

    default:
      const unreachable: never = assetType;
      return unreachable;
  }
};

export { getDebtFieldsFromType };
