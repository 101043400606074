import { Spinner, useAuth } from "@multiply/lib";
import { useNavigate } from "react-router-dom";
import {
  Relationship,
  useGetPersonQuery,
  useUpdatePersonMutation,
} from "../../graphqlTypes";
import { IntroPhoneFormValues } from "../../hooks";
import { getIntroPathFromPerson } from "../../utils";
import { IntroFactfindPhoneForm } from "./IntroFactfindPhoneForm";

export function IntroFactFindPhone() {
  const { userId, loggedIn } = useAuth();
  const navigate = useNavigate();

  const [personResult] = useGetPersonQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });
  const person = personResult.data?.person;
  const personId = person?.id;

  const defaultValues = {
    countryCode: "+353",
    mobileNumber: "",
  };

  const [updatePersonResult, updatePerson] = useUpdatePersonMutation();

  const onSubmit = async (formValues: IntroPhoneFormValues) => {
    let phoneNumber =
      (formValues?.countryCode || "") + (formValues?.mobileNumber || "");
    try {
      const result = await updatePerson({
        input: {
          userId: userId ?? "",
          personId,

          person: {
            relationship: Relationship.User,
            phoneNumber,
          },
        },
      });

      if (result?.data?.updatePerson?.success) {
        const nextPath = getIntroPathFromPerson(
          result.data?.updatePerson.person
        );

        if (nextPath) {
          navigate(nextPath);
        }
      }
    } catch (error) {}
  };

  const updatePersonError =
    updatePersonResult.data?.updatePerson?.error ?? updatePersonResult.error;

  return (
    <div className="flex flex-col justify-between">
      <div className="flex flex-col justify-between">
        <section className="text-center">
          <h1 className="text-t20 sm:text-t27 text-font-primary pb-16">
            Great, last step
          </h1>
          <h2 className="text-t12 sm:text-t16 text-font-secondary pb-48 sm:pb-36">
            We'll only call if it's important and about the financial advice
            you've received.
          </h2>
        </section>
        {personResult.fetching ? (
          <div data-testid="spinner" className="flex flex-col items-center">
            <Spinner />
          </div>
        ) : (
          <IntroFactfindPhoneForm
            error={updatePersonError?.message}
            defaultValues={defaultValues}
            onSubmit={onSubmit}
          />
        )}
      </div>
    </div>
  );
}
