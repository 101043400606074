import { Spinner, useAuth } from "@multiply/lib";
import { FC } from "react";
import { useGetPersonQuery } from "../../graphqlTypes";

type IntroFactFindProps = {
  children?: any;
};

const IntroFactFindLoading: FC<IntroFactFindProps> = () => {
  const { userId } = useAuth();

  const [personResult] = useGetPersonQuery({
    variables: { userId: userId ?? "" },
    pause: !userId,
  });

  return (
    <>
      <h1 className="text-t20 sm:text-t27 text-font-primary">
        Thanks {personResult.data?.person?.firstName}
      </h1>
      <p className="text-t12 sm:text-t16 text-font-secondary pb-32">
        We're verifying your identity and opening your account
      </p>
      <Spinner />
    </>
  );
};

export { IntroFactFindLoading };
