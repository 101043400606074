import { GoalProgress } from "../../components";
import { Card, useWindowDimensions, PiggyBankOutlineIcon } from "@multiply/lib";

type ThisMeansEmergencyFundProps = {
  monthsProtected: number;
  recommendedProtectedMonths: number;
  progress: number;
  currentProvision: string;
  recommendedProvision: string;
};

const ThisMeansEmergencyFund = ({
  monthsProtected,
  recommendedProtectedMonths,
  currentProvision,
  recommendedProvision,
  progress,
}: ThisMeansEmergencyFundProps) => {
  const { isPhone } = useWindowDimensions();

  return (
    <Card className="w-full flex justify-between items-center p-32">
      <p className="text-t12 sm:text-t27 text-font-primary">Emergency fund</p>

      <GoalProgress
        title={`${monthsProtected} out of ${recommendedProtectedMonths} month${
          recommendedProtectedMonths !== 1 ? "s" : ""
        } saved`}
        subtitle={`${currentProvision} out of ${recommendedProvision}`}
        variant="flat"
        icon={
          !isPhone ? (
            <PiggyBankOutlineIcon size="lg" className="text-icon-display" />
          ) : undefined
        }
        progress={progress}
      />
    </Card>
  );
};

export { ThisMeansEmergencyFund };
