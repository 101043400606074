import { GetPlanAdviceInfoQuery } from "./graphqlTypes";

export type DateObject = {
  day: string | number;
  month: string | number;
  year: string | number;
};

export enum InteractionFlags {
  CompletedFactFindIntro = "COMPLETED_FACT_FIND_INTRO",
  CompletedBasicFactFind = "COMPLETED_BASIC_FACT_FIND",
  CompletedGoalsWizard = "COMPLETED_GOALS_WIZARD",
  CompletedViewGoals = "COMPLETED_VIEW_GOALS",
  CompletedFinancialsFactFind = "COMPLETED_FINANCIALS_FACT_FIND",
  CompletedPensionsLimitations = "COMPLETED_PENSIONS_LIMITATIONS",
  CompletedPensionsVulnerability = "COMPLETED_PENSIONS_VULNERABILITY",
  CompletedPensionsFactFind = "COMPLETED_PENSIONS_FACT_FIND",
  CompletedPensionsRisk = "COMPLETED_PENSIONS_RISK",
  CompletedPensionsOnboarding = "COMPLETED_PENSIONS_ONBOARDING",
  CompletedAddProducts = "COMPLETED_ADD_PRODUCTS",
  CompletedGenerateLifePlan = "COMPLETED_GENERATE_LIFE_PLAN",
}

export type AssetFieldName =
  | "accessible"
  | "balance"
  | "frequency"
  | "increasing"
  | "maturityDate"
  | "name"
  | "overdrawnBy"
  | "owners"
  | "provider"
  | "regularPaymentAmount"
  | "startDate"
  | "valuationMethod"
  | "whereFrom"
  | "propertyType"
  | "pensionType"
  | "active"
  | "throughCurrentEmployer"
  | "isOverdrawn"
  | "isIrishLifePension";

export type AssetFieldConfig = {
  fieldName: AssetFieldName;
  label?: string;
  tooltip?: React.ReactNode;
  input?: React.ReactElement;
  required?: boolean;
};

export type AssetField = AssetFieldName | AssetFieldConfig;

export type DebtFieldName =
  | "owners"
  | "name"
  | "provider"
  | "balance"
  | "monthlyRepayment"
  | "clearedOnDeath"
  | "maturityDate"
  | "endDate"
  | "mortgageType"
  | "mortgageYearsLeft"
  | "isMainMortgage"
  | "repaidMonthly"
  | "arePaymentsUpToDate"
  | "hasPaymentProtectionInsurance"
  | "arrears";

export type DebtFieldConfig = {
  fieldName: DebtFieldName;
  label: string;
  input?: React.ReactNode;
};

export type DebtField = DebtFieldName | DebtFieldConfig;

export type PolicyFieldName =
  | "owners"
  | "coverType"
  | "name"
  | "provider"
  | "isOnePlan"
  | "isIrishLifePolicy"
  | "throughCurrentEmployer"
  | "amountOfCover"
  | "yearsRemaining"
  | "premium"
  | "paymentFrequency"
  | "renewalDate";

export type PolicyFieldConfig = {
  fieldName: PolicyFieldName;
  label: string;
  input?: React.ReactNode;
};

export type PolicyField = PolicyFieldName | PolicyFieldConfig;

export type Recommendation = NonNullable<
  GetPlanAdviceInfoQuery["plan"]["recommendationGroups"]
>[0];

export enum UserJourneyTypes {
  FocusedPension = "pension",
  Holistic = "full",
}
