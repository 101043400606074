import { DropdownCaret } from "../Icons/DropdownCaret";
import classNames from "classnames";
import { useState } from "react";

type BulletSections = {
  title?: string;
  bullets: string[];
};

type AccordionProps = {
  className?: string;
  title?: string;
  bullets?: string[];
  bulletSections?: BulletSections[];
};
const Accordion = ({
  className,
  title,
  bullets = [],
  bulletSections = [],
}: AccordionProps) => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  return (
    <div className={classNames("overflow-hidden", className)}>
      <section
        className="flex justify-between items-center px-18 py-24 font-light cursor-pointer"
        onClick={() => setAccordionOpen(!accordionOpen)}
      >
        <h1 className="text-t16 text-brand-primary font-semibold">{title}</h1>
        <DropdownCaret
          className={`transition-all ease-in-out delay-150 w-16 ${
            accordionOpen ? "rotate-180" : ""
          }`}
        />
      </section>

      <section
        className={classNames({
          "text-font-secondary px-18 pb-24 max-h-full transition-all delay-300 duration-1000 ease-in-out":
            accordionOpen,
          "text-transparent p-0 max-h-[0em] border-b-0 transition-all delay-75 duration-1000 ease-in-out":
            !accordionOpen,
        })}
        aria-hidden={!accordionOpen}
        data-testid="bullet-section"
      >
        {bullets.length > 0 && (
          <ul className="list-disc" data-testid="unordered-list">
            {bullets.map((bullet, index) => {
              return (
                <li key={index} className="ml-18">
                  {bullet}
                </li>
              );
            })}
          </ul>
        )}
        {bulletSections.length > 0 && (
          <>
            {bulletSections.map((bulletSection, index) => {
              const { title, bullets } = bulletSection;
              return (
                <div key={index}>
                  <h1 className="text-t16 text-brand-primary font-semibold">
                    {title}
                  </h1>
                  <ul
                    className="list-disc mb-12 sm:18"
                    data-testid="unordered-list"
                  >
                    {bullets.map((bullet, index) => {
                      return (
                        <li key={index} className="ml-18">
                          {bullet}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </>
        )}
      </section>
      <div
        className={classNames("border-b border-accordion-divider", {
          "mt-32 sm:mt-0": accordionOpen,
          "overflow-y-hidden": !accordionOpen,
        })}
      ></div>
    </div>
  );
};

export { Accordion };
