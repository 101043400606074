import { ReactNode } from "react";
import { GoalType } from "../../graphqlTypes";
import { ModalNavLink } from "../ModalNavLink";

type DisplayGoalSubTitleProps = {
  goalType: GoalType | null;
  goalName: string | null;
};
const DisplayGoalSubTitle = ({
  goalType,
  goalName,
}: DisplayGoalSubTitleProps) => {
  let subTitle: ReactNode = "";
  switch (goalType) {
    case GoalType.ChildEducation:
      subTitle = (
        <>
          Planning for college? Get an estimate for your child's college costs
          with our{" "}
          <span>
            <ModalNavLink
              to={`/cross/tools/college-costs-calculator${
                goalName ? `?goalName=${goalName}` : ""
              }`}
              className="text-font-links"
            >
              calculator tool.
            </ModalNavLink>
          </span>
        </>
      );
      break;
    case GoalType.Custom:
      subTitle =
        "There's no need to set specific goals for your emergency fund, pension, or general investments. These are already included in your plan by default.";
      break;
  }

  return (
    <p className="border-l-4 border-icon-display text-brand-secondary pl-18 text-t12 sm:text-t16 sm:mt-16">
      {subTitle}
    </p>
  );
};

export { DisplayGoalSubTitle };
