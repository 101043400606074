import { RadioInputGroup, Tooltip } from "@multiply/lib";
import { BiologicalSex } from "../../graphqlTypes";

type InputGenderProps = {
  error?: string;
  value: BiologicalSex | null;
  onChange: (value: BiologicalSex | undefined) => void;
  onBlur: () => void;
};

const InputGender = ({ error, value, onChange, onBlur }: InputGenderProps) => {
  const tooltipText = `This will be factored into any retirement recommendations.`;
  return (
    <fieldset>
      <legend className="flex">
        <p className="text-font-primary pr-8 mb-12">Sex</p>
        <Tooltip aria-label={tooltipText}>{tooltipText}</Tooltip>
      </legend>
      <RadioInputGroup
        value={value ?? undefined}
        onChange={onChange}
        onBlur={onBlur}
        variant="stacked"
        options={[
          {
            label: "Female",
            value: BiologicalSex.Female,
          },
          {
            label: "Male",
            value: BiologicalSex.Male,
          },
        ]}
        error={error}
      />
    </fieldset>
  );
};

export { InputGender };
