import classNames from "classnames";
import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import { getDimensionsFromSize, IconProps } from "./shared";

const MoreIcon: FC<IconProps> = ({ size, variant, className, ...props }) => {
  const { width, height } = getDimensionsFromSize(size);

  return (
    <svg
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 31 31"
      fill="currentColor"
      stroke="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="more advice icon"
      {...props}
    >
      <path
        d="M3.82118 30.9899H27.1788C28.0638 30.9899 28.9125 30.6384 29.5383 30.0126C30.1641 29.3868 30.5156 28.5381 30.5156 27.6531V4.29548C30.5156 3.41051 30.1641 2.56178 29.5383 1.93601C28.9125 1.31023 28.0638 0.958679 27.1788 0.958679H3.82118C2.9362 0.958679 2.08747 1.31023 1.4617 1.93601C0.83593 2.56178 0.484375 3.41051 0.484375 4.29548V27.6531C0.484375 28.5381 0.83593 29.3868 1.4617 30.0126C2.08747 30.6384 2.9362 30.9899 3.82118 30.9899ZM7.15799 14.3059H13.8316V7.63229H17.1684V14.3059H23.842V17.6427H17.1684V24.3163H13.8316V17.6427H7.15799V14.3059Z"
        fill="currentColor"
      />
    </svg>
  );
};

export { MoreIcon };
