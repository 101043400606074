import { Button } from "@multiply/lib";
import { AssetType, PropertyType } from "../../graphqlTypes";
import { useAssetForm } from "../../hooks";
import { getAssetNameFromType } from "../../utils";
import { AssetField } from "./AssetField";
import { getAssetFieldsFromType } from "./getAssetFieldsFromType";

type PropertyFormProps = {
  onSubmit: (values: any) => Promise<void>;
  defaultValues: any;
};

const PropertyForm = ({ onSubmit, defaultValues }: PropertyFormProps) => {
  const fields = getAssetFieldsFromType(AssetType.Property);
  const name = getAssetNameFromType(AssetType.Property);

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting, isValid },
  } = useAssetForm({ fields, defaultValues });

  const propertyType = watch("propertyType");

  //  don't ask wherefrom if property type is main residence
  const filteredFields = fields.filter((field) => {
    const fieldName = typeof field === "string" ? field : field.fieldName;

    if (
      fieldName === "whereFrom" &&
      propertyType === PropertyType.MainResidence
    ) {
      return false;
    }

    return true;
  });

  return (
    <form className="flex flex-col items-center w-full">
      <div className="flex items-center self-start mb-60">
        <h1 className="text-t27 text-font-primary">{name}</h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-x-32 gap-y-32 self-stretch">
        {filteredFields.map((field) => (
          <AssetField
            key={typeof field === "string" ? field : field.fieldName}
            field={field}
            control={control}
          />
        ))}
      </div>

      <Button
        className="mt-96 w-240"
        onClick={handleSubmit(onSubmit)}
        disabled={isSubmitting || !isValid}
      >
        Save
      </Button>
    </form>
  );
};

export { PropertyForm };
