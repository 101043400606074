import { useAuth } from "@multiply/lib";
import { useGetUserQuery } from "../graphqlTypes";

const useIsMIL = () => {
  const { userId, loggedIn } = useAuth();

  const [userResult] = useGetUserQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const isMIL = userResult.data?.user?.userType === "MIL";

  return isMIL;
};

export { useIsMIL };
