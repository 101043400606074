const MonthlyOutgoingEssentialsExplanation = () => {
  return (
    <main className="w-full h-full">
      <h1 className="text-t27 text-font-primary font-bold pb-36">
        Everyone's essentials are different, but may include
      </h1>

      <ul className="list-disc mb-24 ml-24">
        <li className="text-font-primary pb-12">Food</li>
        <li className="text-font-primary pb-12">Debt repayments</li>
        <li className="text-font-primary pb-12">Insurance payments</li>
      </ul>

      <h3 className="text-font-primary font-bold pb-16">It doesn't include</h3>
      <ul className="list-disc mb-24 ml-24">
        <li className="text-font-primary pb-12">
          Spending you would be willing to give up that is non-essential (e.g.
          streaming services, spending on holidays, dining out etc)
        </li>
      </ul>
      <p className="text-font-primary">
        We use your outgoings to calculate how much you'll have left over each
        month to save and invest, so make this figure as accurate as you can.
      </p>
    </main>
  );
};

export { MonthlyOutgoingEssentialsExplanation };
