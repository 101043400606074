import { cacheExchange, Cache } from "@urql/exchange-graphcache";
import { gql } from "urql";
import { Queries } from "./queries";

type AuthExchange = ReturnType<typeof cacheExchange>;

const invalidateCacheForField = (cache: Cache, fieldName: string) => {
  const key = "Query";
  cache
    .inspectFields(key)
    .filter((field) => {
      return field.fieldName === fieldName;
    })
    .forEach((field) => {
      cache.invalidate(key, field.fieldKey);
    });
};

const deleteEntity = (cache: Cache, fieldName: string, id: string) => {
  const EntityList = gql`
    query ($userId: ID!) {
      ${fieldName}(userId: $userId) {
        id
      }
    }
  `;

  cache
    .inspectFields("Query")
    .filter((field) => field.fieldName === fieldName)
    .forEach((field) => {
      cache.updateQuery(
        {
          query: EntityList,
          variables: { userId: field.arguments?.userId },
        },
        (data) => {
          data[fieldName] = data[fieldName].filter(
            (entity: any) => entity.id !== id
          );
          return data;
        }
      );
    });
};

// TODO this configuration should be passed in when the urql client is created instead of living in lib
const createCacheExchange = ({
  schema,
  queries,
}: {
  schema: any;
  queries?: Queries;
}): AuthExchange => {
  return cacheExchange({
    // objects without an id (embedded objects) should explicitly return a null id
    keys: {
      Address: () => null,
      BreakdownSlice: () => null,
      checkIn: () => null,
      checkInPayload: () => null,
      CollegeCost: () => null,
      ComparisonBlock: () => null,
      ComparisonFeature: () => null,
      ComparisonPackage: () => null,
      DebtSummary: () => null,
      EmergencyFundProgress: () => null,
      EmergencyFundSummary: () => null,
      GoalProgress: () => null,
      HealthSummary: () => null,
      Link: () => null,
      MarkdownBlock: () => null,
      Money: () => null,
      PersonFactfind: () => null,
      ProductSummary: () => null,
      ProtectionScenario: () => null,
      ProtectionSummary: () => null,
      Provider: (provider) =>
        provider?.id?.toString() ?? provider?.name?.toString() ?? null,
      ResposiveImage: () => null,
      RetirementSummary: () => null,
      SavingsInvestingSummary: () => null,
      UrlBlock: () => null,
      WealthProjection: () => null,
      WealthAtDate: () => null,
      RecommendationCTA: () => null,
      Question: (question) =>
        typeof question?.key === "string" ? question.key : null,
      FieldInput: () => null,
      PensionAtDate: () => null,
      PensionCalculation: () => null,
      FieldOption: () => null,
      staleFields: () => null,
      PRSAFundRecommendation: () => null,
    },
    schema,
    updates: {
      Mutation: {
        updatePerson(_result, args, cache, _info) {
          const personId = (args as any)?.input?.person?.id;

          // if no person id we created a new person
          if (!personId) {
            invalidateCacheForField(cache, "person");
          }
          invalidateCacheForField(cache, "plan");
          invalidateCacheForField(cache, "persons");
        },

        signUp: (_result, args, cache, _info) => {
          invalidateCacheForField(cache, "person");
          invalidateCacheForField(cache, "user");
        },

        syncUser(_result, args, cache, _info) {
          invalidateCacheForField(cache, "person");
          invalidateCacheForField(cache, "user");
          invalidateCacheForField(cache, "asset");
          invalidateCacheForField(cache, "assets");
          invalidateCacheForField(cache, "debt");
          invalidateCacheForField(cache, "debts");
          invalidateCacheForField(cache, "policy");
          invalidateCacheForField(cache, "policies");
        },

        updateEmail(_result, args, cache, _info) {
          invalidateCacheForField(cache, "user");
        },

        bookAppointment(_result, args, cache, _info) {
          invalidateCacheForField(cache, "availableAppointmentDays");
          invalidateCacheForField(cache, "availableAppointments");
          invalidateCacheForField(cache, "scheduledAppointments");
          invalidateCacheForField(cache, "plan");
        },

        updateAsset(_result, args, cache, _info) {
          const assetId = (args as any)?.input?.asset?.id;

          // if no asset id we created a new asset
          if (!assetId) {
            invalidateCacheForField(cache, "asset");
            invalidateCacheForField(cache, "assets");
          }
          invalidateCacheForField(cache, "plan");
          invalidateCacheForField(cache, "planGraph");
        },

        deleteAsset(_result, args, cache, _info) {
          const assetId = (args as any)?.input?.assetId;

          deleteEntity(cache, "assets", assetId);
          invalidateCacheForField(cache, "plan");
          invalidateCacheForField(cache, "planGraph");
        },

        updateAssets(_result, args, cache, _info) {
          invalidateCacheForField(cache, "assets");
          invalidateCacheForField(cache, "plan");
          invalidateCacheForField(cache, "planGraph");
        },

        updateDebt(_result, args, cache, _info) {
          const debtId = (args as any)?.input?.debt?.id;

          // if no debt id we created a new debt
          if (!debtId) {
            invalidateCacheForField(cache, "debt");
            invalidateCacheForField(cache, "debts");
          }
          invalidateCacheForField(cache, "plan");
          invalidateCacheForField(cache, "planGraph");
        },

        deleteDebt(_result, args, cache, _info) {
          const debtId = (args as any)?.input?.debtId;

          deleteEntity(cache, "debts", debtId);
          invalidateCacheForField(cache, "plan");
          invalidateCacheForField(cache, "planGraph");
        },

        updateDebts(_result, args, cache, _info) {
          invalidateCacheForField(cache, "debts");
          invalidateCacheForField(cache, "plan");
          invalidateCacheForField(cache, "planGraph");
        },

        updatePolicy(_result, args, cache, _info) {
          const policyId = (args as any)?.input?.policy?.id;

          // if no policy id we created a new policy
          if (!policyId) {
            invalidateCacheForField(cache, "policy");
            invalidateCacheForField(cache, "policies");
          }
          invalidateCacheForField(cache, "plan");
          invalidateCacheForField(cache, "planGraph");
        },

        deletePolicy(_result, args, cache, _info) {
          const policyId = (args as any)?.input?.policyId;

          deleteEntity(cache, "policies", policyId);
          invalidateCacheForField(cache, "plan");
          invalidateCacheForField(cache, "planGraph");
        },

        updatePolicies(_result, args, cache, _info) {
          invalidateCacheForField(cache, "policies");
          invalidateCacheForField(cache, "plan");
          invalidateCacheForField(cache, "planGraph");
        },

        updateGoal(_result, args, cache, _info) {
          const goalId = (args as any)?.input?.goalId;

          // if no goal id we created a new goal
          if (!goalId) {
            invalidateCacheForField(cache, "goals");
            invalidateCacheForField(cache, "CollegeCost");
          }
          invalidateCacheForField(cache, "plan");
          invalidateCacheForField(cache, "planGraph");
        },

        deleteGoal(_result, args, cache, _info) {
          const goalId = (args as any)?.input?.goalId;

          deleteEntity(cache, "goals", goalId);
          invalidateCacheForField(cache, "plan");
          invalidateCacheForField(cache, "planGraph");
        },

        updateGoals(_result, args, cache, _info) {
          invalidateCacheForField(cache, "goals");
          invalidateCacheForField(cache, "plan");
          invalidateCacheForField(cache, "planGraph");
        },

        updateFactfind(result, args, cache, _info) {
          // ff may create or delete partner
          invalidateCacheForField(cache, "persons");
          invalidateCacheForField(cache, "plan");
          invalidateCacheForField(cache, "planGraph");
          invalidateCacheForField(cache, "assets");
          invalidateCacheForField(cache, "debts");
          invalidateCacheForField(cache, "policies");
        },

        updateRisk(_result, args, cache, _info) {
          invalidateCacheForField(cache, "plan");
          invalidateCacheForField(cache, "planGraph");
        },

        setVulnerabilityReminder(_result, args, cache, _info) {
          invalidateCacheForField(cache, "vulnerabilityReminder");
        },

        deleteVulnerabilityReminder(_result, args, cache, _info) {
          invalidateCacheForField(cache, "vulnerabilityReminder");
        },

        createNewSurvey(_result, args, cache, _info) {
          invalidateCacheForField(cache, "showSurvey");
        },

        declineSurvey(_result, args, cache, _info) {
          invalidateCacheForField(cache, "showSurvey");
        },

        subscribeToTool(_result, args, cache, _info) {
          invalidateCacheForField(cache, "tools");
        },

        updateCheckIn(_result, args, cache, _info) {
          invalidateCacheForField(cache, "checkIn");
          invalidateCacheForField(cache, "factfind");
        },
      },
    },
  });
};

export { createCacheExchange };
