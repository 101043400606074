import { Card, CollapsibleArrowIcon } from "@multiply/lib";
import classNames from "classnames";
import { useState } from "react";

type UATBannerProps = {
  children: React.ReactNode;
};

const UATBanner = ({ children }: UATBannerProps) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <>
      {process.env.REACT_APP_SHOW_DEV_NOTICE === "true" ? (
        <Card className="fixed top-24 right-24 py-18 px-24 flex flex-col w-360 z-10">
          <div className="flex">
            <span className="text-t18 text-font-primary flex-1">
              Development build notice
            </span>
            <button
              onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
              className="text-t12 text-font-links outline-none"
            >
              {isExpanded ? "Collapse" : "Expand"}{" "}
              <CollapsibleArrowIcon
                size="xsm"
                className={classNames(
                  "inline-block align-top ease-in-out duration-300",
                  {
                    "-rotate-90": !isExpanded,
                    "rotate-90": isExpanded,
                  }
                )}
              />
            </button>
          </div>
          {isExpanded ? (
            <p className="text-t12 text-font-primary mt-12 ">
              This website is a work in progress. By using this site, you agree
              that you have permission to access it and that any or all
              information provided by this site during the duration of your
              session, should not be taken as advice.
            </p>
          ) : null}
        </Card>
      ) : null}
      {children}
    </>
  );
};

export { UATBanner };
