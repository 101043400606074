import classNames from "classnames";
import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import { getDimensionsFromSize, IconProps } from "./shared";
import { useId } from "../../hooks";

const CollegeCapIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);

  const uuid = useId();

  return (
    <svg
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      viewBox="0 0 32 33"
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${uuid})`}>
        <path
          d="M18.242 6.337a5.232 5.232 0 0 0-4.48 0L.764 12.494a1.334 1.334 0 0 0-.762 1.275v8.07a1.334 1.334 0 1 0 2.666 0v-5.99l2.667 1.323v7.333a1.334 1.334 0 0 0 .392.944l.067.064.16.147c.836.744 1.739 1.41 2.698 1.986 1.782 1.072 4.355 2.192 7.35 2.192 2.994 0 5.568-1.12 7.352-2.192a17.98 17.98 0 0 0 2.698-1.986l.16-.147.062-.064h.005a1.332 1.332 0 0 0 .39-.947V17.17l4.591-2.277a1.333 1.333 0 0 0-.02-2.4L18.241 6.334v.003Zm.085 14.973 5.675-2.816v5.419a15.31 15.31 0 0 1-2.019 1.448c-1.547.928-3.643 1.81-5.981 1.81-2.339 0-4.432-.88-5.982-1.81a15.31 15.31 0 0 1-2.018-1.448v-5.419l5.674 2.816a5.241 5.241 0 0 0 4.651 0ZM17.1 8.748l10.512 4.978-10.472 5.195a2.564 2.564 0 0 1-2.277 0L4.391 13.726l10.512-4.978a2.565 2.565 0 0 1 2.197 0ZM5.732 25.454l-.002-.002.002.002Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={uuid}>
          <path fill="#fff" transform="translate(0 .5)" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { CollegeCapIcon };
