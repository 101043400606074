import React from "react";
import classNames from "classnames";

type Props = {
  title: string;
  index: number;
  setSelectedTab: (index: number) => void;
  selectedTab: number;
};

const TabTitle: React.FC<Props> = ({
  title,
  selectedTab,
  setSelectedTab,
  index,
}) => {
  return (
    <li className="text-font-primary">
      <button
        className={classNames("text-t16 md:text-t18 flex flex-col pb-24", {
          "text-action-primary font-semibold": selectedTab === index,
        })}
        onClick={() => setSelectedTab(index)}
      >
        {title}
        <span
          aria-hidden={true}
          className={classNames(
            "h-4 w-full rounded-full content-[''] mt-2 inline-block",
            {
              "bg-action-primary": selectedTab === index,
            }
          )}
        />
      </button>
    </li>
  );
};

export { TabTitle };
