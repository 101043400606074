import { ContainerMidSize, Spinner, TrackedLink, useAuth } from "@multiply/lib";
import { BookAdviserCall, PlanStatusCard, YouToldUs } from "../../components";
import {
  AuthorisationStatus,
  HealthSummary,
  RecommendationType,
  useGetPlanRecommendationsQuery,
} from "../../graphqlTypes";
import { IrishLifeHealthInsuranceOverview } from "./IrishLifeHealthInsuranceOverview";
import { WhatIsHealthInsurance } from "./WhatIsHealthInsurance";

const Health = () => {
  const { userId, loggedIn } = useAuth();
  const [planResult] = useGetPlanRecommendationsQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const recommendationGroups =
    planResult?.data?.plan?.recommendationGroups ?? [];

  const healthRecommendation = recommendationGroups.find(
    (group) => group.recommendationType === RecommendationType.Health
  );

  const summary = healthRecommendation?.summary as
    | HealthSummary
    | null
    | undefined;

  const notApproved =
    planResult?.data?.plan?.authorisationStatus !==
    AuthorisationStatus.AdviceApproved;

  const errorGenerated = planResult?.error;

  return (
    <div className="w-full flex justify-center">
      {planResult.fetching ? (
        <Spinner className="w-60 h-60 my-32" />
      ) : errorGenerated || notApproved ? (
        <PlanStatusCard
          planResult={planResult.data?.plan}
          error={planResult?.error?.message || null}
        />
      ) : (
        <ContainerMidSize>
          <h1 className="text-t21 sm:text-t34 text-font-primary mb-12">
            Health
          </h1>

          <h2 className="text-t12 sm:text-t27 text-font-tertiary mb-56">
            Keep your body and mind in shape and get cover before you need it
          </h2>

          <div className="bg-input-border w-full h-2 mb-56" />

          <section className="text-t12 sm:text-t16 text-font-secondary mb-56 font-bold">
            <p>
              This information is provided by Irish Life Health dac. Irish Life
              Health dac is regulated by the Central Bank of Ireland. What's
              shown here is for information purposes only.
            </p>
          </section>

          <h1 className="sm:text-t27 text-font-primary mb-32">Overview</h1>

          <section className="flex flex-col items-start mb-96">
            <YouToldUs
              title="You told us"
              text={healthRecommendation?.factfindSummary}
              editPath="/products"
            />
            {!summary?.missing ? (
              <>
                <p className="text-t12 sm:text-t21 text-font-secondary mt-36">
                  Like any insurance plan, if you have health insurance you
                  should review it regularly to ensure it continues to meet the
                  needs of you and your family. Irish Life Health can review
                  your existing plan to ensure it still meets your needs. Simply
                  let Irish Life Health know when you would prefer to be
                  contacted and they will call at that time. For information now
                  you can visit the{" "}
                  <TrackedLink
                    className="text-font-links"
                    href="https://www.irishlifehealth.ie/?utm_source=digitaladvice&utm_medium=multiply&utm_campaign=crosslink_digitaladvice"
                  >
                    Irish Life Health webpage
                  </TrackedLink>
                </p>
                <p className="text-t12 sm:text-t21 text-font-secondary mt-16">
                  This information does not constitute advice from Irish Life
                  Financial Services.
                </p>
              </>
            ) : null}
          </section>

          <section className="mb-60">
            <h1 className="sm:text-t27 text-font-primary">
              What is Health Insurance?
            </h1>
            <p className="text-t12 sm:text-t21 text-font-tertiary mb-40 mt-18">
              Health insurance offers protection by contributing towards or
              covering things like your medical bills such as hospital and
              doctors appointment costs and also includes various benefits that
              take care of your body and mind.
            </p>
            <WhatIsHealthInsurance />
          </section>
          <section className="mb-60 space-y-32">
            <h1 className="sm:text-t27 text-font-primary mb-32">
              Additional benefits with Irish Life Health
            </h1>
            <IrishLifeHealthInsuranceOverview />
          </section>

          <section>
            <BookAdviserCall hasPlan={true} />
          </section>
        </ContainerMidSize>
      )}
    </div>
  );
};

export { Health };
