import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";
import {
  IconProps,
  getDimensionsFromSize,
  getColorFromVariant,
} from "./shared";

const PiggyBankOutlineIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);

  return (
    <svg
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="piggy bank outline icon"
      {...props}
    >
      <path
        d="M6.75 2A2.258 2.258 0 0 0 4.5 4.25c0 .4.114.771.297 1.094-.9.527-1.605 1.275-1.984 2.156H2.75a.244.244 0 0 1-.25-.25c0-.088.045-.158.11-.203l-.579-.813A1.238 1.238 0 0 0 1.5 7.25c0 .615.459 1.119 1.047 1.219-.026.175-.047.35-.047.531 0 1.246.662 2.326 1.625 3.125-.121.5-.06 1.033.328 1.422a1.531 1.531 0 0 0 2.156 0l.188-.188c.39.072.783.141 1.203.141.42 0 .813-.069 1.203-.14l.188.187a1.531 1.531 0 0 0 2.156 0c.38-.381.438-.899.328-1.391A4.603 4.603 0 0 0 12.89 11H14V7h-1.14a4.478 4.478 0 0 0-.641-.813l.281-1.546V4H12s-1.201.02-2.219.782a7.805 7.805 0 0 0-.813-.189c.02-.11.032-.226.032-.343A2.258 2.258 0 0 0 6.75 2Zm0 1C7.445 3 8 3.555 8 4.25c0 .086-.016.168-.032.25a6.514 6.514 0 0 0-2.264.422A1.244 1.244 0 0 1 6.75 3Zm4.625 2.094c.008-.002.008.002.015 0l-.187 1.14-.046.266.218.188c.334.289.61.639.813 1.031l.14.281H13v2h-.672l-.14.281c-.245.482-.63.906-1.126 1.265l-.359.267.219.406a.503.503 0 0 1-.079.625.508.508 0 0 1-.733 0l-.36-.36-.203-.187-.266.063c-.406.093-.838.14-1.281.14a5.725 5.725 0 0 1-1.282-.14l-.264-.063-.204.188-.36.359a.509.509 0 0 1-.734 0 .503.503 0 0 1-.078-.625l.219-.406-.36-.266C4.034 10.89 3.5 9.994 3.5 9c0-1.871 1.955-3.5 4.5-3.5.615 0 1.201.092 1.735.266l.265.093.219-.187c.357-.318.799-.49 1.156-.578ZM11 8c-.275 0-.5.225-.5.5s.225.5.5.5.5-.225.5-.5-.225-.5-.5-.5Z"
        fill={fill}
      />
    </svg>
  );
};

export { PiggyBankOutlineIcon };
