import {
  Card,
  GraduateCapOutlineIcon,
  HomeIcon,
  PiggyBankOutlineIcon,
  RingOutlineIcon,
} from "@multiply/lib";
import { ModalNavLink, DisplayGoalTitle } from "../../components";
import { GoalType } from "../../graphqlTypes";

const AddGoalType = () => {
  return (
    <div>
      <Card
        variant="flat"
        className="w-full py-48 px-24 flex flex-col items-center"
      >
        <h1 className="text-t27 text-font-primary py-16 text-center">
          What type of savings goal do you want to add?
        </h1>
        <div className="w-fit min-w-240">
          <ModalNavLink
            to={`/cross/add-goal/${GoalType.BuyHome}`}
            className="flex my-16 w-full"
          >
            <div className="border border-font-primary w-full p-16 rounded-lg flex items-center gap-16">
              <HomeIcon size="md" className="text-icon-display" />
              <DisplayGoalTitle
                goalType={GoalType.BuyHome}
                className="text-font-primary text-t21"
              />
            </div>
          </ModalNavLink>
          <ModalNavLink
            to={`/cross/add-goal/${GoalType.Wedding}`}
            className="flex my-16 border w-full"
          >
            <div className="border border-font-primary w-full p-16 rounded-lg  flex items-center gap-16">
              <RingOutlineIcon size="md" className="text-icon-display" />
              <DisplayGoalTitle
                goalType={GoalType.Wedding}
                className="text-font-primary text-t21"
              />
            </div>
          </ModalNavLink>
          <ModalNavLink
            to={`/cross/add-goal/${GoalType.ChildEducation}`}
            className="flex my-16 w-full"
          >
            <div className="border border-font-primary w-full p-16 rounded-lg flex items-center gap-16">
              <GraduateCapOutlineIcon size="md" className="text-icon-display" />
              <DisplayGoalTitle
                goalType={GoalType.ChildEducation}
                className="text-font-primary text-t21"
              />
            </div>
          </ModalNavLink>
          <ModalNavLink
            to={`/cross/add-goal/${GoalType.Custom}`}
            className="flex my-16  w-full"
          >
            <div className="border border-font-primary p-16 w-full rounded-lg flex items-center gap-16">
              <PiggyBankOutlineIcon size="md" className="text-icon-display" />
              <DisplayGoalTitle
                goalType={GoalType.Custom}
                className="text-font-primary text-t21"
              />
            </div>
          </ModalNavLink>
        </div>
      </Card>
    </div>
  );
};

export { AddGoalType };
