import {
  Avatar,
  Card,
  ContainerMaxSize,
  obfuscateDob,
  useAuth,
  useToaster,
} from "@multiply/lib";
import { useNavigate } from "react-router-dom";
import { CheckInCard, SettingsCard } from "../../components";
import {
  CheckInType,
  EmploymentStatus,
  EmploymentType,
  PlanScope,
  useGetFactFindQuery,
  useGetPersonQuery,
  useGetRiskQuery,
  useGetUserQuery,
  useUpdateCheckInMutation,
} from "../../graphqlTypes";
import { useGetCheckIns, useVulnerableStatus } from "../../hooks";
import {
  currencyOrNull,
  getFieldsFromFactFind,
  getNextCheckInLink,
  useIsMIL,
} from "../../utils";
import { Loading } from "../Loading";

const numberOrNull = (value: number | null | undefined) => {
  if (value === null || value === undefined) {
    return null;
  }

  return value > 0 ? value : "None";
};

function HolisticPersonalDetails() {
  const { userId, loggedIn } = useAuth();
  const navigate = useNavigate();

  const [riskResult] = useGetRiskQuery({ variables: { userId: userId ?? "" } });

  const [personResult] = useGetPersonQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const [factFindResults] = useGetFactFindQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const vulnerable = useVulnerableStatus();

  const { fetching, checkInRequired, lastCheckIn } = useGetCheckIns(
    CheckInType.Factfind
  );

  const { addToast } = useToaster();

  const loading =
    fetching || factFindResults?.fetching || personResult?.fetching;

  const [, updateCheckIn] = useUpdateCheckInMutation();

  const confirmCheckInHandler = async () => {
    try {
      const result = await updateCheckIn({
        input: {
          userId: userId ?? "",
          checkInTypes: [CheckInType.Factfind],
        },
      });

      if (lastCheckIn) addToast({ message: "Plan updated", duration: 5000 });
      const nextLink = vulnerable
        ? "/check-in"
        : getNextCheckInLink(result.data?.updateCheckIn.checkIns ?? []);
      navigate(nextLink);
    } catch (error) {}
  };

  const [userResult] = useGetUserQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const isMIL = useIsMIL();

  const { dateOfBirth, firstName, lastName, address, phoneNumber } =
    personResult?.data?.person ?? {};
  const { email } = userResult.data?.user ?? {};

  const {
    postalCode,
    flatNumber,
    buildingNumber,
    buildingName,
    street,
    subStreet,
    town,
    country,
  } = address ?? {};
  const fullAddress = `${flatNumber ?? ""} ${buildingNumber ?? ""} ${
    buildingName ?? ""
  } ${subStreet ?? ""} ${street ?? ""} ${town ?? ""} ${postalCode ?? ""} ${
    country ?? ""
  }`;

  const {
    baseAnnualIncomeGross,
    baseAnnualIncomeGrossPartner,
    dateOfBirthPartner,
    firstNamePartner,
    lastNamePartner,
    eligibleForStatePension,
    eligibleForStatePensionPartner,
    employmentStatus,
    mainEmploymentType,
    employmentStatusPartner,
    mainEmploymentTypePartner,
    hasBonus,
    hasChildren,
    expectedBonusAmount,
    hasBonusPartner,
    expectedBonusAmountPartner,
    hasOtherIncome,
    expectedOtherIncomeAmount,
    hasOtherIncomePartner,
    expectedOtherIncomeAmountPartner,
    benefitInKind,
    benefitInKindPartner,
    hasGoodHealth,
    hasGoodHealthPartner,
    marginalTaxRate,
    marginalTaxRatePartner,
    maritalStatus,
    monthlyIncomeNet,
    monthlyIncomeNetPartner,
    extraMonthlyOutgoingEssentials,
    monthlyOutgoingNonEssentials,
    monthlyRent,
    monthlyRetirementOutgoingExpected,
    monthlyRetirementOutgoingExpectedPartner,
    monthlyUtilities,
    monthsOfFinancialSafety,
    monthsOfFinancialSafetyPartner,
    numberOfChildren,
    occupation,
    occupationPartner,
    memberOfEmployersPensionScheme,
    memberOfEmployersPensionSchemePartner,
    employerOffersPensionScheme,
    employerOffersPensionSchemePartner,
    planningFor,
    retirementAgeEstimate,
    retirementAgeEstimatePartner,
    smokingStatus,
    smokingStatusPartner,
    relationshipToOtherDependants,
  } = getFieldsFromFactFind(factFindResults?.data?.factfind);

  const totalMonthlyOutgoing =
    (monthlyRent ?? 0) +
    (monthlyUtilities ?? 0) +
    (extraMonthlyOutgoingEssentials ?? 0) +
    (monthlyOutgoingNonEssentials ?? 0);

  const hasPartner =
    planningFor &&
    [PlanScope.UserAndPartner, PlanScope.UserAndPartnerAndKids].includes(
      planningFor
    );

  const userIsEmployed = employmentStatus === EmploymentStatus.Employed;
  const partnerIsEmployed =
    employmentStatusPartner === EmploymentStatus.Employed;

  if (!loading && personResult.data && factFindResults.data) {
    return (
      <ContainerMaxSize>
        {loading ? (
          <div className="w-full flex justify-center items-center">
            <Loading />
          </div>
        ) : (
          <div className="w-full sm:pb-60 space-y-48">
            {checkInRequired && (
              <CheckInCard
                title="Are these details up-to-date?"
                subTitle="Please check and update any personal details that have changed. This will help us create an accurate plan."
                lastCheckIn={lastCheckIn}
                onClick={confirmCheckInHandler}
              />
            )}

            <section className="lg:w-fit">
              <h1 className="text-t20 sm:text-t27 text-font-primary pb-16">
                Personal details
              </h1>

              {isMIL && (
                <Card className="p-16 mb-28 border-input-border-focus border-2">
                  <p className="text-t14 text-font-primary">
                    Changes you make here will be reflected in your financial
                    plan only and will not update your personal records on Irish
                    Life Assurance or Irish Life Health.
                  </p>
                </Card>
              )}

              <Card className="p-24 space-y-32">
                <div className="flex flex-col items-center lg:items-start">
                  <Avatar variant="icon" size="xl" colorType="irish-life" />
                  <p className="text-t21 text-font-primary capitalize pt-8">
                    {firstName} {lastName}
                  </p>
                </div>

                <div
                  className="flex flex-col gap-24 lg:gap-80 
              lg:flex-row lg:flex-wrap"
                >
                  <section>
                    <p className="text-font-primary">Date of birth</p>
                    <p className="text-font-secondary">
                      {obfuscateDob(dateOfBirth)}
                    </p>
                  </section>

                  <section>
                    <p className="text-font-primary">Address</p>
                    <p className="text-font-secondary capitalize">
                      {fullAddress.toLowerCase()}
                    </p>
                  </section>

                  <section>
                    <p className="text-font-primary">Email</p>
                    <p className="text-font-secondary">{email}</p>
                  </section>

                  <section>
                    <p className="text-font-primary">Mobile number</p>
                    <p className="text-font-secondary">{phoneNumber}</p>
                  </section>
                </div>
              </Card>
            </section>

            <section>
              <h2 className="text-font-primary pb-16">Family</h2>
              <div className="sm:flex flex-wrap gap-16">
                <SettingsCard
                  fieldName="planning_for"
                  label="Planning with"
                  value={
                    planningFor === PlanScope.UserAndPartner
                      ? "My partner and I"
                      : "Just me"
                  }
                />
                {planningFor === PlanScope.UserAndPartner && (
                  <SettingsCard
                    fieldName="name_partner"
                    label="Partner's name"
                    value={
                      firstNamePartner && lastNamePartner
                        ? firstNamePartner + " " + lastNamePartner
                        : "Not added"
                    }
                  />
                )}
                {planningFor === PlanScope.UserAndPartner && (
                  <SettingsCard
                    fieldName="date_of_birth_partner"
                    label="Partner's date of birth"
                    value={obfuscateDob(dateOfBirthPartner)}
                  />
                )}
                <SettingsCard
                  label="Marital status"
                  fieldName="marital_status"
                  value={maritalStatus}
                />
                <SettingsCard
                  label="Children"
                  fieldName="children"
                  value={
                    hasChildren === true
                      ? numberOrNull(numberOfChildren)
                      : hasChildren === false
                      ? "None"
                      : null
                  }
                />
                <SettingsCard
                  label="Other dependants"
                  fieldName="dependants"
                  value={
                    relationshipToOtherDependants?.[0].relationship
                      ? relationshipToOtherDependants
                          ?.map((relationship) => relationship.relationship)
                          .filter((relationship) => !!relationship)
                          .join(", ")
                      : "None"
                  }
                />
              </div>
            </section>

            <section>
              <h2 className="text-font-primary pb-16">Work</h2>
              <div className="sm:flex flex-wrap gap-16">
                <SettingsCard
                  fieldName="employment_status"
                  label="Employment status"
                  value={
                    employmentStatus === EmploymentStatus.Employed
                      ? mainEmploymentType
                      : employmentStatus
                  }
                />
                {userIsEmployed && (
                  <>
                    <SettingsCard
                      fieldName="occupation"
                      label="Occupation"
                      value={occupation}
                    />
                    {mainEmploymentType !== EmploymentType.SelfEmployed && (
                      <SettingsCard
                        fieldName="employer_pension"
                        label="Member of employer's pension scheme"
                        value={
                          memberOfEmployersPensionScheme === true
                            ? "Yes"
                            : memberOfEmployersPensionScheme === false
                            ? "No"
                            : null
                        }
                      />
                    )}
                    {memberOfEmployersPensionScheme === false && (
                      <SettingsCard
                        fieldName="offer_employer_pension"
                        label="Employer offers pension scheme"
                        value={
                          employerOffersPensionScheme === true
                            ? "Yes"
                            : employerOffersPensionScheme === false
                            ? "No"
                            : null
                        }
                      />
                    )}
                    <SettingsCard
                      fieldName="base_annual_income_gross"
                      label="Annual income"
                      value={currencyOrNull(baseAnnualIncomeGross)}
                    />
                    <SettingsCard
                      fieldName="expected_bonus_amount"
                      label="Annual bonus"
                      value={
                        hasBonus
                          ? currencyOrNull(expectedBonusAmount)
                          : hasBonus === false
                          ? "None"
                          : null
                      }
                    />
                    <SettingsCard
                      fieldName="expected_other_income_amount"
                      label="Other income"
                      value={
                        hasOtherIncome
                          ? currencyOrNull(expectedOtherIncomeAmount)
                          : hasOtherIncome === false
                          ? "None"
                          : null
                      }
                    />
                    <SettingsCard
                      fieldName="benefit_in_kind"
                      label="Your total benefits in kind"
                      value={benefitInKind?.display ?? "None"}
                    />
                    <SettingsCard
                      fieldName="monthly_income_net"
                      label="Monthly take-home pay"
                      value={currencyOrNull(monthlyIncomeNet)}
                    />
                    <SettingsCard
                      fieldName="marginal_tax_rate"
                      label="Highest income tax rate"
                      value={
                        marginalTaxRate === 99
                          ? "I don't pay income tax"
                          : marginalTaxRate === 0.4
                          ? "40% (higher rate)"
                          : marginalTaxRate === 0.2
                          ? "20% (standard rate rate)"
                          : null
                      }
                    />
                  </>
                )}
                {hasPartner && (
                  <>
                    <SettingsCard
                      fieldName="employment_status_partner"
                      label="Partner's employment status"
                      value={
                        employmentStatusPartner === EmploymentStatus.Employed
                          ? mainEmploymentTypePartner
                          : employmentStatusPartner
                      }
                    />
                    {partnerIsEmployed && (
                      <>
                        <SettingsCard
                          fieldName="occupation_partner"
                          label="Partner's occupation"
                          value={occupationPartner}
                        />
                        {mainEmploymentTypePartner !==
                          EmploymentType.SelfEmployed && (
                          <SettingsCard
                            fieldName="employer_pension_partner"
                            label="Partner in employer's pension scheme"
                            value={
                              memberOfEmployersPensionSchemePartner === true
                                ? "Yes"
                                : memberOfEmployersPensionSchemePartner ===
                                  false
                                ? "No"
                                : null
                            }
                          />
                        )}
                        {memberOfEmployersPensionSchemePartner === false && (
                          <SettingsCard
                            fieldName="offer_employer_pension_partner"
                            label="Partner's employer offers pension scheme"
                            value={
                              employerOffersPensionSchemePartner === true
                                ? "Yes"
                                : employerOffersPensionSchemePartner === false
                                ? "No"
                                : null
                            }
                          />
                        )}
                        <SettingsCard
                          fieldName="base_annual_income_gross_partner"
                          label="Partner's annual income"
                          value={currencyOrNull(baseAnnualIncomeGrossPartner)}
                        />
                        <SettingsCard
                          fieldName="expected_bonus_amount_partner"
                          label="Partner's annual bonus"
                          value={
                            hasBonusPartner
                              ? currencyOrNull(expectedBonusAmountPartner)
                              : hasBonusPartner === false
                              ? "None"
                              : null
                          }
                        />
                        <SettingsCard
                          fieldName="expected_other_income_amount_partner"
                          label="Partner's other income"
                          value={
                            hasOtherIncomePartner
                              ? currencyOrNull(expectedOtherIncomeAmountPartner)
                              : null
                          }
                        />
                        <SettingsCard
                          fieldName="benefit_in_kind_partner"
                          label="Partner's total benefits in kind"
                          value={benefitInKindPartner?.display ?? "None"}
                        />
                        <SettingsCard
                          fieldName="monthly_income_net_partner"
                          label="Partner's take-home pay"
                          value={currencyOrNull(monthlyIncomeNetPartner)}
                        />
                        <SettingsCard
                          fieldName="marginal_tax_rate_partner"
                          label="Partner's highest income tax rate"
                          value={
                            marginalTaxRatePartner === 99
                              ? "They don't pay income tax"
                              : marginalTaxRatePartner === 0.4
                              ? "40% (higher rate)"
                              : marginalTaxRatePartner === 0.2
                              ? "20% (standard rate)"
                              : null
                          }
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            </section>

            <section>
              <h2 className="text-font-primary pb-16">Outgoings</h2>
              <div className="sm:flex flex-wrap gap-16">
                <SettingsCard
                  fieldName="outgoings"
                  label="Outgoings"
                  value={currencyOrNull(totalMonthlyOutgoing)}
                />
              </div>
            </section>

            <section>
              <h2 className="text-font-primary pb-16">Retirement</h2>
              <div className="sm:flex flex-wrap gap-16">
                <SettingsCard
                  fieldName="eligible_for_state_pension"
                  label="State pension eligibility"
                  value={
                    eligibleForStatePension === true
                      ? "Yes"
                      : eligibleForStatePension === false
                      ? "No"
                      : null
                  }
                />
                <SettingsCard
                  fieldName="retirement_age_estimate"
                  label="Retirement age target"
                  value={retirementAgeEstimate}
                />
                <SettingsCard
                  fieldName="monthly_retirement_outgoing_expected"
                  label="Retirement income target"
                  value={currencyOrNull(monthlyRetirementOutgoingExpected)}
                />
                {hasPartner && (
                  <>
                    <SettingsCard
                      fieldName="eligible_for_state_pension_partner"
                      label="Partner's State Pension eligibility"
                      value={
                        eligibleForStatePensionPartner === true
                          ? "Yes"
                          : eligibleForStatePensionPartner === false
                          ? "No"
                          : null
                      }
                    />
                    <SettingsCard
                      fieldName="retirement_age_estimate_partner"
                      label="Partner's retirement age target"
                      value={retirementAgeEstimatePartner}
                    />
                    <SettingsCard
                      fieldName="monthly_retirement_outgoing_expected_partner"
                      label="Partner's retirement income target"
                      value={currencyOrNull(
                        monthlyRetirementOutgoingExpectedPartner
                      )}
                    />
                  </>
                )}
              </div>
            </section>

            <section>
              <h2 className="text-font-primary pb-16">Health</h2>
              <div className="sm:flex flex-wrap gap-16">
                <SettingsCard
                  fieldName="has_good_health"
                  label="In good health"
                  value={
                    hasGoodHealth === true
                      ? "Yes"
                      : hasGoodHealth === false
                      ? "No"
                      : null
                  }
                />
                <SettingsCard
                  fieldName="smoking_status"
                  label="Smoking status"
                  value={smokingStatus}
                />
                <SettingsCard
                  fieldName="months_of_financial_safety"
                  label="Months after injury"
                  value={
                    monthsOfFinancialSafety === 99
                      ? "Longer"
                      : monthsOfFinancialSafety === 0
                      ? "N/A"
                      : monthsOfFinancialSafety
                      ? `${monthsOfFinancialSafety} months`
                      : null
                  }
                />
                {hasPartner && (
                  <>
                    <SettingsCard
                      fieldName="has_good_health_partner"
                      label="Partner in good health"
                      value={
                        hasGoodHealthPartner === true
                          ? "Yes"
                          : hasGoodHealthPartner === false
                          ? "No"
                          : null
                      }
                    />
                    <SettingsCard
                      fieldName="smoking_status_partner"
                      label="Partner's smoking status"
                      value={smokingStatusPartner}
                    />
                    <SettingsCard
                      fieldName="months_of_financial_safety_partner"
                      label="Partner months after injury"
                      value={
                        monthsOfFinancialSafetyPartner === 99
                          ? "Longer"
                          : monthsOfFinancialSafetyPartner === 0
                          ? "N/A"
                          : monthsOfFinancialSafetyPartner
                          ? `${monthsOfFinancialSafetyPartner} months`
                          : null
                      }
                    />
                  </>
                )}
              </div>
            </section>
            {riskResult.data?.risk?.calculatedProfile && (
              <section>
                <p className="text-font-primary pb-16">Risk</p>
                <Card className="p-24 mb-16">
                  <p className="sm:text-t20 text-font-primary">Profile</p>
                  <p className="sm:text-t20 text-font-secondary">
                    {riskResult.data?.risk?.calculatedProfile?.name}
                  </p>
                </Card>
              </section>
            )}
          </div>
        )}
      </ContainerMaxSize>
    );
  } else return null;
}

export { HolisticPersonalDetails };
