import { Card, Switch } from "@multiply/lib";
import { useEffect, useRef } from "react";
import { Control, Controller, FieldName, useForm } from "react-hook-form";
import { useDeepMemoizedValue } from "../../utils";
import cloneDeep from "lodash/cloneDeep";

type ToggleCardProps = {
  control: Control<EditContactPreferencesFormValues>;
  name: FieldName<EditContactPreferencesFormValues>;
  "aria-label": string;
  description: string;
  disabled: boolean;
};

const ToggleCard = ({
  name,
  description,
  "aria-label": ariaLabel,
  control,
  disabled,
}: ToggleCardProps) => {
  return (
    <Card className="flex px-16 py-24">
      <p className="text-font-primary text-t12 sm:text-t16 flex-1">
        {description}
      </p>

      <div className="ml-16 flex items-center">
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Switch
              checked={value}
              aria-label={ariaLabel}
              onChange={onChange}
              disabled={disabled}
            />
          )}
        />
      </div>
    </Card>
  );
};

export type EditContactPreferencesFormValues = {
  contactServicesIrishLifeGroup: boolean;
  userResearch: boolean;
  checkInEmails: boolean;
};

type EditContactPreferencesFormProps = {
  defaultValues: EditContactPreferencesFormValues;
  onChange: (values: EditContactPreferencesFormValues) => void;
  loading: boolean;
};

const EditContactPreferencesForm = ({
  defaultValues,
  onChange,
}: EditContactPreferencesFormProps) => {
  const {
    control,
    watch,
    formState: { isSubmitting },
  } = useForm({ defaultValues });

  const formValues = watch();
  // clone because formValues is a proxy
  const stableFormValues = useDeepMemoizedValue(cloneDeep(formValues));

  // doesn't run on first render
  // runs on every change after that
  const shouldRun = useRef<boolean>(false);
  useEffect(() => {
    if (shouldRun.current && stableFormValues) {
      onChange(stableFormValues);
    } else {
      shouldRun.current = true;
    }
  }, [onChange, stableFormValues]);

  return (
    <>
      <ToggleCard
        name="contactServicesIrishLifeGroup"
        control={control}
        description="I agree to being contacted about offers and services from within the Irish Life Group; including our Health, Life Assurance, Financial Services and Wellness Companies via email, SMS, phone or post."
        aria-label="contact preferences toggle input"
        disabled={isSubmitting}
      />

      <ToggleCard
        name="userResearch"
        control={control}
        description="I agree to being contacted for user research via email, SMS, phone or post"
        aria-label="user research toggle input"
        disabled={isSubmitting}
      />

      <ToggleCard
        name="checkInEmails"
        control={control}
        description="I agree to being sent regular check-in emails and reminders that will help me achieve my goals"
        aria-label="check in emails toggle input"
        disabled={isSubmitting}
      />
    </>
  );
};

export { EditContactPreferencesForm };
