import EventEmitter from "eventemitter3";
import { AuthService, AuthState, Events } from "../types";
import { v4 as uuid } from "@lukeed/uuid";

type CreateServiceArgs = {
  sessionLogin?: AuthState;
  currentLogin?: AuthState;
  newLogin?: AuthState;
};

const createMemoryAuthService = (args?: CreateServiceArgs) => {
  const eventEmitter = new EventEmitter<Events>();

  let currentLogin: AuthState = args?.currentLogin ?? {
    userId: null,
    token: null,
    refreshToken: null,
    username: null,
  };

  const newLogin: AuthState = args?.newLogin ?? {
    userId: uuid(),
    token: uuid(),
    refreshToken: uuid(),
    username: "test@email.com",
  };

  let redirectResult: AuthState | null = null;

  const loginWithEmailAndPassword: AuthService["loginWithEmailAndPassword"] =
    async ({ email, password }) => {
      eventEmitter.emit("authStateChange", newLogin);
      currentLogin = { ...newLogin };
      return newLogin;
    };

  const signUpWithEmailAndPassword: AuthService["signUpWithEmailAndPassword"] =
    async ({ email, password }) => {
      eventEmitter.emit("authStateChange", newLogin);
      currentLogin = { ...newLogin };
      return newLogin;
    };

  const loginWithPopup: AuthService["loginWithPopup"] = async () => {
    eventEmitter.emit("authStateChange", newLogin);
    currentLogin = { ...newLogin };
    return newLogin;
  };

  const loginWithRedirect: AuthService["loginWithRedirect"] = async () => {
    redirectResult = { ...newLogin };
  };

  const handleRedirectResult: AuthService["handleRedirectResult"] =
    async () => {
      if (redirectResult !== null) {
        eventEmitter.emit("authStateChange", redirectResult);
        currentLogin = { ...redirectResult };
        return redirectResult;
      }
      return null;
    };

  const logOut: AuthService["logOut"] = async () => {
    eventEmitter.emit("authStateChange", {
      userId: null,
      token: null,
      refreshToken: null,
      username: null,
    });
    currentLogin = {
      userId: null,
      token: null,
      refreshToken: null,
      username: null,
    };
  };

  const refreshJWT: AuthService["refreshJWT"] = async () => {};

  const onAuthStateChange: AuthService["onAuthStateChange"] = (
    authStateChangeHandler
  ) => {
    eventEmitter.on("authStateChange", authStateChangeHandler);

    const unsubscribe = () =>
      eventEmitter.off("authStateChange", authStateChangeHandler);

    return () => {
      unsubscribe();
    };
  };

  const unsubscribeAll = () => {
    eventEmitter.removeAllListeners("authStateChange");
  };

  const restoreSession: AuthService["restoreSession"] = async () => {
    const authState = args?.sessionLogin ?? currentLogin;

    eventEmitter.emit("authStateChange", authState);
    currentLogin = authState;
    return authState;
  };

  return {
    initialAuthState: currentLogin,
    loginWithEmailAndPassword,
    signUpWithEmailAndPassword,
    loginWithPopup,
    loginWithRedirect,
    handleRedirectResult,
    logOut,
    restoreSession,
    refreshJWT,
    onAuthStateChange,
    unsubscribeAll,
  };
};

export { createMemoryAuthService };
