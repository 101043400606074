import EventEmitter from "eventemitter3";
import { AuthService, Events } from "../types";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  EmailAuthProvider,
  signInWithRedirect,
  onAuthStateChanged,
  User,
  signOut,
  getRedirectResult,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";

type CreateServiceArgs = {
  clientId: string;
  authDomain: string;
  tenantId?: string;
};

const createFirebaseAuthService = (args: CreateServiceArgs) => {
  const configuration = {
    apiKey: args.clientId,
    authDomain: args.authDomain,
  };
  const app = initializeApp(configuration);
  const auth = getAuth(app);
  if (args.tenantId) {
    auth.tenantId = args.tenantId;
  }

  const emailProvider = new EmailAuthProvider();
  const eventEmitter = new EventEmitter<Events>();

  const getAuthStateFromUser = async (user: User | null) => {
    const userId = user?.uid ?? null;
    const token = (await user?.getIdToken()) ?? null;
    const refreshToken = user?.refreshToken ?? null;
    const username = user?.email ?? null;

    return {
      userId,
      token,
      refreshToken,
      username,
    };
  };

  const loginWithPopup: AuthService["loginWithPopup"] = async () => {
    const loginResult = await signInWithPopup(auth, emailProvider);
    return getAuthStateFromUser(loginResult.user);
  };

  const loginWithRedirect: AuthService["loginWithRedirect"] = async () => {
    await signInWithRedirect(auth, emailProvider);
  };

  const changePasswordWithRedirect: AuthService["changePasswordWithRedirect"] =
    async () => {
      // not sure if firebase allows the redirect flow
      // we probably just change password without redirecting
    };

  const loginWithEmailAndPassword: AuthService["loginWithEmailAndPassword"] =
    async ({ email, password }) => {
      const result = await signInWithEmailAndPassword(auth, email, password);
      const authState = getAuthStateFromUser(result.user);
      return authState;
    };

  const signUpWithEmailAndPassword: AuthService["signUpWithEmailAndPassword"] =
    async ({ email, password }) => {
      const result = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const authState = getAuthStateFromUser(result.user);
      return authState;
    };

  const handleRedirectResult: AuthService["handleRedirectResult"] =
    async () => {
      const result = await getRedirectResult(auth);
      const user = result?.user ?? null;
      const authState = await getAuthStateFromUser(user);

      return authState;
    };

  const logOut: AuthService["logOut"] = async () => {
    await signOut(auth);
  };

  const refreshJWT: AuthService["refreshJWT"] = async () => {
    await auth.currentUser?.getIdToken();
  };

  const firebaseUnsubscribe = onAuthStateChanged(auth, async (user) => {
    const authState = await getAuthStateFromUser(user);
    eventEmitter.emit("authStateChange", authState);
  });

  const onAuthStateChange: AuthService["onAuthStateChange"] = (
    authStateChangeHandler
  ) => {
    eventEmitter.on("authStateChange", authStateChangeHandler);

    const unsubscribe = () =>
      eventEmitter.off("authStateChange", authStateChangeHandler);

    return () => {
      unsubscribe();
    };
  };

  const unsubscribeAll = () => {
    firebaseUnsubscribe();
    eventEmitter.removeAllListeners("authStateChange");
  };

  const restoreSession: AuthService["restoreSession"] = async () => {
    // firebase does this automatically

    const result = await getRedirectResult(auth);
    const user = result?.user ?? null;

    const authState = await getAuthStateFromUser(user);

    return authState;
  };

  return {
    loginWithPopup,
    loginWithRedirect,
    changePasswordWithRedirect,
    handleRedirectResult,
    logOut,
    restoreSession,
    refreshJWT,
    onAuthStateChange,
    unsubscribeAll,
    loginWithEmailAndPassword,
    signUpWithEmailAndPassword,
  };
};

export { createFirebaseAuthService };
