import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";
import {
  IconProps,
  getDimensionsFromSize,
  getColorFromVariant,
} from "./shared";
import { useId } from "../../hooks";

const DownArrowIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);

  const id = useId();

  return (
    <svg
      aria-label={props["aria-label"] ?? "down arrow icon"}
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <mask id={id}>
          <rect fill="#FFF" width="100%" height="100%" />
          <path d="M8.167 10.762 5.63 6.369h5.073l-2.536 4.393Z" fill="#000" />
        </mask>
      </defs>
      <circle
        cx={8.167}
        cy={7.833}
        r={6.833}
        fill={fill}
        mask={`url(#${id})`}
      />
    </svg>
  );
};

export { DownArrowIcon };
