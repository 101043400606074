import { useAuth } from "@multiply/lib";
import { Link } from "react-router-dom";
import { ProductCard } from "../../components";
import { useGetDebtsQuery } from "../../graphqlTypes";
import { convertNumberToEuroString, getDebtNameFromType } from "../../utils";
import { EmptyProductCard } from "./EmptyProductCard";
import { ProductSection } from "./ProductSection";

const DebtsSection = () => {
  const { userId } = useAuth();

  const [debtsResult] = useGetDebtsQuery({
    variables: { userId: userId ?? "" },
  });
  const debts = debtsResult?.data?.debts ?? [];
  const balance = debts.reduce(
    (total: number, debt: any) => total + (debt.balance?.float ?? 0),
    0
  );

  return (
    <div className="flex flex-col">
      <ProductSection
        loading={debtsResult.fetching}
        title="Debts"
        subtitle="Credit cards, Student loans, Mortgages etc…"
        balance={convertNumberToEuroString(balance)}
      >
        {debts.length === 0 ? (
          <EmptyProductCard to="add/debts" aria-label="add debts" />
        ) : (
          debts.map((debt: any) => (
            <ProductCard
              to={`add/debt/${debt.id}`}
              key={debt.id}
              title={getDebtNameFromType(debt.productType)}
              subtitle={
                debt.isComplete
                  ? debt?.name ?? debt?.provider?.name ?? ""
                  : "Add details"
              }
              balance={debt.balance?.display ?? null}
            />
          ))
        )}
      </ProductSection>
      {debts.length > 0 && (
        <Link to="add/debt" className="text-t16 text-font-links mt-24">
          Add another debt
        </Link>
      )}
    </div>
  );
};

export { DebtsSection };
