import { Button, ContainerMidSize, useAuth } from "@multiply/lib";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CheckboxButtonGroup,
  ConversationCardTemplate,
} from "../../components";
import { DebtType, useUpdateDebtsMutation } from "../../graphqlTypes";
import { usePartnerStatus } from "../../hooks";
import { getDebtNameFromType, sortedDebtTypes } from "../../utils";

function AddDebts() {
  const { userId } = useAuth();
  const [debtTypes, setDebtTypes] = useState<DebtType[]>([]);
  const [addDebtsResult, addDebts] = useUpdateDebtsMutation();
  const navigate = useNavigate();

  const handleAddDebts = async () => {
    try {
      const newDebts = debtTypes.map((debtType) => ({
        productType: debtType,
      }));
      const result = await addDebts({
        input: { userId: userId ?? "", debts: newDebts },
      });

      if (result?.data?.updateDebts?.success) {
        const debts = result.data.updateDebts.debts;

        if (debts?.length === 1) {
          const debtId = debts[0]?.id;
          navigate(`/products/add/debt/${debtId}`);
        } else {
          navigate("/products");
        }
      }
    } catch (error) {}
  };

  const error = addDebtsResult.error ?? addDebtsResult.data?.updateDebts?.error;

  const hasPartner = usePartnerStatus();

  return (
    <ContainerMidSize>
      <ConversationCardTemplate
        className="max-w-960 mx-auto"
        title="Which debts do you have?"
        subtitle={`Select all of the ones you ${
          hasPartner ? "or your partner " : ""
        }currently have`}
      >
        <CheckboxButtonGroup
          value={debtTypes}
          onChange={setDebtTypes}
          options={Object.values(sortedDebtTypes).map((debtType) => ({
            value: debtType,
            label: getDebtNameFromType(debtType),
          }))}
        />
        <Button
          onClick={handleAddDebts}
          disabled={addDebtsResult.fetching || !debtTypes.length}
        >
          Next
        </Button>
        {error && <p className="text-action-error mt-16">{error.message}</p>}
      </ConversationCardTemplate>
    </ContainerMidSize>
  );
}

export { AddDebts };
