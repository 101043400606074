import {
  ContainerMaxSize,
  hasOwnProperty,
  Spinner,
  useAuth,
} from "@multiply/lib";
import { useLocation, useNavigate } from "react-router-dom";
import {
  PensionAtDate,
  RecommendationType,
  RetirementSummary,
  useGetFactFindQuery,
  useGetPlanRecommendationsQuery,
  useUpdateFactFindMutation,
} from "../../graphqlTypes";
import PensionSimulatorForm from "./PensionSimulatorForm";
import { PensionSimulatorFormValues } from "./usePensionSimulatorForm";

export type PensionDetails = {
  pensionProjection: PensionAtDate[];
  projectedIncome: number;
} & PensionSimulatorFormValues;

const PensionSimulator = () => {
  const { userId, loggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Get the current retirement age from factfind
  const [factFindResults] = useGetFactFindQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });
  const retirementAge =
    factFindResults.data?.factfind.userSpecificFactfind
      ?.retirementAgeEstimate ?? 0;

  // Get target monthly income from recommended pension plan
  const [planResult] = useGetPlanRecommendationsQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });
  const recommendationGroups =
    planResult?.data?.plan?.recommendationGroups ?? [];
  const retirementRecommendation = recommendationGroups.find(
    (group) => group.recommendationType === RecommendationType.Retirement
  );
  const summary = retirementRecommendation?.summary as
    | RetirementSummary
    | null
    | undefined;

  const projectedMonthlyIncome = summary?.currentProvision.float;
  const targetMonthlyIncome = summary?.targetProvision.float;

  const defaultValues = {
    targetMonthlyIncome: targetMonthlyIncome ?? null,
    monthlyContribution: 0,
    oneOffContribution: 0,
    retirementAge: retirementAge ?? null,
  };

  const [updateFactFindResult, updateFactfind] = useUpdateFactFindMutation();

  const loading =
    updateFactFindResult.fetching ||
    factFindResults.fetching ||
    planResult.fetching;

  const error =
    updateFactFindResult.error ||
    factFindResults.error ||
    planResult.error ||
    planResult.data?.plan.factfindErrors;

  const onSubmit = async (
    formvalues: PensionSimulatorFormValues,
    dirtyFields: string
  ) => {
    try {
      const result = await updateFactfind({
        input: {
          userId: userId ?? "",
          factfind: {
            userSpecificFactfind: {
              retirementAgeEstimate: formvalues.retirementAge,
              monthlyRetirementOutgoingExpected: formvalues.targetMonthlyIncome,
            },
          },
        },
      });
      if (result?.data?.updateFactfind.success) {
        const nextState =
          typeof location.state === "object" &&
          hasOwnProperty(location.state ?? {}, "backgroundLocation")
            ? location.state
            : { backgroundLocation: location };
        navigate(`/no-cross/retirement-goals-updated?updated=${dirtyFields}`, {
          state: nextState,
        });
      }
    } catch (error) {}
  };

  return (
    <div className="w-full flex justify-center">
      {loading ? (
        <Spinner />
      ) : (
        <ContainerMaxSize className="pt-0">
          <PensionSimulatorForm
            defaultValues={defaultValues}
            projectedIncome={projectedMonthlyIncome ?? 0}
            onSubmit={onSubmit}
          />
          {error ? <p className="text-action-error">{error.message}</p> : null}
        </ContainerMaxSize>
      )}
    </div>
  );
};

export { PensionSimulator };
