import {
  useGetAllCollegeCostsQuery,
  useGetBasicInfoFactFindQuery,
} from "../../graphqlTypes";
import { useAuth } from "@multiply/lib";

const useShowSuggestedChildEducationGoal = () => {
  const { userId } = useAuth();

  const [basicInfoResult] = useGetBasicInfoFactFindQuery({
    variables: { userId: userId ?? "" },
    pause: !userId,
  });

  const [collegeCostsResult] = useGetAllCollegeCostsQuery({
    variables: {
      userId: userId ?? "",
    },
    pause: !userId,
  });

  const showSuggestedChildEducationGoal =
    basicInfoResult?.data?.factfind?.hasChildren &&
    !collegeCostsResult?.data?.collegeCosts?.length;

  return Boolean(showSuggestedChildEducationGoal);
};

export { useShowSuggestedChildEducationGoal };
