import { Goal } from "../../graphqlTypes";
import { IconBubble } from "./IconBubble";

const IconBubbles = (props: any) => {
  const { datum } = props;

  let goalsToRender = datum.goals;

  if (goalsToRender && goalsToRender.length > 1)
    goalsToRender = [...datum.goals].reverse();

  return (
    <>
      {goalsToRender.map((goal: Goal, index: number) => {
        const id = goal?.id ?? index;
        let offset = goalsToRender.length - 1 - index;

        return (
          <IconBubble
            key={id}
            {...props}
            offset={offset}
            goalType={goal.goalType ?? "RETIREMENT"}
          />
        );
      })}
    </>
  );
};

export { IconBubbles };
