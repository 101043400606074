import classNames from "classnames";
import { FC } from "react";

type ContainerMaxSizeProps = {
  className?: string;
  children?: React.ReactNode;
};

const ContainerMaxSize: FC<ContainerMaxSizeProps> = ({
  className,
  children,
}) => {
  return (
    <div
      className={classNames(
        "py-48 w-full px-max-screen lg:px-max-screen-lg",
        className
      )}
    >
      {children}
    </div>
  );
};

export { ContainerMaxSize };
