import { FC } from "react";
import classNames from "classnames";
import { NavLink, NavLinkProps } from "react-router-dom";

type MenuLinkProps = {
  to: NavLinkProps["to"];
  end?: boolean;
  "aria-label": NavLinkProps["aria-label"];
  className?: string;
  children?: React.ReactNode;
  isPill?: boolean;
};

const MenuLink: FC<MenuLinkProps> = ({
  to,
  end,
  children,
  className,
  isPill,
  ...props
}) => {
  return (
    <NavLink
      to={to}
      end={end}
      aria-label={props["aria-label"]}
      className={({ isActive }) =>
        classNames(
          "flex flex-col items-center lg:px-12 lg:py-6 text-center justify-center",
          "lg:border-l-4 lg:border-r-4 lg:border-r-transparent",
          "gap-y-4 lg:gap-y-8 text-t11 lg:text-t14",
          {
            "[&_svg]:text-menu-active font-bold border-menu-active": isActive,
            "border-transparent font-normal": !isActive,
            "bg-menu-background lg:bg-transparent text-white px-8 sm:px-14":
              isActive && isPill,
            "rounded-sm sm:rounded-lg lg:rounded-none text-t16 sm:text-t21 py-6 sm:py-12 lg:p-0":
              isPill,
          },
          className
        )
      }
    >
      {children}
    </NavLink>
  );
};

export { MenuLink };
