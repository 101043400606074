import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SuggestedGoalCard } from "../../components";
import { PiggyBankOutlineIcon } from "@multiply/lib";
import { InteractionFlags } from "../../globalTypes";
import { useInteractionFlag } from "../../hooks";
import { hasOwnProperty } from "../../utils";

const SuggestedAddGoal = () => {
  const navigate = useNavigate();

  const { hasSuggestedGoals } = useParams();

  const completedGoalsWizard = useInteractionFlag(
    InteractionFlags.CompletedGoalsWizard
  );

  const onSkip = async () => {
    if (!completedGoalsWizard.value && !completedGoalsWizard.loading) {
      await completedGoalsWizard.update(true);
    }

    navigate("/advice/plan/view-goals");
  };

  const location = useLocation();

  const onAdd = async () => {
    const nextState =
      typeof location.state === "object" &&
      hasOwnProperty(location.state ?? {}, "backgroundLocation")
        ? location.state
        : { backgroundLocation: location };

    navigate("/cross/name-custom-goal", {
      state: nextState,
    });
  };

  return (
    <SuggestedGoalCard
      title={`Do you have any ${
        hasSuggestedGoals === "true" ? "other" : ""
      } financial goals within the next 6 years?`}
      subtitle={`Some common examples include buying a new ${
        hasSuggestedGoals === "true"
          ? "boiler, fixing a roof or redecorating a room"
          : "car, renovating a home and saving for a holiday"
      }. Add ${
        hasSuggestedGoals === "true" ? "anything" : "any goals"
      } you can think of.`}
      icon={<PiggyBankOutlineIcon className="text-icon-display" size="xxl" />}
      onAdd={onAdd}
      onSkip={onSkip}
      disabled={completedGoalsWizard.loading}
      onSkipText="No"
      disclaimer={
        hasSuggestedGoals === "true"
          ? "Remember: you can always edit your goals later"
          : ""
      }
    />
  );
};

export { SuggestedAddGoal };
