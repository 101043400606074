import isEqual from "lodash/isEqual";
import { AssetOrigin } from "../../graphqlTypes";
import { Dropdown } from "@multiply/lib";

type InputWhereFromTypes = {
  value: AssetOrigin | null;
  onChange: (value: AssetOrigin | null) => void;
  onBlur: () => void;
  error?: string;
};

const InputWhereFrom = ({
  value,
  onChange,
  onBlur,
  error,
}: InputWhereFromTypes) => {
  const originToItem = (assetOrigin: AssetOrigin) => {
    switch (assetOrigin) {
      case AssetOrigin.Bonus:
        return { value: AssetOrigin.Bonus, label: "Bonus" };
      case AssetOrigin.Borrowing:
        return { value: AssetOrigin.Borrowing, label: "Borrowing" };
      case AssetOrigin.Compensation:
        return { value: AssetOrigin.Compensation, label: "Compensation" };
      case AssetOrigin.Gift:
        return { value: AssetOrigin.Gift, label: "Gift" };
      case AssetOrigin.Inheritance:
        return { value: AssetOrigin.Inheritance, label: "Inheritance" };
      case AssetOrigin.LifeAssuranceClaimDeath:
        return {
          value: AssetOrigin.LifeAssuranceClaimDeath,
          label: "Life Assurance Claim (Death)",
        };
      case AssetOrigin.LifeAssuranceClaimIllness:
        return {
          value: AssetOrigin.LifeAssuranceClaimIllness,
          label: "Life Assurance Claim (Illness)",
        };
      case AssetOrigin.Other:
        return { value: AssetOrigin.Other, label: "Other" };
      case AssetOrigin.PensionLumpSum:
        return { value: AssetOrigin.PensionLumpSum, label: "Pension Lump Sum" };
      case AssetOrigin.Redundancy:
        return { value: AssetOrigin.Redundancy, label: "Redundancy" };
      case AssetOrigin.RegularSavings:
        return { value: AssetOrigin.RegularSavings, label: "Regular Savings" };
      case AssetOrigin.Reinvestment:
        return { value: AssetOrigin.Reinvestment, label: "Reinvestment" };
      case AssetOrigin.SaleOfInvestment:
        return {
          value: AssetOrigin.SaleOfInvestment,
          label: "Sale of Investment",
        };
      case AssetOrigin.SaleOfTheFamilyHome:
        return {
          value: AssetOrigin.SaleOfTheFamilyHome,
          label: "Sale of the Family Home",
        };
      case AssetOrigin.Windfall:
        return { value: AssetOrigin.Windfall, label: "Windfall" };
      case AssetOrigin.Salary:
        return { value: AssetOrigin.Salary, label: "Salary" };

      default:
        const unreachable: never = assetOrigin;
        return unreachable;
    }
  };

  const items = [
    ...[
      AssetOrigin.Salary,
      AssetOrigin.RegularSavings,
      AssetOrigin.Bonus,
      AssetOrigin.Reinvestment,
    ].map(originToItem),
    { value: undefined, disabled: true, label: "----" },
    ...[
      AssetOrigin.Borrowing,
      AssetOrigin.Compensation,
      AssetOrigin.Gift,
      AssetOrigin.Inheritance,
      AssetOrigin.LifeAssuranceClaimDeath,
      AssetOrigin.LifeAssuranceClaimIllness,
      AssetOrigin.PensionLumpSum,
      AssetOrigin.Redundancy,
      AssetOrigin.SaleOfInvestment,
      AssetOrigin.SaleOfTheFamilyHome,
      AssetOrigin.Windfall,
      AssetOrigin.Other,
    ].map(originToItem),
  ];

  const selectedValue =
    items.find((item) => isEqual(item.value, value)) ?? null;

  return (
    <Dropdown
      aria-label="dropdown to see list of sources of asset origin"
      error={error}
      label="Where this came from"
      items={items}
      placeholder="Select where this came from"
      value={selectedValue}
      onChange={(item) => {
        onChange(item?.value ?? null);
      }}
      onBlur={onBlur}
    />
  );
};

export { InputWhereFrom };
