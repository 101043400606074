import { SVGProps } from "react";

const Dental = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M17.988 36c9.941 0 18-8.059 18-18s-8.059-18-18-18c-9.94 0-18 8.059-18 18s8.06 18 18 18Z"
        fill="url(#b)"
      />
      <path
        clipRule="evenodd"
        d="M18.052 26.043c2.413 0 .859 6.175 2.467 5.79 3.363-.808 1.967-7.874 1.967-7.874s1.519-2.084 1.25-4.169c-.268-2.084-2.783-2.3-3.841-2.084-1.52.31-1.901.243-1.901.243h-.04s-.334.066-1.854-.243c-1.058-.215-3.574 0-3.842 2.084-.269 2.085 1.25 4.169 1.25 4.169s-1.396 7.066 1.967 7.873c1.609.386.082-5.79 2.495-5.79h.082Z"
        stroke="#50C9B5"
        strokeWidth={1.08}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={17.988}
        y1={29.211}
        x2={17.988}
        y2={-1.601}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#EFF4FA" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);

export { Dental };
