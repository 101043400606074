import classNames from "classnames";
import { GoalType } from "../../graphqlTypes";

type DisplayGoalTitleProps = {
  goalType: GoalType | null;
  className?: string;
};
const DisplayGoalTitle = ({ goalType, className }: DisplayGoalTitleProps) => {
  let title = "";
  switch (goalType) {
    case GoalType.BuyHome:
      title = "Buy a home";
      break;
    case GoalType.ChildEducation:
      title = "Child's education";
      break;
    case GoalType.Custom:
      title = "Custom goal";
      break;
    case GoalType.Wedding:
      title = "Fund a wedding";
      break;
  }

  return <h1 className={classNames(className)}>{title}</h1>;
};

export { DisplayGoalTitle };
