import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Dropdown } from "@multiply/lib";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { DateObject } from "../../globalTypes";
import { AssetOrigin, AssetType, PaymentFrequency } from "../../graphqlTypes";
import { assetValidationFields } from "../../hooks";
import { getAssetNameFromType } from "../../utils";
import { AssetField } from "./AssetField";

type SavingsDepositFormFields = {
  productType:
    | AssetType.SavingsDepositAccount
    | AssetType.RegularSavingsDepositAccount
    | AssetType.DepositTracker;
  provider: string;
  owners: string[];
  balance: number;
  whereFrom: AssetOrigin;
  regularPaymentAmount: number; // Regular savings
  frequency: PaymentFrequency; // Regular savings
  maturityDate: DateObject; // Deposit tracker
  name: string;
  accessible: boolean;
};

const schema = yup.object().shape({
  productType: assetValidationFields.productType,
  provider: assetValidationFields.provider,
  owners: assetValidationFields.owners,
  balance: assetValidationFields.balance,
  whereFrom: assetValidationFields.whereFrom,
  regularPaymentAmount: assetValidationFields.regularPaymentAmount.when(
    "productType",
    {
      is: AssetType.RegularSavingsDepositAccount,
      otherwise: (schema) => schema.optional(),
    }
  ),
  frequency: assetValidationFields.frequency.when("productType", {
    is: AssetType.RegularSavingsDepositAccount,
    then: (schema) => schema.required("Product type is required"),
    otherwise: (schema) => schema.optional(),
  }),
  maturityDate: assetValidationFields.maturityDate.when("productType", {
    is: AssetType.DepositTracker,
    then: (schema) => schema.required("Maturity date is required"),
    otherwise: () => yup.string().nullable(),
  }),
  name: assetValidationFields.name,
  accessible: assetValidationFields.accessible,
});

type SavingsDepositFormProps = {
  onSubmit: (values: any) => Promise<void>;
  defaultValues: SavingsDepositFormFields;
};

const SavingsDepositForm = ({
  onSubmit,
  defaultValues,
}: SavingsDepositFormProps) => {
  const {
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting, isValid, isDirty },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    delayError: 1000,
    mode: "all",
  });

  const productType = watch("productType");
  const name = getAssetNameFromType(productType);

  return (
    <form className="flex flex-col items-center w-full">
      <div className="flex items-center self-start mb-60">
        <h1 className="text-t27 text-font-primary">{name}</h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-x-32 gap-y-32 self-stretch">
        <Controller
          name="productType"
          control={control}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error },
          }) => (
            <Dropdown
              aria-label="dropdown to see list of savings deposit options"
              error={error?.message}
              label="Type"
              items={[
                AssetType.SavingsDepositAccount,
                AssetType.RegularSavingsDepositAccount,
                AssetType.DepositTracker,
              ]}
              itemToString={getAssetNameFromType}
              placeholder="Select asset type"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
            />
          )}
        />
        <AssetField field="provider" control={control} />
        <AssetField field="owners" control={control} />
        <AssetField
          field={{ fieldName: "balance", label: "Current value" }}
          control={control}
        />
        <AssetField field="whereFrom" control={control} />
        {productType === AssetType.RegularSavingsDepositAccount ? (
          <AssetField field="regularPaymentAmount" control={control} />
        ) : null}
        {productType === AssetType.RegularSavingsDepositAccount ? (
          <AssetField field="frequency" control={control} />
        ) : null}
        {productType === AssetType.DepositTracker ? (
          <AssetField
            field={{ fieldName: "maturityDate", label: "Maturity date" }}
            control={control}
          />
        ) : null}
        <AssetField field="name" control={control} />
        <AssetField field="accessible" control={control} />
      </div>

      <Button
        className="mt-96 w-240"
        onClick={handleSubmit(onSubmit)}
        disabled={isSubmitting || !isValid || !isDirty}
      >
        Save
      </Button>
    </form>
  );
};

export { SavingsDepositForm };
