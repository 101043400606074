import { ContainerMaxSize, useAuth } from "@multiply/lib";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { InteractionFlags } from "../../globalTypes";
import {
  AuthorisationStatus,
  useGetPlanAdviceInfoQuery,
} from "../../graphqlTypes";
import { useInteractionFlag } from "../../hooks";
import { Loading } from "../Loading";
import { PrePlan } from "./PrePlan";

function Home() {
  const { userId, loggedIn } = useAuth();
  const navigate = useNavigate();
  const [planResult] = useGetPlanAdviceInfoQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const authorisationStatus = planResult?.data?.plan?.authorisationStatus;

  const completedGenerateLifePlan = useInteractionFlag(
    InteractionFlags.CompletedGenerateLifePlan
  );

  const loading =
    (planResult?.fetching || completedGenerateLifePlan.loading) &&
    !planResult.data?.plan;

  const isPostPlan =
    authorisationStatus === AuthorisationStatus.AdviceApproved &&
    completedGenerateLifePlan.value;

  useEffect(() => {
    if (loading) {
      return;
    }
    if (isPostPlan) {
      navigate("/advice/plan", { replace: true });
    }
  });

  return (
    <ContainerMaxSize>
      {loading ? (
        <div className="h-full w-full flex items-center justify-center">
          <Loading />
        </div>
      ) : (
        <PrePlan />
      )}
    </ContainerMaxSize>
  );
}

export { Home };
