import { useAuth } from "@multiply/lib";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { ProductCard } from "../../components";
import { useGetAssetsQuery } from "../../graphqlTypes";
import { convertNumberToEuroString, getAssetNameFromType } from "../../utils";
import { getPensionNameFromType } from "../../utils/getPensionNameFromType";
import { ProductSection } from "../Products";
import { EmptyPensionCard } from "./EmptyPensionCard";

type OverviewPensionsProps = {
  className?: string;
};

const OverviewPensions = ({ className }: OverviewPensionsProps) => {
  const { userId } = useAuth();

  const [assetsResult] = useGetAssetsQuery({
    variables: { userId: userId ?? "" },
  });

  const allAssets = assetsResult?.data?.assets ?? [];

  const pensions = allAssets.filter(
    (asset) => asset?.productType === "PENSION"
  );

  const balance = pensions.reduce(
    (total, pension) => total + (pension?.balance?.float ?? 0),
    0
  );

  return (
    <div
      className={classNames("flex flex-col flex-wrap space-y-16", className)}
    >
      <ProductSection
        loading={assetsResult.fetching}
        title="Pensions"
        balance={convertNumberToEuroString(balance)}
        variant="segmented"
      >
        {pensions.map((pension) => (
          <ProductCard
            to={`add/pension/${pension?.id}`}
            key={pension?.id}
            title={getAssetNameFromType(pension?.productType)}
            subtitle={
              pension?.isComplete
                ? getPensionNameFromType(pension?.pensionType) ?? ""
                : "Add details"
            }
            balance={pension?.balance?.display ?? null}
            className="mb-15 shadow-primary rounded-lg"
          />
        ))}

        <Link to={pensions.length === 0 ? "add/pensions" : "add/pension"}>
          <EmptyPensionCard
            aria-label="add pensions"
            buttonText={
              pensions.length === 0 ? "Add a pension" : "Add another pension"
            }
          />
        </Link>
      </ProductSection>
    </div>
  );
};

export { OverviewPensions };
