import { DisplayGoalSubTitle, TextInput } from "../../components";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Button, useAuth, PiggyBankOutlineIcon } from "@multiply/lib";
import { useNavigate } from "react-router-dom";
import { GoalType, useUpdateGoalMutation } from "../../graphqlTypes";
import { useInteractionFlag } from "../../hooks";
import { InteractionFlags } from "../../globalTypes";

const schema = yup.object().shape({
  goalName: yup.string().required("Please provide a name").nullable(),
});

const NameCustomGoal = () => {
  const navigate = useNavigate();
  const [updateGoalResult, updateGoal] = useUpdateGoalMutation();
  const { userId } = useAuth();
  let error = updateGoalResult?.error?.message;

  const completedGoalsWizard = useInteractionFlag(
    InteractionFlags.CompletedGoalsWizard
  );

  const { control, formState, handleSubmit } = useForm({
    defaultValues: { goalName: "" },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const { isSubmitting, isValid } = formState;

  const onSubmit = async (formValues: any) => {
    const result = await updateGoal({
      input: {
        userId: userId ?? "",
        goal: {
          goalType: GoalType.Custom,
          name: formValues.goalName ?? "Custom goal",
        },
      },
    });

    if (!completedGoalsWizard.value && !completedGoalsWizard.loading) {
      await completedGoalsWizard.update(true);
    }

    if (result?.data?.updateGoal?.success) {
      if (result?.data?.updateGoal?.success) {
        navigate("/advice/plan/view-goals");
      }
    }
  };

  return (
    <div className="flex flex-col justify-center items-center md:px-120 mt-12 sm:mt-48 w-full">
      <PiggyBankOutlineIcon className="text-icon-display" size="xl" />
      <h1 className="text-t21 sm:text-t34 text-font-primary mb-12 text-center">
        What are you saving for?
      </h1>
      <DisplayGoalSubTitle goalType={GoalType.Custom} goalName={null} />

      <Controller
        control={control}
        name="goalName"
        render={({ field: { value, onChange, onBlur }, fieldState }) => (
          <TextInput
            placeholder="Enter name"
            label="Name of goal"
            className="w-full my-40"
            error={fieldState?.error?.message}
            value={value ?? ""}
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
      />
      <Button
        disabled={!isValid || isSubmitting || updateGoalResult.fetching}
        variant="primary"
        className="w-full"
        onClick={handleSubmit(onSubmit)}
      >
        Add goal
      </Button>
      <Button
        variant="plain"
        size="sm"
        className="my-32 text-action-error"
        onClick={() => navigate(-1)}
      >
        Cancel
      </Button>
      {error ? <p className="mt-12 text-action-error">{error}</p> : null}
    </div>
  );
};

export { NameCustomGoal };
