import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Control, FieldArrayWithId } from "react-hook-form";
import { EditGoalCard, ModalNavLink } from "../../components";
import { GetGoalQuery, GoalType } from "../../graphqlTypes";
import { GoalsFormValues, useInteractionFlag } from "../../hooks";
import { useWindowDimensions, CircledPlusIcon } from "@multiply/lib";
import { InteractionFlags } from "../../globalTypes";

type Goal = NonNullable<GetGoalQuery["goal"]>;

type ViewGoalsFormProps = {
  control: Control<GoalsFormValues, any>;
  goalsFields: FieldArrayWithId<GoalsFormValues, "goals", "id">[];
  onChangeGoalIndex: (oldIndex: number) => (newIndex: number) => void;
  goals: (Goal | null)[];
};

const ViewGoalsForm = ({
  control,
  goalsFields,
  onChangeGoalIndex,
  goals,
}: ViewGoalsFormProps) => {
  const { isLessThanCustomWidth } = useWindowDimensions(1200);
  const completedGenerateLifePlan = useInteractionFlag(
    InteractionFlags.CompletedGenerateLifePlan
  );
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div className="space-y-16 flex flex-col">
          {goalsFields.map((field, index) => {
            return (
              <EditGoalCard
                goalName={
                  goals.find((goal) => goal?.id === field.goalId)?.name ?? ""
                }
                key={field.id}
                onChangeIndex={onChangeGoalIndex(index)}
                field={field}
                index={index}
                control={control}
                maxIndex={goalsFields.length - 1}
                goalType={field.goalType ?? GoalType.Custom}
                factFindCompleted={!!completedGenerateLifePlan.value ?? false}
              />
            );
          })}
        </div>
      </DndProvider>

      {isLessThanCustomWidth ? (
        <div className="w-full flex justify-center mt-32">
          <ModalNavLink to="/cross/add-goal-type" className="my-16 w-fit">
            <p className="text-font-links">Add another goal</p>
          </ModalNavLink>
        </div>
      ) : (
        <ModalNavLink
          to="/cross/add-goal-type"
          className="flex items-center mt-60 w-fit"
        >
          <CircledPlusIcon aria-label="add goal icon" className="mr-12" />
          <p className="text-font-links text-t27">Add another goal</p>
        </ModalNavLink>
      )}
    </>
  );
};

export { ViewGoalsForm };
