import { CURRENCY_SYMBOL } from "./getCurrencySymbol";

const currencyOrNull = (value: number | null | undefined) => {
  if (value === null || value === undefined) {
    return null;
  }
  return `${CURRENCY_SYMBOL}${value.toLocaleString("en-US")}`;
};

export { currencyOrNull };
