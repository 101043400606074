import { AssetType } from "../graphqlTypes";

export type UserFacingAssetType = Exclude<
  AssetType,
  AssetType.RegularSavingsDepositAccount | AssetType.DepositTracker
>;

const sortedAssetTypes: UserFacingAssetType[] = [
  AssetType.SavingsDepositAccount,
  AssetType.CurrentAccount,
  AssetType.Pension,
  AssetType.CreditUnionAccount,
  AssetType.Cash,
  AssetType.Shares,
  AssetType.InvestmentBond,
  AssetType.Property,
  AssetType.Land,
  AssetType.AnPostSavingsAccount,
  AssetType.ApprovedRetirementFund,
  AssetType.LifeCompanySavingsPlan,
  AssetType.TermAccount,
  AssetType.GovernmentBond,
  AssetType.OtherAsset,
];

export { sortedAssetTypes };
