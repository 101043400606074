import { SVGProps, FC } from "react";

const DottedSquareIcon: FC<SVGProps<SVGSVGElement>> = ({
  className,
  ...props
}) => {
  return (
    <svg
      aria-label={props["aria-label"] ?? "missing square icon"}
      className={className}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.340608"
        y="0.340608"
        width="49.1673"
        height="49.1673"
        rx="7.83398"
        stroke="black"
        strokeWidth="0.681215"
        strokeDasharray="6.81 6.81"
      />
    </svg>
  );
};

export { DottedSquareIcon };
