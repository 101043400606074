import isEqual from "lodash/merge";
import { CoverType } from "../../graphqlTypes";
import { Dropdown } from "@multiply/lib";

type InputCoverTypeTypes = {
  value: CoverType | null;
  onChange: (value: CoverType | null) => void;
  onBlur: () => void;
  error?: string;
};

const InputCoverType = ({
  value,
  onChange,
  onBlur,
  error,
}: InputCoverTypeTypes) => {
  const items = [
    { label: "Mortgage Protection", value: CoverType.MortgageProtection },
    { label: "Decreasing Life Cover", value: CoverType.Decreasing },
    { label: "Level Life Cover", value: CoverType.Level },
    { label: "Whole of Life", value: CoverType.WholeOfLife },
    { label: "Death in Service", value: CoverType.DeathInService },
  ];

  const selectedValue =
    items.find((item) => isEqual(item.value, value)) ?? null;

  return (
    <Dropdown
      aria-label="dropdown to see list of types of cover"
      error={error}
      label="Type"
      items={items}
      placeholder="Select type"
      value={selectedValue}
      onChange={(item) => {
        onChange(item?.value ?? null);
      }}
      onBlur={onBlur}
    />
  );
};

export { InputCoverType };
