import { dequal } from "dequal";
import { useRef } from "react";

const useDeepMemoizedValue = <T>(value: T): T => {
  const ref = useRef<T>(value);

  if (!dequal(ref.current, value)) {
    ref.current = value;
  }

  return ref.current;
};

export { useDeepMemoizedValue };
