import { ButtonLink, Card, useAuth } from "@multiply/lib";
import { ModalNavLink, PensionsPageTemplate } from "../../components";
import {
  EmploymentStatus,
  EmploymentType,
  useGetFactFindQuery,
} from "../../graphqlTypes";
import { currencyOrNull } from "../../utils";
import { EditField } from "./EditField";
import { Loading } from "../Loading";

const EmploymentDetailsCheck = () => {
  const { userId, loggedIn } = useAuth();

  const [factFindResults] = useGetFactFindQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });
  const mainEmploymentType =
    factFindResults?.data?.factfind?.userSpecificFactfind?.mainEmploymentType;
  const employmentStatus =
    factFindResults?.data?.factfind?.userSpecificFactfind?.employmentStatus;
  const baseAnnualIncomeGross =
    factFindResults?.data?.factfind?.userSpecificFactfind
      ?.baseAnnualIncomeGross;
  const expectedOtherIncomeAmount =
    factFindResults?.data?.factfind?.userSpecificFactfind
      ?.expectedOtherIncomeAmount ;
  const benefitInKind =
    factFindResults?.data?.factfind?.userSpecificFactfind
      ?.benefitInKind?.float ;
  const expectedBonusAmount =
    factFindResults?.data?.factfind?.userSpecificFactfind
      ?.expectedBonusAmount ;
  const memberOfEmployersPensionScheme =
    factFindResults?.data?.factfind?.userSpecificFactfind
      ?.memberOfEmployersPensionScheme

    
  const totalIncome = (baseAnnualIncomeGross ?? 0) + (expectedOtherIncomeAmount ?? 0) + (benefitInKind ?? 0) + (expectedBonusAmount ?? 0)

  return (
    <PensionsPageTemplate className="pt-[10%] sm:px-24 pb-24">
      <h1 className="text-font-primary font-semibold text-t21 sm:text-t32 mb-28 text-center">
        Before we get started, are these details correct?
      </h1>

      {factFindResults.fetching ? (
        <Loading />
      ) : (
        <Card className="flex flex-col sm:flex-row justify-center gap-20 sm:gap-40 py-32 px-20 mx-20 sm:mx-0">
          <EditField
            label="Employment status"
            value={
              employmentStatus === EmploymentStatus.Employed &&
              mainEmploymentType
                ? mainEmploymentType
                : employmentStatus
            }
            fieldName="employment_status_pensions"
          />
          {employmentStatus === EmploymentStatus.Employed &&
            mainEmploymentType === EmploymentType.RegularEmployment && (
              <EditField
                fieldName="has_pension"
                label="In employer’s pension scheme"
                value={
                  memberOfEmployersPensionScheme === true
                    ? "Yes"
                    : memberOfEmployersPensionScheme === false
                    ? "No"
                    : null
                }
              />
            )}
          <EditField
            fieldName="income"
            label="Total annual income including bonuses, other income and benefits in kind"
            value={currencyOrNull(totalIncome)}
          />
        </Card>
      )}

      <ButtonLink
        to="/pensions/add-basic-details"
        className="mt-24 sm:mt-53 sm:text-t16"
        size="sm"
      >
        These details are correct
      </ButtonLink>
      <ModalNavLink
        to="/cross/change-details"
        className="mt-32 text-font-links"
      >
        I need to change something
      </ModalNavLink>
    </PensionsPageTemplate>
  );
};

export { EmploymentDetailsCheck };
