import { Button } from "@multiply/lib";
import { useEffect, useState } from "react";
import { IIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";

type AuthIdlePromptProps = {
  idleTimer: IIdleTimer;
};

const AuthIdlePrompt = ({ idleTimer }: AuthIdlePromptProps) => {
  const navigate = useNavigate();
  const { getRemainingTime, reset } = idleTimer;
  const [remainingTime, setRemainingTime] = useState(
    Math.ceil(getRemainingTime() / 1000)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(Math.ceil(getRemainingTime() / 1000));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime]);

  const onStillHere = () => {
    reset();
    navigate(-1);
  };

  return (
    <div className="fixed w-full h-full flex flex-col items-center">
      <h1 className="text-t21 lg:text-t27 text-font-primary font-bold pb-16">
        You are about to be logged out
      </h1>
      <h2 className="lg:text-21 text-font-primary pb-24">{remainingTime}</h2>

      <div className="flex flex-col items-center gap-24">
        <Button
          onClick={onStillHere}
          className="py-16 rounded-lg border-[1px] border-input-border-inner-border text-t21 text-font-primary w-216"
        >
          I'm still here
        </Button>
      </div>
    </div>
  );
};

export { AuthIdlePrompt };
