import { InteractionFlags } from "../../globalTypes";
import { useInteractionFlag } from "../useInteractionFlag";

const useResumeFactFindLink = (lastFlagToConsider?: InteractionFlags) => {
  const completedFactFindIntro = useInteractionFlag(
    InteractionFlags.CompletedFactFindIntro
  );

  const completedBasicFactFind = useInteractionFlag(
    InteractionFlags.CompletedBasicFactFind
  );

  const completedGoalsWizard = useInteractionFlag(
    InteractionFlags.CompletedGoalsWizard
  );

  const completedViewGoals = useInteractionFlag(
    InteractionFlags.CompletedViewGoals
  );

  const completedFinancialsFactFind = useInteractionFlag(
    InteractionFlags.CompletedFinancialsFactFind
  );

  const completedAddProducts = useInteractionFlag(
    InteractionFlags.CompletedAddProducts
  );

  const completedGenerateLifePlan = useInteractionFlag(
    InteractionFlags.CompletedGenerateLifePlan
  );

  const loading =
    completedFactFindIntro.loading ||
    completedBasicFactFind.loading ||
    completedViewGoals.loading ||
    completedGoalsWizard.loading ||
    completedViewGoals.loading ||
    completedFinancialsFactFind.loading ||
    completedAddProducts.loading ||
    completedGenerateLifePlan.loading;

  const flagArr = [
    [
      InteractionFlags.CompletedFactFindIntro,
      "/advice/plan/goals-wizard/create-plan-right-time",
      completedFactFindIntro.value,
    ],
    [
      InteractionFlags.CompletedBasicFactFind,
      "/advice/plan/basic-info",
      completedBasicFactFind.value,
    ],
    [
      InteractionFlags.CompletedGoalsWizard,
      "/advice/plan/goals-wizard",
      completedGoalsWizard.value,
    ],
    [
      InteractionFlags.CompletedViewGoals,
      "/advice/plan/view-goals",
      completedViewGoals.value,
    ],
    [
      InteractionFlags.CompletedFinancialsFactFind,
      "/advice/plan/financial-situation",
      completedFinancialsFactFind.value,
    ],
    [
      InteractionFlags.CompletedAddProducts,
      "/products",
      completedAddProducts.value,
    ],
    [
      InteractionFlags.CompletedGenerateLifePlan,
      "/advice/plan/generate",
      completedGenerateLifePlan.value,
    ],
  ] as const;

  const flag = flagArr.find(
    ([flag, route, completed]) => flag === lastFlagToConsider || !completed
  );
  const link = flag?.[1] ?? "/advice";

  const hasStarted = Boolean(
    completedAddProducts.value ||
      completedFinancialsFactFind.value ||
      completedViewGoals.value ||
      completedGoalsWizard.value ||
      completedBasicFactFind.value ||
      completedFactFindIntro.value ||
      completedGenerateLifePlan.value
  );

  return {
    loading,
    link,
    hasStarted,
  };
};

export { useResumeFactFindLink };
