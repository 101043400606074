import { Input, InputProps } from "@multiply/lib";
import {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  useRef,
} from "react";
import { useId } from "../../hooks";

type DateInputProps = {
  value: string | null; // ISO date string (YYYY-MM-DD)
  onChange: (value: string) => void;
  onBlur: () => void;
  onKeyUp?: React.ComponentProps<"fieldset">["onKeyUp"];
  className?: string;
  error?: string;
  helperText?: React.ReactNode;
  format?: "DD" | "DDMM" | "MMYYYY";
} & Omit<InputProps, "onChange" | "value">;

const DateInput = forwardRef<HTMLFieldSetElement, DateInputProps>(
  (
    {
      value,
      onChange,
      onBlur,
      className,
      error,
      format,
      onKeyUp,
      label,
      helperText,
      id,
      disabled,
      onSubmit,
      ...inputProps
    },
    ref
  ) => {
    const match =
      typeof value === "string"
        ? value?.match(/^(?<year>[^-]*)(?:-(?<month>[^-]*)(?:-(?<day>.*))?)?$/)
        : null;

    const hideDayInput = format === "MMYYYY";
    const showDayOnly = format === "DD";
    const dayValue = hideDayInput ? "01" : match?.groups?.day ?? "";
    const monthValue = match?.groups?.month ?? "";
    const yearValue = match?.groups?.year ?? "";

    const monthRef = useRef<HTMLInputElement>(null);
    const yearRef = useRef<HTMLInputElement>(null);

    const baseId = useId(id);

    const formatValue = (year: string, month: string, day: string) => {
      return `${year}-${month}-${day}`;
    };

    const handleChangeDay: ChangeEventHandler<HTMLInputElement> = (event) => {
      const nextDayValue = event.target.value;
      onChange(formatValue(yearValue, monthValue, nextDayValue));
      if (nextDayValue.length === 2 && monthRef.current) {
        monthRef.current.focus();
        monthRef.current.select();
      }
    };

    const handleChangeMonth: ChangeEventHandler<HTMLInputElement> = (event) => {
      const nextMonthValue = event.target.value;
      onChange(formatValue(yearValue, nextMonthValue, dayValue));
      if (nextMonthValue.length === 2 && yearRef.current) {
        yearRef.current.focus();
        yearRef.current.select();
      }
    };

    const handleChangeYear: ChangeEventHandler<HTMLInputElement> = (event) => {
      const nextYearValue = event.target.value;
      onChange(formatValue(nextYearValue, monthValue, dayValue));
    };

    const handleBlur: FocusEventHandler<HTMLFieldSetElement> = (event) => {
      // trigger blur when focus is moving outside of the fieldset
      if (!event.currentTarget.contains(event.relatedTarget)) {
        onBlur && onBlur();
      }
    };

    const overallErrorId = `${baseId}-hint`;

    let errorMessage = error ?? "";
    if (error && hideDayInput) errorMessage = errorMessage.replace("DD-", "");

    return (
      <fieldset
        ref={ref}
        role="group"
        className={className}
        onBlur={handleBlur}
        onKeyUp={onKeyUp}
      >
        <legend className="text-input-label font-semibold text-t16 pb-8">
          {label}
        </legend>
        {helperText && <p className="text-font-secondary">{helperText}</p>}

        <div className="flex space-x-8">
          {!hideDayInput && (
            <Input
              className="min-w-80 flex-1"
              aria-errormessage={error && overallErrorId}
              placeholder="DD"
              disabled={disabled}
              aria-label="day"
              value={dayValue}
              onChange={handleChangeDay}
              inputMode="numeric"
              align="center"
              maxLength={2}
              invalid={error ? true : undefined}
              onSubmit={onSubmit}
            />
          )}

          {!showDayOnly && (
            <Input
              ref={monthRef}
              className="min-w-80 flex-1"
              aria-errormessage={error && overallErrorId}
              aria-label="month"
              placeholder="MM"
              disabled={disabled}
              value={monthValue}
              onChange={handleChangeMonth}
              inputMode="numeric"
              align="center"
              maxLength={2}
              invalid={error ? true : undefined}
              onSubmit={onSubmit}
            />
          )}

          {!showDayOnly && (
            <Input
              ref={yearRef}
              className="min-w-80 flex-1"
              aria-errormessage={error && overallErrorId}
              aria-label="year"
              placeholder="YYYY"
              disabled={disabled}
              value={yearValue}
              onChange={handleChangeYear}
              inputMode="numeric"
              align="center"
              maxLength={4}
              invalid={error ? true : undefined}
              onSubmit={onSubmit}
            />
          )}
        </div>

        {error && (
          <p className="text-t16 text-action-error mt-4" id={overallErrorId}>
            {errorMessage}
          </p>
        )}
      </fieldset>
    );
  }
);

export { DateInput };
