import { VictoryBar, VictoryStack } from "victory";

type SavingsGraphProps = {
  portions: number[];
};

const SavingsGraph = ({ portions }: SavingsGraphProps) => {
  return (
    <>
      <VictoryStack
        padding={{ top: 0, bottom: 0, right: 0, left: 0 }}
        height={20}
        horizontal
        colorScale={["#5261AC", "#B1BCF6", "#E2E7FF"]}
      >
        {portions.map((portion) => (
          <VictoryBar barRatio={1} data={[{ x: "a", y: portion }]} />
        ))}
      </VictoryStack>
    </>
  );
};

export { SavingsGraph };
