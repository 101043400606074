import classNames from "classnames";
import "./slider.css";

type SliderProps = {
  min: number;
  max: number;
  value: number | null;
  onChange: (value: number | null) => void;
  className?: string;
  disabled?: boolean;
};

const Slider = ({
  min,
  max,
  value,
  onChange,
  className,
  disabled,
}: SliderProps) => {
  return (
    <div className={classNames("w-full", className)}>
      <input
        type="range"
        id="slider"
        value={value?.toString()}
        min={min ?? 0}
        max={max ?? 0}
        step="1"
        className="w-full appearance-none focus:outline-none"
        onChange={(e) => onChange(parseInt(e.target.value))}
        disabled={disabled}
      />
    </div>
  );
};

export { Slider };
