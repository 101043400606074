/**
 * Type checked version of Object.hasOwnProperty e.g.
 *
 * if(hasOwnProperty(person, 'name')) {
 *  console.log(person.name)
 * }
 */
function hasOwnProperty<X extends {}, Y extends PropertyKey>(
  obj: unknown,
  prop: Y
): obj is X & Record<Y, unknown> {
  return (
    obj !== undefined &&
    typeof obj === "object" &&
    obj !== null &&
    obj.hasOwnProperty(prop)
  );
}

export { hasOwnProperty };
