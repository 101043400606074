import { Button, Card } from "@multiply/lib";

export function EditAddress() {
  const handleSave = () => {};

  return (
    <div className="h-screen w-full sm:px-48 sm:pb-60 sm:pt-80 p-16">
      <h1 className="text-t20 sm:text-t27 text-font-primary pb-16">Address</h1>
      <Card className="p-36">
        <div className="w-full flex justify-center">
          <Button onClick={handleSave}>Save</Button>
        </div>
      </Card>
    </div>
  );
}
