import isEqual from "lodash/isEqual";
import { MortgageType } from "../../graphqlTypes";
import { Dropdown } from "@multiply/lib";

type InputMortgageTypeProps = {
  value: MortgageType | null;
  onChange: (value: MortgageType | null) => void;
  onBlur: () => void;
  error?: string;
};

const InputMortgageType = ({
  value,
  onChange,
  onBlur,
  error,
}: InputMortgageTypeProps) => {
  const toItem = (type: MortgageType) => {
    switch (type) {
      case MortgageType.Repayment:
        return {
          value: type,
          label: "Repayment Mortgage",
        };

      case MortgageType.InterestOnly:
        return {
          value: type,
          label: "Interest Only Mortgage",
        };

      case MortgageType.Warehoused:
        return {
          value: type,
          label: "Warehoused Mortgage",
        };

      default:
        const unreachable: never = type;
        return unreachable;
    }
  };

  const items = Object.values(MortgageType).map(toItem);

  const selectedValue =
    items.find((item) => isEqual(item.value, value)) ?? null;

  return (
    <Dropdown
      aria-label="dropdown to see list of mortgage types"
      error={error}
      label="Mortgage type"
      items={items}
      placeholder="Select property type"
      value={selectedValue}
      onChange={(item) => {
        onChange(item?.value ?? null);
      }}
      onBlur={onBlur}
    />
  );
};

export { InputMortgageType };
