import { VictoryPie } from "victory";
import { UmbrellaOutlineIcon } from "@multiply/lib";

type CircularCoverGraphProps = {
  percentage: number;
};

const CircularCoverGraph = ({ percentage }: CircularCoverGraphProps) => {
  const data = [
    { x: 1, y: percentage },
    { x: 2, y: 100 - percentage },
  ];
  return (
    <svg
      aria-label="progress chart"
      viewBox="0 0 200 200"
      className="w-40 sm:w-80"
    >
      <VictoryPie
        standalone={false}
        width={215}
        height={215}
        data={data}
        innerRadius={72}
        cornerRadius={25}
        labels={() => null}
        style={{
          data: {
            fill: ({ datum }) => {
              const color = "#AEB7FD";
              return datum.x === 1 ? color : "#FFF6F6";
            },
          },
        }}
      />
      <svg x={62} y={62}>
        <UmbrellaOutlineIcon size="xxl" variant="icon" />
      </svg>
    </svg>
  );
};

export { CircularCoverGraph };
