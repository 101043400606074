import classNames from "classnames";
import { FC } from "react";
import { ButtonLink, Card } from "@multiply/lib";

type ContentCardProps = {
  disabled: boolean;
  className?: string;
  title: string;
  subtitle: string;
  cta: string;
  to: string;
  children?: React.ReactNode;
};

const ContentCard: FC<ContentCardProps> = ({
  disabled,
  className,
  children,
  title,
  subtitle,
  cta,
  to,
  ...contentCardProps
}) => {
  return (
    <Card className={classNames("inline-block ", className)}>
      <div className="my-32">{children}</div>
      <div className="flex justify-between border-t-2 border-inner-border px-24 sm:px-36 py-24 sm:flex-row">
        <div className="flex flex-col justify-between">
          <h1 className="text-t16 sm:text-t27 text-font-primary font-bold">
            {title}
          </h1>

          <h2 className="text-t12 sm:text-t21 text-font-secondary">
            {subtitle}
          </h2>
        </div>
        <div className="flex">
          <ButtonLink
            {...contentCardProps}
            className="ml-16 sm:ml-32 lg:ml-160 min-w-120 sm:min-w-180"
            disabled={disabled}
            to={to}
          >
            {cta}
          </ButtonLink>
        </div>
      </div>
    </Card>
  );
};

export { ContentCard };
