import isEqual from "lodash/isEqual";
import { PensionType } from "../../graphqlTypes";
import { Dropdown } from "@multiply/lib";
import { sortedPensionTypes } from "../../utils";
import { getPensionNameFromType } from "../../utils/getPensionNameFromType";

type InputPensionTypeProps = {
  value: PensionType | null;
  onChange: (value: PensionType | null) => void;
  onBlur: () => void;
  error?: string;
};

const InputPensionType = ({
  value,
  onChange,
  onBlur,
  error,
}: InputPensionTypeProps) => {
  const items = sortedPensionTypes.map((pensionType) => ({
    value: pensionType,
    label: getPensionNameFromType(pensionType),
  }));

  const selectedValue =
    items.find((item) => isEqual(item.value, value)) ?? null;

  return (
    <Dropdown
      aria-label="dropdown to see list of types of pension"
      error={error}
      label="Pension type"
      items={items}
      placeholder="Select pension type"
      value={selectedValue}
      onChange={(item) => {
        onChange(item?.value ?? null);
      }}
      onBlur={onBlur}
    />
  );
};

export { InputPensionType };
