import { ContainerSmallSize } from "@multiply/lib";

type ConversationPageTemplateProps = {
  title?: string;
  subTitle?: string;
  children?: React.ReactNode;
  className?: string;
};

/**
 * Template for a conversation style page with title and subtitle
 * children will typically be a <ConversationForm />
 */
const ConversationPageTemplate = ({
  title,
  subTitle,
  className,
  children,
}: ConversationPageTemplateProps) => {
  return (
    <ContainerSmallSize className={className}>
      <div className="flex flex-1 flex-col items-center sm:mb-48">
        {title && (
          <h1 className="text-t21 sm:text-t32 text-font-primary font-semibold mb-8 text-center pt-24">
            {title}
          </h1>
        )}
        {subTitle && (
          <h2 className="text-font-primary text-t16 sm:text-t18 text-center">
            {subTitle}
          </h2>
        )}

        {children}
      </div>
    </ContainerSmallSize>
  );
};

export { ConversationPageTemplate };
