import { Card, DottedSquareIcon } from "@multiply/lib";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { ProductSummary } from "../../graphqlTypes";

type YourProductsProps = {
  className?: string;
  productSummary?: ProductSummary | null;
  title?: string;
  productType?: string;
};

const YourProducts = ({
  productSummary,
  className,
  title,
  productType,
}: YourProductsProps) => {
  return (
    <div className={classNames("flex flex-col", className)}>
      {title && (
        <h2 className="text-t12 sm:text-t21 text-font-secondary mb-24">
          {title}
        </h2>
      )}

      <Card className="flex justify-between p-32 flex-1">
        {productSummary?.missing ? (
          <div className="flex items-center gap-16">
            <DottedSquareIcon />
            <p className="text-label-primary text-t16 sm:text-t21">
              {`You have no ${
                productType ? productType.toLowerCase() + " " : ""
              }products`}
            </p>
          </div>
        ) : (
          <div>
            <p className="text-font-tertiary text-t16 sm:text-t21">
              {productSummary?.preProductAmount}
            </p>
            <p className="text-font-primary font-bold sm:text-t27">
              {productSummary?.productAmount}
            </p>
            <p className="text-font-tertiary text-t16 sm:text-t21">
              {productSummary?.postProductAmount}
            </p>
          </div>
        )}

        <Link
          to="/products"
          className="text-t16 sm:text-t21 text-font-links ml-32 self-start"
          aria-label="Edit your product details"
        >
          Edit
        </Link>
      </Card>
    </div>
  );
};
export { YourProducts };
