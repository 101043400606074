import { Button, ContainerMidSize, useAuth } from "@multiply/lib";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CheckboxButtonGroup,
  ConversationCardTemplate,
  ModalNavLink,
} from "../../components";
import { AssetType, useUpdateAssetsMutation } from "../../graphqlTypes";
import {
  sortedPensionTypesPage1,
  sortedPensionTypesPage2,
  UserFacingPensionType,
} from "../../utils";
import { getPensionNameFromType } from "../../utils/getPensionNameFromType";

type Options = {
  value: UserFacingPensionType | string;
  label: string;
};

const AddPensions = () => {
  const { userId } = useAuth();

  const [pensionTypes, setPensionTypes] = useState<UserFacingPensionType[]>([]);
  const [addAssetsResult, addAssets] = useUpdateAssetsMutation();
  const navigate = useNavigate();

  const optionsPage1: Options[] = [
    ...sortedPensionTypesPage1.map((pensionType) => ({
      value: pensionType,
      label: getPensionNameFromType(pensionType),
    })),
    { value: "OTHER", label: "Other" },
  ];

  const optionsPage2: Options[] = [
    ...sortedPensionTypesPage2.map((pensionType) => ({
      value: pensionType,
      label: getPensionNameFromType(pensionType),
    })),
    { value: "OTHER", label: "Other" },
  ];

  const [pageOne, setPageOne] = useState<boolean>(true);
  const [options, setOptions] = useState<Options[]>(optionsPage1 ?? []);

  const handleShowOtherPensions = () => {
    switch (pageOne) {
      case true:
        setPageOne(false);
        setOptions(optionsPage2);
        break;
      default:
        setPageOne(true);
        setOptions(optionsPage1);
        break;
    }
  };

  const handleAddPensionType = (pensionTypes: UserFacingPensionType[]) => {
    if (pensionTypes.includes("OTHER" as UserFacingPensionType)) {
      handleShowOtherPensions();
    } else {
      setPensionTypes(pensionTypes as UserFacingPensionType[]);
    }
  };

  const handleAddPensions = async () => {
    try {
      const newPensions = pensionTypes.map((pensionType) => ({
        productType: AssetType.Pension,
        pensionType,
      }));

      const result = await addAssets({
        input: { userId: userId ?? "", assets: newPensions },
      });

      if (result?.data?.updateAssets?.success) {
        const assets = result.data.updateAssets.assets;

        if (assets?.length === 1) {
          const assetId = assets[0]?.id;
          navigate(`/pensions/add/pension/${assetId}`);
        } else {
          navigate("/pensions");
        }
      }
    } catch (error) {}
  };

  const error =
    addAssetsResult.error ?? addAssetsResult.data?.updateAssets?.error;

  return (
    <ContainerMidSize>
      <ConversationCardTemplate
        title="Add your pension(s)"
        subtitle={
          <ModalNavLink
            to="/cross/pensions-explanation"
            className="text-font-links"
          >
            Which pension do I have?
          </ModalNavLink>
        }
      >
        <CheckboxButtonGroup
          value={pensionTypes}
          onChange={(pensionTypes) =>
            handleAddPensionType(pensionTypes as UserFacingPensionType[])
          }
          options={options}
          variant="auto-start"
        />

        <Button
          onClick={handleAddPensions}
          disabled={addAssetsResult.fetching || pensionTypes.length === 0}
        >
          Next
        </Button>
        {error && <p className="mt-16 text-action-error">{error.message}</p>}
      </ConversationCardTemplate>
    </ContainerMidSize>
  );
};

export { AddPensions };
