import { ResizeObserver, ResizeObserverEntry } from "@juggle/resize-observer";
import {
  AnalyticsProvider,
  AuthContextProvider,
  AuthService,
  createAzureAuthService,
  createMemoryAuthService,
  MultiplyUrqlProvider,
  ToasterProvider,
} from "@multiply/lib";
import "@multiply/lib/style.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import React, { useState } from "react";
import ReactDOM, { render } from "react-dom";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import App from "./App";
import { DowntimeWrapper, FlagsmithProvider } from "./components";
import "./genesys/irish-life.css";
import "./index.css";
import { GET_FIELDS } from "./queries/GetFields";
import reportWebVitals from "./reportWebVitals";
import schema from "./urql.schema.json";

Sentry.init({
  dsn: "https://d6b4c35e8ba74d1685a44c48ee5de4c4@o262596.ingest.sentry.io/4504486794297344",
  integrations: [new BrowserTracing()],
  tracesSampleRate: process.env.NODE_ENV !== "production" ? 1 : 0.2,
});

if (!("ResizeObserver" in window)) {
  window.ResizeObserver = ResizeObserver;
  // @ts-ignore
  window.ResizeObserverEntry = ResizeObserverEntry;
}

const start = async () => {
  if (process.env.REACT_APP_USE_MOCK_API === "true") {
    const { setupWorker } = await import("msw");
    const { graphqlHandlers, store, emptySeed, postPlanSeed } = await import(
      "./__fixtures__"
    );

    if (process.env.REACT_APP_HAS_MOCK_PLAN === "true")
      store.seed(postPlanSeed);
    else {
      store.seed(emptySeed);
    }

    const worker = setupWorker(...graphqlHandlers(store));

    await worker.start({
      onUnhandledRequest(req, print) {
        const href = req.url.href;
        const pathname = req.url.pathname;

        if (
          href.includes("googletagmanager.com") ||
          href.includes("facebook.com") ||
          href.includes("facebook.net") ||
          href.includes("qualtrics.com") ||
          href.includes("cookiebot.com") ||
          href.includes("segment.io") ||
          href.includes("segment.com") ||
          href.includes("sentry.io") ||
          href.includes("gstatic.com") ||
          href.includes("google-analytics.com") ||
          href.includes("mypurecloud.ie") ||
          href.match(/\.png$/) ||
          pathname.startsWith("/static/")
        ) {
          return;
        }

        print.warning();
      },
    });
  }

  let authService: AuthService;

  if (process.env.REACT_APP_USE_MOCK_AUTH === "true") {
    const { user } = await import("./__fixtures__");
    authService = createMemoryAuthService({
      currentLogin: {
        userId: user.id,
        token: "token",
        refreshToken: "refreshToken",
        username: "test@email.com",
      },
    });
  } else {
    authService = createAzureAuthService({
      clientId: process.env.REACT_APP_AZURE_AUTH_CLIENT_ID ?? "",
      baseAuthority: process.env.REACT_APP_AZURE_BASE_AUTHORITY ?? "",
      passwordResetAuthority:
        process.env.REACT_APP_AZURE_PASSWORD_RESET_AUTHORITY ?? "",
      defaultAuthority: process.env.REACT_APP_AZURE_DEFAULT_AUTHORITY ?? "",
      signInAuthority: process.env.REACT_APP_AZURE_SIGNIN_AUTHORITY ?? "",
      captureException: Sentry.captureException,
    });
  }

  // report a11y problems to the browser console
  if (process.env.NODE_ENV !== "production") {
    const axe = await import("@axe-core/react");
    axe.default(React, ReactDOM, 1000);
  }

  const container = document.getElementById("root");

  if (!container) {
    throw new Error("App container not found");
  }

  const IrishLife = () => {
    const [isDown, setIsDown] = useState(false);

    return (
      <React.StrictMode>
        <AuthContextProvider authService={authService}>
          <AnalyticsProvider
            writeKey={
              process.env.REACT_APP_TESTING === "true"
                ? undefined
                : (process.env.REACT_APP_SEGMENT_WRITE_KEY as string)
            }
          >
            <FlagsmithProvider>
              <MultiplyUrqlProvider
                queries={{
                  GET_FIELDS,
                }}
                onDowntime={setIsDown}
                schema={schema}
                endpoint={process.env.REACT_APP_GRAPHQL_ENDPOINT ?? ""}
              >
                <DowntimeWrapper isDown={isDown}>
                  <HistoryRouter history={createBrowserHistory({ window })}>
                    <ToasterProvider>
                      <App />
                    </ToasterProvider>
                  </HistoryRouter>
                </DowntimeWrapper>
              </MultiplyUrqlProvider>
            </FlagsmithProvider>
          </AnalyticsProvider>
        </AuthContextProvider>
      </React.StrictMode>
    );
  };

  render(<IrishLife />, container);

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
};

start();
