import classNames from "classnames";
import { FC } from "react";

type ContainerIndentSizeProps = {
  className?: string;
  children?: React.ReactNode;
};

const ContainerIndentSize: FC<ContainerIndentSizeProps> = ({
  className,
  children,
}) => {
  return (
    <div
      className={classNames(
        "w-full px-indent-screen lg:px-indent-screen-lg",
        className
      )}
    >
      {children}
    </div>
  );
};

export { ContainerIndentSize };
