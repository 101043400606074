import { Card, useAuth } from "@multiply/lib";
import { Link } from "react-router-dom";
import { useGetPlanQuery } from "../../graphqlTypes";
import { usePartnerStatus } from "../../hooks";

const MissingDataCard = () => {
  const { userId, loggedIn } = useAuth();

  const [planResult] = useGetPlanQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const factfindErrors = planResult.data?.plan?.factfindErrors;

  const hasProductErrors =
    !!factfindErrors?.assets ||
    !!factfindErrors?.insurances ||
    !!factfindErrors?.debts;

  const hasGoalErrors = !!factfindErrors?.goals;

  const hasPartner = usePartnerStatus();
  const hasUserErrors = !!factfindErrors?.factfind?.user_specific_factfind;
  const hasPartnerErrors =
    !!factfindErrors?.factfind?.partner_specific_factfind && hasPartner;

  const hasProfileErrors =
    !!factfindErrors?.persons || hasUserErrors || hasPartnerErrors;

  const humanReadableErrors: String[] | undefined =
    factfindErrors?.humanReadableErrors;

  return (
    <Card className="p-24 mb-48">
      <h1 className="text-font-primary text-t24 sm:text-t27 mb-12 font-bold">
        Missing data
      </h1>

      <p className="text-font-primary text-t12 sm:text-t16 mb-24">
        We can't generate your plan as there is some missing information.
      </p>

      {humanReadableErrors ? (
        <ul className="text-font-primary text-t12 sm:text-t16 mb-24 ml-32 list-disc">
          {humanReadableErrors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      ) : null}

      {hasProfileErrors && (
        <Link
          to="/settings/personal-details"
          className="text-font-primary sm:text-t21 pb-16 block"
        >
          Edit profile
        </Link>
      )}
      {hasGoalErrors && (
        <Link
          to="/advice/plan/view-goals"
          className="text-font-primary sm:text-t21 pb-16 block"
        >
          Edit goals
        </Link>
      )}
      {hasProductErrors && (
        <Link to="/products" className="text-font-primary sm:text-t21">
          Edit products
        </Link>
      )}
    </Card>
  );
};

export { MissingDataCard };
