import classNames from "classnames";

type RoundelProps = {
  children?: React.ReactNode;
  className?: string;
};

const Roundel = ({ children, className }: RoundelProps) => {
  return (
    <div className={className}>
      <div
        className={classNames(
          "rounded-full ring ring-recommendation-icon-roundel ring-offset-2 sm:ring-offset-4",
          "m-4 sm:m-8 h-48 sm:h-80 w-48 sm:w-80 bg-recommendation-icon-roundel",
          "flex items-center justify-center"
        )}
      >
        {children}
      </div>
    </div>
  );
};

export { Roundel };
