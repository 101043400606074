import { v4 as uuid } from "@lukeed/uuid";
import React, { createContext, useCallback, useMemo, useState } from "react";

export enum Icon {
  Success,
  Error,
}

export type Toast = {
  duration: number;
  message: string;
  icon: Icon;
  /**
   * Mark this toast as expired. Should trigger exit animations
   */
  expire: () => void;
  /**
   * Removes this toast from the list of toasts. Called after exit animations have finished
   */
  remove: () => void;
  /**
   * Pause automatic expiration
   */
  pause: () => void;
  /**
   * Resume automatic expiration
   */
  resume: () => void;
  isExpired: boolean;
  id: string;
};

type AddToastArg = {
  duration?: Toast["duration"];
  icon?: Toast["icon"];
  message: Toast["message"];
};

type ToasterContextValue = {
  toasts: Toast[];
  addToast: (toast: AddToastArg) => Promise<void>;
};

const ToasterContext = createContext<ToasterContextValue | undefined>(
  undefined
);

type ToasterProviderProps = {
  children: React.ReactNode;
};

const ToasterProvider = ({ children }: ToasterProviderProps) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const addToast = useCallback(
    ({ duration = 5000, icon = Icon.Success, message }: AddToastArg) => {
      return new Promise<void>((resolve) => {
        const id = uuid();

        const automaticExpire = () => {
          expire();
          resolve();
        };

        let startTime: number;
        let remainingTime = duration;
        let timeout: number;

        const startTimer = () => {
          startTime = new Date().getTime();
          timeout = window.setTimeout(automaticExpire, remainingTime);
        };

        const remove = () => {
          setToasts((toasts) => toasts.filter((toast) => toast.id !== id));

          clearTimeout(timeout);
          resolve();
        };

        const expire = () => {
          setToasts((toasts) => {
            const index = toasts.findIndex((toast) => toast.id === id);

            if (index > -1) {
              const newToast = { ...toasts[index], isExpired: true };

              return [
                ...toasts.slice(0, index),
                newToast,
                ...toasts.slice(index + 1),
              ];
            }

            return toasts;
          });

          clearTimeout(timeout);
        };

        const pause = () => {
          window.clearTimeout(timeout);
          const elapsedTime = new Date().getTime() - startTime;
          remainingTime = remainingTime - elapsedTime;
        };

        const resume = () => {
          if (!toast.isExpired) {
            startTimer();
          }
        };

        const toast = {
          duration,
          icon,
          message,
          expire,
          remove,
          pause,
          resume,
          isExpired: false,
          id,
        };

        setToasts((toasts) => [...toasts, toast]);

        startTimer();
      });
    },
    []
  );

  const value = useMemo(
    () => ({
      toasts,
      addToast,
    }),
    [toasts, addToast]
  );

  return (
    <ToasterContext.Provider value={value}>{children}</ToasterContext.Provider>
  );
};

export { ToasterContext, ToasterProvider };
