import { InputOwners } from "../../components";
import { AssetField } from "../../globalTypes";
import { AssetType } from "../../graphqlTypes";

const getAssetFieldsFromType = (assetType: AssetType): AssetField[] => {
  switch (assetType) {
    case AssetType.CurrentAccount:
      return [
        "provider",
        "owners",
        "isOverdrawn",
        "balance",
        "overdrawnBy",
        "whereFrom",
        "name",
      ];

    case AssetType.Cash:
      return [
        "owners",
        { fieldName: "balance", label: "Current value" },
        "accessible",
        "whereFrom",
        "name",
      ];

    case AssetType.CreditUnionAccount:
      return [
        "provider",
        "owners",
        { fieldName: "balance", label: "Current value" },
        "accessible",
        "whereFrom",
        "maturityDate",
        "name",
      ];

    case AssetType.AnPostSavingsAccount:
      return [
        "owners",
        "balance",
        "whereFrom",
        "startDate",
        "regularPaymentAmount",
        "frequency",
        "accessible",
      ];

    case AssetType.Shares:
      return [
        "owners",
        { fieldName: "balance", label: "Current value" },
        "accessible",
        "whereFrom",
        "maturityDate",
      ];

    case AssetType.GovernmentBond:
      return [
        "owners",
        { fieldName: "balance", label: "Current value" },
        "accessible",
        "whereFrom",
        "name",
        { fieldName: "maturityDate", label: "Maturity date", required: true },
      ];

    case AssetType.InvestmentBond:
      return [
        "owners",
        { fieldName: "balance", label: "Current value" },
        "accessible",
        "whereFrom",
        "name",
        "maturityDate",
      ];

    case AssetType.Property:
      return [
        "owners",
        "propertyType",
        { fieldName: "balance", label: "Current value" },
        "whereFrom",
        "name",
      ];

    case AssetType.ApprovedRetirementFund:
      return [
        {
          fieldName: "owners",
          //@ts-ignore
          input: <InputOwners noJoint />,
        },
        { fieldName: "balance", label: "Current value" },
        "accessible",
        "whereFrom",
        "name",
      ];

    case AssetType.LifeCompanySavingsPlan:
      return [
        "provider",
        "owners",
        "balance",
        "startDate",
        "regularPaymentAmount",
        "frequency",
        "accessible",
      ];

    case AssetType.Land:
      return [
        "owners",
        { fieldName: "balance", label: "Current value" },
        "whereFrom",
        "name",
      ];

    case AssetType.OtherAsset:
      return [
        "owners",
        { fieldName: "balance", label: "Current value" },
        "maturityDate",
        "accessible",
        "whereFrom",
      ];

    case AssetType.RegularSavingsDepositAccount:
      return [
        "provider",
        "owners",
        { fieldName: "balance", label: "Current value" },
        "whereFrom",
        "regularPaymentAmount",
        "frequency",
        "name",
        "accessible",
      ];

    case AssetType.DepositTracker:
      return [
        "provider",
        "owners",
        { fieldName: "balance", label: "Current value" },
        "whereFrom",
        { fieldName: "maturityDate", label: "Maturity date", required: true },
        "name",
        "accessible",
      ];

    case AssetType.SavingsDepositAccount:
      return [
        "provider",
        "owners",
        { fieldName: "balance", label: "Current value" },
        "whereFrom",
        "name",
        "accessible",
      ];

    case AssetType.TermAccount:
      return [
        "provider",
        "owners",
        { fieldName: "balance", label: "Current value" },
        "whereFrom",
        { fieldName: "maturityDate", label: "Maturity date", required: true },
        "name",
        "accessible",
      ];

    case AssetType.Pension:
      return [
        {
          fieldName: "owners",
          //@ts-ignore
          input: <InputOwners noJoint />,
        },
        { fieldName: "provider", label: "Pension provider (optional)" },
        {
          fieldName: "valuationMethod",
          label: "Valuation method",
          tooltip:
            "Your valuation method is the way in which you estimate the value your pension. You could estimate based on its current value, its total value at retirement or what you expect to receive monthly.",
        },
        {
          fieldName: "balance",
          label: "Value",
          tooltip: (
            <>
              <p>The value you add in here must be an estimation of either:</p>
              <ul className="list-disc list-inside">
                <li>Your pension's current value</li>
                <li>Your pension's total value at retirement</li>
                <li>Your monthly pension income at retirement</li>
              </ul>
            </>
          ),
        },
        {
          fieldName: "regularPaymentAmount",
          label: "Monthly payment (optional)",
          tooltip:
            "The monthly total you're currently paying into this pension.",
          required: false,
        },
        "pensionType",
        "active",
        "throughCurrentEmployer",
        "isIrishLifePension",
        "increasing",
      ];

    default:
      const unreachable: never = assetType;
      return unreachable;
  }
};

export { getAssetFieldsFromType };
