import { Button, ContainerSmallSize, useAuth } from "@multiply/lib";
import { useNavigate } from "react-router-dom";
import { ConversationCardTemplate } from "../../components";
import {
  useGetFactFindQuery,
  useUpdateFactFindMutation,
} from "../../graphqlTypes";
import { useGetCheckIns } from "../../hooks";
import { Loading } from "../Loading";
import { useEffect } from "react";
import { getNextCheckInLink } from "../../utils";

const CheckIn = () => {
  const { userId, loggedIn } = useAuth();
  const navigate = useNavigate();

  const [factFindResults] = useGetFactFindQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });
  const vulnerable = !!factFindResults?.data?.factfind?.vulnerable ?? false;

  const [, updateFactfind] = useUpdateFactFindMutation();

  const { error, fetching, checkIns } = useGetCheckIns();

  const loading = fetching || factFindResults?.fetching;

  const navigateLink = getNextCheckInLink(checkIns ?? []);

  useEffect(() => {
    if (!vulnerable && !loading && navigateLink) navigate(navigateLink);
  }, [vulnerable, loading, navigate, navigateLink]);

  const errors = factFindResults?.error || error;

  const handleContinue = async () => {
    try {
      await updateFactfind({
        input: {
          userId: userId ?? "",
          factfind: {
            vulnerable: false,
          },
        },
      });
    } catch (error) {}
  };

  const handleBookAppointment = () => navigate("/appointments");

  if (!vulnerable) return null;

  return (
    <ContainerSmallSize className="h-full w-full flex items-center justify-center">
      {loading ? (
        <div className="h-full w-full flex items-center justify-center">
          <Loading />
        </div>
      ) : (
        <ConversationCardTemplate
          title="Has a recent life event caused you distress or disruption?"
          subtitle={
            <p>
              If so, we recommend scheduling a call with a financial adviser.
              They'll be able to offer some extra support around your plan.
            </p>
          }
          className="mb-40 h-auto"
        >
          <fieldset className="flex flex-col items-start gap-12 sm:gap-16 w-full">
            <Button
              className="max-w-fit whitespace-normal h-48 px-40 text-t16 sm:text-t18"
              size="none"
              variant="ghost"
              onClick={handleContinue}
            >
              My circumstances haven't changed
            </Button>
            <Button
              size="none"
              className="max-w-fit whitespace-normal h-48 sm:px-40 text-t16 sm:text-t18"
              variant="ghost"
              onClick={handleBookAppointment}
            >
              Book a call with a financial adviser
            </Button>
          </fieldset>

          {errors && (
            <p className="text-t16 text-action-error mt-12">{errors.message}</p>
          )}
        </ConversationCardTemplate>
      )}
    </ContainerSmallSize>
  );
};

export { CheckIn };
