import {
  Avatar,
  Card,
  ContainerMaxSize,
  TrackedLink,
  useAuth,
} from "@multiply/lib";
import { captureException } from "@sentry/react";
import { format } from "date-fns";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useGetPersonQuery, useGetUserQuery } from "../../graphqlTypes";
import { useIsMIL } from "../../utils";

export function Settings() {
  const {
    userId,
    logOut,
    loggedIn,
    changePasswordWithRedirect,
    handleRedirectResult,
  } = useAuth();

  useEffect(() => {
    const completePasswordChange = async () => {
      await handleRedirectResult();
    };
    completePasswordChange();
  }, [handleRedirectResult]);

  const [personResult] = useGetPersonQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const [userResult] = useGetUserQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const isMIL = useIsMIL();

  const handleLogout = () => {
    if (isMIL) {
      if (!process.env.REACT_APP_MY_IRISH_LIFE_URI) {
        captureException(
          new Error(`REACT_APP_MY_IRISH_LIFE_URI env var not configured`)
        );
      }
      logOut(process.env.REACT_APP_MY_IRISH_LIFE_URI);
    } else {
      logOut();
    }
  };

  const { joinDate, firstName, lastName } = personResult?.data?.person ?? {};

  return (
    <ContainerMaxSize>
      <div className="w-full flex flex-col">
        <section className="mb-48">
          <h1 className="text-t21 sm:text-t27 text-font-primary">Settings</h1>
          <p className=" sm:text-t21 text-font-secondary mt-8">
            Edit your data and account here.
          </p>
        </section>

        <div className="lg:flex gap-56 flex-wrap">
          <section className="mb-36 sm:w-400 ">
            <h2 className="text-t21 sm:text-t27 text-font-primary pb-16">
              Personal details
            </h2>
            <Link to="/settings/personal-details">
              <Card
                className="flex items-center p-24"
                ariaLabel="Personal details settings page"
              >
                <Avatar size="lg" variant="icon" colorType="irish-life" />
                <div className="ml-16">
                  <p className="text-t16 sm:text-t21 text-font-primary pb-4 capitalize">
                    {firstName} {lastName}
                  </p>
                  <p className="text-t12 sm:text-t16 text-font-secondary">
                    Joined{" "}
                    {joinDate && format(new Date(joinDate), "do MMMM yyyy")}
                  </p>
                </div>
              </Card>
            </Link>
          </section>

          <section className="mb-36 sm:w-400">
            <h2 className="text-t21 sm:text-t27 text-font-primary pb-16">
              Privacy and security
            </h2>

            <Link to="/settings/documents">
              <Card className="p-24 mb-16">
                <p className="text-t16 sm:text-t21 text-font-secondary">
                  Documents
                </p>
              </Card>
            </Link>

            <button
              onClick={changePasswordWithRedirect}
              className="w-full text-left"
            >
              <Card className="p-24 mb-16">
                <p className="text-t16 sm:text-t21 text-font-secondary">
                  Change password
                </p>
              </Card>
            </button>

            <Link to="/settings/contact-preferences">
              <Card className="p-24">
                <p className="text-t16 sm:text-t21 text-font-secondary">
                  Update contact preferences
                </p>
              </Card>
            </Link>
          </section>

          <section className="mb-36 sm:w-400">
            <h2 className="text-t21 sm:text-t27 text-font-primary pb-16">
              Help and support
            </h2>

            <Link to="/settings/contact">
              <Card className="p-24">
                <p className="text-t16 sm:text-t21 text-font-secondary">
                  Contact us
                </p>
              </Card>
            </Link>
          </section>
        </div>

        <div className="mt-32 sm:mt-160 sm:flex flex-1 items-end justify-between sm:pb-32">
          <section className="flex flex-col gap-16 sm:flex-row sm:gap-40">
            <TrackedLink
              href="https://assets-eu-01.kc-usercontent.com/ffd8d21b-ebd4-0151-55d5-297335c8f50f/39746f00-8406-49c3-a26b-dd08cc67f881/Terms%20of%20Business%20ILFS.pdf"
              rel="noreferrer"
              target="_blank"
              className="text-t16 sm:text-t21 text-font-links w-fit"
            >
              Terms of Business
            </TrackedLink>
            <TrackedLink
              href="https://cdn.multiply.ai/documents/irish-life/irish_life_digital_advice_portal_terms_of_use.pdf"
              rel="noreferrer"
              target="_blank"
              className="text-t16 sm:text-t21 text-font-links w-fit"
            >
              Terms of use
            </TrackedLink>
            <TrackedLink
              href="https://assets-eu-01.kc-usercontent.com/ffd8d21b-ebd4-0151-55d5-297335c8f50f/c88ce4a9-46d1-43da-bf19-3e08ca23a28e/ILA%2017350%20%28NPI%2006-22%29-M6.pdf"
              rel="noreferrer"
              target="_blank"
              className="text-t16 sm:text-t21 text-font-links w-fit"
            >
              Privacy Policy
            </TrackedLink>
            <TrackedLink
              href="https://www.irishlife.ie/website-use-policy/#cookies-policy"
              rel="noreferrer"
              target="_blank"
              className="text-t16 sm:text-t21 text-font-links w-fit"
            >
              Cookies Policy
            </TrackedLink>
          </section>
          <button
            disabled={userResult.fetching}
            className="text-t16 sm:text-t21 inline text-action-error pt-48 sm:pt-0"
            onClick={handleLogout}
          >
            Log out
          </button>
        </div>
      </div>
    </ContainerMaxSize>
  );
}
