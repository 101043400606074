import { useAuth } from "@multiply/lib";
import { Navigate } from "react-router-dom";
import { useGetPersonQuery } from "../../graphqlTypes";
import { getIntroPathFromPerson } from "../../utils";
import { Loading } from "../Loading";

const IntroRedirect = () => {
  const { userId, loggedIn } = useAuth();

  const [result] = useGetPersonQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
    requestPolicy: "network-only",
  });

  const redirectPath = getIntroPathFromPerson(result.data?.person);

  if (result.fetching || !userId || result.stale) {
    return <Loading />;
  } else if (redirectPath) {
    return <Navigate to={redirectPath} replace />;
  }

  return (
    <main className="flex justify-center items-center h-full">
      <p className="text-t27">
        Oops, something went wrong. Please contact support
      </p>
    </main>
  );
};

export { IntroRedirect };
