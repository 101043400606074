import { useAuth } from "@multiply/lib";
import {
  useGetAssetsQuery,
  useGetDebtsQuery,
  useGetPoliciesQuery,
} from "../../graphqlTypes";

const useAllProductsComplete = () => {
  const { userId, loggedIn } = useAuth();
  const [assetsResult] = useGetAssetsQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const [debtsResult] = useGetDebtsQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const [policiesResult] = useGetPoliciesQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const loading =
    assetsResult.fetching || debtsResult.fetching || policiesResult.fetching;
  const error = assetsResult.error ?? debtsResult.error ?? policiesResult.error;

  const allProductsComplete = [
    ...(assetsResult.data?.assets ?? []),
    ...(debtsResult.data?.debts ?? []),
    ...(policiesResult.data?.policies ?? []),
  ].every((product) => product?.isComplete);

  return {
    loading,
    error,
    value: allProductsComplete,
  };
};

export { useAllProductsComplete };
