import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { Button, Spinner, TextArea, useAuth, useToaster } from "@multiply/lib";
import { useSuggestNewToolMutation } from "../../graphqlTypes";

type SuggestToolFormValues = {
  suggestion: string;
};

const validationSchema: yup.SchemaOf<SuggestToolFormValues> = yup
  .object()
  .shape({
    suggestion: yup
      .string()
      .required("Please enter your ideas for tools you think would be useful")
      .nullable(),
  })
  .defined();

const SuggestTool = () => {
  const { userId } = useAuth();

  const navigate = useNavigate();
  const { addToast } = useToaster();

  const [suggestNewToolResult, suggestNewTool] = useSuggestNewToolMutation();
  let error = suggestNewToolResult?.error?.message ?? null;

  const { control, formState, handleSubmit } = useForm({
    defaultValues: {
      suggestion: "",
    },
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  const { isValid, isSubmitting } = formState;

  const onSubmit = async (formValues: any) => {
    const newToolSuggestion = formValues.suggestion;

    try {
      const result = await suggestNewTool({
        input: {
          userId: userId ?? "",
          tool: newToolSuggestion ?? "",
        },
      });

      if (result.data?.suggestNewTool?.success) {
        addToast({ message: "Success", duration: 5000 });
        navigate("/tools");
      } else {
        error = result.error?.message ?? "";
      }
    } catch (error) {}
  };

  return (
    <div className="flex flex-col justify-center items-center mx-60 mt-12">
      <h1 className="sm:text-t34 text-font-primary mb-12 text-center">
        Tell us what tools would help you most
      </h1>
      <p className="sm:text-t21 text-font-secondary mb-32">
        We’d love to hear your suggestions for new tools.
      </p>

      <div className="w-full mb-60">
        <Controller
          control={control}
          name="suggestion"
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error },
          }) => (
            <TextArea
              className="flex-1"
              value={value ?? ""}
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message}
            />
          )}
        />
      </div>

      <Button
        disabled={!isValid || isSubmitting}
        className="mb-60 min-w-320"
        onClick={handleSubmit(onSubmit)}
      >
        Submit
      </Button>

      {isValid && isSubmitting && <Spinner />}

      {error ? (
        <p className="text-action-error text-center mt-12">{error}</p>
      ) : null}
    </div>
  );
};

export { SuggestTool };
