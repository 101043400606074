import { authExchange } from "@urql/exchange-auth";

type AuthExchange = ReturnType<typeof authExchange>;

const createAuthExchange = ({
  getToken,
  refreshToken,
  logOut,
}: {
  getToken: () => string | null;
  refreshToken: () => Promise<void>;
  logOut: () => Promise<void>;
}): AuthExchange =>
  authExchange(async (utils) => {
    return {
      addAuthToOperation(operation) {
        const token = getToken();
        if (token) {
          return utils.appendHeaders(operation, {
            "auth-token": token,
          });
        }
        return operation;
      },

      willAuthError(_operation) {
        const token = getToken();
        // e.g. check for expiration, existence of auth etc
        return !token;
      },

      didAuthError: (error) => {
        if (error.message.includes("Token Error")) {
          return true;
        }
        return false;
      },

      async refreshAuth() {
        try {
          await refreshToken();
        } catch (err) {
          await logOut();
        }
      },
    };
  });

export { createAuthExchange };
