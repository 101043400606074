import { FC } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import { getButtonClasses, ButtonSize, ButtonVariant } from "../Button";
import { TrackedLink } from "../TrackedLink";

type ButtonLinkProps = {
  to: NavLinkProps["to"];
  children?: React.ReactNode;
  rightIcon?: React.ReactNode;
  disabled?: boolean;
  variant?: ButtonVariant;
  size?: ButtonSize;
} & Omit<React.ComponentProps<"a">, "ref">;

const ButtonLink: FC<ButtonLinkProps> = ({
  to,
  className,
  disabled,
  children,
  rightIcon,
  variant,
  size,
  onClick,
  ...linkProps
}) => {
  const isBrowser = typeof document !== "undefined";
  let isLocalLink = false;
  if (isBrowser) {
    isLocalLink =
      new URL(document.baseURI).origin ===
      new URL(to.toString(), document.baseURI).origin;
  }

  const derivedClassNames = getButtonClasses({
    size,
    variant,
    className,
    icons: rightIcon ? true : false,
  });

  const inner = (
    <button
      aria-disabled={disabled}
      className={derivedClassNames}
      disabled={disabled}
    >
      {children}
      {rightIcon && <span className="shrink-0">{rightIcon}</span>}
    </button>
  );

  if (disabled) {
    linkProps["aria-disabled"] = true;
    return <span {...linkProps}>{inner}</span>;
  }

  if (isLocalLink) {
    return (
      <NavLink {...linkProps} onClick={onClick} to={to}>
        {inner}
      </NavLink>
    );
  }

  return (
    <TrackedLink {...linkProps} href={to.toString()}>
      {inner}
    </TrackedLink>
  );
};

export { ButtonLink };
