import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";
import {
  IconProps,
  getDimensionsFromSize,
  getColorFromVariant,
} from "./shared";

const HouseIcon: FC<IconProps> = ({ size, variant, className, ...props }) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);

  return (
    <svg
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="house icon"
      {...props}
    >
      <path d="M6.667 9.333h2.666V14H6.666V9.333Z" fill={fill} />
      <path
        d="m13.613 6.787-5.14-5.254a.667.667 0 0 0-.946 0l-5.14 5.26A1.334 1.334 0 0 0 2 7.747v5.587a1.334 1.334 0 0 0 1.26 1.333h2.073v-6A.667.667 0 0 1 6 8h4a.667.667 0 0 1 .667.667v6h2.073A1.334 1.334 0 0 0 14 13.334V7.747a1.38 1.38 0 0 0-.387-.96Z"
        fill={fill}
      />
    </svg>
  );
};

export { HouseIcon };
