import { Button, ContainerSmallSize } from "@multiply/lib";
import { ConversationCardTemplate } from "../../components";
import { useNavigate } from "react-router-dom";
import { useGetUserJourney, useInteractionFlag } from "../../hooks";
import { Loading } from "../Loading";
import { InteractionFlags } from "../../globalTypes";

const PensionLimitations = () => {
  const navigate = useNavigate();
  const userJourney = useGetUserJourney();

  const completedPensionsLimitations = useInteractionFlag(
    InteractionFlags.CompletedPensionsLimitations
  );

  const handleClick = async (to: string) => {
    if (!completedPensionsLimitations.value)
      await completedPensionsLimitations.update(true);
    navigate(to);
  };

  return (
    <ContainerSmallSize className="h-screen flex flex-col items-center justify-center">
      {userJourney?.loading || completedPensionsLimitations.loading ? (
        <Loading />
      ) : (
        <ConversationCardTemplate
          title="Other important areas to consider"
          subtitle={
            <div className="flex flex-col space-y-12">
              <p>
                This tool focuses on retirement planning only, we will assess
                and provide advice on any shortfall in your pension
                requirements. It does not consider other needs such as
                protection or savings and investments. We recommend you also
                review your other needs.
              </p>
              <p>
                We will either recommend a new Irish Life pension, or suggest
                other actions based on your personal situation.
              </p>
              <p>
                We will not assess whether your existing pension arrangements
                offer better terms than a new Irish Life PRSA.
              </p>
            </div>
          }
          className="sm:p-56"
        >
          <fieldset className="flex flex-col items-start gap-16 sm:gap-16 w-full mt-32">
            <Button
              className="max-w-fit whitespace-normal h-48 px-40 text-t15 sm:text-t18"
              size="none"
              variant="ghost"
              onClick={() => handleClick("/pensions/vulnerable")}
            >
              I understand, continue
            </Button>
            <Button
              className="max-w-fit whitespace-normal h-48 px-40 text-t15 sm:text-t18"
              size="none"
              variant="ghost"
              onClick={() => handleClick(userJourney.callRoute)}
            >
              Book a call with a financial adviser
            </Button>
          </fieldset>
        </ConversationCardTemplate>
      )}
    </ContainerSmallSize>
  );
};

export { PensionLimitations };
