import { Button } from "@multiply/lib";

type AddSavingsGoalButtonProps = {
  handleAddGoal?: () => void;
  savingsGoalText?: string;
  id?: string;
};
const AddSavingsGoalButton = ({
  handleAddGoal,
  savingsGoalText,
  id,
}: AddSavingsGoalButtonProps) => {
  return (
    <div className="flex flex-col">
      <Button
        size="sm"
        variant="primary"
        className="font-semibold"
        onClick={handleAddGoal}
        id={id}
      >
        {savingsGoalText}
      </Button>
    </div>
  );
};

export { AddSavingsGoalButton };
