import classNames from "classnames";
import { CURRENCY_SYMBOL } from "../../utils";

type LevelPricingProps = {
  className?: string;
};

const LevelPricing = ({ className }: LevelPricingProps) => {
  return (
    <div className={classNames("grid grid-cols-3", className)}>
      <div className="text-font-primary text-t12">
        <h5 className="text-t27 font-bold mb-40">Level 1</h5>

        <p className="text-t20 font-bold">22 plans</p>
        <p className="mb-16">to choose and customize from</p>
        <p>from</p>
        <p>
          <span className="text-t20 font-bold">{CURRENCY_SYMBOL}46</span>/ per
          month
        </p>
      </div>

      <div className="text-font-primary text-t12">
        <h5 className="text-t27 font-bold mb-40">Level 2</h5>

        <p className="text-t20 font-bold">42 plans</p>
        <p className="mb-16">to choose and customize from</p>
        <p>from</p>
        <p>
          <span className="text-t20 font-bold">{CURRENCY_SYMBOL}70</span>/ per
          month
        </p>
      </div>

      <div className="text-font-primary text-t12">
        <h5 className="text-t27 font-bold mb-40">Level 3+</h5>

        <p className="text-t20 font-bold">12 plans</p>
        <p className="mb-16">to choose and customize from</p>
        <p>from</p>
        <p>
          <span className="text-t20 font-bold">{CURRENCY_SYMBOL}374</span>/ per
          month
        </p>
      </div>
    </div>
  );
};

export { LevelPricing };
