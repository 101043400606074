import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";
import {
  IconProps,
  getDimensionsFromSize,
  getColorFromVariant,
} from "./shared";

const InboxIcon: FC<IconProps> = ({ size, variant, className, ...props }) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);

  return (
    <svg
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.655 8.34 14.56 3.143c-.212-1.134-1.026-1.81-2.179-1.81H3.62c-.575 0-1.06.16-1.44.476-.38.317-.63.764-.74 1.332L.345 8.339a.57.57 0 0 0-.01.113v3.834a2.19 2.19 0 0 0 2.19 2.19h10.952a2.19 2.19 0 0 0 2.19-2.19V8.452a.571.571 0 0 0-.01-.113ZM2.512 3.362v-.01c.122-.63.473-.924 1.106-.924h8.763c.637 0 .988.293 1.105.921l.002.014.922 4.376a.135.135 0 0 1-.028.115.136.136 0 0 1-.107.05h-4.084a.545.545 0 0 0-.548.542 1.643 1.643 0 1 1-3.286 0 .545.545 0 0 0-.547-.542H1.725a.137.137 0 0 1-.134-.165l.921-4.377Z"
        fill={fill}
      />
    </svg>
  );
};

export { InboxIcon };
