import { useId } from "../../hooks";
import classNames from "classnames";

type TextAreaProps = {
  className?: string;
  label?: React.ReactNode;
  isDisabled?: boolean;
  error?: string;
} & React.ComponentProps<"textarea">;

const TextArea = ({
  className,
  label,
  isDisabled,
  error,
  ...textAreaProps
}: TextAreaProps) => {
  const defaultId = useId();
  const id = textAreaProps.id ?? defaultId;

  return (
    <div
      className={classNames(
        "flex flex-col text-t16 text-font-primary",
        className
      )}
    >
      {label && (
        <label
          htmlFor={id}
          className="text-input-label text-t16 font-semibold mb-8"
        >
          {label}
        </label>
      )}
      <div className="relative">
        <textarea
          {...textAreaProps}
          className="min-h-200 w-full bg-background-primary rounded-lg border-input-border border resize-none p-24 pb-40"
          id={id}
        />
        {textAreaProps.maxLength && (
          <span className="absolute bottom-12 right-12 text-t12 text-font-secondary bg-background-primary/80 rounded-md p-4">
            {textAreaProps.value?.toString().length}/{textAreaProps.maxLength}
          </span>
        )}
      </div>
      {error && <p className="text-action-error mt-12">{error}</p>}
    </div>
  );
};
export { TextArea };
