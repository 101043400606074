import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";
import { IconProps, getDimensionsFromSize, getColorFromVariant } from "./index";
import { useId } from "../../hooks";

const ArrowRightCircle: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);

  const id = useId();

  return (
    <svg
      aria-label={props["aria-label"] ?? ""}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={classNames(defaultClassnames, className)}
    >
      <defs>
        <mask id={id}>
          <rect fill="#FFF" width="100%" height="100%" />
          <path
            d="M12.122 8.355a.5.5 0 0 0 0-.708L8.94 4.465a.5.5 0 1 0-.707.708L11.06 8 8.233 10.83a.5.5 0 1 0 .707.707l3.182-3.182ZM4.239 8.5h7.53v-1h-7.53v1Z"
            fill="#000"
          />
        </mask>
      </defs>
      <rect width={16} height={16} rx={8} fill={fill} mask={`url(#${id})`} />
    </svg>
  );
};

export { ArrowRightCircle };
