import { Spinner, useAuth, CollegeCapIcon } from "@multiply/lib";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  InputCollegeCostsForm,
  CollegeCostsValues,
} from "@multiply-ai/college-costs-calculator";
import {
  CollegeCost,
  useCalculateCollegeCostsMutation,
  useGetCollegeCostQuery,
  WhatToIncludeType,
} from "../../graphqlTypes";
import {
  useGoalDetailsFromParams,
  useInteractionFlag,
  useNavigateWithState,
} from "../../hooks";
import { InteractionFlags } from "../../globalTypes";

export type CollegeCostsCalculatorProps = {
  className?: string;
  saveCalculationResults?: (calculationResults: CollegeCost | null) => void;
  previousCollegeCostResult?: CollegeCost | null;
  modal?: boolean;
  startOver?: boolean;
  makeChanges?: boolean;
};
const CollegeCostsCalculator = ({
  className,
  saveCalculationResults,
  previousCollegeCostResult,
  modal,
  startOver,
  makeChanges,
}: CollegeCostsCalculatorProps) => {
  const navigate = useNavigateWithState();
  const redirect = useNavigate();
  const { userId } = useAuth();
  const { calculationId } = useParams();
  const { goalName, goalId } = useGoalDetailsFromParams();

  const completedGenerateLifePlan = useInteractionFlag(
    InteractionFlags.CompletedGenerateLifePlan
  );

  const userHasGeneratedPlan =
    !completedGenerateLifePlan.loading && completedGenerateLifePlan.value;

  const [calculateCollegeCostResult, calculateCollegeCosts] =
    useCalculateCollegeCostsMutation();

  const [defaultValues, setDefaultValues] = useState<
    Partial<CollegeCostsValues>
  >({
    ageOfChild: undefined,
    courseDuration: undefined,
    freeFees: undefined,
    accommodation: undefined,
    costsInclude: undefined,
  });

  // Only get calculated result when authenticated and not starting over with an empty form
  const [readCollegeCostResult] = useGetCollegeCostQuery({
    variables: {
      userId: userId ?? "",
      collegeCostUuid: calculationId ?? null,
    },
    pause:
      !makeChanges &&
      (!userId || startOver || (!!userId && !userHasGeneratedPlan)),
    requestPolicy: "network-only",
  });

  // If specific input values are passed as a prop, use them
  // if user has a plan. Otherwise, show blank form
  if (previousCollegeCostResult) {
    setDefaultValues({
      ageOfChild: previousCollegeCostResult.ageOfChild ?? undefined,
      courseDuration: previousCollegeCostResult.courseDuration ?? undefined,
      freeFees:
        previousCollegeCostResult.eligibleForFreeTuitionFees ?? undefined,
      accommodation: previousCollegeCostResult.accommodationType ?? undefined,
      costsInclude:
        previousCollegeCostResult.whatToInclude as WhatToIncludeType[],
    });
  }

  useEffect(() => {
    const collegeCostResults = readCollegeCostResult?.data?.collegeCost;
    const haveAddedGoal = !!collegeCostResults?.goalId;

    // If we have recent calculation values ...
    if (collegeCostResults) {
      if (!calculationId && !haveAddedGoal) {
        // ... and they have not yet been added as a goal, go straight to the reults
        redirect(
          `/tools/college-costs-calculator/results/${collegeCostResults?.id}`
        );
      }
      if (calculationId) {
        // ... otherwise, only use them if explicitly asked for
        setDefaultValues({
          ageOfChild: collegeCostResults?.ageOfChild ?? undefined,
          courseDuration: collegeCostResults?.courseDuration ?? undefined,
          freeFees: collegeCostResults?.eligibleForFreeTuitionFees ?? undefined,
          accommodation: collegeCostResults?.accommodationType ?? undefined,
          costsInclude:
            collegeCostResults?.whatToInclude as WhatToIncludeType[],
        });
      }
    }
  }, [
    calculateCollegeCostResult,
    calculationId,
    readCollegeCostResult?.data?.collegeCost,
    redirect,
  ]);

  const error =
    readCollegeCostResult?.error?.message ||
    calculateCollegeCostResult?.error?.message;
  const loading =
    readCollegeCostResult?.fetching || calculateCollegeCostResult?.fetching;

  const onSubmit = async (formValues: CollegeCostsValues) => {
    const result = await calculateCollegeCosts({
      input: {
        userId: userId ?? null,
        accommodation: formValues?.accommodation,
        ageOfChild: formValues?.ageOfChild,
        courseDuration: formValues?.courseDuration,
        eligibleForFreeTuitionFees: formValues?.freeFees,
        whatToInclude: formValues?.costsInclude,
      },
    });

    let searchParams = new URLSearchParams();
    if (goalName) searchParams.set("goalName", goalName);
    if (goalId) searchParams.set("goalId", goalId);

    if (result?.data?.updateCollegeCosts) {
      if (!userId) {
        if (saveCalculationResults !== undefined)
          saveCalculationResults(result?.data?.updateCollegeCosts);
      } else {
        navigate(
          `${modal ? "/cross" : ""}/tools/college-costs-calculator/results/${
            result?.data?.updateCollegeCosts?.id
          }`,
          searchParams
        );
      }
    }
  };

  return (
    <div className={className}>
      <div className="flex items-start gap-12">
        <CollegeCapIcon size="lg" className="text-icon-display" />
        <h1 className="text-t21 sm:text-t27 font-semibold text-font-primary mb-4">
          College costs calculator
        </h1>
      </div>
      <p className="text-font-secondary text-t16 sm:text-t21 mb-28">
        Calculate what it could cost you to put a child through third-level
        education.
      </p>
      <section className="flex flex-wrap">
        {loading ? (
          <Spinner />
        ) : (
          <InputCollegeCostsForm
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            modal={modal ?? false}
          />
        )}
      </section>
      {error ? <p className="text-action-error mt-12">{error}</p> : null}
    </div>
  );
};

export { CollegeCostsCalculator };
