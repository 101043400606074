import { FC } from "react";
import classNames from "classnames";
import { defaultClassnames } from "../defaultClassNames";
import {
  getColorFromVariant,
  getDimensionsFromSize,
  IconProps,
} from "../shared";
import { useId } from "../../../hooks";

const PinRetirementIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);
  const radialGradient = useId();
  const clipPath = useId();
  return (
    <svg
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 39 49"
      fill="none"
      data-testid="pin retirement icon"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={fill}
        stroke={`url(#${radialGradient})`}
        strokeWidth={2.016}
        d="M37.12 19.185c0 2.419-.851 5.391-2.267 8.546-1.409 3.138-3.34 6.377-5.412 9.302-2.075 2.928-4.272 5.515-6.201 7.358-.966.923-1.844 1.64-2.59 2.118-.78.503-1.28.655-1.522.655s-.742-.152-1.523-.655c-.745-.479-1.623-1.195-2.589-2.118-1.929-1.843-4.126-4.43-6.2-7.358-2.074-2.925-4.004-6.164-5.413-9.302-1.416-3.155-2.267-6.127-2.267-8.546 0-9.944 8.056-18.005 17.992-18.005 9.936 0 17.992 8.06 17.992 18.005Z"
      />
      <g clipPath={`url(#${clipPath})`} transform="translate(10,12) scale(0.5)">
        <path
          d="M13.5503 18.0137H21.8086M17.128 8.89341C19.5862 6.02105 22.8566 4.22163 25.4945 5.18778C29.1999 6.54489 30.3427 12.8772 28.8866 18.0137H21.8086M17.128 8.89341C14.9832 6.85172 12.4465 5.71908 10.2716 6.4377C6.79487 7.58643 5.3869 13.0876 6.23409 18.0137C7.04735 18.0137 18.8418 18.0137 21.8086 18.0137M17.128 8.89341C18.9273 10.6062 20.4507 12.9587 21.2535 15.3948C21.5575 16.3175 21.7383 17.1918 21.8086 18.0137"
          stroke="white"
          strokeWidth="2.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.0915 17.9834C31.0915 24.3615 25.02 29.5319 17.5306 29.5319C10.0411 29.5319 3.96973 24.3615 3.96973 17.9834H31.0915Z"
          stroke="white"
          strokeWidth="2.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.19873 21.4827H10.516"
          stroke="white"
          strokeWidth="2.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.3896 22.606H29.5516"
          stroke="white"
          strokeWidth="2.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.9666 25.5571H12.6084"
          stroke="white"
          strokeWidth="2.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id={clipPath}>
          <rect
            width="35"
            height="35"
            fill="white"
            transform="translate(0.12793 0.172119)"
          />
        </clipPath>
      </defs>
      <defs>
        <radialGradient
          id={radialGradient}
          cx={0}
          cy={0}
          r={1}
          gradientTransform="matrix(0 19.013 -19 0 19.128 19.185)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.839} stopColor="#fff" />
          <stop offset={1} stopColor="#ECEFFF" />
        </radialGradient>
      </defs>
    </svg>
  );
};
export { PinRetirementIcon };
