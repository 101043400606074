import { VictoryThemeDefinition } from "victory";

const colors = [
  "#252525",
  "#525252",
  "#737373",
  "#969696",
  "#bdbdbd",
  "#d9d9d9",
  "#f0f0f0",
];

const PRIMARY = "#3B3160";
const SECONDARY = "#625B80";
const PLOTLINE = "#3f366e";

// Typography
const sansSerif = "Assistant, sans-serif";
const letterSpacing = "normal";
const fontSize = 10;

// Layout
const baseProps = {
  width: 500,
  height: 300,
  padding: 24,
  colorScale: colors,
};

// Labels
const baseLabelStyles = {
  fontFamily: sansSerif,
  fontSize,
  letterSpacing,
  padding: 10,
  fill: SECONDARY,
  stroke: "transparent",
};

const centeredLabelStyles = { textAnchor: "middle", ...baseLabelStyles };

// Strokes
const strokeLinecap = "square";
const strokeLinejoin = "square";

const VictoryTheme: VictoryThemeDefinition = {
  area: {
    ...baseProps,
    style: {
      data: {
        fill: PRIMARY,
        stroke: PLOTLINE,
        strokeWidth: 1,
      },
      labels: baseLabelStyles,
    },
  },

  axis: {
    ...baseProps,
    style: {
      axis: {
        fill: "transparent",
        stroke: PRIMARY,
        strokeWidth: 1,
        strokeLinecap,
        strokeLinejoin,
      },
      axisLabel: { ...centeredLabelStyles, padding: 25 },
      grid: {
        fill: "none",
        stroke: "none",
        pointerEvents: "painted",
      },
      ticks: {
        fill: "transparent",
        size: 1,
        stroke: "transparent",
      },
      tickLabels: baseLabelStyles,
    },
  },

  bar: {
    ...baseProps,
    style: {
      data: {
        fill: PRIMARY,
        padding: 8,
        strokeWidth: 0,
      },
      labels: baseLabelStyles,
    },
  },

  boxplot: {
    ...baseProps,
    style: {
      max: { padding: 8, stroke: PRIMARY, strokeWidth: 1 },
      maxLabels: { ...baseLabelStyles, padding: 3 },
      median: { padding: 8, stroke: PRIMARY, strokeWidth: 1 },
      medianLabels: { ...baseLabelStyles, padding: 3 },
      min: { padding: 8, stroke: PRIMARY, strokeWidth: 1 },
      minLabels: { ...baseLabelStyles, padding: 3 },
      q1: { padding: 8, fill: SECONDARY },
      q1Labels: { ...baseLabelStyles, padding: 3 },
      q3: { padding: 8, fill: SECONDARY },
      q3Labels: { ...baseLabelStyles, padding: 3 },
    },
    boxWidth: 20,
  },

  candlestick: {
    ...baseProps,
    style: {
      data: {
        stroke: PRIMARY,
        strokeWidth: 1,
      },
      labels: { ...baseLabelStyles, padding: 5 },
    },
    candleColors: {
      positive: "#ffffff",
      negative: PRIMARY,
    },
  },

  chart: baseProps,

  errorbar: {
    ...baseProps,
    borderWidth: 8,
    style: {
      data: {
        fill: "transparent",
        stroke: PRIMARY,
        strokeWidth: 2,
      },
      labels: baseLabelStyles,
    },
  },

  group: {
    ...baseProps,
    colorScale: colors,
  },

  histogram: {
    ...baseProps,
    style: {
      data: {
        fill: SECONDARY,
        stroke: PRIMARY,
        strokeWidth: 2,
      },
      labels: baseLabelStyles,
    },
  },

  legend: {
    colorScale: colors,
    gutter: 10,
    orientation: "vertical",
    titleOrientation: "top",
    style: {
      data: {
        type: "circle",
      },
      labels: baseLabelStyles,
      title: { ...baseLabelStyles, padding: 5 },
    },
  },

  line: {
    ...baseProps,
    style: {
      data: {
        stroke: PRIMARY,
        strokeWidth: 1,
      },
      labels: baseLabelStyles,
    },
  },

  pie: {
    style: {
      data: {
        padding: 10,
        stroke: "transparent",
        strokeWidth: 1,
      },
      labels: { ...baseLabelStyles, padding: 20 },
    },
    colorScale: colors,
    width: 400,
    height: 400,
    padding: 50,
  },

  scatter: {
    ...baseProps,
    style: {
      data: {
        fill: "#fff",
        stroke: PLOTLINE,
        strokeWidth: 1.5,
      },
      labels: baseLabelStyles,
    },
  },

  stack: {
    ...baseProps,
    colorScale: colors,
  },

  tooltip: {
    style: { ...baseLabelStyles, padding: 0, pointerEvents: "none" },
    flyoutStyle: {
      stroke: PRIMARY,
      strokeWidth: 1,
      fill: "#f0f0f0",
      pointerEvents: "none",
    },
    flyoutPadding: 5,
    cornerRadius: 5,
    pointerLength: 10,
  },

  voronoi: {
    ...baseProps,
    style: {
      data: {
        fill: "transparent",
        stroke: "transparent",
        strokeWidth: 0,
      },
      labels: {
        ...baseLabelStyles,
        padding: 5,
        pointerEvents: "none",
      },
      flyout: {
        stroke: PRIMARY,
        strokeWidth: 1,
        fill: "#f0f0f0",
        pointerEvents: "none",
      },
    },
  },
};

export { VictoryTheme };
