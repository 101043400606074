import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";
import {
  IconProps,
  getDimensionsFromSize,
  getColorFromVariant,
} from "./shared";

const TickIcon: FC<IconProps> = ({ size, variant, className, ...props }) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);

  return (
    <svg
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m6.666 10.333-.661.75a1 1 0 0 0 1.369-.043l-.708-.707Zm6.04-4.626a1 1 0 0 0-1.413-1.414l1.414 1.414ZM4.81 7.361a1 1 0 0 0-1.324 1.5l1.324-1.5Zm2.564 3.68 5.333-5.334-1.414-1.414-5.334 5.333 1.415 1.414Zm-.046-1.457L4.81 7.36l-1.324 1.5 2.519 2.222 1.323-1.5Z"
        fill={fill}
      />
    </svg>
  );
};

export { TickIcon };
