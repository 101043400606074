import { InputProps } from "@multiply/lib";
import { FC } from "react";
import { CurrencyInput } from "../CurrencyInput";
import Minus from "./Minus";
import Plus from "./Plus";

export type PlusMinusCurrencyInputProps = {
  value: number | string;
  onSubmit?: () => void;
  onChange?: (value: number | null) => void;
  onBlur?: () => void;
  onIncrease: () => void;
  onDecrease: () => void;
} & Omit<InputProps, "onChange">;

const PlusMinusCurrencyInput: FC<PlusMinusCurrencyInputProps> = ({
  value,
  className,
  error,
  onChange,
  onBlur,
  onIncrease,
  onDecrease,
  ...inputProps
}) => {
  return (
    <div className="flex items-end gap-x-1">
      <Minus onClick={onDecrease} />
      <CurrencyInput
        placeholder="Enter an amount"
        error={error}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className={className}
        variant="plain"
        {...inputProps}
      />
      <Plus onClick={onIncrease} />
    </div>
  );
};

export { PlusMinusCurrencyInput };
