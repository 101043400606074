import { AssetIcon, BackArrowIcon, EmptyAddCard, Spinner } from "@multiply/lib";
import { Link } from "react-router-dom";
import { CURRENCY_SYMBOL } from "../../utils";
import classNames from "classnames";

export interface AssetWithProductType {
  balance: string | undefined;
  productType: "debt" | "asset";
  name: string;
}

type AssetCardGroupProps = {
  className?: string;
  result: Array<AssetWithProductType> | null;
  loading: boolean;
  manageText: string;
  addText: string;
};

const AssetOrDebtCardGroup = ({
  className,
  result,
  loading,
  manageText,
  addText,
}: AssetCardGroupProps) => {
  return (
    <>
      {!loading ? (
        result && result.length > 0 ? (
          <div className="flex flex-col">
            <div
              className={classNames(
                "flex sm:flex-col gap-12 w-full overflow-x-auto sm:overflow-x-hidden no-scrollbar shadow-none max-h-240 sm:max-h-full"
              )}
            >
              {result.map((item, i) => {
                return (
                  <Link key={`${item?.productType}-${i}`} to="/products">
                    <div
                      className={classNames(
                        "flex justify-center items-center py-16 w-280 sm:min-w-full sm:max-w-full h-65 bg-input-background rounded-lg",
                        className
                      )}
                    >
                      <div className="pl-18">
                        {item.productType === "asset" ? (
                          <AssetIcon size="lg" variant="green" />
                        ) : (
                          <BackArrowIcon size="lg" variant="green" />
                        )}
                      </div>
                      <div className="mx-16 flex-1 min-w-0">
                        <h1 className="text-t16 text-font-primary whitespace-nowrap text-ellipsis overflow-hidden font-semibold">
                          {item?.name}
                        </h1>
                        <p
                          className="text-t14 sm:text-t16 text-font-secondary whitespace-nowrap text-ellipsis overflow-hidden"
                          data-testid="goal-test-subtitle"
                        >
                          {item?.balance ?? `${CURRENCY_SYMBOL}0 `}
                        </p>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
            <div className="flex-1 flex items-start justify-start mt-16">
              <Link
                to="/products"
                className="whitespace-nowrap text-font-links self-start text-t14 sm:text-t16"
              >
                {manageText}
              </Link>
            </div>
          </div>
        ) : (
          <EmptyAddCard to="/products" title={addText} />
        )
      ) : (
        <Spinner size="md" />
      )}
    </>
  );
};

export { AssetOrDebtCardGroup };
