import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";
import {
  IconProps,
  getDimensionsFromSize,
  getColorFromVariant,
} from "./shared";

const UmbrellaOutlineIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);

  return (
    <svg
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="umbrella icon"
      strokeWidth={0.75}
      {...props}
    >
      <path
        d="M8.486 8.144v4.804a1.569 1.569 0 0 1-1.564 1.564 1.569 1.569 0 0 1-1.565-1.564"
        stroke={fill}
        fill="none"
        strokeWidth="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.482 8.136c1.21 0 2.268.533 2.862 1.33.595-.797 1.653-1.33 2.863-1.33s2.362.504 2.957 1.301c.595-.797 1.558-1.3 2.767-1.3.508-.002 1.012.096 1.482.288-.274-3.602-3.392-6.445-7.206-6.445C4.39 1.98 1.273 4.824 1 8.428c.47-.193.974-.292 1.482-.292Z"
        stroke={fill}
        strokeWidth="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.17 1v.863a.265.265 0 0 1-.085.194c-.472.443-2.74 2.81-2.74 7.41M8.327 1.98s2.836 2.232 2.836 7.364"
        stroke={fill}
        strokeWidth="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { UmbrellaOutlineIcon };
