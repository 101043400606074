import {
  NestCircleIcon,
  useWindowDimensions
} from "@multiply/lib";
import bannerDesktop from "../../assets/images/pension-banner.png";
import bannerMobile from "../../assets/images/pension-plan.png";
import { PlanOverviewLayout } from "../../components/PlanOverviewLayout";

const PensionPlan = () => {
  const { isLessThanCustomWidth } = useWindowDimensions(730);
  return (
    <PlanOverviewLayout
      bannerImg={isLessThanCustomWidth ? bannerMobile : bannerDesktop}
      title={
        <>
          Get your <br /> pension sorted
        </>
      }
      subtitle="Get advice focussed on your retirement"
      highlights={[
        {
          icon: <NestCircleIcon variant="icon" size="xxl" />,
          title: "Planning for retirement",
          description:
            "Visualise your retirement goals and put a plan in place to reach them.",
        },
      ]}
      suitableFor="Anyone aged 18-59 planning for their own retirement."
    />
  );
};

export { PensionPlan };
