import { PrsaFund } from "../../graphqlTypes";
import { getPensionPortfolios } from "../../utils";
import { FactsheetLink } from "./FactsheetLink";
import { ResponsiblePension } from "./ResponsiblePension";

type PensionsPortfolioCardType = {
  recommendedFund: PrsaFund;
};

const PensionPortfolioCard = ({
  recommendedFund,
}: PensionsPortfolioCardType) => {
  const { name, isResponsible, detailsLink } =
    getPensionPortfolios(recommendedFund);
  return (
    <div className="bg-background-primary pt-16 pb-24 px-24 rounded-xsm">
      <div className="sm:flex justify-between items-center pb-20 sm:pb-0">
        <p className="text-font-primary text-t18 sm:text-t27 pb-8 font-semibold mr-12">
          {name}
        </p>
        {isResponsible && <ResponsiblePension text="RESPONSIBLE PENSION" />}
      </div>
      <FactsheetLink detailsLink={detailsLink} />
    </div>
  );
};

export { PensionPortfolioCard };
