import {
  Accordion,
  Button,
  Card,
  InfoCircle,
  SectionHeading,
  useAuth,
} from "@multiply/lib";
import { Controller } from "react-hook-form";
import {
  CurrencyInput,
  ModalNavLink,
  PlusMinusCurrencyInput,
} from "../../components";
import {
  PensionSimulatorFormValues,
  usePensionSimulatorForm,
} from "./usePensionSimulatorForm";
import { CURRENCY_SYMBOL, convertNumberToEuroString } from "../../utils";
import { KeyItem } from "./KeyItem";
import { InputRetirementAge } from "./InputRetirementAge";
import { useGetPensionCalculationQuery } from "../../graphqlTypes";
import { PensionsGraph } from "./PensionsGraph";
import {
  STATE_RETIREMENT_AGE,
  bulletSections,
} from "./pensionAccordionBullets";

type PensionSimulatorProps = {
  projectedIncome: number;
  defaultValues: PensionSimulatorFormValues;
  error?: string;
  onSubmit: (values: PensionSimulatorFormValues, dirtyFields: string) => void;
};
const PensionSimulatorForm = ({
  projectedIncome,
  defaultValues,
  error,
  onSubmit,
}: PensionSimulatorProps) => {
  const { formState, handleSubmit, control, watch, setValue, getFieldState } =
    usePensionSimulatorForm({
      defaultValues,
    });
  const { isSubmitting, isValid } = formState;
  const isDirty =
    getFieldState("retirementAge", formState).isDirty ||
    getFieldState("targetMonthlyIncome", formState).isDirty;

  const watchTargetMonthlyIncome = watch("targetMonthlyIncome") ?? 0;
  const watchMonthlyContribution = watch("monthlyContribution") ?? 0;
  const watchOneOffContribution = watch("oneOffContribution") ?? 0;
  const watchRetirementAge = watch("retirementAge") ?? 0;

  const { userId } = useAuth();
  const [pensionResults] = useGetPensionCalculationQuery({
    variables: {
      input: {
        targetMonthlyIncome: watchTargetMonthlyIncome,
        retirementAge: watchRetirementAge,
        contributionPerMonth: watchMonthlyContribution,
        oneOffContribution: watchOneOffContribution,
        userId: userId ?? "",
      },
    },
    pause: !userId,
  });

  const pensionProjection =
    pensionResults.data?.calculatePension?.pensionProjection ?? [];

  const pensionAtStateRetirementAge = pensionProjection.find(
    (item) => item?.age === watchRetirementAge + 1
  );

  const targetIncomeAtRetirementPerMonth =
    (pensionAtStateRetirementAge?.targetIncome?.float ?? 0) / 12;

  const projectedIncomeAtRetirementPerMonth =
    (pensionAtStateRetirementAge?.totalPensionAmount?.float ?? 0) / 12;

  const gapToTargetPerMonth =
    projectedIncomeAtRetirementPerMonth - targetIncomeAtRetirementPerMonth;

  const statePensionAmountPerMonth =
    (pensionAtStateRetirementAge?.statePensionAmount?.float ?? 0) / 12;

  const totalPensionAmountPerMonth =
    (pensionAtStateRetirementAge?.totalPensionAmount?.float ?? 0) / 12;

  const decreaseMonthlyContribution = () => {
    if (watchMonthlyContribution > 0) {
      let newValue = Math.round((watchMonthlyContribution - 50) / 50) * 50;
      setValue("monthlyContribution", newValue < 0 ? 0 : newValue, {
        shouldValidate: true,
      });
    }
  };

  const increaseMonthlyContribution = () => {
    let newValue = Math.round((watchMonthlyContribution + 50) / 50) * 50;
    setValue("monthlyContribution", newValue, {
      shouldValidate: true,
    });
  };

  const addDirtyFields = (values: any) => {
    const dirtyFields = `${
      getFieldState("targetMonthlyIncome", formState).isDirty
        ? "targetMonthlyIncome"
        : ""
    }${
      getFieldState("retirementAge", formState).isDirty ? "retirementAge" : ""
    }`;
    onSubmit(values, dirtyFields);
  };

  return (
    <section className="flex flex-col space-y-32 max-w-860">
      <SectionHeading
        title="Pension calculator"
        subtitle="Compare your projected and target income. Then see the impact changing your retirement age and contributions could have on your pension."
        className="max-w-800"
      />

      <Card className="flex flex-col py-28 px-16 sm:py-36 sm:px-28 w-full space-y-18 sm:space-y-32 sm:max-w-640 xl:max-w-800">
        <section className="flex flex-col sm:flex-row sm:justify-between">
          <div>
            <h1 className="test-t15 font-semibold text-font-secondary">
              Projected income in today's money
            </h1>
            <p className="test-t16 font-bold text-font-primary">
              {convertNumberToEuroString(Math.round(projectedIncome))} per month
            </p>
            <p className="text-t13 text-font-secondary mb-12">
              {convertNumberToEuroString(
                Math.round(projectedIncomeAtRetirementPerMonth)
              )}{" "}
              at age {STATE_RETIREMENT_AGE} when adjusted for inflation
            </p>
          </div>
          <div className="flex flex-col">
            <h1 className="test-t15 font-semibold text-font-secondary">
              Target income in today's money
            </h1>
            <Controller
              control={control}
              name="targetMonthlyIncome"
              render={({
                field: { value, onBlur, onChange },
                fieldState: { error },
              }) => (
                <CurrencyInput
                  placeholder={`${CURRENCY_SYMBOL}0`}
                  error={error?.message}
                  value={value ?? ""}
                  onChange={onChange}
                  onBlur={onBlur}
                  className="w-200"
                  rightIcon="per month"
                />
              )}
            />
            <p className="text-t14 text-font-secondary mt-2">
              {convertNumberToEuroString(
                Math.round(projectedIncomeAtRetirementPerMonth)
              )}{" "}
              at age {STATE_RETIREMENT_AGE} when adjusted for inflation
            </p>
          </div>
        </section>

        <section className="w-full max-w-620 pt-8 sm:pt-18">
          <PensionsGraph
            inputValues={{
              targetMonthlyIncome: watchTargetMonthlyIncome,
              retirementAge: watchRetirementAge,
              contributionPerMonth: watchMonthlyContribution,
              oneOffContribution: watchOneOffContribution,
            }}
          />

          <p className="text-t13 uppercase text-font-secondary font-bold">
            PROJECTED INCOME PER MONTH (AGE {watchRetirementAge})
          </p>
          <p className="text-t13  text-font-secondary font-normal mb-10">
            Figures adjusted for inflation
          </p>

          <div className="flex flex-row gap-16 flex-nowrap w-full justify-between">
            <KeyItem
              colour="bg-brand-900"
              text="state pension"
              amount={statePensionAmountPerMonth}
            />
            <KeyItem
              colour="bg-accent-primary"
              text="your pensions"
              amount={totalPensionAmountPerMonth}
            />
            <KeyItem
              colour="bg-gray-200"
              text="gap to target"
              amount={gapToTargetPerMonth > 0 ? 0 : gapToTargetPerMonth}
            />
          </div>
        </section>

        <section className="flex flex-col sm:flex-row sm:flex-wrap sm:gap-x-20">
          <div className="mb-18">
            <Controller
              control={control}
              name="monthlyContribution"
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
              }) => (
                <div className="flex flex-col mb-2">
                  <div className="text-font-secondary text-t16 font-semibold pb-8">
                    Additional contribution per month
                  </div>
                  <PlusMinusCurrencyInput
                    placeholder={`${CURRENCY_SYMBOL}0`}
                    error={error?.message}
                    value={value ?? ""}
                    onChange={onChange}
                    onBlur={onBlur}
                    onIncrease={increaseMonthlyContribution}
                    onDecrease={decreaseMonthlyContribution}
                    className="w-100"
                  />
                </div>
              )}
            />

            <ModalNavLink to="/cross/tax-relief-contributions">
              <div className="flex justify-start items-center gap-x-4 mt-4">
                <p className="text-t14 text-font-secondary">
                  Net cost after tax relief:{" "}
                  {convertNumberToEuroString(
                    (watchMonthlyContribution ?? 0) * 0.6
                  )}
                </p>
                <InfoCircle />
              </div>
            </ModalNavLink>
          </div>

          <div className="flex gap-x-4 sm:gap-x-20 pb-16 sm:pb-24">
            <Controller
              control={control}
              name="oneOffContribution"
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
              }) => (
                <CurrencyInput
                  label="One off contribution"
                  placeholder={`${CURRENCY_SYMBOL}0`}
                  error={error?.message}
                  value={value ?? ""}
                  onChange={onChange}
                  onBlur={onBlur}
                  className="max-w-200"
                />
              )}
            />
            <Controller
              control={control}
              name="retirementAge"
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
              }) => (
                <InputRetirementAge
                  currentAge={50}
                  error={error?.message}
                  value={value ?? null}
                  onChange={onChange}
                  onBlur={onBlur}
                  className="max-w-120"
                />
              )}
            />
          </div>
        </section>

        <Button
          disabled={!isValid || isSubmitting || !isDirty}
          onClick={handleSubmit(addDirtyFields)}
          aria-label="update my retirement goals"
          className="w-full sm:w-1/2"
        >
          Update my retirement goals
        </Button>

        {error ? <p className="text-action-error mt-12">{error}</p> : null}
      </Card>

      <footer>
        <p className="text-font-primary text-t16 ring-2 ring-font-primary px-32 py-24 mb-14">
          Warning: These figures are estimates only. They are not a reliable
          guide to the future performance of your investment.
        </p>

        <Accordion
          className="mb-32 w-full h-full"
          title="Notes and assumptions"
          bulletSections={bulletSections}
        />
      </footer>
    </section>
  );
};

export default PensionSimulatorForm;
