import gql from 'graphql-tag';
import * as Urql from 'urql';
import { graphql, ResponseResolver, GraphQLRequest, GraphQLContext } from 'msw'
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AssetData: any;
  Date: any;
  Datetime: any;
  DebtData: any;
  FactfindErrors: any;
  FieldErrors: any;
  GoalData: any;
  Markdown: any;
  MoneyInput: any;
  Percentage: any;
  PolicyData: any;
  RawJSONForDebugging: any;
  UUID: any;
};

export enum AccommodationType {
  Home = 'HOME',
  Rented = 'RENTED',
  Student = 'STUDENT'
}

/** A generic address */
export type Address = {
  __typename: 'Address';
  buildingName?: Maybe<Scalars['String']>;
  buildingNumber?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  flatNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  postalCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  subStreet?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
};

/** Add an address */
export type AddressInput = {
  buildingName?: InputMaybe<Scalars['String']>;
  buildingNumber?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  flatNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  postalCode?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  subStreet?: InputMaybe<Scalars['String']>;
  town?: InputMaybe<Scalars['String']>;
};

export type Appointment = {
  __typename: 'Appointment';
  advisorEmail?: Maybe<Scalars['String']>;
  advisorId: Scalars['ID'];
  advisorName: Scalars['String'];
  advisorPhone?: Maybe<Scalars['String']>;
  endTime: Scalars['Datetime'];
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
  startTime: Scalars['Datetime'];
};

/** Asset entered in fact find */
export type Asset = {
  __typename: 'Asset';
  accessible?: Maybe<Scalars['Boolean']>;
  /** Whether pension is currently active. Affects: Pension */
  active?: Maybe<Scalars['Boolean']>;
  balance?: Maybe<Money>;
  /** Affects: An Post State Savings Account / Life Company Savings Plan */
  frequency?: Maybe<PaymentFrequency>;
  id: Scalars['UUID'];
  /** Whether contributions are increasing each year. Affects: Pension */
  increasing?: Maybe<Scalars['Boolean']>;
  isComplete: Scalars['Boolean'];
  /** Affects: Credit Union Account / Shares / Government Bond / Investment Bond / Other */
  maturityDate?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  /** Affects: Current account */
  overdrawnBy?: Maybe<Money>;
  owners?: Maybe<Array<Maybe<Person>>>;
  /** Affects: Pension */
  pensionType?: Maybe<PensionType>;
  productType: AssetType;
  /** Affects: Property */
  propertyType?: Maybe<PropertyType>;
  provider?: Maybe<Provider>;
  /** Affects: An Post State Savings Account / Pension / Life Company Savings Plan */
  regularPaymentAmount?: Maybe<Money>;
  /** Affects: An Post State Savings Account / Life Company Savings Plan */
  startDate?: Maybe<Scalars['Date']>;
  /** Whether pension is provided by current employer. Affects: Pension */
  throughCurrentEmployer?: Maybe<Scalars['Boolean']>;
  userId: Scalars['ID'];
  /** Affects: Pension */
  valuationMethod?: Maybe<ValuationMethod>;
  /** Affects: Cash / Current Account / All Savings and Deposits / Credit Union Account / An Post State Savings Account / Shares / Government Bond / Investment Bond / Property / Approved Retirement Fund / Land / Other */
  whereFrom?: Maybe<AssetOrigin>;
};

export type AssetInput = {
  accessible?: InputMaybe<Scalars['Boolean']>;
  /** Whether pension is currently active. Affects: Pension */
  active?: InputMaybe<Scalars['Boolean']>;
  balance?: InputMaybe<Scalars['MoneyInput']>;
  /** Affects: An Post State Savings Account / Life Company Savings Plan */
  frequency?: InputMaybe<PaymentFrequency>;
  /** Whether contributions are increasing each year. Affects: Pension */
  increasing?: InputMaybe<Scalars['Boolean']>;
  /** Affects: Credit Union Account / Shares / Government Bond / Investment Bond / Other */
  maturityDate?: InputMaybe<Scalars['Date']>;
  name?: InputMaybe<Scalars['String']>;
  /** Affects: Current account */
  overdrawnBy?: InputMaybe<Scalars['MoneyInput']>;
  owners?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Affects: Pension */
  pensionType?: InputMaybe<PensionType>;
  productType: AssetType;
  /** Affects: Property */
  propertyType?: InputMaybe<PropertyType>;
  provider?: InputMaybe<Scalars['ID']>;
  providerName?: InputMaybe<Scalars['String']>;
  /** Affects: An Post State Savings Account / Pension / Life Company Savings Plan */
  regularPaymentAmount?: InputMaybe<Scalars['MoneyInput']>;
  /** Affects: An Post State Savings Account / Life Company Savings Plan */
  startDate?: InputMaybe<Scalars['Date']>;
  /** Whether pension is provided by current employer. Affects: Pension */
  throughCurrentEmployer?: InputMaybe<Scalars['Boolean']>;
  /** Affects: Pension */
  valuationMethod?: InputMaybe<ValuationMethod>;
  /** Affects: Cash / Current Account / All Savings and Deposits / Credit Union Account / An Post State Savings Account / Shares / Government Bond / Investment Bond / Property / Approved Retirement Fund / Land / Other */
  whereFrom?: InputMaybe<AssetOrigin>;
};

export enum AssetOrigin {
  Bonus = 'BONUS',
  Borrowing = 'BORROWING',
  Compensation = 'COMPENSATION',
  Gift = 'GIFT',
  Inheritance = 'INHERITANCE',
  LifeAssuranceClaimDeath = 'LIFE_ASSURANCE_CLAIM_DEATH',
  LifeAssuranceClaimIllness = 'LIFE_ASSURANCE_CLAIM_ILLNESS',
  Other = 'OTHER',
  PensionLumpSum = 'PENSION_LUMP_SUM',
  Redundancy = 'REDUNDANCY',
  RegularSavings = 'REGULAR_SAVINGS',
  Reinvestment = 'REINVESTMENT',
  Salary = 'SALARY',
  SaleOfInvestment = 'SALE_OF_INVESTMENT',
  SaleOfTheFamilyHome = 'SALE_OF_THE_FAMILY_HOME',
  Windfall = 'WINDFALL'
}

export enum AssetType {
  AnPostSavingsAccount = 'AN_POST_SAVINGS_ACCOUNT',
  ApprovedRetirementFund = 'APPROVED_RETIREMENT_FUND',
  Cash = 'CASH',
  CreditUnionAccount = 'CREDIT_UNION_ACCOUNT',
  CurrentAccount = 'CURRENT_ACCOUNT',
  DepositTracker = 'DEPOSIT_TRACKER',
  GovernmentBond = 'GOVERNMENT_BOND',
  InvestmentBond = 'INVESTMENT_BOND',
  Land = 'LAND',
  LifeCompanySavingsPlan = 'LIFE_COMPANY_SAVINGS_PLAN',
  OtherAsset = 'OTHER_ASSET',
  Pension = 'PENSION',
  Property = 'PROPERTY',
  RegularSavingsDepositAccount = 'REGULAR_SAVINGS_DEPOSIT_ACCOUNT',
  SavingsDepositAccount = 'SAVINGS_DEPOSIT_ACCOUNT',
  Shares = 'SHARES',
  TermAccount = 'TERM_ACCOUNT'
}

export type AssetsInput = {
  accessible?: InputMaybe<Scalars['Boolean']>;
  /** Whether pension is currently active. Affects: Pension */
  active?: InputMaybe<Scalars['Boolean']>;
  assetId?: InputMaybe<Scalars['UUID']>;
  balance?: InputMaybe<Scalars['MoneyInput']>;
  /** Affects: An Post State Savings Account / Life Company Savings Plan */
  frequency?: InputMaybe<PaymentFrequency>;
  /** Whether contributions are increasing each year. Affects: Pension */
  increasing?: InputMaybe<Scalars['Boolean']>;
  /** Affects: Credit Union Account / Shares / Government Bond / Investment Bond / Other */
  maturityDate?: InputMaybe<Scalars['Date']>;
  name?: InputMaybe<Scalars['String']>;
  /** Affects: Current account */
  overdrawnBy?: InputMaybe<Scalars['MoneyInput']>;
  owners?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Affects: Pension */
  pensionType?: InputMaybe<PensionType>;
  productType: AssetType;
  /** Affects: Property */
  propertyType?: InputMaybe<PropertyType>;
  provider?: InputMaybe<Scalars['ID']>;
  providerName?: InputMaybe<Scalars['String']>;
  /** Affects: An Post State Savings Account / Pension / Life Company Savings Plan */
  regularPaymentAmount?: InputMaybe<Scalars['MoneyInput']>;
  /** Affects: An Post State Savings Account / Life Company Savings Plan */
  startDate?: InputMaybe<Scalars['Date']>;
  /** Whether pension is provided by current employer. Affects: Pension */
  throughCurrentEmployer?: InputMaybe<Scalars['Boolean']>;
  /** Affects: Pension */
  valuationMethod?: InputMaybe<ValuationMethod>;
  /** Affects: Cash / Current Account / All Savings and Deposits / Credit Union Account / An Post State Savings Account / Shares / Government Bond / Investment Bond / Property / Approved Retirement Fund / Land / Other */
  whereFrom?: InputMaybe<AssetOrigin>;
};

export enum AuthorisationStatus {
  AdviceApproved = 'ADVICE_APPROVED',
  AwaitingUserInfo = 'AWAITING_USER_INFO',
  AwaitingUserKycAction = 'AWAITING_USER_KYC_ACTION',
  Error = 'ERROR',
  FailedKyc = 'FAILED_KYC',
  GuidanceDuringSuspend = 'GUIDANCE_DURING_SUSPEND',
  GuidancePendingReview = 'GUIDANCE_PENDING_REVIEW',
  PendingKyc = 'PENDING_KYC',
  PendingReview = 'PENDING_REVIEW',
  Referred = 'REFERRED',
  Rejected = 'REJECTED',
  Suspended = 'SUSPENDED',
  Triaged = 'TRIAGED'
}

export type AvailableAppointment = {
  __typename: 'AvailableAppointment';
  endTime: Scalars['Datetime'];
  id: Scalars['ID'];
  startTime: Scalars['Datetime'];
};

export type AvailableAppointmentDay = {
  __typename: 'AvailableAppointmentDay';
  date: Scalars['Date'];
  id: Scalars['ID'];
};

/** Specifies the biological sex of a person */
export enum BiologicalSex {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type BookAppointmentInput = {
  /** Any other notes about the appointment */
  notes?: InputMaybe<Scalars['String']>;
  /** Reason for the appointment */
  reason?: InputMaybe<Scalars['String']>;
  /** Start time of the appointment */
  startTime: Scalars['Datetime'];
  /** The id of the user */
  userId: Scalars['ID'];
};

export type BookAppointmentPayload = MutationPayload & {
  __typename: 'BookAppointmentPayload';
  appointment?: Maybe<Appointment>;
  error?: Maybe<ErrorType>;
  success: Scalars['Boolean'];
};

export type BreakdownSlice = {
  __typename: 'BreakdownSlice';
  amount: Money;
  name: Scalars['String'];
};

export type ChangeEmailVerificationInput = {
  /** The new email */
  email: Scalars['String'];
  /** The ID of the user */
  userId: Scalars['ID'];
};

export type ChangeEmailVerificationPayload = MutationPayload & {
  __typename: 'ChangeEmailVerificationPayload';
  /** The new email, if the mutation is successful */
  email?: Maybe<Scalars['String']>;
  error?: Maybe<ErrorType>;
  success: Scalars['Boolean'];
};

export type ChildPerson = {
  __typename: 'ChildPerson';
  dateOfBirth?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
};

export type CollegeCalculatorInput = {
  accommodation: AccommodationType;
  ageOfChild: Scalars['Int'];
  courseDuration: Scalars['Int'];
  eligibleForFreeTuitionFees: Scalars['Boolean'];
  userId?: InputMaybe<Scalars['ID']>;
  whatToInclude: Array<WhatToIncludeType>;
};

export type CollegeCost = {
  __typename: 'CollegeCost';
  accommodationCost?: Maybe<Money>;
  accommodationType?: Maybe<AccommodationType>;
  ageOfChild?: Maybe<Scalars['Int']>;
  annualCost?: Maybe<Money>;
  courseDuration?: Maybe<Scalars['Int']>;
  eligibleForFreeTuitionFees?: Maybe<Scalars['Boolean']>;
  fees?: Maybe<Money>;
  goalId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  monthlySavingsRequired?: Maybe<Money>;
  otherExpenses?: Maybe<Money>;
  targetDate?: Maybe<Scalars['Date']>;
  total?: Maybe<Money>;
  totalAdjustedForInflation?: Maybe<Money>;
  userId?: Maybe<Scalars['ID']>;
  whatToInclude?: Maybe<Array<Maybe<WhatToIncludeType>>>;
};

export type ComparisonBlock = {
  __typename: 'ComparisonBlock';
  features: Array<ComparisonFeature>;
  packages: Array<ComparisonPackage>;
};

export type ComparisonFeature = {
  __typename: 'ComparisonFeature';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ComparisonPackage = {
  __typename: 'ComparisonPackage';
  cost: Money;
  features: Array<Scalars['String']>;
  name: Scalars['String'];
};

export type Consent = {
  __typename: 'Consent';
  contactServicesIrishLifeGroup?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type ConsentInput = {
  contactServicesIrishLifeGroup?: InputMaybe<Scalars['Boolean']>;
};

export type ContentBlock = ComparisonBlock | MarkdownBlock | UrlBlock;

export enum CoverForScenario {
  Covered = 'COVERED',
  NotCovered = 'NOT_COVERED',
  PartiallyCovered = 'PARTIALLY_COVERED'
}

export enum CoverType {
  DeathInService = 'DEATH_IN_SERVICE',
  Decreasing = 'DECREASING',
  Level = 'LEVEL',
  MortgageProtection = 'MORTGAGE_PROTECTION',
  WholeOfLife = 'WHOLE_OF_LIFE'
}

/** Debt entered in fact find */
export type Debt = {
  __typename: 'Debt';
  arePaymentsUpToDate?: Maybe<Scalars['Boolean']>;
  arrears?: Maybe<Money>;
  balance?: Maybe<Money>;
  clearedOnDeath?: Maybe<Scalars['Boolean']>;
  /** Loan */
  endDate?: Maybe<Scalars['Date']>;
  /** Mortgage */
  hasPaymentProtectionInsurance?: Maybe<Scalars['Boolean']>;
  id: Scalars['UUID'];
  isComplete: Scalars['Boolean'];
  /** Mortgage */
  isMainMortgage?: Maybe<Scalars['Boolean']>;
  /** Car Loan / Hire Purchase Agreement / Personal Loan / Other */
  maturityDate?: Maybe<Scalars['Date']>;
  monthlyRepayment?: Maybe<Money>;
  /** Mortgage */
  mortgageType?: Maybe<MortgageType>;
  /** Mortgage */
  mortgageYearsLeft?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  owners?: Maybe<Array<Maybe<Person>>>;
  productType: DebtType;
  provider?: Maybe<Provider>;
  repaidMonthly?: Maybe<Scalars['Boolean']>;
  userId: Scalars['ID'];
};

export type DebtInput = {
  arePaymentsUpToDate?: InputMaybe<Scalars['Boolean']>;
  arrears?: InputMaybe<Scalars['MoneyInput']>;
  balance?: InputMaybe<Scalars['MoneyInput']>;
  clearedOnDeath?: InputMaybe<Scalars['Boolean']>;
  /** Loan */
  endDate?: InputMaybe<Scalars['Date']>;
  /** Mortgage */
  hasPaymentProtectionInsurance?: InputMaybe<Scalars['Boolean']>;
  /** Mortgage */
  isMainMortgage?: InputMaybe<Scalars['Boolean']>;
  /** Car Loan / Hire Purchase Agreement / Personal Loan / Other */
  maturityDate?: InputMaybe<Scalars['Date']>;
  monthlyRepayment?: InputMaybe<Scalars['MoneyInput']>;
  /** Mortgage */
  mortgageType?: InputMaybe<MortgageType>;
  /** Mortgage */
  mortgageYearsLeft?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  owners?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  productType: DebtType;
  provider?: InputMaybe<Scalars['ID']>;
  providerName?: InputMaybe<Scalars['String']>;
  repaidMonthly?: InputMaybe<Scalars['Boolean']>;
};

export type DebtSummary = {
  __typename: 'DebtSummary';
  clearedBy: Scalars['String'];
  idealMonthlyRepayment: Money;
  totalDebtAmount: Money;
};

export enum DebtType {
  CarLoan = 'CAR_LOAN',
  CreditCard = 'CREDIT_CARD',
  HirePurchaseAgreement = 'HIRE_PURCHASE_AGREEMENT',
  Loan = 'LOAN',
  Mortgage = 'MORTGAGE',
  OtherDebt = 'OTHER_DEBT',
  PersonalLoan = 'PERSONAL_LOAN'
}

export type DebtsInput = {
  arePaymentsUpToDate?: InputMaybe<Scalars['Boolean']>;
  arrears?: InputMaybe<Scalars['MoneyInput']>;
  balance?: InputMaybe<Scalars['MoneyInput']>;
  clearedOnDeath?: InputMaybe<Scalars['Boolean']>;
  debtId?: InputMaybe<Scalars['UUID']>;
  /** Loan */
  endDate?: InputMaybe<Scalars['Date']>;
  /** Mortgage */
  hasPaymentProtectionInsurance?: InputMaybe<Scalars['Boolean']>;
  /** Mortgage */
  isMainMortgage?: InputMaybe<Scalars['Boolean']>;
  /** Car Loan / Hire Purchase Agreement / Personal Loan / Other */
  maturityDate?: InputMaybe<Scalars['Date']>;
  monthlyRepayment?: InputMaybe<Scalars['MoneyInput']>;
  /** Mortgage */
  mortgageType?: InputMaybe<MortgageType>;
  /** Mortgage */
  mortgageYearsLeft?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  owners?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  productType: DebtType;
  provider?: InputMaybe<Scalars['ID']>;
  providerName?: InputMaybe<Scalars['String']>;
  repaidMonthly?: InputMaybe<Scalars['Boolean']>;
};

export type DeclineSurveyInput = {
  /** The user ID of the user */
  userId: Scalars['ID'];
};

export type DeclineSurveyPayload = MutationPayload & {
  __typename: 'DeclineSurveyPayload';
  error?: Maybe<ErrorType>;
  success: Scalars['Boolean'];
  survey?: Maybe<NpsSurvey>;
};

export type DeleteAssetInput = {
  assetId: Scalars['UUID'];
  userId: Scalars['ID'];
};

export type DeleteAssetPayload = MutationPayload & {
  __typename: 'DeleteAssetPayload';
  error?: Maybe<ErrorType>;
  success: Scalars['Boolean'];
};

export type DeleteDebtInput = {
  debtId: Scalars['UUID'];
  userId: Scalars['ID'];
};

export type DeleteDebtPayload = MutationPayload & {
  __typename: 'DeleteDebtPayload';
  error?: Maybe<ErrorType>;
  success: Scalars['Boolean'];
};

export type DeleteGoalInput = {
  goalId: Scalars['UUID'];
  userId: Scalars['ID'];
};

export type DeleteGoalPayload = MutationPayload & {
  __typename: 'DeleteGoalPayload';
  error?: Maybe<ErrorType>;
  success: Scalars['Boolean'];
};

export type DeleteIncomeInput = {
  incomeId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type DeleteIncomePayload = MutationPayload & {
  __typename: 'DeleteIncomePayload';
  error?: Maybe<ErrorType>;
  success: Scalars['Boolean'];
};

export type DeletePolicyInput = {
  policyId: Scalars['UUID'];
  userId: Scalars['ID'];
};

export type DeletePolicyPayload = MutationPayload & {
  __typename: 'DeletePolicyPayload';
  error?: Maybe<ErrorType>;
  success: Scalars['Boolean'];
};

export type DeleteVulnerabilityReminderInput = {
  userId: Scalars['ID'];
};

export type DeleteVulnerabilityReminderPayload = MutationPayload & {
  __typename: 'DeleteVulnerabilityReminderPayload';
  error?: Maybe<ErrorType>;
  success: Scalars['Boolean'];
};

export enum DependantRelationship {
  Child = 'CHILD',
  OtherFamilyMember = 'OTHER_FAMILY_MEMBER',
  OtherNonFamilyMember = 'OTHER_NON_FAMILY_MEMBER',
  Parent = 'PARENT'
}

export type DismissContextualAdviceInput = {
  /** UUID of the advice */
  adviceId: Scalars['ID'];
  /** The id of the user */
  userId: Scalars['ID'];
};

export type DismissContextualAdvicePayload = MutationPayload & {
  __typename: 'DismissContextualAdvicePayload';
  error?: Maybe<ErrorType>;
  success: Scalars['Boolean'];
};

export type EmergencyFundProgress = {
  __typename: 'EmergencyFundProgress';
  currentProvision: Money;
  monthsProtected: Scalars['Int'];
  progress: Scalars['Percentage'];
  recommendedProtectedMonths: Scalars['Int'];
  recommendedProvision: Money;
};

export type EmergencyFundSummary = {
  __typename: 'EmergencyFundSummary';
  emergencyFundProgress: EmergencyFundProgress;
};

export enum EmploymentStatus {
  Employed = 'EMPLOYED',
  Homemaker = 'HOMEMAKER',
  Retired = 'RETIRED',
  StudentOrTraining = 'STUDENT_OR_TRAINING',
  Unemployed = 'UNEMPLOYED'
}

export enum EmploymentType {
  CompanyDirector = 'COMPANY_DIRECTOR',
  CompanyDirector_20Pct = 'COMPANY_DIRECTOR_20PCT',
  RegularEmployment = 'REGULAR_EMPLOYMENT',
  SelfEmployed = 'SELF_EMPLOYED'
}

/** Error code to allow urther front end behaviour depending on error */
export enum ErrorCode {
  /** Appointment is no longer available */
  AppointmentNotAvailable = 'APPOINTMENT_NOT_AVAILABLE',
  /** Insufficient funds to achieve mutation */
  InsufficientFunds = 'INSUFFICIENT_FUNDS',
  /** Internal server error - something went wrong server side, likely to be unexpected */
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  /** Data is invalid in some way */
  InvalidData = 'INVALID_DATA',
  /** Object not found for a given ID, may have been deleted */
  ObjectNotFound = 'OBJECT_NOT_FOUND'
}

/** Standardised error response type for mutations */
export type ErrorType = {
  __typename: 'ErrorType';
  /** Error code to allow further front end behaviour depending on error */
  code: ErrorCode;
  /** Field errors, if present */
  fieldErrors?: Maybe<Scalars['FieldErrors']>;
  /** User facing message describing the error */
  message: Scalars['String'];
};

export type Factfind = {
  __typename: 'Factfind';
  /** Age of children */
  ageOfChildren?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Extra Essential monthly spending should be (essentialOutgoings - monthlyRent - monthlyBills) */
  extraMonthlyOutgoingEssentials?: Maybe<Scalars['Float']>;
  /** Does the user have children? */
  hasChildren?: Maybe<Scalars['Boolean']>;
  /** Whether the user has a mortgage on their home */
  hasMortgage?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  /** User's marital status */
  maritalStatus?: Maybe<MaritalStatus>;
  /** Essential monthly spending - should be >= than monthlyRent + monthlyUtilities */
  monthlyOutgoingEssentials?: Maybe<Scalars['Float']>;
  /** Non essential monthly spending */
  monthlyOutgoingNonEssentials?: Maybe<Scalars['Float']>;
  /** Monthly rent */
  monthlyRent?: Maybe<Scalars['Float']>;
  /** Monthly utilities cost */
  monthlyUtilities?: Maybe<Scalars['Float']>;
  /** Number of children */
  numberOfChildren?: Maybe<Scalars['Int']>;
  /** Number of other dependants */
  numberOfOtherDependants?: Maybe<Scalars['Int']>;
  /** Any other dependants? */
  otherDependants?: Maybe<Scalars['Boolean']>;
  /** Whether the user owns their own home */
  ownsHome?: Maybe<Scalars['Boolean']>;
  /** Additional factfind information relating to the user's partner */
  partnerSpecificFactfind?: Maybe<PersonFactfind>;
  /** Who the plan is for */
  planningFor?: Maybe<PlanScope>;
  /** Relationship to dependantss */
  relationshipToDependants?: Maybe<DependantRelationship>;
  /** Relationship to dependants */
  relationshipToOtherDependants?: Maybe<Array<Maybe<DependantRelationship>>>;
  /** Additional factfind information relating to the user */
  userSpecificFactfind?: Maybe<PersonFactfind>;
  /** Is the user vulnerable */
  vulnerable?: Maybe<Scalars['Boolean']>;
};

export type FactfindInput = {
  /** Age of children */
  ageOfChildren?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** Extra Essential monthly spending should be (essentialOutgoings - monthlyRent - monthlyBills) */
  extraMonthlyOutgoingEssentials?: InputMaybe<Scalars['Float']>;
  /** Does the user have children? */
  hasChildren?: InputMaybe<Scalars['Boolean']>;
  /** Whether the user has a mortgage on their home */
  hasMortgage?: InputMaybe<Scalars['Boolean']>;
  /** User's marital status */
  maritalStatus?: InputMaybe<MaritalStatus>;
  /** Essential monthly spending - should be >= than monthlyRent + monthlyUtilities */
  monthlyOutgoingEssentials?: InputMaybe<Scalars['Float']>;
  /** Non essential monthly spending */
  monthlyOutgoingNonEssentials?: InputMaybe<Scalars['Float']>;
  /** Monthly rent */
  monthlyRent?: InputMaybe<Scalars['Float']>;
  /** Monthly utilities cost */
  monthlyUtilities?: InputMaybe<Scalars['Float']>;
  /** Number of children */
  numberOfChildren?: InputMaybe<Scalars['Int']>;
  /** Number of other dependants */
  numberOfOtherDependants?: InputMaybe<Scalars['Int']>;
  /** Any other dependants? */
  otherDependants?: InputMaybe<Scalars['Boolean']>;
  /** Whether the user owns their own home */
  ownsHome?: InputMaybe<Scalars['Boolean']>;
  /** Additional factfind information relating to the user's partner */
  partnerSpecificFactfind?: InputMaybe<PersonFactfindInput>;
  /** Who the plan is for */
  planningFor?: InputMaybe<PlanScope>;
  /** Relationship to dependants */
  relationshipToDependants?: InputMaybe<DependantRelationship>;
  /** Relationship to dependants */
  relationshipToOtherDependants?: InputMaybe<Array<InputMaybe<DependantRelationship>>>;
  /** Additional factfind information relating to the user */
  userSpecificFactfind?: InputMaybe<PersonFactfindInput>;
  /** Is the user vulnerable */
  vulnerable?: InputMaybe<Scalars['Boolean']>;
};

/** Goal entered in fact find */
export type Goal = {
  __typename: 'Goal';
  goalData?: Maybe<Scalars['GoalData']>;
  goalType?: Maybe<GoalType>;
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
  owners: Array<Maybe<Person>>;
  priority?: Maybe<Scalars['Int']>;
  targetDate?: Maybe<Scalars['Date']>;
  userId: Scalars['ID'];
  valueToSave?: Maybe<Money>;
};

export type GoalInput = {
  goalData?: InputMaybe<Scalars['GoalData']>;
  goalType?: InputMaybe<GoalType>;
  name?: InputMaybe<Scalars['String']>;
  owners?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  targetDate?: InputMaybe<Scalars['Date']>;
  valueToSave?: InputMaybe<Scalars['MoneyInput']>;
};

export type GoalProgress = {
  __typename: 'GoalProgress';
  amountSaved: Money;
  goalType: GoalType;
  name: Scalars['String'];
  progress?: Maybe<Scalars['Percentage']>;
  targetAmount: Money;
};

export enum GoalType {
  BuyHome = 'BUY_HOME',
  ChildEducation = 'CHILD_EDUCATION',
  Custom = 'CUSTOM',
  Wedding = 'WEDDING'
}

export type GoalsInput = {
  goalData?: InputMaybe<Scalars['GoalData']>;
  goalId: Scalars['UUID'];
  goalType?: InputMaybe<GoalType>;
  name?: InputMaybe<Scalars['String']>;
  owners?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  targetDate?: InputMaybe<Scalars['Date']>;
  valueToSave?: InputMaybe<Scalars['MoneyInput']>;
};

export type HealthCheck = {
  __typename: 'HealthCheck';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export enum HealthCoverageStatus {
  Covered = 'COVERED',
  CoveredByIrishLife = 'COVERED_BY_IRISH_LIFE',
  Missing = 'MISSING'
}

export type HealthSummary = {
  __typename: 'HealthSummary';
  covered: HealthCoverageStatus;
  missing: Scalars['Boolean'];
};

/** Income entered in fact find */
export type Income = {
  __typename: 'Income';
  annualBonus?: Maybe<Money>;
  annualIncome?: Maybe<Money>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  owners: Array<Maybe<Person>>;
  userId: Scalars['ID'];
};

export type IncomeInput = {
  annualBonus?: InputMaybe<Scalars['MoneyInput']>;
  annualIncome?: InputMaybe<Scalars['MoneyInput']>;
  name?: InputMaybe<Scalars['String']>;
  owners?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

/** Interaction flag */
export type InteractionFlag = {
  __typename: 'InteractionFlag';
  id: Scalars['ID'];
  value?: Maybe<Scalars['Boolean']>;
};

export enum InvestingSummary {
  MoneyWorking = 'MONEY_WORKING',
  NeedsWork = 'NEEDS_WORK'
}

export type Link = {
  __typename: 'Link';
  text: Scalars['String'];
  url: Scalars['String'];
};

export enum MaritalStatus {
  CivilPartnership = 'CIVIL_PARTNERSHIP',
  Cohabitating = 'COHABITATING',
  Divorced = 'DIVORCED',
  Married = 'MARRIED',
  Separated = 'SEPARATED',
  Single = 'SINGLE',
  Widowed = 'WIDOWED'
}

/** List of message ids to be marked as read */
export type MarkAsReadInput = {
  messageIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  userId: Scalars['ID'];
};

export type MarkAsReadPayload = MutationPayload & {
  __typename: 'MarkAsReadPayload';
  error?: Maybe<ErrorType>;
  messageIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  success: Scalars['Boolean'];
};

export type MarkdownBlock = {
  __typename: 'MarkdownBlock';
  body?: Maybe<Scalars['String']>;
  collapsible?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};

/** A Single Message */
export type Message = {
  __typename: 'Message';
  /** Date message was created */
  dateReceived: Scalars['Date'];
  /** UUID of the message */
  id: Scalars['ID'];
  /** Url for message content */
  link?: Maybe<Scalars['String']>;
  /** Indicates whether a message has been read, false by default */
  read: Scalars['Boolean'];
  /** Message subtitle copy */
  subtitle?: Maybe<Scalars['String']>;
  /** Message title copy */
  title: Scalars['String'];
};

export type Money = {
  __typename: 'Money';
  display: Scalars['String'];
  float: Scalars['Float'];
  string: Scalars['String'];
};

export enum MortgageType {
  InterestOnly = 'INTEREST_ONLY',
  Repayment = 'REPAYMENT',
  Warehoused = 'WAREHOUSED'
}

export type Mutation = {
  __typename: 'Mutation';
  bookAppointment: BookAppointmentPayload;
  changeEmailVerification?: Maybe<ChangeEmailVerificationPayload>;
  /** New Survey Results */
  createNewSurvey: NewSurveyPayload;
  /** Decline Survey */
  declineSurvey: DeclineSurveyPayload;
  deleteAsset?: Maybe<DeleteAssetPayload>;
  deleteDebt?: Maybe<DeleteDebtPayload>;
  /** Deletes a goal */
  deleteGoal?: Maybe<DeleteGoalPayload>;
  deleteIncome?: Maybe<DeleteIncomePayload>;
  /** Deletes a policy */
  deletePolicy?: Maybe<DeletePolicyPayload>;
  /** Delete a reminder for a vulnerable user */
  deleteVulnerabilityReminder: DeleteVulnerabilityReminderPayload;
  /** Mark a message as read */
  markAsRead: MarkAsReadPayload;
  /** Kick off plan generation for a user */
  requestPlan: RequestPlanPayload;
  /** Set a reminder for a vulnerable user in 3 months */
  setVulnerabilityReminder: SetVulnerabilityReminderPayload;
  /** Sign up a new user (after firebase sign up) */
  signUp?: Maybe<SignUpPayload>;
  /** New tool subscription */
  subscribeToTool: NewToolPayload;
  /** Suggest new tool */
  suggestNewTool: NewToolPayload;
  /** Sync user data from MIL token */
  syncUser?: Maybe<SyncUserPayload>;
  updateAsset?: Maybe<UpdateAssetPayload>;
  updateAssets?: Maybe<UpdateAssetsPayload>;
  updateCollegeCosts: CollegeCost;
  updateConsent: UpdateConsentPayload;
  updateDebt?: Maybe<UpdateDebtPayload>;
  updateDebts?: Maybe<UpdateDebtsPayload>;
  /** Update email */
  updateEmail?: Maybe<UpdateEmailPayload>;
  /** Update factfind */
  updateFactfind: UpdateFactfindPayload;
  /** Updates a goal */
  updateGoal?: Maybe<UpdateGoalPayload>;
  /** Updates multiple goals */
  updateGoals?: Maybe<UpdateGoalsPayload>;
  updateIncome?: Maybe<UpdateIncomePayload>;
  /** Update the value of an interaction flag */
  updateInteractionFlag: UpdateInteractionFlagPayload;
  /** Update a single person (user / partner) */
  updatePerson: UpdatePersonPayload;
  /** Updates multiple policies */
  updatePolicies?: Maybe<UpdatePoliciesPayload>;
  /** Updates a policy */
  updatePolicy?: Maybe<UpdatePolicyPayload>;
  /** Update factfind */
  updateRisk: UpdateRiskPayload;
};


export type MutationBookAppointmentArgs = {
  input: BookAppointmentInput;
};


export type MutationChangeEmailVerificationArgs = {
  input?: InputMaybe<ChangeEmailVerificationInput>;
};


export type MutationCreateNewSurveyArgs = {
  input: NewSurveyInput;
};


export type MutationDeclineSurveyArgs = {
  input: DeclineSurveyInput;
};


export type MutationDeleteAssetArgs = {
  input?: InputMaybe<DeleteAssetInput>;
};


export type MutationDeleteDebtArgs = {
  input?: InputMaybe<DeleteDebtInput>;
};


export type MutationDeleteGoalArgs = {
  input?: InputMaybe<DeleteGoalInput>;
};


export type MutationDeleteIncomeArgs = {
  input?: InputMaybe<DeleteIncomeInput>;
};


export type MutationDeletePolicyArgs = {
  input?: InputMaybe<DeletePolicyInput>;
};


export type MutationDeleteVulnerabilityReminderArgs = {
  input: DeleteVulnerabilityReminderInput;
};


export type MutationMarkAsReadArgs = {
  input: MarkAsReadInput;
};


export type MutationRequestPlanArgs = {
  input: RequestPlanInput;
};


export type MutationSetVulnerabilityReminderArgs = {
  input: SetVulnerabilityReminderInput;
};


export type MutationSignUpArgs = {
  input?: InputMaybe<SignUpInput>;
};


export type MutationSubscribeToToolArgs = {
  input: NewToolInput;
};


export type MutationSuggestNewToolArgs = {
  input: NewToolInput;
};


export type MutationSyncUserArgs = {
  input?: InputMaybe<SyncUserInput>;
};


export type MutationUpdateAssetArgs = {
  input?: InputMaybe<UpdateAssetInput>;
};


export type MutationUpdateAssetsArgs = {
  input?: InputMaybe<UpdateAssetsInput>;
};


export type MutationUpdateCollegeCostsArgs = {
  input: CollegeCalculatorInput;
};


export type MutationUpdateConsentArgs = {
  input: UpdateConsentInput;
};


export type MutationUpdateDebtArgs = {
  input?: InputMaybe<UpdateDebtInput>;
};


export type MutationUpdateDebtsArgs = {
  input?: InputMaybe<UpdateDebtsInput>;
};


export type MutationUpdateEmailArgs = {
  input?: InputMaybe<UpdateEmailInput>;
};


export type MutationUpdateFactfindArgs = {
  input: UpdateFactfindInput;
};


export type MutationUpdateGoalArgs = {
  input?: InputMaybe<UpdateGoalInput>;
};


export type MutationUpdateGoalsArgs = {
  input?: InputMaybe<UpdateGoalsInput>;
};


export type MutationUpdateIncomeArgs = {
  input?: InputMaybe<UpdateIncomeInput>;
};


export type MutationUpdateInteractionFlagArgs = {
  input: UpdateInteractionFlagInput;
};


export type MutationUpdatePersonArgs = {
  input: UpdatePersonInput;
};


export type MutationUpdatePoliciesArgs = {
  input?: InputMaybe<UpdatePoliciesInput>;
};


export type MutationUpdatePolicyArgs = {
  input?: InputMaybe<UpdatePolicyInput>;
};


export type MutationUpdateRiskArgs = {
  input: UpdateRiskInput;
};

export type MutationPayload = {
  /** Error information, if present */
  error?: Maybe<ErrorType>;
  /** Whether the mutation was successful */
  success: Scalars['Boolean'];
};

export type NpsSurvey = {
  __typename: 'NPSSurvey';
  completed: Scalars['Boolean'];
  feedback?: Maybe<Scalars['String']>;
  followUp?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  score?: Maybe<Scalars['Int']>;
  userId: Scalars['ID'];
};

export type NewSurveyInput = {
  /** Suggested feedback */
  feedback?: InputMaybe<Scalars['String']>;
  /** Follow up with user */
  followUp?: InputMaybe<Scalars['Boolean']>;
  /** Likelyhood of reccomendation */
  score: Scalars['Int'];
  /** The user ID of the user */
  userId: Scalars['ID'];
};

export type NewSurveyPayload = MutationPayload & {
  __typename: 'NewSurveyPayload';
  error?: Maybe<ErrorType>;
  success: Scalars['Boolean'];
  survey?: Maybe<NpsSurvey>;
};

export type NewToolInput = {
  /** Tool subscribe to */
  tool?: InputMaybe<Scalars['String']>;
  /** The ID of the user */
  userId: Scalars['ID'];
};

export type NewToolPayload = MutationPayload & {
  __typename: 'NewToolPayload';
  error?: Maybe<ErrorType>;
  success: Scalars['Boolean'];
  tool?: Maybe<Scalars['String']>;
};

export enum PaymentFrequency {
  Halfyearly = 'HALFYEARLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Yearly = 'YEARLY'
}

export enum PensionType {
  Amrf = 'AMRF',
  Arf = 'ARF',
  AvcPension = 'AVC_PENSION',
  CompanyPension = 'COMPANY_PENSION',
  DefinedBenefit = 'DEFINED_BENEFIT',
  DefinedContribution = 'DEFINED_CONTRIBUTION',
  ExecutivePension = 'EXECUTIVE_PENSION',
  PerformancePrsa = 'PERFORMANCE_PRSA',
  PersonalPension = 'PERSONAL_PENSION',
  PersonalRetirementBond = 'PERSONAL_RETIREMENT_BOND',
  StandardPrsa = 'STANDARD_PRSA'
}

/** Readable fields for a single person */
export type Person = {
  __typename: 'Person';
  address?: Maybe<Address>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  joinDate?: Maybe<Scalars['Date']>;
  lastName?: Maybe<Scalars['String']>;
  legalFirstName?: Maybe<Scalars['String']>;
  legalLastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  relationship?: Maybe<Relationship>;
  sex?: Maybe<BiologicalSex>;
  title?: Maybe<Scalars['String']>;
};

export type PersonFactfind = {
  __typename: 'PersonFactfind';
  /** Base income before bonus of comission */
  baseAnnualIncomeGross?: Maybe<Scalars['Float']>;
  /** Date of birth */
  dateOfBirth?: Maybe<Scalars['Date']>;
  /** Is eligible for state pension */
  eligibleForStatePension?: Maybe<Scalars['Boolean']>;
  /** Whether the persons employer provides them with any income protection */
  employerIncomeProtection?: Maybe<Scalars['Boolean']>;
  /** Employment status */
  employmentStatus?: Maybe<EmploymentStatus>;
  /** Expected annual bonus / comission */
  expectedBonusAmount?: Maybe<Scalars['Float']>;
  /** Expected annual income from other employment */
  expectedOtherIncomeAmount?: Maybe<Scalars['Float']>;
  /** First name */
  firstName?: Maybe<Scalars['String']>;
  /** Has any bonus / commission */
  hasBonus?: Maybe<Scalars['Boolean']>;
  /** Whether person is in good health */
  hasGoodHealth?: Maybe<Scalars['Boolean']>;
  /** Any other sources of income? */
  hasOtherIncome?: Maybe<Scalars['Boolean']>;
  /** Last name */
  lastName?: Maybe<Scalars['String']>;
  /** Primary income type */
  mainEmploymentType?: Maybe<EmploymentType>;
  /** Marginal tax rate in decimal format, N/A if null  */
  marginalTaxRate?: Maybe<Scalars['Float']>;
  /** Monthly income net of tax */
  monthlyIncomeNet?: Maybe<Scalars['Float']>;
  /** Expected outgoings in retirement */
  monthlyRetirementOutgoingExpected?: Maybe<Scalars['Float']>;
  /** How many months the person could financially survive for following an accident or injury, N/A if null, nonspecifically longer than 12 months if value above 12' */
  monthsOfFinancialSafety?: Maybe<Scalars['Int']>;
  /** Occupation */
  occupation?: Maybe<Scalars['String']>;
  /** Estimated retirement age */
  retirementAgeEstimate?: Maybe<Scalars['Int']>;
  /** Smoking status */
  smokingStatus?: Maybe<SmokingStatus>;
};

export type PersonFactfindInput = {
  /** Base income before bonus of comission */
  baseAnnualIncomeGross?: InputMaybe<Scalars['Float']>;
  /** Date of birth */
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  /** Is eligible for state pension */
  eligibleForStatePension?: InputMaybe<Scalars['Boolean']>;
  /** Employment status */
  employmentStatus?: InputMaybe<EmploymentStatus>;
  /** Expected annual bonus / comission */
  expectedBonusAmount?: InputMaybe<Scalars['Float']>;
  /** Expected annual income from other employment */
  expectedOtherIncomeAmount?: InputMaybe<Scalars['Float']>;
  /** First name */
  firstName?: InputMaybe<Scalars['String']>;
  /** Has any bonus / commission */
  hasBonus?: InputMaybe<Scalars['Boolean']>;
  /** Whether person is in good health */
  hasGoodHealth?: InputMaybe<Scalars['Boolean']>;
  /** Any other sources of income? */
  hasOtherIncome?: InputMaybe<Scalars['Boolean']>;
  /** Last name */
  lastName?: InputMaybe<Scalars['String']>;
  /** Primary income type */
  mainEmploymentType?: InputMaybe<EmploymentType>;
  /** Marginal tax rate in decimal format, leave unspecified if N/A  */
  marginalTaxRate?: InputMaybe<Scalars['Float']>;
  /** Monthly income net of tax */
  monthlyIncomeNet?: InputMaybe<Scalars['Float']>;
  /** Expected outgoings in retirement */
  monthlyRetirementOutgoingExpected?: InputMaybe<Scalars['Float']>;
  /** How many months the person could financially survive for following an accident or injury, leave unspecified if N/A, input a value >12 if answer is 'Longer' */
  monthsOfFinancialSafety?: InputMaybe<Scalars['Int']>;
  /** Occupation */
  occupation?: InputMaybe<Scalars['String']>;
  /** Estimated retirement age */
  retirementAgeEstimate?: InputMaybe<Scalars['Int']>;
  /** Smoking status */
  smokingStatus?: InputMaybe<SmokingStatus>;
};

/** Person fields that can be updated */
export type PersonInput = {
  address?: InputMaybe<AddressInput>;
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  legalFirstName?: InputMaybe<Scalars['String']>;
  legalLastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  relationship?: InputMaybe<Relationship>;
  sex?: InputMaybe<BiologicalSex>;
  title?: InputMaybe<Scalars['String']>;
};

/** A user's financial plan */
export type Plan = {
  __typename: 'Plan';
  authorisationStatus?: Maybe<AuthorisationStatus>;
  factfindErrors?: Maybe<Scalars['FactfindErrors']>;
  /** UUID for the plan */
  id: Scalars['ID'];
  ilCrmData?: Maybe<Scalars['RawJSONForDebugging']>;
  /** When the plan was last regenerated */
  lastUpdated?: Maybe<Scalars['Date']>;
  monthlyAllocationAmount?: Maybe<Money>;
  recommendationGroups?: Maybe<Array<RecommendationGroup>>;
  /** Suitability report related to the plan, if available */
  suitabilityReportLink?: Maybe<Scalars['String']>;
  triageReason?: Maybe<Scalars['String']>;
};

export enum PlanScope {
  User = 'USER',
  UserAndKids = 'USER_AND_KIDS',
  UserAndPartner = 'USER_AND_PARTNER',
  UserAndPartnerAndKids = 'USER_AND_PARTNER_AND_KIDS'
}

export type PoliciesInput = {
  /** Affects: All but health insurance */
  amountOfCover?: InputMaybe<Scalars['MoneyInput']>;
  /** Affects: Life Cover */
  coverType?: InputMaybe<CoverType>;
  /** Affects: Health insurance */
  isIrishLifePolicy?: InputMaybe<Scalars['Boolean']>;
  isOnePlan?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  owners?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Affects: All but health insurance */
  paymentFrequency?: InputMaybe<PaymentFrequency>;
  policyId?: InputMaybe<Scalars['UUID']>;
  /** Affects: All but health insurance */
  premium?: InputMaybe<Scalars['MoneyInput']>;
  productType: PolicyType;
  provider?: InputMaybe<Scalars['ID']>;
  providerName?: InputMaybe<Scalars['String']>;
  /** Affects: Health insurance */
  renewalDate?: InputMaybe<Scalars['Date']>;
  throughCurrentEmployer?: InputMaybe<Scalars['Boolean']>;
  /** Affects: All but health insurance */
  yearsRemaining?: InputMaybe<Scalars['Int']>;
};

/** Policy entered in fact find */
export type Policy = {
  __typename: 'Policy';
  /** Affects: All but health insurance */
  amountOfCover?: Maybe<Money>;
  /** Affects: Life Cover */
  coverType?: Maybe<CoverType>;
  id: Scalars['UUID'];
  isComplete: Scalars['Boolean'];
  /** Affects: Health insurance */
  isIrishLifePolicy?: Maybe<Scalars['Boolean']>;
  isOnePlan?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  owners?: Maybe<Array<Maybe<Person>>>;
  /** Affects: All but health insurance */
  paymentFrequency?: Maybe<PaymentFrequency>;
  /** Affects: All but health insurance */
  premium?: Maybe<Money>;
  productType: PolicyType;
  provider?: Maybe<Provider>;
  /** Affects: Health insurance */
  renewalDate?: Maybe<Scalars['Date']>;
  throughCurrentEmployer?: Maybe<Scalars['Boolean']>;
  userId: Scalars['ID'];
  /** Affects: All but health insurance */
  yearsRemaining?: Maybe<Scalars['Int']>;
};

export type PolicyInput = {
  /** Affects: All but health insurance */
  amountOfCover?: InputMaybe<Scalars['MoneyInput']>;
  /** Affects: Life Cover */
  coverType?: InputMaybe<CoverType>;
  /** Affects: Health insurance */
  isIrishLifePolicy?: InputMaybe<Scalars['Boolean']>;
  isOnePlan?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  owners?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Affects: All but health insurance */
  paymentFrequency?: InputMaybe<PaymentFrequency>;
  /** Affects: All but health insurance */
  premium?: InputMaybe<Scalars['MoneyInput']>;
  productType: PolicyType;
  provider?: InputMaybe<Scalars['ID']>;
  providerName?: InputMaybe<Scalars['String']>;
  /** Affects: Health insurance */
  renewalDate?: InputMaybe<Scalars['Date']>;
  throughCurrentEmployer?: InputMaybe<Scalars['Boolean']>;
  /** Affects: All but health insurance */
  yearsRemaining?: InputMaybe<Scalars['Int']>;
};

export enum PolicyType {
  HealthInsurance = 'HEALTH_INSURANCE',
  IncomeProtection = 'INCOME_PROTECTION',
  LifeCover = 'LIFE_COVER',
  SpecifiedIllnessCover = 'SPECIFIED_ILLNESS_COVER'
}

export type ProductSummary = {
  __typename: 'ProductSummary';
  missing?: Maybe<Scalars['Boolean']>;
  postProductAmount?: Maybe<Scalars['String']>;
  preProductAmount?: Maybe<Scalars['String']>;
  productAmount?: Maybe<Scalars['String']>;
};

export enum PropertyType {
  CommercialProperty = 'COMMERCIAL_PROPERTY',
  MainResidence = 'MAIN_RESIDENCE',
  ResidentialInvestmentProperty = 'RESIDENTIAL_INVESTMENT_PROPERTY'
}

export enum ProtectionProvision {
  ComprehensivePlan = 'COMPREHENSIVE_PLAN',
  Covered = 'COVERED',
  NotFullyCovered = 'NOT_FULLY_COVERED',
  NoCover = 'NO_COVER'
}

export type ProtectionScenario = {
  __typename: 'ProtectionScenario';
  covered: CoverForScenario;
  description: Scalars['String'];
  recommended: Money;
  recommendedForPartner?: Maybe<Money>;
  recommendedForUser: Money;
  subtitle: Scalars['String'];
  title: Scalars['String'];
};

export type ProtectionSummary = {
  __typename: 'ProtectionSummary';
  currentCover: ProtectionProvision;
  idealCover: ProtectionProvision;
  missing: Scalars['Boolean'];
  missingPolicies?: Maybe<Scalars['String']>;
  scenarios?: Maybe<Array<Maybe<ProtectionScenario>>>;
};

export type Provider = {
  __typename: 'Provider';
  address?: Maybe<Address>;
  bannerColor?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  providerTypes: Array<Maybe<ProviderType>>;
};

export enum ProviderType {
  AssetProvider = 'ASSET_PROVIDER',
  DebtProvider = 'DEBT_PROVIDER',
  PolicyProvider = 'POLICY_PROVIDER'
}

/** Queries to Multiply's user data service */
export type Query = {
  __typename: 'Query';
  /** Addresses for a given postal code */
  addressesAtPostalCode?: Maybe<Array<Maybe<Address>>>;
  /** Asset entered in fact find */
  asset?: Maybe<Asset>;
  assets?: Maybe<Array<Maybe<Asset>>>;
  /** Days that have available appointments */
  availableAppointmentDays: Array<AvailableAppointmentDay>;
  /** Appointments available on a given day */
  availableAppointments: Array<AvailableAppointment>;
  collegeCost?: Maybe<CollegeCost>;
  collegeCosts?: Maybe<Array<Maybe<CollegeCost>>>;
  consent?: Maybe<Consent>;
  /** Debt entered in fact find */
  debt?: Maybe<Debt>;
  debts?: Maybe<Array<Maybe<Debt>>>;
  /** Get high level factfind for a user */
  factfind: Factfind;
  /** Goal entered in fact find */
  goal?: Maybe<Goal>;
  /** All goals for a given user */
  goals?: Maybe<Array<Maybe<Goal>>>;
  /** Information about a given version of the app */
  health: HealthCheck;
  /** Income entered in fact find */
  income?: Maybe<Income>;
  /** Front end user interaction flags, for showing banners etc. */
  interactionFlag: InteractionFlag;
  /** List a users messages */
  messages: Array<Message>;
  /** Next available appointment */
  nextAvailableAppointment?: Maybe<AvailableAppointment>;
  occupations: Array<Maybe<Scalars['String']>>;
  /** Get a single person (user / partner) */
  person?: Maybe<Person>;
  /** Get a list of persons associated with the user */
  persons: Array<Person>;
  /** Financial plan for the user */
  plan: Plan;
  /** Wealth projection for the user */
  planGraph: WealthProjection;
  /** All policies for a given user */
  policies?: Maybe<Array<Maybe<Policy>>>;
  /** Policy entered in fact find */
  policy?: Maybe<Policy>;
  /** Records of providers for a given type, returns all providers if no provider type is specified */
  providers?: Maybe<Array<Maybe<Provider>>>;
  /** Get risk profile information for a user */
  risk: Risk;
  /** Appointments for a given user */
  scheduledAppointments: Array<Appointment>;
  /** Boolean to show survey */
  showSurvey?: Maybe<Scalars['Boolean']>;
  /** All tool suggestions for a given user */
  suggestedTools?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Survey results for a given survey from a given user */
  survey?: Maybe<NpsSurvey>;
  /** All surveys for a given user */
  surveys?: Maybe<Array<Maybe<NpsSurvey>>>;
  /** All tools subscribed for a given user */
  tools?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Number of unread messages for the user */
  unreadMessages?: Maybe<Scalars['Int']>;
  /** Multiply / Irish Life Customer */
  user?: Maybe<User>;
  vulnerabilityReminder?: Maybe<Scalars['Date']>;
};


/** Queries to Multiply's user data service */
export type QueryAddressesAtPostalCodeArgs = {
  postalCode: Scalars['String'];
};


/** Queries to Multiply's user data service */
export type QueryAssetArgs = {
  assetId: Scalars['UUID'];
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QueryAssetsArgs = {
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QueryAvailableAppointmentDaysArgs = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
  userId?: InputMaybe<Scalars['ID']>;
};


/** Queries to Multiply's user data service */
export type QueryAvailableAppointmentsArgs = {
  date: Scalars['Date'];
  userId?: InputMaybe<Scalars['ID']>;
};


/** Queries to Multiply's user data service */
export type QueryCollegeCostArgs = {
  collegeCostId?: InputMaybe<Scalars['ID']>;
  collegeCostUuid?: InputMaybe<Scalars['UUID']>;
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QueryCollegeCostsArgs = {
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QueryConsentArgs = {
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QueryDebtArgs = {
  debtId: Scalars['UUID'];
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QueryDebtsArgs = {
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QueryFactfindArgs = {
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QueryGoalArgs = {
  goalId: Scalars['UUID'];
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QueryGoalsArgs = {
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QueryHealthArgs = {
  message?: InputMaybe<Scalars['String']>;
};


/** Queries to Multiply's user data service */
export type QueryIncomeArgs = {
  incomeId: Scalars['ID'];
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QueryInteractionFlagArgs = {
  flagKey: Scalars['ID'];
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QueryMessagesArgs = {
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QueryNextAvailableAppointmentArgs = {
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QueryPersonArgs = {
  personId?: InputMaybe<Scalars['ID']>;
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QueryPersonsArgs = {
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QueryPlanArgs = {
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QueryPlanGraphArgs = {
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QueryPoliciesArgs = {
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QueryPolicyArgs = {
  policyId: Scalars['UUID'];
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QueryProvidersArgs = {
  providerType?: InputMaybe<ProviderType>;
};


/** Queries to Multiply's user data service */
export type QueryRiskArgs = {
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QueryScheduledAppointmentsArgs = {
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QueryShowSurveyArgs = {
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QuerySuggestedToolsArgs = {
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QuerySurveyArgs = {
  id: Scalars['ID'];
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QuerySurveysArgs = {
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QueryToolsArgs = {
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QueryUnreadMessagesArgs = {
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QueryUserArgs = {
  userId: Scalars['ID'];
};


/** Queries to Multiply's user data service */
export type QueryVulnerabilityReminderArgs = {
  userId: Scalars['ID'];
};

export type Recommendation = {
  __typename: 'Recommendation';
  ctas: Array<RecommendationCta>;
  explanation: Array<ContentBlock>;
  id: Scalars['ID'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
  warnings: Array<Scalars['String']>;
};

export type RecommendationCta = {
  __typename: 'RecommendationCTA';
  text: Scalars['String'];
  url: Scalars['String'];
};

export type RecommendationGroup = {
  __typename: 'RecommendationGroup';
  factfindSummary: Scalars['String'];
  id: Scalars['ID'];
  recommendationType: RecommendationType;
  recommendations?: Maybe<Array<Recommendation>>;
  relatedProducts?: Maybe<ProductSummary>;
  riskProfileQuestionnaireRequired: Scalars['Boolean'];
  subtitle: Scalars['String'];
  summary?: Maybe<RecommendationGroupSummary>;
  thingsToDo?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
};

export type RecommendationGroupSummary = DebtSummary | EmergencyFundSummary | HealthSummary | ProtectionSummary | RetirementSummary | SavingsInvestingSummary;

export enum RecommendationType {
  Debt = 'DEBT',
  EmergencyFund = 'EMERGENCY_FUND',
  Health = 'HEALTH',
  Protection = 'PROTECTION',
  Retirement = 'RETIREMENT',
  SavingsInvesting = 'SAVINGS_INVESTING'
}

/** Specifies whether the person is the user or their partner */
export enum Relationship {
  Partner = 'PARTNER',
  User = 'USER'
}

export type RequestPlanInput = {
  /** The id of the user */
  userId: Scalars['ID'];
};

export type RequestPlanPayload = MutationPayload & {
  __typename: 'RequestPlanPayload';
  error?: Maybe<ErrorType>;
  success: Scalars['Boolean'];
};

export type RetirementSummary = {
  __typename: 'RetirementSummary';
  breakdown: Array<BreakdownSlice>;
  currentProvision: Money;
  realisticProvision: Money;
  targetProvision: Money;
};

export type Risk = {
  __typename: 'Risk';
  calculatedProfile?: Maybe<RiskProfile>;
  id: Scalars['ID'];
  questionnaire?: Maybe<RiskQuestionnaire>;
  selectedProfile?: Maybe<RiskProfile>;
};

export type RiskInput = {
  questionnaire?: InputMaybe<RiskQuestionnaireInput>;
  selectedProfile?: InputMaybe<RiskProfileId>;
};

export type RiskProfile = {
  __typename: 'RiskProfile';
  description: Scalars['String'];
  id: RiskProfileId;
  name: Scalars['String'];
};

export enum RiskProfileId {
  Adventurous = 'ADVENTUROUS',
  Balanced = 'BALANCED',
  Careful = 'CAREFUL',
  Conservative = 'CONSERVATIVE',
  Experienced = 'EXPERIENCED',
  SafetyFirst = 'SAFETY_FIRST',
  VeryAdventurous = 'VERY_ADVENTUROUS'
}

export type RiskQuestionnaire = {
  __typename: 'RiskQuestionnaire';
  bestPossibleReturnQ2?: Maybe<Scalars['String']>;
  choiceTwoInvestmentProductsQ12?: Maybe<Scalars['String']>;
  describeThinkAboutPossibleLossesGainsQ14?: Maybe<Scalars['String']>;
  exploringInvestmentOpportunitiesQ1?: Maybe<Scalars['String']>;
  friendDescribeAttitudeRisksQ10?: Maybe<Scalars['String']>;
  highGrowthAcceptLossesQ8?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  investmentGoesDownReactionQ7?: Maybe<Scalars['String']>;
  investmentSafeLowerReturnsQ15?: Maybe<Scalars['String']>;
  investmentsRiskAnxiousQ6?: Maybe<Scalars['String']>;
  largeGainsRiskLargeLossesQ11?: Maybe<Scalars['String']>;
  possibleHighReturnsChanceQ9?: Maybe<Scalars['String']>;
  preferInvestmentSafeQ5?: Maybe<Scalars['String']>;
  preferSmallCertainGainsQ13?: Maybe<Scalars['String']>;
  riskPastFinancialDecisionsQ4?: Maybe<Scalars['String']>;
  typicalAttitudeFinancialDecisionsQ3?: Maybe<Scalars['String']>;
};

export type RiskQuestionnaireInput = {
  bestPossibleReturnQ2?: InputMaybe<Scalars['String']>;
  choiceTwoInvestmentProductsQ12?: InputMaybe<Scalars['String']>;
  describeThinkAboutPossibleLossesGainsQ14?: InputMaybe<Scalars['String']>;
  exploringInvestmentOpportunitiesQ1?: InputMaybe<Scalars['String']>;
  friendDescribeAttitudeRisksQ10?: InputMaybe<Scalars['String']>;
  highGrowthAcceptLossesQ8?: InputMaybe<Scalars['String']>;
  investmentGoesDownReactionQ7?: InputMaybe<Scalars['String']>;
  investmentSafeLowerReturnsQ15?: InputMaybe<Scalars['String']>;
  investmentsRiskAnxiousQ6?: InputMaybe<Scalars['String']>;
  largeGainsRiskLargeLossesQ11?: InputMaybe<Scalars['String']>;
  possibleHighReturnsChanceQ9?: InputMaybe<Scalars['String']>;
  preferInvestmentSafeQ5?: InputMaybe<Scalars['String']>;
  preferSmallCertainGainsQ13?: InputMaybe<Scalars['String']>;
  riskPastFinancialDecisionsQ4?: InputMaybe<Scalars['String']>;
  typicalAttitudeFinancialDecisionsQ3?: InputMaybe<Scalars['String']>;
};

export type SavingsInvestingSummary = {
  __typename: 'SavingsInvestingSummary';
  breakdown: Array<BreakdownSlice>;
  emergencyFundProgress: EmergencyFundProgress;
  goalProgress: Array<Maybe<GoalProgress>>;
  investingSummary: InvestingSummary;
  savingsSummary: SavingsSummary;
};

export enum SavingsSummary {
  NeedsWork = 'NEEDS_WORK',
  OnTrack = 'ON_TRACK'
}

export type SetVulnerabilityReminderInput = {
  userId: Scalars['ID'];
};

export type SetVulnerabilityReminderPayload = MutationPayload & {
  __typename: 'SetVulnerabilityReminderPayload';
  error?: Maybe<ErrorType>;
  /** Expected date of the email reminder */
  reminderDate?: Maybe<Scalars['Date']>;
  success: Scalars['Boolean'];
};

export type SignUpInput = {
  /** The advisor ID */
  advisorId?: InputMaybe<Scalars['String']>;
  /** The client ID */
  clientId?: InputMaybe<Scalars['String']>;
  /** The UUID of the user's device */
  deviceId?: InputMaybe<Scalars['String']>;
  /** The user's email */
  email?: InputMaybe<Scalars['String']>;
  /** The user's name */
  name?: InputMaybe<Scalars['String']>;
  /** The user referral */
  referralSource?: InputMaybe<Scalars['String']>;
  /** The team name */
  teamName?: InputMaybe<Scalars['String']>;
  /** The id of the user */
  userId: Scalars['ID'];
};

export type SignUpPayload = MutationPayload & {
  __typename: 'SignUpPayload';
  error?: Maybe<ErrorType>;
  success: Scalars['Boolean'];
};

export enum SmokingStatus {
  Nonsmoker = 'NONSMOKER',
  OccasionalSmoker = 'OCCASIONAL_SMOKER',
  Smoker = 'SMOKER',
  UsedNicotineReplacements = 'USED_NICOTINE_REPLACEMENTS'
}

export type SyncUserInput = {
  /** The id of the user */
  userId: Scalars['ID'];
};

export type SyncUserPayload = MutationPayload & {
  __typename: 'SyncUserPayload';
  error?: Maybe<ErrorType>;
  success: Scalars['Boolean'];
};

export type UpdateAssetInput = {
  asset: AssetInput;
  assetId?: InputMaybe<Scalars['UUID']>;
  userId: Scalars['ID'];
};

export type UpdateAssetPayload = MutationPayload & {
  __typename: 'UpdateAssetPayload';
  asset?: Maybe<Asset>;
  error?: Maybe<ErrorType>;
  success: Scalars['Boolean'];
};

export type UpdateAssetsInput = {
  assets: Array<AssetsInput>;
  userId: Scalars['ID'];
};

export type UpdateAssetsPayload = MutationPayload & {
  __typename: 'UpdateAssetsPayload';
  assets?: Maybe<Array<Maybe<Asset>>>;
  error?: Maybe<ErrorType>;
  success: Scalars['Boolean'];
};

export type UpdateConsentInput = {
  consent: ConsentInput;
  userId: Scalars['ID'];
};

export type UpdateConsentPayload = MutationPayload & {
  __typename: 'UpdateConsentPayload';
  consent?: Maybe<Consent>;
  error?: Maybe<ErrorType>;
  success: Scalars['Boolean'];
};

export type UpdateDebtInput = {
  debt: DebtInput;
  debtId?: InputMaybe<Scalars['UUID']>;
  userId: Scalars['ID'];
};

export type UpdateDebtPayload = MutationPayload & {
  __typename: 'UpdateDebtPayload';
  debt?: Maybe<Debt>;
  error?: Maybe<ErrorType>;
  success: Scalars['Boolean'];
};

export type UpdateDebtsInput = {
  debts: Array<DebtsInput>;
  userId: Scalars['ID'];
};

export type UpdateDebtsPayload = MutationPayload & {
  __typename: 'UpdateDebtsPayload';
  debts?: Maybe<Array<Maybe<Debt>>>;
  error?: Maybe<ErrorType>;
  success: Scalars['Boolean'];
};

export type UpdateEmailInput = {
  code: Scalars['String'];
  /** The new email */
  email: Scalars['String'];
  /** The ID of the user */
  userId: Scalars['ID'];
};

export type UpdateEmailPayload = MutationPayload & {
  __typename: 'UpdateEmailPayload';
  error?: Maybe<ErrorType>;
  success: Scalars['Boolean'];
};

export type UpdateFactfindInput = {
  /** The updated factfind fields */
  factfind: FactfindInput;
  /** The ID of the user */
  userId: Scalars['ID'];
};

export type UpdateFactfindPayload = MutationPayload & {
  __typename: 'UpdateFactfindPayload';
  error?: Maybe<ErrorType>;
  /** Factfind details */
  factfind?: Maybe<Factfind>;
  success: Scalars['Boolean'];
};

export type UpdateGoalInput = {
  collegeCostId?: InputMaybe<Scalars['ID']>;
  goal: GoalInput;
  /** When no goal ID is passed, the mutation creates a new goal */
  goalId?: InputMaybe<Scalars['UUID']>;
  userId: Scalars['ID'];
};

export type UpdateGoalPayload = MutationPayload & {
  __typename: 'UpdateGoalPayload';
  error?: Maybe<ErrorType>;
  goal?: Maybe<Goal>;
  success: Scalars['Boolean'];
};

export type UpdateGoalsInput = {
  goals: Array<GoalsInput>;
  userId: Scalars['ID'];
};

export type UpdateGoalsPayload = MutationPayload & {
  __typename: 'UpdateGoalsPayload';
  error?: Maybe<ErrorType>;
  goals?: Maybe<Array<Maybe<Goal>>>;
  success: Scalars['Boolean'];
};

export type UpdateIncomeInput = {
  income: IncomeInput;
  incomeId?: InputMaybe<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type UpdateIncomePayload = MutationPayload & {
  __typename: 'UpdateIncomePayload';
  error?: Maybe<ErrorType>;
  income?: Maybe<Income>;
  success: Scalars['Boolean'];
};

export type UpdateInteractionFlagInput = {
  /** Interaction flag id */
  flagKey: Scalars['ID'];
  /** The user id */
  userId: Scalars['ID'];
  /** Flag value */
  value: Scalars['Boolean'];
};

export type UpdateInteractionFlagPayload = MutationPayload & {
  __typename: 'UpdateInteractionFlagPayload';
  error?: Maybe<ErrorType>;
  interactionFlag?: Maybe<InteractionFlag>;
  success: Scalars['Boolean'];
};

/** Input to update a single person. */
export type UpdatePersonInput = {
  person: PersonInput;
  personId?: InputMaybe<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type UpdatePersonPayload = MutationPayload & {
  __typename: 'UpdatePersonPayload';
  error?: Maybe<ErrorType>;
  person?: Maybe<Person>;
  success: Scalars['Boolean'];
};

export type UpdatePoliciesInput = {
  policies: Array<PoliciesInput>;
  userId: Scalars['ID'];
};

export type UpdatePoliciesPayload = MutationPayload & {
  __typename: 'UpdatePoliciesPayload';
  error?: Maybe<ErrorType>;
  policies?: Maybe<Array<Maybe<Policy>>>;
  success: Scalars['Boolean'];
};

export type UpdatePolicyInput = {
  policy: PolicyInput;
  /** When no policy ID is passed, the mutation creates a new policy */
  policyId?: InputMaybe<Scalars['UUID']>;
  userId: Scalars['ID'];
};

export type UpdatePolicyPayload = MutationPayload & {
  __typename: 'UpdatePolicyPayload';
  error?: Maybe<ErrorType>;
  policy?: Maybe<Policy>;
  success: Scalars['Boolean'];
};

export type UpdateRiskInput = {
  /** The updated factfind fields */
  risk: RiskInput;
  /** The ID of the user */
  userId: Scalars['ID'];
};

export type UpdateRiskPayload = MutationPayload & {
  __typename: 'UpdateRiskPayload';
  error?: Maybe<ErrorType>;
  /** Risk details */
  risk?: Maybe<Risk>;
  success: Scalars['Boolean'];
};

export type UrlBlock = {
  __typename: 'UrlBlock';
  links?: Maybe<Array<Maybe<Link>>>;
  title?: Maybe<Scalars['String']>;
};

/** Multiply / Irish Life Customer */
export type User = {
  __typename: 'User';
  /** Current email */
  email?: Maybe<Scalars['String']>;
  /** External auth ID */
  id: Scalars['ID'];
  /** User's name */
  name?: Maybe<Scalars['String']>;
  /** The user referral */
  referralSource?: Maybe<Scalars['String']>;
};

export enum ValuationMethod {
  EstimatedCurrentValue = 'ESTIMATED_CURRENT_VALUE',
  EstimatedValueAtRetirement = 'ESTIMATED_VALUE_AT_RETIREMENT',
  MonthlyIncomeAtRetirement = 'MONTHLY_INCOME_AT_RETIREMENT'
}

export type WealthAtDate = {
  __typename: 'WealthAtDate';
  amount: Money;
  date: Scalars['Date'];
  goal?: Maybe<Array<Maybe<GoalType>>>;
};

export type WealthProjection = {
  __typename: 'WealthProjection';
  currentWealth: Money;
  finalWealth: Money;
  wealthProjection: Array<Maybe<WealthAtDate>>;
};

export enum WhatToIncludeType {
  Accommodation = 'ACCOMMODATION',
  Fees = 'FEES',
  OtherExpenses = 'OTHER_EXPENSES'
}

export type CalculateCollegeCostsMutationVariables = Exact<{
  input: CollegeCalculatorInput;
}>;


export type CalculateCollegeCostsMutation = { __typename: 'Mutation', updateCollegeCosts: { __typename: 'CollegeCost', accommodationType?: AccommodationType | null, ageOfChild?: number | null, courseDuration?: number | null, eligibleForFreeTuitionFees?: boolean | null, goalId?: string | null, id?: string | null, targetDate?: any | null, userId?: string | null, whatToInclude?: Array<WhatToIncludeType | null> | null, accommodationCost?: { __typename: 'Money', float: number, string: string, display: string } | null, annualCost?: { __typename: 'Money', float: number, string: string, display: string } | null, fees?: { __typename: 'Money', float: number, string: string, display: string } | null, monthlySavingsRequired?: { __typename: 'Money', float: number, string: string, display: string } | null, otherExpenses?: { __typename: 'Money', float: number, string: string, display: string } | null, total?: { __typename: 'Money', float: number, string: string, display: string } | null, totalAdjustedForInflation?: { __typename: 'Money', float: number, string: string, display: string } | null } };

export type GetAllCollegeCostsQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type GetAllCollegeCostsQuery = { __typename: 'Query', collegeCosts?: Array<{ __typename: 'CollegeCost', accommodationType?: AccommodationType | null, ageOfChild?: number | null, courseDuration?: number | null, eligibleForFreeTuitionFees?: boolean | null, goalId?: string | null, id?: string | null, targetDate?: any | null, userId?: string | null, whatToInclude?: Array<WhatToIncludeType | null> | null, accommodationCost?: { __typename: 'Money', float: number, string: string, display: string } | null, annualCost?: { __typename: 'Money', float: number, string: string, display: string } | null, fees?: { __typename: 'Money', float: number, string: string, display: string } | null, monthlySavingsRequired?: { __typename: 'Money', float: number, string: string, display: string } | null, otherExpenses?: { __typename: 'Money', float: number, string: string, display: string } | null, total?: { __typename: 'Money', float: number, string: string, display: string } | null, totalAdjustedForInflation?: { __typename: 'Money', float: number, string: string, display: string } | null } | null> | null };

export type UpdateGoalMutationVariables = Exact<{
  input: UpdateGoalInput;
}>;


export type UpdateGoalMutation = { __typename: 'Mutation', updateGoal?: { __typename: 'UpdateGoalPayload', success: boolean, error?: { __typename: 'ErrorType', code: ErrorCode, message: string, fieldErrors?: any | null } | null, goal?: { __typename: 'Goal', id: any, userId: string, name?: string | null, targetDate?: any | null, goalType?: GoalType | null, priority?: number | null, owners: Array<{ __typename: 'Person', id: string, firstName?: string | null } | null>, valueToSave?: { __typename: 'Money', float: number, string: string, display: string } | null } | null } | null };


export const CalculateCollegeCostsDocument = gql`
    mutation CalculateCollegeCosts($input: CollegeCalculatorInput!) {
  updateCollegeCosts(input: $input) {
    __typename
    accommodationCost {
      float
      string
      display
    }
    accommodationType
    ageOfChild
    annualCost {
      float
      string
      display
    }
    courseDuration
    eligibleForFreeTuitionFees
    fees {
      float
      string
      display
    }
    goalId
    id
    monthlySavingsRequired {
      float
      string
      display
    }
    otherExpenses {
      float
      string
      display
    }
    targetDate
    total {
      float
      string
      display
    }
    totalAdjustedForInflation {
      float
      string
      display
    }
    userId
    whatToInclude
  }
}
    `;

export function useCalculateCollegeCostsMutation() {
  return Urql.useMutation<CalculateCollegeCostsMutation, CalculateCollegeCostsMutationVariables>(CalculateCollegeCostsDocument);
};
export const GetAllCollegeCostsDocument = gql`
    query GetAllCollegeCosts($userId: ID!) {
  collegeCosts(userId: $userId) {
    __typename
    accommodationCost {
      float
      string
      display
    }
    accommodationType
    ageOfChild
    annualCost {
      float
      string
      display
    }
    courseDuration
    eligibleForFreeTuitionFees
    fees {
      float
      string
      display
    }
    goalId
    id
    monthlySavingsRequired {
      float
      string
      display
    }
    otherExpenses {
      float
      string
      display
    }
    targetDate
    total {
      float
      string
      display
    }
    totalAdjustedForInflation {
      float
      string
      display
    }
    userId
    whatToInclude
  }
}
    `;

export function useGetAllCollegeCostsQuery(options: Omit<Urql.UseQueryArgs<GetAllCollegeCostsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllCollegeCostsQuery>({ query: GetAllCollegeCostsDocument, ...options });
};
export const UpdateGoalDocument = gql`
    mutation UpdateGoal($input: UpdateGoalInput!) {
  updateGoal(input: $input) {
    success
    error {
      code
      message
      fieldErrors
    }
    goal {
      id
      userId
      owners {
        id
        firstName
      }
      name
      targetDate
      goalType
      priority
      valueToSave {
        float
        string
        display
      }
    }
  }
}
    `;

export function useUpdateGoalMutation() {
  return Urql.useMutation<UpdateGoalMutation, UpdateGoalMutationVariables>(UpdateGoalDocument);
};

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCalculateCollegeCostsMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ updateCollegeCosts })
 *   )
 * })
 */
export const mockCalculateCollegeCostsMutation = (resolver: ResponseResolver<GraphQLRequest<CalculateCollegeCostsMutationVariables>, GraphQLContext<CalculateCollegeCostsMutation>, any>) =>
  graphql.mutation<CalculateCollegeCostsMutation, CalculateCollegeCostsMutationVariables>(
    'CalculateCollegeCosts',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetAllCollegeCostsQuery((req, res, ctx) => {
 *   const { userId } = req.variables;
 *   return res(
 *     ctx.data({ collegeCosts })
 *   )
 * })
 */
export const mockGetAllCollegeCostsQuery = (resolver: ResponseResolver<GraphQLRequest<GetAllCollegeCostsQueryVariables>, GraphQLContext<GetAllCollegeCostsQuery>, any>) =>
  graphql.query<GetAllCollegeCostsQuery, GetAllCollegeCostsQueryVariables>(
    'GetAllCollegeCosts',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateGoalMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ updateGoal })
 *   )
 * })
 */
export const mockUpdateGoalMutation = (resolver: ResponseResolver<GraphQLRequest<UpdateGoalMutationVariables>, GraphQLContext<UpdateGoalMutation>, any>) =>
  graphql.mutation<UpdateGoalMutation, UpdateGoalMutationVariables>(
    'UpdateGoal',
    resolver
  )
