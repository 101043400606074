import classNames from "classnames";
import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import { IconProps, getDimensionsFromSize } from "./shared";

const ChecklistIcon: FC<IconProps> = ({ size, className, ...props }) => {
  const { width, height } = getDimensionsFromSize(size);
  return (
    <svg
      width={width}
      height={height}
      data-testid="checklist icon"
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      viewBox="0 0 38 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.68244 28.5695L0.09375 21.9809L2.69211 19.3825L6.63604 23.3264L14.5239 15.4386L17.1223 18.0833L6.68244 28.5695ZM6.68244 13.7218L0.09375 7.13311L2.69211 4.53475L6.63604 8.47869L14.5239 0.59082L17.1223 3.23558L6.68244 13.7218ZM20.5094 24.8576V21.1457H37.2131V24.8576H20.5094ZM20.5094 10.0099V6.29792H37.2131V10.0099H20.5094Z"
        fill="#717fe3"
      />
    </svg>
  );
};

export { ChecklistIcon };
