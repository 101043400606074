import { Button, ContainerSmallSize, Card } from "@multiply/lib";
import { useState } from "react";

type SuggestedGoalCardProps = {
  icon: any;
  title: string;
  disclaimer?: React.ReactNode;
  subtitle: React.ReactNode;
  onAdd: () => Promise<void>;
  onSkip: () => void;
  onSkipText?: string;
  error?: string;
  disabled?: boolean;
};

const SuggestedGoalCard = ({
  icon,
  title,
  subtitle,
  onAdd,
  onSkip,
  error,
  disclaimer,
  disabled,
  onSkipText,
}: SuggestedGoalCardProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleAddGoal = async () => {
    setLoading(true);
    try {
      await onAdd();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <ContainerSmallSize>
      <Card className="flex flex-col justify-center items-center p-32 mb-24">
        {icon}
        <h1 className="text-t21 text-font-primary py-16 text-center">
          {title}
        </h1>
        <h2 className="text-t16 text-font-tertiary pb-16 text-center">
          {subtitle}
        </h2>
        <div className="flex flex-col items-center sm:flex-row gap-16 sm:gap-32 mt-20">
          <Button
            onClick={handleAddGoal}
            disabled={loading || disabled}
            variant="ghost"
            className="whitespace-normal h-48 px-40 w-full sm:w-fit"
          >
            Yes, add a goal
          </Button>
          <Button
            onClick={onSkip}
            disabled={loading || disabled}
            variant="ghost"
            className="whitespace-normal h-48 px-40 w-full sm:w-fit"
          >
            {onSkipText ?? "Skip"}
          </Button>
        </div>
        {error ? <p className="mt-12 text-action-error">{error}</p> : null}
        {disclaimer ? (
          <p className="text-t12 text-font-tertiary pt-40 text-center">
            {disclaimer}
          </p>
        ) : null}
      </Card>
    </ContainerSmallSize>
  );
};

export { SuggestedGoalCard };
