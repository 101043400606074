import { Tooltip, RadioInputGroup } from "../index";

type InputYesNoProps = {
  label?: React.ReactNode;
  tooltip?: string;
  value: boolean | null;
  onChange: (value: boolean | null) => void;
  onBlur: () => void;
  error?: string;
  className?: string;
};

const InputYesNo = ({
  onChange,
  onBlur,
  value,
  error,
  className,
  label,
  tooltip,
}: InputYesNoProps) => {
  return (
    <fieldset className={className}>
      {(label || tooltip) && (
        <legend className="flex items-center w-full mb-16">
          {label && (
            <span className="text-input-label font-semibold">{label}</span>
          )}
          {tooltip && (
            <Tooltip className="inline-block" aria-label={tooltip}>
              {tooltip}
            </Tooltip>
          )}
        </legend>
      )}
      <RadioInputGroup
        className="flex gap-24"
        value={value === null ? undefined : value}
        onBlur={onBlur}
        options={[
          { label: "Yes", value: true },
          { label: "No", value: false },
        ]}
        onChange={(nextValue) => {
          nextValue === undefined ? onChange(null) : onChange(nextValue);
        }}
        variant="wrapped"
      />
      {error && <p className="text-action-error mt-12">{error}</p>}
    </fieldset>
  );
};

export { InputYesNo };
