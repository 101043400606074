import classNames from "classnames";
import { FC } from "react";

type IrishLifeDisclaimerTextProps = {
  className?: string;
};

const IrishLifeDisclaimerText: FC<IrishLifeDisclaimerTextProps> = ({
  className,
}) => {
  return (
    <p
      className={classNames(
        "text-t12 sm:text-t14 text-font-primary",
        className
      )}
    >
      Registered Office: Irish Life Centre, Lower Abbey Street, Dublin 1.
      Registered in Ireland: No. 489221. Irish Life Financial Services Limited,
      trading as Irish Life, is regulated by the Central Bank of Ireland. Irish
      Life Financial Services is an insurance intermediary tied to Irish Life
      Assurance for life and pensions. Directors: David Killeen (Chairman), Tim
      Coughlin, Denis McLoughlin, Roisin Kelly, Tony Lawless
    </p>
  );
};

export { IrishLifeDisclaimerText };
