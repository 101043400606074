import { cloneElement } from "react";
import { Control, Controller } from "react-hook-form";
import {
  CurrencyInput,
  DateInput,
  InputAccessible,
  InputFrequency,
  InputOwners,
  InputPensionType,
  InputPropertyType,
  InputReference,
  InputValuationMethod,
  InputWhereFrom,
  InputProvider,
} from "../../components";
import { InputYesNo } from "@multiply/lib";
import { AssetField as AssetFieldType } from "../../globalTypes";

type AssetFieldProps = {
  field: AssetFieldType;
  control: Control<any, any>;
};

const AssetField = ({ field, control }: AssetFieldProps) => {
  const fieldName = typeof field === "string" ? field : field.fieldName;
  const label = typeof field === "string" ? undefined : field.label;
  const tooltip = typeof field === "string" ? undefined : field.tooltip;
  const input = typeof field === "string" ? undefined : field.input;

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => {
        if (input) {
          return cloneElement(input, {
            value,
            onChange,
            onBlur,
            error: error?.message,
            key: fieldName,
          });
        }

        switch (fieldName) {
          case "accessible":
            return (
              <InputAccessible
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                key={fieldName}
              />
            );
          case "balance":
            return (
              <CurrencyInput
                label={label ?? "Balance"}
                tooltip={tooltip}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                key={fieldName}
              />
            );
          case "frequency":
            return (
              <InputFrequency
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                key={fieldName}
              />
            );

          case "increasing":
            return (
              <InputYesNo
                label="Increasing?"
                tooltip="
              This means that your pension contributions are increasing each year. You should be able to find this out by looking at your pension details or asking your Pension provider.
              "
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                key={fieldName}
              />
            );
          case "isOverdrawn":
            return (
              <InputYesNo
                label="Is this account overdrawn?"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                key={fieldName}
              />
            );
          case "maturityDate":
            return (
              <DateInput
                className="md:col-span-2"
                label={label ?? "Maturity date (optional)"}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                key={fieldName}
              />
            );
          case "name":
            return (
              <InputReference
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error}
                key={fieldName}
              />
            );
          case "overdrawnBy":
            return (
              <CurrencyInput
                label={label ?? "Amount overdrawn by"}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                key={fieldName}
              />
            );
          case "owners":
            return (
              <InputOwners
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                key={fieldName}
              />
            );
          case "provider":
            return (
              <InputProvider
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                key={fieldName}
                label={label}
              />
            );
          case "regularPaymentAmount":
            return (
              <CurrencyInput
                label={label ?? "Regular payment amount"}
                tooltip={tooltip}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                key={fieldName}
              />
            );
          case "startDate":
            return (
              <DateInput
                className="md:col-span-2"
                label={label ?? "Start date"}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                key={fieldName}
              />
            );
          case "valuationMethod":
            return (
              <InputValuationMethod
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                key={fieldName}
                label={label}
                tooltip={tooltip}
              />
            );
          case "whereFrom":
            return (
              <InputWhereFrom
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                key={fieldName}
              />
            );
          case "propertyType":
            return (
              <InputPropertyType
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                key={fieldName}
              />
            );
          case "pensionType":
            return (
              <InputPensionType
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                key={fieldName}
              />
            );
          case "active":
            return (
              <InputYesNo
                label="Is your defined benefit pension active?"
                tooltip="
                  Answer yes, if you're still accruing benefits within this scheme.
                  Answer no, if you're awaiting retirement before taking any benefits you've built up.
                "
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                key={fieldName}
              />
            );
          case "throughCurrentEmployer":
            return (
              <InputYesNo
                label="Is this with your current employer?"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                key={fieldName}
              />
            );

          case "isIrishLifePension":
            return (
              <InputYesNo
                label="Is this pension provided by Irish Life?"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                key={fieldName}
              />
            );

          default:
            const unreachable: never = fieldName;
            return unreachable;
        }
      }}
      key={fieldName}
    />
  );
};

export { AssetField };
