import { useAuth } from "@multiply/lib";
import { PlanScope, useGetBasicInfoFactFindQuery } from "../../graphqlTypes";

const usePartnerStatus = () => {
  const { userId, loggedIn } = useAuth();

  const [basicInfoResult] = useGetBasicInfoFactFindQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const planningFor = basicInfoResult?.data?.factfind?.planningFor;

  const hasPartner =
    planningFor &&
    [PlanScope.UserAndPartner, PlanScope.UserAndPartnerAndKids].includes(
      planningFor
    );

  return hasPartner ?? false;
};
export { usePartnerStatus };
