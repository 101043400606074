import { ArrowRightNoOutline, Button, useAuth } from "@multiply/lib";
import { useNavigate } from "react-router-dom";
import { useGetPersonQuery } from "../../graphqlTypes";
import { Loading } from "../Loading";
import intro_adviser from "../../assets/images/intro-adviser.png";
import { OnboardingIntroContainer } from "../../components";
import { motion } from "framer-motion";

const AdvisorIntro = () => {
  const { userId, loggedIn } = useAuth();
  const navigate = useNavigate();

  const [personResult] = useGetPersonQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  // TODO: POPULATE WITH REAL DATA => WHERE DOES IT COME FROM (adviser name, years of experience..)?

  const { firstName } = personResult?.data?.person ?? {};

  const variants = {
    hidden: { opacity: 0, x: 1000, y: 0 },
    enter: { opacity: 1, x: 0, y: 0 },
  };

  if (personResult?.fetching) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loading />
      </div>
    );
  }
  return (
    <div className="bg-brand-primary">
      <motion.main
        variants={variants}
        initial="hidden"
        animate="enter"
        transition={{ type: "linear" }}
        className="bg-brand-primary"
      >
        <OnboardingIntroContainer className="pt-12">
          <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 3 }}
            className="flex-1 flex flex-col md:flex-row gap-12 md:gap-80 items-center justify-center px-28 md:px-100 pb-48"
          >
            <motion.div
              animate={{ y: [0, -10, 0] }}
              transition={{
                repeat: Infinity,
                duration: 1.5,
                ease: "easeInOut",
              }}
              style={{ display: "inline-block" }}
            >
              <div className="max-w-420 mt-20">
                <img
                  src={intro_adviser}
                  aria-label="adviser"
                  alt="adviser"
                  height="auto"
                />
              </div>
            </motion.div>
            <div>
              <h1 className="text-t27 md:text-t57 text-white font-bold pb-12 md:pb-32">
                Hi {firstName}
              </h1>
              <p className="text-white text-t15 md:text-t23 pb-6 md:pb-18">
                I’m Linda, a financial adviser with over X years of experience.
                I lead the team here at Irish Life.
              </p>
              <p className="text-white text-t15 md:text-t23 pb-6 md:pb-18">
                Whether you’re dreaming of your first home, taking a trip of a
                lifetime, or creating a comfortable retirement, our online
                advice can make it happen.
              </p>
              <p className="text-white text-t15 md:text-t23 pb-12 md:pb-24">
                We'll get you there step-by-step and help you make confident
                choices with your money. Let’s get started!
              </p>
              <p className="text-white text-t15 md:text-t23 font-bold">
                Linda Moran
              </p>
              <p className="text-white text-t15 md:text-t23">
                Financial adviser
              </p>
            </div>
          </motion.section>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2, delay: 3 }}
            className="flex justify-center pb-48 md:pb-88"
          >
            <Button
              className="text-center"
              variant="secondary"
              iconRight={<ArrowRightNoOutline size="sm" />}
              onClick={() => navigate("/team-intro")}
            >
              Continue
            </Button>
          </motion.div>
        </OnboardingIntroContainer>
      </motion.main>
    </div>
  );
};

export { AdvisorIntro };
