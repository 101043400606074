import { isRecord } from "./isRecord";

export function flattenObject(obj: Record<string, unknown>): Record<string, unknown> {
    const result: Record<string, unknown> = {};
  
    const applyEntry = (key: string | number, value: unknown) => {
      if (isRecord(value)) {
        const flatValue = flattenObject(value);
  
        Object.entries(flatValue).forEach(([subKey, value]) => {
          result[`${key}.${subKey}`] = value;
        });
      } else {
        result[key] = value;
      }
    };
  
    if (isRecord(obj)) {
      Object.entries(obj).forEach(([key, value]) => {
        applyEntry(key, value);
      });
    }
  
    return result;
  }