const OtherIncomeExplanation = () => {
  return (
    <div className="w-full h-full">
      <h1 className="text-t27 text-font-primary font-bold pb-36">
        What does earned income mean?
      </h1>
      <p className="text-font-primary pb-16">
        Income can be divided into two categories, earned income and unearned
        income.
      </p>
      <p className="text-font-primary pb-16">
        Earned income is any payment you receive as a result of employment. Such
        as from a trade, profession or vocation.
      </p>
      <p className="text-font-primary pb-16">
        In contrast, unearned income is any money you receive without having to
        perform any work or services. This includes:
      </p>

      <ul className="ml-32 mb-24 list-disc">
        <li>Interest from bank and building society accounts</li>
        <li>Interest on stocks</li>
        <li>Dividends on shares</li>
        <li>
          Rental income, unless the rental income is part of the income of a
          trading business
        </li>
      </ul>
      <p className="text-font-primary pb-16">
        Don't enter any child's allowance into your earned or unearned income.
        We prefer that this is considered extra money on top and our plans on
        your income exclude this money.
      </p>
    </div>
  );
};

export { OtherIncomeExplanation };
