import { ButtonLink, Card, ArrowRightCircle, Tooltip } from "@multiply/lib";
import { ModalNavLink } from "../ModalNavLink";

const RiskProfileCard = () => {
  return (
    <Card className="flex flex-col sm:flex-row sm:items-center p-40">
      <div className="mb-18 sm:mr-32 flex-1">
        <h2 className="text-t21 sm:text-t27 text-font-primary mb-32">
          Set your risk profile{" "}
          <ModalNavLink
            to="/cross/what-is-a-risk-assessment"
            className="inline-block"
          >
            <Tooltip className="inline-block align-top">
              <>
                <p className="font-bold">What is a risk assessment?</p>
                <p>
                  A short set of questions that help us work out a profile that
                  fits your Savings, Investing & Retirement needs. You can
                  always re-assess your risk profile if your circumstances
                  change by speaking to one of our advisers. We recommend
                  reviewing at least once a year to ensure suitability.
                </p>
                <p className="font-bold">Why is this needed?</p>
                <p>
                  To make the most suitable recommendations for you, we need to
                  assess your appetite for risk.
                </p>
              </>
            </Tooltip>
          </ModalNavLink>
        </h2>
        <p>
          Take our attitude to risk assessment to help us provide accurate
          recommendations
        </p>
      </div>

      <ButtonLink
        to="/advice/risk/assess/savings-and-investing"
        rightIcon={<ArrowRightCircle className="ml-18 shrink-0" />}
        className="font-bold"
      >
        Continue
      </ButtonLink>
    </Card>
  );
};

export { RiskProfileCard };
