import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";
import {
  IconProps,
  getDimensionsFromSize,
  getColorFromVariant,
} from "./shared";

const RingOutlineIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);

  return (
    <svg
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 6.667 5.333 2.933l1.067-1.6h3.2l1.066 1.6L8 6.667Zm2.333-2.134-.8 1.134C11 6.267 12 7.667 12 9.333a4 4 0 1 1-8 0c0-1.666 1-3.066 2.466-3.666l-.8-1.134a5.27 5.27 0 0 0-3 4.8 5.333 5.333 0 1 0 10.667 0 5.27 5.27 0 0 0-3-4.8Z"
        fill={fill}
      />
    </svg>
  );
};

export { RingOutlineIcon };
