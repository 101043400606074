import {
  FC,
  // useState
} from "react";
import { Input, InputProps } from "@multiply/lib";
import classNames from "classnames";
// import { EyeIcon } from "../Icons";

export type PasswordInputProps = {
  className?: string;
  error?: string;
  label?: string;
  children?: React.ReactNode;
} & InputProps;

const PasswordInput: FC<PasswordInputProps> = ({
  children,
  className,
  ...inputProps
}) => {
  // const [passwordType, setPasswordType] = useState<"password" | "text">(
  //   "password"
  // );

  // const togglePasswordType = () => {
  //   setPasswordType((passwordType) => {
  //     if (passwordType === "text") {
  //       return "password";
  //     } else {
  //       return "text";
  //     }
  //   });
  // };

  return (
    <div className={classNames("text-t21", className)}>
      <Input
        type="password"
        {...inputProps}

        // TODO - implement into new lib input
        // type={passwordType}
        // leftIcon={
        //   <EyeIcon aria-label="view password" onClick={togglePasswordType} />
        // }
      >
        {children}
      </Input>
    </div>
  );
};

export { PasswordInput };
