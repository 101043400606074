import { useAuth } from "@multiply/lib";
import { useEffect } from "react";

const Logout = () => {
  const { logOut } = useAuth();

  useEffect(() => {
    logOut();
  });

  return null;
};

export { Logout };
