import { ContainerSmallSize, useAuth } from "@multiply/lib";
import { useGetVulnerabilityReminderQuery } from "../../graphqlTypes";
import { Loading } from "../Loading";
import { useGetUserJourney } from "../../hooks";
import { InitialVulnerabilityCheck } from "./InitialVulnerabilityCheck";
import { ReminderVulnerabilityCheck } from "./ReminderVulnerabilityCheck";

const CreatePlanRightTime = () => {
  const { userId } = useAuth();

  const [reminderResult] = useGetVulnerabilityReminderQuery({
    variables: { userId: userId ?? "" },
  });

  const userJourney = useGetUserJourney();
  const loading = reminderResult?.fetching || userJourney?.loading;

  return (
    <ContainerSmallSize>
      {loading ? (
        <div className="h-full w-full flex items-center justify-center">
          <Loading />
        </div>
      ) : (
        <>
          {!reminderResult?.data?.vulnerabilityReminder ? (
            <InitialVulnerabilityCheck
              to={userJourney.planRoute}
              call={userJourney.callRoute}
            />
          ) : (
            <ReminderVulnerabilityCheck
              reminderDate={reminderResult?.data?.vulnerabilityReminder || null}
            />
          )}
        </>
      )}
    </ContainerSmallSize>
  );
};

export { CreatePlanRightTime };
