import { PensionType } from "../graphqlTypes";

export type UserFacingPensionType = Exclude<
  PensionType,
  PensionType.Amrf | PensionType.CompanyPension | PensionType.PerformancePrsa
>;

const sortedPensionTypes: UserFacingPensionType[] = [
  PensionType.StandardPrsa,
  PensionType.PersonalPension,
  PensionType.DefinedContribution,
  PensionType.DefinedBenefit,
  PensionType.AvcPension,
  PensionType.Arf,
  PensionType.ExecutivePension,
  PensionType.PersonalRetirementBond,
];

const sortedPensionTypesPage1: UserFacingPensionType[] = [
  PensionType.StandardPrsa,
  PensionType.DefinedContribution,
  PensionType.DefinedBenefit,
];

const sortedPensionTypesPage2: UserFacingPensionType[] = [
  PensionType.AvcPension,
  PensionType.Arf,
  PensionType.ExecutivePension,
  PensionType.PersonalRetirementBond,
  PensionType.PersonalPension,
];

export { sortedPensionTypes, sortedPensionTypesPage1, sortedPensionTypesPage2 };
