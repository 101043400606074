import type { FC } from "react";
import classNames from "classnames";
import {
  getColorFromVariant,
  getDimensionsFromSize,
  IconProps,
} from "./shared";
import { defaultClassnames } from "./defaultClassNames";

const SproutBoldIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);
  const color = getColorFromVariant(variant);
  return (
    <svg
      aria-hidden={true}
      data-testid="sprout icon"
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_7520_184038)">
        <path
          d="M1.44238 2.33135H0.317383C0.317383 4.50752 2.08047 6.26885 4.25488 6.26885V8.51885C4.25488 8.84404 4.50801 9.09717 4.80156 9.09717C5.09512 9.09717 5.37988 8.84404 5.37988 8.53467V6.28467C5.37988 4.11025 3.6168 2.33135 1.44238 2.33135ZM8.19238 1.22217C6.71143 1.22217 5.42559 2.03955 4.75059 3.24893C5.23838 3.77838 5.59873 4.42455 5.7877 5.13857C7.77051 4.93291 9.31738 3.25947 9.31738 1.22217H8.19238Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_7520_184038">
          <rect
            width="9"
            height="9"
            fill="white"
            transform="translate(0.317383 0.659668)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export { SproutBoldIcon };
