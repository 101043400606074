import { AssetType } from "../../graphqlTypes";
import { assetValidationFields } from "../../hooks";
import { getAssetNameFromType } from "../../utils";
import { AssetField } from "./AssetField";
import { getAssetFieldsFromType } from "./getAssetFieldsFromType";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useEffect } from "react";
import { Button } from "@multiply/lib";

type CurrentAccountFormProps = {
  onSubmit: (values: any) => Promise<void>;
  defaultValues: any;
};

const schema = yup.object().shape({
  provider: assetValidationFields.provider,
  owners: assetValidationFields.owners,
  isOverdrawn: assetValidationFields.isOverdrawn,
  balance: assetValidationFields.balance.when("isOverdrawn", {
    is: true,
    then: (schema) => schema.optional(),
    otherwise: (schema) => schema.required("Please provide an answer"),
  }),
  overdrawnBy: assetValidationFields.overdrawnBy.when("isOverdrawn", {
    is: true,
    then: (schema) => schema.required("Please provide an answer"),
    otherwise: (schema) => schema.optional(),
  }),
  whereFrom: assetValidationFields.whereFrom,
  name: assetValidationFields.name,
});

const CurrentAccountForm = ({
  onSubmit,
  defaultValues,
}: CurrentAccountFormProps) => {
  const fields = getAssetFieldsFromType(AssetType.CurrentAccount);
  const name = getAssetNameFromType(AssetType.CurrentAccount);

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting, isValid },
    setValue,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    delayError: 1000,
    mode: "all",
  });

  const isOverdrawn = watch("isOverdrawn");

  useEffect(() => {
    if (isOverdrawn) {
      setValue("balance", null);
    } else {
      setValue("overdrawnBy", null);
    }
  }, [isOverdrawn, setValue]);

  // ask for balance only if overdrawn is false
  // ask for overdrawn by value only if overdrawn is true
  const filteredFields = fields.filter((field) => {
    const fieldName = typeof field === "string" ? field : field.fieldName;

    if (fieldName === "balance" && isOverdrawn !== false) {
      return false;
    }

    if (fieldName === "overdrawnBy" && isOverdrawn !== true) {
      return false;
    }

    return true;
  });

  return (
    <form className="flex flex-col items-center w-full">
      <div className="flex items-center self-start mb-60">
        <h1 className="text-t27 text-font-primary">{name}</h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-x-32 gap-y-32 self-stretch">
        {filteredFields.map((field) => (
          <AssetField
            key={typeof field === "string" ? field : field.fieldName}
            field={field}
            control={control}
          />
        ))}
      </div>

      <Button
        className="mt-96 w-240"
        onClick={handleSubmit(onSubmit)}
        disabled={isSubmitting || !isValid}
      >
        Save
      </Button>
    </form>
  );
};

export { CurrentAccountForm };
