import { Card } from "@multiply/lib";
import classNames from "classnames";
import { Link } from "react-router-dom";

type YouToldUsProps = {
  text?: string;
  className?: string;
  editPath?: string;
  title?: string;
};

const YouToldUs = ({ text, className, editPath, title }: YouToldUsProps) => {
  return (
    <div className={classNames("flex flex-col", className)}>
      {title && (
        <h2 className="text-t16 sm:text-t21 text-font-primary mb-12 sm:mb-32">
          {title}
        </h2>
      )}

      <Card className="flex flex-1 justify-between p-32 gap-16">
        <p className="text-t16 sm:text-t21 text-label-primary whitespace-normal">
          {text}
        </p>

        <Link
          to={editPath ?? "/settings/personal-details"}
          className="text-t16 sm:text-t21 text-font-links ml-32"
          aria-label="Edit your personal details"
        >
          Edit
        </Link>
      </Card>
    </div>
  );
};

export { YouToldUs };
