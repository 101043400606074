import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, useAuth, useWindowDimensions } from "@multiply/lib";
import { ConversationCardTemplate } from "../../components/ConversationCardTemplate";
import { InteractionFlags, UserJourneyTypes } from "../../globalTypes";
import { useSetVulnerabilityReminderMutation } from "../../graphqlTypes";
import { useGetUserJourney, useInteractionFlag } from "../../hooks";
import { Loading } from "../Loading";

type InitialVulnerabilityCheckProps = {
  to: string;
  call: string;
};

const InitialVulnerabilityCheck = ({
  to,
  call,
}: InitialVulnerabilityCheckProps) => {
  const { userId } = useAuth();
  const { isPhone } = useWindowDimensions();

  const navigate = useNavigate();

  const [setReminderResult, setReminder] =
    useSetVulnerabilityReminderMutation();

  const [loading, setLoading] = useState(false);

  const completedFactFindIntro = useInteractionFlag(
    InteractionFlags.CompletedFactFindIntro
  );
  const completedPensionsVulnerability = useInteractionFlag(
    InteractionFlags.CompletedPensionsVulnerability
  );

  const userJourney = useGetUserJourney();

  const handleContinue = async () => {
    setLoading(true);
    try {
      if (userJourney.value === UserJourneyTypes.FocusedPension) {
        if (!completedPensionsVulnerability.value)
          await completedPensionsVulnerability.update(true);
      } else {
        if (!completedFactFindIntro.value) {
          await completedFactFindIntro.update(true);
        }
      }

      navigate(to);
    } finally {
      setLoading(false);
    }
  };

  const handleSetReminder = async () => {
    setLoading(true);

    try {
      const result = await setReminder({ input: { userId: userId ?? "" } });

      if (result.data?.setVulnerabilityReminder?.success) {
        const reminderDate =
          result.data?.setVulnerabilityReminder?.reminderDate;
        navigate(`/reminder-scheduled/${reminderDate}`);
      }
    } finally {
      setLoading(false);
    }
  };

  const error =
    setReminderResult?.error ||
    setReminderResult?.data?.setVulnerabilityReminder.error;

  return (
    <>
      {userJourney?.loading ||
      completedFactFindIntro.loading ||
      completedPensionsVulnerability.loading ? (
        <Loading />
      ) : (
        <ConversationCardTemplate
          title="Has a recent life event caused you distress or disruption?"
          subtitle={
            <div className="flex flex-col space-y-12">
              <p>If so, we recommend scheduling a call with an adviser.</p>
              <p>
                You can also set a reminder to come back later, if now doesn't
                feel like the right time to make financial decisions.
              </p>
            </div>
          }
          className="mb-40 h-auto"
        >
          <fieldset className="flex flex-col items-start gap-12 sm:gap-16 w-full">
            <Button
              className="max-w-fit whitespace-normal h-48 px-40 text-t16 sm:text-t18"
              size="none"
              variant="ghost"
              disabled={loading}
              onClick={handleContinue}
            >
              I'm okay to continue
            </Button>

            <Button
              size="none"
              className="max-w-fit whitespace-normal h-48 sm:px-40 text-t16 sm:text-t18"
              variant="ghost"
              disabled={loading}
              onClick={() => navigate(call)}
            >
              Book a call with a financial adviser
            </Button>

            <Button
              size="none"
              className="max-w-fit whitespace-normal px-40 text-t16 sm:text-t18"
              disabled={loading}
              variant="ghost"
              onClick={handleSetReminder}
            >
              Email {!isPhone ? "me " : ""}a reminder in 3 months
            </Button>
          </fieldset>

          {error && (
            <p className="text-t16 text-action-error mt-12">{error.message}</p>
          )}
        </ConversationCardTemplate>
      )}
    </>
  );
};

export { InitialVulnerabilityCheck };
