import classNames from "classnames";
import { ArrowRightCircle } from "@multiply/lib";

type TextPaginationProps = {
  currentPage: number;
  totalPages: number;
  setPage: (pageNumber: number) => void;
  className?: string;
};

const TextPagination = ({
  currentPage,
  totalPages,
  setPage,
  className,
}: TextPaginationProps) => {
  return (
    <>
      {totalPages > 1 && (
        <div
          className={classNames(
            "flex gap-8 justify-between items-center",
            className
          )}
        >
          {currentPage > 0 && (
            <button onClick={() => setPage(currentPage - 1)}>
              <ArrowRightCircle
                size="lg"
                className="text-brand-primary rotate-180"
                aria-label="Previous page"
              />
            </button>
          )}
          <p>
            {currentPage + 1} of {totalPages}
          </p>
          {currentPage < totalPages - 1 && (
            <button onClick={() => setPage(currentPage + 1)}>
              <ArrowRightCircle
                size="lg"
                className="text-brand-primary"
                aria-label="Next page"
              />
            </button>
          )}
        </div>
      )}
    </>
  );
};

export { TextPagination };
