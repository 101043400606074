import isEqual from "lodash/isEqual";
import { Dropdown } from "@multiply/lib";
import { AccommodationType } from "../../graphqlTypes";

type InputAccommodationProps = {
  value: string | null;
  onChange: (value: string | null) => void;
  onBlur: () => void;
  error?: string;
  className?: string;
};

const InputAccommodation = ({
  value,
  onChange,
  onBlur,
  error,
  className,
}: InputAccommodationProps) => {
  const items = [
    { value: AccommodationType.Student, label: "Student accommodation" },
    { value: AccommodationType.Rented, label: "Rented accommodation" },
    { value: AccommodationType.Home, label: "At home" },
  ];

  const selectedValue =
    items.find((item) => isEqual(item.value, value)) ?? null;

  return (
    <Dropdown
      error={error}
      label="Where do you expect your child to live while studying?"
      items={items}
      placeholder="Select an option"
      value={selectedValue}
      onChange={(item) => {
        onChange(item?.value ?? null);
      }}
      onBlur={onBlur}
      className={className}
    />
  );
};

export { InputAccommodation };
