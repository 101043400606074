import {
  ContainerMidSize,
  ButtonLink,
  Card,
  SurveyIcon,
  TickIcon,
} from "@multiply/lib";
import { useParams } from "react-router-dom";

const RiskProfileComplete = () => {
  const { redirectPage } = useParams();
  return (
    <ContainerMidSize>
      <div className="flex flex-1 flex-col items-center justify-center">
        <Card className="p-32 sm:p-40 text-center max-w-800 flex flex-col items-center">
          <div className="inline-block mb-24 mt-12 relative p-4">
            <SurveyIcon className="text-icon-display" size="xl" />
            <TickIcon className="text-white bg-brand-primary rounded-full absolute top-0 right-0" />
          </div>

          <h1 className="text-font-primary text-t21 sm:text-t27 mb-12">
            Risk profile added
          </h1>

          <p className="text-font-secondary text-t12 sm:text-t16 mb-12">
            Your retirement recommendations will now take this into account.
          </p>

          <p className="text-font-secondary text-t12 sm:text-t16 mb-96">
            If at any point you believe your attitude to risk has changed, we
            recommend speaking to one of our advisers. You can book a call on
            your financial plan page.
          </p>

          <ButtonLink
            to={redirectPage ? `/advice/plan/${redirectPage}` : "/advice"}
          >
            Finish
          </ButtonLink>
        </Card>
      </div>
    </ContainerMidSize>
  );
};

export { RiskProfileComplete };
