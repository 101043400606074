import classNames from "classnames";
import { FC } from "react";

type ContainerSmallSizeProps = {
  className?: string;
  children?: React.ReactNode;
};

const ContainerSmallSize: FC<ContainerSmallSizeProps> = ({
  className,
  children,
}) => {
  return (
    <div
      className={classNames(
        "w-full px-small-screen lg:px-small-screen-lg",
        className
      )}
    >
      {children}
    </div>
  );
};

export { ContainerSmallSize };
