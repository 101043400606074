import { Control, Controller, useWatch } from "react-hook-form";
import {
  CurrencyInput,
  DateInput,
  InputFrequency,
  InputOwners,
  InputReference,
  InputProvider,
  InputCoverType,
} from "../../components";
import { InputYesNo, NumberInput } from "@multiply/lib";
import { PolicyField } from "../../globalTypes";
import { CoverType } from "../../graphqlTypes";

type PolicyFieldsProps = {
  fields: PolicyField[];
  control: Control<any, any>;
};

const PolicyFields = ({ fields, control }: PolicyFieldsProps) => {
  const coverType = useWatch({
    control,
    name: "coverType",
  });

  const renderField = (field: PolicyField) => {
    const fieldName = typeof field === "string" ? field : field.fieldName;
    let label = typeof field === "string" ? undefined : field.label;
    if (fieldName === "yearsRemaining" && coverType === CoverType.WholeOfLife)
      label = "Term remaining (optional)";

    return (
      <Controller
        name={fieldName}
        control={control}
        render={({
          field: { value, onChange, onBlur },
          fieldState: { error },
        }) => {
          switch (fieldName) {
            case "name":
              return (
                <InputReference
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error}
                  key={fieldName}
                />
              );

            case "owners":
              return (
                <InputOwners
                  label="People Covered"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  key={fieldName}
                />
              );

            case "provider":
              return (
                <InputProvider
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  key={fieldName}
                  label="Plan name (optional)"
                />
              );

            case "isOnePlan":
              return (
                <InputYesNo
                  label={label ?? "Is this an Irish Life product?"}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  key={fieldName}
                />
              );

            case "isIrishLifePolicy":
              return (
                <InputYesNo
                  label={
                    label ??
                    "Is this cover provided to you by Irish Life Health?"
                  }
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  key={fieldName}
                />
              );

            case "throughCurrentEmployer":
              return (
                <InputYesNo
                  label={
                    label ?? "Is this cover provided to you by your employer?"
                  }
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  key={fieldName}
                />
              );

            case "amountOfCover":
              return (
                <CurrencyInput
                  label={label ?? "Amount of cover (lump sum)"}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  key={fieldName}
                />
              );

            case "yearsRemaining":
              return (
                <NumberInput
                  label={label ?? "Term remaining in years"}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  key={fieldName}
                />
              );

            case "premium":
              return (
                <CurrencyInput
                  label={label ?? "Premium"}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  key={fieldName}
                />
              );

            case "paymentFrequency":
              return (
                <InputFrequency
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  key={fieldName}
                />
              );

            case "renewalDate":
              return (
                <DateInput
                  className="md:col-span-2"
                  label={label ?? "Renewal Date"}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  key={fieldName}
                />
              );
            case "coverType":
              return (
                <InputCoverType
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  key={fieldName}
                />
              );
            default:
              const unreachable: never = fieldName;
              return unreachable;
          }
        }}
        key={fieldName}
      />
    );
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-x-32 gap-y-32 self-stretch">
      {fields.map(renderField)}
    </div>
  );
};

export { PolicyFields };
