import React from "react";
import classNames from "classnames";

type SwitchProps = {
  disabled: boolean;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  size?: "sm" | "md";
} & Omit<React.ComponentProps<"input">, "size">;

const Switch = ({
  disabled,
  checked,
  onChange,
  size,
  className,
  ...props
}: SwitchProps) => {
  const sizeSelector = size ?? "sm";

  return (
    <div
      className={classNames(
        "flex flex-row flex-nowrap items-center",
        className
      )}
    >
      <label className="relative inline-flex items-center cursor-pointer mx-8">
        <input
          type="checkbox"
          role="switch"
          value=""
          className="sr-only peer appearance-none outline-none"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          {...props}
        />
        <span
          className={classNames(
            // Sizes
            {
              "w-34 h-20 after:w-16 after:h-16 after:absolute after:top-[2px] after:left-[2px] peer-checked:after:translate-x-[90%] ":
                sizeSelector === "sm",
            },
            {
              "w-80 h-40 after:w-32 after:h-32 after:absolute after:top-[4px] after:left-[2px] peer-checked:after:translate-x-[130%]":
                sizeSelector === "md",
            },
            // Borders & rings
            "border-none peer-focus-visible:ring rounded-full",
            // Colours: track
            "bg-switch-background-unchecked peer-checked:bg-switch-background-checked",
            // Colours: toggle
            "peer-checked:after:bg-switch-toggle-unchecked after:bg-switch-toggle-checked",
            // Toggle
            "duration-300 ease-in-out after:content-[''] after:border after:rounded-full after:transition-all",
            // Disabled
            "peer-disabled:bg-switch-disabled-background peer-disabled:cursor-not-allowed"
          )}
        ></span>
      </label>
    </div>
  );
};

export { Switch };
