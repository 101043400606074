import { useAuth, Dropdown } from "@multiply/lib";
import isEqual from "lodash/isEqual";
import {
  PlanScope,
  Relationship,
  useGetFactFindQuery,
  useGetPersonsQuery,
} from "../../graphqlTypes";

type InputOwnersProps = {
  label?: string;
  value: string[] | null;
  onChange: (value: string[] | null) => void;
  onBlur: () => void;
  error?: string;
  className?: string;
  noJoint?: boolean;
};

const InputOwners = ({
  label,
  value,
  onChange,
  onBlur,
  className,
  error,
  noJoint = false,
}: InputOwnersProps) => {
  const { userId } = useAuth();

  const [personsResult] = useGetPersonsQuery({
    variables: { userId: userId ?? "" },
  });

  const [hasPartnerResult] = useGetFactFindQuery({
    variables: { userId: userId ?? "" },
  });

  const planScope = hasPartnerResult?.data?.factfind?.planningFor;

  const hasPartner =
    planScope &&
    [PlanScope.UserAndPartner, PlanScope.UserAndPartnerAndKids].includes(
      planScope
    );

  const me = personsResult.data?.persons?.find(
    (person) => person.relationship === Relationship.User
  );
  const partner = personsResult.data?.persons?.find(
    (person) => person.relationship === Relationship.Partner
  );

  const items: {
    id: string;
    label: string;
    value: string[];
  }[] = [];

  if (me) {
    items.push({
      id: me.id,
      label: "Me",
      value: [me.id],
    });
  }

  if (partner && hasPartner) {
    items.push({
      id: partner.id,
      label: partner?.firstName ?? "My partner",
      value: [partner.id],
    });
  }

  if (me && partner && hasPartner && !noJoint) {
    items.push({
      id: `${partner.id}:${me.id}`,
      label: `Me & ${partner?.firstName ?? "my partner"}`,
      value: [partner.id, me.id],
    });
  }

  const selectedValue =
    items.find((item) => {
      const value1 = [...item.value].sort();
      const value2 = [...(value ?? [])].sort();
      return isEqual(value1, value2);
    }) ?? null;

  return (
    <Dropdown
      aria-label="dropdown to see list of different types of ownership"
      className={className}
      error={error}
      label={label ?? "Owner"}
      items={items}
      placeholder="Select owner"
      value={selectedValue}
      onChange={(item) => {
        onChange(item?.value ?? null);
      }}
      onBlur={onBlur}
    />
  );
};
export { InputOwners };
