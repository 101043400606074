import { Spinner, useAuth, CollegeCapIcon } from "@multiply/lib";
import { useParams } from "react-router-dom";
import { CollegeCostsCalculatorResultDetails } from "@multiply-ai/college-costs-calculator";
import { InteractionFlags } from "../../globalTypes";
import {
  CollegeCost,
  GoalType,
  useGetCollegeCostQuery,
  useGetGoalsQuery,
  useUpdateGoalMutation,
} from "../../graphqlTypes";
import {
  useGoalDetailsFromParams,
  useInteractionFlag,
  useNavigateWithState,
  useResumeFactFindLink,
} from "../../hooks";

export type CollegeCostsCalculatorResultsProps = {
  className?: string;
  previousCollegeCostResult?: CollegeCost | null;
  makeChangesHandler?: (calculationResults: CollegeCost | null) => void;
  modal?: boolean;
};

const CollegeCostsCalculatorResults = ({
  className,
  previousCollegeCostResult,
  makeChangesHandler,
  modal,
}: CollegeCostsCalculatorResultsProps) => {
  const { userId } = useAuth();
  const navigate = useNavigateWithState();
  const { calculationId } = useParams();
  const { goalName, goalId } = useGoalDetailsFromParams();
  let searchParams = new URLSearchParams();
  if (goalName) searchParams.set("goalName", goalName);
  if (goalId) searchParams.set("goalId", goalId);

  const [readCollegeCostResult] = useGetCollegeCostQuery({
    variables: {
      userId: userId ?? "",
      collegeCostUuid: calculationId ?? "",
    },
    pause: !userId,
  });

  const collegeCostResult = previousCollegeCostResult
    ? previousCollegeCostResult
    : readCollegeCostResult?.data?.collegeCost ?? undefined;

  const completedBasicFactfind = useInteractionFlag(
    InteractionFlags.CompletedBasicFactFind
  );

  const completedGenerateLifePlan = useInteractionFlag(
    InteractionFlags.CompletedGenerateLifePlan
  );

  const [updateGoalResults, updateGoal] = useUpdateGoalMutation();
  const [getGoalsResult] = useGetGoalsQuery({
    variables: { userId: userId ?? "" },
  });

  const error =
    updateGoalResults?.error?.message ||
    updateGoalResults?.data?.updateGoal?.error?.message ||
    readCollegeCostResult?.error?.message;

  const loading =
    readCollegeCostResult?.fetching ||
    completedGenerateLifePlan?.loading ||
    completedBasicFactfind?.loading ||
    updateGoalResults?.fetching ||
    getGoalsResult?.fetching;

  const changeInputValues = () => {
    if (!userId && makeChangesHandler) {
      makeChangesHandler(collegeCostResult ?? null);
    } else {
      const calculationId = collegeCostResult?.id;
      const url = modal
        ? `/cross/tools/college-costs-calculator/make-changes/${calculationId}`
        : `/tools/college-costs-calculator/make-changes/${calculationId}`;
      navigate(url, searchParams);
    }
  };

  const factFindStatus = useResumeFactFindLink(
    InteractionFlags.CompletedViewGoals
  );

  const startOverButtonHandle = () => {
    if (!userId && makeChangesHandler) {
      makeChangesHandler(null);
    } else {
      const url = modal
        ? `/cross/tools/college-costs-calculator/start-over`
        : `/tools/college-costs-calculator/start-over`;
      navigate(url, searchParams);
    }
  };

  const addSavingsGoal = async () => {
    try {
      await updateGoal({
        input: {
          userId: userId ?? "",
          goalId: goalId ?? null,
          goal: {
            goalType: GoalType.ChildEducation,
            name: goalName ?? "Child's education",
            targetDate: collegeCostResult?.targetDate,
            valueToSave: collegeCostResult?.total?.float,
          },
          collegeCostId: calculationId ?? undefined,
        },
      });
      navigate(factFindStatus.link);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={className}>
      {!modal && (
        <>
          <div className="flex flex-row items-center gap-12 mb-8">
            <CollegeCapIcon size="lg" className="text-icon-display" />
            <h1 className="text-t21 sm:text-t27 font-semibold text-font-primary">
              College costs calculator
            </h1>
          </div>
          <p className="text-t16 sm:text-t21 text-font-secondary mb-28">
            Calculate what it could cost you to put a child through third-level
            education.
          </p>
        </>
      )}
      {loading ? (
        <Spinner />
      ) : (
        <>
          {collegeCostResult && (
            <CollegeCostsCalculatorResultDetails
              userId={userId}
              modal={modal}
              collegeCostValues={collegeCostResult as CollegeCost}
              onMakeChanges={changeInputValues}
              onStartAgain={startOverButtonHandle}
              onAddGoal={userId ? addSavingsGoal : undefined}
              savingsGoalText={
                completedGenerateLifePlan.value
                  ? "Add new savings goal"
                  : completedBasicFactfind.value
                  ? goalName
                    ? "Update goal"
                    : "Add new savings goal"
                  : "Add goal and get your plan"
              }
            />
          )}
          {error ? <p className="text-action-error mt-12">{error}</p> : null}
        </>
      )}
    </div>
  );
};

export { CollegeCostsCalculatorResults };
