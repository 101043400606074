import { ContainerMaxSize, Spinner, useAuth } from "@multiply/lib";
import { Link } from "react-router-dom";
import { RecommendationGroupSummaries } from "../../components";
import { useGetPlanAdviceInfoQuery } from "../../graphqlTypes";

const Priorities = () => {
  const { userId, loggedIn } = useAuth();

  const [planResult] = useGetPlanAdviceInfoQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const recommendationGroups = planResult.data?.plan.recommendationGroups ?? [];

  const thingsToDoRecommendations = recommendationGroups?.filter(
    (recommendationGroup) => (recommendationGroup.thingsToDo ?? 0) > 0
  );

  const onTrackRecommendations = recommendationGroups?.filter(
    (recommendationGroup) => (recommendationGroup.thingsToDo ?? 0) === 0
  );

  const totalThingsToDo = thingsToDoRecommendations?.reduce(
    (total, recommendationGroup) =>
      total + (recommendationGroup.thingsToDo ?? 0),
    0
  );

  return (
    <ContainerMaxSize className="space-y-60">
      {planResult.fetching ? (
        <Spinner />
      ) : (
        <>
          {thingsToDoRecommendations.length > 0 && (
            <section>
              <h1 className="text-font-primary text-t21 sm:text-t34 font-semibold">
                You have {totalThingsToDo} things to do
              </h1>
              <h2 className="mt-4 text-t14 sm:text-t18 text-font-secondary">
                Your actions to get on track, in{" "}
                <Link
                  className="text-font-links"
                  to="/advice/recommendations-priority"
                  aria-label="Learn more about your recommendation priorities"
                >
                  priority order
                </Link>
              </h2>
              <RecommendationGroupSummaries
                className="mt-18 max-w-800"
                recommendationGroups={thingsToDoRecommendations}
              />
            </section>
          )}

          {onTrackRecommendations.length > 0 && (
            <section>
              <h1 className="text-font-primary text-t21 sm:text-t34 font-semibold">
                On track
              </h1>
              <h2 className="mt-4 text-t14 sm:text-t18 text-font-secondary">
                Things that you're already doing a great job with
              </h2>
              <RecommendationGroupSummaries
                className="mt-18 max-w-800"
                recommendationGroups={onTrackRecommendations}
              />
            </section>
          )}
        </>
      )}
    </ContainerMaxSize>
  );
};

export { Priorities };
