import classNames from "classnames";
import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import { getDimensionsFromSize, IconProps } from "./shared";

const CalendarIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);

  return (
    <svg
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="currentColor"
      stroke="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="calendar icon"
      {...props}
    >
      <path d="M.5 14.5c0 .8.7 1.5 1.6 1.5h11.8c.9 0 1.6-.7 1.6-1.5V6H.5v8.5zm10.7-6.1c0-.2.2-.4.4-.4H13c.2 0 .4.2.4.4v1.3c0 .2-.2.4-.4.4h-1.3c-.2 0-.4-.2-.4-.4V8.4zm0 4c0-.2.2-.4.4-.4H13c.2 0 .4.2.4.4v1.2c0 .2-.2.4-.4.4h-1.3c-.2 0-.4-.2-.4-.4v-1.2zm-4.3-4c0-.2.2-.4.4-.4h1.3c.2 0 .4.2.4.4v1.3c0 .2-.2.4-.4.4H7.3c-.2 0-.4-.2-.4-.4V8.4zm0 4c0-.2.2-.4.4-.4h1.3c.2 0 .4.2.4.4v1.2c0 .2-.2.4-.4.4H7.3c-.2 0-.4-.2-.4-.4v-1.2zm-4.3-4c0-.2.2-.4.4-.4h1.3c.2 0 .4.2.4.4v1.3c0 .2-.2.4-.4.4H3c-.2 0-.4-.2-.4-.4V8.4zm0 4c0-.2.2-.4.4-.4h1.3c.2 0 .4.2.4.4v1.2c0 .2-.2.4-.4.4H3c-.2 0-.4-.2-.4-.4v-1.2zM13.9 2h-1.6V.5c0-.3-.3-.5-.5-.5h-1.1c-.3 0-.5.2-.5.5V2H5.9V.5c0-.3-.3-.5-.6-.5H4.2c-.2 0-.5.2-.5.5V2H2.1C1.2 2 .5 2.7.5 3.5V5h15V3.5c0-.8-.7-1.5-1.6-1.5z" />
    </svg>
  );
};

export { CalendarIcon };
