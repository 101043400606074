import { FC } from "react";
import classNames from "classnames";
import { defaultClassnames } from "../defaultClassNames";
import {
  getColorFromVariant,
  getDimensionsFromSize,
  IconProps,
} from "../shared";
import { useId } from "../../../hooks";

const PinChildEducationIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);
  const radialGradient = useId();
  return (
    <svg
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 39 49"
      fill="none"
      data-testid="pin child education icon"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={fill}
        stroke={`url(#${radialGradient})`}
        strokeWidth={2.016}
        d="M37.12 19.185c0 2.419-.851 5.391-2.267 8.546-1.409 3.138-3.34 6.377-5.412 9.302-2.075 2.928-4.272 5.515-6.201 7.358-.966.923-1.844 1.64-2.59 2.118-.78.503-1.28.655-1.522.655s-.742-.152-1.523-.655c-.745-.479-1.623-1.195-2.589-2.118-1.929-1.843-4.126-4.43-6.2-7.358-2.074-2.925-4.004-6.164-5.413-9.302-1.416-3.155-2.267-6.127-2.267-8.546 0-9.944 8.056-18.005 17.992-18.005 9.936 0 17.992 8.06 17.992 18.005Z"
      />
      <g clipPath="url(#b)">
        <path
          fill="#fff"
          d="M20.53 12.906a2.448 2.448 0 0 0-2.098 0l-6.087 2.886a.625.625 0 0 0-.357.597v3.783a.625.625 0 0 0 1.249 0v-2.808l1.249.62v3.437a.627.627 0 0 0 .183.442l.032.03.075.069c.391.349.814.66 1.264.931.834.502 2.039 1.027 3.442 1.027 1.402 0 2.607-.525 3.443-1.027a8.42 8.42 0 0 0 1.264-.931l.075-.069.028-.03h.003a.625.625 0 0 0 .182-.443v-3.437l2.15-1.067a.625.625 0 0 0-.01-1.125l-6.086-2.886v.001Zm.04 7.018 2.658-1.32v2.54a7.17 7.17 0 0 1-.945.678c-.725.435-1.706.849-2.801.849-1.096 0-2.076-.413-2.802-.849a7.17 7.17 0 0 1-.945-.679v-2.54l2.657 1.32c.687.34 1.492.34 2.179 0Zm-.574-5.888 4.923 2.333-4.904 2.435a1.201 1.201 0 0 1-1.067 0l-4.904-2.435 4.923-2.333a1.202 1.202 0 0 1 1.03 0Zm-5.324 7.83-.001-.002.001.002Z"
        />
      </g>
      <defs>
        <radialGradient
          id={radialGradient}
          cx={0}
          cy={0}
          r={1}
          gradientTransform="matrix(0 19.013 -19 0 19.128 19.185)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.839} stopColor="#fff" />
          <stop offset={1} stopColor="#ECEFFF" />
        </radialGradient>
        <clipPath id="b">
          <path fill="#fff" d="M11.987 10.17h14.987v14.998H11.987z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export { PinChildEducationIcon };
