import { useAuth, useToaster } from "@multiply/lib";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmDialogue } from "../../components";
import { useDeletePolicyMutation } from "../../graphqlTypes";

const ConfirmDeletePolicy = () => {
  const { policyId } = useParams();
  const { userId } = useAuth();
  const { addToast } = useToaster();
  const navigate = useNavigate();

  const [deletePolicyResult, deletePolicy] = useDeletePolicyMutation();

  const deletionError =
    deletePolicyResult?.error || deletePolicyResult?.data?.deletePolicy?.error;

  const handleDeletePolicy = async () => {
    try {
      const result = await deletePolicy({
        input: {
          userId: userId ?? "",
          policyId,
        },
      });

      if (result.data?.deletePolicy?.success) {
        navigate("/products");
      }
    } catch (error) {}
    addToast({ message: "Policy deleted" });
  };

  return (
    <ConfirmDialogue
      title="Are you sure you want to delete this policy?"
      onClickConfirm={handleDeletePolicy}
      onClickCancel={() => navigate(-1)}
      confirmCta="Yes, delete"
      cancelCta="No, keep"
      error={deletionError?.message}
    />
  );
};

export { ConfirmDeletePolicy };
