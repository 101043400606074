import { InputYesNo } from "@multiply/lib";

type InputAccessibleProps = {
  value: boolean | null;
  onChange: (value: boolean | null) => void;
  onBlur: () => void;
  error?: string;
  className?: string;
};

const InputAccessible = ({
  onChange,
  onBlur,
  value,
  error,
  className,
}: InputAccessibleProps) => {
  return (
    <InputYesNo
      label="Accessible?"
      tooltip="Answer yes if you can easily withdraw or transfer this money, without a fee or penalty. Answer no if you are bound to a fixed timed period and cannot withdraw quickly."
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      error={error}
      className={className}
    />
  );
};

export { InputAccessible };
