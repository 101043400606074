import {
  Size,
  PinHomeIcon,
  PinWeddingIcon,
  PinChildEducationIcon,
  PinPiggyBankIcon,
} from "@multiply/lib";
import classNames from "classnames";
import { GoalType } from "../../graphqlTypes";

type GoalPinIconProps = {
  goalType: GoalType;
  size: Size;
  className?: string;
};

const GoalPinIcon = ({ goalType, size, className }: GoalPinIconProps) => {
  switch (goalType) {
    case GoalType.BuyHome:
      return (
        <PinHomeIcon
          size={size}
          className={classNames("text-icon-display", className)}
        />
      );
    case GoalType.Wedding:
      return (
        <PinWeddingIcon
          size={size}
          className={classNames("text-icon-display", className)}
        />
      );
    case GoalType.ChildEducation:
      return (
        <PinChildEducationIcon
          size={size}
          className={classNames("text-icon-display", className)}
        />
      );
    default:
      return (
        <PinPiggyBankIcon
          size={size}
          className={classNames("text-icon-display", className)}
        />
      );
  }
};

export { GoalPinIcon };
