import { useAuth } from "@multiply/lib";
import {
  EmploymentStatus,
  EmploymentType,
  useGetFactFindQuery,
} from "../../graphqlTypes";

export const useIsEmployed = () => {
  const { userId, loggedIn } = useAuth();

  const [factFindResults] = useGetFactFindQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const mainEmploymentType =
    factFindResults?.data?.factfind?.userSpecificFactfind?.mainEmploymentType;
  const employmentStatus =
    factFindResults?.data?.factfind?.userSpecificFactfind?.employmentStatus;

  const isEmployed =
    mainEmploymentType === EmploymentType.RegularEmployment &&
    employmentStatus === EmploymentStatus.Employed;

  return { loading: factFindResults?.fetching, value: isEmployed };
};
