import { Button } from "@multiply/lib";
import { useNavigate } from "react-router-dom";
import { InteractionFlags } from "../../globalTypes";
import { useInteractionFlag, useProductTypesAdded } from "../../hooks";
import { Loading } from "../Loading";

const ConfirmAddedAllProducts = () => {
  const productTypesCompleted = useProductTypesAdded();

  const navigate = useNavigate();

  const completedAddProducts = useInteractionFlag(
    InteractionFlags.CompletedAddProducts
  );

  let title = `You told us you have no ${
    !productTypesCompleted.assetsValue &&
    (!productTypesCompleted.debtsValue || !productTypesCompleted.policiesValue)
      ? "assets or "
      : !productTypesCompleted.assetsValue &&
        productTypesCompleted.debtsValue &&
        productTypesCompleted.policiesValue
      ? "assets."
      : ""
  } ${
    !productTypesCompleted.debtsValue && !productTypesCompleted.policiesValue
      ? "debts or "
      : !productTypesCompleted.debtsValue && productTypesCompleted.policiesValue
      ? "debts."
      : ""
  }${!productTypesCompleted.policiesValue ? "existing policies." : ""}`;

  const handleComplete = async () => {
    if (!completedAddProducts.loading && !completedAddProducts.value) {
      await completedAddProducts.update(true);
    }

    navigate("/advice/plan/generate");
  };

  return (
    <>
      {completedAddProducts.loading || productTypesCompleted.loading ? (
        <div className="mt-40 flex-1 flex items-center justify-center">
          <Loading />
        </div>
      ) : (
        <div className="mb-32 text-font-primary">
          <h1 className="text-t21 sm:text-t34">{title}</h1>
          <p className="mt-16 text-t21">
            If you have any, please go back and add them to get an accurate
            plan. If you have none, please continue.
          </p>

          <div className="h-full flex flex-col items-end gap-18 sm:mt-40 pr-48">
            <Button
              className="mt-80 min-w-200"
              variant="ghost"
              onClick={handleComplete}
            >
              Continue
            </Button>

            <Button
              className="min-w-200"
              variant="ghost"
              onClick={() => navigate(-1)}
            >
              Go back
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export { ConfirmAddedAllProducts };
