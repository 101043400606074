import { Spinner } from "@multiply/lib";

const AppLoading = () => {
  return (
    <main className="w-screen h-screen flex flex-col items-center justify-center">
      <h1 className="text-t20 sm:text-t27 text-font-primary mb-40">
        One moment please
      </h1>

      <Spinner />
    </main>
  );
};

export { AppLoading };
