import isEqual from "lodash/isEqual";
import { Dropdown } from "@multiply/lib";


type InputRetirementAgeProps = {
  currentAge: number;
  value: number | null;
  onChange: (value: number | null) => void;
  onBlur: () => void;
  error?: string;
  className?: string;
};

const InputRetirementAge = ({
  currentAge,
  value,
  onChange,
  onBlur,
  error,
  className,
}: InputRetirementAgeProps) => {
  const items = Array.from({ length: 96 - currentAge }, (_, i) => ({
    value: i + currentAge,
    label: `${i + currentAge}`,
  }));

  const selectedValue =
    items.find((item) => isEqual(item.value, value)) ?? null;

  return (
    <Dropdown
      error={error}
      label="Retirement age"
      items={items}
      placeholder="Select an option"
      value={selectedValue}
      onChange={(item) => {
        onChange(item?.value ?? null);
      }}
      onBlur={onBlur}
      className={className}
    />
  );
};

export { InputRetirementAge };
