import { useState, useEffect } from "react";

const size = {
  mobile: "425px",
  tablet: "1024px",
};

export const device = {
  mobile: `(max-width: ${size.mobile})`,
  tablet: `(max-width: ${size.tablet})`,
};

const getWindowDimensions = (widthProp?: number) => {
  const { innerWidth: width } = window;
  return {
    isPhone: width < 640,
    isTablet: width < 768,
    isDesktop: width > 768,
    isLessThanCustomWidth: widthProp && width < widthProp,
    isMoreThanCustomWidth: widthProp && width > widthProp,
  };
};

export const useWindowDimensions = (widthProp?: number) => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(widthProp)
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions(widthProp));
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [widthProp]);

  return windowDimensions;
};
