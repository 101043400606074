import isEqual from "lodash/isEqual";
import { Dropdown } from "@multiply/lib";

type InputCourseDurationProps = {
  value: number | null;
  onChange: (value: number | null) => void;
  onBlur: () => void;
  error?: string;
  className?: string;
};

const InputCourseDuration = ({
  value,
  onChange,
  onBlur,
  error,
  className,
}: InputCourseDurationProps) => {
  const items = [
    { value: 2, label: "2 years" },
    { value: 3, label: "3 years" },
    { value: 4, label: "4 years" },
    { value: 5, label: "5 years" },
    { value: 6, label: "6 years" },
    { value: 7, label: "7 years" },
  ];

  const selectedValue =
    items.find((item) => isEqual(item.value, value)) ?? null;

  return (
    <Dropdown
      error={error}
      label="How long do you expect your child's course to be?"
      items={items}
      placeholder="Select an option"
      value={selectedValue}
      onChange={(item) => {
        onChange(item?.value ?? null);
      }}
      onBlur={onBlur}
      className={className}
    />
  );
};

export { InputCourseDuration };
