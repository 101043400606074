import { CalendarOutlineIcon, Dropdown, useAuth } from "@multiply/lib";
import { addYears } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useGetGoalsQuery } from "../../graphqlTypes";

type ExtentInputProps = {
  onChange: (value?: ExtentOption) => void;
};

export type ExtentOption = {
  label: string;
  id: string;
  value: {
    start: Date | null;
    end: Date | null;
  };
};

const ExtentInput = ({ onChange }: ExtentInputProps) => {
  const { userId } = useAuth();

  const [goalsResult] = useGetGoalsQuery({
    variables: { userId: userId ?? "" },
  });

  const goals = goalsResult.data?.goals;

  const extentOptions: ExtentOption[] = useMemo(() => {
    const goalOptions =
      goals?.map((goal) => {
        return {
          label: goal?.name?.toUpperCase() ?? "",
          id: goal?.id,
          value: {
            start: new Date(),
            end: new Date(goal?.targetDate),
          },
        };
      }) ?? [];

    return [
      ...goalOptions,
      {
        label: "RETIREMENT",
        id: "RETIREMENT",
        value: { start: new Date(), end: null },
      },

      {
        label: "NEXT 2 YEARS",
        id: "NEXT_2_YEARS",
        value: { start: new Date(), end: addYears(new Date(), 2) },
      },

      {
        label: "NEXT 5 YEARS",
        id: "NEXT_5_YEARS",
        value: { start: new Date(), end: addYears(new Date(), 5) },
      },
    ];
  }, [goals]);

  const [selectedExtentId, setSelectedExtentId] = useState<string | null>(
    "RETIREMENT"
  );

  const selectedExtent = useMemo(
    () => extentOptions.find((option) => option.id === selectedExtentId),

    [extentOptions, selectedExtentId]
  );

  useEffect(() => {
    onChange(selectedExtent);
  }, [selectedExtent, onChange]);

  return (
    <Dropdown
      leftIcon={<CalendarOutlineIcon className="h-16" />}
      variant="small"
      className="self-start mb-24"
      items={extentOptions}
      value={selectedExtent}
      onChange={(extent) => setSelectedExtentId(extent?.id ?? null)}
    />
  );
};

export { ExtentInput };
