import { Card, Spinner, Tooltip, useAuth } from "@multiply/lib";
import { format } from "date-fns";
import { useState, useMemo } from "react";
import { useGetPlanGraphQuery } from "../../graphqlTypes";
import { useRetirementDate } from "../../hooks";
import { convertNumberToEuroString } from "../../utils";
import { PlanGraph, Wealth } from "../PlanGraph";
import { ExtentInput, ExtentOption } from "./ExtentInput";
import { getWealthRange } from "./lifePlanUtils";

type LifePlanGraphCardProps = {
  className?: string;
};

const LifePlanGraphCard = ({ className }: LifePlanGraphCardProps) => {
  const { userId, loggedIn } = useAuth();

  const [selectedExtent, setSelectedExtent] = useState<
    ExtentOption | undefined
  >();

  const startDate = selectedExtent?.value.start;
  const endDate = selectedExtent?.value.end;

  const [planGraphResult] = useGetPlanGraphQuery({
    variables: {
      userId: userId ?? "",
      startDate: startDate ? format(startDate, "yyyy/MM/dd") : null,
      endDate: endDate ? format(endDate, "yyyy/MM/dd") : null,
    },
    pause: !loggedIn || !selectedExtent,
  });

  const currentWealth = planGraphResult.data?.planGraph.currentWealth;
  const finalWealth = planGraphResult.data?.planGraph.finalWealth;
  const wealthProjection = planGraphResult.data?.planGraph.wealthProjection;
  const retirementDateResult = useRetirementDate();

  const wealthRange = useMemo(() => {
    return getWealthRange(
      retirementDateResult.earliest,
      (wealthProjection as Wealth[]) ?? []
    );
  }, [wealthProjection, retirementDateResult.earliest]);

  const tooltipText = `Your net wealth means your Total Assets subtracted by your Debts or Liabilities.`;

  return (
    <section className={className}>
      <Card className="px-24 md:px-32 pt-18 md:pt-28 pb-0 flex flex-col">
        <ExtentInput onChange={setSelectedExtent} />
        <div className="flex w-fit items-center">
          <div>
            <div className="flex items-center">
              <h1 className="text-font-primary text-t13 md:text-t18 font-semibold pr-4">
                Current net wealth
              </h1>
              <Tooltip aria-label={tooltipText}>{tooltipText}</Tooltip>
            </div>
            <p className="text-font-primary font-bold text-t20 md:text-t23 lg:text-t27">
              {convertNumberToEuroString(currentWealth?.float || 0)}
            </p>
          </div>
          <div className="md:border-l border-h-divider mx-14 md:mx-40"></div>

          <div>
            <h1 className="text-font-primary text-t13 md:text-t18 font-semibold pr-4">
              Projected by retirement
            </h1>

            <p className="text-font-primary font-bold text-t20 md:text-t23 lg:text-t27">
              {convertNumberToEuroString(finalWealth?.float || 0)}
            </p>
          </div>
        </div>

        {planGraphResult?.fetching || !wealthRange || !wealthRange ? (
          <Spinner className="my-24" />
        ) : (
          <PlanGraph range={wealthRange} />
        )}
      </Card>
    </section>
  );
};

export { LifePlanGraphCard };
