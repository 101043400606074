import { LinkIcon } from "@multiply/lib";
import classNames from "classnames";


type FactsheetLinkProps = {
  detailsLink: string;
  className?: string;
};

const FactsheetLink = ({ detailsLink, className }: FactsheetLinkProps) => {
  return (
    <a
    className={classNames("text-t14 text-font-links", className)}
      href={detailsLink}
      target="_blank"
      rel="noreferrer"
    >
      Details and factsheet
      <LinkIcon size="xsm" className="ml-6" />
    </a>
  );
};

export { FactsheetLink };
