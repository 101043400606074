import { useId } from "@multiply/lib";
import { format } from "date-fns";
import { convertNumberToEuroString } from "../../utils";

const GraphTooltip = (props: any) => {
  const { x, y, datum } = props;

  const value = convertNumberToEuroString(datum.amount.float);

  const dateString = datum.date
    ? format(new Date(datum.date), "MMMM yyyy")
    : "";
  const tooltipText = `${value} in ${dateString}`;

  const filterDef = useId();

  return (
    <g>
      <path
        transform={`translate(${x - 85}, ${y - 90}) `}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 7C12.3726 7 7 12.3726 7 19V60C7 66.6274 12.3726 72 19 72H70.7158L83.5 87L96.2842 72H147C153.627 72 159 66.6274 159 60V19C159 12.3726 153.627 7 147 7H19Z"
        fill="white"
        stroke="none"
        filter={`url(#${filterDef})`}
      />

      <text
        x={x - 66}
        y={y - 55}
        className="stroke-none fill-font-primary font-semibold text-t13"
      >
        Net wealth forecast
      </text>
      <text
        x={x - 66}
        y={y - 38}
        className="stroke-none fill-font-secondary font-normal text-t11"
      >
        {tooltipText}
      </text>

      <defs>
        <filter
          id={filterDef}
          x="0"
          y="0"
          width="170"
          height="98"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="4.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4041_109833"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4041_109833"
            result="shape"
          />
        </filter>
      </defs>
    </g>
  );
};

export default GraphTooltip;
