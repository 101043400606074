import { ScrollToTop } from "../../components";
import { useInteractionFlag } from "../../hooks";
import { InteractionFlags } from "../../globalTypes";
import { ContainerMaxSize } from "@multiply/lib";
import { Loading } from "../Loading";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PensionsHome = () => {
  const navigate = useNavigate();

  const completedPensionsLimitations = useInteractionFlag(
    InteractionFlags.CompletedPensionsLimitations
  );
  const completedPensionsVulnerability = useInteractionFlag(
    InteractionFlags.CompletedPensionsVulnerability
  );
  const completedPensionsFactFind = useInteractionFlag(
    InteractionFlags.CompletedPensionsFactFind
  );
  const completedPensionsRisk = useInteractionFlag(
    InteractionFlags.CompletedPensionsRisk
  );
  const completedAcceptPRSAInvestmentFund = useInteractionFlag(
    InteractionFlags.CompletedPensionsOnboarding
  );

  const loading =
    completedPensionsLimitations.loading ||
    completedPensionsVulnerability.loading ||
    completedPensionsFactFind.loading ||
    completedPensionsRisk.loading ||
    completedAcceptPRSAInvestmentFund.loading;

  useEffect(() => {
    if (loading) {
      return;
    }
    if (completedAcceptPRSAInvestmentFund.value) {
      navigate("/pensions/pension-details", { replace: true });
    } else if (completedPensionsRisk.value) {
      navigate("/pensions/accept-investment-fund", { replace: true });
    } else if (completedPensionsFactFind.value) {
      navigate("/pensions/risk/assess", { replace: true });
    } else if (completedPensionsVulnerability.value) {
      navigate("/pensions/factfind", { replace: true });
    } else if (completedPensionsLimitations.value) {
      navigate("/pensions/vulnerable", { replace: true });
    } else {
      navigate("/pensions/limitations", { replace: true });
    }
  });

  return (
    <ScrollToTop>
      <ContainerMaxSize>
        {loading ? (
          <div className="h-full w-full flex items-center justify-center">
            <Loading />
          </div>
        ) : null}
      </ContainerMaxSize>
    </ScrollToTop>
  );
};

export { PensionsHome };
