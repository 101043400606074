const COLLEGE_COSTS_CALC_KEY = "collegeCostsCalc";

const useLocallyStoreCollegeCostsCalcResults = () => {
  const saveCalculationDetails = (collegeCosts: string | null) => {
    if (typeof window === "undefined") return;

    try {
      if (collegeCosts) {
        window.localStorage.setItem(COLLEGE_COSTS_CALC_KEY, collegeCosts);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCalculationDetails = () => {
    let collegeCosts = null;

    if (typeof window !== "undefined") {
      try {
        collegeCosts = window.localStorage.getItem(COLLEGE_COSTS_CALC_KEY);
      } catch (error) {
        console.log(error);
      }
    }

    return collegeCosts;
  };

  const clearCalculationDetails = () => {
    window.localStorage.removeItem(COLLEGE_COSTS_CALC_KEY);
  };

  return {
    saveCalculationDetails,
    getCalculationDetails,
    clearCalculationDetails,
  };
};

export { useLocallyStoreCollegeCostsCalcResults };
