import { Spinner } from "@multiply/lib";
import { ContentCard, PlanTimeline } from "../../components";
import { useResumeFactFindLink } from "../../hooks";

type GetStartedCardProps = {
  disabled?: boolean;
  className?: string;
};

const GetStartedCard = ({ disabled, className }: GetStartedCardProps) => {
  const factFindStatus = useResumeFactFindLink();

  const cta = factFindStatus.hasStarted ? "Continue" : "Start";

  return factFindStatus.loading ? (
    <Spinner />
  ) : (
    <ContentCard
      className={className}
      title="Create your plan"
      subtitle="This usually takes just 10 minutes"
      cta={cta}
      to={factFindStatus.link}
      disabled={disabled || factFindStatus.loading}
      aria-label={`${cta}: Create your plan`}
    >
      <PlanTimeline
        className="max-w-full"
        aria-label="example of plan timeline"
      />
    </ContentCard>
  );
};

export { GetStartedCard };
