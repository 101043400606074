import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import { Button, Card } from "@multiply/lib";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { EditablePortfolioCard, PensionsPageTemplate } from "../../components";
import { PrsaFund } from "../../graphqlTypes";

const AllocatePensionContributions = () => {
  const navigate = useNavigate();

  // defaultValues will come from BE. Query error will replace hardcoded value bellow
  const error = false;

  const schema = yup.object().shape({
    globalCashFund: yup.number().required("Please enter amount").nullable(),
    pensionPortfolio2: yup.number().required("Please enter amount").nullable(),
    pensionPortfolio3: yup.number().required("Please enter amount").nullable(),
    pensionPortfolio4: yup.number().required("Please enter amount").nullable(),
    pensionPortfolio5: yup.number().required("Please enter amount").nullable(),
    pensionPortfolio6: yup.number().required("Please enter amount").nullable(),
  });

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      globalCashFund: 0,
      pensionPortfolio2: 0,
      pensionPortfolio3: 0,
      pensionPortfolio4: 0,
      pensionPortfolio5: 0,
      pensionPortfolio6: 0,
    },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const currentlyAllocated = Object.values(watch()).reduce((a, b) => a + b, 0);
  const leftToAllocate = 100 - currentlyAllocated;

  type PensionsFormValues = {
    globalCashFund: number;
    pensionPortfolio2: number;
    pensionPortfolio3: number;
    pensionPortfolio4: number;
    pensionPortfolio5: number;
    pensionPortfolio6: number;
  };

  const onSubmit = async (values: PensionsFormValues) => {
    // Replace log with mutation
    navigate("/PENSION_TODO");
  };

  return (
    <PensionsPageTemplate
      title="Confirm your investment fund"
      subtitle="This is the fund we'll invest your money into."
    >
      {error ? (
        <p className="text-action-error">
          Something went wrong, please try again later
        </p>
      ) : (
        <>
          <Card className="p-18 sm:py-36 sm:px-53 rounded-xl mt-28 mx-18 sm:min-w-800">
            <h3 className="text-font-primary text-t21 font-semibold pb-8">
              Monthly contributions
            </h3>
            <p className="text-brand-secondary block">
              Percentage left to allocate: {leftToAllocate}%
            </p>
            <Controller
              control={control}
              name="globalCashFund"
              render={({ field: { value, onChange } }) => (
                <EditablePortfolioCard
                  recommendedFund={PrsaFund.GlobalCashFund}
                  value={value}
                  onChange={onChange}
                  leftToAllocate={leftToAllocate}
                />
              )}
            />
            <Controller
              control={control}
              name="pensionPortfolio2"
              render={({ field: { value, onChange } }) => (
                <EditablePortfolioCard
                  recommendedFund={PrsaFund.PensionPortfolio_2}
                  value={value}
                  onChange={onChange}
                  leftToAllocate={leftToAllocate}
                />
              )}
            />
            <Controller
              control={control}
              name="pensionPortfolio3"
              render={({ field: { value, onChange } }) => (
                <EditablePortfolioCard
                  recommendedFund={PrsaFund.PensionPortfolio_3}
                  value={value}
                  onChange={onChange}
                  leftToAllocate={leftToAllocate}
                />
              )}
            />
            <Controller
              control={control}
              name="pensionPortfolio4"
              render={({ field: { value, onChange } }) => (
                <EditablePortfolioCard
                  recommendedFund={PrsaFund.PensionPortfolio_4}
                  value={value}
                  onChange={onChange}
                  leftToAllocate={leftToAllocate}
                />
              )}
            />
            <Controller
              control={control}
              name="pensionPortfolio5"
              render={({ field: { value, onChange } }) => (
                <EditablePortfolioCard
                  recommendedFund={PrsaFund.PensionPortfolio_5}
                  value={value}
                  onChange={onChange}
                  leftToAllocate={leftToAllocate}
                />
              )}
            />
            <Controller
              control={control}
              name="pensionPortfolio6"
              render={({ field: { value, onChange } }) => (
                <EditablePortfolioCard
                  recommendedFund={PrsaFund.PensionPortfolio_6}
                  value={value}
                  onChange={onChange}
                  leftToAllocate={leftToAllocate}
                />
              )}
            />
          </Card>
          <Button
            onClick={handleSubmit(onSubmit)}
            className="mt-24 sm:mt-53 text-t18"
            size="sm"
            disabled={leftToAllocate !== 0}
          >
            Continue
          </Button>
        </>
      )}
    </PensionsPageTemplate>
  );
};

export { AllocatePensionContributions };
