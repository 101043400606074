import { SirenOutlineIcon, useAuth, NestOutlineIcon } from "@multiply/lib";
import { Link } from "react-router-dom";
import {
  EmergencyFundSummary,
  RecommendationType,
  RetirementSummary,
  useGetPlanRecommendationsQuery,
} from "../../graphqlTypes";
import { GoalProgress } from "../GoalProgress";

type OtherProgressCardGroupProps = {
  className?: string;
};

const OtherProgressCardGroup = ({ className }: OtherProgressCardGroupProps) => {
  const { userId, loggedIn } = useAuth();
  const [planResult] = useGetPlanRecommendationsQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const recommendationGroups =
    planResult?.data?.plan?.recommendationGroups ?? [];

  // Emergency fund variables
  const emergencyFundRecommendation = recommendationGroups.find(
    (group) => group.recommendationType === RecommendationType.EmergencyFund
  );
  const emergencyFundSummary = emergencyFundRecommendation?.summary as
    | EmergencyFundSummary
    | null
    | undefined;

  const emergencyFundCurrentProvision =
    emergencyFundSummary?.emergencyFundProgress.currentProvision.display;
  const emergencyFundProgress =
    emergencyFundSummary?.emergencyFundProgress.progress;

  //Retirement variables
  const retirementIncomeRecommendation = recommendationGroups.find(
    (group) => group.recommendationType === RecommendationType.Retirement
  );
  const retirementSummary = retirementIncomeRecommendation?.summary as
    | RetirementSummary
    | null
    | undefined;

  const retirementIncomeCurrentProvision =
    retirementSummary?.targetProvision.display;
  const retirementIncomeCurrentProvisionFloat = retirementSummary
    ?.currentProvision.float as number;
  const retirementIncomeProgressFloat = retirementSummary?.targetProvision
    ?.float as number;

  return (
    <div className="flex flex-col">
      <div className="flex sm:flex-col gap-12 w-full overflow-x-auto sm:overflow-x-hidden no-scrollbar shadow-none max-h-240 sm:max-h-full">
        <Link key="emergency-fund" to="/advice/plan/emergency-fund">
          <GoalProgress
            title="Emergency fund"
            subtitle={
              emergencyFundCurrentProvision
                ? `${emergencyFundCurrentProvision} saved`
                : ""
            }
            loading={!emergencyFundCurrentProvision}
            progress={emergencyFundProgress ?? 0}
            icon={<SirenOutlineIcon size="lg" variant="green" />}
            className="flex w-full max-w-280 sm:max-w-full bg-input-background rounded-lg"
          />
        </Link>

        <Link key="retirement-income" to="/advice/plan/retirement">
          <GoalProgress
            title="Retirement income"
            subtitle={
              retirementIncomeCurrentProvision
                ? `${retirementIncomeCurrentProvision} per month projected`
                : ""
            }
            loading={!retirementIncomeCurrentProvision}
            progress={
              retirementIncomeCurrentProvisionFloat
                ? Math.min(
                    (100 * retirementIncomeCurrentProvisionFloat) /
                      retirementIncomeProgressFloat,
                    100
                  )
                : 0
            }
            icon={<NestOutlineIcon size="lg" variant="green" />}
            className="flex w-full max-w-280 sm:max-w-full bg-input-background rounded-lg"
          />
        </Link>
      </div>

      <p className="text-t14 sm:text-t16 text-brand-secondary mt-16">
        These are goals we've created to help give you financial security.
      </p>
    </div>
  );
};

export { OtherProgressCardGroup };
