import classNames from "classnames";
import { FocusEventHandler } from "react";
import { Button, useWindowDimensions } from "@multiply/lib";

type Variant =
  | "stacked"
  | "wrapped"
  | "stacked-end"
  | "stacked-full"
  | "wrapped-end"
  | "auto"
  | "auto-end"
  | "auto-start";

type CheckboxButtonGroupProps<ValueType extends string | number | boolean> = {
  value: ValueType[];
  onChange: (value: ValueType[]) => void;
  onBlur?: () => void;
  options: Array<{
    label: string;
    value: ValueType;
  }>;
  className?: string;
  variant?: Variant;
};

function CheckboxButtonGroup<ValueType extends string | number | boolean>({
  value,
  onChange,
  onBlur,
  options,
  className,
  variant: defaultVariant = "auto",
}: CheckboxButtonGroupProps<ValueType>) {
  const { isPhone } = useWindowDimensions();

  const handleChange = (selectedValue: ValueType) => {
    if (value.includes(selectedValue)) {
      const newValue = value.filter((v) => v !== selectedValue);
      onChange(newValue);
    } else {
      const newValue = [...value, selectedValue];
      onChange(newValue);
    }
  };

  const handleBlur: FocusEventHandler<HTMLDivElement> = (event) => {
    // trigger blur when focus is moving outside of the fieldset
    if (!event.currentTarget.contains(event.relatedTarget)) {
      onBlur && onBlur();
    }
  };

  const getVariant = (variant: Variant): Variant => {
    switch (variant) {
      case "auto":
      case "auto-start":
        return options.length > 4 ? "wrapped" : "stacked";
      case "auto-end":
        return options.length > 4 ? "wrapped-end" : "stacked-end";
      default:
        return variant;
    }
  };

  const variant = getVariant(defaultVariant);

  const getVariantClasses = (variant: Variant) => {
    switch (variant) {
      case "stacked":
        return "flex-col items-start";
      case "wrapped":
        return "flex-wrap";
      case "stacked-end":
        return "flex-col items-end";
      case "stacked-full":
        return "flex-col items-stretch";
      case "wrapped-end":
        return "flex-wrap justify-end";
    }
  };

  return (
    <div
      className={classNames(
        "flex gap-x-12 w-full mb-16",
        getVariantClasses(variant),
        className
      )}
      onBlur={handleBlur}
    >
      {options.map((option, index) => {
        const lastItem = index === options.length - 1;
        return (
          <Button
            key={String(option.value)}
            size={isPhone ? "sm" : "md"}
            variant={value.includes(option.value) ? "secondary" : "ghost"}
            onClick={() => handleChange(option.value)}
            onBlur={onBlur}
            className={classNames(
              "text-t16 sm:text-t18 p-8 h-fit min-h-[3em]",
              `${variant.includes("full") ? "w-full" : "w-fit"}`,
              `${variant.includes("stacked") ? "flex-1" : ""}`,
              { "mb-16": !lastItem }
            )}
          >
            {option.label}
          </Button>
        );
      })}
    </div>
  );
}

export { CheckboxButtonGroup };
