import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";
import {
  IconProps,
  getDimensionsFromSize,
  getColorFromVariant,
} from "./shared";

const SlidersIcon: FC<IconProps> = ({ size, variant, className, ...props }) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);

  return (
    <svg
      aria-hidden={true}
      data-testid="sprout icon"
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.666 6a2 2 0 0 0-1.88 1.333H2a.667.667 0 1 0 0 1.334h8.786A2 2 0 1 0 12.666 6ZM2 4.667h.786a2 2 0 0 0 3.76 0H14a.667.667 0 1 0 0-1.334H6.546a2 2 0 0 0-3.76 0H2a.667.667 0 1 0 0 1.334Zm12 6.666H9.213a2 2 0 0 0-3.76 0H2a.667.667 0 1 0 0 1.334h3.453a2 2 0 0 0 3.76 0H14a.666.666 0 1 0 0-1.334Z"
        fill={fill}
      />
    </svg>
  );
};

export { SlidersIcon };
