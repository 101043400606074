import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export type SignupFormValues = {
  email: string;
  password: string;
  termsBusiness: boolean;
  privacyPolicy: boolean;
  offers?: boolean;
};

const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
export const defaultSignUpValidationSchema: yup.SchemaOf<SignupFormValues> = yup
  .object()
  .shape({
    email: yup
      .string()
      .required("Please provide an email")
      .email("Please enter a valid email")
      .nullable(),
    password: yup
      .string()
      .required("Please provide a password")
      .matches(passwordRegex, "Please enter a valid password")
      .nullable(),
    termsBusiness: yup
      .bool()
      .oneOf([true])
      .required("You must agree to the terms")
      .nullable(),
    privacyPolicy: yup
      .bool()
      .oneOf([true])
      .required("You must agree to the terms")
      .nullable(),
    offers: yup.bool().nullable(),
  })
  .defined();

type UseSignupFormArgs = {
  defaultValues?: Partial<SignupFormValues>;
  validationSchema?: yup.SchemaOf<SignupFormValues>;
};

const useSignupForm = (args?: UseSignupFormArgs) => {
  const defaultValues = args?.defaultValues;
  const validationSchema =
    args?.validationSchema ?? defaultSignUpValidationSchema;

  const form = useForm<SignupFormValues>({
    defaultValues: {
      email: defaultValues?.email ?? "",
      password: defaultValues?.password ?? "",
      termsBusiness: defaultValues?.termsBusiness ?? false,
      privacyPolicy: defaultValues?.privacyPolicy ?? false,
      offers: defaultValues?.offers ?? false,
    },
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  return form;
};

export { useSignupForm };
