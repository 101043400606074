import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";
import {
  IconProps,
  getDimensionsFromSize,
  getColorFromVariant,
} from "./shared";

const BookOutlineIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);

  return (
    <svg
      aria-label={props["aria-label"] ?? "book outline icon"}
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 2v-.615h-.615V2H3Zm0 12h-.615v.615H3V14Zm10.154 0v.615h.615V14h-.615ZM12.077 2.77l-.394.472c.012.01.024.02.036.028l.358-.5ZM2.385 2v12h1.23V2h-1.23ZM3 14.615h10.154v-1.23H3v1.23ZM13.77 14V4.615h-1.231V14h1.23Zm-.616-9.385h.615v-.032l-.003-.057a2.925 2.925 0 0 0-.169-.758 3.19 3.19 0 0 0-1.162-1.5l-.716 1.002c.421.3.623.653.723.924a1.688 1.688 0 0 1 .097.43v-.008h.615Zm-.683-2.319A4.002 4.002 0 0 0 10.88 1.5a4.036 4.036 0 0 0-.777-.114h-.025L10.076 2v.615h-.004.002a2.809 2.809 0 0 1 .507.078c.322.08.73.24 1.101.549l.788-.946Zm-2.394-.911H3v1.23h7.077v-1.23Z"
        fill={fill}
      />
      <path stroke={fill} strokeWidth={1.231} d="M4.846 5.692h5.539" />
    </svg>
  );
};

export { BookOutlineIcon };
