import classNames from "classnames";
import {
  addMonths,
  eachDayOfInterval,
  eachHourOfInterval,
  endOfDay,
  endOfMonth,
  format,
  isWithinInterval,
  startOfDay,
  startOfMonth,
} from "date-fns";
import { useState } from "react";
import { Caret } from "./Caret";
import { DayPicker } from "./DayPicker";
import { TimePicker } from "./TimePicker";

type RenderDaysArgs = {
  start: Date;
  end: Date;
  value: Date | null;
  onChange: (value: Date | null) => void;
};

type RenderTimesArgs = {
  start: Date;
  end: Date;
  value: Date | null;
  onChange: (value: Date | null) => void;
};

const defaultRenderDays = ({ start, end, value, onChange }: RenderDaysArgs) => {
  return (
    <DayPicker
      value={value}
      onChange={onChange}
      days={eachDayOfInterval({
        start,
        end,
      })}
      start={start}
      end={end}
    />
  );
};

export type DateTimePickerProps = {
  value?: Date | null;
  startDate?: Date;
  onChange: (value: Date) => void;
  className?: string;
  dateLabel?: string;
  renderDays?: (args: RenderDaysArgs) => React.ReactNode;
  renderTimes?: (args: RenderDaysArgs) => React.ReactNode;
};

const defaultRenderTimes = ({
  start,
  end,
  value,
  onChange,
}: RenderTimesArgs) => {
  return (
    <TimePicker
      value={value}
      onChange={onChange}
      times={eachHourOfInterval({
        start,
        end,
      })}
    />
  );
};

const DateTimePicker = (props: DateTimePickerProps) => {
  const {
    className,
    startDate = new Date(),
    value,
    onChange,
    dateLabel,
    renderDays = defaultRenderDays,
    renderTimes = defaultRenderTimes,
  } = props;

  const [dateToView, setDateToView] = useState(
    new Date(
      (startOfMonth(startDate).getTime() + endOfMonth(startDate).getTime()) / 2
    )
  );

  const windowStart = startOfMonth(dateToView);
  const windowEnd = endOfMonth(dateToView);

  const [selectedDay, setSelectedDay] = useState(
    value ? startOfDay(value) : null
  );

  const [selectedTime, setSelectedTime] = useState(
    value ? startOfDay(value) : null
  );

  const handleAddMonth = () => {
    setDateToView((date) => addMonths(date, 1));
  };

  const handleSubMonth = () => {
    setDateToView((date) => addMonths(date, -1));
  };

  const handleChangeTime = (value: Date | null) => {
    setSelectedTime(value);
    if (value) {
      onChange(value);
    }
  };

  return (
    <section className={classNames(className)}>
      <div className="w-full max-w-340">
        {dateLabel && (
          <h1 className="text-font-primary font-normal text-t21 sm:text-t27 mb-40">
            {dateLabel}
          </h1>
        )}
        <div>
          <div className="font-semibold flex self-stretch">
            <button
              onClick={handleSubMonth}
              aria-label="previous month"
              className="p-8"
            >
              <Caret />
            </button>

            <div className="flex-1 text-t21 text-font-primary flex items-center justify-center">
              {format(dateToView, "MMMM yyyy")}
            </div>

            <button
              onClick={handleAddMonth}
              aria-label="next month"
              className="p-8"
            >
              <Caret className="rotate-180" />
            </button>
          </div>

          {renderDays({
            start: windowStart,
            end: windowEnd,
            value: selectedDay,
            onChange: setSelectedDay,
          })}
        </div>
      </div>

      {selectedDay &&
        isWithinInterval(selectedDay, {
          start: windowStart,
          end: windowEnd,
        }) &&
        renderTimes({
          start: startOfDay(selectedDay),
          end: endOfDay(selectedDay),
          value: selectedTime,
          onChange: handleChangeTime,
        })}
    </section>
  );
};

export { DateTimePicker };
