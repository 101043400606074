import classNames from "classnames";
import { Link } from "react-router-dom";

type ConfirmDialogueProps = {
  className?: string;
  title: React.ReactNode;
  confirmCta: string;
  cancelCta: string;
  error?: string;
};

interface CardWithConfirmLinkProps extends ConfirmDialogueProps {
  confirmTo: string;
  onClickConfirm?: undefined;
}

interface CardWithConfirmButtonProps extends ConfirmDialogueProps {
  onClickConfirm: () => void;
  confirmTo?: undefined;
}

interface CardWithCancelLinkProps extends ConfirmDialogueProps {
  cancelTo: string;
  onClickCancel?: undefined;
}

interface CardWithCancelButtonProps extends ConfirmDialogueProps {
  onClickCancel: () => void;
  cancelTo?: undefined;
}

const ConfirmDialogue = ({
  className,
  title,
  confirmCta,
  cancelCta,
  confirmTo,
  cancelTo,
  onClickConfirm,
  onClickCancel,
  error,
}: (CardWithConfirmButtonProps | CardWithConfirmLinkProps) &
  (CardWithCancelLinkProps | CardWithCancelButtonProps)) => {
  return (
    <div
      className={classNames(
        className,
        "w-full h-full flex flex-col items-center pt-80 pb-120"
      )}
    >
      <div>
        <h1 className="text-t27 text-font-primary pb-16 text-center">
          {title}
        </h1>
        <div className="flex flex-col text-center items-center gap-24 mt-40">
          {confirmTo ? (
            <Link
              className="py-16 rounded-lg border-[1px] border-input-border-inner-border text-t21 text-font-primary w-216"
              to={confirmTo}
            >
              {confirmCta}
            </Link>
          ) : (
            <button
              onClick={onClickConfirm}
              className="py-16 rounded-lg border-[1px] border-input-border-inner-border text-t21 text-font-primary w-216"
            >
              {confirmCta}
            </button>
          )}

          {cancelTo ? (
            <Link
              className="py-16 rounded-lg border-[1px] border-input-border-inner-border text-t21 text-font-primary w-216"
              to={cancelTo}
            >
              {cancelCta}
            </Link>
          ) : (
            <button
              onClick={onClickCancel}
              className="py-16 rounded-lg border-[1px] border-input-border-inner-border text-t21 text-font-primary w-216"
            >
              {cancelCta}
            </button>
          )}
          <p className="text-action-error">{error}</p>
        </div>
      </div>
    </div>
  );
};

export { ConfirmDialogue };
