import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";
import {
  IconProps,
  getDimensionsFromSize,
  getColorFromVariant,
} from "./shared";

const LinkIcon: FC<IconProps> = ({ size, variant, className, ...props }) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);

  return (
    <svg
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 14"
      data-testid="link icon"
      fill="none"
      {...props}
    >
      <path
        d="M6.94967 8.94843C6.79694 9.01137 6.6755 9.13246 6.61212 9.28501C6.54874 9.43756 6.54863 9.60905 6.6118 9.76168C6.70813 9.99426 6.65484 10.262 6.47678 10.4399L4.71712 12.2002C4.23116 12.686 3.44342 12.686 2.95746 12.2002L1.6371 10.8804C1.40361 10.6471 1.27243 10.3304 1.27243 10.0003C1.27243 9.67017 1.40361 9.35356 1.6371 9.12017L3.39925 7.36051C3.57837 7.18418 3.84513 7.13112 4.07809 7.22549C4.3958 7.35659 4.75962 7.20532 4.89072 6.88762C5.02182 6.56992 4.87055 6.20609 4.55285 6.07499C3.85479 5.79257 3.05565 5.952 2.51942 6.48068L0.757272 8.24034C-0.21271 9.21318 -0.21271 10.7874 0.757272 11.7603L2.07764 13.08C3.04955 14.0516 4.62503 14.0516 5.59695 13.08L7.35723 11.3204C7.89017 10.7859 8.0499 9.98354 7.7623 9.28568C7.63099 8.96819 7.2672 8.81722 6.94967 8.94843Z"
        fill={fill}
      />
      <path
        d="M4.05718 9.77967C4.30168 10.0189 4.6925 10.0189 4.93701 9.77967L9.77731 4.93999C10.0131 4.69584 10.0097 4.30776 9.76973 4.06774C9.52972 3.82773 9.14163 3.82436 8.89748 4.06016L4.05718 8.89984C3.81427 9.14282 3.81427 9.53669 4.05718 9.77967Z"
        fill={fill}
      />
      <path
        d="M12.637 2.52019L11.3173 1.20045C10.3445 0.230466 8.7702 0.230466 7.79736 1.20045L6.03708 2.9601C5.50409 3.49433 5.34434 4.29651 5.63201 4.99416C5.71693 5.19968 5.90504 5.34428 6.12548 5.3735C6.34592 5.40271 6.56521 5.31211 6.70073 5.13581C6.83625 4.95951 6.86743 4.7243 6.78251 4.51878C6.68729 4.2869 6.74052 4.02053 6.91753 3.84304L8.67719 2.08339C8.91058 1.8499 9.22719 1.71872 9.55733 1.71872C9.88746 1.71872 10.2041 1.8499 10.4375 2.08339L11.7572 3.40313C11.9907 3.63652 12.1219 3.95313 12.1219 4.28327C12.1219 4.61341 11.9907 4.93001 11.7572 5.16341L9.99506 6.91996C9.81578 7.09599 9.54921 7.14901 9.31621 7.05498C8.99851 6.92353 8.63441 7.07452 8.50296 7.39222C8.37152 7.70993 8.52251 8.07403 8.84021 8.20548C9.53837 8.48997 10.3389 8.3306 10.8749 7.80041L12.6345 6.04075C13.6053 5.06838 13.6064 3.49393 12.637 2.52019Z"
        fill={fill}
      />
    </svg>
  );
};

export { LinkIcon };
