import { DateTimePicker } from "@multiply/lib";
import classNames from "classnames";
import { AdviserDayPicker } from "./AdviserDayPicker";
import { AdviserTimePicker } from "./AdviserTimePicker";

type InputAdviserDateProps = {
  value: Date | null;
  onChange: (value: Date) => void;
  className?: string;
  dateLabel?: string;
  timeLabel?: string;
};

const InputAdviserDate = ({
  value,
  onChange,
  className,
  dateLabel,
  timeLabel,
}: InputAdviserDateProps) => {
  return (
    <DateTimePicker
      value={value}
      onChange={onChange}
      className={classNames("w-full gap-16 flex flex-wrap", className)}
      dateLabel={dateLabel}
      renderDays={({ value, onChange, start, end }) => {
        return (
          <AdviserDayPicker
            value={value}
            onChange={onChange}
            start={start}
            end={end}
          />
        );
      }}
      renderTimes={({ value, onChange, start, end }) => {
        return (
          <AdviserTimePicker
            className="basis-[min-content] grow"
            value={value}
            onChange={onChange}
            start={start}
            end={end}
            label={timeLabel}
          />
        );
      }}
    />
  );
};

export { InputAdviserDate };
