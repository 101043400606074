import {
  ChartCircleIcon,
  NestCircleIcon,
  SproutCircleIcon,
  WalletCircleIcon,
  useWindowDimensions,
} from "@multiply/lib";
import bannerDesktop from "../../assets/images/holistic-banner.png";
import bannerMobile from "../../assets/images/holistic-plan.png";
import { PlanOverviewLayout } from "../../components/PlanOverviewLayout";

const HolisticPlan = () => {
  const { isLessThanCustomWidth } = useWindowDimensions(730);
  return (
    <PlanOverviewLayout
      bannerImg={isLessThanCustomWidth ? bannerMobile : bannerDesktop}
      title={
        <>
          Get a full <br /> financial review
        </>
      }
      subtitle="Get advice on all aspects of your finances"
      highlights={[
        {
          icon: <WalletCircleIcon variant="icon" size="xxl" />,
          title: "Covering the basics",
          description:
            "Manage any debts, be ready for emergencies and protect what matters.",
        },
        {
          icon: <SproutCircleIcon variant="icon" size="xxl" />,
          title: "Achieving your goals",
          description:
            "Set financial goals and access personalised support to achieve them.",
        },
        {
          icon: <ChartCircleIcon variant="icon" size="xxl" />,
          title: "Investing with confidence",
          description:
            "Discover when, where and how best to invest with our experts.",
        },
        {
          icon: <NestCircleIcon variant="icon" size="xxl" />,
          title: "Planning for retirement",
          description:
            "Visualise your retirement goals and put a plan in place to reach them.",
        },
      ]}
      suitableFor="Anyone aged 18-59 planning their finances, by themselves or with a partner."
    />
  );
};

export { HolisticPlan };
