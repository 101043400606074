type PlusProps = {
  className?: string;
  onClick: () => void;
};

const Plus = ({ className, onClick }: PlusProps) => {
  return (
    <div onClick={onClick}>
      <svg
        width="49"
        height="49"
        viewBox="0 0 49 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M0.289062 0.973633H24.2891C37.5439 0.973633 48.2891 11.7188 48.2891 24.9736V24.9736C48.2891 38.2285 37.5439 48.9736 24.2891 48.9736H0.289062V0.973633Z"
          fill="#717FE2"
        />
        <path
          d="M31.2891 24.9736H17.2891"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.2891 31.9736L24.2891 17.9736"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Plus;
