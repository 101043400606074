import { Card, DottedSquareIcon, useAuth } from "@multiply/lib";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useGetAssetsQuery } from "../../graphqlTypes";
import { convertNumberToEuroString } from "../../utils";

type YourPensionsProps = {
  className?: string;
  title?: string;
};

const YourPensions = ({ className, title }: YourPensionsProps) => {
  const { userId } = useAuth();
  const [assetsResult] = useGetAssetsQuery({
    variables: { userId: userId ?? "" },
  });

  const allAssets = assetsResult?.data?.assets ?? [];

  const pensions = allAssets.filter(
    (asset) => asset?.productType === "PENSION"
  );

  const balance = pensions.reduce(
    (total, pension) => total + (pension?.balance?.float ?? 0),
    0
  );

  return (
    <div className={classNames("flex flex-col", className)}>
      {title && (
        <h2 className="text-t16 sm:text-t21 text-font-primary mb-12 sm:mb-32">
          {title}
        </h2>
      )}

      <Card className="flex justify-between p-32 flex-1">
        {balance ? (
          <div>
            <p className="text-font-secondary text-t16 sm:text-t27">
              Total fund
            </p>
            <p className="text-font-primary font-bold text-t21 sm:text-t34">
              {convertNumberToEuroString(balance)}
            </p>
          </div>
        ) : (
          <div className="flex items-center gap-16">
            <DottedSquareIcon />
            <p className="text-label-primary text-t16 sm:text-t21">
              You have no pensions.
            </p>
          </div>
        )}

        <Link
          to="/pensions"
          className="text-t16 sm:text-t21 text-font-links ml-32 self-start"
          aria-label="Edit your pension details"
        >
          {`${balance ? "View" : "Add"} pensions`}
        </Link>
      </Card>
    </div>
  );
};
export { YourPensions };
