import { intervalToDuration } from "date-fns";

const dateToAge = (date: string) => {
  const stringToDate = Date.parse(date);

  return (
    intervalToDuration({
      start: new Date(stringToDate),
      end: new Date(),
    }).years ?? 0
  );
};

export { dateToAge };
