import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";
import {
  IconProps,
  getDimensionsFromSize,
  getColorFromVariant,
} from "./shared";
import { useId } from "../../hooks";

const GraduateCapOutlineIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);
  const clipPathId = useId();

  return (
    <svg
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${clipPathId})`}>
        <path
          d="M9.12 2.919a2.616 2.616 0 0 0-2.24 0L.383 5.997a.667.667 0 0 0-.381.638v4.034a.667.667 0 1 0 1.333 0V7.675l1.334.66v3.667a.667.667 0 0 0 .196.473l.033.032.08.073c.418.372.87.705 1.35.993.89.536 2.177 1.096 3.674 1.096 1.497 0 2.784-.56 3.676-1.096.48-.288.931-.62 1.35-.993l.08-.073.03-.032h.003a.667.667 0 0 0 .194-.474V8.335l2.296-1.14a.666.666 0 0 0-.01-1.2l-6.5-3.078v.002Zm.044 7.486L12 8.997v2.71c-.316.268-.654.51-1.01.723-.773.465-1.82.906-2.99.906-1.17 0-2.216-.44-2.99-.905A7.654 7.654 0 0 1 4 11.707v-2.71l2.837 1.408a2.621 2.621 0 0 0 2.326 0ZM8.55 4.124l5.256 2.49L8.57 9.21a1.283 1.283 0 0 1-1.138 0L2.196 6.613l5.256-2.49a1.283 1.283 0 0 1 1.098 0Zm-5.684 8.353-.001-.001.001.001Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id={clipPathId}>
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { GraduateCapOutlineIcon };
