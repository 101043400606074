import { Button, Card, useAuth } from "@multiply/lib";
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteGoalMutation } from "../../graphqlTypes";

const DeleteGoal = () => {
  const { goalId } = useParams();
  const { userId } = useAuth();
  const navigate = useNavigate();
  const [deleteGoalResult, deleteGoal] = useDeleteGoalMutation();

  const error = deleteGoalResult.error;

  const onDelete = async () => {
    try {
      await deleteGoal({
        input: {
          userId: userId ?? "",
          goalId,
        },
      });
    } catch (error) {}
    navigate(-1);
  };

  return (
    <Card
      variant="flat"
      className="w-full min-h-320 flex flex-col space-y-56 sm:px-40"
    >
      <h1
        id="delete-goal"
        className="text-t20 sm:text-t27 text-font-primary text-center mt-32 sm:mt-60"
      >
        Are you sure you want to delete this goal?
      </h1>
      <section className="flex flex-col items-center sm:flex-row sm:w-fit sm:mx-auto gap-24">
        <Button
          disabled={deleteGoalResult.fetching}
          onClick={onDelete}
          variant="ghost"
        >
          Yes, delete goal
        </Button>
        <Button onClick={() => navigate(-1)} variant="ghost">
          No, keep goal
        </Button>
      </section>

      {error ? (
        <p className="text-action-error text-center">{error.message}</p>
      ) : null}
    </Card>
  );
};

export { DeleteGoal };
