import { DayPicker, useAuth } from "@multiply/lib";
import { useGetAvailableAppointmentDaysQuery } from "../../graphqlTypes";

const AdviserDayPicker = ({
  start,
  end,
  value,
  onChange,
  dateLabel,
}: {
  start: Date;
  end: Date;
  value: Date | null;
  onChange: (value: Date | null) => void;
  dateLabel?: string;
}) => {
  const { userId } = useAuth();

  // start and end date don't actually do anything in this api but are required
  // we set them to a static value to prevent cache misses
  const [availableAppointmentDaysResult] = useGetAvailableAppointmentDaysQuery({
    variables: {
      userId: userId ?? null,
      startDate: "2022-01-01",
      endDate: "2022-01-01",
    },
  });

  const days =
    availableAppointmentDaysResult.data?.availableAppointmentDays.map(
      (appointment) => new Date(appointment.date)
    ) ?? [];

  return (
    <DayPicker
      loading={availableAppointmentDaysResult.fetching}
      value={value}
      onChange={onChange}
      start={start}
      end={end}
      days={days}
      className="w-full flex justify-center"
      label={dateLabel}
    />
  );
};

export { AdviserDayPicker };
