import classNames from "classnames";
import { ComponentProps } from "react";
import { convertNumberToEuroString } from "../../utils";

type PensionTooltipProps = {
  active: boolean;
  payload: object[];
  plotLabel: string;
  retirementAge: number | null;
} & ComponentProps<any>;

const getDetailsFromPayload = (payload: any[], plotLabel: string) => {
  const searchPayload = payload.find((item) => item.dataKey === plotLabel);
  if (searchPayload) {
    const { statePensionAmount, totalPensionAmount, targetIncome, age } =
      searchPayload.payload;

    return {
      age,
      value: searchPayload.value.toFixed(0),
      gap: targetIncome
        ? parseInt(
            (
              targetIncome.float -
              (statePensionAmount.float + totalPensionAmount.float)
            ).toFixed(0)
          )
        : 0,
    };
  }
  return { age: undefined, value: undefined, gap: undefined };
};

const getValues = ({
  payload,
  plotLabel,
  retirementAge,
}: PensionTooltipProps) => {
  const { age, value, gap } = getDetailsFromPayload(
    payload,
    plotLabel ?? "targetIncome.float"
  );

  switch (plotLabel) {
    case "statePensionAmount.float":
      return {
        label: "State pension",
        age,
        value,
      };
    case "personalPensionAmount.float":
      return {
        label: "Your pensions",
        age,
        value,
      };

    case null:
    case "targetIncome.float":
      if (age >= retirementAge) {
        return {
          label: "Target value",
          age,
          value,
          gap,
        };
      }
      return {
        label: "",
      };

    case "employmentIncome.float":
    default:
      return {
        label: "",
      };
  }
};

const PensionTooltip = ({
  active,
  payload,
  plotLabel,
  retirementAge,
}: PensionTooltipProps) => {
  if (active && payload && payload.length) {
    const { label, age, value, gap } = getValues({
      payload,
      plotLabel,
      retirementAge,
    });

    return (
      <>
        {label !== "" && (
          <div
            className={classNames(
              "bg-white/95 rounded ring-2 p-8 text-t10 text-font-primary shadow-md",
              {
                "ring-brand-900": plotLabel === "statePensionAmount.float",
                "ring-accent-primary":
                  plotLabel === "personalPensionAmount.float",
                "ring-font-error":
                  plotLabel !== "statePensionAmount.float" &&
                  plotLabel !== "personalPensionAmount.float",
              }
            )}
          >
            <p className="text-center font-semibold">{label}</p>
            <div className="grid grid-cols-2">
              <p className="">Age</p>
              <p>{age}</p>

              <p className="pr-6">Amount</p>
              <p>{value && convertNumberToEuroString(value)}</p>

              {gap && (
                <>
                  <p className="pr-6">Gap</p>
                  <p>{gap && convertNumberToEuroString(gap < 0 ? 0 : gap)}</p>
                </>
              )}
            </div>
          </div>
        )}
      </>
    );
  }

  return null;
};
export { PensionTooltip };
