import classNames from "classnames";
import { useCombobox } from "downshift";
import Fuse from "fuse.js";
import { useMemo, useState } from "react";
import {
  itemToKey as defaultItemToKey,
  itemToString as defaultItemToString,
} from "../../utils";
import { Card } from "../Card";
import { Input } from "../Input";

type ComboboxProps<Item> = {
  value: Item | null | undefined;
  onChange?: (item: Item | null | undefined) => void;
  onSubmit?: () => void;
  items: Item[];
  itemToString?: (item: Item | null) => string;
  itemToKey?: (item: Item | null) => string;
  searchKeys?: string[];
  placeholder?: string;
  label?: React.ReactNode;
  error?: string;
  className?: string;
  disabled?: boolean;
};

function Combobox<Item>({
  value,
  onChange,
  onSubmit,
  items,
  itemToString = defaultItemToString,
  itemToKey = defaultItemToKey,
  searchKeys = ["label"],
  placeholder,
  label,
  error,
  className,
  disabled,
}: ComboboxProps<Item>) {
  const [inputValue, setInputValue] = useState<string | null | undefined>(null);
  const searchableList = useMemo(
    () =>
      new Fuse(items, {
        keys: searchKeys,
        getFn: itemToString,
      }),
    [itemToString, items, searchKeys]
  );

  const filteredItems = useMemo(
    () =>
      inputValue
        ? searchableList
            .search(inputValue)
            ?.map((searchResult) => searchResult.item)
        : items,
    [inputValue, items, searchableList]
  );

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    defaultSelectedItem: value,
    items: filteredItems,
    itemToString,
    onInputValueChange: ({ inputValue }) => {
      setInputValue(inputValue);
    },
    onSelectedItemChange: ({ selectedItem }) => {
      onChange && onChange(selectedItem);
      onSubmit && onSubmit();
    },
  });

  const inputProps = getInputProps();
  const errorMessageId = `${inputProps.id}-errormessage`;

  return (
    <div role="group" className={classNames("relative", className)}>
      {label && (
        <label
          className="text-input-label text-t16 font-semibold"
          {...getLabelProps()}
        >
          {label}
        </label>
      )}
      <div className="mt-4" aria-label={label} {...getComboboxProps()}>
        <Input
          disabled={disabled}
          {...inputProps}
          onSubmit={onSubmit}
          placeholder={placeholder}
          aria-errormessage={errorMessageId}
        />
        <Card
          className={classNames(
            "absolute mt-1 z-30 min-w-full overflow-hidden bg-white",
            {
              hidden: !isOpen || disabled,
            }
          )}
        >
          <ul
            {...getMenuProps()}
            className="max-h-320 overflow-y-auto focus-visible:outline-none"
          >
            {isOpen &&
              filteredItems.map((item, index) => {
                return (
                  <li
                    className={classNames("p-16 text-font-primary", {
                      "bg-menu-active/50": highlightedIndex === index,
                    })}
                    key={itemToKey(item)}
                    {...getItemProps({ item, index })}
                  >
                    {itemToString(item)}
                  </li>
                );
              })}
          </ul>
        </Card>
        {error && (
          <span id={errorMessageId} className="text-action-error text-t16">
            {error}
          </span>
        )}
      </div>
    </div>
  );
}

export { Combobox };
