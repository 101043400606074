import isEqual from "lodash/merge";
import { PaymentFrequency } from "../../graphqlTypes";
import { Dropdown } from "@multiply/lib";

type InputFrequencyTypes = {
  value: PaymentFrequency | null;
  onChange: (value: PaymentFrequency | null) => void;
  onBlur: () => void;
  error?: string;
};

const InputFrequency = ({
  value,
  onChange,
  onBlur,
  error,
}: InputFrequencyTypes) => {
  const items = [
    { label: "Monthly", value: PaymentFrequency.Monthly },
    { label: "Quarterly", value: PaymentFrequency.Quarterly },
    { label: "Half-yearly", value: PaymentFrequency.Halfyearly },
    { label: "Yearly", value: PaymentFrequency.Yearly },
  ];

  const selectedValue =
    items.find((item) => isEqual(item.value, value)) ?? null;

  return (
    <Dropdown
      aria-label="dropdown to see list of frequency of payment"
      error={error}
      label="Payment Frequency"
      items={items}
      placeholder="Select frequency"
      value={selectedValue}
      onChange={(item) => {
        onChange(item?.value ?? null);
      }}
      onBlur={onBlur}
    />
  );
};

export { InputFrequency };
