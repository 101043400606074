import { FC } from "react";

type NetworkErrorProps = {
  error?: React.ReactNode;
};

const NetworkError: FC<NetworkErrorProps> = ({ error }) => {
  return (
    <main className="h-screen flex items-center justify-center">
      <h2>
        {process.env.NODE_ENV === "development" && error
          ? error
          : "Something went wrong, please try again later"}
      </h2>
    </main>
  );
};

export { NetworkError };
