import { useAuth } from "@multiply/lib";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppLoading } from "../AppLoading";

const SingleSignOn = () => {
  const { restoreSession, loginWithRedirect, handleRedirectResult } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const login = async () => {
      const sessionRestored = await restoreSession();
      if (sessionRestored) {
        return;
      }

      const authResult = await handleRedirectResult();
      if (authResult !== null) {
        if (authResult.postLoginRedirectUri) {
          // restore location user was trying to access before auth
          const parsedRedirectUri = new URL(authResult.postLoginRedirectUri);
          const redirectPath = parsedRedirectUri.href.replace(
            parsedRedirectUri.origin,
            ""
          );
          navigate(redirectPath, { replace: true });
        }
      } else {
        await loginWithRedirect();
      }
    };

    login();
  }, [handleRedirectResult, loginWithRedirect, navigate, restoreSession]);

  return <AppLoading />;
};

export { SingleSignOn };
