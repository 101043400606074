import { Card, DragHandle, Tooltip, useWindowDimensions } from "@multiply/lib";
import classNames from "classnames";
import { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Control, Controller, FieldArrayWithId, useController } from "react-hook-form";
import { GoalType } from "../../graphqlTypes";
import { GoalsFormValues } from "../../hooks";
import { getGoalTooltipLabelFromType } from "../../utils";
import { CurrencyInput } from "../CurrencyInput";
import { DateInput } from "../DateInput";
import { GoalIcon } from "../GoalIcon";
import { GoalsPriorityCounter } from "../GoalsPriorityCounter";
import { ModalNavLink } from "../ModalNavLink";
import { createPortal } from "react-dom";
import { EmbeddedCollegeCostsCalculator } from "../EmbeddedCollegeCostsCalculator";


type EditGoalCardProps = {
  control: Control<GoalsFormValues>;
  className?: string;
  maxIndex: number;
  goalType: GoalType;
  index: number;
  field: FieldArrayWithId<GoalsFormValues, "goals", "id">;
  onChangeIndex: (newIndex: number) => void;
  factFindCompleted?: boolean;
  goalName?: string;
};

type DragItem = { index: number; onChangeIndex: (newIndex: number) => void };

export const EditGoalCard = ({
  control,
  className,
  maxIndex,
  index,
  field,
  goalType,
  onChangeIndex,
  factFindCompleted = true,
  goalName,
}: EditGoalCardProps) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const [isCollegeCostsOpen, setIsCollegeCostsOpen] = useState(false);

  const { isLessThanCustomWidth } = useWindowDimensions(1200);

  const [{ isDragging }, dragRef] = useDrag<
    DragItem,
    void,
    { isDragging: boolean }
  >(
    () => ({
      type: "GoalCard",
      item: () => {
        return { index, onChangeIndex };
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [onChangeIndex, index]
  );

  const [{ isOver }, dropRef] = useDrop<DragItem, void, { isOver: boolean }>(
    () => ({
      accept: "GoalCard",
      drop: (item) => {
        item.onChangeIndex(index);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver() && monitor.getItem()?.index !== index,
      }),
    }),
    [index]
  );

  dragRef(dropRef(cardRef));

  const valueController = useController({control, name: `goals.${index}.valueToSave`})

  const modal = isCollegeCostsOpen ? createPortal(
    <div className="fixed inset-0 z-10 flex items-center justify-center">
      <div className="absolute inset-0 bg-black/50" onClick={() => setIsCollegeCostsOpen(false)} />
      
      <EmbeddedCollegeCostsCalculator goalId={field.goalId} goalName={goalName ?? "Goal name"} className="relative" currencySymbol="€" onComplete={(goal) => {
        valueController.field.onChange(goal?.valueToSave?.float)
        setIsCollegeCostsOpen(false)
      }} />
      
    </div>,
    document.body
  ) : null;

  if (isLessThanCustomWidth) {
    return (
      <>
      {modal}
      <Card
        ref={cardRef}
        testId={`goal-${index}`}
        className={classNames(
          "w-full flex flex-col justify-between py-32 px-16",
          className
        )}
      >
        <div className="flex items-center mb-24">
          <GoalsPriorityCounter
            value={index ?? 0}
            maxValue={maxIndex}
            onChange={onChangeIndex}
          />
          <div className="ml-24">
            <GoalIcon size="xl" goalType={goalType} />

            <h1 className="text-font-primary text-t21 sm:text-t27">
              {goalName ?? "Goal name"}
            </h1>

            <ModalNavLink
              to={`/cross/edit-goal-name/${field.goalId}`}
              className="text-font-secondary text-t12 sm:text-t16"
            >
              Edit name
            </ModalNavLink>
          </div>
        </div>

        <section className="flex flex-col sm:flex-row sm:items-end my-36">
          <Controller
            control={control}
            name={`goals.${index}.targetDate`}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <DateInput
                className="mb-18 sm:mb-0"
                onChange={onChange}
                label="Target date"
                value={value ?? ""}
                onBlur={onBlur}
                error={error?.message}
                format="MMYYYY"
              />
            )}
          />

          <Controller
            control={control}
            name={`goals.${index}.valueToSave`}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <div className="flex items-baseline">
                <CurrencyInput
                  className="sm:ml-8 w-full"
                  label={
                    <div
                      className="flex justify-between items-start"
                      onClick={(e) => e.preventDefault()}
                    >
                      {goalType === GoalType.BuyHome ? (
                        <p>Deposit & fees needed</p>
                      ) : goalType === GoalType.ChildEducation ? (
                        <div className="flex flex-col">
                          <p>Amount needed</p>
                          {!factFindCompleted && (
                            <button
                              onClick={() => setIsCollegeCostsOpen(true)}
                              className="text-font-links"
                            >
                              Help me calculate this
                            </button>
                          )}
                        </div>
                      ) : (
                        <p>Amount needed</p>
                      )}
                      {goalType !== GoalType.Custom &&
                        goalType !== GoalType.ChildEducation && (
                          <Tooltip>
                            {getGoalTooltipLabelFromType(goalType)}
                          </Tooltip>
                        )}
                    </div>
                  }
                  placeholder="Enter amount"
                  value={value ?? ""}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                />
              </div>
            )}
          />
        </section>

        <ModalNavLink
          to={`/cross/delete-goal/${field.goalId}`}
          className="text-action-error pr-24 h-fit sm:pr-32 pl-0 whitespace-nowrap"
        >
          Delete goal
        </ModalNavLink>
      </Card>
      </>
    );
  }

  return (
    <>
    {modal}
    <Card
      ref={cardRef}
      testId={`goal-${index}`}
      className={classNames(
        "flex pl-24 border-2 border-transparent border-dashed ",
        {
          "opacity-50": isDragging,
          "border-action-primary": isOver,
          "cursor-grabbing": isDragging,
        }
      )}
    >
      <DragHandle
        aria-label="Click and drag the drag handle to change the order of your goals"
        className={classNames("mr-16 shrink-0 cursor-grab self-center", {
          "cursor-grabbing": isDragging,
        })}
      />

      <GoalsPriorityCounter
        className="self-center"
        value={index ?? 0}
        maxValue={maxIndex}
        onChange={onChangeIndex}
      />

      <div className="flex-1 min-w-240 ml-60 my-36 mr-16">
        <GoalIcon size="xl" goalType={goalType} />

        <h1 className="text-font-primary text-t27 mt-12">
          {goalName ?? "Goal name"}
        </h1>

        <ModalNavLink
          to={`/cross/edit-goal-name/${field.goalId}`}
          className="text-font-secondary text-t16"
        >
          Edit name
        </ModalNavLink>
      </div>

      <section className="flex items-end my-36 space-x-60">
        <Controller
          control={control}
          name={`goals.${index}.valueToSave`}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error },
          }) => (
            <div className="flex items-baseline">
              <CurrencyInput
                label={
                  <div
                    className="flex justify-between items-start"
                    onClick={(e) => e.preventDefault()}
                  >
                    {goalType === GoalType.BuyHome ? (
                      <p>Deposit & fees needed</p>
                    ) : goalType === GoalType.ChildEducation ? (
                      <div className="flex flex-col">
                        <p>Amount needed</p>
                        {!factFindCompleted && (
                          <button
                            onClick={() => setIsCollegeCostsOpen(true)}
                            className="text-font-links"
                          >
                            Help me calculate this
                          </button>
                        )}
                      </div>
                    ) : (
                      <p>Amount needed</p>
                    )}
                    {goalType !== GoalType.Custom &&
                      goalType !== GoalType.ChildEducation && (
                        <Tooltip>
                          {getGoalTooltipLabelFromType(goalType)}
                        </Tooltip>
                      )}
                  </div>
                }
                onBlur={onBlur}
                error={error?.message}
                placeholder="Enter amount"
                value={value ?? ""}
                onChange={onChange}
              />
            </div>
          )}
        />

        <Controller
          control={control}
          name={`goals.${index}.targetDate`}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error },
          }) => (
            <DateInput
              className="w-min"
              onChange={onChange}
              label="Target date"
              value={value ?? ""}
              onBlur={onBlur}
              error={error?.message}
              format="MMYYYY"
            />
          )}
        />
      </section>

      <div className="flex self-start justify-end my-36 ml-32">
        <ModalNavLink
          to={`/cross/delete-goal/${field.goalId}`}
          className="text-action-error pr-24 sm:pr-32 pl-0 h-full whitespace-nowrap"
        >
          Delete goal
        </ModalNavLink>
      </div>
    </Card>
    </>
  );
};
