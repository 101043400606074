import { useAuth, ContainerMidSize } from "@multiply/lib";
import {
  BookAdviserCall,
  RecommendationCarousel,
  YourProducts,
} from "../../components";
import {
  EmergencyFundSummary,
  RecommendationType,
  useGetPlanRecommendationsQuery,
} from "../../graphqlTypes";
import { CURRENCY_SYMBOL } from "../../utils";
import { ThisMeansEmergencyFund } from "./ThisMeansEmergencyFund";

const EmergencyFund = () => {
  const { userId, loggedIn } = useAuth();
  const [planResult] = useGetPlanRecommendationsQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const emergencyFundGroups =
    planResult?.data?.plan?.recommendationGroups ?? [];

  const emergencyFundRecommendation = emergencyFundGroups.find(
    (group) => group.recommendationType === RecommendationType.EmergencyFund
  );

  const summary = emergencyFundRecommendation?.summary as
    | EmergencyFundSummary
    | null
    | undefined;

  const monthsProtected = summary?.emergencyFundProgress?.monthsProtected;
  const recommendedProtectedMonths =
    summary?.emergencyFundProgress?.recommendedProtectedMonths;
  const currentProvision =
    summary?.emergencyFundProgress.currentProvision.display;
  const recommendedProvision =
    summary?.emergencyFundProgress.recommendedProvision.display;
  const progress = summary?.emergencyFundProgress.progress;

  return (
    <ContainerMidSize>
      <div className="w-full flex flex-col justify-center">
        <h1 className="text-t21 sm:text-t34  text-font-primary mb-12">
          Emergency fund
        </h1>

        <p className="text-t12 sm:text-t27 text-font-tertiary mb-56">
          Cover unforeseen circumstances
        </p>

        <div className="bg-input-border w-full h-2 mb-56" />

        <h1 className="sm:text-t27 text-font-primary mb-32">Overview</h1>

        <section className="sm:flex items-stretch space-y-40 sm:space-y-0 mb-40">
          <YourProducts
            title="Your assets marked for emergencies"
            productSummary={emergencyFundRecommendation?.relatedProducts}
          />
        </section>
        <section className="mb-60">
          <p className="text-font-secondary pb-24 text-t12 sm:text-t21">
            This means
          </p>
          <ThisMeansEmergencyFund
            monthsProtected={monthsProtected ?? 0}
            recommendedProtectedMonths={recommendedProtectedMonths ?? 0}
            currentProvision={currentProvision ?? `${CURRENCY_SYMBOL}0`}
            recommendedProvision={recommendedProvision ?? `${CURRENCY_SYMBOL}0`}
            progress={progress ?? 0}
          />
        </section>

        <section className="mb-60 space-y-32">
          {emergencyFundRecommendation?.recommendations && (
            <RecommendationCarousel
              recommendations={emergencyFundRecommendation?.recommendations}
              title="Recommendations"
            />
          )}
        </section>

        <section>
          <BookAdviserCall hasPlan={true} />
        </section>
      </div>
    </ContainerMidSize>
  );
};

export { EmergencyFund };
