import { Card, Tab, TabGroup } from "@multiply/lib";
import classNames from "classnames";
import { GoalCardGroup } from "./GoalCardGroup";
import { OtherProgressCardGroup } from "../OtherProgressCardGroup";
import { AssetList, DebtList } from "../AssetOrDebtCardGroup";

type GoalsOverviewProps = {
  className?: string;
};

const GoalsOverview = ({ className }: GoalsOverviewProps) => {
  return (
    <>
      <Card
        className={classNames(
          "py-16 px-24 w-full h-fit lg:min-h-[450px]",
          className
        )}
      >
        <TabGroup
          tabs={[
            {
              title: "Goals",
              tab: (
                <Tab>
                  <GoalCardGroup />
                </Tab>
              ),
            },
            {
              title: "Other",
              tab: (
                <Tab>
                  <OtherProgressCardGroup />
                </Tab>
              ),
            },
            {
              title: "Assets",
              tab: (
                <Tab>
                  <AssetList />
                </Tab>
              ),
            },
            {
              title: "Debts",
              tab: (
                <Tab>
                  <DebtList />
                </Tab>
              ),
            },
          ]}
        />
      </Card>
    </>
  );
};

export { GoalsOverview };
