import { CURRENCY_SYMBOL } from "./getCurrencySymbol";

const convertNumberToEuroString = (currencyNumber: number) => {
  const commaSeparatedValue = Math.abs(currencyNumber)
    .toFixed(2)
    .replace(".00", "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return currencyNumber < 0
    ? `-${CURRENCY_SYMBOL}${commaSeparatedValue}`
    : `${CURRENCY_SYMBOL}${commaSeparatedValue}`;
};

export { convertNumberToEuroString };
