import { useAuth } from "@multiply/lib";
import {
  useGetAssetsQuery,
  useGetDebtsQuery,
  useGetPoliciesQuery,
} from "../../graphqlTypes";

const useProductTypesAdded = () => {
  const { userId, loggedIn } = useAuth();
  const [assetsResult] = useGetAssetsQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const [debtsResult] = useGetDebtsQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const [policiesResult] = useGetPoliciesQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const loading =
    assetsResult.fetching || debtsResult.fetching || policiesResult.fetching;

  const error = assetsResult.error ?? debtsResult.error ?? policiesResult.error;

  const assetsValue =
    assetsResult.data?.assets && assetsResult.data?.assets.length > 0
      ? true
      : false;
  const debtsValue =
    debtsResult.data?.debts && debtsResult.data?.debts.length > 0
      ? true
      : false;
  const policiesValue =
    policiesResult.data?.policies && policiesResult.data?.policies.length > 0
      ? true
      : false;

  return {
    loading,
    error,
    anySet: assetsValue || debtsValue || policiesValue,
    assetsValue,
    debtsValue,
    policiesValue,
  };
};

export { useProductTypesAdded };
