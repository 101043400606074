import classNames from "classnames";
import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import {
  getColorFromVariant,
  getDimensionsFromSize,
  IconProps,
} from "./shared";

const UmbrellaCircleIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);
  return (
    <svg
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      viewBox="0 0 59 59"
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={29.666} cy={29.406} r={24.672} fill={fill} />
      <g
        clipPath="url(#a)"
        stroke="#5463D6"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M30.66 29.606v8.643a2.822 2.822 0 0 1-2.815 2.814 2.822 2.822 0 0 1-2.814-2.814"
          strokeWidth={2.328}
        />
        <path
          d="M19.858 29.591c2.176 0 4.079.959 5.149 2.394 1.07-1.435 2.973-2.394 5.15-2.394 2.175 0 4.248.907 5.319 2.342 1.07-1.435 2.803-2.342 4.979-2.342a7.036 7.036 0 0 1 2.666.52c-.494-6.48-6.102-11.593-12.965-11.593-6.864 0-12.474 5.116-12.965 11.598a7.023 7.023 0 0 1 2.667-.525Z"
          fill="#5463D6"
          fillOpacity={0.2}
          strokeWidth={1.863}
        />
        <path
          d="M30.091 16.753v1.553a.478.478 0 0 1-.154.35c-.849.796-4.93 5.056-4.93 13.328M30.373 18.518s5.102 4.014 5.102 13.247"
          strokeWidth={2.328}
        />
      </g>
      <circle
        cx={29.487}
        cy={29.238}
        r={28.071}
        stroke="#BEC7FF"
        strokeWidth={1.863}
      />
      <defs>
        <clipPath id="a">
          <path
            fill="#fff"
            transform="translate(15.393 14.955)"
            d="M0 0h28.784v28.784H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export { UmbrellaCircleIcon };
