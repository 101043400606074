import { Card, ContainerMaxSize, useAuth } from "@multiply/lib";
import { format } from "date-fns";
import { useGetScheduledAppointmentsQuery } from "../../graphqlTypes";

const AdviserCallHistory = () => {
  const { userId } = useAuth();
  const [scheduledAppointmentsResult] = useGetScheduledAppointmentsQuery({
    variables: { userId: userId ?? "" },
  });

  const appointments =
    scheduledAppointmentsResult.data?.scheduledAppointments ?? [];

  return (
    <ContainerMaxSize>
      <div className="flex flex-col w-full max-w-520 space-y-18 sm:space-y-24">
        {appointments.map((appointment) => (
          <Card key={appointment.id} className="flex py-24 px-18 w-full">
            <div className="flex-1">
              <p className="text-t16 sm:text-t21 text-font-primary">
                {appointment.advisorName}
              </p>

              <p className="text-t12 sm:text-t16 text-font-secondary mt-12">
                Adviser
              </p>
            </div>

            <p className="text-t16 sm:text-t21 text-font-primary">
              {format(new Date(appointment.startTime), "dd/MM/yyyy")}
            </p>
          </Card>
        ))}
      </div>{" "}
    </ContainerMaxSize>
  );
};

export { AdviserCallHistory };
