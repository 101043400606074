import { SproutBoldIcon } from "@multiply/lib";

type ResponsiblePensionProps = {
  text: string;
};

const ResponsiblePension = ({ text }: ResponsiblePensionProps) => {
  return (
    <div className="bg-todos-none w-fit h-fit rounded-2xl py-6 px-10 flex items-center gap-2 sm:gap-6">
      <p className="text-t10 font-semibold text-todos-none-label">{text}</p>
      <SproutBoldIcon width={10} height={10} />
    </div>
  );
};

export { ResponsiblePension };
