import { ButtonLink, useWindowDimensions } from "@multiply/lib";
import {
  IrishLifeDisclaimerText,
  IrishLifeLogo,
  IrishLifeLogoLeftCorner,
} from "../../components";

const PageNotFound = () => {
  const { isPhone } = useWindowDimensions();

  return (
    <main className="h-screen min-h-screen bg-background-primary text-font-primary ">
      <section className="">
        {isPhone ? (
          <div className="py-24 pl-24">
            <IrishLifeLogo aria-label="irish life logo" />
          </div>
        ) : (
          <IrishLifeLogoLeftCorner aria-label="irish life logo" />
        )}

        <section className="absolute inset-0 max-w-240 sm:max-w-520 mx-auto">
          <div className="flex flex-col items-center justify-center h-screen  text-center">
            <h1 className="text-t34 lg:text-t57">404</h1>
            <p className="text-t21">
              Sorry, we can't find the page you're searching for.
            </p>
            <ButtonLink className="mt-48" to="/advice">
              Go to home page
            </ButtonLink>
          </div>
        </section>

        <section className="absolute bottom-0 text-t10 lg:text-t12 w-screen">
          <IrishLifeDisclaimerText className="flex pb-32 lg:pb-40 px-18 justify-center items-center" />
        </section>
      </section>
    </main>
  );
};
export { PageNotFound };
