import { Recommendation as RecommendationType } from "../../graphqlTypes";
import { ComparisonSection } from "./ComparisonSection";
import { MarkdownSection } from "./MarkdownSection";
import { UrlSection } from "./UrlSection";
import classNames from "classnames";
import { ButtonLink, Card, ArrowRightCircle } from "@multiply/lib";
import { useGetUserJourney } from "../../hooks";
import { Loading } from "../../pages";

type RecommendationCardProps = {
  recommendation?: RecommendationType;
  className?: string;
};

const RecommendationCard = ({
  recommendation,
  className,
}: RecommendationCardProps) => {
  const userJourney = useGetUserJourney();
  return (
    <>
      {userJourney.loading ? (
        <Loading />
      ) : (
        <Card className={classNames("py-56 px-16 lg:px-60", className)}>
          <h2 className="text-font-primary text-t21 sm:text-t27 mb-8">
            {recommendation?.title}
          </h2>
          <h3 className="text-font-primary text-t16 sm:text-t21 mb-40">
            {recommendation?.subtitle}
          </h3>

          <div className="flex flex-col gap-y-12">
            {(recommendation?.explanation ?? []).map((contentBlock, index) => {
              const contentType = contentBlock.__typename;

              switch (contentType) {
                case "MarkdownBlock":
                  return (
                    <MarkdownSection contentBlock={contentBlock} key={index} />
                  );

                case "ComparisonBlock":
                  return (
                    <ComparisonSection
                      contentBlock={contentBlock}
                      key={index}
                    />
                  );

                case "UrlBlock":
                  return <UrlSection contentBlock={contentBlock} key={index} />;

                case undefined:
                  throw new Error("__typename not set for ContentBlock");

                default:
                  const unreachable: never = contentType;
                  return unreachable;
              }
            })}
          </div>
          <div className="h-1 bg-brand-primary my-56" />
          <div className="flex justify-center sm:justify-start">
            <div className="flex flex-col items-end">
              {recommendation?.ctas && recommendation?.ctas.length > 0 ? (
                recommendation?.ctas.map((cta) => (
                  <ButtonLink
                    key={cta.text}
                    className="mt-18 font-bold"
                    rightIcon={<ArrowRightCircle />}
                    to={cta.url}
                  >
                    {cta.text}
                  </ButtonLink>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>

          {recommendation?.warnings && (
            <section className="mt-40 sm:mt-60 space-y-16 sm:space-y-24">
              {recommendation.warnings.map((warning, index) => (
                <p
                  key={index}
                  className="text-font-primary font-bold text-t12 sm:text-t16 border-2 border-font-primary px-24 py-16 sm:px-32 sm:py-24"
                >
                  {warning}
                </p>
              ))}
            </section>
          )}
        </Card>
      )}
    </>
  );
};

export { RecommendationCard };
