import classNames from "classnames";
import {
  AccommodationType,
  CollegeCost,
  WhatToIncludeType,
} from "../../graphqlTypes";
import {
  accommodationTooltip,
  generateTooltipElement,
  otherExpensesTooltip,
} from "./CollegeCostCalculatorUtils";
import { CollegeCalculationYouToldUs } from "./CollegeCalculationYouToldUs";
import { Accordion, Button, Card } from "@multiply/lib";
import { AddSavingsGoalButton } from "./AddSavingsGoalButton";

type CollegeCostsCalculatorResultDetailsProps = {
  collegeCostValues: CollegeCost;
  onMakeChanges: () => void;
  onStartAgain: () => void;
  onAddGoal?: () => void;
  savingsGoalText?: string;
  className?: string;
  modal?: boolean;
  userId?: string | null;
  currencySymbol?: string;
};

const CollegeCostsCalculatorResultDetails = ({
  className,
  modal,
  collegeCostValues,
  onMakeChanges,
  onStartAgain,
  onAddGoal,
  savingsGoalText,
  userId,
  currencySymbol = "€",
}: CollegeCostsCalculatorResultDetailsProps) => {
  const year = new Date(collegeCostValues.targetDate).getFullYear().toString();

  return (
    <Card
      variant={modal ? "flat" : "floating"}
      className={classNames(
        "w-full",
        { "mt-24": modal },
        {
          "px-18 py-24 sm:px-36 sm:py-40 w-full h-fit md:w-[90%]": !modal,
        },
        className
      )}
    >
      <h2 className="text-t21 sm:text-t34 font-semibold text-font-primary">
        {`We estimate that you'll need to save ${collegeCostValues.total?.display} by 
        ${year}`}
      </h2>
      <p className="text-t16 text-font-primary mt-12">
        <span className={`${!userId ? "hidden" : "inline"}`}>
          Add this goal and we'll work it into your plan to help you achieve it.
        </span>
      </p>

      <div className="flex flex-col md:flex-row gap-20 sm:gap-32 mt-24">
        <div className="w-full md:w-3/4">
          <table className="rounded-sm text-left border border-spacing-0 border-separate border-[#D8D6DF] border-1 w-full">
            <thead className="bg-[#E3E5F9] text-font-primary font-bold">
              <tr>
                <th className="rounded-tl-sm p-20">COST PER YEAR</th>
                <th className="rounded-tr-sm p-20 w-120 text-right">
                  {collegeCostValues.annualCost?.display}
                </th>
              </tr>
            </thead>

            <tbody className="text-t16 text-font-primary font-semibold">
              <tr>
                <td className="p-20 flex items-center">
                  Fees{" "}
                  {collegeCostValues?.eligibleForFreeTuitionFees
                    ? generateTooltipElement("freeFeeEligible", currencySymbol)
                    : generateTooltipElement(
                        "freeFeeNotEligible",
                        currencySymbol
                      )}
                </td>
                <td className="p-20 text-right">
                  {collegeCostValues.fees?.display}
                </td>
              </tr>
              {collegeCostValues?.whatToInclude?.includes(
                WhatToIncludeType.Accommodation
              ) && (
                <tr>
                  <td className="p-20 border-t border-1 border-[#D8D6DF] flex items-center">
                    Accommodation{" "}
                    {collegeCostValues?.accommodationType !==
                      AccommodationType.Home &&
                      accommodationTooltip(
                        collegeCostValues?.accommodationType,
                        currencySymbol
                      )}
                  </td>
                  <td className="p-20 text-right border-t border-1 border-[#D8D6DF]">
                    {collegeCostValues.accommodationCost?.display}
                  </td>
                </tr>
              )}

              {collegeCostValues?.whatToInclude?.includes(
                WhatToIncludeType.OtherExpenses
              ) && (
                <tr>
                  <td className="p-20 border-t border-1 border-[#D8D6DF] flex items-center">
                    Other expenses{" "}
                    {otherExpensesTooltip(
                      collegeCostValues?.accommodationType,
                      currencySymbol
                    )}
                  </td>
                  <td className="p-20 text-right border-t border-1 border-[#D8D6DF]">
                    {collegeCostValues.otherExpenses?.display}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <CollegeCalculationYouToldUs
          collegeCostValues={collegeCostValues}
          makeChangesHandler={onMakeChanges}
        />
      </div>

      <Accordion
        className="mb-32 w-full md:w-[69%]"
        title="Notes and assumptions"
        bullets={[
          "Not all courses are eligible for free fees. Courses at private colleges, for example. If your child is not eligible for free fees, tuition costs vary.",
          "Student contribution fees can change every year. For the academic year 2022-2023, the maximum rate of student contribution is €3,000.",
          "Scholarships, funding programmes, and grants are available that can lower annual costs. We have not factored these into the calculation.",
          "Within our calculation student accommodation is only covered during term time. In addition, we assume that rented accommodation involves sharing a property with three other tenants. Costs will likely be higher if there are fewer tenants.",
          "We assume that your child will start college at 19 years of age.",
          "Inflation of costs are not included in the calculation, but will be included when you add a goal to your financial plan.",
          "The calculations from this tool should be seen as a rough estimate rather than definitive figures.",
        ]}
      />

      <div className="flex flex-col items-start sm:flex-row gap-12 mb-24">
        {onAddGoal && (
          <AddSavingsGoalButton
            handleAddGoal={onAddGoal}
            savingsGoalText={savingsGoalText}
            id="add-goal-submit"
          />
        )}
        <Button
          size="sm"
          variant="ghost"
          onClick={onStartAgain}
          id="start-over-submit"
        >
          Start over
        </Button>
      </div>
    </Card>
  );
};

export { CollegeCostsCalculatorResultDetails };
