import classNames from "classnames";
import { ReactNode } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useWindowDimensions } from "../../styles";
import { InfoCircle } from "../Icons";
import "react-tooltip/dist/react-tooltip.css";
import { useId } from "../../hooks";

type TooltipProps = {
  children?: ReactNode;
  className?: string;
  variant?: "default" | "component";
  place?: "top" | "right" | "bottom" | "left";
  "aria-label"?: string;
};
const Tooltip = ({
  children,
  className,
  variant = "default",
  place = "left",
  "aria-label": ariaLabel,
}: TooltipProps) => {
  const id = useId();
  const { isPhone } = useWindowDimensions();

  return (
    <div
      id="tooltip-icon"
      data-tooltip-id={id}
      className={classNames("pl-8 w-fit", className)}
      onClick={(e) => e.preventDefault()}
      aria-label={`Tooltip details: ${ariaLabel ? ariaLabel : ""}`}
      role="tooltip"
      tabIndex={0}
    >
      <InfoCircle aria-hidden={true} className="h-20 w-20" />
      <ReactTooltip
        id={id}
        className="z-[9999] max-w-[50vw] xl:max-w-[30vw]"
        noArrow={true}
        openOnClick={isPhone ? true : false}
        place={place}
        aria-hidden={true}
        style={{
          backgroundColor: variant === "component" ? "transparent" : "#5261AC",
          padding: variant === "component" ? "0px" : "8px 16px",
          borderRadius: "9px 9px 9px 0px",
          color: "#fff",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "14.4px",
        }}
      >
        <div>{children}</div>
      </ReactTooltip>
    </div>
  );
};

export { Tooltip };
