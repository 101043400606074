import { DependantRelationship, Factfind } from "../graphqlTypes";

const getFieldsFromFactFind = (factfind?: Factfind) => {
  let {
    planningFor,
    monthlyRent,
    monthlyUtilities,
    extraMonthlyOutgoingEssentials,
    monthlyOutgoingNonEssentials,
    maritalStatus,
    numberOfChildren,
    ageOfChildren = [],
    relationshipToOtherDependants = [],
    userSpecificFactfind,
    partnerSpecificFactfind,
    ownsHome,
    hasMortgage,
  } = factfind ?? {};

  const {
    employmentStatus,
    mainEmploymentType,
    occupation,
    memberOfEmployersPensionScheme,
    employerOffersPensionScheme,
    baseAnnualIncomeGross,
    hasBonus,
    hasOtherIncome,
    expectedBonusAmount,
    benefitInKind,
    monthlyIncomeNet,
    marginalTaxRate,
    expectedOtherIncomeAmount,
    eligibleForStatePension,
    monthlyRetirementOutgoingExpected,
    hasGoodHealth,
    smokingStatus,
    monthsOfFinancialSafety,
    retirementAgeEstimate,
    industry,
    residentCountry,
    domicileCountry,
    birthCountry,
    nationality,
    pep,
    employer,
    employerAddressLine1,
    employerAddressLine2,
    employerAddressLine3,
  } = userSpecificFactfind ?? {};

  const {
    firstName: firstNamePartner,
    lastName: lastNamePartner,
    dateOfBirth: dateOfBirthPartner,
    employmentStatus: employmentStatusPartner,
    mainEmploymentType: mainEmploymentTypePartner,
    occupation: occupationPartner,
    memberOfEmployersPensionScheme: memberOfEmployersPensionSchemePartner,
    employerOffersPensionScheme: employerOffersPensionSchemePartner,
    baseAnnualIncomeGross: baseAnnualIncomeGrossPartner,
    hasBonus: hasBonusPartner,
    hasOtherIncome: hasOtherIncomePartner,
    expectedBonusAmount: expectedBonusAmountPartner,
    benefitInKind: benefitInKindPartner,
    monthlyIncomeNet: monthlyIncomeNetPartner,
    marginalTaxRate: marginalTaxRatePartner,
    expectedOtherIncomeAmount: expectedOtherIncomeAmountPartner,
    eligibleForStatePension: eligibleForStatePensionPartner,
    retirementAgeEstimate: retirementAgeEstimatePartner,
    monthlyRetirementOutgoingExpected: monthlyRetirementOutgoingExpectedPartner,
    hasGoodHealth: hasGoodHealthPartner,
    smokingStatus: smokingStatusPartner,
    monthsOfFinancialSafety: monthsOfFinancialSafetyPartner,
  } = partnerSpecificFactfind ?? {};

  // make sure we always have the right number of array entries for ageOfChildren
  const numMissingChildren =
    (numberOfChildren ?? 0) - (ageOfChildren?.length ?? 0);

  if (numMissingChildren > 0) {
    const extraAges = Array(numMissingChildren).fill(null);
    ageOfChildren = [...(ageOfChildren ?? []), ...extraAges];
  }

  if (numMissingChildren < 0) {
    ageOfChildren = (ageOfChildren ?? []).slice(0, numMissingChildren);
  }

  const ageOfChildrenObjects = (ageOfChildren ?? []).map((age) => ({
    age: age ?? 0,
  }));

  const relationshipToOtherDependantsObjects: Array<{
    hasOther: boolean | null;
    relationship: DependantRelationship | null;
  }> = relationshipToOtherDependants
    ? [
        ...relationshipToOtherDependants?.map((relationship) => ({
          relationship: relationship as DependantRelationship,
          hasOther: true,
        })),
        { hasOther: false, relationship: null },
      ]
    : [{ hasOther: false, relationship: null }];

  return {
    ageOfChildren: ageOfChildrenObjects,
    hasChildren: (ageOfChildrenObjects.length > 0) as boolean | null,
    baseAnnualIncomeGross,
    baseAnnualIncomeGrossPartner,
    dateOfBirthPartner,
    firstNamePartner,
    lastNamePartner,
    eligibleForStatePension,
    eligibleForStatePensionPartner,
    employmentStatus,
    employmentStatusPartner,
    mainEmploymentType,
    mainEmploymentTypePartner,
    hasBonus,
    hasBonusPartner,
    hasOtherIncome,
    hasOtherIncomePartner,
    expectedBonusAmount,
    expectedBonusAmountPartner,
    expectedOtherIncomeAmount,
    expectedOtherIncomeAmountPartner,
    benefitInKind,
    benefitInKindPartner,
    hasGoodHealth,
    hasGoodHealthPartner,
    marginalTaxRate,
    marginalTaxRatePartner,
    maritalStatus,
    monthlyIncomeNet,
    monthlyIncomeNetPartner,
    monthlyRent,
    monthlyUtilities,
    extraMonthlyOutgoingEssentials,
    monthlyOutgoingNonEssentials,
    hasMortgage,
    ownsHome,
    monthlyRetirementOutgoingExpected,
    monthlyRetirementOutgoingExpectedPartner,
    monthsOfFinancialSafety,
    monthsOfFinancialSafetyPartner,
    numberOfChildren,
    occupation,
    occupationPartner,
    memberOfEmployersPensionScheme,
    memberOfEmployersPensionSchemePartner,
    employerOffersPensionScheme,
    employerOffersPensionSchemePartner,
    relationshipToOtherDependants: relationshipToOtherDependantsObjects,
    planningFor,
    retirementAgeEstimate: (retirementAgeEstimate ?? 66) as number | null,
    retirementAgeEstimatePartner: (retirementAgeEstimatePartner ?? 66) as
      | number
      | null,
    smokingStatus,
    smokingStatusPartner,
    industry,
    residentCountry,
    domicileCountry,
    birthCountry,
    nationality,
    pep,
    employer,
    employerAddressLine1,
    employerAddressLine2,
    employerAddressLine3,
  };
};

export type FactfindFields = ReturnType<typeof getFieldsFromFactFind>;
export type FactfindFieldKey = keyof FactfindFields;
export type FactfindFieldValue = FactfindFields[FactfindFieldKey];

export { getFieldsFromFactFind };
