const TaxReliefContributions = () => {
  return (
    <section className="text-font-primary p-18 sm:p-32 space-y-18">
      <h1 className="text-t21 sm:text-t32 font-semibold">
        Tax relief contributions
      </h1>
      <p className="text-font-secondary">
        Caculations assuming personal contributions
      </p>
      <div className="grid grid-cols-2 border-b border-divider pb-12 gap-y-16 font-semibold">
        <p className="pl-8">Selected rate:</p>
        <p>40%</p>
        <p className="col-span-2 bg-brand-tertiary uppercase pl-8 py-6">
          CONTRIBUTIONS
        </p>
        <p className="pl-8">Regular:</p>
        <p>€1000 per month</p>
        <p className="pl-8">Less tax relief:</p>
        <p>€400</p>
        <p className="pl-8">Net cost:</p>
        <p>€600</p>
      </div>
      <p>
        Please note that tax relief must be sought and is not guaranteed. The
        calculators above are illustrative, in relation to this calendar year
        only, and do not take account of contributions paid to other pension
        arrangements
      </p>
    </section>
  );
};

export default TaxReliefContributions;
