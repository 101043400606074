import { TextInput } from "../TextInput";

type InputProviderProps = {
  value: string | null;
  onChange: (value: string | null) => void;
  onBlur: () => void;
  className?: string;
  error?: string;
  label?: string;
};

const InputProvider = ({
  value,
  onChange,
  onBlur,
  className,
  error,
  label,
}: InputProviderProps) => {
  return (
    <TextInput
      className={className}
      label={label ? label : "Account name (optional)"}
      placeholder="Enter account name"
      error={error}
      value={value ?? ""}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onChange(e.target.value)
      }
      onBlur={onBlur}
    />
  );
};
export { InputProvider };
