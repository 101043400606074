import { itemToString } from "./itemToString";

const obfuscateDob = (dob: any) => {
  if (!dob) return null;
  const splitDob = itemToString(dob).split("-");
  let obfuscatedDob = `**/${splitDob[1]}/${splitDob[0]}`;
  return obfuscatedDob;
};

export { obfuscateDob };
