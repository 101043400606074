import startApplication from "../assets/images/start-pension-application-cropped.png";
import startApplicationMobile from "../assets/images/start-pension-application.png";
import adviceChange from "../assets/images/advice-change-cropped.png";
import adviceChangeMobile from "../assets/images/advice-change.png";
import applicationConfirmation from "../assets/images/pension-application-confirmation-cropped.png";
import applicationConfirmationMobile from "../assets/images/pension-application-confirmation.png";
import { TrackedLink } from "@multiply/lib";

export enum HeroType {
  StartApplication = "START_APPLICATION",
  AdviceChanged = "ADVICE_CHANGED",
  ApplicationConfirmation = "APPLICATION_CONFIRMATION",
}

type HeroContent = {
  heading?: string;
  title: string;
  subtitle: string;
  ctaText: string;
  imageUrl: string;
  imageUrlSmallDevice: string;
  navPath: string;
  disclaimer?: string | JSX.Element;
};

export const getHeroContent = (type: HeroType): HeroContent => {
  switch (type) {
    case HeroType.StartApplication:
      return {
        heading: "Irish Life",
        title: "Online Clear PRSA  Application",
        subtitle:
          "You started your PRSA journey with Irish Life Financial Services, let's continue with our partners Irish Life Assurance. It won’t take long.",
        ctaText: "Get started",
        navPath: "/pensions/declaration",
        imageUrl: startApplication,
        imageUrlSmallDevice: startApplicationMobile,
        disclaimer: (
          <p className="text-font-secondary text-t21">
            Irish Life Assurance{" "}
            <TrackedLink
              className="text-font-links"
              href="https://assets-eu-01.kc-usercontent.com/ffd8d21b-ebd4-0151-55d5-297335c8f50f/39746f00-8406-49c3-a26b-dd08cc67f881/Terms%20of%20Business%20ILFS.pdf"
              rel="noreferrer"
              target="_blank"
            >
              Terms of Business
            </TrackedLink>{" "}
            and{" "}
            <TrackedLink
              className="text-font-links"
              href="https://assets-eu-01.kc-usercontent.com/ffd8d21b-ebd4-0151-55d5-297335c8f50f/c88ce4a9-46d1-43da-bf19-3e08ca23a28e/ILA%2017350%20%28NPI%2006-22%29-M6.pdf"
              rel="noreferrer"
              target="_blank"
            >
              Privacy Notice
            </TrackedLink>
          </p>
        ),
      };

    case HeroType.AdviceChanged:
      return {
        title: "Book a call with an adviser",
        subtitle:
          "Due to your circumstances, you will need to book a call with an adviser to open a PRSA product.",
        ctaText: "Book a call",
        navPath: "/pensions/appointments",
        imageUrl: adviceChange,
        imageUrlSmallDevice: adviceChangeMobile,
      };

    case HeroType.ApplicationConfirmation:
      return {
        title: "Thanks for your application",
        subtitle:
          "Our team will now review your application. Irish Life Esign will soon send you an email signature request from noreply@esign.irishlife.ie.",
        ctaText: "Back to my plan",
        navPath: "/",
        imageUrl: applicationConfirmation,
        imageUrlSmallDevice: applicationConfirmationMobile,
      };
    default:
      const unreachable: never = type;
      return unreachable;
  }
};
