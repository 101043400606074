import { Button, ContainerMidSize, useAuth } from "@multiply/lib";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConversationCardTemplate, RadioButtonGroup } from "../../components";
import { useUpdateAssetMutation } from "../../graphqlTypes";
import {
  getAssetNameFromType,
  sortedAssetTypes,
  UserFacingAssetType,
} from "../../utils";

function AddAsset() {
  const { userId } = useAuth();

  const [assetType, setAssetType] = useState<UserFacingAssetType | null>(null);
  const [updateAssetResult, updateAsset] = useUpdateAssetMutation();
  const navigate = useNavigate();

  const handleAddAsset = async () => {
    try {
      if (assetType) {
        const newAsset = {
          productType: assetType,
        };
        const result = await updateAsset({
          input: { userId: userId ?? "", asset: newAsset },
        });

        if (result?.data?.updateAsset?.success) {
          const assetId = result?.data?.updateAsset?.asset?.id;
          navigate(`/products/add/asset/${assetId}`);
        }
      }
    } catch (error) {}
  };

  const error =
    updateAssetResult.error ?? updateAssetResult.data?.updateAsset?.error;

  return (
    <ContainerMidSize>
      <ConversationCardTemplate title="Which asset do you want to add?">
        <RadioButtonGroup
          value={assetType}
          onChange={(nextValue) => {
            setAssetType(nextValue);
          }}
          options={sortedAssetTypes.map((assetType) => ({
            value: assetType,
            label: getAssetNameFromType(assetType),
          }))}
        />
        <Button
          onClick={handleAddAsset}
          disabled={updateAssetResult.fetching || !assetType}
        >
          Next
        </Button>
        {error && <p className="mt-16 text-action-error">{error.message}</p>}
      </ConversationCardTemplate>
    </ContainerMidSize>
  );
}

export { AddAsset };
