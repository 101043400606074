import { Button } from "@multiply/lib";
import { GetDebtQuery } from "../../graphqlTypes";
import { useDebtForm } from "../../hooks";
import { getDebtFieldsFromType, getDebtNameFromType } from "../../utils";
import { DebtFields } from "./DebtFields";

type DebtFormProps = {
  debt: NonNullable<GetDebtQuery["debt"]>;
  onSubmit: (values: any) => Promise<void>;
  defaultValues: any;
};

const DebtForm = ({ debt, onSubmit, defaultValues }: DebtFormProps) => {
  const fields = getDebtFieldsFromType(debt?.productType);
  const name = getDebtNameFromType(debt?.productType);

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting, isValid },
  } = useDebtForm({ fields, defaultValues });

  // seems to prevent a bug where useWatch in DebtFields sometimes doesn't update
  watch("arePaymentsUpToDate");

  return (
    <form className="flex flex-col items-center w-full">
      <div className="flex items-center self-start mb-60">
        <h1 className="text-t27 text-font-primary">{name}</h1>
      </div>

      <DebtFields fields={fields} control={control} />

      <Button
        className="mt-96 w-240"
        onClick={handleSubmit(onSubmit)}
        disabled={isSubmitting || !isValid}
      >
        Save
      </Button>
    </form>
  );
};

export { DebtForm };
