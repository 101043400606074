import {
  Avatar,
  Card,
  ContainerMaxSize,
  obfuscateDob,
  useAuth,
} from "@multiply/lib";
import {
  EmploymentStatus,
  EmploymentType,
  Factfind,
  QuestionGroup,
  useGetFactFindQuery,
  useGetFieldsQuery,
  useGetPersonQuery,
  useGetRiskQuery,
  useGetUserQuery,
} from "../../graphqlTypes";
import { currencyOrNull, useIsMIL } from "../../utils";
import { Loading } from "../Loading";
import { SettingsCard } from "../../components";

import get from "lodash/get";
import set from "lodash/set";
import toPath from "lodash/toPath";

const getValuesFromQuestionGroups = (
  factfind: { factfind: Partial<Factfind> },
  questionGroups: QuestionGroup[]
) => {
  const values: { [field: string]: any } = {};

  questionGroups.forEach((questionGroup) => {
    questionGroup.questions.forEach((question) => {
      const fullPath = toPath(question.key);
      set(values, fullPath[fullPath.length - 1], get(factfind, question.key));
    });
  });
  return values;
};

const PensionsPersonalDetails = () => {
  const { userId, loggedIn } = useAuth();
  const isMIL = useIsMIL();

  const [riskResult] = useGetRiskQuery({ variables: { userId: userId ?? "" } });

  const [personResult] = useGetPersonQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const [factFindResults] = useGetFactFindQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const [fieldsResult] = useGetFieldsQuery({
    variables: { userId: userId ?? "", context: "pensions_factfind" },
    pause: !loggedIn,
  });

  const loading =
    factFindResults?.fetching ||
    personResult?.fetching ||
    fieldsResult?.fetching;

  const [userResult] = useGetUserQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const { dateOfBirth, firstName, lastName, address, phoneNumber } =
    personResult?.data?.person ?? {};
  const { email } = userResult.data?.user ?? {};

  const {
    postalCode,
    flatNumber,
    buildingNumber,
    buildingName,
    street,
    subStreet,
    town,
    country,
  } = address ?? {};
  const fullAddress = `${flatNumber ?? ""} ${buildingNumber ?? ""} ${
    buildingName ?? ""
  } ${subStreet ?? ""} ${street ?? ""} ${town ?? ""} ${postalCode ?? ""} ${
    country ?? ""
  }`;

  const {
    maritalStatus,
    employmentStatus,
    mainEmploymentType,
    memberOfEmployersPensionScheme,
    baseAnnualIncomeGross,
    hasOtherIncome,
    expectedOtherIncomeAmount,
    hasBonus,
    expectedBonusAmount,
    monthlyIncomeNet,
    marginalTaxRate,
    monthlyRent,
    monthlyUtilities,
    extraMonthlyOutgoingEssentials,
    monthlyOutgoingNonEssentials,
    eligibleForStatePension,
    retirementAgeEstimate,
    monthlyRetirementOutgoingExpected,
  } = getValuesFromQuestionGroups(
    { factfind: factFindResults?.data?.factfind ?? {} },
    fieldsResult?.data?.factfindFields?.questionGroups ?? []
  );

  const userIsEmployed = employmentStatus === EmploymentStatus.Employed;

  const totalMonthlyOutgoing =
    (monthlyRent ?? 0) +
    (monthlyUtilities ?? 0) +
    (extraMonthlyOutgoingEssentials ?? 0) +
    (monthlyOutgoingNonEssentials ?? 0);

  if (!loading && personResult.data && factFindResults.data) {
    return (
      <ContainerMaxSize>
        {loading ? (
          <div className="w-full flex justify-center items-center">
            <Loading />
          </div>
        ) : (
          <div className="w-full sm:pb-60 space-y-48">
            <section className="lg:w-fit">
              <h1 className="text-t20 sm:text-t27 text-font-primary pb-16">
                Personal details
              </h1>

              {isMIL && (
                <Card className="p-16 mb-28 border-input-border-focus border-2">
                  <p className="text-t14 text-font-primary">
                    Changes you make here will be reflected in your financial
                    plan only and will not update your personal records on Irish
                    Life Assurance or Irish Life Health.
                  </p>
                </Card>
              )}

              <Card className="p-24 space-y-32">
                <div className="flex flex-col items-center lg:items-start">
                  <Avatar variant="icon" size="xl" colorType="irish-life" />
                  <p className="text-t21 text-font-primary capitalize pt-8">
                    {firstName} {lastName}
                  </p>
                </div>

                <div
                  className="flex flex-col gap-24 lg:gap-80 
              lg:flex-row lg:flex-wrap"
                >
                  <section>
                    <p className="text-font-primary">Date of birth</p>
                    <p className="text-font-secondary">
                      {obfuscateDob(dateOfBirth)}
                    </p>
                  </section>

                  <section>
                    <p className="text-font-primary">Address</p>
                    <p className="text-font-secondary capitalize">
                      {fullAddress.toLowerCase()}
                    </p>
                  </section>

                  <section>
                    <p className="text-font-primary">Email</p>
                    <p className="text-font-secondary">{email}</p>
                  </section>

                  <section>
                    <p className="text-font-primary">Mobile number</p>
                    <p className="text-font-secondary">{phoneNumber}</p>
                  </section>
                </div>
              </Card>
            </section>

            <section className="sm:flex flex-wrap gap-16">
              <SettingsCard
                label="Marital status"
                fieldName="pensions_marital_status"
                value={maritalStatus}
              />
              <SettingsCard
                fieldName="pensions_employment_status"
                label="Employment status"
                value={
                  employmentStatus === EmploymentStatus.Employed
                    ? mainEmploymentType
                    : employmentStatus
                }
              />
              {userIsEmployed && (
                <>
                  {mainEmploymentType !== EmploymentType.SelfEmployed && (
                    <SettingsCard
                      label="In employer's pension scheme?"
                      fieldName="pensions_employer_pension"
                      value={memberOfEmployersPensionScheme ? "Yes" : "No"}
                    />
                  )}
                  <SettingsCard
                    fieldName="pensions_base_annual_income_gross"
                    label="Annual income"
                    value={currencyOrNull(baseAnnualIncomeGross)}
                  />
                  <SettingsCard
                    fieldName="pensions_expected_other_income_amount"
                    label="Other income"
                    value={
                      !hasOtherIncome
                        ? "No"
                        : currencyOrNull(expectedOtherIncomeAmount)
                    }
                  />
                  <SettingsCard
                    fieldName="pensions_expected_bonus_amount"
                    label="Annual bonus"
                    value={
                      !hasBonus ? "No" : currencyOrNull(expectedBonusAmount)
                    }
                  />

                  <SettingsCard
                    fieldName="pensions_monthly_income_net"
                    label="Monthly take-home pay"
                    value={currencyOrNull(monthlyIncomeNet)}
                  />
                  <SettingsCard
                    fieldName="pensions_marginal_tax_rate"
                    label="Highest income tax rate"
                    value={
                      marginalTaxRate === 99
                        ? "I don't pay income tax"
                        : marginalTaxRate === 0.4
                        ? "40% (higher rate)"
                        : marginalTaxRate === 0.2
                        ? "20% (standard rate rate)"
                        : null
                    }
                  />
                  <SettingsCard
                    fieldName="pensions_outgoings"
                    label="Outgoings"
                    value={currencyOrNull(totalMonthlyOutgoing)}
                  />
                  <SettingsCard
                    fieldName="pensions_eligible_for_state_pension"
                    label="State pension eligibility"
                    value={
                      eligibleForStatePension === true
                        ? "Yes"
                        : eligibleForStatePension === false
                        ? "No"
                        : null
                    }
                  />
                  <SettingsCard
                    fieldName="pensions_retirement_age_estimate"
                    label="Retirement age target"
                    value={retirementAgeEstimate}
                  />
                  <SettingsCard
                    fieldName="pensions_monthly_retirement_outgoing_expected"
                    label="Retirement income target"
                    value={currencyOrNull(monthlyRetirementOutgoingExpected)}
                  />
                </>
              )}
            </section>

            {riskResult.data?.risk?.calculatedProfile && (
              <section>
                <p className="text-font-primary pb-16">Risk</p>
                <Card className="p-24 mb-16">
                  <p className="sm:text-t18 text-font-primary">Profile</p>
                  <p className="sm:text-t18 text-font-secondary">
                    {riskResult.data?.risk?.calculatedProfile?.name}
                  </p>
                </Card>
              </section>
            )}
          </div>
        )}
      </ContainerMaxSize>
    );
  } else return null;
};

export { PensionsPersonalDetails };
