import { Button, ContainerMidSize, useAuth } from "@multiply/lib";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConversationCardTemplate, RadioButtonGroup } from "../../components";
import { PolicyType, useUpdatePolicyMutation } from "../../graphqlTypes";
import { getPolicyNameFromType, sortedPolicyTypes } from "../../utils";

function AddPolicy() {
  const { userId } = useAuth();
  const [policyType, setPolicyType] = useState<PolicyType | null>(null);
  const [addPolicyResult, addPolicy] = useUpdatePolicyMutation();
  const navigate = useNavigate();

  const handleAddPolicy = async () => {
    try {
      if (policyType) {
        const newPolicy = {
          productType: policyType,
        };
        const result = await addPolicy({
          input: { userId: userId ?? "", policy: newPolicy },
        });

        if (result?.data?.updatePolicy?.success) {
          const policyId = result?.data?.updatePolicy?.policy?.id;
          navigate(`/products/add/policy/${policyId}`);
        }
      }
    } catch (error) {}
  };

  const error =
    addPolicyResult.error ?? addPolicyResult.data?.updatePolicy?.error;

  return (
    <ContainerMidSize>
      <ConversationCardTemplate title="Which policy do you want to add?">
        <RadioButtonGroup
          value={policyType}
          onChange={(nextValue) => {
            setPolicyType(nextValue);
          }}
          options={Object.values(sortedPolicyTypes).map((policyType) => ({
            value: policyType,
            label: getPolicyNameFromType(policyType),
          }))}
        />
        <Button
          onClick={handleAddPolicy}
          disabled={addPolicyResult.fetching || !policyType}
        >
          Next
        </Button>
        {error && <p className="mt-16 text-action-error">{error.message}</p>}
      </ConversationCardTemplate>
    </ContainerMidSize>
  );
}

export { AddPolicy };
