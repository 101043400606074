import { useAuth } from "@multiply/lib";
import { addYears, compareAsc } from "date-fns";
import { useMemo } from "react";
import { CombinedError } from "urql";
import {
  Relationship,
  useGetPersonsQuery,
  useGetRetirementAgeFactFindQuery,
} from "../../graphqlTypes";

export type RetirementDetails = {
  retirementAge: number | null | undefined;
  retirementDate: Date | null;
};

export type RetirementDate = {
  user: RetirementDetails;
  partner: RetirementDetails;
  earliest: RetirementDetails;
  loading: boolean;
  error: CombinedError | undefined;
};

const useRetirementDate = (): RetirementDate => {
  const { userId } = useAuth();
  const [personsResult] = useGetPersonsQuery({
    variables: { userId: userId ?? "" },
  });

  const user = personsResult.data?.persons.find(
    (person) => person.relationship === Relationship.User
  );
  const partner = personsResult.data?.persons.find(
    (person) => person.relationship === Relationship.Partner
  );

  const [retirementFactFindResult] = useGetRetirementAgeFactFindQuery({
    variables: { userId: userId ?? "" },
  });

  const userDateOfBirth = user?.dateOfBirth;
  const userRetirementAge =
    retirementFactFindResult.data?.factfind.userSpecificFactfind
      ?.retirementAgeEstimate;

  const partnerDateOfBirth = partner?.dateOfBirth;
  const partnerRetirementAge =
    retirementFactFindResult.data?.factfind.partnerSpecificFactfind
      ?.retirementAgeEstimate;

  const userRetirementDate = useMemo(
    () =>
      userDateOfBirth && userRetirementAge
        ? addYears(new Date(userDateOfBirth), userRetirementAge)
        : null,
    [userDateOfBirth, userRetirementAge]
  );

  const partnerRetirementDate = useMemo(
    () =>
      partnerDateOfBirth && partnerRetirementAge
        ? addYears(new Date(partnerDateOfBirth), partnerRetirementAge)
        : null,
    [partnerDateOfBirth, partnerRetirementAge]
  );

  const earliestRetirement = useMemo(() => {
    return compareAsc(
      userRetirementDate ?? addYears(new Date(), 999),
      partnerRetirementDate ?? addYears(new Date(), 999)
    ) < 0
      ? {
          retirementAge: userRetirementAge,
          retirementDate: userRetirementDate,
        }
      : {
          retirementAge: partnerRetirementAge,
          retirementDate: partnerRetirementDate,
        };
  }, [
    partnerRetirementAge,
    partnerRetirementDate,
    userRetirementAge,
    userRetirementDate,
  ]);

  const result = useMemo(() => {
    return {
      user: {
        retirementAge: userRetirementAge,
        retirementDate: userRetirementDate,
      },
      partner: {
        retirementAge: partnerRetirementAge,
        retirementDate: partnerRetirementDate,
      },
      earliest: earliestRetirement,
      loading: personsResult.fetching || retirementFactFindResult.fetching,
      error: personsResult.error || retirementFactFindResult.error,
    };
  }, [
    earliestRetirement,
    partnerRetirementAge,
    partnerRetirementDate,
    personsResult.error,
    personsResult.fetching,
    retirementFactFindResult.error,
    retirementFactFindResult.fetching,
    userRetirementAge,
    userRetirementDate,
  ]);

  return result;
};

export { useRetirementDate };
