import { TextInput } from "../../components";

type InputMobileNumberProps = {
  error?: string;
  value: string;
  onChange: (e: any) => void;
  onBlur: () => void;
  autoFocus?: boolean;
};

const InputMobileNumber = ({
  error,
  value,
  onChange,
  onBlur,
  autoFocus,
}: InputMobileNumberProps) => {
  return (
    <TextInput
      className="flex-1"
      placeholder="Enter mobile number excluding country code"
      error={error}
      value={value ?? ""}
      onChange={(e) => onChange(e.target.value)}
      onBlur={onBlur}
      aria-label="Input phone number"
      align="left"
      inputMode="numeric"
    />
  );
};

export { InputMobileNumber };
