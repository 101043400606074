import { Button, Icon, Spinner, useAuth, useToaster } from "@multiply/lib";
import debounce from "lodash/debounce";
import get from "lodash/get";
import set from "lodash/set";
import { useMemo } from "react";
import { ConversationForm } from "../../components/ConversationForm";
import { ConversationPageTemplate } from "../../components/ConversationPageTemplate";
import {
  PrsaPensionApplication,
  QuestionGroup,
  useGetFieldsQuery,
  useGetPrsaPensionApplicationQuery,
  useUpdatePensionApplicationMutation,
} from "../../graphqlTypes";
import { flattenObject } from "../../utils";

const getDefaultValues = (
  prsaPensionApplication: {
    prsaPensionApplication: Partial<PrsaPensionApplication>;
  },
  questionGroups: QuestionGroup[]
) => {
  const defaultValues: { [field: string]: any } = {};

  questionGroups.forEach((questionGroup) => {
    questionGroup.questions.forEach((question) => {
      let value = get(prsaPensionApplication, question.key);
      if (value && typeof value === "object" && "float" in value) {
        value = value.float;
      }
      set(defaultValues, question.key, value);
    });
  });

  return defaultValues;
};

type PrsaFactfindPageProps = {
  context: string;
  onDone: (factfind: PrsaPensionApplication) => Promise<void>;
  title?: string;
  subTitle?: string;
  cta?: string;
};

const PrsaFactfindPage = ({
  context,
  onDone,
  title,
  subTitle,
  cta,
}: PrsaFactfindPageProps) => {
  const { userId, loggedIn } = useAuth();
  const { addToast } = useToaster();

  const [factFindResult] = useGetPrsaPensionApplicationQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const [fieldsResult] = useGetFieldsQuery({
    variables: { userId: userId ?? "", context },
    pause: !loggedIn,
  });

  const [updatePensionFactfindResult, updatePensionFactfind] =
    useUpdatePensionApplicationMutation();

  const onChange = useMemo(
    () =>
      debounce(async (values) => {
        await updatePensionFactfind({
          input: {
            ...values,
            userId: userId ?? "",
            context,
          },
        });
      }, 300),
    [updatePensionFactfind, userId, context]
  );

  const onSubmit =
    (onValidationError: (errors: any) => void) => async (values: any) => {
      try {
        const result = await updatePensionFactfind({
          input: {
            ...values,
            userId: userId ?? "",
            context,
          },
        });
        if (result.data?.updatePensionApplication.validationErrors) {
          onValidationError({
            factfind: result.data?.updatePensionApplication.validationErrors,
          });
          return;
        }

        if (!result.data?.updatePensionApplication.success) {
          addToast({
            message:
              result.data?.updatePensionApplication.error?.message ??
              "Something went wrong",
            icon: Icon.Error,
          });
          return;
        }

        if (result.data?.updatePensionApplication.prsaPensionApplication) {
          await onDone(
            result.data?.updatePensionApplication.prsaPensionApplication
          );
        }
      } catch (error) {
        console.error("ERROR", error);
      }
    };

  const prsaPensionApplication = factFindResult?.data?.prsaPensionApplication;

  const questionGroups = fieldsResult.data?.factfindFields.questionGroups;

  const error = factFindResult.error ?? fieldsResult.error;

  const defaultValues = getDefaultValues(
    { prsaPensionApplication: prsaPensionApplication ?? {} },
    questionGroups ?? []
  );

  return (
    <ConversationPageTemplate subTitle={subTitle}>
      {error ? (
        <div className="w-full flex items-center justify-center h-[65vh]">
          <p className="text-action-error text-t12 sm:text-t18">
            {error.message}
          </p>
        </div>
      ) : prsaPensionApplication && questionGroups ? (
        <ConversationForm
          className="my-[15vh]"
          onChange={onChange}
          onSubmit={onSubmit}
          loading={updatePensionFactfindResult.fetching}
          errors={flattenObject({
            prsaPensionApplication:
              updatePensionFactfindResult.data?.updatePensionApplication
                ?.validationErrors,
          })}
          defaultValues={defaultValues}
          questionGroups={questionGroups}
          renderSubmit={({ onSubmit, disabled, ref }) => (
            <Button ref={ref} disabled={disabled} onClick={onSubmit}>
              {cta ?? "Next"}
            </Button>
          )}
        />
      ) : (
        <div className="w-full flex items-center justify-center h-[65vh]">
          <Spinner />
        </div>
      )}
    </ConversationPageTemplate>
  );
};

export { PrsaFactfindPage };
