import { Outlet } from "react-router-dom";
import { Sidebar } from "../Sidebar";
import { BackButton } from "../BackButton";
import classNames from "classnames";

type ViewPortSizes = {
  mobile?: boolean;
  desktop?: boolean;
};

type BreadcrumbLayoutProps = {
  title?: React.ReactNode | null;
  backButton?: ViewPortSizes | null;
  backToDestination?: string | null;
};
const BreadcrumbLayout = ({
  title,
  backButton,
  backToDestination,
}: BreadcrumbLayoutProps) => {
  return (
    <div className="flex flex-col lg:flex-row">
      <Sidebar className="lg:sticky lg:top-0 hidden lg:block shrink-0" />
      <main className="min-h-screen flex flex-col flex-1 min-w-0">
        <section className="text-t16 text-font-links px-max-screen lg:px-max-screen-lg">
          {title && (
            <p className="pt-36 pb-18 lg:pt-60" data-testid="breadcrumb-title">
              {title}
            </p>
          )}
          <BackButton
            backToDestination={backToDestination}
            className={classNames("pl-0", {
              "pb-16 sm:pb-40 text-t21": title,
              "py-16 sm:py-36 text-t16 my-24 sm:my-0": !title,
              "max-md:hidden": !backButton?.mobile,
              "lg:hidden": !backButton?.desktop,
            })}
          />
        </section>
        <div className="mb-32 flex flex-1">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export { BreadcrumbLayout };
