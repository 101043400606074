import { BookAdviserCallIcon, useAuth } from "@multiply/lib";
import { useGetPlanAdviceInfoQuery } from "../../graphqlTypes";
import classNames from "classnames";

type AdviserImageProps = {
  className?: string;
};
const AdviserImage = ({ className }: AdviserImageProps) => {
  const { userId, loggedIn } = useAuth();
  const [planResult] = useGetPlanAdviceInfoQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const adviserPhoto = planResult?.data?.plan?.advisorPhoto ?? null;

  return (
    <div className="flex">
      {adviserPhoto ? (
        <img
          src={`data:image/png;base64,${adviserPhoto}`}
          alt="Adviser"
          className={classNames("self-center", className)}
        />
      ) : (
        <BookAdviserCallIcon
          variant="icon"
          size="xxxl"
          className={classNames("self-center", className)}
        />
      )}
    </div>
  );
};

export { AdviserImage };
