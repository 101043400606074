import classNames from "classnames";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { BackButton } from "../BackButton";

type ModalLayoutProps = {
  className?: string;
  children?: React.ReactNode;
};

const ModalLayout: FC<ModalLayoutProps> = ({ children, className }) => {
  return (
    <div
      role="dialog"
      className={classNames(
        "max-h-full sm:h-full overflow-scroll w-full bg-background-primary fixed top-0 p-16 sm:p-32 z-10 overflow-y-auto overscroll-contain",
        className
      )}
      aria-modal={true}
    >
      <BackButton
        className="flex justify-end text-font-links mb-32 text-t16 sm:text-t20 sm:absolute"
        aria-label="Back to previous page"
        autoFocus
      />

      <div className="h-full min-h-screen flex justify-center mt-36 sm:mt-48">
        <Outlet />
      </div>
    </div>
  );
};

export { ModalLayout };
