import { useAnalyticsIfAvailable } from "../../hooks";

const wait = (timeout: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
};

const getHref = (element: HTMLAnchorElement) => {
  return (
    element.getAttribute("href") ??
    element.getAttributeNS("http://www.w3.org/1999/xlink", "href") ??
    element.getAttribute("xlink:href") ??
    element.getElementsByTagName("a")[0]?.getAttribute("href")
  );
};

// Check if a user is opening the link in a new tab
function userNewTab(
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
): boolean {
  if (
    event.ctrlKey ||
    event.shiftKey ||
    event.metaKey ||
    (event.button && event.button === 1)
  ) {
    return true;
  }
  return false;
}

// Check if the link opens in new tab
function linkNewTab(element: HTMLAnchorElement): boolean {
  const href = getHref(element);

  if (element.target === "_blank" && href) {
    return true;
  }
  return false;
}

type TrackedLinkProps = Omit<React.ComponentProps<"a">, "onClick">;

/**
 * This is a drop in replacement for a normal <a> tag but will send an event to segment when it is clicked
 */
const TrackedLink = ({ children, ...props }: TrackedLinkProps) => {
  const analytics = useAnalyticsIfAvailable();

  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = async (
    event
  ) => {
    if (analytics) {
      const element = event.currentTarget;
      const href = getHref(element);

      const opensInNewTab = userNewTab(event) || linkNewTab(element);

      if (opensInNewTab) {
        analytics.track("OPEN_EXTERNAL_LINK", { to: href });
      } else {
        event.preventDefault();

        // wait a maximum of 500ms for the track call to complete
        await Promise.race([
          analytics.track("OPEN_EXTERNAL_LINK", { to: href }),
          wait(500),
        ]);

        if (href) {
          window.location.href = href;
        }
      }
    }
  };

  return (
    <a {...props} onClick={handleClick}>
      {children}
    </a>
  );
};

export { TrackedLink };
