import { useSearchParams } from "react-router-dom";

type goalDetails = {
  goalName?: string | null;
  goalId?: string | null;
};

const useGoalDetailsFromParams = () => {
  const [searchParams] = useSearchParams();

  let thisGoal: goalDetails = {
    goalName: searchParams.get("goalName"),
    goalId: searchParams.get("goalId"),
  };

  return thisGoal;
};

export { useGoalDetailsFromParams };
