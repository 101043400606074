import classNames from "classnames";
import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import {
  getColorFromVariant,
  getDimensionsFromSize,
  IconProps,
} from "./shared";

const BackArrowCircleIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);
  return (
    <svg
      aria-hidden={true}
      data-testid="back arrow circle icon"
      viewBox="0 0 59 59"
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(defaultClassnames, className)}
      {...props}
    >
      <circle cx={29.672} cy={29.406} r={24.672} fill={fill} />
      <path
        d="M28.464 18.035c.226-.233.532-.364.852-.364.32 0 .625.131.851.364.226.233.353.549.353.878 0 .329-.127.645-.353.878l-3.514 3.622h6.07c.85-.01 1.693.154 2.48.482a6.41 6.41 0 0 1 2.109 1.43 6.636 6.636 0 0 1 1.41 2.157 6.799 6.799 0 0 1 .001 5.101 6.636 6.636 0 0 1-1.41 2.157 6.41 6.41 0 0 1-2.109 1.43 6.257 6.257 0 0 1-2.48.483h-11.24a1.172 1.172 0 0 1-.88-.347 1.275 1.275 0 0 1 0-1.786 1.172 1.172 0 0 1 .879-.348h11.241a3.955 3.955 0 0 0 2.84-1.212 4.204 4.204 0 0 0 1.175-2.926c0-.544-.103-1.082-.305-1.584a4.149 4.149 0 0 0-.87-1.343 3.955 3.955 0 0 0-2.84-1.212h-5.15l2.593 2.673c.226.233.353.549.353.878 0 .33-.127.645-.353.878a1.187 1.187 0 0 1-.851.363c-.32 0-.626-.13-.852-.363l-4.996-5.15a1.429 1.429 0 0 1-.4-.995c0-.373.144-.73.4-.994l4.996-5.15Z"
        fill="#5463D6"
      />
      <circle
        cx={29.493}
        cy={29.238}
        r={28.071}
        stroke="#BEC7FF"
        strokeWidth={1.863}
      />
    </svg>
  );
};

export { BackArrowCircleIcon };