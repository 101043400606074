import classNames from "classnames";
import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import {
  getColorFromVariant,
  getDimensionsFromSize,
  IconProps,
} from "./shared";

const SproutCircleIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 59 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      {...props}
    >
      <circle cx={29.688} cy={29.406} r={24.672} fill={fill} />
      <g clipPath="url(#a)">
        <path
          d="M28.997 34.537C17.827 36.263 16.86 24.312 16.86 24.312c7.652-1.182 11.624 3.382 12.267 7.426"
          fill="#5463D6"
          fillOpacity={0.2}
        />
        <path
          d="M28.997 34.537C17.827 36.263 16.86 24.312 16.86 24.312c7.652-1.182 11.624 3.382 12.267 7.426"
          stroke="#5463D6"
          strokeWidth={1.863}
        />
        <path
          d="M29.144 40.484V29.278S27.967 17.63 41.254 17.63c0 0 1.328 13.063-11.808 13.063"
          fill="#5463D6"
          fillOpacity={0.2}
        />
        <path
          d="M29.144 40.484V29.278S27.967 17.63 41.254 17.63c0 0 1.328 13.063-11.808 13.063"
          stroke="#5463D6"
          strokeWidth={1.863}
        />
        <path
          d="M40.825 18.055 29.666 30.367M16.861 24.312l12.16 9.452"
          stroke="#5463D6"
          strokeWidth={1.751}
        />
      </g>
      <circle
        cx={29.509}
        cy={29.238}
        r={28.071}
        stroke="#BEC7FF"
        strokeWidth={1.863}
      />
      <defs>
        <clipPath id="a">
          <path
            fill="#fff"
            transform="translate(15.734 15.939)"
            d="M0 0h27.06v27.06H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export { SproutCircleIcon };
