import classNames from "classnames";
import clamp from "lodash/clamp";

type CounterProps = {
  disabled: boolean;
  className?: string;
  value: number;
  onChange: (percentage: number) => void;
  min?: number;
  max: number;
  increment?: number;
};

const Counter = ({
  disabled,
  className,
  value,
  onChange,
  min = 0,
  max,
  increment = 1,
}: CounterProps) => {
  const calculateNewValue = (add?: boolean) => {
    const newValue = add ? value + increment : value - increment;
    onChange(clamp(newValue, min, max));
  };

  return (
    <div
      className={classNames(
        "flex w-fit gap-20 bg-white rounded-full px-18 items-center",
        className
      )}
    >
      <button
        onClick={() => calculateNewValue()}
        className={`${
          value === 0 ? "text-tools-table-outline" : "text-font-links"
        } text-t73`}
      >
        -
      </button>
      <div className="h-full border-background-primary border-2 flex justify-center items-center min-w-80">
        <p className="text-font-primary">{value}%</p>
      </div>
      <button
        onClick={() => calculateNewValue(true)}
        className={`${
          disabled ? "text-tools-table-outline" : "text-font-links"
        } text-t73`}
        disabled={disabled}
      >
        +
      </button>
    </div>
  );
};

export { Counter };
