import { Card, ContainerMaxSize, TrackedLink, useAuth } from "@multiply/lib";
import { useGetPlanSuitabilityReportLinkQuery } from "../../graphqlTypes";

export function Documents() {
  const { userId, loggedIn, token } = useAuth();

  const [planResult] = useGetPlanSuitabilityReportLinkQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const suitabilityReportLink = planResult?.data?.plan?.suitabilityReportLink;

  return (
    <ContainerMaxSize>
      <div className="w-full flex flex-col sm:pt-80 py-16">
        <div className="lg:flex flex-col gap-120">
          <div className="lg:flex gap-48">
            {suitabilityReportLink && (
              <section className="mb-36 sm:w-400">
                <h1 className="text-t21 sm:text-t27 text-font-primary pb-16">
                  Your documents
                </h1>

                <TrackedLink
                  href={`${suitabilityReportLink}?auth-token=${token}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Card className="p-24 mb-16">
                    <p className="text-t16 sm:text-t21 text-font-secondary">
                      Latest statement of suitability
                    </p>
                  </Card>
                </TrackedLink>
              </section>
            )}

            <section className="mb-36 sm:w-400">
              <h2 className="text-t21 sm:text-t27 text-font-primary pb-16">
                This site
              </h2>
              <TrackedLink
                href="https://cdn.multiply.ai/documents/irish-life/irish_life_digital_advice_portal_terms_of_use.pdf"
                rel="noreferrer"
                target="_blank"
              >
                <Card className="p-24 mb-16">
                  <p className="text-t16 sm:text-t21 text-font-secondary">
                    Terms of use
                  </p>
                </Card>
              </TrackedLink>
              <TrackedLink
                href="https://assets-eu-01.kc-usercontent.com/ffd8d21b-ebd4-0151-55d5-297335c8f50f/c88ce4a9-46d1-43da-bf19-3e08ca23a28e/ILA%2017350%20%28NPI%2006-22%29-M6.pdf"
                rel="noreferrer"
                target="_blank"
              >
                <Card className="p-24 mb-16">
                  <p className="text-t16 sm:text-t21 text-font-secondary">
                    Privacy policy
                  </p>
                </Card>
              </TrackedLink>
              <TrackedLink
                href="https://assets-eu-01.kc-usercontent.com/ffd8d21b-ebd4-0151-55d5-297335c8f50f/39746f00-8406-49c3-a26b-dd08cc67f881/Terms%20of%20Business%20ILFS.pdf"
                rel="noreferrer"
                target="_blank"
              >
                <Card className="p-24 mb-16">
                  <p className="text-t16 sm:text-t21 text-font-secondary">
                    Terms of business
                  </p>
                </Card>
              </TrackedLink>
              <TrackedLink
                href="https://www.irishlife.ie/sites/retail/files/Remuneration_Summary_Document_-_ILFS_Compliance.pdf"
                rel="noreferrer"
                target="_blank"
              >
                <Card className="p-24">
                  <p className="text-t16 sm:text-t21 text-font-secondary">
                    Remuneration Summary
                  </p>
                </Card>
              </TrackedLink>
            </section>
          </div>
          <div className="lg:flex gap-48 flex-wrap">
            <section className="mb-36 sm:w-400">
              <h2 className="text-t21 sm:text-t27 text-font-primary pb-16">
                OnePlan
              </h2>
              <TrackedLink
                href="https://assets-eu-01.kc-usercontent.com/ffd8d21b-ebd4-0151-55d5-297335c8f50f/76789818-e284-49d1-bc96-545b765d6889/OnePlan.pdf"
                rel="noreferrer"
                target="_blank"
              >
                <Card className="p-24 mb-16">
                  <p className="text-t16 sm:text-t21 text-font-secondary">
                    OnePlan Protection Product Booklet
                  </p>
                </Card>
              </TrackedLink>
              <TrackedLink
                href="https://assets-eu-01.kc-usercontent.com/ffd8d21b-ebd4-0151-55d5-297335c8f50f/e6966409-f0c3-4f22-9d3f-bb9afd8bc0ec/OnePlan%20T&Cs.pdf"
                rel="noreferrer"
                target="_blank"
              >
                <Card className="p-24">
                  <p className="text-t16 sm:text-t21 text-font-secondary">
                    OnePlan Protection Terms and conditions
                  </p>
                </Card>
              </TrackedLink>
            </section>
            <section className="mb-36 sm:w-400">
              <h2 className="text-t21 sm:text-t27 text-font-primary pb-16">
                Mortgage Life Insurance
              </h2>
              <TrackedLink
                href="https://cdn.multiply.ai/documents/irish-life/mortgage_insurance_booklet.pdf"
                rel="noreferrer"
                target="_blank"
              >
                <Card className="p-24 mb-16">
                  <p className="text-t16 sm:text-t21 text-font-secondary">
                    Mortgage Life Insurance Product Booklet
                  </p>
                </Card>
              </TrackedLink>
              <TrackedLink
                href="https://cdn.multiply.ai/documents/irish-life/mortgage_insurance_terms.pdf"
                rel="noreferrer"
                target="_blank"
              >
                <Card className="p-24">
                  <p className="text-t16 sm:text-t21 text-font-secondary">
                    Mortgage Life Insurance Terms and conditions
                  </p>
                </Card>
              </TrackedLink>
            </section>
            <section className="mb-36 sm:w-400">
              <h2 className="text-t21 sm:text-t27 text-font-primary pb-16">
                Clear Invest
              </h2>
              <TrackedLink
                href="https://assets-eu-01.kc-usercontent.com/ffd8d21b-ebd4-0151-55d5-297335c8f50f/fb454306-a028-4de5-b626-23b211e6d30e/clear-invest-booklet.pdf"
                rel="noreferrer"
                target="_blank"
              >
                <Card className="p-24 mb-16">
                  <p className="text-t16 sm:text-t21 text-font-secondary">
                    Clear Invest Product Booklet
                  </p>
                </Card>
              </TrackedLink>
              <TrackedLink
                href="https://assets-eu-01.kc-usercontent.com/ffd8d21b-ebd4-0151-55d5-297335c8f50f/8301024e-8b05-48a2-92eb-a79bff1e32a5/MAPS%20Range%20of%20Returns.pdf"
                rel="noreferrer"
                target="_blank"
              >
                <Card className="p-24 mb-16">
                  <p className="text-t16 sm:text-t21 text-font-secondary">
                    Clear Invest Range of returns
                  </p>
                </Card>
              </TrackedLink>
              <TrackedLink
                href="https://assets-eu-01.kc-usercontent.com/ffd8d21b-ebd4-0151-55d5-297335c8f50f/3a556dff-7855-4b8c-9f89-43e78064cd6f/Clear%20Invest%20Terms%20and%20Conditions.pdf"
                rel="noreferrer"
                target="_blank"
              >
                <Card className="p-24">
                  <p className="text-t16 sm:text-t21 text-font-secondary">
                    Clear Invest Terms & Conditions
                  </p>
                </Card>
              </TrackedLink>
            </section>
            <section className="mb-36 sm:w-400">
              <h2 className="text-t21 sm:text-t27 text-font-primary pb-16">
                Clear PRSA
              </h2>
              <TrackedLink
                href="https://assets-eu-01.kc-usercontent.com/ffd8d21b-ebd4-0151-55d5-297335c8f50f/d2413a56-af45-4e91-80e2-46fa959bc3b1/PRSA%20Personal%20Pension%20Booklet.pdf"
                rel="noreferrer"
                target="_blank"
              >
                <Card className="p-24 mb-16">
                  <p className="text-t16 sm:text-t21 text-font-secondary">
                    Clear PRSA Product Booklet
                  </p>
                </Card>
              </TrackedLink>
              <TrackedLink
                href="https://cdn.multiply.ai/documents/irish-life/clear_prsa_terms.pdf"
                rel="noreferrer"
                target="_blank"
              >
                <Card className="p-24 mb-16">
                  <p className="text-t16 sm:text-t21 text-font-secondary">
                    Clear PRSA Terms & Conditions
                  </p>
                </Card>
              </TrackedLink>
            </section>
          </div>
        </div>
      </div>
    </ContainerMaxSize>
  );
}
