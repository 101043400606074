export function Library() {
  return (
    <div className="h-screen w-full sm:px-48 sm:pb-60 sm:pt-80 p-16">
      <h1 className="text-t20 sm:text-t27 text-font-primary">Coming soon</h1>
      <p className="text-t16 sm:text-t20 text-font-secondary pb-32">
        Relevant news, financial education and more
      </p>
    </div>
  );
}
