import { PolicyType } from "../graphqlTypes";

const getPolicyNameFromType = (type: PolicyType | string | undefined) => {
  switch (type) {
    case PolicyType.LifeCover:
      return "Life Cover";
    case PolicyType.SpecifiedIllnessCover:
      return "Specified Illness Cover";
    case PolicyType.IncomeProtection:
      return "Income Protection / Bill Cover";
    case PolicyType.HealthInsurance:
      return "Health Insurance";
    default:
      return "Unknown";
  }
};
export { getPolicyNameFromType };
