import classNames from "classnames";
import { forwardRef } from "react";
import { useId } from "../../hooks";

export type ButtonSize = "none" | "sm" | "md" | "lg";
export type ButtonVariant = "primary" | "secondary" | "ghost" | "plain";

export type ButtonProps = {
  size?: ButtonSize;
  variant?: ButtonVariant;
  children?: React.ReactNode;
  className?: string;
  iconRight?: React.ReactNode;
  iconLeft?: React.ReactNode;
} & React.ComponentProps<"button">;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { size, variant, className, children, iconRight, iconLeft, ...buttonProps },
    ref
  ) => {
    const defaultId = useId();
    const id = buttonProps.id ?? defaultId;

    const buttonClasses = getButtonClasses({
      size,
      variant,
      className,
      icons: iconLeft || iconRight ? true : false,
    });

    return (
      <button
        ref={ref}
        {...buttonProps}
        type={buttonProps.type ?? "button"}
        id={id}
        className={buttonClasses}
      >
        {iconLeft}
        {children}
        {iconRight}
      </button>
    );
  }
);

type ButtonClassesProps = {
  size?: ButtonSize;
  variant?: ButtonVariant;
  className?: string;
  icons?: boolean;
};
const getButtonClasses = ({
  size = "md",
  variant,
  className,
  icons,
}: ButtonClassesProps) => {
  const getDefaultClasses = (variant: string) => {
    switch (variant) {
      case "ghost":
        return "h-fit min-h-60 min-w-180 text-button-ghost-txt bg-button-ghost-background border border-button-ghost-border hover:text-button-ghost-txt-hover hover:bg-button-ghost-background-hover disabled:text-button-ghost-txt-disabled disabled:bg-button-ghost-background-disabled disabled:border-button-ghost-border-disabled font-semibold";

      case "primary":
        return "h-fit min-h-60 min-w-180 text-button-primary-txt bg-button-primary-background hover:text-button-primary-txt-hover hover:bg-button-primary-background-hover disabled:text-button-primary-txt-disabled disabled:bg-button-primary-background-disabled font-semibold";

      case "secondary":
        return "h-fit min-h-60 min-w-180 text-button-secondary-txt bg-button-secondary-background border-0 hover:text-button-secondary-txt-hover hover:bg-button-secondary-background-hover disabled:text-button-secondary-txt-disabled disabled:bg-button-secondary-background-disabled font-semibold";

      default:
        return "rounded-none px-0 text-left text-font-links hover:text-font-links-hovered disabled:text-button-primary-txt-disabled";
    }
  };

  const defaultClasses = getDefaultClasses(variant ?? "primary");
  return classNames(
    "text-center flex items-center justify-center rounded-full px-24 py-8 h-fit w-fit",
    {
      "text-t16": size === "sm",
      "text-t21": size === "md",
      "text-t27": size === "lg",
      "flex justify-between items-center gap-x-8": icons,
    },
    defaultClasses,
    className
  );
};

export { Button, getButtonClasses };
