import { Link } from "react-router-dom";
import { FactfindFieldValue } from "../../utils";
import { Card } from "@multiply/lib";

type SettingsCardProps = {
  label: string;
  value: FactfindFieldValue;
  fieldName: string;
  stale?: boolean;
};

const SettingsCard = ({
  label,
  value,
  fieldName,
  stale = false,
}: SettingsCardProps) => {
  const convertEnumToLowercaseString = (enumString: string) => {
    return enumString.split("_").join(" ").toLowerCase();
  };
  const convertToSentenceCase = (string: string) => {
    if (!string) return "";
    return string
      .split(", ")
      .map((sentence) =>
        sentence
          .split(" ")
          .map((word, index) =>
            index === 0 ? word[0].toUpperCase() + word.slice(1) : word
          )
          .join(" ")
      )
      .join(", ");
  };

  return (
    <Link to={`/settings/edit-personal-details/edit/${fieldName}`}>
      <Card className="flex justify-between gap-14 p-18 mb-16 sm:w-400 bg-white hover:bg-background-hover">
        <div>
          <p className="text-font-primary">{label}</p>
          {typeof value === "string" ? (
            <p className="text-t12 text-font-secondary">
              {convertToSentenceCase(
                convertEnumToLowercaseString(value) ?? "Not added"
              )}
            </p>
          ) : Array.isArray(value) ? (
            <p className="text-t12 text-font-secondary">
              {value.length > 0
                ? value.map(({ age }) => age).join(", ")
                : "Not added"}
            </p>
          ) : (
            <p className="text-t12 text-font-secondary">
              {value ?? "Not added"}
            </p>
          )}
        </div>
        <p className="text-font-links text-t12">Edit</p>
      </Card>
    </Link>
  );
};

export { SettingsCard };
