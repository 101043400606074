import { differenceInMilliseconds } from "date-fns";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import { Goal } from "../../graphqlTypes";
import { RetirementDetails } from "../../hooks";
import { Wealth } from "../PlanGraph";

/**
 * addRetirementToWealth - nserts a retirment wealth item into the wealth array at the closest date to the retirement date
 * @param wealth
 * @param retirementDateString
 * @param age
 * @returns ammended wealth array
 */
const addRetirementToWealth = (
  wealth: Wealth[],
  retirementDateString: string,
  age: number
) => {
  const retirementDate = new Date(retirementDateString);

  let closestIndex: number | null = null;
  wealth.forEach((value, index) => {
    if (!value?.date) {
      return;
    }

    const currentDate = new Date(value.date);
    if (currentDate.getFullYear() !== retirementDate.getFullYear()) {
      return;
    }

    if (!closestIndex) {
      closestIndex = index;
      return;
    }

    const closestDate = wealth[closestIndex]?.date;
    if (closestDate) {
      const closestDifference = Math.abs(
        differenceInMilliseconds(new Date(closestDate), retirementDate)
      );
      const currentDifference = Math.abs(
        differenceInMilliseconds(currentDate, retirementDate)
      );

      if (currentDifference < closestDifference) {
        closestIndex = index;
      }

      return;
    }
  });

  if (!closestIndex) {
    return wealth;
  }

  const wealthItemWithRetirement = merge({}, wealth[closestIndex], {
    age,
    goals: [
      {
        name: "Retirement",
        id: "RETIREMENT",
        targetDate: retirementDateString,
      },
    ],
  });

  return [
    ...wealth.slice(0, closestIndex),
    wealthItemWithRetirement,
    ...wealth.slice(closestIndex + 1),
  ];
};

/**
 * getWealthRange - the range is set to the earliest retirement date of either the user and/or partner
 * @param retirementDateResult
 * @param wealthProjection
 * @returns wealth array up to the earliest retirement date
 */
const getWealthRange = (
  retirement: RetirementDetails,
  wealthProjection: Wealth[]
) => {
  const wealthProjectionWithRetirement =
    retirement?.retirementDate && retirement?.retirementAge
      ? addRetirementToWealth(
          wealthProjection,
          retirement.retirementDate.toISOString(),
          retirement.retirementAge
        )
      : wealthProjection;

  const wealthProjectionUpToRetirement = wealthProjectionWithRetirement.filter(
    (event) => {
      if (retirement?.retirementDate) {
        return (
          new Date(event?.date).getFullYear() <=
          new Date(retirement.retirementDate).getFullYear()
        );
      }
      return null;
    }
  );

  return wealthProjectionUpToRetirement;
};

/**
 * getRangeInYears - returns the wealth array with the interval of years
 * @param wealthRange
 * @returns wealth array with the interval of years
 */
const getRangeInYears = (wealthRange: Wealth[]) => {
  if (wealthRange.length === 0) {
    return [] as Wealth[];
  }

  let wealthCopy = cloneDeep(wealthRange);
  let rangeInYears = [...wealthCopy.slice(11, 12), ...wealthCopy.slice(23)];

  let goalsInFirstYear: (Goal | null)[] = [];
  wealthCopy.slice(0, 12).forEach((wealthItem) => {
    if (wealthItem?.goals && wealthItem?.goals.length > 0)
      goalsInFirstYear = [...goalsInFirstYear, ...wealthItem?.goals];
  });

  let goalsInSecondYear: (Goal | null)[] = [];
  wealthCopy.slice(12, 24).forEach((wealthItem) => {
    if (wealthItem?.goals && wealthItem?.goals.length > 0)
      goalsInSecondYear = [...goalsInSecondYear, ...wealthItem?.goals];
  });

  if (rangeInYears[0]) {
    rangeInYears[0].goals = [...goalsInFirstYear];
  }
  if (rangeInYears[1]) {
    rangeInYears[1].goals = [...goalsInSecondYear];
  }

  return rangeInYears;
};

export { addRetirementToWealth, getWealthRange, getRangeInYears };
