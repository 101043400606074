import { yupResolver } from "@hookform/resolvers/yup";
import { startOfDay, subYears } from "date-fns";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { BiologicalSex } from "../../graphqlTypes";
import { MAXIMUM_AGE, transformDate } from "../../utils";

export type IntroFormValues = {
  firstName: string | null;
  lastName: string | null;
  dob: string;
  sex: BiologicalSex | null;
};

export const defaultIntroValidationSchema: yup.SchemaOf<IntroFormValues> = yup
  .object()
  .shape({
    firstName: yup.string().required("Please provide a first name").nullable(),
    lastName: yup.string().required("Please provide a last name").nullable(),
    dob: yup
      .date()
      .transform((value, originalValue) => transformDate(originalValue))
      .typeError('Please enter a valid date in the format "DD-MM-YYYY"')
      .max(subYears(startOfDay(new Date()), 18), "You must be 18 or over")
      .min(
        subYears(startOfDay(new Date()), MAXIMUM_AGE),
        `You must be younger than ${MAXIMUM_AGE - 1}`
      )
      .required("Please provide a date of birth")
      .nullable(),
    sex: yup.string().required("Please provide an answer").nullable(),
  })
  .defined();

type UseIntroFormArgs = {
  defaultValues?: Partial<IntroFormValues>;
  validationSchema?: yup.SchemaOf<IntroFormValues>;
};

const useIntroForm = (args?: UseIntroFormArgs) => {
  const defaultValues = args?.defaultValues;

  const validationSchema =
    args?.validationSchema ?? defaultIntroValidationSchema;

  const form = useForm<IntroFormValues>({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  return form;
};

export { useIntroForm };
