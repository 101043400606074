import { Button, ButtonLink, Card, useAuth, Checkbox } from "@multiply/lib";
import { Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  TextInput,
  IrishLifeLogoLeftCorner,
  PasswordInput,
  IrishLifeDisclaimerText,
} from "../../components";
import { SignupFormValues, useSignupForm } from "../../hooks";

export function SignUp() {
  // Todo: where does those links go?

  const { control, formState, handleSubmit } = useSignupForm();
  const { isDirty, isValid, isSubmitting } = formState;
  const { signUpWithEmailAndPassword } = useAuth();
  const navigate = useNavigate();

  const onSubmit = async ({ email, password }: SignupFormValues) => {
    await signUpWithEmailAndPassword({ email, password });
    navigate("/advice");
  };

  return (
    <div className="h-screen w-screen flex flex-col">
      <div className="flex justify-between">
        <IrishLifeLogoLeftCorner className="flex place-self-start" />
        <ButtonLink className="pt-48 pr-48" to="/advice">
          Log in
        </ButtonLink>
      </div>
      <section className="flex-1 flex items-center justify-center">
        <Card className=" px-160 pt-80 self-center">
          <form className="flex flex-col max-w-520">
            <h1 className="text-t27 text-font-primary text-center mb-60">
              Create your account
            </h1>

            <Controller
              control={control}
              name="email"
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
              }) => (
                <TextInput
                  className="mb-36"
                  label="Email"
                  placeholder="Enter your email"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />

            <Controller
              control={control}
              name="password"
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
              }) => (
                <PasswordInput
                  className="mb-8"
                  label="Create a password"
                  placeholder="Enter a password"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />

            <p className="text-font-secondary text-t16 mb-48">
              Password must be at least 8 characters, contain at least one
              lowercase letter, one uppercase letter, and one numeric digit.
            </p>
            <Controller
              control={control}
              name="termsBusiness"
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
              }) => (
                <Checkbox
                  className="mb-16"
                  checked={value}
                  onChange={onChange}
                  key="123"
                  onBlur={onBlur}
                >
                  I have received the Irish Life Financial Services{" "}
                  <span className="text-font-links">Terms of Business</span>
                </Checkbox>
              )}
            />
            <Controller
              control={control}
              name="privacyPolicy"
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
              }) => (
                <Checkbox
                  className="mb-16"
                  checked={value}
                  onChange={onChange}
                  key="123"
                  onBlur={onBlur}
                >
                  I accept the{" "}
                  <span className="text-font-links">
                    Irish Life Terms of use & Privacy Policy
                  </span>
                </Checkbox>
              )}
            />
            <Controller
              control={control}
              name="offers"
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
              }) => (
                <Checkbox
                  className="mb-32"
                  checked={value}
                  onChange={onChange}
                  key="234"
                  onBlur={onBlur}
                >
                  Optional: I agree to being contacted about offers and services
                  from within the Irish Life Group via email, SMS, phone or
                  post. I understand that I can change my mind at any time and
                  opt out via my profile settings, by emailing{" "}
                  <span className="text-font-links">
                    {" "}
                    dataprotectionqueries@irishlife.ie
                  </span>{" "}
                  or by using the opt out link in any emails or texts sent to
                  me."
                </Checkbox>
              )}
            />
            <Button
              disabled={!isValid || !isDirty || isSubmitting}
              className="mb-60"
              onClick={handleSubmit(onSubmit)}
            >
              Sign up
            </Button>
          </form>
        </Card>
      </section>

      <IrishLifeDisclaimerText className="text-primary text-t16 text-center mb-60" />
    </div>
  );
}
