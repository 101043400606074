import { ButtonLink, Card } from "@multiply/lib";

const PensionCalculatorCard = () => {
  return (
    <section>
      <Card className="flex flex-col p-20 sm:p-40">
        <h1 className="text-t18 sm:text-t27 text-font-primary font-semibold">
          Try our pension calculator
        </h1>
        <p className="text-t16 sm:text-t23 text-font-secondary mt-8">
          See how your pension fund could look if you changed your
          contributions, target income or retirement age.
        </p>
        <ButtonLink
          variant="secondary"
          className="min-w-fit max-w-fit px-0 md:py-18 max-h-fit h-fit min-h-fit text-t16 md:text-t21 mt-20"
          size="sm"
          to="/pensions/pension-details/pension-calculator"
        >
          Get started
        </ButtonLink>
      </Card>
    </section>
  );
};

export { PensionCalculatorCard };
