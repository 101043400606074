import { isDate, parse } from "date-fns";

export const transformDate = (originalValue: unknown) => {
  if (
    originalValue === null || // This allows dates to be nullable.
    originalValue === "--" // This would occur if a date entry was started but then removed
  )
    return null;
  if (isDate(originalValue)) {
    return originalValue;
  } else if (
    typeof originalValue === "string" &&
    !originalValue.match(/\d{4}-\d{2}-\d{2}/)
  ) {
    return new Date("invalid date");
  } else if (typeof originalValue === "string") {
    return parse(originalValue, "yyyy-MM-dd", new Date());
  } else {
    return new Date("invalid date");
  }
};
