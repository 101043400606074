import { FC } from "react";
import { getDimensionsFromSize, IconProps } from "./shared";

const AvatarIcon: FC<IconProps> = ({ size, className, ...props }) => {
  const { width, height } = getDimensionsFromSize(size);

  return (
    <svg
      aria-hidden={true}
      width={width}
      height={height}
      viewBox="0 0 42 44"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M37.1235 35.395C39.2777 32.8031 40.7759 29.7306 41.4915 26.4372C42.2071 23.1439 42.1189 19.7267 41.2345 16.4746C40.3501 13.2225 38.6954 10.2313 36.4105 7.75398C34.1255 5.27666 31.2775 3.3861 28.1074 2.24224C24.9373 1.09839 21.5383 0.734886 18.1979 1.18249C14.8576 1.63009 11.6742 2.87562 8.91708 4.81372C6.15993 6.75183 3.91012 9.32548 2.35799 12.317C0.805856 15.3085 -0.00294389 18.6298 8.05147e-06 22C0.00114081 26.8992 1.72763 31.6416 4.87651 35.395L4.84651 35.4205C4.95151 35.5465 5.07151 35.6545 5.17951 35.779C5.31451 35.9335 5.46001 36.079 5.59951 36.229C6.01951 36.685 6.45151 37.123 6.90451 37.534C7.04251 37.66 7.18501 37.777 7.32451 37.897C7.80451 38.311 8.29801 38.704 8.80951 39.07C8.87551 39.115 8.93551 39.1735 9.00151 39.22V39.202C12.5147 41.6742 16.7057 43.001 21.0015 43.001C25.2974 43.001 29.4884 41.6742 33.0015 39.202V39.22C33.0675 39.1735 33.126 39.115 33.1935 39.07C33.7035 38.7025 34.1985 38.311 34.6785 37.897C34.818 37.777 34.9605 37.6585 35.0985 37.534C35.5515 37.1215 35.9835 36.685 36.4035 36.229C36.543 36.079 36.687 35.9335 36.8235 35.779C36.93 35.6545 37.0515 35.5465 37.1565 35.419L37.1235 35.395ZM21 9.99997C22.335 9.99997 23.6401 10.3958 24.7501 11.1375C25.8601 11.8792 26.7253 12.9335 27.2362 14.1669C27.7471 15.4003 27.8808 16.7575 27.6203 18.0668C27.3599 19.3762 26.717 20.5789 25.773 21.5229C24.829 22.4669 23.6262 23.1098 22.3169 23.3703C21.0075 23.6307 19.6503 23.497 18.4169 22.9862C17.1835 22.4753 16.1293 21.6101 15.3876 20.5001C14.6459 19.39 14.25 18.085 14.25 16.75C14.25 14.9598 14.9612 13.2429 16.227 11.977C17.4929 10.7111 19.2098 9.99997 21 9.99997ZM9.01051 35.395C9.03653 33.4254 9.83693 31.5453 11.2385 30.1614C12.6402 28.7774 14.5303 28.001 16.5 28H25.5C27.4697 28.001 29.3599 28.7774 30.7615 30.1614C32.1631 31.5453 32.9635 33.4254 32.9895 35.395C29.6998 38.3595 25.4284 40.0001 21 40.0001C16.5716 40.0001 12.3002 38.3595 9.01051 35.395Z" />
    </svg>
  );
};

export { AvatarIcon };
