import classNames from "classnames";
import { useState } from "react";
import { DownArrowIcon } from "@multiply/lib";

type CollapsibleSectionProps = {
  title: React.ReactNode;
  children?: React.ReactNode;
  defaultIsOpen?: boolean;
  className?: string;
};

const CollapsibleSection = ({
  title,
  children,
  defaultIsOpen = false,
  className,
}: CollapsibleSectionProps) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  return (
    <div className={className}>
      <div className="flex bg-background-primary rounded-md">
        <h4 className="text-font-primary text-t21 ml-32 my-12">{title}</h4>
        <div className="flex-1 flex justify-end px-32">
          <button onClick={() => setIsOpen((isOpen) => !isOpen)}>
            <DownArrowIcon
              className={classNames("text-font-primary", {
                "rotate-180": isOpen,
              })}
            />
          </button>
        </div>
      </div>

      {isOpen && <div className="px-32 py-24">{children}</div>}
    </div>
  );
};

export { CollapsibleSection };
