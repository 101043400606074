import {
  Card,
  useAuth,
  useToaster,
  usePolling,
  Button,
  ButtonLink,
  Spinner,
  ArrowRightNoOutline,
  useWindowDimensions,
} from "@multiply/lib";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import {
  useBookAppointmentMutation,
  useGetNextAvailableAppointmentQuery,
  useGetScheduledAppointmentsQuery,
} from "../../graphqlTypes";
import { isFuture, format } from "date-fns";
import { AdviserImage } from "../AdviserImage";
import { useGetUserJourney } from "../../hooks";

type BookAdviserCallProps = {
  hasPlan: boolean;
  className?: string;
  
};

const BookAdviserCall = ({ className, hasPlan }: BookAdviserCallProps) => {
  const { userId } = useAuth();
  const { addToast } = useToaster();
  const navigate = useNavigate();

  const userJourney = useGetUserJourney();

  const { isPhone } = useWindowDimensions();
  const [scheduledAppointmentsResult] = useGetScheduledAppointmentsQuery({
    variables: { userId: userId ?? "" },
  });

  const [
    nextAvailableAppointmentResult,
    refetchNextAvailableAppointmentResult,
  ] = useGetNextAvailableAppointmentQuery({
    variables: { userId: userId ?? "" },
  });

  usePolling(
    [nextAvailableAppointmentResult, refetchNextAvailableAppointmentResult],
    60 * 1000 * 3
  );

  const nextAvailableAppointment =
    nextAvailableAppointmentResult.data?.nextAvailableAppointment;

  const [bookAppointmentResult, bookAppointment] = useBookAppointmentMutation();
  const bookAppointmentError =
    bookAppointmentResult.data?.bookAppointment.error ??
    bookAppointmentResult.error;

  const loading =
    userJourney?.loading ||
    bookAppointmentResult?.fetching ||
    nextAvailableAppointmentResult.fetching;

  const handleBook = async () => {
    if (nextAvailableAppointment) {
      // IL Tracking
      if (typeof dataLayer !== "undefined") {
        dataLayer?.push({
          event: "ButtonClick",
          il_click_url: window.location.href,
          il_click_text: "Book suggested video call",
        });
      }

      const result = await bookAppointment({
        input: {
          userId: userId ?? "",
          startTime: nextAvailableAppointment.startTime,
          reason: reason,
        },
      });

      if (result?.data?.bookAppointment?.success) {
        addToast({ message: "Appointment booked", duration: 5000 });
      }
    }
  };

  const goToBookings = () => {
    navigate(userJourney.callRoute);
  };

  const appointments =
    scheduledAppointmentsResult.data?.scheduledAppointments ?? [];

  const futureAppointments = appointments.filter((appointment) =>
    isFuture(new Date(appointment.startTime))
  );
  const hasFutureAppointments = futureAppointments.length;

  let formattedApptDate = "";
  let reason = "";

  if (hasFutureAppointments) {
    const date = new Date(futureAppointments[0]?.startTime);
    formattedApptDate = format(date, "do MMMMMMM yyyy h:mm a");
    reason = futureAppointments[0]?.reason || "Your Plan";
  }

  const nextAppointmentDate =
    nextAvailableAppointmentResult?.data?.nextAvailableAppointment?.startTime;

  const nextAvDate = () => {
    if (typeof nextAppointmentDate === "string") {
      const nextAvailableDate = new Date(nextAppointmentDate);
      return format(nextAvailableDate, "do MMMMMMM yyyy h:mm a");
    } else if (nextAvailableAppointmentResult.fetching) {
      return <Spinner />;
    } else if (nextAvailableAppointmentResult.error) {
      return null;
    }
  };

  const nextDate = nextAvDate();

  const noAppointmentsAvailable =
    (nextAvailableAppointmentResult.error &&
      nextAvailableAppointmentResult.error.message) ||
    !nextDate;

  return (
    <>
      {hasFutureAppointments ? (
        <Card className={classNames("p-18 sm:p-36", className)}>
          <div className="flex">
            <AdviserImage className="w-53 sm:w-[100px] lg:w-[120px] mr-14 sm:mr-32" />
            <div className="pl-24">
              <section className="flex flex-1 flex-col gap-4">
                <p className="text-t16 sm:text-t21 text-font-secondary">
                  Reminder
                </p>
                <p className="text-t16 sm:text-t27 text-font-primary font-semibold">
                  Attend your appointment
                </p>
              </section>
              <p className="text-t14 sm:text-t21 text-font-primary pt-8">
                Please join the video call on your desktop computer or laptop
              </p>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row border-t-2 pt-20 w-full justify-between items-start gap-y-16 lg:items-center mt-16 md:mt-32">
            <div>
              <p className="text-font-primary sm:text-t21 mb-4">
                Scheduled call
              </p>
              <p className="text-font-primary sm:text-t27 font-bold pr-4">
                {formattedApptDate}
              </p>
            </div>
            <div>
              <p className="text-font-primary sm:text-t21 mb-4">With adviser</p>
              <p className="text-font-primary sm:text-t27 font-bold">
                {futureAppointments[0].advisorName}
              </p>
            </div>
          </div>
        </Card>
      ) : (
        <Card className={classNames("p-18 sm:p-36", className)}>
          <div className="flex">
            {!isPhone && (
              <AdviserImage className="w-53 sm:w-[100px] lg:w-[120px] mr-14 sm:mr-32" />
            )}
            <div className="sm:pl-32 ">
              <div className="flex flex-row gap-x-16 items-center">
                {isPhone && (
                  <AdviserImage className="w-53 sm:w-[100px] lg:w-[120px] mr-14 sm:mr-32" />
                )}
                <section className="flex flex-1 flex-col gap-4">
                  {hasPlan && (
                    <h1 className="text-t16 sm:text-t21 text-font-secondary font-semibold">
                      Invitation:
                    </h1>
                  )}
                  <h2 className="text-t21 sm:text-t27 text-font-primary font-semibold">
                    {hasPlan
                      ? "Kick-off call"
                      : "Book a free call with an adviser"}
                  </h2>
                </section>
              </div>
              <p className="text-t16 sm:text-t21 text-font-tertiary pt-12">
                {hasPlan
                  ? "Get a free walk through of your plan and start putting it into action, with the help of an expert adviser."
                  : "Struggling to create your plan? Instead of using our digital advice service, you can get advice from one of our expert advisers."}
              </p>
            </div>
          </div>
          <div className="flex border-t-2 mt-20 pt-20 md:mt-32 md:pt-32 justify-between items-start flex-col xl:flex-row">
            {loading ? (
              <Spinner className="sm:mr-32 mt-12 xl:mt-0" />
            ) : (
              <div className="flex flex-col md:flex-row w-full justify-between md:mr-12 md:items-center">
                {!noAppointmentsAvailable && (
                  <div className="flex flex-row md:flex-col lg:pt-12 justify-between items-center md:items-start">
                    <div className="flex flex-col items-start">
                      <p className="text-font-primary sm:text-t21 mb-4">
                        {hasPlan ? "Suggested time" : "Next available time"}
                      </p>
                      <div className="text-font-primary sm:text-t27 font-bold">
                        {nextAvDate()}
                      </div>
                    </div>

                    <ButtonLink
                      to={userJourney.callRoute}
                      variant="plain"
                      size="sm"
                      className="w-fit px-1 py-8 text-t16 sm:text-t21 pl-0 pr-0"
                      rightIcon={<ArrowRightNoOutline size="xsm" />}
                    >
                      Change
                    </ButtonLink>
                  </div>
                )}

                <Button
                  onClick={noAppointmentsAvailable ? goToBookings : handleBook}
                  disabled={
                    nextAvailableAppointmentResult.fetching ||
                    !!nextAvailableAppointmentResult.error
                  }
                  variant="secondary"
                  className="min-w-fit max-w-fit px-0 md:py-18 max-h-fit h-fit min-h-fit text-t16 md:text-t21 mt-20 md:mt-0"
                  size="sm"
                >
                  {noAppointmentsAvailable
                    ? "View times"
                    : hasPlan
                    ? "Accept invitation"
                    : "Book a call"}
                </Button>
              </div>
            )}
          </div>

          {bookAppointmentError?.message && (
            <p className="text-action-error mt-24">
              Something went wrong on our end. Please try again or go to
              Settings and contact our support team if you're still having
              issues.
            </p>
          )}
        </Card>
      )}
    </>
  );
};

export { BookAdviserCall };
