import { useAuth } from "@multiply/lib";
import { useGetPersonQuery } from "../../graphqlTypes";

const useIsIntroComplete = () => {
  const { userId, loggedIn } = useAuth();

  const [result] = useGetPersonQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  return {
    error: result.error,
    loading: result.fetching || !loggedIn,

    complete: Boolean(
      result?.data?.person?.firstName &&
        result?.data?.person?.lastName &&
        result?.data?.person?.dateOfBirth &&
        result?.data?.person?.sex &&
        result?.data?.person?.address &&
        result?.data?.person?.phoneNumber
    ),
  };
};

export { useIsIntroComplete };
