import { PensionType } from "../graphqlTypes";

const getPensionNameFromType = (type: PensionType | undefined | null) => {
  switch (type) {
    case PensionType.Amrf:
      return "Approved Minimum Retirement Fund (AMRF)";
    case PensionType.Arf:
      return "Approved Retirement Fund (ARF)";
    case PensionType.AvcPension:
      return "Additional Voluntary Contribution (AVC)";
    case PensionType.CompanyPension:
      return "Company Pension";
    case PensionType.DefinedBenefit:
      return "Company/(Defined Benefit)";
    case PensionType.DefinedContribution:
      return "Company/(Defined Contribution)";
    case PensionType.ExecutivePension:
      return "Executive Pension";
    case PensionType.PerformancePrsa:
      return "Performance PRSA";
    case PensionType.PersonalPension:
      return "Retirement Annuity Contract";
    case PensionType.PersonalRetirementBond:
      return "Personal Retirement Bond (PRB)";
    case PensionType.StandardPrsa:
      return "Personal Retirement Savings Account (PRSA)";
    case undefined:
    case null:
      return "Unknown";
    default:
      const unreachable: never = type;
      console.error(`Unknown type detected: ${unreachable}`);
      return "Unknown";
  }
};
export { getPensionNameFromType };
