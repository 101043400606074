import flagsmith from "flagsmith";
import { FlagsmithProvider as FlagsmithReactProvider } from "flagsmith/react";
import { ComponentProps, useEffect, useState } from "react";
import { useAuth } from "@multiply/lib";

type FlagsmithReactProviderProps = ComponentProps<
  typeof FlagsmithReactProvider
>;
type FlagsmithOptions = FlagsmithReactProviderProps["options"];

const FlagsmithProvider = ({ children }: { children: React.ReactNode }) => {
  const { userId, username } = useAuth();

  const [flagSmithOptions, setFlagsmithOptions] =
    useState<FlagsmithOptions | null>(null);

  useEffect(() => {
    const setOptions = async () => {
      if (process.env.REACT_APP_MOCK_FLAGSMITH === "true") {
        const flagsmithState = await import("../../flagsmith.json");

        setFlagsmithOptions({
          environmentID: flagsmithState.environmentID,
          state: flagsmithState,
        });
      } else {
        setFlagsmithOptions({
          environmentID: process.env.REACT_APP_FLAGSMITH_ENVIRONMENT_ID ?? "",
          preventFetch: true,
        });
      }
    };

    setOptions();
  }, []);

  useEffect(() => {
    if (userId && username && flagsmith.initialised) {
      flagsmith.identify(userId, {
        is_multiply: /multiply.ai$/i.test(username ?? ""),
      });
    }
  }, [userId, username]);

  return (
    <>
      {flagSmithOptions ? (
        <FlagsmithReactProvider
          options={flagSmithOptions}
          flagsmith={flagsmith}
        >
          <>{children}</>
        </FlagsmithReactProvider>
      ) : null}
    </>
  );
};

export { FlagsmithProvider };
