import { TimePicker, useAuth } from "@multiply/lib";
import { formatISO } from "date-fns";
import { useGetAvailableAppointmentsQuery } from "../../graphqlTypes";

const AdviserTimePicker = ({
  start,
  end,
  value,
  onChange,
  label,
  className,
}: {
  start: Date;
  end: Date;
  value: Date | null;
  onChange: (value: Date | null) => void;
  label?: string;
  className?: string;
}) => {
  const day = new Date((start.getTime() + end.getTime()) / 2);
  const { userId } = useAuth();

  const [availableAppointmentsResult] = useGetAvailableAppointmentsQuery({
    variables: {
      userId: userId ?? null,
      date: formatISO(day, { representation: "date" }),
    },
  });

  const times =
    availableAppointmentsResult.data?.availableAppointments.map(
      (appointment) => new Date(appointment.startTime)
    ) ?? [];

  return (
    <TimePicker
      className={className}
      value={value}
      onChange={onChange}
      times={times}
      loading={availableAppointmentsResult.fetching}
      label={label}
    />
  );
};

export { AdviserTimePicker };
