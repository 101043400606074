import { useEffect } from "react";
import { Control, useFieldArray } from "react-hook-form";
import { GoalsFormValues } from "./types";

const useGoalsFields = ({
  control,
  defaultValues,
}: {
  control: Control<GoalsFormValues>;
  defaultValues: GoalsFormValues;
}) => {
  const {
    fields: goalsFields,
    append,
    remove,
    update,
    move,
  } = useFieldArray({ name: "goals", control });

  // keep goals field array up to date with defaultValues
  useEffect(() => {
    // look for new goals which don't exist in the current fields
    const defaultGoals = defaultValues?.goals ?? [];
    const missingGoals = defaultGoals.filter((goal) => {
      const existsInFields = goalsFields.find(
        (goalField) => goalField.goalId === goal.goalId
      );
      return !existsInFields;
    });

    if (missingGoals.length > 0) {
      // add them to to fields
      append(missingGoals);
    }
  }, [append, defaultValues, goalsFields]);


  // remove deleted goal from goal fields array
  useEffect(() => {
    // look for old goal which doesn't exist in the default values anymore
    const surplusGoals = goalsFields.filter((goalField) => {
      const existsInDefaultValues = defaultValues.goals.find(
        (goal) => goalField.goalId === goal.goalId
      );
      return !existsInDefaultValues;
    });

    // find the indexes of the goals to delete
    const surplusGoalIndexes = surplusGoals.map((surplusGoal) =>
      goalsFields.indexOf(surplusGoal)
    );

    if (surplusGoalIndexes.length > 0) {
      // remove them from fields
      remove(surplusGoalIndexes);
    }
  }, [defaultValues, goalsFields, remove]);

  const handleChangeGoalIndex = (oldIndex: number) => (newIndex: number) => {
    move(oldIndex, newIndex);
  };

  return {
    goalsFields,
    handleChangeGoalIndex,
    update,
  };
};

export { useGoalsFields };
