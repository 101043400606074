const MonthlyTakeHomeExplanation = () => {
  return (
    <main className="w-full h-full">
      <h1 className="text-t27 text-font-primary font-bold pb-36">
        What if I don't get paid monthly?
      </h1>

      <h2 className="text-font-primary font-bold pb-16">
        If you're paid weekly
      </h2>
      <p className="text-font-primary pb-36">
        Multiply your latest net weekly wage by 52, and divide by 12.
      </p>
      <h3 className="text-font-primary font-bold pb-16">
        If you're paid four-weekly
      </h3>
      <p className="text-font-primary">
        Multiply your latest net four-weekly wage by 13, and divide by 12.
      </p>
    </main>
  );
};

export { MonthlyTakeHomeExplanation };
