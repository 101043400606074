import { Transition } from "@headlessui/react";
import classNames from "classnames";
import { Toast as ToastType } from "./ToasterContext";
import { ToastIcon } from "./ToastIcon";
import { useToaster } from "./useToaster";

type ToastProps = {
  toast: ToastType;
};

const Toast = ({ toast }: ToastProps) => {
  return (
    <Transition
      className="flex items-center justify-center rounded bg-white shadow-primary text-font-primary p-12"
      appear
      show={!toast.isExpired}
      enter="transition ease-in-out duration-300"
      enterFrom="opacity-0 translate-y-24"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in-out duration-500"
      leaveFrom="opacity-100 translate-x-0"
      leaveTo="opacity-0 translate-x-60"
      afterLeave={toast.remove}
      onMouseEnter={toast.pause}
      onMouseLeave={toast.resume}
      onClick={toast.expire}
    >
      <p className="mr-4">{toast.message}</p>
      <ToastIcon icon={toast.icon} />
    </Transition>
  );
};

type ToastsProps = {
  className?: string;
};

const Toasts = ({ className }: ToastsProps) => {
  const { toasts } = useToaster();

  return (
    <>
      {toasts.map((toast, index) => (
        <div
          key={toast.id}
          className={classNames(
            "z-50 fixed top-24 right-18 sm:top-56 sm:right-60 transition duration-300",
            className
          )}
          style={{ transform: `translateY(${(50 + 12) * index}px)` }}
        >
          <Toast toast={toast} />
        </div>
      ))}
    </>
  );
};

export { Toasts };
