import {
  ArrowRightCircle,
  Button,
  Card,
  ContainerMaxSize,
  Spinner,
  useAuth,
} from "@multiply/lib";
import holistic_upgrade from "../../assets/images/holistic_upgrade_mobile.png";
import { useSyncUserMutation } from "../../graphqlTypes";
import { useNavigate } from "react-router-dom";
import { UserJourneyTypes } from "../../globalTypes";

const HolisticUpgrade = () => {
  const { userId } = useAuth();
  const [syncUserResult, syncUser] = useSyncUserMutation();
  const navigate = useNavigate();

  const handleUpgrade = async () => {
    try {
      const result = await syncUser({
        input: {
          userId: userId ?? "",
          userJourney: UserJourneyTypes.Holistic,
        },
      });

      if (result.data?.syncUser?.success) {
        navigate("/advice");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {syncUserResult.fetching ? (
        <Spinner />
      ) : (
        <ContainerMaxSize className="lg:mt-80">
          <Card className="flex flex-col lg:flex-row lg:max-w-7xl">
            <div
              className="lg:w-[45%] min-h-200 bg-center bg-cover xl:bg-right lg:order-last rounded-t-lg lg:rounded-r-lg lg-rounded-l-none"
              style={{ backgroundImage: `url(${holistic_upgrade})` }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
                style={{ width: "33%", height: "100%" }}
                className="relative top-0 -left-1 opacity-0 lg:opacity-100"
              >
                <polygon stroke="white" fill="white" points="0,0 100,0 0,100" />
              </svg>
            </div>

            <section className="m-18 lg:my-24 lg:ml-40 lg:w-[55%]">
              <h1 className="text-t27 lg:text-t32 text-font-primary font-semibold mb-12">
                Get your free personalised financial plan
              </h1>
              <div className="text-t16 text-font-secondary flex flex-col space-y-12">
                <p>
                  Now that you've got pension planning sorted, why not take
                  control of the rest of your finances?
                </p>
                <p className="lg:font-semibold">
                  With our free financial plan you can:
                </p>

                <div className="flex flex-col lg:flex-row">
                  <ul className="list-tick list-outside ml-12">
                    <li className="pl-4">
                      Get a clear picture of your finances
                    </li>
                    <li className="pl-4">
                      Protect the things that matter most
                    </li>
                    <li className="lg:hidden pl-4">
                      Invest with potential to grow your money
                    </li>
                    <li className="lg:hidden pl-4">
                      Create a financial plan for your whole family
                    </li>
                  </ul>
                  <ul className="hidden lg:inline list-tick list-outside ml-60 mb-20">
                    <li className="pl-4">
                      Invest with potential to grow your money
                    </li>
                    <li className="pl-4">
                      Create a financial plan for your whole family
                    </li>
                  </ul>
                </div>

                <p>
                  As you've already given us some of the information we need, it
                  should only take a few minutes to get your plan.  
                </p>

                <div className="flex justify-center lg:justify-start pt-8 md:pt-18 pb-40">
                  <Button onClick={handleUpgrade}>
                    <div className="flex items-center gap-12">
                      Get a full plan for free
                      <ArrowRightCircle size="lg" />
                    </div>
                  </Button>
                </div>
              </div>
            </section>
          </Card>
        </ContainerMaxSize>
      )}
    </>
  );
};

export { HolisticUpgrade };
