import { Control, Controller, useWatch } from "react-hook-form";
import {
  CurrencyInput,
  DateInput,
  InputMortgageType,
  InputOwners,
  InputReference,
  InputProvider,
} from "../../components";
import { InputYesNo, NumberInput } from "@multiply/lib";
import { DebtField } from "../../globalTypes";

type DebtFieldsProps = {
  fields: DebtField[];
  control: Control<any, any>;
};

const DebtFields = ({ fields, control }: DebtFieldsProps) => {
  const arePaymentsUpToDate = useWatch({
    control,
    name: "arePaymentsUpToDate",
  });

  const renderField = (field: DebtField) => {
    const fieldName = typeof field === "string" ? field : field.fieldName;
    const label = typeof field === "string" ? undefined : field.label;

    return (
      <Controller
        name={fieldName}
        control={control}
        render={({
          field: { value, onChange, onBlur },
          fieldState: { error },
        }) => {
          switch (fieldName) {
            case "balance":
              return (
                <CurrencyInput
                  label={label ?? "Estimated Balance"}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  key={fieldName}
                />
              );
            case "arePaymentsUpToDate":
              return (
                <InputYesNo
                  label={label ?? "Are you up to date on your payments?"}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  key={fieldName}
                />
              );

            case "maturityDate":
              return (
                <DateInput
                  className="md:col-span-2"
                  label={label ?? "Maturity Date"}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  key={fieldName}
                />
              );

            case "name":
              return (
                <InputReference
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error}
                  key={fieldName}
                />
              );

            case "owners":
              return (
                <InputOwners
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  key={fieldName}
                />
              );

            case "provider":
              return (
                <InputProvider
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  key={fieldName}
                />
              );

            case "monthlyRepayment":
              return (
                <CurrencyInput
                  label={label ?? "Monthly Repayment"}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  key={fieldName}
                />
              );

            case "clearedOnDeath":
              return (
                <InputYesNo
                  label={label ?? "Cleared on death?"}
                  tooltip="Answer yes if either the debt is automatically cleared or if you have a policy that will pay off this debt if you pass away."
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  key={fieldName}
                />
              );

            case "repaidMonthly":
              return (
                <InputYesNo
                  label={label ?? "Paid off in full each month?"}
                  tooltip=""
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  key={fieldName}
                />
              );

            case "endDate":
              return (
                <DateInput
                  className="md:col-span-2"
                  label={label ?? "End Date"}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  key={fieldName}
                />
              );

            case "mortgageType":
              return (
                <InputMortgageType
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  key={fieldName}
                />
              );

            case "mortgageYearsLeft":
              return (
                <NumberInput
                  label={label ?? "Years left on mortgage"}
                  placeholder="Years remaining"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  key={fieldName}
                  decimals={false}
                />
              );

            case "isMainMortgage":
              return (
                <InputYesNo
                  label={label ?? "Main Mortgage"}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  key={fieldName}
                />
              );

            case "hasPaymentProtectionInsurance":
              return (
                <InputYesNo
                  label={label ?? "Has Payment Protection Insurance?"}
                  tooltip="This pays out a sum of money to help you cover your monthly mortgage repayments if you are unable to work."
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  key={fieldName}
                />
              );

            case "arrears":
              return arePaymentsUpToDate === false ? (
                <CurrencyInput
                  label="How much do you owe in arrears?"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                />
              ) : (
                <></>
              );

            default:
              const unreachable: never = fieldName;
              return unreachable;
          }
        }}
      />
    );
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-x-32 gap-y-32 self-stretch">
      {fields.map(renderField)}
    </div>
  );
};

export { DebtFields };
