import { Combobox, Spinner } from "@multiply/lib";
import { useGetCountriesQuery } from "../../graphqlTypes";

type InputCountryProps = {
  value: string | null;
  onChange: (value: string) => void;
  onBlur: () => void;
  onSubmit?: () => void;
  error?: string;
  label?: React.ReactNode;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
};

const InputCountry = ({
  onChange,
  label,
  placeholder,
  value,
  error,
  onSubmit,
  className,
  disabled,
}: InputCountryProps) => {
  const [countriesResult] = useGetCountriesQuery();
  const countries = countriesResult?.data?.countries ?? [];
  const items = (countries ?? []).map((country) => ({
    label: country ?? "",
    value: country ?? "",
  }));

  const selectedItem = items?.find((item) => item.value === value);

  return countriesResult.fetching ? (
    <Spinner />
  ) : (
    <Combobox
      className={className}
      value={selectedItem}
      onChange={(selectedItem) => {
        onChange(selectedItem?.value ?? "");
      }}
      onSubmit={onSubmit}
      label={label}
      placeholder={placeholder}
      error={error}
      items={items}
      disabled={disabled}
    />
  );
};

export { InputCountry };
