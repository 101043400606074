import { hasOwnProperty } from "./hasOwnProperty";

function itemToString(item: unknown) {
  if (!item) {
    return "";
  }

  if (typeof item === "string") {
    return item;
  }

  if (typeof item === "number") {
    return item.toString();
  }

  if (
    typeof item === "object" &&
    hasOwnProperty(item, "label") &&
    typeof item.label === "string"
  ) {
    return item.label;
  }

  throw new Error(`Could not convert item to string: ${JSON.stringify(item)}`);
}

export { itemToString };
