const MonthlyOutgoingNonEssentialsExplanation = () => {
  return (
    <main className="w-full h-full">
      <h1 className="text-t27 text-font-primary font-bold pb-36">
        What counts as non-essential outgoings?
      </h1>

      <h2 className="text-font-primary font-bold pb-16">
        This is personal to you, but might include
      </h2>
      <ul className="list-disc mb-24 ml-16">
        <li className="text-font-primary pb-12">
          Everyday spending, e.g. buying coffee and eating at restaurants
        </li>
        <li className="text-font-primary pb-12">
          Irregular spending, e.g. holidays and gifts
        </li>
        <li className="text-font-primary pb-12">
          Any monthly subscriptions you could live without
        </li>
      </ul>
      <p className="text-font-primary pb-24">
        We know many of these outgoings can vary month to month. An estimate for
        a typical month's spending is fine.
      </p>

      <h3 className="text-font-primary font-bold pb-16">It doesn't include</h3>
      <ul className="list-disc mb-24 ml-24">
        <li className="text-font-primary pb-12">
          Pension contributions or other savings
        </li>
        <li className="text-font-primary pb-12">Student loan repayments</li>
      </ul>
      <p className="text-font-primary">
        We use your outgoings to calculate how much you'll have left over each
        month to save and invest, so make this figure as accurate as you can.
      </p>
    </main>
  );
};

export { MonthlyOutgoingNonEssentialsExplanation };
