import { SVGProps, FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";

const ArrowHeadDownIcon: FC<SVGProps<SVGSVGElement>> = ({
  className,
  ...props
}) => {
  return (
    <svg
      aria-label={props["aria-label"] ?? "open icon"}
      width={15}
      height={10}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27 18"
      {...props}
      className={classNames(defaultClassnames, className)}
    >
      <path
        fill="#5261AC"
        d="M14.752 16.747 25.588 3.745A2.168 2.168 0 0 0 22.257.97l-9.17 11.004L3.917.97a2.168 2.168 0 0 0-3.33 2.776l10.835 13.002a2.17 2.17 0 0 0 3.33 0Z"
      />
    </svg>
  );
};

export { ArrowHeadDownIcon };
