import classNames from "classnames";
import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";

type ProductCardProps = {
  className?: string;
  to?: string;
  title: ReactNode;
  subtitle?: ReactNode;
  balance?: ReactNode;
  ["aria-label"]?: string;
};

const ProductCard: FC<ProductCardProps> = ({
  to,
  className,
  title,
  subtitle,
  balance,
  "aria-label": ariaLabel,
}) => {
  const card = (
    <div
      className={classNames(
        "bg-white inline-flex p-16 sm:p-32 hover:bg-background-hover w-full sm:w-420 min-h-80",
        className
      )}
    >
      <div className="flex flex-col ml-16 mr-40 flex-1 overflow-hidden text-t18 sm:text-t21">
        <div className="text-font-primary overflow-hidden text-ellipsis whitespace-nowrap">
          {title}
        </div>

        <p
          className={`sm:w-80 ${
            subtitle === "Add details"
              ? "text-action-error"
              : "text-font-secondary"
          } text-t12 sm:text-t16 mt-4 overflow-hidden text-ellipsis whitespace-nowrap h-18`}
        >
          {subtitle ? subtitle : "  "}
        </p>
      </div>

      {balance ? (
        <div className="text-font-primary text-t18 text-right">{balance}</div>
      ) : null}
    </div>
  );

  return to ? (
    <Link to={to} aria-label={ariaLabel}>
      {card}
    </Link>
  ) : (
    card
  );
};

export { ProductCard };
