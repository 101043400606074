import { SVGProps } from "react";

const DigitalDoctor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
    {...props}
  >
    <g clipPath="url(#a)">
      <path d="M0 18a18 18 0 1 1 36 0 18 18 0 0 1-36 0Z" fill="url(#b)" />
      <path
        d="M15.273 31.784H6.078a1.98 1.98 0 0 1-1.973-1.973V13.993a1.98 1.98 0 0 1 1.973-1.97h9.195a1.976 1.976 0 0 1 1.969 1.97V29.81a1.976 1.976 0 0 1-1.97 1.973v0Z"
        stroke="#2196F3"
        strokeWidth={1.008}
        strokeMiterlimit={50}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.715 28.044h9.914V13.899H5.715v14.145Z"
        stroke="#2196F3"
        strokeWidth={0.842}
        strokeMiterlimit={50}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.838 29.844a.853.853 0 1 1 1.703-.11.853.853 0 0 1-1.703.11Z"
        stroke="#2196F3"
        strokeWidth={0.72}
        strokeMiterlimit={50}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={3.15}
        y1={32.321}
        x2={32.249}
        y2={3.233}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#EFF4FA" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);

export { DigitalDoctor };
