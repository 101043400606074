import { Button, ContainerMaxSize, useAuth, useToaster } from "@multiply/lib";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckInCard } from "../../components";
import { InteractionFlags } from "../../globalTypes";
import { CheckInType, useUpdateCheckInMutation } from "../../graphqlTypes";
import {
  useGetCheckIns,
  useInteractionFlag,
  useProductTypesAdded,
  useVulnerableStatus,
} from "../../hooks";
import { getNextCheckInLink, hasOwnProperty } from "../../utils";

import { Loading } from "../Loading";
import { OverviewProducts } from "./OverviewProducts";
import { useAllProductsComplete } from "./useAllProductsComplete";

export function Products() {
  const { userId } = useAuth();

  const allProductsComplete = useAllProductsComplete();
  const completedAddProducts = useInteractionFlag(
    InteractionFlags.CompletedAddProducts
  );
  const completedFinancialsFactfind = useInteractionFlag(
    InteractionFlags.CompletedFinancialsFactFind
  );

  const productTypesAdded = useProductTypesAdded();

  const navigate = useNavigate();
  const location = useLocation();

  const vulnerable = useVulnerableStatus();

  const { fetching, checkInRequired, lastCheckIn } = useGetCheckIns(
    CheckInType.Products
  );

  const { addToast } = useToaster();

  const [, updateCheckIn] = useUpdateCheckInMutation();

  const confirmCheckInHandler = async () => {
    try {
      const result = await updateCheckIn({
        input: {
          userId: userId ?? "",
          checkInTypes: [CheckInType.Products],
        },
      });

      if (lastCheckIn) addToast({ message: "Plan updated", duration: 5000 });
      const nextLink = vulnerable
        ? "/check-in"
        : getNextCheckInLink(result.data?.updateCheckIn.checkIns ?? []);
      navigate(nextLink);
    } catch (error) {}
  };

  const openConfirmModal = () => {
    const nextState =
      typeof location.state === "object" &&
      hasOwnProperty(location.state ?? {}, "backgroundLocation")
        ? location.state
        : { backgroundLocation: location };

    navigate(`/cross/confirm-all-products-added`, {
      state: nextState,
    });
  };

  return (
    <ContainerMaxSize>
      <div className="h-full sm:min-h-screen w-full flex flex-col">
        <div className="flex-1">
          <h1 className="text-t21 sm:text-t27 text-font-primary pb-4">
            Products
          </h1>
          <p className="text-t16 sm:text-t21 text-font-secondary">
            What you own and what you owe
          </p>
          {fetching || completedAddProducts.loading ? (
            <div className="mt-40 flex-1 flex items-center justify-center">
              <Loading />
            </div>
          ) : (
            <div className="mt-40 flex-1 flex flex-col w-full gap-60">
              {checkInRequired && (
                <CheckInCard
                  title="Has anything below changed?"
                  subTitle="Before continuing, please update your account balances and add any other new products. This will help us create an accurate plan."
                  lastCheckIn={lastCheckIn}
                  onClick={confirmCheckInHandler}
                />
              )}

              <OverviewProducts />

              <div className="flex items-end flex-wrap gap-40">
                <p className="text-t12 sm:text-t16 text-font-secondary sm:w-2/3">
                  If you're an existing Irish Life customer you'll still need to
                  add your products here for now. In future, with your consent,
                  we may be able to automatically link your existing Irish Life
                  products to your financial plan.
                </p>
                {completedFinancialsFactfind.value &&
                  !completedAddProducts.value && (
                    <div className="w-full flex-1 flex items-end justify-center sm:justify-start">
                      <Button
                        className="whitespace-nowrap"
                        disabled={
                          completedFinancialsFactfind.loading ||
                          completedAddProducts.loading ||
                          allProductsComplete.loading ||
                          !allProductsComplete.value ||
                          productTypesAdded.loading ||
                          !productTypesAdded.anySet
                        }
                        onClick={async () => {
                          if (
                            productTypesAdded.assetsValue &&
                            productTypesAdded.debtsValue &&
                            productTypesAdded.policiesValue
                          ) {
                            if (
                              !completedAddProducts.loading &&
                              !completedAddProducts.value
                            ) {
                              await completedAddProducts.update(true);
                            }

                            navigate("/advice/plan/generate");
                          } else {
                            openConfirmModal();
                          }
                        }}
                      >
                        I've added all my products
                      </Button>
                    </div>
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
    </ContainerMaxSize>
  );
}
