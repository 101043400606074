import { Card, useAuth, ButtonLink, Spinner } from "@multiply/lib";
import { format, formatISO } from "date-fns";
import { useSearchParams } from "react-router-dom";
import {
  BookAdviserCallForm,
  BookAdviserCallFormValues,
  BookAdvisorCallFormHeader,
} from "../../components";

import { useBookAppointmentMutation } from "../../graphqlTypes";
import { ContainerSmallSize } from "@multiply/lib";
import { useGetUserJourney } from "../../hooks";
import { UserJourneyTypes } from "../../globalTypes";

const BookAdviserCall = ({ className }: { className?: string }) => {
  const { userId } = useAuth();
  const [params] = useSearchParams();
  const date = params.get("date");
  const defaultDate = date ? new Date(date) : null;

  const [bookAppointmentResult, bookAppointment] = useBookAppointmentMutation();
  const appointment = bookAppointmentResult.data?.bookAppointment.appointment;

  const userJourney = useGetUserJourney();
  const onPensionJourney =
    userJourney?.value === UserJourneyTypes.FocusedPension ? true : false;

  const onSubmit = async ({
    notes,
    reason,
    bookingDate,
  }: BookAdviserCallFormValues) => {
    // IL Tracking
    if (typeof dataLayer !== "undefined") {
      dataLayer?.push({
        event: "ButtonClick",
        il_click_url: window.location.href,
        il_click_text: "Book video call",
      });
    }

    await bookAppointment({
      input: {
        userId: userId ?? "",
        notes,
        reason: onPensionJourney ? "Pensions" : reason,
        startTime: bookingDate ? formatISO(bookingDate) : null,
      },
    });
  };

  return (
    <ContainerSmallSize className={className}>
      {bookAppointmentResult.data?.bookAppointment.success ? (
        <div className="mt-60">
          <BookAdvisorCallFormHeader
            className="hidden sm:block"
            onPensionsJourney={onPensionJourney}
          />

          <Card className="sm:mt-60 py-60 px-18 lg:px-216 text-font-primary flex flex-col items-center text-center">
            <h1 className="text-t27">Adviser call confirmed</h1>
            <h2 className="text-t27 font-bold mt-8">
              {format(
                new Date(appointment?.startTime),
                "do MMMM yyyy - hh:mmaa"
              )}
            </h2>

            <p className="mt-36 text-t16">
              Your adviser:{" "}
              <span className="text-brand-primary">
                {appointment?.advisorName}
              </span>
            </p>

            <p className="mt-24 text-t16 text-center">
              Email:{" "}
              <span className="text-brand-primary">
                {appointment?.advisorEmail}
              </span>
            </p>

            {appointment?.advisorPhone && (
              <p>
                Phone number:{" "}
                <span className="text-brand-primary">
                  {appointment?.advisorPhone}
                </span>
              </p>
            )}

            <p className="mt-24 text-t16">
              You will receive an email from your adviser with a Microsoft Teams
              link for your call in advance of your appointment. Please join the
              call from your desktop computer or laptop.
            </p>

            {userJourney.loading ? (
              <Spinner />
            ) : (
              <ButtonLink to={userJourney.defaultRoute} className="mt-24">
                Okay
              </ButtonLink>
            )}
          </Card>
        </div>
      ) : (
        <>
          <BookAdviserCallForm
            defaultValues={{
              bookingDate: defaultDate,
              reason: null,
              notes: null,
            }}
            onSubmit={onSubmit}
          />
          {bookAppointmentResult.data?.bookAppointment.error && (
            <p className="text-t16 text-action-error mt-12">
              {bookAppointmentResult.data?.bookAppointment.error.message}
            </p>
          )}
        </>
      )}
    </ContainerSmallSize>
  );
};

export { BookAdviserCall };
