import { RecommendationType } from "../graphqlTypes";

const getLinkFromRecType = (recType: RecommendationType) => {
  switch (recType) {
    case RecommendationType.Debt:
      return "/advice/plan/debt";
    case RecommendationType.EmergencyFund:
      return "/advice/plan/emergency-fund";
    case RecommendationType.Health:
      return "/advice/plan/health";
    case RecommendationType.Protection:
      return "/advice/plan/protection";
    case RecommendationType.Retirement:
      return "/advice/plan/retirement";
    case RecommendationType.SavingsInvesting:
      return "/advice/plan/savings-and-investing";
    default:
      const unreachable: never = recType;
      return unreachable;
  }
};

export { getLinkFromRecType };
