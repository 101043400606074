import { getButtonClasses } from "@multiply/lib";
import classNames from "classnames";
import { forwardRef, ReactNode, useState } from "react";

type Variant =
  | "stacked"
  | "wrapped"
  | "stacked-end"
  | "stacked-full"
  | "wrapped-end"
  | "auto"
  | "auto-end"
  | "auto-start";

const getVariantClasses = (variant: Variant) => {
  switch (variant) {
    case "stacked":
      return "flex-col";
    case "wrapped":
      return "flex-wrap";
    case "stacked-end":
      return "flex-col items-end";
    case "stacked-full":
      return "flex-col items-stretch";
    case "wrapped-end":
      return "flex-wrap justify-end";
  }
};

type InputProps = React.ComponentProps<"input">;
type FieldSetProps = React.ComponentProps<"fieldset">;

type RadioButtonProps = InputProps & {
  label: ReactNode;
};

const RadioButton = forwardRef(
  (
    { label, className, ...inputProps }: RadioButtonProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <>
        <label className={classNames("cursor-pointer", className)}>
          <input ref={ref} {...inputProps} type="radio" className="sr-only" />
          {label}
        </label>
      </>
    );
  }
);

type SharedRadioButtonGroupProps<ValueType> = {
  name?: string;
  options: Array<{
    label: React.ReactNode;
    value: ValueType;
  }>;
  error?: string;
  disabled?: InputProps["disabled"];
  variant?: Variant | "auto" | "auto-end" | "auto-start";
  className?: FieldSetProps["className"];
  label?: FieldSetProps["aria-label"];
  "labelled-by"?: FieldSetProps["aria-labelledby"];
  onChange?: (nextValue: ValueType) => void;
  onSubmit?: () => void;
  onBlur?: InputProps["onBlur"];
};

type ControlledRadioButtonGroupProps<ValueType> = {
  defaultValue?: never;
  value: ValueType;
};

type UncontrolledRadioButtonGroupProps<ValueType> = {
  value?: never;
  defaultValue: ValueType;
};

type RadioButtonGroupProps<ValueType> =
  | SharedRadioButtonGroupProps<ValueType> &
      (
        | ControlledRadioButtonGroupProps<ValueType>
        | UncontrolledRadioButtonGroupProps<ValueType>
      );

const RadioButtonGroup = forwardRef(
  <ValueType extends string | boolean | number | null>(
    {
      value: controlledValue,
      onChange,
      onSubmit,
      onBlur,
      options,
      className,
      "labelled-by": labelledBy,
      label,
      variant: defaultVariant = "auto",
      disabled,
      error,
      defaultValue,
      name,
    }: RadioButtonGroupProps<ValueType>,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const [uncontrolledValue, setUncontrolledValue] = useState(defaultValue);

    const value = controlledValue ?? uncontrolledValue;

    const handleChange = (newValue: ValueType) => () => {
      if (controlledValue === undefined) {
        setUncontrolledValue(newValue);
      }

      onChange && onChange(newValue);
    };

    const getVariant = (
      variant: Variant | "auto" | "auto-end" | "auto-start"
    ): Variant => {
      switch (variant) {
        case "auto":
        case "auto-start":
          return options.length > 4 ? "wrapped" : "stacked";
        case "auto-end":
          return options.length > 4 ? "wrapped-end" : "stacked-end";
        default:
          return variant;
      }
    };

    const variant = getVariant(defaultVariant);

    return (
      <fieldset
        role="radiogroup"
        aria-labelledby={labelledBy}
        aria-label={label}
        className={classNames(
          "flex gap-x-12 w-full",
          getVariantClasses(variant),
          className
        )}
      >
        {options.map((option, index) => {
          const checked = value === option.value;
          const lastItem = index === options.length - 1;
          const buttonClasses = getButtonClasses({
            variant: checked ? "primary" : "ghost",
          });

          return (
            <RadioButton
              ref={ref}
              key={option.value?.toString()}
              value={option.value?.toString()}
              label={option.label}
              name={name}
              onChange={handleChange(option.value)}
              onClick={() => onSubmit && onSubmit()}
              onBlur={onBlur}
              disabled={disabled}
              checked={checked}
              className={classNames(
                buttonClasses,
                `${variant.includes("full") ? "w-full" : "w-fit"}`,
                `${variant.includes("stacked") ? "flex-1" : ""}`,
                { "mb-16": !lastItem }
              )}
            />
          );
        })}
        {error && <p className="text-action-error text-t16 mt-12">{error}</p>}
      </fieldset>
    );
  }
);

export { RadioButtonGroup };
