import { RecommendationSummaryCard } from "@multiply/lib";
import classNames from "classnames";
import { RecommendationGroup } from "../../graphqlTypes";
import { getLinkFromRecType } from "../../utils";
import { RecommendationIcon } from "../RecommendationIcon";

type RecommendationGroupSummary = Pick<
  RecommendationGroup,
  "recommendationType" | "thingsToDo" | "title"
>;

type RecommendationGroupSummariesProps = {
  recommendationGroups: RecommendationGroupSummary[];
  className?: string;
};

const RecommendationGroupSummaries = ({
  recommendationGroups,
  className,
}: RecommendationGroupSummariesProps) => {
  return (
    <ul className={classNames("space-y-10 sm:space-y-20", className)}>
      {recommendationGroups.map((recommendationGroup) => {
        return (
          <li key={recommendationGroup.recommendationType}>
            <RecommendationSummaryCard
              to={getLinkFromRecType(recommendationGroup.recommendationType)}
              icon={
                <RecommendationIcon
                  type={recommendationGroup.recommendationType}
                />
              }
              title={recommendationGroup.title}
              thingsToDo={recommendationGroup.thingsToDo ?? 0}
            />
          </li>
        );
      })}
    </ul>
  );
};

export { RecommendationGroupSummaries };
