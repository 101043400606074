import classNames from "classnames";
import { FC } from "react";

type ContainerMidSizeProps = {
  className?: string;
  children?: React.ReactNode;
};

const ContainerMidSize: FC<ContainerMidSizeProps> = ({
  className,
  children,
}) => {
  return (
    <div className={classNames("px-mid-screen lg:px-mid-screen-lg", className)}>
      {children}
    </div>
  );
};

export { ContainerMidSize };
