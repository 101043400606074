import get from "lodash/get";
import set from "lodash/set";
import toPath from "lodash/toPath";
import { useEffect, useState } from "react";
import {
  FieldValues,
  UseFormGetFieldState,
  UseFormWatch,
} from "react-hook-form";

const useSaveOnChange = ({
  loading,
  getFieldState,
  onChange,
  watch,
}: {
  loading: boolean;
  getFieldState: UseFormGetFieldState<FieldValues>;
  onChange?: (values: FieldValues) => void;
  watch: UseFormWatch<FieldValues>;
}) => {
  const [syncedWithApiStatus, setSyncedWithApiStatus] = useState<
    "WAITING" | "UPDATING" | "UPDATED"
  >("UPDATED");

  /**
   * This changes the syncedWithApiStatus depending on the loading state.
   * Allows scrolling when new fields are added to the conversation.
   */
  useEffect(() => {
    if (loading && syncedWithApiStatus === "WAITING") {
      setSyncedWithApiStatus("UPDATING");
    } else if (!loading && syncedWithApiStatus === "UPDATING") {
      setSyncedWithApiStatus("UPDATED");
    }
  }, [loading, syncedWithApiStatus]);

  /**
   * handleChangeField will save the field changes for a single field
   */
  const handleChangeField = (field: string) => {
    setTimeout(() => {
      const fieldState = getFieldState(field);

      if (!fieldState.invalid || fieldState.error?.type === "server") {
        const formValues = watch();

        // if we have a field that is part of an array we need to send the whole array on each update
        // look for the first string that is a number and take the path up to that point
        const fullPath = toPath(field);
        const arrayIndex = fullPath.findIndex((path) => !isNaN(Number(path)));
        const path = fullPath.slice(
          0,
          arrayIndex === -1 ? undefined : arrayIndex
        );

        const value = get(formValues, path);

        const updateValues = set({}, path, value);
        onChange && onChange(updateValues);
        setSyncedWithApiStatus("WAITING");
      }
    });
  };

  return { syncedWithApiStatus, handleChangeField };
};

export { useSaveOnChange };
