import { Card, useAuth, CrossIcon, TickIcon } from "@multiply/lib";
import classNames from "classnames";
import React from "react";
import {
  CoverForScenario,
  PlanScope,
  ProtectionScenario,
  useGetFactFindQuery,
} from "../../graphqlTypes";

type ProtectionScenariosProps = {
  className?: string;
  scenarios: Array<ProtectionScenario | null> | null | undefined;
};

const ProtectionScenarios = ({
  scenarios,
  className,
}: ProtectionScenariosProps) => {
  const { userId } = useAuth();

  const [hasPartnerResult] = useGetFactFindQuery({
    variables: { userId: userId ?? "" },
  });

  const planScope = hasPartnerResult?.data?.factfind?.planningFor;

  const hasPartner =
    planScope &&
    [PlanScope.UserAndPartner, PlanScope.UserAndPartnerAndKids].includes(
      planScope
    );

  return (
    <>
      <h1 className="sm:text-t27 text-font-primary my-32">This means that:</h1>

      <Card
        className={classNames(
          "sm:grid grid-rows-[auto_1fr_auto] auto-cols-fr grid-flow-col p-40 pt-60 gap-x-32",
          className
        )}
      >
        {(scenarios ?? []).map((scenario, index, array) => {
          return (
            <React.Fragment key={index}>
              <h4 className="text-font-primary sm:text-t20 font-bold mb-12 sm:mb-32 mr-32">
                {scenario?.title}
              </h4>

              <div
                className={classNames(
                  "sm:border-input-border pb-16 sm:pb-36 pr-32",
                  {
                    "sm:border-r-2": index < array.length - 1,
                  }
                )}
              >
                <p className="text-font-primary text-t21">
                  {scenario?.recommended.display}
                </p>
                <p className="text-font-primary text-t10 sm:text-t12 mb-16">
                  {scenario?.subtitle}
                </p>

                <p className="flex-1 text-font-secondary text-t12 sm:text-t16">
                  {scenario?.description}
                </p>
                {hasPartner && scenario?.recommendedForPartner && (
                  <div className="pt-36">
                    <p className="flex-1 text-font-secondary text-t12 sm:text-t16">
                      {scenario?.recommendedForUser.display} to cover you
                    </p>
                    <p className="flex-1 text-font-secondary text-t12 sm:text-t16 ">
                      {scenario?.recommendedForPartner.display} to cover your
                      partner
                    </p>
                  </div>
                )}
              </div>

              <p className="text-font-primary text-t12 sm:text-t16 mb-36 sm:mb-0 pr-32">
                {scenario?.covered === CoverForScenario.Covered ? (
                  <>
                    You're covered
                    <TickIcon className="inline text-icon-display" />
                  </>
                ) : scenario?.covered === CoverForScenario.PartiallyCovered ? (
                  <>
                    You're partially covered
                    <CrossIcon className="inline text-action-error" />
                  </>
                ) : (
                  <>
                    You're not covered
                    <CrossIcon className="inline text-action-error" />
                  </>
                )}
              </p>
            </React.Fragment>
          );
        })}
      </Card>
    </>
  );
};

export { ProtectionScenarios };
