import { CURRENCY_SYMBOL } from "../../utils";
import { Wealth } from "./types";

/**
 * Gets the maximum amount in the range
 * @param range - array of wealth objects
 * @returns - maximum amount in the range
 */
const getMaximumInRange = (range: Array<Wealth | null>) => {
  if (range.length === 0) return null;

  const maxWealth = range.reduce((prev, current) => {
    if (current?.amount.float && prev?.amount.float) {
      return current.amount.float > prev.amount.float ? current : prev;
    }
    return prev;
  }, range[0]);
  return maxWealth;
};

/**
 * Gets the minimum amount in the range
 * @param range - array of wealth objects
 * @returns - minimum amount in the range
 */
const getMinimumInRange = (range: Array<Wealth | null>) => {
  if (range.length === 0) return null;

  const minWealth = range.reduce((prev, current) => {
    if (current?.amount.float && prev?.amount.float) {
      return current.amount.float < prev.amount.float ? current : prev;
    }
    return prev;
  }, range[0]);
  return minWealth;
};

/**
 * Gets the width of a number in pixels
 * @param number - number to calculate the width of
 * @returns - width of the number in pixels
 */
const getWidthOfNumberInPixels = (number: number | undefined) => {
  if (!number) return 0;
  const numberAsString = `${CURRENCY_SYMBOL}` + number.toString();
  return numberAsString.length * 8;
};

export { getMaximumInRange, getMinimumInRange, getWidthOfNumberInPixels };
