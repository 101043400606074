import { Button, ContainerMidSize, useAuth } from "@multiply/lib";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CheckboxButtonGroup,
  ConversationCardTemplate,
} from "../../components";
import { useUpdateAssetsMutation } from "../../graphqlTypes";
import { usePartnerStatus } from "../../hooks";
import {
  getAssetNameFromType,
  sortedAssetTypes,
  UserFacingAssetType,
} from "../../utils";

function AddAssets() {
  const { userId } = useAuth();

  const [assetTypes, setAssetTypes] = useState<UserFacingAssetType[]>([]);

  const [addAssetsResult, addAssets] = useUpdateAssetsMutation();
  const navigate = useNavigate();

  const handleAddAssets = async () => {
    try {
      const newAssets = assetTypes.map((assetType) => ({
        productType: assetType,
        owners: [],
      }));

      const result = await addAssets({
        input: { userId: userId ?? "", assets: newAssets },
      });

      if (result?.data?.updateAssets?.success) {
        const assets = result.data.updateAssets.assets;

        if (assets?.length === 1) {
          const assetId = assets[0]?.id;
          navigate(`/products/add/asset/${assetId}`);
        } else {
          navigate("/products");
        }
      }
    } catch (error) {}
  };

  const error =
    addAssetsResult.error ?? addAssetsResult.data?.updateAssets?.error;

  const hasPartner = usePartnerStatus();

  return (
    <ContainerMidSize>
      <ConversationCardTemplate
        title="Which assets do you have?"
        subtitle={`Select all of the ones you ${
          hasPartner ? "or your partner " : ""
        }currently have`}
      >
        <CheckboxButtonGroup
          value={assetTypes}
          onChange={setAssetTypes}
          options={sortedAssetTypes.map((assetType) => ({
            value: assetType,
            label: getAssetNameFromType(assetType),
          }))}
        />

        <Button
          onClick={handleAddAssets}
          disabled={addAssetsResult.fetching || !assetTypes.length}
        >
          Next
        </Button>

        {error && <p className="text-action-error mt-16">{error.message}</p>}
      </ConversationCardTemplate>
    </ContainerMidSize>
  );
}

export { AddAssets };
