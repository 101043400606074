import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";
import { IconProps, getDimensionsFromSize } from "./shared";

const HomeIcon: FC<IconProps> = ({ size, variant, className, ...props }) => {
  const { width, height } = getDimensionsFromSize(size);

  return (
    <svg
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="home icon"
      {...props}
    >
      <path
        d="M5.67835 12.5886H3.77104C3.24435 12.5886 2.81738 12.1617 2.81738 11.635V6.8667"
        stroke="currentColor"
        strokeWidth="1.52729"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.911011 5.91309L6.49371 1.18932C6.8493 0.888391 7.37025 0.888391 7.72583 1.18932L12.3536 5.10503"
        stroke="currentColor"
        strokeWidth="1.52729"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4478 1.14478H12.3551V3.05209"
        stroke="currentColor"
        strokeWidth="1.52729"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3777 11.7463C10.446 11.8144 10.4664 11.917 10.4295 12.0061C10.3927 12.0952 10.3057 12.1534 10.2092 12.1534C10.1128 12.1534 10.0258 12.0952 9.98895 12.0061C9.95207 11.917 9.97252 11.8144 10.0408 11.7463C10.0854 11.7015 10.146 11.6763 10.2092 11.6763C10.2725 11.6763 10.3331 11.7015 10.3777 11.7463"
        stroke="currentColor"
        strokeWidth="1.52729"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5687 10.9771L14.9128 8.63304C15.3166 8.22924 15.3166 7.57456 14.9128 7.17077C14.509 6.76697 13.8543 6.76697 13.4505 7.17077L11.1083 9.51485C9.97045 9.06925 8.67603 9.48262 8.00703 10.5052C7.33803 11.5279 7.4778 12.8795 8.34189 13.7436C9.20598 14.6076 10.5576 14.7474 11.5802 14.0784C12.6028 13.4094 13.0162 12.115 12.5706 10.9771H12.5687Z"
        stroke="currentColor"
        strokeWidth="1.52729"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { HomeIcon };
