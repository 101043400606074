import React, { useState } from "react";
import { TabTitle } from "./TabTitle";
import classNames from "classnames";

type Props = {
  tabs: Array<{
    title: string;
    tab: React.ReactElement;
  }>;
  className?: string;
};

const TabGroup: React.FC<Props> = ({ tabs, className }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const displayChildren = () => {
    return (
      <div className={classNames("flex flex-col", className)}>
        <ul className="flex gap-x-20" id="tab-group-list" role="list">
          {tabs.map((item, index) => (
            <TabTitle
              key={index}
              title={item.title}
              index={index}
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
            />
          ))}
        </ul>
        {tabs[selectedTab].tab}
      </div>
    );
  };
  return displayChildren();
};

export { TabGroup };
