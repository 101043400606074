import classNames from "classnames";
import { AssetsSection } from "./AssetsSection";
import { DebtsSection } from "./DebtsSection";
import { PoliciesSection } from "./PoliciesSection";

type OverviewProductsProps = {
  className?: string;
};

const OverviewProducts = ({ className }: OverviewProductsProps) => {
  return (
    <>
      <div
        className={classNames(
          className,
          "flex flex-col flex-wrap sm:flex-row sm:mt-32 gap-x-40 gap-y-40"
        )}
      >
        <AssetsSection />

        <DebtsSection />

        <PoliciesSection />
      </div>
    </>
  );
};

export { OverviewProducts };
