import classNames from "classnames";
import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import {
  IconProps,
  getColorFromVariant,
  getDimensionsFromSize,
} from "./shared";

const ChartCircleIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);
  return (
    <svg
      width={width}
      height={height}
      data-testid="chart circle icon"
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      viewBox="0 0 82 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="41.4706" cy="41.2089" r="34.2636" fill={fill} />
      <path
        d="M32.6635 56.9615V46.9674H38.7961V56.9615H32.6635ZM43.5571 56.961V24.5715H49.6902V56.961H43.5571ZM54.5065 56.961V34.6708H60.6391V56.961H54.5065ZM21.7141 56.961V34.6708H27.8472V56.961H21.7141Z"
        fill="#CBD1F9"
        stroke="#717FE2"
        strokeWidth="2.35"
      />
      <circle
        cx="41.2227"
        cy="40.9609"
        r="38.984"
        stroke="#E1E5FF"
        strokeWidth="2.58678"
      />
    </svg>
  );
};

export { ChartCircleIcon };
