import { ContainerMidSize, Spinner, useAuth } from "@multiply/lib";
import {
  BookAdviserCall,
  PlanStatusCard,
  RecommendationCarousel,
  YourProducts,
  YouToldUs,
} from "../../components";
import {
  AuthorisationStatus,
  RecommendationType,
  useGetPlanRecommendationsQuery,
} from "../../graphqlTypes";

const Debt = () => {
  const { userId, loggedIn } = useAuth();
  const [planResult] = useGetPlanRecommendationsQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const recommendationGroups =
    planResult?.data?.plan?.recommendationGroups ?? [];

  const debtRecommendation = recommendationGroups.find(
    (group) => group.recommendationType === RecommendationType.Debt
  );

  const notApproved =
    planResult?.data?.plan?.authorisationStatus !==
    AuthorisationStatus.AdviceApproved;

  const errorGenerated = planResult?.error;

  return (
    <div className="w-full flex justify-center">
      {planResult.fetching ? (
        <Spinner className="w-60 h-60 my-32" />
      ) : errorGenerated || notApproved ? (
        <PlanStatusCard
          planResult={planResult.data?.plan}
          error={planResult?.error?.message || null}
        />
      ) : (
        <ContainerMidSize>
          <h1 className="text-t21 sm:text-t34 text-font-primary mb-12">Debt</h1>

          <p className="text-t12 sm:text-t27 text-font-tertiary mb-56">
            Prevent debt becoming unmanageable and to help grow your savings
            faster
          </p>

          <div className="bg-input-border w-full h-2 mb-56" />

          <h1 className="sm:text-t27 text-font-primary mb-32">Overview</h1>

          <section className="sm:flex space-y-40 sm:space-y-0 items-stretch gap-x-48 mb-32">
            <YouToldUs
              title="You told us"
              className="flex-1"
              editPath="/products"
              text={debtRecommendation?.factfindSummary}
            />

            <YourProducts
              title="Debts"
              productSummary={debtRecommendation?.relatedProducts}
            />
          </section>

          <section className="mb-60 space-y-32">
            {debtRecommendation?.recommendations && (
              <RecommendationCarousel
                recommendations={debtRecommendation?.recommendations}
                title="Information"
              />
            )}
          </section>

          <section>
            <BookAdviserCall hasPlan={true} />
          </section>
        </ContainerMidSize>
      )}
    </div>
  );
};

export { Debt };
