import { useAuth, useToaster } from "@multiply/lib";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmDialogue } from "../../components";
import { UserJourneyTypes } from "../../globalTypes";
import { useDeleteAssetMutation } from "../../graphqlTypes";
import { useGetUserJourney } from "../../hooks";

const ConfirmDeleteAsset = () => {
  const { assetId } = useParams();
  const { userId } = useAuth();
  const { addToast } = useToaster();
  const navigate = useNavigate();

  const { value, error } = useGetUserJourney();
  const userJourney = value;

  const [deleteAssetResult, deleteAsset] = useDeleteAssetMutation();

  const deletionError =
    deleteAssetResult?.error ||
    deleteAssetResult?.data?.deleteAsset?.error ||
    error;

  const handleDeleteAsset = async () => {
    try {
      const result = await deleteAsset({
        input: {
          userId: userId ?? "",
          assetId,
        },
      });

      if (result.data?.deleteAsset?.success) {
        navigate(
          userJourney === UserJourneyTypes.FocusedPension
            ? "/pensions"
            : "/products"
        );
      }
    } catch (error) {}
    addToast({
      message: `${
        userJourney === UserJourneyTypes.FocusedPension ? "Pension" : "Asset"
      } deleted`,
    });
  };

  return (
    <ConfirmDialogue
      title={`Are you sure you want to delete this ${
        userJourney === UserJourneyTypes.FocusedPension ? "pension" : "asset"
      }?`}
      onClickConfirm={handleDeleteAsset}
      onClickCancel={() => navigate(-1)}
      confirmCta="Yes, delete"
      cancelCta="No, keep"
      error={deletionError?.message}
    />
  );
};

export { ConfirmDeleteAsset };
