import { FC } from "react";
import classNames from "classnames";
import { defaultClassnames } from "../defaultClassNames";
import {
  getColorFromVariant,
  getDimensionsFromSize,
  IconProps,
} from "../shared";
import { useId } from "../../../hooks";

const PinWeddingIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);
  const radialGradient = useId();
  return (
    <svg
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 39 49"
      fill="none"
      data-testid="pin wedding icon"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={fill}
        stroke={`url(#${radialGradient})`}
        strokeWidth={2.016}
        d="M37.12 19.185c0 2.419-.851 5.391-2.267 8.546-1.409 3.138-3.34 6.377-5.412 9.302-2.075 2.928-4.272 5.515-6.201 7.358-.966.923-1.844 1.64-2.59 2.118-.78.503-1.28.655-1.522.655s-.742-.152-1.523-.655c-.745-.479-1.623-1.195-2.589-2.118-1.929-1.843-4.126-4.43-6.2-7.358-2.074-2.925-4.004-6.164-5.413-9.302-1.416-3.155-2.267-6.127-2.267-8.546 0-9.944 8.056-18.005 17.992-18.005 9.936 0 17.992 8.06 17.992 18.005Z"
      />
      <path
        fill="#fff"
        d="m18.863 17.668-2.497-3.5.999-1.499h2.997l1 1.5-2.499 3.5Zm2.186-2-.75 1.063a3.685 3.685 0 0 1 2.311 3.437 3.75 3.75 0 0 1-3.747 3.749 3.745 3.745 0 0 1-3.746-3.75c0-1.562.936-2.874 2.31-3.436l-.75-1.062a4.94 4.94 0 0 0-2.81 4.499 5 5 0 0 0 4.996 4.999 4.994 4.994 0 0 0 4.996-5c0-1.999-1.124-3.686-2.81-4.498Z"
      />
      <defs>
        <radialGradient
          id={radialGradient}
          cx={0}
          cy={0}
          r={1}
          gradientTransform="matrix(0 19.013 -19 0 19.128 19.185)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.839} stopColor="#fff" />
          <stop offset={1} stopColor="#ECEFFF" />
        </radialGradient>
      </defs>
    </svg>
  );
};
export { PinWeddingIcon };
