import { Spinner, useAuth } from "@multiply/lib";
import { formatISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import {
  Relationship,
  useGetPersonQuery,
  useUpdatePersonMutation,
} from "../../graphqlTypes";
import { IntroFormValues } from "../../hooks";
import { getIntroPathFromPerson } from "../../utils";
import { IntroFactfindNameDobForm } from "./IntroFactfindNameDobForm";

export function IntroFactFindNameDob() {
  const navigate = useNavigate();
  const { userId, loggedIn } = useAuth();

  const [personResult] = useGetPersonQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const person = personResult.data?.person;
  const personId = person?.id;

  const defaultValues = {
    firstName: person?.firstName ?? null,
    lastName: person?.lastName ?? null,
    dob: person?.dateOfBirth,
    sex: person?.sex ?? null,
  };

  const [updatePersonResult, updatePerson] = useUpdatePersonMutation();

  const onSubmit = async (formValues: IntroFormValues) => {
    try {
      const result = await updatePerson({
        input: {
          userId: userId ?? "",
          personId,
          person: {
            relationship: Relationship.User,
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            sex: formValues.sex,
            dateOfBirth: formatISO(formValues.dob as unknown as Date, {
              representation: "date",
            }),
          },
        },
      });

      if (result?.data?.updatePerson?.success) {
        const nextPath = getIntroPathFromPerson(
          result.data?.updatePerson.person
        );

        if (nextPath) {
          navigate(nextPath);
        }
      }
    } catch (error) {}
  };

  const updatePersonError =
    updatePersonResult.data?.updatePerson?.error ?? updatePersonResult.error;

  return (
    <div className="flex flex-col h-full">
      <div>
        <section className="text-center">
          <h1 className="text-t21 sm:text-t27 text-font-primary pb-16">
            Let's get started
          </h1>
          <p className="text-t12 sm:text-t16 text-font-secondary pb-48 sm:pb-36">
            To create your account and provide financial advice, we need some
            basic information.
          </p>
        </section>
        {personResult.fetching ? (
          <div className="flex flex-col items-center">
            <Spinner />
          </div>
        ) : (
          <IntroFactfindNameDobForm
            error={updatePersonError?.message}
            onSubmit={onSubmit}
            defaultValues={defaultValues}
          />
        )}
      </div>
    </div>
  );
}
