import { Button, ContainerMidSize, useAuth } from "@multiply/lib";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CheckboxButtonGroup,
  ConversationCardTemplate,
} from "../../components";
import { PolicyType, useUpdatePoliciesMutation } from "../../graphqlTypes";
import { usePartnerStatus } from "../../hooks";
import { getPolicyNameFromType, sortedPolicyTypes } from "../../utils";

function AddPolicies() {
  const { userId } = useAuth();
  const [policyTypes, setPolicyTypes] = useState<PolicyType[]>([]);
  const [addPoliciesResult, addPolicies] = useUpdatePoliciesMutation();
  const navigate = useNavigate();

  const handleAddPolicies = async () => {
    try {
      const newPolicies = policyTypes.map((policyType) => ({
        productType: policyType,
      }));
      const result = await addPolicies({
        input: { userId: userId ?? "", policies: newPolicies },
      });

      if (result?.data?.updatePolicies?.success) {
        const policies = result.data.updatePolicies.policies;

        if (policies?.length === 1) {
          const policyId = policies[0]?.id;
          navigate(`/products/add/policy/${policyId}`);
        } else {
          navigate("/products");
        }
      }
    } catch (error) {}
  };

  const error =
    addPoliciesResult.error ?? addPoliciesResult.data?.updatePolicies?.error;

  const hasPartner = usePartnerStatus();

  return (
    <ContainerMidSize>
      <ConversationCardTemplate
        className="max-w-960 mx-auto"
        title="Which policies do you have?"
        subtitle={`Select all of the ones you ${
          hasPartner ? "or your partner " : ""
        }currently have`}
      >
        <CheckboxButtonGroup
          variant="wrapped"
          value={policyTypes}
          onChange={setPolicyTypes}
          options={Object.values(sortedPolicyTypes).map((policyType) => ({
            value: policyType,
            label: getPolicyNameFromType(policyType),
          }))}
        />
        <Button
          onClick={handleAddPolicies}
          disabled={addPoliciesResult.fetching || !policyTypes.length}
        >
          Next
        </Button>
        {error && <p className="text-action-error mt-16">{error.message}</p>}
      </ConversationCardTemplate>
    </ContainerMidSize>
  );
}

export { AddPolicies };
