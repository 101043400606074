import { ButtonLink, Card, ContainerMidSize, Spinner } from "@multiply/lib";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { useGetUserJourney } from "../../hooks";

const VulnerabilityReminderScheduled = () => {
  const { reminderDate } = useParams();

  const userJourney = useGetUserJourney();

  const formattedDate = reminderDate
    ? format(new Date(reminderDate), "do MMMM yyyy")
    : null;
  return (
    <ContainerMidSize>
      <div className="h-screen flex items-center justify-center">
        <Card className="pt-60 pb-48 px-32 text-center">
          <h2 className="text-t20 text-font-primary">Email reminder saved</h2>
          <h1 className="text-t27 font-bold text-font-primary mb-24">
            {formattedDate}
          </h1>
          <p className="text-t16 text-font-secondary mb-24">
            We'll reach out again in three months. You can always come back and
            get started sooner, if you feel ready.
          </p>

          {userJourney.loading ? (
            <Spinner />
          ) : (
            <ButtonLink
              to={userJourney.defaultRoute}
              className="sm:max-w-320 sm:mx-auto"
            >
              Okay
            </ButtonLink>
          )}
        </Card>
      </div>
    </ContainerMidSize>
  );
};

export { VulnerabilityReminderScheduled };
