import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Tooltip } from "@multiply/lib";
import classNames from "classnames";
import get from "lodash/get";
import set from "lodash/set";
import { useEffect, useMemo, useRef } from "react";
import { FieldError, FieldValues, useForm } from "react-hook-form";
import ReactMarkdown from "react-markdown";
import * as yup from "yup";
import { FieldInputType, QuestionGroup } from "../../graphqlTypes";
import { isRecord, transformDate } from "../../utils";
import { ConversationCardTemplate } from "../ConversationCardTemplate";
import { ModalNavLink } from "../ModalNavLink";
import { ConversationField } from "./ConversationField";
import { Link } from "react-router-dom";
import { useSaveOnChange } from "./useSaveOnChange";
import { useScrollToNextField } from "./useScrollToNextField";

const MarkdownString = ({ children }: { children: string }) => (
  <ReactMarkdown
    components={{
      a: ({ href, ...linkProps }) => {
        if (href?.includes("http" || "https")) {
          return (
            <a
              className="inline text-font-links"
              href={href}
              target="_blank"
              rel="noreferrer"
            >
              {linkProps?.children}
            </a>
          );
        } else if (href === "/tooltip") {
          return <Tooltip className="inline">{linkProps?.children}</Tooltip>;
        } else if (href?.startsWith("root")) {
          return (
            <Link
              className="text-font-links"
              to={href.replace("root", "") ?? ""}
              {...linkProps}
            />
          );
        } else {
          return (
            <ModalNavLink
              className="text-font-links"
              to={href ?? ""}
              {...linkProps}
            />
          );
        }
      },
    }}
  >
    {children}
  </ReactMarkdown>
);

const convertToFieldError = (value: unknown) => {
  let message = "";

  if (typeof value === "string") {
    message = value;
  }

  if (Array.isArray(value)) {
    message = value.join(", ");
  }

  if (!message) {
    return;
  }

  return {
    type: "server",
    message,
  };
};

/**
 * Takes a plain nested object and recursively creates a yup schema by wrapping each nested object in a yup.object or yup.array
 */
const createNestedObjectSchema = (
  value: Record<string, unknown>
): yup.AnyObjectSchema => {
  const schema: Record<string, any> = {};

  Object.entries(value).forEach(([key, value]) => {
    if (isRecord(value)) {
      schema[key] = createNestedObjectSchema(value);
    } else if (Array.isArray(value)) {
      yup.array().of(value[0]);
    } else {
      schema[key] = value;
    }
  });

  return yup.object(schema);
};

type RenderSubmit = ({
  onSubmit,
  disabled,
  ref,
}: {
  onSubmit: (e: React.BaseSyntheticEvent) => Promise<void>;
  disabled: boolean;
  ref?: React.MutableRefObject<any | null>;
}) => React.ReactNode;

const defaultRenderSubmit: RenderSubmit = ({ onSubmit, disabled, ref }) => (
  <Button ref={ref} disabled={disabled} onClick={onSubmit}>
    Next
  </Button>
);

type ConversationFormProps = {
  defaultValues: FieldValues;
  onSubmit: (
    onValidationError: (errors: any) => void
  ) => (values: FieldValues) => Promise<void>;
  onChange?: (values: FieldValues) => void;
  loading: boolean;
  questionGroups: QuestionGroup[];
  className?: string;
  renderSubmit?: RenderSubmit;
  errors: Record<string, unknown>;
};

const ConversationForm = ({
  defaultValues,
  onSubmit,
  onChange,
  loading,
  questionGroups,
  className,
  errors,
  renderSubmit = defaultRenderSubmit,
}: ConversationFormProps) => {
  const clientSideValidationSchema = useMemo(() => {
    const schema: Record<string, any> = {};

    questionGroups.forEach((questionGroup) => {
      questionGroup.questions.forEach((question) => {
        let validator;

        switch (question.input.type) {
          case FieldInputType.DateDdMmYyyy:
            validator = yup
              .date()
              .transform((value, originalValue) => transformDate(originalValue))
              .typeError('Please enter a valid date in the format "DD-MM-YYYY"')
              .nullable();
            break;

          case FieldInputType.NumberFloat:
          case FieldInputType.NumberInteger:
            validator = yup
              .number()
              .typeError("Please enter a number")
              .nullable();
            break;

          default:
            validator = yup.mixed().nullable();
            break;
        }
        set(schema, question.key, validator);
      });
    });

    const yupSchema = createNestedObjectSchema(schema);

    return yupSchema;
  }, [questionGroups]);

  const {
    control,
    watch,
    handleSubmit,
    getFieldState,
    setError,
    formState: { errors: formErrors, isSubmitting },
  } = useForm({
    shouldUnregister: true,
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(clientSideValidationSchema, {}, { rawValues: true }),
  });

  const cardRefs = useRef<Map<string, HTMLDivElement>>(new Map());
  const submitRef = useRef<HTMLElement>(null);

  /**
   *  set form errors from error prop
   *  updated in response to auto-save
   */
  useEffect(() => {
    if (errors) {
      Object.entries(errors).forEach(([key, value]) => {
        const fieldError = convertToFieldError(value);
        if (fieldError) {
          setError(key, fieldError);
        }
      });
    }
  }, [errors, setError]);

  const { syncedWithApiStatus, handleChangeField } = useSaveOnChange({
    loading,
    getFieldState,
    onChange,
    watch,
  });

  const { handleSubmitField, handleSubmitError } = useScrollToNextField({
    submitRef,
    cardRefs,
    syncedWithApiStatus,
    getFieldState,
    questionGroups,
  });

  return (
    <form
      className={classNames(
        "flex flex-col items-center gap-y-[20vh]",
        className
      )}
      onSubmit={(e) => e.preventDefault()}
    >
      {questionGroups.map((questionGroup, index) => {
        const error = get(formErrors, questionGroup.id) as FieldError;

        return (
          <ConversationCardTemplate
            ref={(element) => {
              if (element) {
                cardRefs.current.set(questionGroup.id, element);
              } else {
                cardRefs.current.delete(questionGroup.id);
              }
            }}
            key={questionGroup.id}
            preTitle={questionGroup.preTitle ?? undefined}
            title={questionGroup.title}
            helperText={questionGroup.helperText ?? undefined}
            subtitle={<MarkdownString>{questionGroup.subtitle}</MarkdownString>}
            error={error?.message}
            footer={questionGroup.footer}
          >
            {questionGroup.questions.map((question) => (
              <ConversationField
                loading={loading}
                input={question.input}
                label={
                  question.label ? (
                    <MarkdownString>{question.label}</MarkdownString>
                  ) : undefined
                }
                helperText={question.helperText ?? undefined}
                placeholder={question.placeholder ?? undefined}
                key={question.key}
                name={question.key}
                control={control}
                onSubmit={() => handleSubmitField(question.key)}
                onChange={() => handleChangeField(question.key)}
                questionNumber={index + 1}
                totalNumberOfQuestions={questionGroup.questions.length}
              />
            ))}
          </ConversationCardTemplate>
        );
      })}

      {renderSubmit({
        onSubmit: handleSubmit(onSubmit(handleSubmitError)),
        disabled: isSubmitting || !!loading,
        ref: submitRef,
      })}
    </form>
  );
};

export { ConversationForm };
