import React from "react";
import { useEffect } from "react";
import { useAuth } from "../auth";
import { AnalyticsBrowser } from "@segment/analytics-next";

const AnalyticsContext = React.createContext<AnalyticsBrowser | null>(
  undefined!
);

const AnalyticsProvider = ({
  children,
  writeKey,
}: {
  writeKey: string | undefined;
  children: React.ReactNode;
}) => {
  const analytics = React.useMemo(
    () => (writeKey ? AnalyticsBrowser.load({ writeKey }) : null),
    [writeKey]
  );
  const { userId } = useAuth();
  useEffect(() => {
    if (analytics && userId) {
      analytics.identify(userId);
    }
  }, [userId, analytics]);

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};

const useAnalytics = () => {
  const result = React.useContext(AnalyticsContext);
  if (!result) {
    throw new Error("Context used outside of its Provider!");
  }
  return result;
};

const useAnalyticsIfAvailable = () => {
  const result = React.useContext(AnalyticsContext);
  if (!result) {
    return undefined;
  }
  return result;
};

export {
  AnalyticsContext,
  AnalyticsProvider,
  useAnalytics,
  useAnalyticsIfAvailable,
};
