import { Wealth } from "./types";

const AgeCircles = ({
  x = 0,
  y = 0,
  datum,
}: {
  x?: number;
  y?: number;
  datum?: Wealth;
}) => {
  return (
    <g
      transform={`translate(${x}, ${y})`}
      className="fill-plan-graph-age-fill stroke-plan-graph-age-stroke"
    >
      <circle r={13} stroke="transparent"></circle>
      <text
        fontSize={15}
        textAnchor="middle"
        alignmentBaseline="middle"
        transform="translate(0,1)"
      >
        {datum?.age}
      </text>
    </g>
  );
};

export { AgeCircles };
