const PepExplanation = () => {
  return (
    <section className="w-full h-full">
      <h1 className="text-t21 text-font-primary font-semibold pt-36 pb-12">
        What is a Politically Exposed Person (PEP)?
      </h1>
      <p className="text-font-secondary pb-12">
        A “politically exposed person” means any individual, who currently is,
        or has at any time in the past 12 months, been entrusted with a
        prominent public function and performs one of the following roles:
      </p>
      <ul className="list-disc ml-24">
        <li className="text-font-secondary">
          A member of a parliament/ member of a legislature or equivalent
        </li>
        <li className="text-font-secondary">
          A member of a Supreme Court, Constitutional Court or any other high
          level judicial body which passes non-appealable verdicts (except in
          exceptional circumstances)
        </li>
        <li className="text-font-secondary">
          A member of a court of auditors or the board of a central bank
        </li>
        <li className="text-font-secondary">
          An ambassador, charge d’affairs or high ranking officer in the armed
          forces
        </li>
        <li className="text-font-secondary">
          A member of the administrative, management or supervisory body of a
          state owned enterprise
        </li>
      </ul>

      <h2 className="text-t21 text-font-primary font-semibold pt-24 pb-12">
        What is a Relative?
      </h2>
      <ul className="list-disc ml-24">
        <li className="text-font-secondary">
          Any spouse of the Politically Exposed Person
        </li>
        <li className="text-font-secondary">
          Any person who is considered to be a common law spouse of the
          politically exposed person under law
        </li>
        <li className="text-font-secondary">
          A child of the politically exposed person
        </li>
        <li className="text-font-secondary">
          Any spouse of the child of a politically exposed person
        </li>
        <li className="text-font-secondary">
          Any parent of the politically exposed person
        </li>
        <li className="text-font-secondary">
          Any other family member of the politically exposed person who is of a
          prescribed class set out by the Department of Finance
        </li>
      </ul>

      <h3 className="text-t21 text-font-primary font-semibold pt-24">
        What is a Close Associate?
      </h3>
      <ul className="list-disc ml-24">
        <li className="text-font-secondary">
          Any individual who has a joint beneficial ownership of a legal entity
          or legal arrangement, or any other close business relations with the
          politically exposed person
        </li>
        <li className="text-font-secondary">
          Any individual who has a sole beneficial ownership of a legal entity
          or legal arrangement set up for the actual benefit of the politically
          exposed person
        </li>
      </ul>

      <h4 className="text-t21 text-font-primary font-semibold pt-24 pb-12">
        Note
      </h4>

      <p className="text-font-secondary">
        If you or any of the Beneficiaries, Trustees, Settlors, Appointers or in
        the case of a Company Owner, Director, Beneficial Owner are PEP or RCA
        (or have been within the last 12 months), you should answer ‘Yes’.
      </p>
    </section>
  );
};

export { PepExplanation };
