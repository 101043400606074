import { useAuth } from "@multiply/lib";
import { CombinedError } from "urql";
import { UserJourneyTypes } from "../../globalTypes";
import { useGetUserQuery } from "../../graphqlTypes";

const useGetUserJourney = (): {
  value: string;
  loading: boolean;
  defaultRoute: string;
  planRoute: string;
  callRoute: string;
  error: CombinedError | undefined;
} => {
  const { userId, loggedIn } = useAuth();

  const [userResult] = useGetUserQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  let defaultRoute, planRoute, callRoute;
  switch (userResult?.data?.user?.userJourney) {
    case UserJourneyTypes.FocusedPension:
      defaultRoute = "/pensions/home";
      planRoute = "/pensions/factfind";
      callRoute = "/pensions/appointments";
      break;

    case UserJourneyTypes.Holistic:
    default:
      defaultRoute = "/advice";
      planRoute = "/advice/plan/basic-info";
      callRoute = "/appointments";
      break;
  }

  return {
    value: userResult?.data?.user?.userJourney ?? UserJourneyTypes.Holistic,
    loading: userResult.fetching,
    error: userResult.error,
    defaultRoute,
    planRoute,
    callRoute,
  };
};

export { useGetUserJourney };
