import classNames from "classnames";
import { convertNumberToEuroString } from "../../utils";

type KeyItemProps = {
  colour: string;
  text: string;
  amount: number;
};
const KeyItem = ({ colour, text, amount }: KeyItemProps) => {
  const displayAmount = convertNumberToEuroString(Math.round(Math.abs(amount)));
  return (
    <div className="flex justify-start items-center shrink-0 space-x-4 sm:space-x-12">
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        className={`mr-4 rounded ${colour} w-14 h-14 sm:w-24 sm:h-24`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0" y="0" width="14" height="14" />
      </svg>
      <div>
        <div className="uppercase text-t9 sm:text-t10 font-bold text-monochrome-900">
          {text}
        </div>
        <div
          className={classNames("text-t14 sm:text-t16 font-semibold", {
            "text-monochrome-900": amount > 0,
            "text-font-error": amount < 0,
          })}
        >
          {displayAmount}
        </div>
      </div>
    </div>
  );
};

export { KeyItem };
