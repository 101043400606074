import { Card } from "@multiply/lib";

const EmptyPensionCard = ({ buttonText }: { buttonText: string }) => {
  return (
    <Card className="flex justify-between items-center bg-white hover:bg-background-hover min-h-80 p-16 sm:p-32">
      <div className="ml-16 text-t18 sm:text-t21 text-font-primary">
        {buttonText}
      </div>
      <div className="rounded-lg bg-brand-primary text-white text-t32 sm:text-t44 flex justify-center items-center h-32 w-32 sm:h-48 sm:w-48 mr-16 sm:mr-0">
        +
      </div>
    </Card>
  );
};

export { EmptyPensionCard };
