import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";
import {
  IconProps,
  getDimensionsFromSize,
  getColorFromVariant,
} from "./shared";

const ChatIcon: FC<IconProps> = ({ size, variant, className, ...props }) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);

  return (
    <svg
      aria-label={props["aria-label"] ?? "book outline icon"}
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.95413 20.2692L6.56397 19.0654L7.19578 19.3813C8.64723 20.1053 10.2609 20.4912 11.9531 20.4912C17.6052 20.4912 22.1987 16.0412 22.1987 11.0995C22.1987 6.15769 17.6052 1.70771 11.9531 1.70771C6.301 1.70771 1.70759 6.15769 1.70759 11.0995C1.70759 12.9436 2.37355 14.8152 3.59789 16.4152L4.18701 17.1836L2.95242 20.2675L2.95413 20.2692ZM1.74686 22.472C1.59234 22.5238 1.42622 22.5302 1.26814 22.4906C1.11006 22.451 0.966612 22.367 0.854746 22.2485C0.74288 22.13 0.667268 21.9819 0.636841 21.8218C0.606414 21.6617 0.622443 21.4963 0.683036 21.345L2.24036 17.4517C0.819643 15.5904 0 13.3637 0 11.0995C0 5.4405 5.12277 0.00012207 11.9531 0.00012207C18.7835 0.00012207 23.9062 5.4405 23.9062 11.0995C23.9062 16.7584 18.7835 22.1988 11.9531 22.1988C10.0375 22.2042 8.14709 21.7621 6.43249 20.9078L1.74686 22.4703V22.472Z"
        fill={fill}
      />
      <path
        stroke={fill}
        strokeWidth={1.231}
        d="M7.68387 12.8073H16.2218C16.791 12.8073 17.0756 13.0919 17.0756 13.6611C17.0756 14.2303 16.791 14.5149 16.2218 14.5149H7.68387C7.11468 14.5149 6.83008 14.2303 6.83008 13.6611C6.83008 13.0919 7.11468 12.8073 7.68387 12.8073ZM8.53767 7.68457H15.368C15.9372 7.68457 16.2218 7.96917 16.2218 8.53836C16.2218 9.10756 15.9372 9.39216 15.368 9.39216H8.53767C7.96847 9.39216 7.68387 9.10756 7.68387 8.53836C7.68387 7.96917 7.96847 7.68457 8.53767 7.68457Z"
        fill="white"
      />
    </svg>
  );
};
export { ChatIcon };
