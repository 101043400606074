import { Button } from "@multiply/lib";
import { GetPolicyQuery } from "../../graphqlTypes";
import { usePolicyForm } from "../../hooks";
import { getPolicyFieldsFromType, getPolicyNameFromType } from "../../utils";
import { PolicyFields } from "./PolicyFields";

type PolicyFormProps = {
  policy: NonNullable<GetPolicyQuery["policy"]>;
  onSubmit: (values: any) => Promise<void>;
  defaultValues: any;
};

const PolicyForm = ({ policy, onSubmit, defaultValues }: PolicyFormProps) => {
  const fields = getPolicyFieldsFromType(policy?.productType);
  const name = getPolicyNameFromType(policy?.productType);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    watch,
  } = usePolicyForm({ fields, defaultValues });

  const throughCurrentEmployer = watch("throughCurrentEmployer");

  const filteredFields = fields.filter((field) => {
    const fieldName = typeof field === "string" ? field : field.fieldName;
    if (
      throughCurrentEmployer &&
      (fieldName === "premium" || fieldName === "paymentFrequency")
    ) {
      return false;
    }
    return true;
  });

  return (
    <form className="flex flex-col items-center w-full">
      <div className="flex items-center self-start mb-60">
        <h1 className="text-t27 text-font-primary">{name}</h1>
      </div>

      <PolicyFields fields={filteredFields} control={control} />

      <Button
        className="mt-96 w-240"
        onClick={handleSubmit(onSubmit)}
        disabled={isSubmitting || !isValid}
      >
        Save
      </Button>
    </form>
  );
};

export { PolicyForm };
