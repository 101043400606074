import {
  Button,
  Icon,
  Spinner,
  hasOwnProperty,
  useAuth,
  useToaster,
} from "@multiply/lib";
import debounce from "lodash/debounce";
import { useCallback, useMemo } from "react";
import { ConversationForm } from "../../components/ConversationForm";
import {
  Factfind,
  useGetFactFindQuery,
  useGetFieldsQuery,
  useUpdateFactFindMutation,
} from "../../graphqlTypes";
import { ConversationPageTemplate } from "../../components/ConversationPageTemplate";
import { useLocation, useNavigate } from "react-router-dom";
import { useInteractionFlag } from "../../hooks";
import { flattenObject } from "../../utils";

type FactfindPageProps = {
  context: string;
  onDone: (factfind: Factfind) => Promise<void>;
  title?: string;
  subTitle?: string;
  cta?: string;
};

const FactfindPage = ({
  context,
  onDone,
  title,
  subTitle,
  cta,
}: FactfindPageProps) => {
  const { userId, loggedIn } = useAuth();
  const { addToast } = useToaster();
  const navigate = useNavigate();
  const location = useLocation();

  const [factFindResult] = useGetFactFindQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const [fieldsResult] = useGetFieldsQuery({
    variables: { userId: userId ?? "", context },
    pause: !loggedIn,
  });

  const [updateFactfindResult, updateFactfind] = useUpdateFactFindMutation();
  const seenTriageModal = useInteractionFlag("SEEN_TRIAGE_MODAL");

  const triageIfRequired = useCallback(
    (result: Awaited<ReturnType<typeof updateFactfind>>) => {
      const triage = result.data?.updateFactfind.triage;

      if (triage?.userIsTriaged && !seenTriageModal.value) {
        seenTriageModal.update(true);

        const nextState =
          typeof location.state === "object" &&
          hasOwnProperty(location.state ?? {}, "backgroundLocation")
            ? location.state
            : { backgroundLocation: location };

        navigate(
          `/cross/employment-triage/${btoa(
            JSON.stringify({ reason: triage.reason })
          )}`,
          {
            state: nextState,
          }
        );
      } else if (!triage?.userIsTriaged && seenTriageModal.value) {
        seenTriageModal.update(false);
      }
    },
    [location, navigate, seenTriageModal]
  );

  const onChange = useMemo(
    () =>
      // Could possibly lose data if user changes more than one field within debounce time
      debounce(async (values) => {
        const result = await updateFactfind({
          input: {
            ...values,
            userId: userId ?? "",
            context,
          },
        });
        triageIfRequired(result);
      }, 300),
    [context, triageIfRequired, updateFactfind, userId]
  );

  const onSubmit =
    (onValidationError: (errors: any) => void) => async (values: any) => {
      try {
        const result = await updateFactfind({
          input: {
            ...values,
            userId: userId ?? "",
            context,
          },
        });

        if (result.data?.updateFactfind.validationErrors) {
          onValidationError({
            factfind: result.data?.updateFactfind.validationErrors,
          });
          return;
        }

        if (!result.data?.updateFactfind.success) {
          addToast({
            message:
              result.data?.updateFactfind.error?.message ??
              "Something went wrong",
            icon: Icon.Error,
          });
          return;
        }

        if (result.data?.updateFactfind.factfind) {
          await onDone(result.data?.updateFactfind.factfind);
        }

        triageIfRequired(result);
      } catch (error) {}
    };

  const factfind = factFindResult?.data?.factfind;
  const questionGroups = fieldsResult.data?.factfindFields.questionGroups;

  const error = factFindResult.error ?? fieldsResult.error;

  return (
    <ConversationPageTemplate title={title} subTitle={subTitle}>
      {error ? (
        <div className="w-full flex items-center h-[65vh]">
          <p className="text-action-error text-t12 sm:text-t18">
            {error.message}
          </p>
        </div>
      ) : factfind && questionGroups ? (
        <ConversationForm
          className="my-[15vh] w-full"
          onChange={onChange}
          onSubmit={onSubmit}
          loading={updateFactfindResult.fetching}
          errors={flattenObject({
            factfind:
              updateFactfindResult.data?.updateFactfind?.validationErrors,
          })}
          defaultValues={factFindResult.data ?? {}}
          questionGroups={questionGroups}
          renderSubmit={({ onSubmit, disabled, ref }) => (
            <Button ref={ref} disabled={disabled} onClick={onSubmit}>
              {cta ?? "Next"}
            </Button>
          )}
        />
      ) : (
        <div className="w-full flex items-center justify-center h-[65vh]">
          <Spinner />
        </div>
      )}
    </ConversationPageTemplate>
  );
};

export { FactfindPage };
