import { useNavigate } from "react-router-dom";
import { InteractionFlags } from "../../globalTypes";
import { Factfind } from "../../graphqlTypes";
import { useInteractionFlag } from "../../hooks";
import { FactfindPage } from "../FactfindPage";

const FinancialFactfind = () => {
  const navigate = useNavigate();

  const completedFinancialFactfind = useInteractionFlag(
    InteractionFlags.CompletedFinancialsFactFind
  );

  const onDone = async (factfind: Factfind) => {
    if (!completedFinancialFactfind.value) {
      await completedFinancialFactfind.update(true);
    }

    navigate("/products");
  };

  return (
    <FactfindPage
      context="factfind_part_2"
      onDone={onDone}
      title="Just a few more details"
      subTitle="You can scroll up and down to edit any of your answers"
    />
  );
};

export { FinancialFactfind };
