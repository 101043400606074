import classNames from "classnames";
import type { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import { IconProps, getDimensionsFromSize } from "./shared";

const ChatBubblesIcon: FC<IconProps> = ({ size, className, ...props }) => {
  const { width, height } = getDimensionsFromSize(size);

  return (
    <svg
      aria-label="chat icon"
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_13173_15181)">
        <path
          d="M28.8421 25.1068C30.7481 23.4081 31.8273 21.1439 31.8273 18.7942C31.8273 16.2971 30.6584 13.9618 28.5357 12.218C26.4697 10.5214 23.7352 9.58691 20.8354 9.58691C17.9356 9.58691 15.2011 10.5214 13.1354 12.2181C11.0127 13.9618 9.84375 16.2971 9.84375 18.7942C9.84375 21.2913 11.0127 23.6267 13.1354 25.3705C15.2011 27.0672 17.9356 28.0016 20.8354 28.0016C21.2218 28.0016 21.6106 27.9845 21.9961 27.9506L22.6547 28.5215C24.9237 30.4877 27.8254 31.5701 30.8278 31.5702H31.827V29.4433L31.5344 29.1507C30.3799 27.9928 29.465 26.6186 28.8421 25.1068ZM23.9636 27.0112L22.642 25.8657L22.2014 25.9197C21.7481 25.9752 21.292 26.003 20.8354 26.003C15.8767 26.003 11.8422 22.7691 11.8422 18.7942C11.8422 14.8193 15.8767 11.5854 20.8354 11.5854C25.7941 11.5854 29.8286 14.8192 29.8286 18.7942C29.8286 20.7746 28.8406 22.6234 27.0465 24.0001L26.471 24.4417L26.7366 25.1934C27.2808 26.7284 28.0807 28.1604 29.1024 29.4287C27.1996 29.1111 25.4214 28.2745 23.9636 27.0112Z"
          fill="#717fe3"
          stroke="#717fe3"
          strokeWidth="1.19909"
        />
        <path
          d="M4.61103 20.4207C5.54639 19.2367 6.2808 17.907 6.78487 16.4848L7.04842 15.7369L6.47342 15.2956C4.78171 13.9975 3.85004 12.2552 3.85004 10.3896C3.85004 6.641 7.66021 3.59125 12.3436 3.59125C15.789 3.59125 18.7616 5.24187 20.0925 7.60695C20.3396 7.59496 20.5878 7.58821 20.8371 7.58821C21.3379 7.58821 21.834 7.61251 22.3256 7.6611C21.8113 6.32867 20.9181 5.11235 19.6901 4.10374C17.719 2.48453 15.1102 1.59277 12.3436 1.59277C9.57694 1.59277 6.96817 2.48453 4.99692 4.10374C2.96859 5.76991 1.85156 8.00227 1.85156 10.3896C1.85156 12.6254 2.87279 14.7797 4.67754 16.4003C4.08857 17.8176 3.22799 19.106 2.14428 20.1929L1.85156 20.4856V22.5768H2.8508C4.69859 22.5766 6.52096 22.1463 8.17369 21.32C7.99246 20.6331 7.88628 19.9285 7.857 19.2187C6.85387 19.8067 5.75513 20.2135 4.61103 20.4207Z"
          fill="#717fe3"
          stroke="#717fe3"
          strokeWidth="1.19909"
        />
      </g>
      <defs>
        <clipPath id="clip0_13173_15181">
          <rect
            width="31.9757"
            height="31.9757"
            fill="white"
            transform="translate(0.851562 0.592773)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export { ChatBubblesIcon };
