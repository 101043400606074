import { useAuth } from "@multiply/lib";
import { useGetAssetsQuery } from "../../graphqlTypes";
import { CombinedError } from "urql";

type UseAllPensionsCompleteResults = {
  loading: boolean;
  error: CombinedError | undefined;
  value: boolean;
};
const useAllPensionsComplete = (): UseAllPensionsCompleteResults => {
  const { userId, loggedIn } = useAuth();
  const [assetsResult] = useGetAssetsQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const loading = assetsResult.fetching;
  const error = assetsResult.error;

  const pensions = (assetsResult.data?.assets ?? []).filter(
    (asset) => asset?.productType === "PENSION"
  );
  const useAllPensionsComplete = [...(pensions ?? [])].every(
    (product) => product?.isComplete
  );

  return {
    loading,
    error,
    value: useAllPensionsComplete,
  };
};

export { useAllPensionsComplete };
