import { Card, ContainerSmallSize, useWindowDimensions } from "@multiply/lib";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { IrishLifeDisclaimerText } from "../IrishLifeDisclaimerText";
import { IrishLifeLogo } from "../IrishLifeLogo";
import { IrishLifeLogoLeftCorner } from "../IrishLifeLogoLeftCorner";

type IntroFactFindLayoutProps = {
  children?: any;
};

const IntroFactFindLayout: FC<IntroFactFindLayoutProps> = ({ children }) => {
  const { isPhone } = useWindowDimensions();

  return (
    <main className="min-h-screen bg-background-primary flex flex-col justify-between">
      <section className="flex flex-col">
        {isPhone ? (
          <div className="py-24 pl-24">
            <IrishLifeLogo aria-label="irish life logo" />
          </div>
        ) : (
          <IrishLifeLogoLeftCorner
            aria-label="irish life logo"
            className="mb-8 flex place-self-start"
          />
        )}
        <ContainerSmallSize>
          <Card className="py-32 sm:py-60 px-32 sm:px-100 flex flex-col items-center min-h-fit sm:h-fit mb-36 self-center">
            <Outlet />
          </Card>
        </ContainerSmallSize>
      </section>

      <IrishLifeDisclaimerText className="p-16 sm:text-center" />
    </main>
  );
};

export { IntroFactFindLayout };
