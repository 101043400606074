import { PolicyField } from "../globalTypes";
import { PolicyType } from "../graphqlTypes";

const getPolicyFieldsFromType = (policyType: PolicyType): PolicyField[] => {
  switch (policyType) {
    case PolicyType.HealthInsurance:
      return [
        "throughCurrentEmployer",
        "provider",
        "isIrishLifePolicy",
        "owners",
        { fieldName: "renewalDate", label: "Renewal date (optional)" },
      ];

    case PolicyType.IncomeProtection:
      return [
        "throughCurrentEmployer",
        "provider",
        "isOnePlan",
        "owners",
        { fieldName: "amountOfCover", label: "Amount of cover (per annum)" },
        "yearsRemaining",
        "premium",
        "paymentFrequency",
        "name",
      ];

    case PolicyType.LifeCover:
      return [
        "provider",
        "throughCurrentEmployer",
        "coverType",
        "isOnePlan",
        "owners",
        "amountOfCover",
        "yearsRemaining",
        "premium",
        "paymentFrequency",
        "name",
      ];

    case PolicyType.SpecifiedIllnessCover:
      return [
        "throughCurrentEmployer",
        "provider",
        "isOnePlan",
        "owners",
        "amountOfCover",
        "yearsRemaining",
        "premium",
        "paymentFrequency",
        "name",
      ];

    default:
      const unreachable: never = policyType;
      return unreachable;
  }
};

export { getPolicyFieldsFromType };
