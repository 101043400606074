import { BackUp } from "./BackUp";
import { Dental } from "./Dental";
import { DigitalDoctor } from "./DigitalDoctor";
import { HealthyMinds } from "./HealthyMinds";
import { MinorInjury } from "./MinorInjury";
import { MyClinic } from "./MyClinic";

const HealthInsuranceFeatures = () => {
  return (
    <div className="grid grid-cols-3 gap-32">
      <div>
        <DigitalDoctor className="w-80 h-80 mb-32" />
        <h3 className="text-font-primary text-t20 font-bold mb-12">
          Digital Doctor
        </h3>
        <p className="text-font-secondary text-t16">
          With Digital Doctor you get unlimited consultations with a GP by phone
          or online video
        </p>
      </div>

      <div>
        <HealthyMinds className="w-80 h-80 mb-32" />
        <h3 className="text-font-primary text-t20 font-bold mb-12">
          Healthy Minds
        </h3>
        <p className="text-font-secondary text-t16">
          Get access to independent professional counselling. Because we all
          need a healthy mind as well as a healthy body.
        </p>
      </div>

      <div>
        <BackUp className="w-80 h-80 mb-32" />
        <h3 className="text-font-primary text-t20 font-bold mb-12">Back-Up</h3>
        <p className="text-font-secondary text-t16">
          Get expert advice and treatment for acute back and neck pain from a
          network of nationwide physiotherapists.
        </p>
      </div>

      <div>
        <MinorInjury className="w-80 h-80 mb-32" />
        <h3 className="text-font-primary text-t20 font-bold mb-12">
          Minor Injury Clinic
        </h3>
        <p className="text-font-secondary text-t16">
          Get rapid access to a network of walk-in minor injury clinics,
          providing medical treatment and advice 365 days a year.
        </p>
      </div>

      <div>
        <MyClinic className="w-80 h-80 mb-32" />
        <h3 className="text-font-primary text-t20 font-bold mb-12">MyClinic</h3>
        <p className="text-font-secondary text-t16">
          MyClinic is a new virtual health service that gives you fast access to
          expert advice, at a time and place that suits you.
        </p>
      </div>

      <div>
        <Dental className="w-80 h-80 mb-32" />
        <h3 className="text-font-primary text-t20 font-bold mb-12">
          Discounts + more
        </h3>
        <p className="text-font-secondary text-t16">
          Members can access orthodontic and dental discounts as well as a range
          of other discounts and benefits.
        </p>
      </div>
    </div>
  );
};

export { HealthInsuranceFeatures };
