import { useAuth, RingOutlineIcon } from "@multiply/lib";
import { useNavigate } from "react-router-dom";
import { SuggestedGoalCard } from "../../components";
import { GoalType, useUpdateGoalMutation } from "../../graphqlTypes";
import { CURRENCY_SYMBOL } from "../../utils";
import { useShowSuggestedChildEducationGoal } from "../../hooks";

const SuggestedGoalWedding = () => {
  const navigate = useNavigate();
  const [updateGoalResult, updateGoal] = useUpdateGoalMutation();
  const { userId } = useAuth();

  const showChildEducationGoal = useShowSuggestedChildEducationGoal();

  const onAdd = async () => {
    const result = await updateGoal({
      input: {
        userId: userId ?? "",
        goal: { goalType: GoalType.Wedding, name: "Wedding" },
      },
    });

    if (result?.data?.updateGoal?.success) {
      if (showChildEducationGoal) {
        navigate("/advice/plan/goals-wizard/child-education");
      } else {
        navigate("/advice/plan/goals-wizard/add-goal/true");
      }
    }
  };

  const onSkip = () => {
    if (showChildEducationGoal) {
      navigate("/advice/plan/goals-wizard/child-education");
    } else {
      navigate("/advice/plan/goals-wizard/add-goal/true");
    }
  };

  return (
    <SuggestedGoalCard
      title="Do you want to save for a wedding?"
      subtitle={`On average, couples spend around ${CURRENCY_SYMBOL}36,000* for a wedding. Save in good time to avoid stress and focus on what's important.`}
      icon={<RingOutlineIcon className="text-icon-display" size="xxl" />}
      onAdd={onAdd}
      onSkip={onSkip}
      error={
        updateGoalResult?.error?.message ||
        updateGoalResult?.data?.updateGoal?.error?.message
      }
      disclaimer="*https://onefabday.com/how-much-does-a-wedding-cost-in-ireland/"
    />
  );
};

export { SuggestedGoalWedding };
