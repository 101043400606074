import { Button, useWindowDimensions, LockIcon } from "@multiply/lib";
import { Controller } from "react-hook-form";
import { DateInput, TextInput } from "../../components";
import { IntroFormValues, useIntroForm } from "../../hooks";
import { InputGender } from "./InputGender";

const isNullOrUndefined = (value: unknown) => {
  return value === null || value === undefined;
};

type IntroFactfindNameDobFormProps = {
  defaultValues: Partial<IntroFormValues>;
  error?: string;
  onSubmit: (values: IntroFormValues) => Promise<void>;
};

const IntroFactfindNameDobForm = ({
  defaultValues,
  error,
  onSubmit,
}: IntroFactfindNameDobFormProps) => {
  const { isPhone } = useWindowDimensions();
  const { control, formState, handleSubmit } = useIntroForm({ defaultValues });
  const { isSubmitting, isValid } = formState;

  return (
    <form className="flex flex-col gap-24">
      <Controller
        control={control}
        name="firstName"
        render={({
          field: { value, onChange, onBlur },
          fieldState: { error },
        }) =>
          isNullOrUndefined(defaultValues.firstName) ? (
            <TextInput
              autoFocus
              label="First name"
              placeholder="Enter first name"
              error={error?.message}
              value={value ?? ""}
              onChange={onChange}
              onBlur={onBlur}
            />
          ) : (
            <></>
          )
        }
      />
      <Controller
        control={control}
        name="lastName"
        render={({
          field: { value, onChange, onBlur },
          fieldState: { error },
        }) =>
          isNullOrUndefined(defaultValues.lastName) ? (
            <TextInput
              label="Last name"
              placeholder="Enter last name"
              error={error?.message}
              value={value ?? ""}
              onChange={onChange}
              onBlur={onBlur}
            />
          ) : (
            <></>
          )
        }
      />
      <Controller
        control={control}
        name="dob"
        render={({
          field: { value, onChange, onBlur },
          fieldState: { error },
        }) =>
          isNullOrUndefined(defaultValues.dob) ? (
            <DateInput
              label="Date of Birth"
              value={value}
              error={error?.message}
              onBlur={onBlur}
              onChange={onChange}
            />
          ) : (
            <></>
          )
        }
      />

      <Controller
        control={control}
        name="sex"
        render={({
          field: { value, onChange, onBlur },
          fieldState: { error },
        }) =>
          isNullOrUndefined(defaultValues.sex) ? (
            <InputGender
              value={value}
              error={error?.message}
              onChange={(value) => {
                onChange(value);
                onBlur();
              }}
              onBlur={onBlur}
            />
          ) : (
            <></>
          )
        }
      />
      <section className="text-center flex flex-col items-center justify-center w-full mt-32 sm:mt-48">
        <div className="flex items-center justify-center gap-8">
          <LockIcon />
          <p className="text-t16 sm:text-t21 text-font-secondary">
            Data is encrypted and stored securely
          </p>
        </div>
        <Button
          disabled={!isValid || isSubmitting}
          onClick={handleSubmit(onSubmit)}
          aria-label="continue"
          className={`mt-32 sm:mt-48 ${isPhone ? "w-full" : "w-1/2"}`}
        >
          Continue
        </Button>
        {error ? <p className="text-action-error mt-12">{error}</p> : null}
      </section>
    </form>
  );
};

export { IntroFactfindNameDobForm };
