import { useAuth, GraduateCapOutlineIcon } from "@multiply/lib";
import { useNavigate } from "react-router-dom";
import { SuggestedGoalCard } from "../../components";
import { GoalType, useUpdateGoalMutation } from "../../graphqlTypes";
import { CURRENCY_SYMBOL } from "../../utils";

const SuggestedGoalChildEducation = () => {
  const navigate = useNavigate();
  const [updateGoalResult, updateGoal] = useUpdateGoalMutation();
  const { userId } = useAuth();

  const onAdd = async () => {
    const result = await updateGoal({
      input: {
        userId: userId ?? "",
        goal: { goalType: GoalType.ChildEducation, name: "Child's education" },
      },
    });

    if (result?.data?.updateGoal?.success) {
      navigate("/advice/plan/goals-wizard/add-goal/true");
    }
  };

  const onSkip = () => {
    navigate("/advice/plan/goals-wizard/add-goal/true");
  };

  return (
    <SuggestedGoalCard
      onAdd={onAdd}
      onSkip={onSkip}
      title="Do you want to save for your child's third-level education?"
      subtitle={`Today, the average tuition and living costs are over ${CURRENCY_SYMBOL}13,305* per year for students living away from home.`}
      icon={<GraduateCapOutlineIcon className="text-icon-display" size="xxl" />}
      error={
        updateGoalResult?.error?.message ||
        updateGoalResult?.data?.updateGoal?.error?.message
      }
      disclaimer="*https://www.tudublin.ie/for-students/student-life/cost-of-living-guide/"
    />
  );
};

export { SuggestedGoalChildEducation };
