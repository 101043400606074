import { Card, CrossIcon } from "@multiply/lib";
import classNames from "classnames";
import { FC } from "react";
import { Outlet, useNavigate } from "react-router-dom";

type ModalWithCrossLayoutProps = {
  className?: string;
  cardClassName?: string;
  maxCardWidth?: string;
  children?: React.ReactNode;
};

const ModalWithCrossLayout: FC<ModalWithCrossLayoutProps> = ({
  className,
  cardClassName,
  maxCardWidth,
}) => {
  const navigate = useNavigate();

  const getMaxWidth = (width: string) => {
    switch (width) {
      case "sm":
        return "max-w-620";
      case "md":
        return "max-w-800";
      default:
        return "max-w-960";
    }
  };

  return (
    <main
      className={classNames(
        "h-full w-full flex items-center justify-center bg-black/50 fixed top-0 p-16 lg:p-32 z-10",
        className
      )}
    >
      <Card
        className={classNames(
          `w-full ${getMaxWidth(
            maxCardWidth ?? ""
          )} h-fit max-h-[90%] overflow-scroll flex flex-col py-24 lg:py-32 px-24 lg:px-36`,
          cardClassName
        )}
      >
        <button
          className="self-end mb-20 absolute z-50"
          onClick={() => navigate(-1)}
          autoFocus
          aria-label="Exit modal"
        >
          <CrossIcon />
        </button>
        <div className="flex items-center justify-center">
          <Outlet />
        </div>
      </Card>
    </main>
  );
};

export { ModalWithCrossLayout };
