import { SVGProps, FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";

const LockIcon: FC<SVGProps<SVGSVGElement>> = ({ className, ...props }) => {
  return (
    <svg
      aria-hidden={true}
      width={24}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={classNames(defaultClassnames, className)}
    >
      <path
        stroke="#55CBB7"
        strokeWidth={1.791}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5 10.75h14.5v10.875H5zM7.719 10.75V8.031A4.531 4.531 0 0 1 12.25 3.5v0a4.531 4.531 0 0 1 4.531 4.531v2.719"
      />
    </svg>
  );
};

export { LockIcon };
