import { useNavigate } from "react-router-dom";
import { Expectation } from "./Expectation";
import { Hero } from "./Hero";
import {
  Card,
  Button,
  ChecklistIcon,
  VideoIcon,
  OpenBookIcon,
  ChatBubblesIcon,
} from "@multiply/lib";
import { ReactNode, FC } from "react";
import classNames from "classnames";

type Highlight = {
  icon: ReactNode;
  title: string;
  description: string;
};

type PlanOverviewLayoutProps = {
  bannerImg?: string;
  title: ReactNode;
  subtitle: string;
  highlights: Highlight[];
  suitableFor: string;
};

const PlanOverviewLayout: FC<PlanOverviewLayoutProps> = ({
  bannerImg,
  title,
  subtitle,
  highlights,
  suitableFor,
}) => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen relative">
      <Hero bannerImg={bannerImg} title={title} subtitle={subtitle} />
      <div className="flex flex-col justify-center items-center p-22 mb-65">
        <section className="md:w-[75%] mb-32 sm:mb-100">
          <section className="mb-28">
            <h3 className="text-font-primary font-bold text-t27 md:text-t32 pb-12">
              This plan covers
            </h3>
            <div className="flex flex-wrap gap-10 justify-between md:gap-24">
              {highlights.map((highlight, index) => {
                return (
                  <Card
                    key={index}
                    className={classNames(
                      "p-18 md:p-28 flex items-center md:max-w-560 rounded-xs",
                      { "w-full md:max-w-full": highlights.length === 1 }
                    )}
                  >
                    {highlight?.icon}
                    <div className="pl-18">
                      <p className="text-font-primary font-semibold md:text-t24">
                        {highlight?.title}
                      </p>
                      <p className="text-font-primary md:text-t18">
                        {highlight?.description}
                      </p>
                    </div>
                  </Card>
                );
              })}
            </div>
          </section>
          <section className="flex flex-col 2xl:flex-row gap-24 justify-between md:mt-40">
            <section className="mb-24 md:mb-0">
              <h4 className="text-font-primary font-bold text-t27 md:text-t32 pb-12">
                What to expect
              </h4>
              <Card className="p-18 md:p-28 flex gap-15 w-fit rounded-xs">
                <Expectation
                  icon={<ChecklistIcon size="md" />}
                  title="Advice"
                />
                <Expectation
                  icon={<OpenBookIcon size="md" />}
                  title="Lessons"
                />
                <Expectation icon={<VideoIcon size="md" />} title="Videos" />
                <Expectation
                  icon={<ChatBubblesIcon size="md" />}
                  title="Support"
                />
              </Card>
            </section>
            <section className="flex-1">
              <h5 className="text-font-primary font-bold text-t27 md:text-t32 pb-12">
                Suitable for
              </h5>
              <Card className="p-18 md:p-28 rounded-xs">
                <p className="text-font-primary">{suitableFor}</p>
              </Card>
            </section>
          </section>
        </section>
      </div>
      <div className="sticky bottom-0 bg-white flex justify-center items-center py-22">
        <Button
          size="sm"
          className="text-center text-t16 md:text-t21"
          variant="secondary"
          onClick={() => navigate("/advice/basic-info")}
        >
          Select this plan
        </Button>
      </div>
    </div>
  );
};

export { PlanOverviewLayout };
