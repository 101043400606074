import { useAuth } from "@multiply/lib";
import { useGetFactFindQuery } from "../../graphqlTypes";

const useVulnerableStatus = () => {
  const { userId, loggedIn } = useAuth();
  const [factFindResults] = useGetFactFindQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });
  return factFindResults?.data?.factfind?.vulnerable ?? false;
};
export { useVulnerableStatus };
