import { Button, Card } from "@multiply/lib";

type CheckInCardProps = {
  title: string;
  subTitle: string;
  lastCheckIn?: boolean;
  onClick: () => void;
};

const CheckInCard = ({
  title,
  subTitle,
  lastCheckIn = false,
  onClick,
}: CheckInCardProps) => {
  return (
    <Card className="px-24 sm:px-40 py-32 bg-brand-primary w-fit">
      <h1 className="text-t21 sm:text-t34 text-white font-semibold sm:mb-22">
        {lastCheckIn ? `Lastly, ${title.toLowerCase()}` : title}
      </h1>

      <p className="text-t16 sm:text-t20 text-white mt-4 mb-16 sm:mb-28 font-normal">
        {subTitle}
      </p>
      <Button
        data-testid="check-in-card-button"
        variant="ghost"
        className="text-brand-primary font-semibold hover:text-white hover:ring-2 hover:ring-white"
        onClick={onClick}
      >
        {lastCheckIn ? "Get my new plan" : "These details are correct"}
      </Button>
    </Card>
  );
};

export { CheckInCard };
