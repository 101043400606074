import { Exchange, errorExchange } from "urql";

const createDowntimeExchange = (
  onDowntime?: (isDown: boolean) => void
): Exchange =>
  errorExchange({
    onResult: (result) => {
      if (result.error?.response?.status === 503) {
        onDowntime && onDowntime(true);
      } else {
        onDowntime && onDowntime(false);
      }
    },
  });

export { createDowntimeExchange };
