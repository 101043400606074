import { format, formatISO } from "date-fns";
import classNames from "classnames";
import isEqual from "lodash/isEqual";
import { Spinner } from "../Spinner";

export type TimePickerProps = {
  className?: string;
  onChange: (value: Date) => void;
  value: Date | null;
  times: Date[];
  loading?: boolean;
  label?: string;
};

const TimePicker = ({
  times,
  className,
  onChange,
  value,
  loading,
  label,
}: TimePickerProps) => {
  return (
    <div className={classNames("text-font-primary", className)}>
      {label && (
        <h1 className="text-font-primary font-normal text-t21 sm:text-t27 mb-40">
          {label}
        </h1>
      )}
      <div className="flex flex-wrap gap-8 sm:gap-16 pb-8">
        {loading ? (
          <Spinner />
        ) : (
          times.map((time) => {
            const key = formatISO(time);
            const formattedHour = format(time, "hh:mmaa");
            const isSelected = isEqual(time, value);

            return (
              <button
                key={key}
                onClick={() => onChange(time)}
                className={classNames(
                  "rounded bg-picker text-brand-900 font-semibold  text-t16 p-16",
                  {
                    "animate-pulse": loading,
                    "text-white bg-picker-selected": isSelected,
                  }
                )}
              >
                {formattedHour}
              </button>
            );
          })
        )}
      </div>
    </div>
  );
};

export { TimePicker };
