import { Button, useWindowDimensions, LockIcon } from "@multiply/lib";
import { Controller } from "react-hook-form";
import { TextInput } from "../../components";
import {
  IntroAddressFormValues,
  useIntroAddressForm,
} from "./useIntroAddressForm";

type IntroFactfindAddressFormProps = {
  defaultValues: IntroAddressFormValues;
  error?: string;
  onSubmit: (values: IntroAddressFormValues) => Promise<void>;
};

const IntroFactfindAddressForm = ({
  error,
  onSubmit,
  defaultValues,
}: IntroFactfindAddressFormProps) => {
  const { formState, handleSubmit, control } = useIntroAddressForm({
    defaultValues,
  });
  const { isSubmitting, isValid } = formState;
  const { isPhone } = useWindowDimensions();

  return (
    <form className="flex flex-col">
      <fieldset className="flex flex-col gap-24">
        <legend aria-label="Address form"></legend>
        <Controller
          control={control}
          name="line1"
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error },
          }) => (
            <TextInput
              autoFocus
              label="Address 1"
              placeholder="Enter the first line of your address"
              error={error?.message}
              value={value ?? ""}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
        <Controller
          control={control}
          name="line2"
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error },
          }) => (
            <TextInput
              label="Address 2"
              placeholder="Enter the second line of your address"
              error={error?.message}
              value={value ?? ""}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
        <Controller
          control={control}
          name="line3"
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error },
          }) => (
            <TextInput
              label="Address 3"
              placeholder="Enter the third line of your address"
              error={error?.message}
              value={value ?? ""}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
        <Controller
          control={control}
          name="county"
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error },
          }) => (
            <TextInput
              label="County"
              placeholder="Enter a county"
              error={error?.message}
              value={value ?? ""}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
        <Controller
          control={control}
          name="country"
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error },
          }) => (
            <TextInput
              label="Country"
              placeholder="Enter a country"
              error={error?.message}
              value={value ?? ""}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
      </fieldset>

      <section className="text-center flex flex-col items-center justify-center w-full mt-32 sm:mt-48">
        <div className="flex items-center justify-center gap-8">
          <LockIcon aria-label="lock icon" />
          <p className="text-t16 sm:text-t21 text-font-secondary">
            Data is encrypted and stored securely
          </p>
        </div>
        <Button
          disabled={!isValid || isSubmitting}
          onClick={handleSubmit(onSubmit)}
          aria-label="continue"
          className={`mt-32 sm:mt-48 ${isPhone ? "w-full" : "w-1/2"}`}
        >
          Continue
        </Button>
        {error ? <p className="text-action-error mt-12">{error}</p> : null}
      </section>
    </form>
  );
};

export { IntroFactfindAddressForm };
