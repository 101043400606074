import { gql } from "urql";

const FieldContextFragment: ReturnType<typeof gql> = gql`
  fragment FieldContextFragment on FactfindContextFields {
    id
    questionGroups {
      id
      title
      subtitle
      preTitle
      helperText
      questions {
        key
        placeholder
        label
        helperText
        input {
          type
          options {
            label
            value
          }
          min
          max
          disabled
        }
      }
      contexts
    }
  }
`;

export { FieldContextFragment };
