import { Combobox, Spinner } from "@multiply/lib";
import { useGetNationalitiesQuery } from "../../graphqlTypes";

type InputNationalityProps = {
  value: string | null;
  onChange: (value: string) => void;
  onBlur: () => void;
  onSubmit?: () => void;
  error?: string;
  label?: React.ReactNode;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
};

const InputNationality = ({
  onChange,
  label,
  placeholder,
  value,
  error,
  onSubmit,
  className,
  disabled,
}: InputNationalityProps) => {
  const [nationalitiesResult] = useGetNationalitiesQuery();

  const nationalities = nationalitiesResult?.data?.nationalities;
  const items = (nationalities ?? []).map((nationality) => ({
    label: nationality ?? "",
    value: nationality ?? "",
  }));

  const selectedItem = items?.find((item) => item.value === value);

  return nationalitiesResult.fetching ? (
    <Spinner />
  ) : (
    <Combobox
      className={className}
      value={selectedItem}
      onChange={(selectedItem) => {
        onChange(selectedItem?.value ?? "");
      }}
      onSubmit={onSubmit}
      label={label}
      placeholder={placeholder}
      error={error}
      items={items}
      disabled={disabled}
    />
  );
};

export { InputNationality };
