import { useAuth } from "@multiply/lib";
import { Link } from "react-router-dom";
import { ProductCard } from "../../components";
import { useGetAssetsQuery } from "../../graphqlTypes";
import { convertNumberToEuroString, getAssetNameFromType } from "../../utils";
import { EmptyProductCard } from "./EmptyProductCard";
import { ProductSection } from "./ProductSection";

const AssetsSection = () => {
  const { userId } = useAuth();

  const [assetsResult] = useGetAssetsQuery({
    variables: { userId: userId ?? "" },
  });

  const assets = assetsResult?.data?.assets ?? [];
  const balance = assets.reduce(
    (total, asset) => total + (asset?.balance?.float ?? 0),
    0
  );

  return (
    <div className="flex flex-col">
      <ProductSection
        loading={assetsResult.fetching}
        title="Assets"
        subtitle="Savings, Investments, Pensions etc.."
        balance={convertNumberToEuroString(balance)}
      >
        {assets.length === 0 ? (
          <EmptyProductCard to="add/assets" aria-label="add assets" />
        ) : (
          assets.map((asset) => (
            <ProductCard
              to={`add/asset/${asset?.id}`}
              key={asset?.id}
              title={getAssetNameFromType(asset?.productType)}
              subtitle={
                asset?.isComplete
                  ? asset?.name ?? asset?.provider?.name ?? ""
                  : "Add details"
              }
              balance={asset?.balance?.display ?? null}
            />
          ))
        )}
      </ProductSection>
      {assets?.length > 0 && (
        <Link to="add/asset" className="text-t16 text-font-links mt-24">
          Add another asset
        </Link>
      )}
    </div>
  );
};

export { AssetsSection };
