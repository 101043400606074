import { hasOwnProperty } from "./hasOwnProperty";

function itemDisabled(item: unknown) {
  if (
    typeof item === "object" &&
    hasOwnProperty(item, "disabled") &&
    typeof item.disabled === "boolean"
  ) {
    return item.disabled;
  }

  return false;
}

export { itemDisabled };
