import { useWindowDimensions } from "@multiply/lib";
import { PrsaFund } from "../../graphqlTypes";
import { getPensionPortfolios } from "../../utils";
import { Counter } from "./Counter";
import { FactsheetLink } from "./FactsheetLink";
import { ResponsiblePension } from "./ResponsiblePension";

export type EditablePortfolioCardType = {
  recommendedFund: PrsaFund;
  leftToAllocate: number;
  value: number;
  onChange: (percentage: number) => void
};

const EditablePortfolioCard = ({
  recommendedFund,
  leftToAllocate,
  value,
  onChange
}: EditablePortfolioCardType) => {
  const { isPhone } = useWindowDimensions();
  const { name, investorType, isResponsible, detailsLink } =
    getPensionPortfolios(recommendedFund);

  return (
    <div className="mt-20 bg-background-primary p-16 px-24 rounded-xsm sm:flex justify-between">
      <div>
        <div className="flex gap-12 sm:gap-32">
          <p className="text-font-primary text-t18 font-semibold">{name}</p>
          {isResponsible && (
            <ResponsiblePension
              text={isPhone ? "RESPONSIBLE" : "RESPONSIBLE PENSION"}
            />
          )}
        </div>
        <p className="text-brand-secondary">{investorType}</p>
        <FactsheetLink
          detailsLink={detailsLink}
          className="max-sm:hidden" 
        />
      </div>
      <Counter
        className="my-14 h-48"
        disabled={leftToAllocate === 0}
        value={value}
        onChange={onChange}
        max={100}
        increment={5}
      />
      <FactsheetLink detailsLink={detailsLink} className="hidden max-sm:block"/>
    </div>
  );
};

export { EditablePortfolioCard };
