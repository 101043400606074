import { SVGProps } from "react";

const MyClinic = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
    {...props}
  >
    <path
      d="M18 36c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18Z"
      fill="url(#a)"
    />
    <path
      d="m26.21 9.528-5.679 6.609M20.219 10.84v5.57h5.265M26.828 26.788l-6.246-6.008M25.538 20.507h-5.214v5.517M10.363 9.528l5.679 6.609M16.3 10.84v5.57h-5.214M9.738 26.788l6.247-6.008M10.98 20.507h5.266v5.517"
      stroke="#5C61AC"
      strokeWidth={0.823}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="a"
        x1={18}
        y1={29.211}
        x2={18}
        y2={-1.601}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#EFF4FA" />
      </linearGradient>
    </defs>
  </svg>
);

export { MyClinic };
