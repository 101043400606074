import {
  Card,
  Spinner,
  useAuth,
  useToaster,
  ContainerMidSize,
} from "@multiply/lib";
import pick from "lodash/pick";
import { useNavigate, useParams } from "react-router-dom";
import { ModalNavLink } from "../../components";
import { AssetFieldName } from "../../globalTypes";
import {
  AssetInput,
  AssetType,
  useGetAssetQuery,
  useUpdateAssetMutation,
} from "../../graphqlTypes";
import { AssetForm } from "./AssetForm";
import { getAssetFieldsFromType } from "./getAssetFieldsFromType";
import { PensionForm } from "./PensionForm";
import { PropertyForm } from "./PropertyForm";
import { SavingsDepositForm } from "./SavingsDepositForm";
import { CurrentAccountForm } from "./CurrentAccountForm";
import { parseProductDatesToISO } from "../../utils";

const UpdateAsset = () => {
  const { assetId } = useParams();
  const { userId } = useAuth();
  const { addToast } = useToaster();
  const navigate = useNavigate();

  const [getAssetResult] = useGetAssetQuery({
    variables: {
      userId: userId ?? "",
      assetId,
    },
  });

  const asset = getAssetResult?.data?.asset;

  const [updateAssetResult, updateAsset] = useUpdateAssetMutation();

  const handleUpdateAsset = async (values: any) => {
    if (asset) {
      const productType = values.productType ?? asset.productType;

      const fieldNames = getAssetFieldsFromType(productType)
        .map((field) => (typeof field === "string" ? field : field.fieldName))
        .filter((field) => field !== "isOverdrawn");

      const { provider, ...filteredValues } = pick(values, fieldNames);

      const newAsset: AssetInput = {
        productType,
        ...filteredValues,
        ...parseProductDatesToISO(filteredValues),
      };

      if (typeof provider === "string") {
        newAsset.providerName = provider;
      }

      try {
        const result = await updateAsset({
          input: {
            userId: userId ?? "",
            assetId,
            asset: newAsset,
          },
        });

        if (result.data?.updateAsset?.success) {
          navigate("/products");
        }
      } catch (error) {}

      addToast({ message: "Success" });
    }
  };

  const updateMutationError =
    updateAssetResult?.error || updateAssetResult?.data?.updateAsset?.error;

  const defaultValues: { [fieldName in AssetFieldName]: any } = {
    owners: (asset?.owners ?? []).map((person) => person?.id ?? ""),
    isOverdrawn: (asset?.overdrawnBy?.float ?? 0) > 0,
    balance: asset?.balance?.float,
    provider: asset?.provider?.name ?? null,
    name: asset?.name ?? null,
    accessible: asset?.accessible ?? null,
    overdrawnBy: asset?.overdrawnBy?.float ?? null,
    maturityDate: asset?.maturityDate,
    startDate: asset?.startDate ?? null,
    increasing: asset?.increasing ?? null,
    regularPaymentAmount: asset?.regularPaymentAmount?.float ?? null,
    valuationMethod: asset?.valuationMethod ?? null,
    whereFrom: asset?.whereFrom ?? null,
    propertyType: asset?.propertyType ?? null,
    pensionType: asset?.pensionType ?? null,
    frequency: asset?.frequency ?? null,
    active: asset?.active ?? null,
    throughCurrentEmployer: asset?.throughCurrentEmployer ?? null,
    isIrishLifePension: asset?.isIrishLifePension ?? null,
  };

  return (
    <ContainerMidSize>
      <div className="flex-1 flex flex-col items-center justify-center">
        <Card className="w-full p-32 pb-18 flex flex-col items-center">
          {getAssetResult.fetching ? (
            <Spinner />
          ) : !asset ? (
            <p className="text-t21">Asset not found</p>
          ) : (
            <>
              {asset.productType === AssetType.Property ? (
                <PropertyForm
                  onSubmit={handleUpdateAsset}
                  defaultValues={defaultValues}
                />
              ) : asset.productType === AssetType.Pension ? (
                <PensionForm
                  onSubmit={handleUpdateAsset}
                  defaultValues={defaultValues}
                />
              ) : asset.productType === AssetType.SavingsDepositAccount ||
                asset.productType === AssetType.RegularSavingsDepositAccount ||
                asset.productType === AssetType.DepositTracker ? (
                <SavingsDepositForm
                  onSubmit={handleUpdateAsset}
                  defaultValues={{
                    productType: asset?.productType,
                    ...defaultValues,
                  }}
                />
              ) : asset.productType === AssetType.CurrentAccount ? (
                <CurrentAccountForm
                  onSubmit={handleUpdateAsset}
                  defaultValues={defaultValues}
                />
              ) : (
                <AssetForm
                  asset={asset}
                  onSubmit={handleUpdateAsset}
                  defaultValues={defaultValues}
                />
              )}

              {assetId && (
                <ModalNavLink
                  to={`/cross/delete-asset/${assetId}`}
                  className="w-240 text-action-error text-center py-18 mt-8"
                >
                  Delete
                </ModalNavLink>
              )}
            </>
          )}
          {updateMutationError && (
            <p className="mt-12 text-action-error text-t16">
              {updateMutationError.message}
            </p>
          )}
        </Card>
      </div>
    </ContainerMidSize>
  );
};

export { UpdateAsset };
