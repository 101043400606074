import { Button, ButtonLink } from "@multiply/lib";
import { useNavigate, useParams } from "react-router-dom";
import { useGetUserJourney } from "../../hooks";

const EmploymentStatusTriage = () => {
  const navigate = useNavigate();
  const { statusString } = useParams();
  const userJourney = useGetUserJourney();

  if (!statusString) {
    return null;
  }

  const { reason } = JSON.parse(atob(statusString)) as {
    reason: string;
  };

  const handleSkip = () => {
    navigate(-1);
  };

  return (
    <div className="w-full h-full flex flex-col items-center">
      <h1 className="text-t21 lg:text-t27 text-font-primary font-bold pb-16">
        Book a call with one of our advisers
      </h1>
      <h2 className="lg:text-t21 text-font-primary pb-24">
        Due to your circumstances, we recommend you continue by talking with one
        of our advisers.
      </h2>
      <p className="lg:text-t21 text-font-primary pb-24">This is because:</p>
      <ul className="list-disc mb-24 ml-24">
        <li className="lg:text-t21 text-font-primary pb-48">{reason}</li>
      </ul>
      <div className="flex flex-col items-center gap-24">
        {!userJourney.loading ? (
          <ButtonLink to={userJourney.callRoute} className="text-t21 w-216">
            Book a call
          </ButtonLink>
        ) : null}
        <Button onClick={handleSkip} variant="ghost" className="w-216">
          Skip
        </Button>
      </div>
    </div>
  );
};

export { EmploymentStatusTriage };
