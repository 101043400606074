import { AnalyticsBrowser } from "@segment/analytics-next";
import { Exchange } from "urql";
import { pipe, tap } from "wonka";

export const createAnalyticsExchange: (
  analytics: AnalyticsBrowser
) => Exchange =
  (analytics) =>
  ({ forward }) => {
    return (operations$) => {
      const tappedOperations$ = pipe(
        operations$,
        tap((operation) => {
          if (operation.kind === "mutation") {
            const queryDefinitions = operation?.query?.definitions?.filter(
              (def) => def.kind === "OperationDefinition"
            );
            for (const definition of queryDefinitions) {
              const mutationName = (definition as any)?.name?.value;
              if (mutationName) {
                analytics.track(`Mutation ${mutationName}`);
              }
            }
          }
        })
      );

      const operationResult$ = forward(tappedOperations$);

      return operationResult$;
    };
  };
