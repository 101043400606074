import { ContainerMaxSize } from "@multiply/lib";
import { CURRENCY_SYMBOL } from "../../utils";

const EggGraphDetails = () => {
  return (
    <ContainerMaxSize>
      <div className="h-full w-full">
        <h1 className="text-font-primary text-t21 pb-24">
          This projection aims to predict your pension by retirement.
        </h1>

        <p className="text-font-primary pb-32">
          It takes into account your income, outgoings, assets and debts, and
          your retirement goals. We then apply the advice being given and
          project the pension values you see.
        </p>

        <h2 className="text-font-primary font-bold pb-24">
          Naturally, no-one knows the future, so we have to apply a number of
          assumptions:
        </h2>

        <p className="text-font-primary">Average earnings increases = 1.5%</p>
        <p className="text-font-primary pb-24">
          Personal spending increases = 1%
        </p>

        <p className="text-font-primary">
          Total pension contribution = Existing Contributions plus any
          recommended increases.
        </p>
        <p className="text-font-primary">
          Pension contribution escalation = 1.5% to the chosen retirement date
          then all contributions assumed to cease.
        </p>
        <p className="text-font-primary">Irish State Pension age = 66</p>
        <p className="text-font-primary">
          Current Irish State Pension amount = {CURRENCY_SYMBOL}13,171.60 per
          annum
        </p>
        <p className="text-font-primary">State Pension escalation = 1.0%</p>
        <p className="text-font-primary pb-24">
          Defined Benefit Pension Income escalation = 1.0%
        </p>

        <p className="text-font-primary">Property Value escalation = 4.5%</p>
        <p className="text-font-primary pb-24">
          Rental Property Income escalation = 1.0%
        </p>

        <p className="text-font-primary">
          Current Account rate of return = 0.0%
        </p>
        <p className="text-font-primary">Cash Savings rate of return = 0.5%</p>
        <p className="text-font-primary">
          Pension / Investment rates of return = 4.5%
        </p>
        <p className="text-font-primary pb-24">
          Any other assets rates of return = 0.0%
        </p>
        <p className="text-font-primary pb-40">
          Assumed fund risk volatility = IL3
        </p>
      </div>
    </ContainerMaxSize>
  );
};

export { EggGraphDetails };
