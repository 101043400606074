import { Button, Spinner } from "@multiply/lib";
import { useNavigate } from "react-router-dom";
import { PensionsPageTemplate } from "../PensionsPageTemplate";
import {
  PensionsReviewCard,
  PensionsReviewCardProps,
} from "../PensionsReviewCard";
import { PensionsStepper } from "../PensionsStepper";

type PensionsReviewProps = {
  title: string | JSX.Element;
  subtitle: string;
  loading: boolean;
  reviewData: PensionsReviewCardProps[];
  onDonePath: string;
  isPrsaFactfind?: boolean;
  backButton?: boolean;
};

const PensionsReview = ({
  title,
  subtitle,
  loading,
  reviewData,
  onDonePath,
  isPrsaFactfind = false,
  backButton
}: PensionsReviewProps) => {
  const navigate = useNavigate();

  return (
    <PensionsPageTemplate backButton={backButton} className="sm:pt-38 pb-24">
      <PensionsStepper currentStep={4} className="mb-24 sm:mb-80"/>
      <h1 className=" text-t21 sm:text-t32 text-font-primary mt-48 font-semibold px-24 text-center">
        {title}
      </h1>
      {!loading ? (
        <>
          <section className="px-56 pt-48">
            <h2 className="text-font-primary font-semibold pb-18">
              {subtitle}
            </h2>
            <div className="flex flex-wrap gap-14">
                {reviewData.map((field, index) => (
                  <PensionsReviewCard
                    key={index}
                    label={field.label}
                    fieldName={field.fieldName}
                    value={
                      typeof field.value === "object"
                        ? field?.value?.float
                        : field.value
                    }
                    isPrsaFactfind={isPrsaFactfind}
                  />
                ))}
            </div>
          </section>
          <Button
            size="sm"
            className="mt-24 sm:mt-53"
            onClick={() => navigate(onDonePath)}
          >
            These details are correct
          </Button>
        </>
      ) : (
        <div className="mt-[10%]">
          <Spinner />
        </div>
      )}
    </PensionsPageTemplate>
  );
};

export { PensionsReview };
