import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export type LoginFormValues = {
  email: string;
  password: string;
};

export const defaultLoginValidationSchema: yup.SchemaOf<LoginFormValues> = yup
  .object()
  .shape({
    email: yup
      .string()
      .required("Please provide an email")
      .email("Please enter a valid email")
      .nullable(),
    password: yup.string().required("Please provide a password").nullable(),
  })
  .defined();

type UseLoginFormArgs = {
  defaultValues?: Partial<LoginFormValues>;
  validationSchema?: yup.SchemaOf<LoginFormValues>;
};

const useLoginForm = (args?: UseLoginFormArgs) => {
  const defaultValues = args?.defaultValues;
  const validationSchema =
    args?.validationSchema ?? defaultLoginValidationSchema;

  const form = useForm<LoginFormValues>({
    defaultValues: {
      email: defaultValues?.email ?? "",
      password: defaultValues?.password ?? "",
    },
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  return form;
};

export { useLoginForm };
