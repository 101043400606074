import classNames from "classnames";
import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import {
  getColorFromVariant,
  getDimensionsFromSize,
  IconProps,
} from "./shared";

const NestCircleIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 59 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      {...props}
    >
      <circle cx={29.682} cy={29.406} r={24.672} fill={fill} />
      <g clipPath="url(#a)">
        <path
          d="M36.518 18.023c3.43 1.256 4.488 7.118 3.14 11.874H18.687c-.784-4.56.52-9.654 3.738-10.717 2.013-.665 4.362.383 6.347 2.273 2.276-2.659 5.303-4.325 7.746-3.43Z"
          fill="#5463D6"
          fillOpacity={0.2}
        />
        <path
          d="M25.46 29.897h7.645m-4.333-8.444c2.276-2.659 5.303-4.325 7.746-3.43 3.43 1.256 4.488 7.118 3.14 11.874h-6.553m-4.333-8.444c-1.985-1.89-4.334-2.938-6.347-2.273-3.219 1.063-4.522 6.156-3.738 10.717h14.418m-4.333-8.444c1.666 1.586 3.076 3.764 3.82 6.019.28.854.448 1.664.513 2.425"
          stroke="#5463D6"
          strokeWidth={1.863}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41.7 29.869c0 5.904-5.62 10.69-12.554 10.69s-12.554-4.786-12.554-10.69H41.7Z"
          fill="#5463D6"
          fillOpacity={0.2}
          stroke="#5463D6"
          strokeWidth={1.863}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.729 33.108h4.922M32.717 34.148h7.556M28.622 36.88h-4.034"
          stroke="#5463D6"
          strokeWidth={1.746}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <circle
        cx={29.503}
        cy={29.238}
        r={28.071}
        stroke="#BEC7FF"
        strokeWidth={1.863}
      />
      <defs>
        <clipPath id="a">
          <path
            fill="#fff"
            transform="translate(15.748 16.093)"
            d="M0 0h26.974v26.974H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export { NestCircleIcon };
