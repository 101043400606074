import { ContainerMaxSize, SectionHeading, useAuth } from "@multiply/lib";
import { isFuture, isPast } from "date-fns";
import {
  CompletedAdviserCallCard,
  GoalsOverview,
  LifePlanGraphCard,
  TopPriorities,
  BookAdviserCall,
  ModalNavLink,
  IrishLifeDisclaimerText,
} from "../../components";
import {
  useGetPlanAdviceInfoQuery,
  useGetScheduledAppointmentsQuery,
} from "../../graphqlTypes";

const Plan = () => {
  const { userId, loggedIn } = useAuth();
  const [planResult] = useGetPlanAdviceInfoQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const recommendationGroups =
    planResult?.data?.plan?.recommendationGroups ?? [];

  const [scheduledAppointmentsResult] = useGetScheduledAppointmentsQuery({
    variables: { userId: userId ?? "" },
  });

  const appointments =
    scheduledAppointmentsResult.data?.scheduledAppointments ?? [];

  const futureAppointments = appointments.filter((appointment) =>
    isFuture(new Date(appointment.startTime))
  );

  const pastAppointments = appointments.filter((appointment) =>
    isPast(new Date(appointment.startTime))
  );

  return (
    <ContainerMaxSize>
      <SectionHeading
        title="Your financial plan"
        subtitle={`Top ${
          recommendationGroups.length < 3 ? recommendationGroups.length : "3"
        } priorities`}
      />

      <section className="grid grid-cols-1 lg:grid-cols-3 lg:gap-x-24">
        {recommendationGroups.length > 0 ? (
          <TopPriorities
            recommendationGroups={recommendationGroups}
            className="w-full col-span-2 lg:col-span-2 col-start-1 mb-36 md:mb-44 lg:mb-60"
          />
        ) : null}

        <GoalsOverview className="lg:sticky mb-36 lg:top-24 col-span-1 lg:col-start-3" />

        <div className="w-full lg:col-span-2 col-start-1">
          <SectionHeading
            className="col-span-2"
            title="Your projected wealth"
            subtitle="This projection estimates the future wealth of someone in your position
        who follows our advice principles."
          />

          <LifePlanGraphCard className="w-full" />

          <p className="text-font-secondary text-t12 md:text-t13 mt-12 mb-36 md:mb-44 lg:mb-60">
            These figures are based on today's money and assumes your
            circumstances remain similar.{" "}
            <ModalNavLink
              to="/life-plan-graph"
              className="text-font-links whitespace-nowrap"
              aria-haspopup="dialog"
            >
              Learn more about our assumptions behind your figures.
            </ModalNavLink>
          </p>
        </div>

        {(appointments.length === 0 || futureAppointments.length !== 0) && (
          <div className="w-full lg:col-span-2 col-start-1">
            <SectionHeading
              title="Your personal adviser"
              subtitle={
                pastAppointments.length !== 0
                  ? "Book a free call, at a time that suits"
                  : ""
              }
            />
            <BookAdviserCall hasPlan={true} className="w-full" />
          </div>
        )}

        {futureAppointments.length === 0 && pastAppointments.length !== 0 && (
          <div className="w-full lg:col-span-2">
            <SectionHeading title="Your personal adviser" />

            <CompletedAdviserCallCard className="w-full" />
          </div>
        )}
      </section>

      <IrishLifeDisclaimerText className="text-t14 mx-12 text-font-secondary pt-40 lg:pt-80" />
    </ContainerMaxSize>
  );
};

export { Plan };
