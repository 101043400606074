import { useId } from "@multiply/lib";
import classNames from "classnames";
import { format } from "date-fns";
import { useMemo } from "react";
import useDimensions from "react-cool-dimensions";
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import { convertNumberToEuroString } from "../../utils";
import { VictoryTheme } from "../../VictoryTheme";
import { AgeCircles } from "./AgeCircles";
import GraphTooltip from "./GraphTooltip";
import {
  getMaximumInRange,
  getMinimumInRange,
  getWidthOfNumberInPixels,
} from "./graphUtils";
import { IconBubbles } from "./IconBubbles";
import type { Wealth } from "./types";

const displayDateLabels = (index: number, wealth: Wealth | null) => {
  if (index === 0) {
    return "Now";
  }

  if (!wealth) {
    return "";
  }

  if (wealth?.goals?.find((goal) => goal?.id === "RETIREMENT")) {
    return "";
  }

  const currentDate = new Date(wealth?.date);

  const isYear = currentDate.getMonth() === 11;
  if (isYear) {
    return format(currentDate, "yyyy");
  } else {
    return format(currentDate, "MMM");
  }
};

type PlanGraphProps = {
  range: Array<Wealth | null>;
  className?: string;
};

const PlanGraph = ({ range, className }: PlanGraphProps) => {
  const goals = useMemo(() => {
    return range.filter((item) => (item?.goals?.length ?? 0) > 0);
  }, [range]);

  const retirementGoals = useMemo(
    () =>
      goals.filter((item) =>
        item?.goals?.find((goal) => goal?.id === "RETIREMENT")
      ) as Wealth[],
    [goals]
  );

  const gradientId = useId();

  const { observe, width, height } = useDimensions();

  const max = getMaximumInRange(range);
  const min = getMinimumInRange(range);
  const domainMin = min ? min?.amount.float - 10000 : 0;
  const domainMax = max ? max?.amount.float + 10000 : 0;

  // set left padding based on max and min values
  const leftPadding =
    getWidthOfNumberInPixels(max?.amount.float) >
    getWidthOfNumberInPixels(min?.amount.float)
      ? getWidthOfNumberInPixels(max?.amount.float)
      : getWidthOfNumberInPixels(min?.amount.float);

  const negativeWealthValues = range.some(
    (item) => item && item?.amount.float < 0
  );

  return (
    <div
      ref={observe}
      className={classNames(
        "flex-1 w-full h-full min-w-300 min-h-400 ",
        className
      )}
    >
      <svg className="h-0">
        <defs>
          <linearGradient id={gradientId} x1="0" x2="0" y1="1" y2="0">
            <stop offset="-20%" stop-color="#CBCDFF" stop-opacity="0" />
            <stop offset="100%" stop-color="#E9EAFF" stop-opacity="1" />
          </linearGradient>
        </defs>
      </svg>

      {range.length > 0 && (
        <VictoryChart
          width={width}
          height={height}
          containerComponent={
            <VictoryVoronoiContainer className="fill-plan-graph-bubble-fill stroke-plan-graph-bubble-stroke" />
          }
          padding={{
            top: 90,
            right: 34,
            bottom: 50,
            left: leftPadding,
          }}
          theme={VictoryTheme}
        >
          {/* X axis */}
          <VictoryAxis
            fixLabelOverlap
            style={{
              axis: {
                stroke: "none",
              },
              tickLabels: { fontSize: 11 },
            }}
            offsetY={50}
            tickFormat={(_, index: number) =>
              displayDateLabels(index, range[index])
            }
          />

          {/* Y axis */}
          <VictoryAxis
            crossAxis={false}
            dependentAxis
            domain={[domainMin, domainMax]}
            style={{
              axis: { stroke: "transparent" },
              tickLabels: { fontSize: 11 },
            }}
            tickFormat={(tick) => convertNumberToEuroString(tick)}
          />

          {/* Zero line for when negative values exist */}
          {negativeWealthValues && (
            <VictoryLine
              data={[
                { x: 0, y: 0 },
                { x: 100, y: 0 },
              ]}
              style={{
                data: {
                  stroke: "#5463D6",
                  strokeDasharray: "4",
                  strokeWidth: 0.5,
                },
              }}
            />
          )}

          {/* Data line with shaded area */}

          <VictoryArea
            standalone={true}
            interpolation="linear"
            data={range}
            x="date"
            y="amount.float"
            style={{
              data: {
                fill: `url(#${gradientId})`,
                strokeWidth: 2,
              },
              labels: {
                fontSize: 12,
                fontWeight: 500,
                textAnchor: "left",
              },
            }}
            labels={() => ""}
            labelComponent={
              <VictoryTooltip
                constrainToVisibleArea
                pointerOrientation="bottom"
                flyoutComponent={<GraphTooltip />}
              />
            }
          />

          {/* Points on line */}
          <VictoryScatter
            data={goals}
            x="date"
            y="amount.float"
            size={({ active }) => (active ? 4 : 3)}
            style={{
              data: {
                fill: ({ active }) => (active ? "#55cbb7" : "white"),
                strokeWidth: 3,
              },
            }}
          />

          {/* Retirement age circles */}
          <VictoryScatter
            data={retirementGoals}
            x="date"
            y={() => ""}
            dataComponent={<AgeCircles />}
          />

          {/* Bubbles */}
          <VictoryScatter
            animate
            data={goals}
            x="date"
            y="amount.float"
            dataComponent={<IconBubbles />}
          />
        </VictoryChart>
      )}
    </div>
  );
};

export { PlanGraph };
