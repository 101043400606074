import { Spinner, useAuth, Slider } from "@multiply/lib";
import { useState } from "react";
import { useGetRiskProfilesQuery, useGetRiskQuery } from "../../graphqlTypes";

type ProfileOptions = {
  __typename: "RiskProfile";
  id: string;
  name: string;
  description: string;
};

type RiskSliderProps = {
  defaultValue: string;
  riskProfileOptions: Array<ProfileOptions>;
};

const RiskSlider = ({ defaultValue, riskProfileOptions }: RiskSliderProps) => {
  const [activeIndex, setActiveIndex] = useState(
    riskProfileOptions.findIndex((option) => option.id === defaultValue) ?? 0
  );

  const selectedProfile = riskProfileOptions[activeIndex];

  const min = 0;
  const max = riskProfileOptions.length - 1;

  return (
    <>
      <h1 className="text-font-primary text-t21 sm:text-t27 mb-12">
        {selectedProfile?.name}
      </h1>

      <p className="text-font-secondary text-t12 sm:text-t16 mb-36">
        {selectedProfile?.description}
      </p>

      <Slider
        className="mb-32"
        min={min}
        max={max}
        value={activeIndex}
        onChange={(value) => setActiveIndex(Number(value))}
      />

      <div className="flex justify-between text-font-secondary text-t12 sm:text-t16">
        <span>Low risk</span>
        <span>High risk</span>
      </div>
    </>
  );
};

const RiskProfileOptions = () => {
  const { userId } = useAuth();
  const [riskResult] = useGetRiskQuery({ variables: { userId: userId ?? "" } });
  const [riskProfilesResult] = useGetRiskProfilesQuery({
    variables: { userId: userId ?? "" },
  });
  const calculatedProfile = riskResult.data?.risk.calculatedProfile;
  const riskProfileOptions = riskProfilesResult.data?.riskProfiles ?? [];

  if (riskResult.fetching || riskProfilesResult.fetching) return <Spinner />;

  return (
    <div>
      <p className="text-font-primary text-t16 sm:text-t21 mb-48 mr-24">
        Based on your answers we've assessed your risk profile as:{" "}
        <b>{calculatedProfile?.name}</b>
      </p>

      {calculatedProfile && (
        <RiskSlider
          defaultValue={calculatedProfile?.id}
          riskProfileOptions={riskProfileOptions as ProfileOptions[]}
        />
      )}

      <p className="text-font-secondary text-t12 sm:text-t16 mb-12 mt-48">
        If you believe you should have a different profile than the one you were
        assessed as, we recommend speaking to one of our advisers. You can book
        a call on your plan page.
      </p>
    </div>
  );
};

export { RiskProfileOptions };
