import classNames from "classnames";
import { Recommendation } from "../../globalTypes";
import {
  Card,
  BackArrowCircleIcon,
  HeartHealthCircleIcon,
  NestCircleIcon,
  SirenInCircleIcon,
  SproutCircleIcon,
  UmbrellaCircleIcon,
} from "@multiply/lib";
import { SingleRecommendation } from "./SingleRecommendation";

type RecommendationsSummaryCardProps = {
  className?: string;
  protectionRecommendation?: Recommendation;
  retirementRecommendation?: Recommendation;
  savingsInvestingRecommendation?: Recommendation;
  debtRecommendation?: Recommendation;
  healthRecommendation?: Recommendation;
  emergencyFundRecommendation?: Recommendation;
  hasPlan?: boolean;
};

const RecommendationsSummaryCard = ({
  className,
  protectionRecommendation,
  retirementRecommendation,
  savingsInvestingRecommendation,
  debtRecommendation,
  healthRecommendation,
  hasPlan = false,
  emergencyFundRecommendation,
}: RecommendationsSummaryCardProps) => {
  const protectionCount = protectionRecommendation?.thingsToDo ?? 0;
  const retirementCount = retirementRecommendation?.thingsToDo ?? 0;
  const savingsInvestingCount = savingsInvestingRecommendation?.thingsToDo ?? 0;
  const healthCount = healthRecommendation?.thingsToDo ?? 0;
  const debtCount = debtRecommendation?.thingsToDo ?? 0;
  const emergencyFundCount = emergencyFundRecommendation?.thingsToDo ?? 0;

  return (
    <Card
      className={classNames(
        "w-full p-24 sm:p-60 sm:flex sm:flex-wrap space-y-16 sm:space-y-0 sm:gap-60",
        {
          "cursor-not-allowed": !hasPlan,
        },
        className
      )}
    >
      <SingleRecommendation
        icon={<UmbrellaCircleIcon variant="icon" size="xl" />}
        title="Protection"
        actions={protectionCount}
      />

      <SingleRecommendation
        icon={<BackArrowCircleIcon variant="icon" size="xl" />}
        title="Debt"
        actions={debtCount}
      />

      <SingleRecommendation
        icon={<SirenInCircleIcon variant="icon" size="xl" />}
        title="Emergency fund"
        actions={emergencyFundCount}
      />

      <SingleRecommendation
        icon={<NestCircleIcon variant="icon" size="xl" />}
        title="Retirement"
        actions={retirementCount}
      />

      <SingleRecommendation
        icon={<SproutCircleIcon variant="icon" size="xl" />}
        title="Savings & Investing"
        actions={savingsInvestingCount}
      />

      <SingleRecommendation
        icon={<HeartHealthCircleIcon variant="icon" size="xl" />}
        title="Health"
        actions={healthCount}
      />
    </Card>
  );
};

export { RecommendationsSummaryCard };
