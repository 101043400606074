import { GoalType } from "../graphqlTypes";
import { CURRENCY_SYMBOL } from "./getCurrencySymbol";

const getGoalTooltipLabelFromType = (goalType: GoalType) => {
  switch (goalType) {
    case GoalType.BuyHome:
      return (
        <>
          <div>
            The average first time buyer needs around {CURRENCY_SYMBOL}52,500
            for a deposit, legal fees, and other moving costs. However, costs
            vary greatly depending on location and features. You can always edit
            your answers later.
          </div>
          <br />
          <div className="text-xs break-words">
            Source:
            <br />
            https://bpfi.ie/publications/bpfi-housing-market-monitor-q3-2021/
          </div>
        </>
      );
    case GoalType.Wedding:
      return (
        <>
          <div>
            On average, couples in Ireland spend around {CURRENCY_SYMBOL}36,000
            on a wedding. Remember you can always edit your answers later.
          </div>
          <br />
          <div className="text-xs break-words">
            Source:
            <br />
            https://onefabday.com/how-much-does-a-wedding-cost-in-ireland/
          </div>
        </>
      );
    default:
      return "";
  }
};

export { getGoalTooltipLabelFromType };
