import React, {
  useState,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { AuthService, AuthState } from "./types";

type AuthContextValue = {
  loginWithPopup: () => Promise<void>;

  loginWithRedirect: () => Promise<void>;

  changePasswordWithRedirect: () => Promise<void>;

  handleRedirectResult: () => Promise<AuthState | null>;

  signUpWithEmailAndPassword: ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => Promise<void>;

  loginWithEmailAndPassword: ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => Promise<void>;

  restoreSession: () => Promise<boolean>;

  logOut: (redirectUri?: string) => Promise<void>;
  loggedIn: boolean;
  userId: string | null;
  token: string | null;
  username: string | null;
  getToken: () => string | null;
  refreshToken: () => Promise<void>;
};

const AuthContext = createContext<AuthContextValue | undefined>(undefined);

type AuthProviderProps = {
  children: React.ReactNode;
  authService: AuthService;
};

const AuthContextProvider = ({ children, authService }: AuthProviderProps) => {
  const [userId, setUserId] = useState<string | null>(
    authService.initialAuthState?.userId ?? null
  );

  const [token, setToken] = useState<string | null>(
    authService.initialAuthState?.token ?? null
  );

  const [username, setUsername] = useState<string | null>(
    authService.initialAuthState?.username ?? null
  );

  // token is duplicated as a ref for access outside of the react render loop, e.g. apollo client
  const tokenRef = useRef<string | null>(null);
  const loggedIn = !!token;

  const signUpWithEmailAndPassword = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      if (authService.signUpWithEmailAndPassword) {
        await authService.signUpWithEmailAndPassword({
          email,
          password,
        });
      } else {
        throw new Error(
          "signUpWithEmailAndPassword not available for current auth provider"
        );
      }
    },
    [authService]
  );

  const loginWithEmailAndPassword = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      if (authService.loginWithEmailAndPassword) {
        await authService.loginWithEmailAndPassword({
          email,
          password,
        });
      } else {
        throw new Error(
          "loginWithEmailAndPassword not available for current auth provider"
        );
      }
    },
    [authService]
  );

  const loginWithPopup = useCallback(async () => {
    if (authService.loginWithPopup) {
      await authService.loginWithPopup();
    } else {
      throw new Error("loginWithPopup not available for current auth provider");
    }
  }, [authService]);

  const loginWithRedirect = useCallback(async () => {
    if (authService.loginWithRedirect) {
      await authService.loginWithRedirect();
    } else {
      throw new Error(
        "loginWithRedirect not available for current auth provider"
      );
    }
  }, [authService]);

  const changePasswordWithRedirect = useCallback(async () => {
    if (authService.changePasswordWithRedirect) {
      await authService.changePasswordWithRedirect();
    } else {
      throw new Error(
        "changePasswordWithRedirect not available for current auth provider"
      );
    }
  }, [authService]);

  const handleRedirectResult = useCallback(async () => {
    if (authService.handleRedirectResult) {
      const result = await authService.handleRedirectResult();
      return result;
    } else {
      throw new Error(
        "handleRedirectResult not available for current auth provider"
      );
    }
  }, [authService]);

  const logOut = useCallback(
    async (redirectUri?: string) => {
      await authService.logOut(redirectUri);
    },
    [authService]
  );

  const restoreSession = useCallback(async () => {
    try {
      const result = await authService.restoreSession();
      const sessionRestored = !!result?.userId;
      return sessionRestored;
    } catch (error) {
      return false;
    }
  }, [authService]);

  const getToken = useCallback(() => {
    return tokenRef.current;
  }, []);

  const refreshToken = useCallback(() => {
    return authService.refreshJWT();
  }, [authService]);

  useEffect(() => {
    const unsubscribe = authService.onAuthStateChange(
      ({ userId, token, username }) => {
        setUserId(userId);
        setUsername(username);
        tokenRef.current = token;
        setToken(token);
      }
    );

    return unsubscribe;
  });

  const value = useMemo(
    () => ({
      loginWithPopup,
      loginWithRedirect,
      changePasswordWithRedirect,
      handleRedirectResult,
      signUpWithEmailAndPassword,
      loginWithEmailAndPassword,
      logOut,
      restoreSession,
      loggedIn,
      userId,
      token,
      username,
      getToken,
      refreshToken,
    }),
    [
      loginWithPopup,
      loginWithRedirect,
      changePasswordWithRedirect,
      handleRedirectResult,
      signUpWithEmailAndPassword,
      loginWithEmailAndPassword,
      logOut,
      restoreSession,
      loggedIn,
      userId,
      token,
      username,
      getToken,
      refreshToken,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthContextProvider };
