import { Dropdown, Input, NumberInput } from "@multiply/lib";
import { Control, Controller } from "react-hook-form";
import { FieldInput, FieldInputType } from "../../graphqlTypes";
import { CurrencyInput } from "../CurrencyInput";
import { DateInput } from "../DateInput";
import { InputOccupation } from "../InputOccupation";
import { SliderInput } from "../SliderInput";
import { RadioButtonGroup } from "../RadioButtonGroup";
import { InputCountry } from "../InputCountry";
import { InputNationality } from "../InputNationality";
import { Checkbox } from "@multiply/lib";
import { RadioInputGroup } from "@multiply/lib";

type ConversationFieldProps = {
  name: string;
  label?: React.ReactNode;
  placeholder?: string;
  helperText?: React.ReactNode;
  control: Control;
  input: FieldInput;
  onSubmit: () => void;
  onChange: () => void;
  loading?: boolean;
  questionNumber: number;
  totalNumberOfQuestions: number;
};

const ConversationField = ({
  name,
  control,
  label,
  placeholder,
  helperText,
  input,
  onSubmit,
  onChange: fieldHasChanged,
  loading,
  questionNumber,
  totalNumberOfQuestions,
}: ConversationFieldProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange: formOnChange, onBlur, value },
        fieldState: { error, isTouched },
        formState: { isSubmitted, isSubmitting },
      }) => {
        const errorMessage =
          (isTouched || isSubmitted) && !loading ? error?.message : undefined;

        const onChange = (value: any) => {
          formOnChange(value);
          fieldHasChanged();
        };

        switch (input.type) {
          case FieldInputType.Currency:
            return (
              <CurrencyInput
                name={name}
                label={label}
                placeholder={placeholder}
                value={value}
                helperText={helperText}
                onChange={onChange}
                onBlur={onBlur}
                onSubmit={onSubmit}
                error={errorMessage}
                disabled={input.disabled ?? isSubmitting}
              />
            );
          case FieldInputType.DateDdMmYyyy:
            return (
              <DateInput
                name={name}
                label={label}
                placeholder={placeholder}
                helperText={helperText}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onSubmit={onSubmit}
                error={errorMessage}
                disabled={input.disabled ?? isSubmitting}
              />
            );
          case FieldInputType.Occupation:
            return (
              <InputOccupation
                label={label}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onSubmit={onSubmit}
                error={errorMessage}
                disabled={input.disabled ?? isSubmitting}
              />
            );
          case FieldInputType.RadioButton:
            return (
              <RadioButtonGroup
                label={typeof label === "string" ? label : undefined}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onSubmit={onSubmit}
                error={errorMessage}
                disabled={input.disabled ?? isSubmitting}
                options={input.options ?? []}
                variant="stacked"
              />
            );
          case FieldInputType.Select:
            return (
              <Dropdown
                label={label}
                placeholder={placeholder}
                value={input.options?.find((option) => option.value === value)}
                onChange={(option) => {
                  onChange(option?.value);
                }}
                onSubmit={onSubmit}
                onBlur={onBlur}
                error={errorMessage}
                disabled={input.disabled ?? isSubmitting}
                items={input.options ?? []}
              />
            );
          case FieldInputType.Slider:
            return (
              <SliderInput
                min={input.min ?? 0}
                max={input.max ?? 0}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onSubmit={onSubmit}
                error={errorMessage}
                disabled={input.disabled ?? isSubmitting}
                confirmButton={
                  questionNumber === totalNumberOfQuestions ? false : true
                }
              />
            );
          case FieldInputType.Text:
            return (
              <Input
                name={name}
                label={label}
                value={value}
                placeholder={placeholder}
                maxLength={input.max ?? undefined}
                helperText={helperText}
                onChange={onChange}
                onBlur={onBlur}
                onSubmit={onSubmit}
                error={errorMessage}
                disabled={input.disabled ?? isSubmitting}
              />
            );
          case FieldInputType.NumberInteger:
            return (
              <NumberInput
                name={name}
                label={label}
                value={value}
                onChange={onChange}
                helperText={helperText}
                max={input.max ?? undefined}
                min={input.min ?? undefined}
                onBlur={onBlur}
                onSubmit={onSubmit}
                error={errorMessage}
                disabled={input.disabled ?? isSubmitting}
                decimals={false}
              />
            );
          case FieldInputType.NumberFloat:
            return (
              <NumberInput
                name={name}
                label={label}
                value={value}
                helperText={helperText}
                onChange={onChange}
                onBlur={onBlur}
                onSubmit={onSubmit}
                error={errorMessage}
                disabled={input.disabled ?? isSubmitting}
                decimals={true}
              />
            );

          case FieldInputType.Country:
            return (
              <InputCountry
                label={label}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onSubmit={onSubmit}
                error={errorMessage}
                disabled={input.disabled ?? isSubmitting}
              />
            );
          case FieldInputType.Nationality:
            return (
              <InputNationality
                label={label}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onSubmit={onSubmit}
                error={errorMessage}
                disabled={input.disabled ?? isSubmitting}
              />
            );
          case FieldInputType.Checkbox:
            return (
              <Checkbox
                label={label ?? undefined}
                onChange={onChange}
                onBlur={onBlur}
                variant="square"
                className="pt-20"
                checked={value}
                onSubmit={onSubmit}
                error={errorMessage}
                disabled={input.disabled ?? isSubmitting}
              />
            );
          case FieldInputType.Radio:
            return (
              <RadioInputGroup
                label={label ?? ""}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onSubmit={onSubmit}
                error={errorMessage}
                disabled={input.disabled ?? isSubmitting}
                options={input.options ?? []}
                variant="stacked"
              />
            );
          case FieldInputType.DisplayOnly:
            return (
              <div>
                <div className="text-input-label font-semibold">{label}</div>

                <div className="text-font-primary font-bold">€{value}</div>

                {helperText && (
                  <p className="text-font-secondary">{helperText}</p>
                )}
              </div>
            );
          // Todo: Handle new field types
          case FieldInputType.CheckboxButton:
          case FieldInputType.DateMmYyyy:
          case FieldInputType.Provider:
          case FieldInputType.YesNo:
          case FieldInputType.Address:
          case FieldInputType.Duration:
            return <></>;
          default:
            const unreachable: never = input.type;
            return unreachable;
        }
      }}
    />
  );
};

export { ConversationField };
