import { InteractionFlags } from "../../globalTypes";
import { useInteractionFlag } from "../../hooks";
import { BookAdviserCall } from "../../pages";
import { Sidebar } from "../Sidebar";

const PensionsAppointmentWrapper = () => {
  const completedAcceptPRSAInvestmentFund = useInteractionFlag(
    InteractionFlags.CompletedPensionsOnboarding
  );

  if (completedAcceptPRSAInvestmentFund.value) {
    return (
      <div className="flex flex-col lg:flex-row">
        <Sidebar className="lg:sticky lg:top-0 shrink-0" />
        <main className="min-h-screen flex-1 min-w-0">
          <BookAdviserCall className="mt-40 sm:my-60" />
        </main>
      </div>
    );
  } else {
    return <BookAdviserCall className="mt-40 sm:my-60" />;
  }
};

export { PensionsAppointmentWrapper };
