import classNames from "classnames";
import { useSelect } from "downshift";
import {
  itemDisabled as defaultItemDisabled,
  itemToKey as defaultItemToKey,
  itemToString as defaultItemToString,
} from "../../utils";
import { Card, DropdownCaret, Tooltip } from "../index";

type DropDownProps<Item> = {
  // ideally this would be an id for better stability, but downshift doesn't support it yet
  // https://github.com/downshift-js/downshift/issues/1495
  value: Item | null;
  onChange: (value: Item | null) => void;
  onOpen?: () => void;
  onClose?: () => void;
  onBlur?: () => void;
  onSubmit?: () => void;
  itemToString?: (item: Item | null) => string;
  itemToKey?: (item: Item | null) => string;
  itemDisabled?: (item: Item | null) => boolean;
  items: Item[];
  label?: React.ReactNode;
  placeholder?: string;
  error?: string;
  className?: string;
  variant?: "default" | "small";
  tooltip?: React.ReactNode;
  leftIcon?: React.ReactNode;
  icon?: React.ReactNode;
  disabled?: boolean;
  "aria-label"?: React.ComponentProps<"button">["aria-label"];
};

function Dropdown<Item>({
  value,
  onChange,
  onOpen,
  onClose,
  onSubmit,
  items,
  label,
  error,
  placeholder,
  tooltip,
  itemToString = defaultItemToString,
  itemToKey = defaultItemToKey,
  itemDisabled = defaultItemDisabled,
  onBlur,
  className,
  variant = "default",
  icon,
  leftIcon,
  disabled,
  "aria-label": buttonAriaLabel,
}: DropDownProps<Item>) {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    selectedItem: value,
    items,
    onIsOpenChange: ({ isOpen }) => {
      isOpen && onOpen && onOpen();
      !isOpen && onClose && onClose();
    },
    onSelectedItemChange: ({ selectedItem }) => {
      onChange && onChange(selectedItem ?? null);
      onSubmit && onSubmit();
      onBlur && onBlur();
    },
  });

  return (
    <div role="group" className={classNames("relative", className)}>
      {label && (
        <div className="text-t16 pb-8">
          <div
            className={classNames(
              "text-input-label flex items-start font-semibold",
              { "justify-between w-full": tooltip }
            )}
            {...getLabelProps()}
          >
            {label}
            {tooltip && <Tooltip>{tooltip}</Tooltip>}
          </div>
        </div>
      )}

      <button
        aria-label={buttonAriaLabel}
        disabled={disabled}
        type="button"
        className={classNames(
          "w-full flex items-center justify-between border-1 border-solid border-transparent rounded text-font-primary",
          {
            "bg-input-background outline outline-1 outline-input-border outline-offset-[-1px] min-h-48 py-12 px-18":
              variant === "default",
            "bg-input-dropdown-background outline-none font-semibold px-16 py-8":
              variant === "small",
            "rounded-b-none": isOpen,
          }
        )}
        {...getToggleButtonProps()}
      >
        {leftIcon && <div className="flex mr-12">{leftIcon}</div>}

        <div
          className={classNames(
            "text-ellipsis whitespace-nowrap overflow-hidden",
            {
              "text-t16 mr-16": variant === "default",
              "text-t11 mr-8": variant === "small",
            }
          )}
        >
          {selectedItem ? itemToString(selectedItem) : placeholder ?? ""}
        </div>

        {icon ? (
          icon
        ) : (
          <DropdownCaret
            className={classNames("shrink-0", {
              "w-8": variant === "small",
              "w-16": variant === "default",
            })}
          />
        )}
      </button>
      <Card
        className={classNames(
          "absolute z-30 min-w-full overflow-hidden rounded rounded-t-none",
          {
            "bg-white": variant === "default",
            "bg-input-dropdown-background text-font-primary font-semibold text-t11 mt-8":
              variant === "small",
            hidden: !isOpen || disabled,
          }
        )}
      >
        <ul
          {...getMenuProps()}
          className="max-h-320 overflow-y-auto focus-visible:outline-none"
        >
          {isOpen &&
            !disabled &&
            items.map((item, index) => (
              <li
                key={itemToKey(item)}
                className={classNames("cursor-pointer text-font-primary", {
                  "bg-menu-active/50":
                    highlightedIndex === index && variant === "default",
                  "bg-input-dropdown-background/50":
                    highlightedIndex === index && variant === "small",
                  "p-16": variant === "default",
                  "p-8": variant === "small",
                })}
                {...getItemProps({ item, index, disabled: itemDisabled(item) })}
              >
                {itemToString(item)}
              </li>
            ))}
        </ul>
      </Card>
      {error && <span className="text-action-error text-t16">{error}</span>}
    </div>
  );
}

export { Dropdown };
