import { useNavigate } from "react-router-dom";
import { FactfindPage } from "../FactfindPage";
import { useInteractionFlag } from "../../hooks";
import { InteractionFlags } from "../../globalTypes";
import { Factfind } from "../../graphqlTypes";

const PensionFactFind = () => {
  const navigate = useNavigate();

  const completedPensionsFactfind = useInteractionFlag(
    InteractionFlags.CompletedPensionsFactFind
  );

  const onDone = async (factfind: Factfind) => {
    if (!completedPensionsFactfind.value) {
      await completedPensionsFactfind.update(true);
    }

    if (factfind.userSpecificFactfind?.hasPension === true) {
      navigate("/pensions/add/pensions");
    } else {
      navigate("/pensions/risk/assess");
    }
  };

  return (
    <div className="mt-120">
      <FactfindPage
        context="pensions_factfind"
        onDone={onDone}
        title="Tell us a bit about yourself..."
        subTitle="You can scroll up and down to edit any of your answers"
      />
    </div>
  );
};

export { PensionFactFind };
