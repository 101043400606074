import { useIsEmployed } from "../../hooks";
import { Stepper } from "./Stepper";

type PensionsStepperProps = {
  currentStep: number;
  className?: string;
};

const PensionsStepper = ({ currentStep, className }: PensionsStepperProps) => {
  const { value, loading } = useIsEmployed();

  const yourDetails = {
    title: "YOUR DETAILS",
    link: "/pensions/add-basic-details",
  };
  const employerDetails = {
    title: "EMPLOYER DETAILS",
    link: "/pensions/add-employment-details",
  };
  const contributionDetails = {
    title: "CONTRIBUTION DETAILS",
    link: "/pensions/add-basic-details",
  };
  const review = { title: "REVIEW", link: "" };

  const steps = value
    ? [yourDetails, employerDetails, contributionDetails, review]
    : [yourDetails, contributionDetails, review];

  return !loading ? (
    <Stepper className={className} currentStep={currentStep} steps={steps} />
  ) : null;
};
export { PensionsStepper };
