import { CURRENCY_SYMBOL } from "../../utils";

const LifePlanGraphDetails = () => {
  return (
    <div className="h-full w-full">
      <h1 className="text-font-primary text-t21 pb-24">
        This projection estimates the future wealth of someone in your position
        up until you (or the youngest of you if you have a partner) retires or
        the State Pension age.
      </h1>

      <p className="text-font-primary pb-32">
        It uses the principles that sit behind the advice provided by Irish
        Life. It's an approximation that uses assumptions to demonstrate the
        value of the advice and your future wealth could be significantly
        different to this projection.
      </p>

      <h2 className="text-font-primary pb-24">
        Naturally, no-one knows the future, so we have to apply a number of
        assumptions. We assume your employment income increases each year, but
        that inflation will also increase your spending and the cost of your
        goals.
      </h2>
      <h3 className="text-font-primary pb-24">
        Additionally, we apply different growth rates depending upon where your
        money is expected to be held. For example, a different growth rate is
        applied to your pension, cash savings and property investments. It
        should be noted that we do not include the 'value' of a defined benefit
        pension in our net wealth projections.
      </h3>
      <h4 className="text-font-primary font-bold pb-24">
        The full assumptions are as follows:
      </h4>

      <p className="text-font-primary">Average earnings increases = 1.5%</p>
      <p className="text-font-primary">Personal income growth rate = 1.0%</p>
      <p className="text-font-primary pb-24">Inflation = 1.0%</p>

      <p className="text-font-primary">
        Irish State Pension age = 66 (and is currently {CURRENCY_SYMBOL}
        13,171.60 per annum)
      </p>
      <p className="text-font-primary pb-24">
        State Pension escalation in retirement = 1.0%
      </p>

      <p className="text-font-primary">
        Pension contributions = Existing contributions plus any recommended
        increases
      </p>
      <p className="text-font-primary pb-24">
        Pension contributions stay consistent as a percentage of income (until
        retirement when they cease)
      </p>

      <p className="text-font-primary">
        Any defined benefit pension income escalation = 1.0%
      </p>
      <p className="text-font-primary">
        Any rental property income escalates = 1.0%
      </p>
      <p className="text-font-primary pb-24">
        Credit cards not cleared every month and overdrafts will be repaid in 2
        years
      </p>

      <p className="text-font-primary pb-24">
        Cash based account rate of return = 0.0%
      </p>

      <p className="text-font-primary">Investment rate of return = 4.5%</p>
      <p className="text-font-primary">Main Residence Property = Inflation %</p>
      <p className="text-font-primary">Other Property growth rate = 4.5%</p>
      <p className="text-font-primary">Pension growth rate = 4.5%</p>
      <p className="text-font-primary pb-40">
        Any other assets rates of return = 0.0%
      </p>

      <p className="mt-24 text-font-primary">
        When a home buying goal is reached, the value is removed from the
        assets.
      </p>
    </div>
  );
};

export { LifePlanGraphDetails };
