import {
  createClient,
  fetchExchange,
  dedupExchange,
  Exchange,
  Client,
} from "urql";
import { retryExchange as createRetryExchange } from "@urql/exchange-retry";
import { createAuthExchange } from "./createAuthExchange";
import { createCacheExchange } from "./createCacheExchange";
import { devtoolsExchange } from "@urql/devtools";
import { createAnalyticsExchange } from "./createAnalyticsExchange";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { createDowntimeExchange } from "./createDowntimeExchange";
import { Queries } from "./queries";

const createUrqlClient = ({
  endpoint,
  getToken,
  refreshToken,
  schema,
  analytics,
  logOut,
  onDowntime,
  queries,
}: {
  endpoint: string;
  getToken: () => string | null;
  refreshToken: () => Promise<void>;
  logOut: () => Promise<void>;
  schema: any;
  analytics?: AnalyticsBrowser;
  onDowntime?: (isDown: boolean) => void;
  queries?: Queries;
}): Client => {
  if (!endpoint) {
    throw new Error("endpoint is required when creating a urql client");
  }

  const cacheExchange = createCacheExchange({ schema, queries });

  const authExchange = createAuthExchange({ getToken, refreshToken, logOut });

  const retryExchange = createRetryExchange({
    initialDelayMs: 1000,
    randomDelay: true,
    maxDelayMs: 1000 * 60 * 10,
    maxNumberAttempts: 40,
    retryIf: (error) => {
      if (error.response?.status >= 500) {
        return true;
      } else if (!!error.networkError) {
        return true;
      }
      return false;
    },
  });

  let analyticsExchange = null;
  if (analytics) {
    analyticsExchange = createAnalyticsExchange(analytics);
  }

  const downtimeExchange = createDowntimeExchange(onDowntime);

  const exchanges = [
    devtoolsExchange,
    dedupExchange,
    analyticsExchange,
    cacheExchange,
    retryExchange,
    authExchange,
    downtimeExchange,
    fetchExchange,
  ].filter((exchange) => exchange != null) as Exchange[];

  return createClient({
    url: endpoint,
    exchanges,
  });
};

export { createUrqlClient };
