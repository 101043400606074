import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";
import {
  IconProps,
  getDimensionsFromSize,
  getColorFromVariant,
} from "./shared";

const NestOutlineIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 36 36"
      data-testid="nest icon"
      {...props}
    >
      <g
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.6}
        clipPath="url(#a)"
      >
        <path d="M13.55 18.014h8.259m-4.681-9.12c2.458-2.873 5.729-4.672 8.366-3.706 3.706 1.357 4.849 7.69 3.393 12.826h-7.078m-4.681-9.12c-2.145-2.042-4.681-3.175-6.856-2.456-3.477 1.148-4.885 6.65-4.038 11.576H21.81m-4.681-9.12c1.8 1.712 3.323 4.065 4.125 6.5.305.923.485 1.798.556 2.62" />
        <path d="M31.091 17.983c0 6.378-6.071 11.549-13.56 11.549-7.49 0-13.561-5.17-13.561-11.549H31.09ZM5.199 21.483h5.317M21.39 22.606h8.162M16.967 25.557h-4.359" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.128.172h35v35h-35z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { NestOutlineIcon };
