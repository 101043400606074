const WhatIsRiskProfile = () => {
  return (
    <div>
      <h1 className="text-font-primary text-t16 sm:text-t21 mb-12">
        What is a risk assessment?
      </h1>
      <p className="text-font-secondary text-t12 sm:text-t16 mb-12">
        A short set of questions that help us work out a profile that fits your
        Savings, Investing & Retirement needs.
      </p>
      <p className="text-font-secondary text-t12 sm:text-t16 mb-24">
        You can always re-assess your risk profile if your circumstances change
        by speaking to one of our advisers. We recommend reviewing at least once
        a year to ensure suitability.
      </p>

      <h2 className="text-font-secondary text-t12 sm:text-t16 font-bold mb-12">
        Why is this needed?
      </h2>
      <p className="text-font-secondary text-t12 sm:text-t16 mb-12">
        To make the most suitable recommendations for you, we need to assess
        your appetite for risk.
      </p>
    </div>
  );
};

export { WhatIsRiskProfile };
