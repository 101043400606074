import { useNavigate } from "react-router-dom";
import { PensionsPageTemplate, PensionsStepper } from "../../components";
import { useIsEmployed } from "../../hooks";
import { FactfindPage } from "../FactfindPage";

const PensionsYourDetails = () => {
  const navigate = useNavigate();
  const { value } = useIsEmployed();

  const onDone = async () => {
    if (value) {
      navigate("/pensions/add-employment-details");
    } else {
      navigate("/pensions/add-contributions-details");
    }
  };

  return (
    <PensionsPageTemplate className="pt-65">
      <PensionsStepper currentStep={1} className="mb-24 sm:mb-80" />
      <FactfindPage
        context="pensions_execution_basic_details"
        onDone={onDone}
        title="Tell us a bit more about you"
        subTitle="You can scroll up and down to edit any of your answers."
      />
    </PensionsPageTemplate>
  );
};

export { PensionsYourDetails };
