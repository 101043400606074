import { FieldContextFragment } from "./fragments/FieldContextFragment";
import { gql } from "urql";

const GET_FIELDS: ReturnType<typeof gql> = gql`
  query GetFields($userId: ID!, $context: String!) {
    factfindFields(userId: $userId, context: $context) {
      ...FieldContextFragment
    }
  }

  ${FieldContextFragment}
`;

export { GET_FIELDS };
