import { Button } from "@multiply/lib";
import { AssetType, PensionType } from "../../graphqlTypes";
import { employerPensionTypes, useAssetForm } from "../../hooks";
import { getAssetNameFromType } from "../../utils";
import { AssetField } from "../UpdateAsset/AssetField";
import { getAssetFieldsFromType } from "../UpdateAsset/getAssetFieldsFromType";

type FocusedPensionFormProps = {
  onSubmit: (values: any) => Promise<void>;
  defaultValues: any;
};

const FocusedPensionForm = ({
  onSubmit,
  defaultValues,
}: FocusedPensionFormProps) => {
  const fields = getAssetFieldsFromType(AssetType.Pension);
  const name = getAssetNameFromType(AssetType.Pension);

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting, isValid, isValidating },
  } = useAssetForm({ fields, defaultValues });

  const pensionType = watch("pensionType");

  const filteredFields = fields.filter((field) => {
    const fieldName = typeof field === "string" ? field : field.fieldName;

    // ask active only if pension type is defined benefit
    if (fieldName === "active" && pensionType !== PensionType.DefinedBenefit) {
      return false;
    }

    // only show owners if not on focussed pension journey
    if (fieldName === "owners") {
      return false;
    }

    if (
      fieldName === "throughCurrentEmployer" &&
      !employerPensionTypes.includes(pensionType)
    ) {
      return false;
    }

    return true;
  });

  return (
    <form className="flex flex-col items-center w-full">
      <div className="flex items-center self-start mb-60">
        <h1 className="text-t27 text-font-primary">{name}</h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-x-32 gap-y-32 self-stretch">
        {filteredFields.map((field) => (
          <AssetField
            key={typeof field === "string" ? field : field.fieldName}
            field={field}
            control={control}
          />
        ))}
      </div>

      <Button
        className="mt-96 w-240"
        onClick={handleSubmit(onSubmit)}
        disabled={isSubmitting || isValidating || !isValid}
      >
        Save
      </Button>
    </form>
  );
};

export { FocusedPensionForm };
