import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";
import {
  IconProps,
  getDimensionsFromSize,
  getColorFromVariant,
} from "./shared";

const HouseOutlineIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);

  return (
    <svg
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="house outline icon"
      {...props}
    >
      <path
        d="M7.132 1.866a1.333 1.333 0 0 1 1.736 0l4.224 3.62 1.658 1.327a.666.666 0 1 1-.833 1.041l-.583-.467v5.28A1.333 1.333 0 0 1 12 14H4a1.333 1.333 0 0 1-1.333-1.333v-5.28l-.583.466a.667.667 0 1 1-.834-1.041l1.658-1.327 4.224-3.62v.001ZM4 6.306v6.36h2V10a1.333 1.333 0 0 1 1.334-1.333h1.333A1.333 1.333 0 0 1 10 10v2.667h2v-6.36L8 2.878 4 6.307Zm4.667 6.36V10H7.334v2.667h1.333Z"
        fill={fill}
      />
    </svg>
  );
};

export { HouseOutlineIcon };
