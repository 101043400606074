import { yupResolver } from "@hookform/resolvers/yup";
import { addMonths, addYears, startOfMonth } from "date-fns";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { transformDate } from "../../utils";
import { GoalsFormValues } from "./types";
import { useGoalsFields } from "./useGoalsFields";

const goalValidationSchema = yup
  .object()
  .shape({
    goalType: yup.string().nullable(),
    targetDate: yup
      .date()
      .transform((value, originalValue) => transformDate(originalValue))
      .typeError('Please enter a valid date in the format "DD-MM-YYYY"')
      .min(
        startOfMonth(addMonths(new Date(), 1)),
        "Your target date must be in the future"
      )
      .max(
        startOfMonth(addYears(new Date(), 100)),
        "Your target date should not be more than 100 years in the future"
      )
      .required("Please provide a target date")
      .nullable(),
    valueToSave: yup
      .number()
      .min(0, "Amount must be at least 0")
      .required("Please provide an answer")
      .nullable(),
  })
  .defined();

const goalsValidationSchema: yup.SchemaOf<GoalsFormValues> = yup
  .object()
  .shape({
    goals: yup
      .array()
      .of(
        goalValidationSchema.concat(
          yup.object().shape({ goalId: yup.string().required() })
        )
      ),
  });

type UseGoalsFormValuesArgs = {
  defaultValues: GoalsFormValues;
  validationSchema?: yup.SchemaOf<GoalsFormValues>;
};

const useGoalsForm = (args: UseGoalsFormValuesArgs) => {
  const defaultValues = args?.defaultValues;
  const validationSchema = args?.validationSchema ?? goalsValidationSchema;

  const form = useForm<GoalsFormValues>({
    defaultValues,
    mode: "all",
    resolver: yupResolver(validationSchema),
    delayError: 1000,
  });

  const { control } = form;
  const { handleChangeGoalIndex, update, goalsFields } = useGoalsFields({
    control,
    defaultValues,
  });
  const result = [
    form,
    { handleChangeGoalIndex, update, goalsFields },
  ] as const;

  return result;
};

export { goalValidationSchema, goalsValidationSchema, useGoalsForm };
