import { SVGProps, FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";

const ArrowHeadUpIcon: FC<SVGProps<SVGSVGElement>> = ({
  className,
  ...props
}) => {
  return (
    <svg
      aria-label={props["aria-label"] ?? "arrow up icon"}
      width={17}
      height={11}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={classNames(defaultClassnames, className)}
    >
      <path
        d="M7.743.876a1 1 0 0 1 1.514 0l7.315 8.47A1 1 0 0 1 15.815 11h-.607a1 1 0 0 1-.736-.323L9.268 5.025a1 1 0 0 0-1.5.033l-4.714 5.587A1 1 0 0 1 2.29 11H1.185a1 1 0 0 1-.757-1.654L7.743.876Z"
        fill="#5261AC"
      />
    </svg>
  );
};

export { ArrowHeadUpIcon };
