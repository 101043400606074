import { ArrowRightCircle, Button, useWindowDimensions } from "@multiply/lib";
import { useNavigate } from "react-router-dom";
import { IrishLifeLogoLeftCorner } from "../../components";
import { HeroType, getHeroContent } from "../../utils";

type HeroPensionProps = {
  type: HeroType;
};

const HeroPension = ({ type }: HeroPensionProps) => {
  const { isPhone } = useWindowDimensions();
  const navigate = useNavigate();

  const {
    heading,
    title,
    subtitle,
    ctaText,
    navPath,
    imageUrl,
    imageUrlSmallDevice,
    disclaimer,
  } = getHeroContent(type);

  return (
    <main className="w-screen min-h-screen sm:h-screen">
      <IrishLifeLogoLeftCorner width={150} height={69} className="sm:absolute mb-[-1%] relative z-10" />
      <div className="h-full w-full flex justify-between flex-col-reverse sm:flex-row">
        <div className="pt-20 sm:pt-[15%] p-[5%] pr-0 ">
          {heading && (
            <h1 className="text-t21 sm:text-t32 text-font-secondary font-semibold">
              {heading}
            </h1>
          )}
          <h2 className="text-t32 lg:text-t57 text-font-primary font-semibold">
            {title}
          </h2>
          <h3 className="sm:text-t21 text-font-tertiary mt-12 sm:mt-36">
            {subtitle}
          </h3>
          <Button
            className="mt-22 sm:mt-36 mb-36 text-t18"
            onClick={() => navigate(navPath)}
            size="sm"
            iconRight={<ArrowRightCircle size="lg" />}
          >
            {ctaText}
          </Button>
          {disclaimer && disclaimer}
        </div>
        <img
          src={isPhone ? imageUrlSmallDevice : imageUrl}
          alt={imageUrl}
          loading="lazy"
        />
      </div>
    </main>
  );
};

export { HeroPension };
