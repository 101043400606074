import { Input, InputProps } from "../index";
import classNames from "classnames";
import { FC } from "react";

export type NumberInputProps = {
  className?: string;
  error?: string | React.ReactNode;
  children?: React.ReactNode;
  decimals?: boolean;
  min?: number;
  max?: number;
  onChange?: (value: number | null) => void;
  onKeyUp?: InputProps["onKeyUp"];
  onBlur?: InputProps["onBlur"];
  helperText?: React.ReactNode;
} & Omit<InputProps, "onChange">;

const NumberInput: FC<NumberInputProps> = ({
  onChange,
  onBlur,
  onKeyUp,
  children,
  className,
  helperText,
  error,
  min,
  max,
  decimals = true,
  value,
  ...inputProps
}) => {
  return (
    <div className={classNames("w-full", className)}>
      <Input
        {...inputProps}
        value={value ?? ""}
        helperText={helperText}
        onChange={(e) => {
          if (e.target.value.length > 9) return false;
          if (max && Number(e.target.value) > max) return false;
          if ((min || min === 0) && Number(e.target.value) < min) return false;
          if (e.target.value === "") {
            onChange && onChange(null);
          } else {
            let nextValue = Number(e.target.value);
            if (!decimals) {
              nextValue = Math.trunc(nextValue);
            }
            onChange && onChange(nextValue);
          }
        }}
        type="number"
        onKeyUp={(event) => {
          onKeyUp && onKeyUp(event);
        }}
        onWheel={(event) => {
          event.currentTarget.blur();
        }}
      >
        {children}
      </Input>
      {error && <p className="text-action-error text-t16 mt-8">{error}</p>}
    </div>
  );
};

export { NumberInput };
