import { FC, ReactNode } from "react";
import { BackButton } from "../BackButton";

type HeroProps = { bannerImg?: string; title: ReactNode; subtitle: string };

const Hero: FC<HeroProps> = ({ bannerImg, title, subtitle }) => {
  return (
    <section className="mb-32 relative">
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-[#00204E] opacity-85 md:bg-gradient-to-r md:from-[#00204E] md:via-transparent md:to-transparent"></div>
      <div>
        <img src={bannerImg} aria-label="team" alt="team" height="auto" />
      </div>
      <BackButton className="invisible xl:visible text-white absolute top-52 left-34" />
      <div className="absolute bottom-20 xl:bottom-40 left-20 xl:left-100 ml-20 mt-40">
        <h1 className="text-t32 xl:text-t57 font-bold pb-0 xl:pb-18 text-white">
          {title}
        </h1>
        <h2 className="xl:text-t21 text-white">{subtitle}</h2>
      </div>
    </section>
  );
};

export { Hero };
