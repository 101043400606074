import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";
import {
  IconProps,
  getDimensionsFromSize,
  getColorFromVariant,
} from "./shared";

const BackArrowIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);

  return (
    <svg
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="back arrow icon"
      {...props}
    >
      <path
        fill={fill}
        d="M7.77 2.9a.674.674 0 0 1 .484-.207c.182 0 .356.075.484.207a.716.716 0 0 1 0 .997L6.742 5.954h3.448c.482-.005.96.088 1.408.274a3.64 3.64 0 0 1 1.197.812c.344.35.616.766.802 1.225a3.86 3.86 0 0 1 0 2.897 3.768 3.768 0 0 1-.801 1.225c-.343.35-.75.626-1.197.813-.448.186-.926.28-1.409.274H3.806a.666.666 0 0 1-.5-.197.705.705 0 0 1-.208-.508.723.723 0 0 1 .209-.507.681.681 0 0 1 .499-.197h6.384c.605 0 1.185-.248 1.613-.689a2.413 2.413 0 0 0 .494-2.561 2.356 2.356 0 0 0-.494-.763 2.246 2.246 0 0 0-1.613-.688H7.265l1.473 1.518a.716.716 0 0 1 0 .997.674.674 0 0 1-.484.207.674.674 0 0 1-.483-.207L4.933 6.955a.812.812 0 0 1-.227-.565c0-.212.082-.416.227-.565L7.771 2.9Z"
      />
    </svg>
  );
};

export { BackArrowIcon };
