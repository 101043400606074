import { ArrowRightNoOutline } from "@multiply/lib";
import { Link } from "react-router-dom";
import { RecommendationGroup } from "../../graphqlTypes";
import { RecommendationGroupSummaries } from "../RecommendationGroupSummaries";

type RecommendationGroupSummary = Pick<
  RecommendationGroup,
  "recommendationType" | "thingsToDo" | "title"
>;

type TopPrioritiesProps = {
  recommendationGroups: RecommendationGroupSummary[];
  className?: string;
};

const TopPriorities = ({
  recommendationGroups,
  className,
}: TopPrioritiesProps) => {
  const priorityGroups = recommendationGroups.slice(0, 3);

  return (
    <section className={className}>
      <RecommendationGroupSummaries
        recommendationGroups={priorityGroups}
        className="mb-24"
      />

      <Link
        to="/advice/priorities"
        className="text-t14 sm:text-t21 text-font-links flex items-center"
      >
        <span>View all priorities &nbsp;&nbsp;</span>
        <ArrowRightNoOutline height=".9em" width=".9em" />
      </Link>
    </section>
  );
};

export { TopPriorities };
