import { NavLink, NavLinkProps, useLocation } from "react-router-dom";
import { hasOwnProperty } from "../../utils";

const ModalNavLink = (props: NavLinkProps) => {
  const location = useLocation();

  const nextState =
    typeof location.state === "object" &&
    hasOwnProperty(location.state ?? {}, "backgroundLocation")
      ? location.state
      : { backgroundLocation: location, ...props.state };

  return <NavLink {...props} state={nextState} />;
};
export { ModalNavLink };
