import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { BookAdvisorCallFormHeader } from "../BookAdvisorCallFormHeader";
import { InputAdviserDate } from "../InputAdviserDate";
import {
  Button,
  Card,
  TextArea,
  ArrowRightCircle,
  Dropdown,
} from "@multiply/lib";
import { transformDate } from "../../utils";
import { useGetUserJourney } from "../../hooks";
import { Loading } from "../../pages";
import { UserJourneyTypes } from "../../globalTypes";

const schema = yup.object().shape({
  bookingDate: yup
    .date()
    .transform((value, originalValue) => transformDate(originalValue))
    .typeError('Please enter a valid date in the format "DD-MM-YYYY"')
    .nullable()
    .required(),
  reason: yup.string().nullable().required(),
  notes: yup.string().nullable(),
});

const pensionschema = yup.object().shape({
  bookingDate: yup
    .date()
    .transform((value, originalValue) => transformDate(originalValue))
    .typeError('Please enter a valid date in the format "DD-MM-YYYY"')
    .nullable()
    .required(),
  notes: yup.string().nullable(),
});

export type BookAdviserCallFormValues = {
  bookingDate: Date | null;
  reason: string | null;
  notes: string | null;
};

export type BookAdviserCallFormProps = {
  onSubmit: (values: BookAdviserCallFormValues) => Promise<void>;
  defaultValues: BookAdviserCallFormValues;
  className?: string;
};

const BookAdviserCallForm = ({
  onSubmit,
  defaultValues,
  className,
}: BookAdviserCallFormProps) => {
  const userJourney = useGetUserJourney();
  const onPensionsJourney =
    userJourney?.value === UserJourneyTypes.FocusedPension ? true : false;

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<BookAdviserCallFormValues>({
    defaultValues,
    resolver: yupResolver(onPensionsJourney ? pensionschema : schema),
    delayError: 500,
    mode: "onChange",
  });

  return (
    <div
      data-testid="book-date-and-time-desktop"
      className={classNames("w-full p-12", className)}
    >
      <BookAdvisorCallFormHeader onPensionsJourney={onPensionsJourney} />
      {userJourney.loading ? (
        <Loading />
      ) : (
        <Card className="w-full mt-40 px-16 sm:px-60 pt-40 pb-24 text-t27 text-font-primary">
          <div className="w-full flex flex-col sm:py-24 gap-24">
            <div className="w-full">
              <Controller
                control={control}
                name="bookingDate"
                render={({ field }) => (
                  <InputAdviserDate
                    value={field.value}
                    onChange={field.onChange}
                    dateLabel="Choose a date"
                    timeLabel="Available times"
                  />
                )}
              />
            </div>

            <div className="sm:col-span-1 w-full mt-24 sm:mt-0">
              {!onPensionsJourney && (
                <Controller
                  control={control}
                  name="reason"
                  render={({ field, fieldState }) => (
                    <Dropdown
                      aria-label="dropdown to see list of topics to discuss"
                      className="sm:w-full px-2 text-t16"
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      error={fieldState.error?.message}
                      label="What would you like to cover?"
                      placeholder="Select an option"
                      items={[
                        "Protection",
                        "Retirement",
                        "Savings & Investing",
                        "Your Plan",
                      ]}
                    />
                  )}
                />
              )}

              <Controller
                control={control}
                name="notes"
                render={({ field, fieldState }) => (
                  <TextArea
                    className="mt-36 sm:mt-60 px-2"
                    value={field.value ?? ""}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={fieldState.error?.message}
                    label="Anything else you want to tell us? (Optional)"
                    maxLength={300}
                    placeholder="Enter text"
                  />
                )}
              />
            </div>
          </div>

          <div className="flex mt-24 px-12 sm:mt-0 justify-center">
            <Button
              iconRight={<ArrowRightCircle size="lg" />}
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid || isSubmitting}
            >
              Schedule appointment
            </Button>
          </div>
        </Card>
      )}
    </div>
  );
};

export { BookAdviserCallForm };
