import { Link } from "react-router-dom";
import classNames from "classnames";

type EmptyAddCardProps = {
  to: string;
  className?: string;
  title?: string;
};
const EmptyAddCard = ({ to, className, title }: EmptyAddCardProps) => {
  return (
    <Link
      to={to}
      className={classNames(
        "flex-1 flex items-center justify-center bg-empty-add-card-background text-empty-add-card-text text-t16 font-semibold rounded-lg py-16 m-b-24 hover:text-font-links-hovered h-80 sm:mt-0",
        className
      )}
      role="link"
    >
      {title}
      <div className="w-24 h-24 sm:w-28 sm:h-28 flex items-center justify-center shrink-0 pl-18">
        <div className="w-24 h-24 sm:w-28 sm:h-28 flex items-center justify-center shrink-0">
          <div className="bg-action-primary rounded-md flex items-center justify-center w-full h-full">
            <p className="text-white font-bold text-t20">+</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export { EmptyAddCard };
