import { AssetType } from "../graphqlTypes";

const getAssetNameFromType = (type: AssetType | undefined | null) => {
  switch (type) {
    case AssetType.Cash:
      return "Cash";
    case AssetType.CurrentAccount:
      return "Current Account";
    case AssetType.AnPostSavingsAccount:
      return "An Post State Savings Account";
    case AssetType.LifeCompanySavingsPlan:
      return "Life Company Savings Plan";
    case AssetType.Shares:
      return "Shares";
    case AssetType.Property:
      return "Property";
    case AssetType.GovernmentBond:
      return "Government Bond";
    case AssetType.InvestmentBond:
      return "Investment Bond";
    case AssetType.ApprovedRetirementFund:
      return "Approved Retirement Fund";
    case AssetType.Land:
      return "Land";
    case AssetType.OtherAsset:
      return "Other";
    case AssetType.CreditUnionAccount:
      return "Credit Union Account";
    case AssetType.DepositTracker:
      return "Deposit Tracker Account";
    case AssetType.RegularSavingsDepositAccount:
      return "Regular Saver / Regular Deposit";
    case AssetType.SavingsDepositAccount:
      return "Savings / Deposit Account";
    case AssetType.TermAccount:
      return "Term Account";
    case AssetType.Pension:
      return "Pension";
    case undefined:
    case null:
      return "Unknown";
    default:
      const unreachable: never = type;
      console.error(`Unknown type detected: ${unreachable}`);
      return "Unknown";
  }
};
export { getAssetNameFromType };
