import classNames from "classnames";

type BookAdvisorCallFormHeaderProps = {
  className?: string;
  onPensionsJourney?: boolean;
};
const BookAdvisorCallFormHeader = ({
  className,
  onPensionsJourney = false,
}: BookAdvisorCallFormHeaderProps) => {
  return (
    <section className={classNames("px-18", className)}>
      <h1 className="text-t21 sm:text-t34 text-font-primary">
        Book a video call with an adviser
      </h1>

      <div className="text-t16 sm:text-t21 text-font-secondary mt-12">
        <p>
          Chat with one of our expert advisers who can walk you through your{" "}
          {onPensionsJourney
            ? "pension recommendations"
            : "financial plan and recommendations"}
          . Please note, if you already have a financial adviser with Irish
          Life, we recommend you talk to them.
        </p>
      </div>
    </section>
  );
};

export { BookAdvisorCallFormHeader };
