import { useNavigate, useParams } from "react-router-dom";
import { Risk } from "../../graphqlTypes";
import { RiskProfileFactfindPage } from "../RiskProfileFactFind";

const HolisticRiskProfile = () => {
  const { redirectPage } = useParams();
  const navigate = useNavigate();

  const onDone = async (risk: Risk) => {
    navigate(`/advice/risk/complete/${redirectPage}`);
  };

  return (
    <RiskProfileFactfindPage
      onDone={onDone}
      title="15 simple questions that will let us know what type of investor you are."
      subTitle="This helps us to give you an accurate investment recommendation."
    />
  );
};

export { HolisticRiskProfile };
