import { Card, CrossIcon, TickIcon } from "@multiply/lib";

const WhatIsHealthInsurance = () => {
  return (
    <Card className="px-48 pt-36 pb-32">
      <h2 className="text-font-secondary text-t21 mb-48">
        The most frequent types of Health Insurance are tiered into these 3
        categories
      </h2>

      <div className="grid grid-cols-[50%_1fr_1fr_1fr] grid-rows-[auto_1fr_1fr_auto]">
        <div></div>
        <div className="text-font-primary font-bold text-t20 text-center mb-56">
          Level 1
        </div>
        <div className="text-font-primary font-bold text-t20 text-center mb-56">
          Level 2
        </div>
        <div className="text-font-primary font-bold text-t20 text-center mb-56">
          Level 3+
        </div>

        <div className="text-right">
          <h3 className="text-font-primary text-t21">Public hospitals</h3>
          <p className="text-font-secondary text-t16 mb-32">
            Semi-Private accommodation in a public hospital
          </p>
        </div>
        <div className="text-center align-top">
          <TickIcon className="text-brand-primary inline" />
        </div>
        <div className="text-center align-top">
          <TickIcon className="text-brand-primary inline" />
        </div>
        <div className="text-center align-top">
          <TickIcon className="text-brand-primary inline" />
        </div>

        <div className="text-right">
          <h3 className="text-font-primary text-t21">
            Public, select private & high tech hospitals
          </h3>
          <p className="text-font-secondary text-t16 mb-32">
            Semi-Private accommodation in a private hospital
          </p>
        </div>
        <div className="text-center align-top">
          <CrossIcon className="text-action-error inline" />
        </div>
        <div className="text-center align-top">
          <TickIcon className="text-brand-primary inline" />
        </div>
        <div className="text-center align-top">
          <TickIcon className="text-brand-primary inline" />
        </div>

        <div className="text-right">
          <h3 className="text-font-primary text-t21">
            All public, private & high tech hospitals
          </h3>
          <p className="text-font-secondary text-t16 mb-32">
            Private accommodation in a private hospital
          </p>
        </div>
        <div className="text-center align-top">
          <CrossIcon className="text-action-error inline" />
        </div>
        <div className="text-center align-top">
          <CrossIcon className="text-action-error inline" />
        </div>
        <div className="text-center align-top">
          <TickIcon className="text-brand-primary inline" />
        </div>
      </div>
    </Card>
  );
};

export { WhatIsHealthInsurance };
