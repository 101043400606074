import { useEffect } from "react";
import { UseMutationResponse, UseQueryResponse } from "urql";

function usePolling<T>(
  [result, executeQuery]: UseQueryResponse<T> | UseMutationResponse<T>,
  pollInterval: number = 5000
) {
  return useEffect(() => {
    if (!result.fetching) {
      const id = setTimeout(
        () => executeQuery({ requestPolicy: "network-only" }),
        pollInterval
      );
      return () => clearTimeout(id);
    }
  }, [result.fetching, executeQuery, pollInterval]);
}

export { usePolling };
