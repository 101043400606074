import { ButtonLink, ContainerMaxSize } from "@multiply/lib";
import { Loading } from "../Loading";
import { OverviewPensions } from "./OverviewPensions";
import { useAllPensionsComplete } from "./useAllPensionsComplete";
import { InteractionFlags } from "../../globalTypes";
import { useInteractionFlag } from "../../hooks";

const Pensions = () => {
  const allPensionsCompleted = useAllPensionsComplete();

  const completedPensionsRiskFactfind = useInteractionFlag(
    InteractionFlags.CompletedPensionsRisk
  );

  return (
    <ContainerMaxSize className="pt-60">
      <div className="h-full sm:min-h-screen flex flex-col">
        {allPensionsCompleted.loading ? (
          <div className="flex-1 flex items-center justify-center">
            <Loading />
          </div>
        ) : (
          <div className="flex-1 flex flex-col max-w-420 gap-32 sm:gap-60">
            <OverviewPensions />
            <div className="w-full flex-1 flex items-start justify-center sm:justify-start">
              <ButtonLink
                className="whitespace-nowrap"
                disabled={!allPensionsCompleted.value}
                to={
                  completedPensionsRiskFactfind.value
                    ? "/pensions/pension-details"
                    : "/pensions/risk/assess"
                }
              >
                I've added all my pensions
              </ButtonLink>
            </div>
          </div>
        )}

        {allPensionsCompleted.error && (
          <p className="text-action-error text-t16 mt-4">
            {allPensionsCompleted.error.message}
          </p>
        )}
      </div>
    </ContainerMaxSize>
  );
};

export { Pensions };
