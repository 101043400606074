import classNames from "classnames";
import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import {
  getColorFromVariant,
  getDimensionsFromSize,
  IconProps,
} from "./shared";

const BookAdviserCallIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);
  return (
    <svg
      aria-hidden={true}
      width={width}
      height={height}
      viewBox="0 0 94 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="book adviser call icon"
      className={classNames(defaultClassnames, className)}
      {...props}
    >
      <circle cx={46.922} cy={46.905} r={39.737} fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m47.569 65.35.017.013a7.875 7.875 0 0 0 9.8-1.076l1.1-1.101a2.625 2.625 0 0 0 0-3.714l-4.642-4.639a2.625 2.625 0 0 0-3.714 0 2.625 2.625 0 0 1-3.711 0l-7.424-7.425a2.625 2.625 0 0 1 0-3.712 2.625 2.625 0 0 0 0-3.713l-4.64-4.64a2.625 2.625 0 0 0-3.712 0l-1.103 1.101a7.875 7.875 0 0 0-1.075 9.8l.011.017a71.06 71.06 0 0 0 19.093 19.09ZM57.422 45.593a9.188 9.188 0 1 0 0-18.375 9.188 9.188 0 0 0 0 18.375Z"
        fill="#5463D6"
        fillOpacity={0.2}
        stroke="#5463D6"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.422 32.468v3.937h3.937"
        fill="#5463D6"
        fillOpacity={0.2}
      />
      <path
        d="M57.422 32.468v3.937h3.937"
        stroke="#5463D6"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx={46.711}
        cy={46.866}
        r={45.211}
        stroke="#E1E5FF"
        strokeWidth={3}
      />
    </svg>
  );
};

export { BookAdviserCallIcon };
