import { Button, Card, Spinner } from "@multiply/lib";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { AdviserImage } from "../AdviserImage";
import { useGetUserJourney } from "../../hooks";

type CompletedAdviserCallCardProps = {
  className?: string;
  adviserImage?: React.ReactElement;
};

const CompletedAdviserCallCard = ({
  className,
  adviserImage,
}: CompletedAdviserCallCardProps) => {
  const userJourney = useGetUserJourney();
  return (
    <section className={classNames("inline-block w-full", className)}>
      {userJourney.loading ? (
        <Spinner />
      ) : (
        <Card className="px-18 py-20 sm:p-40">
          <Link to={userJourney.callRoute}>
            <div className="flex flex-row items-center">
              <AdviserImage className="w-53 sm:w-[100px] lg:w-[120px] mr-14 sm:mr-32" />
              <div className="flex flex-col gap-4 mr-20 flex-1">
                <h1 className="text-t16 sm:text-t27 text-font-primary font-semibold">
                  We're here to help
                </h1>
                <p className="text-t14 sm:text-t20 text-font-secondary">
                  Book a free call, at a time that suits
                </p>
              </div>

              <Button
                className="min-w-fit max-w-fit px-0 sm:py-18 max-h-fit h-fit min-h-fit text-t16 sm:text-t27"
                size="none"
              >
                Book call
              </Button>
            </div>
          </Link>
        </Card>
      )}
    </section>
  );
};

export { CompletedAdviserCallCard };
