import { Tooltip } from "@multiply/lib";
import classNames from "classnames";
import React from "react";
import { ComparisonBlock } from "../../graphqlTypes";
import { CircularCoverGraph } from "./CircularCoverGraph";

type ComparisonSectionProps = {
  contentBlock: ComparisonBlock;
};

const ComparisonSection = ({ contentBlock }: ComparisonSectionProps) => {
  const numberOfFeatures = contentBlock.features?.length ?? 0;
  const numberOfRows = numberOfFeatures + 2;

  return (
    <div
      style={{
        gridTemplateRows: `repeat(${numberOfRows - 2}, auto) 1fr auto`,
        gridTemplateColumns: `auto repeat(${contentBlock.packages.length},1fr)`,
      }}
      className="border-2 border-input-border rounded-lg py-18 pr-18 lg:py-32 lg:pr-32 grid auto-cols-fr grid-flow-col text-center overflow-x-auto"
    >
      <div className="bg-white left-0" />
      {contentBlock.features?.map((feature, index) => (
        <p
          key={index}
          className="text-t10 sm:text-t14 text-font-primary font-bold text-right whitespace-nowrap left-0 bg-white pl-18 lg:pl-32 pr-12 lg:pr-24"
        >
          {feature.name}
          {feature.description && (
            <Tooltip className="inline-block align-middle">
              {feature.description}
            </Tooltip>
          )}
        </p>
      ))}
      <div className="bg-white left-0" />

      {contentBlock.packages.map((contentPackage, packageIndex, packages) => {
        const isLastPackage = packageIndex === packages.length - 1;

        return (
          <React.Fragment key={packageIndex}>
            <div className="flex flex-col items-center">
              <div className="mb-16">
                <CircularCoverGraph percentage={100 - 25 * packageIndex} />
              </div>

              <p className="text-font-primary text-t12 sm:text-t20 font-bold mb-24 px-12 text-center">
                {contentPackage.name}
              </p>
            </div>

            {contentPackage.features?.map((feature, featureIndex, features) => {
              const isLastFeature = featureIndex === features.length - 1;

              return (
                <p
                  key={featureIndex}
                  className={classNames(
                    "text-font-secondary text-t10 sm:text-t16 py-8 px-32 border-input-border",
                    {
                      "pb-32": isLastFeature,
                      "border-r-2": !isLastPackage,
                    }
                  )}
                >
                  {feature}
                </p>
              );
            })}

            <p className="text-font-primary text-t12 sm:text-t20 font-bold px-32">
              {contentPackage.cost?.display}
              <span className="text-t10 sm:text-t16">/month</span>
            </p>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export { ComparisonSection };
