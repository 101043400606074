import classNames from "classnames";
import { CircularProgressGraph } from "../CircularProgressGraph";
import { Spinner } from "@multiply/lib";

type GoalProgressProps = {
  title: string;
  icon?: JSX.Element;
  subtitle: string | JSX.Element;
  progress: number;
  className?: string;
  variant?: "flat" | "floating";
  loading?: boolean;
};

const GoalProgress = ({
  title,
  icon,
  subtitle,
  progress,
  className,
  loading,
}: GoalProgressProps) => {
  return (
    <div
      className={classNames(
        "flex justify-center items-center bg-input-background h-65",
        className
      )}
    >
      {icon && <div className="pl-18">{icon}</div>}
      <div className="mx-16 flex-1 min-w-0">
        <h1 className="text-t16 text-font-primary whitespace-nowrap text-ellipsis overflow-hidden font-semibold">
          {title}
        </h1>
        {!loading ? (
          <p
            className="text-t14 sm:text-t16 text-font-secondary whitespace-nowrap text-ellipsis overflow-hidden"
            data-testid="goal-test-subtitle"
          >
            {subtitle}
          </p>
        ) : (
          <Spinner size="sm" alignHorizontal="left" />
        )}
      </div>
      <div className="w-65">
        <CircularProgressGraph
          percentage={progress}
          text={`${Math.round(progress)}%`}
        />
      </div>
    </div>
  );
};

export { GoalProgress };
