import { useNavigate } from "react-router-dom";
import { ConfirmDialogue } from "../../components";

const ConfirmExitEditDetails = () => {
  const navigate = useNavigate();

  return (
    <ConfirmDialogue
      title="Are you sure you want to exit?"
      confirmTo="/settings/personal-details"
      onClickCancel={() => navigate(-1)}
      confirmCta="Exit without saving"
      cancelCta="Go back to editing"
    />
  );
};

export { ConfirmExitEditDetails };
