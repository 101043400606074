import { useAuth } from "@multiply/lib";
import { PensionsReview, PensionsReviewCardProps } from "../../components";
import {
  useGetFactFindQuery
} from "../../graphqlTypes";
import { useIsEmployed } from "../../hooks";

const PensionsReviewBasicDetails = () => {
  const { userId, loggedIn } = useAuth();
  const { value } = useIsEmployed();

  const [factFindResults] = useGetFactFindQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const {
    retirementAgeEstimate,
    occupation,
    industry,
    residentCountry,
    domicileCountry,
    birthCountry,
    nationality,
    pep,
  } = factFindResults?.data?.factfind?.userSpecificFactfind ?? {};



  const loading = factFindResults?.fetching;

  const reviewData: PensionsReviewCardProps[] = [
    {
      label: "Irish resident for tax purposes",
      fieldName: "resident_country",
      value: residentCountry === "Ireland" ? "Yes" : "No",
    },
    {
      label: "Habitual Irish resident",
      fieldName: "domicile_country",
      value: domicileCountry === "Ireland" ? "Yes" : "No",
    },
    {
      label: "Occupation",
      fieldName: "occupation",
      value: occupation,
    },
    {
      label: "Industry",
      fieldName: "industry",
      value: industry,
    },
    {
      label: "Country of birth",
      fieldName: "country_of_birth",
      value: birthCountry,
    },
    {
      label: "Nationality",
      fieldName: "nationality",
      value: nationality,
    },
    {
      label: "PEP or RCA of a PEP",
      fieldName: "pep",
      value: pep === true ? "Yes" : pep === false ? "No" : null,
    },
    {
      label: "Nominated retirement age",
      fieldName: "retirement_age_estimate",
      value: retirementAgeEstimate,
    },
  ]

  if (!value) {
    reviewData.splice(2, 2);
  }

  return (
    <PensionsReview
      title={<p>Please review and confirm <span className="block sm:inline">your details</span></p>}
      subtitle="Your details"
      loading={loading}
      reviewData={reviewData}
      onDonePath={value ? "/pensions/review-employment-details" : "/pensions/review-contributions-details"}
    />
  );
};

export { PensionsReviewBasicDetails };
