import React, { useRef, useState, useEffect, useMemo } from "react";
import { Recommendation } from "../../graphqlTypes";
import { RecommendationCard } from "../RecommendationCard";
import { DotPagination } from "./DotPagination";
import { TextPagination } from "./TextPagination";

type RecommendationCarouselProps = {
  recommendations?: Recommendation[];
  title?: string;
};

const RecommendationCarousel = ({
  recommendations,
  title = "",
}: RecommendationCarouselProps) => {
  const [currentCard, setCurrentCard] = useState<number>(0);

  const recommendationCardRefs: React.RefObject<HTMLDivElement>[] = useMemo(
    () => recommendations?.map(() => React.createRef()) ?? [],
    [recommendations]
  );

  const parentRef = useRef<HTMLDivElement | null>(null);

  const totalCards: number = recommendationCardRefs?.length || 0;

  useEffect(() => {
    const displayElement = recommendationCardRefs?.[currentCard]?.current;

    if (displayElement) {
      parentRef.current?.scrollTo({
        left: displayElement.offsetLeft,
        behavior: "smooth",
      });
    }
  }, [currentCard, recommendationCardRefs]);

  return (
    <>
      {totalCards > 0 && (
        <>
          <div className="flex justify-between w-full bg-transparent sm:text-t27 text-font-primary pt-24 sm:mb-32">
            <h1 className="text-t21 sm:text-t27 font-semibold">{title}</h1>

            {totalCards > 1 && (
              <TextPagination
                currentPage={currentCard}
                totalPages={totalCards}
                setPage={setCurrentCard}
              />
            )}
          </div>

          <div
            ref={parentRef}
            className="relative max-w-full flex snap-x overflow-hidden"
          >
            {recommendations?.map((recommendation, index) => {
              const elementRef = recommendationCardRefs[index];
              const key = `${recommendation?.id}${index}`;

              return (
                <div key={key} className="snap-center shrink-0 w-full">
                  <div
                    className="shrink-0 w-full p-12"
                    ref={elementRef}
                    style={{
                      height: index === currentCard ? "auto" : 0,
                    }}
                  >
                    <RecommendationCard
                      key={recommendation.id}
                      recommendation={recommendation}
                      className="shadow-secondary"
                    />
                  </div>
                </div>
              );
            })}
          </div>

          {totalCards > 1 && (
            <DotPagination
              currentPage={currentCard}
              totalPages={totalCards}
              setPage={setCurrentCard}
              className="pt-14"
            />
          )}
        </>
      )}
    </>
  );
};

export { RecommendationCarousel };
