import { useNavigate } from "react-router-dom";
import { PensionsPageTemplate, PensionsStepper } from "../../components";
import { PrsaFactfindPage } from "../PrsaFactfindPage";
import { useIsEmployed } from "../../hooks";

const PensionsContributionsDetails = () => {
  const navigate = useNavigate();
  const { value } = useIsEmployed();

  const onDone = async () => {
    navigate("/pensions/investment-fund");
  };

  return (
    <PensionsPageTemplate className="pt-65">
      <PensionsStepper currentStep={value ? 3 : 2} />
      <PrsaFactfindPage
        context="pensions_execution_contributions_details"
        onDone={onDone}
        title="How much would you like to contribute every month?"
      />
    </PensionsPageTemplate>
  );
};

export { PensionsContributionsDetails };
