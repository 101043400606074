const StatePensionEligibilityExplanation = () => {
  return (
    <main className="w-full h-full">
      <h1 className="text-t27 text-font-primary font-bold pb-36">
        How do I know if I am eligible for the State Pension?{" "}
      </h1>
      <p className="text-font-primary pb-24">
        To qualify for the State Pension, you must meet both of the following
        conditions:{" "}
      </p>

      <ul className="list-disc mb-24 ml-16">
        <li className="text-font-primary pb-12">
          Worked in a job that's covered by Ireland's Pay-Related Social
          Insurance program (PRSI) before turning 56 years old
        </li>
        <li className="text-font-primary">
          Paid at least 520 full-rate PRSI contributions (10 years'
          contributions) since entering into insurable employment. If you turned
          66 before 6 April 2012, you need 260 paid full-rate contributions
        </li>
      </ul>
      <p className="text-font-primary pb-24">
        You can request a copy of your PRSI record online at MyWelfare.ie
      </p>
      <p className="text-font-primary pb-24">
        Answer 'eligible' if you believe you will be eligible for the State
        Pension by your retirement age.
      </p>
    </main>
  );
};

export { StatePensionEligibilityExplanation };
