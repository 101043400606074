import { useNavigate } from "react-router-dom";
import { PensionsPageTemplate, PensionsStepper } from "../../components";
import { Factfind } from "../../graphqlTypes";
import { FactfindPage } from "../FactfindPage";

const PensionsEmploymentDetails = () => {
  const navigate = useNavigate();

  const onDone = async (factfind: Factfind) => {
    navigate("/pensions/add-contributions-details");
  };

  return (
    <PensionsPageTemplate className="pt-65">
      <PensionsStepper currentStep={2} className="mb-24 sm:mb-80"/>
      <FactfindPage
        context="pensions_execution_employment_details"
        onDone={onDone}
        title="Next, tell us about your employer"
        subTitle="You can scroll up and down to edit any of your answers"
      />
    </PensionsPageTemplate>
  );
};

export { PensionsEmploymentDetails };
