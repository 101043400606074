import { Button, ContainerMidSize, useAuth } from "@multiply/lib";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ConversationCardTemplate,
  ModalNavLink,
  RadioButtonGroup,
} from "../../components";
import { AssetType, useUpdateAssetMutation } from "../../graphqlTypes";
import {
  sortedPensionTypesPage1,
  sortedPensionTypesPage2,
  UserFacingPensionType,
} from "../../utils";
import { getPensionNameFromType } from "../../utils/getPensionNameFromType";

type ValueType = (string | number | boolean) | null;
type Options = {
  value: ValueType;
  label: string;
};

const AddPension = () => {
  const { userId } = useAuth();

  const [pensionType, setPensionType] = useState<UserFacingPensionType | null>(
    null
  );
  const [updateAssetResult, updateAsset] = useUpdateAssetMutation();
  const navigate = useNavigate();

  const optionsPage1: Options[] = [
    ...sortedPensionTypesPage1.map((pensionType) => ({
      value: pensionType,
      label: getPensionNameFromType(pensionType),
    })),
    { value: "OTHER", label: "Other" },
  ];

  const optionsPage2: Options[] = [
    ...sortedPensionTypesPage2.map((pensionType) => ({
      value: pensionType,
      label: getPensionNameFromType(pensionType),
    })),
    { value: "OTHER", label: "Other" },
  ];

  const [pageOne, setPageOne] = useState<boolean>(true);
  const [options, setOptions] = useState<Options[]>(optionsPage1 ?? []);

  const handleShowOtherPensions = () => {
    switch (pageOne) {
      case true:
        setPageOne(false);
        setOptions(optionsPage2);
        break;
      default:
        setPageOne(true);
        setOptions(optionsPage1);
        break;
    }
  };

  const handleChangePensionType = (pensionType: UserFacingPensionType) => {
    if (pensionType === ("OTHER" as UserFacingPensionType)) {
      handleShowOtherPensions();
    } else {
      setPensionType(pensionType as UserFacingPensionType);
    }
  };

  const handleAddPension = async () => {
    try {
      if (pensionType) {
        const newPension = {
          productType: AssetType.Pension,
          pensionType,
        };
        const result = await updateAsset({
          input: { userId: userId ?? "", asset: newPension },
        });

        if (result?.data?.updateAsset?.success) {
          const assetId = result?.data?.updateAsset?.asset?.id;
          navigate(`/pensions/add/pension/${assetId}`);
        }
      }
    } catch (error) {}
  };

  const error =
    updateAssetResult.error ?? updateAssetResult.data?.updateAsset?.error;

  return (
    <ContainerMidSize>
      <ConversationCardTemplate
        title="Add your pension(s)"
        subtitle={
          <ModalNavLink
            to="/cross/pensions-explanation"
            className="text-font-links"
          >
            Which pension do I have?
          </ModalNavLink>
        }
      >
        <RadioButtonGroup
          value={pensionType}
          onChange={(pensionType) =>
            handleChangePensionType(pensionType as UserFacingPensionType)
          }
          options={options}
          variant="auto"
        />
        <Button
          onClick={handleAddPension}
          disabled={updateAssetResult.fetching || !pensionType}
        >
          Next
        </Button>
        {error && <p className="mt-16 text-action-error">{error.message}</p>}
      </ConversationCardTemplate>
    </ContainerMidSize>
  );
};

export { AddPension };
