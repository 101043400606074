import { AuthorisationStatus, Plan } from "../../graphqlTypes";
import { GuidancePendingReviewCard } from "./GuidancePendingReviewCard";
import { MissingDataCard } from "./MissingDataCard";
import { TriageCard } from "./TriageCard";
import { ErrorCard } from "./ErrorCard";
import { UserPastAdviceAgeCard } from "./UserPastAdviceAgeCard";
import { ReferralCard } from "./ReferralCard";

type PlanStatusCardProps = {
  planResult?: Plan;
  error?: string | null;
  userPastAdviceAge?: boolean;
};

const PlanStatusCard = ({
  planResult,
  error,
  userPastAdviceAge,
}: PlanStatusCardProps) => {
  const authStatus = planResult?.authorisationStatus;

  const missingData =
    (authStatus && authStatus === AuthorisationStatus.AwaitingUserInfo) ||
    !!planResult?.factfindErrors;

  const referred =
    authStatus && [AuthorisationStatus.Referred].includes(authStatus);

  const triaged =
    authStatus &&
    [AuthorisationStatus.Rejected, AuthorisationStatus.Triaged].includes(
      authStatus
    );

  const guidancePendingReview =
    authStatus &&
    [
      AuthorisationStatus.GuidancePendingReview,
      AuthorisationStatus.PendingReview,
    ].includes(authStatus);

  const errored =
    error ||
    (authStatus &&
      [AuthorisationStatus.Suspended, AuthorisationStatus.Error].includes(
        authStatus
      ));

  return userPastAdviceAge ? (
    <UserPastAdviceAgeCard />
  ) : missingData ? (
    <MissingDataCard />
  ) : referred ? (
    <ReferralCard reason={planResult?.triageReason} />
  ) : triaged ? (
    <TriageCard reason={planResult?.triageReason} />
  ) : guidancePendingReview ? (
    <GuidancePendingReviewCard />
  ) : errored ? (
    <ErrorCard />
  ) : null;
};

export { PlanStatusCard };
