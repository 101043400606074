import { Icon, Spinner, useAuth, useToaster } from "@multiply/lib";
import { ConversationForm } from "../../components/ConversationForm";
import { ConversationPageTemplate } from "../../components/ConversationPageTemplate";
import {
  FieldInputType,
  QuestionGroup,
  useGetFieldsQuery,
  useSubmitPensionApplicationMutation,
} from "../../graphqlTypes";
import { flattenObject } from "../../utils";
import { format } from "date-fns";

type FactfindPageProps = {
  context: string;
  onDone: () => void;
  title?: string;
  subTitle?: string;
};

const PensionsPaymentFactfindPage = ({
  context,
  onDone,
  title,
  subTitle,
}: FactfindPageProps) => {
  const { userId, loggedIn } = useAuth();
  const { addToast } = useToaster();

  const [fieldsResult] = useGetFieldsQuery({
    variables: { userId: userId ?? "", context },
    pause: !loggedIn,
  });
  const today = new Date();

  const questionGroups = fieldsResult.data?.factfindFields.questionGroups;

  const directDebitField: QuestionGroup = {
    __typename: "QuestionGroup",
    id: "",
    title: (
      <div className="flex justify-between items-center">
        <p>Direct debit details</p>
      </div>
    ),
    footer: (
      <div className="text-font-secondary font-semibold">
        <p className="mb-6">Date:</p>
        <p className="mb-20">{format(today, "dd/MM/yyyy")}</p>
        <p className="mb-6">Payment frequency</p>
        <p className="mb-28">Monthly</p>
        <p className="text-t12 font-normal mb-8">
          By signing this mandate form, you authorise (A) Irish Life to send
          instructions to your bank to debit your account and (B) your bank to
          debit your account in accordance with the instruction from Irish Life.
          As part of your rights, you are entitled to a refund from your bank
          under the terms and conditions of your agreement with your bank. A
          refund must be claimed within 8 weeks starting from the date on which
          your account was debited. Your rights are explained in a statement
          that you can obtain from your bank.
        </p>
        <p className="text-t12 font-normal mb-8">
          Irish Life Assurance plc isregulated by the Central Bank of Ireland.
        </p>
        <p className="text-t12 font-normal mb-8">
          Irish Life Assurance plc is registered in Ireland number 152576, VAT
          number 9F559239.
        </p>
      </div>
    ),
    questions: [
      {
        __typename: "Question",
        key: "accountHolder",
        placeholder: "Names",
        label: "Name(s) of account holder(s)",
        input: {
          __typename: "FieldInput",
          type: FieldInputType.Text,
          options: null,
          min: null,
          max: null,
        },
      },
      {
        __typename: "Question",
        key: "iban",
        placeholder: "IBAN",
        label: "IBAN",
        helperText: "Your IBAN can be found on a recent bank statement.",
        input: {
          __typename: "FieldInput",
          type: FieldInputType.Text,
          options: null,
          min: null,
          max: null,
        },
      },
      {
        __typename: "Question",
        key: "digitalSignature",
        label: "Digital signature",
        placeholder: "Type your full name",
        input: {
          __typename: "FieldInput",
          type: FieldInputType.Text,
          options: null,
          min: null,
          max: null,
        },
      },
    ],
    contexts: ["pensions_execution_payment_review"],
  };

  const [updatePensionsFactfindResult, updatePensionsFactfind] =
    useSubmitPensionApplicationMutation();

  const onSubmit =
    (onValidationError: (errors: any) => void) => async (values: any) => {
      try {
        const result = await updatePensionsFactfind({
          input: {
            ...values,
            userId: userId ?? "",
          },
        });
        if (result.data?.submitPensionApplication.validationErrors) {
          onValidationError({
            factfind: result.data?.submitPensionApplication.validationErrors,
          });
          return;
        }

        if (!result.data?.submitPensionApplication.success) {
          addToast({
            message:
              result.data?.submitPensionApplication.error?.message ??
              "Something went wrong",
            icon: Icon.Error,
          });
          return;
        }

        if (result.data?.submitPensionApplication) {
          onDone();
        }
      } catch (error) {}
    };

  const defaultValues = {
    accountHolder: null,
    iban: null,
    digitalSignature: null,
    title: null,
    firstName: null,
    lastName: null,
    dateOfBirth: null,
    sex: null,
    confirmedDetails: false,
  };

  return (
    <ConversationPageTemplate title={title} subTitle={subTitle}>
      {questionGroups ? (
        <ConversationForm
          className="mb-[15vh] mt-[5vh]"
          onSubmit={onSubmit}
          questionGroups={[directDebitField, ...questionGroups]}
          loading={updatePensionsFactfindResult.fetching}
          errors={flattenObject(
            updatePensionsFactfindResult.data?.submitPensionApplication
              ?.validationErrors
          )}
          defaultValues={defaultValues}
        />
      ) : (
        <div className="w-full flex items-center justify-center h-[65vh]">
          <Spinner />
        </div>
      )}
    </ConversationPageTemplate>
  );
};

export { PensionsPaymentFactfindPage };
