import classNames from "classnames";
import { BackButton, IrishLifeLogoLeftCorner } from "..";
import { useWindowDimensions } from "@multiply/lib";

type PensionsPageTemplateProps = {
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
  className?: string;
  backButton?: boolean;
  footer?: string;
};

const PensionsPageTemplate = ({
  title,
  subtitle,
  children,
  className,
  backButton,
  footer,
}: PensionsPageTemplateProps) => {
  const { isPhone } = useWindowDimensions();

  return (
    <main className="w-screen min-h-screen flex flex-col justify-between">
      <div>
        {isPhone ? (
          <IrishLifeLogoLeftCorner width={150} height={69} />
        ) : (
          <IrishLifeLogoLeftCorner />
        )}
        {backButton && (
          <BackButton
            aria-label="Back to previous page"
            className="pt-28"
            autoFocus
          />
        )}
        <div
          className={classNames(
            "flex flex-col items-center justify-center",
            {
              "pt-65": !backButton,
            },
            className
          )}
        >
          {title && (
            <h1 className="text-font-primary text-t21 sm:text-t32 font-semibold pb-8">
              {title}
            </h1>
          )}
          {subtitle && (
            <h2 className="sm:text-t21 text-brand-secondary block">
              {subtitle}
            </h2>
          )}
          {children}
        </div>
      </div>
      {footer && (
        <footer className="text-font-secondary text-t18 pl-40 pb-22">
          {footer}
        </footer>
      )}
    </main>
  );
};

export { PensionsPageTemplate };
