import { TrackedLink } from "@multiply/lib";
import { CURRENCY_SYMBOL } from "../../utils";

const TaxRateExplanation = () => {
  return (
    <main className="w-full h-full">
      <h1 className="text-t27 text-font-primary font-bold pb-36">
        How can I work out the highest tax rate I pay?
      </h1>
      <p className="text-font-primary pb-36">
        This is commonly called your 'marginal tax rate' and it can be usually
        found on your payslip. You can also use the table below for guidance.
      </p>

      <h2 className="text-font-primary font-bold">Tax rates and rate bands</h2>

      <table className="bg-white my-18 text-t14 leading-none text-font-primary font-semibold w-full max-w-460 border-brand-primary border">
        <thead className="bg-brand-primary text-white text-t18 py-8 border-brand-primary border">
          <tr>
            <th colSpan={3}>2023</th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center">
            <td className="p-16 border-brand-primary border w-1/3 sm:w-1/2">
              Personal circumstances
            </td>
            <td className="p-16 border-brand-primary border w-1/3 sm:w-1/4">
              Your highest tax rate is 20%
            </td>
            <td className="p-16 border-brand-primary border w-1/3 sm:w-1/4">
              Your highest tax rate is 40%
            </td>
          </tr>
          <tr>
            <td className="p-16 border-brand-primary border font-normal">
              Single or widowed or surviving civil partner, without qualifying
              children
            </td>
            <td className="p-16 border-brand-primary border">
              If your total income is equal or less than {CURRENCY_SYMBOL}40,000
            </td>
            <td className="p-16 border-brand-primary border">
              If your total income exceeds {CURRENCY_SYMBOL}40,000
            </td>
          </tr>
          <tr>
            <td className="p-16 border-brand-primary border font-normal">
              Single or widowed or surviving civil partner, qualifying for
              Single Person Child Carer Credit
            </td>
            <td className="p-16 border-brand-primary border">
              If your total income is equal or less than {CURRENCY_SYMBOL}44,000
            </td>
            <td className="p-16 border-brand-primary border">
              If your total income exceeds {CURRENCY_SYMBOL}44,000
            </td>
          </tr>
          <tr>
            <td className="p-16 border-brand-primary border font-normal">
              Married or in a Civil partnership (one spouse or civil partner
              with income)
            </td>
            <td className="p-16 border-brand-primary border">
              If your total income is equal or less than {CURRENCY_SYMBOL}49,000
            </td>
            <td className="p-16 border-brand-primary border">
              If your total income exceeds {CURRENCY_SYMBOL}49,000
            </td>
          </tr>
          <tr>
            <td className="p-16 border-brand-primary border font-normal">
              Married or in a Civil partnership (both spouses or civil partners
              with income)
            </td>
            <td className="p-16 border-brand-primary border">
              If your total income is equal or less than {CURRENCY_SYMBOL}49,000
              (with an increase of €31,000 max)
            </td>
            <td className="p-16 border-brand-primary border">
              If your total income exceeds {CURRENCY_SYMBOL}49,000
            </td>
          </tr>
        </tbody>
      </table>

      <p className="text-font-primary py-16">
        Note: The increase in the rate band is capped at the lower of{" "}
        {CURRENCY_SYMBOL}31,000 or the income of the lower earner. This increase
        cannot be transferred between spouses or civil partners.
      </p>

      <p className="pb-16 text-font-primary">
        For more information you can visit:{" "}
        <TrackedLink
          className="pb-16 text-font-primary"
          target="_blank"
          rel="noreferrer"
          href="https://www.revenue.ie/en/personal-tax-credits-reliefs-and-exemptions/tax-relief-charts/index.aspx"
        >
          https://www.revenue.ie/en/personal-tax-credits-reliefs-and-exemptions/tax-relief-charts/index.aspx
        </TrackedLink>
      </p>
    </main>
  );
};

export { TaxRateExplanation };
