import { Card, useAuth } from "@multiply/lib";
import { formatISO } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import { AddGoalForm } from "../../components";
import { GoalType, useUpdateGoalMutation } from "../../graphqlTypes";
import { GoalFormValues } from "../../hooks";

const AddGoalFactFind = () => {
  const { goalType } = useParams();
  const [updateGoalResult, updateGoal] = useUpdateGoalMutation();

  const { userId } = useAuth();
  const error =
    updateGoalResult?.error || updateGoalResult?.data?.updateGoal?.error;

  const navigate = useNavigate();

  const onSubmit = async ({
    name,
    targetDate,
    valueToSave,
  }: GoalFormValues) => {
    if (targetDate) {
      try {
        const result = await updateGoal({
          input: {
            userId: userId ?? "",
            goal: {
              name,
              valueToSave,
              targetDate: formatISO(targetDate as unknown as Date, {
                representation: "date",
              }),
              goalType: (goalType as GoalType) ?? GoalType.Custom,
            },
          },
        });

        if (result?.data?.updateGoal?.success) {
          navigate("/advice/plan/view-goals");
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Card
      variant="flat"
      className="w-full pb-36 sm:px-24 pt-32 flex flex-col items-center"
    >
      <AddGoalForm
        goalType={goalType as GoalType}
        onSubmit={onSubmit}
        className="max-w-340 items-center"
      />
      {error ? (
        <p className="mt-24 text-action-error">{error.message}</p>
      ) : null}
    </Card>
  );
};

export { AddGoalFactFind };
