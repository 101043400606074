import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  fromPromise,
  ServerError,
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";

const createApolloClient = ({
  endpoint,
  getToken,
  refreshToken,
}: {
  endpoint: string;
  getToken: () => string | null;
  refreshToken: () => Promise<void>;
}) => {
  const authLink = new ApolloLink((operation, forward) => {
    // const metaHeaders = {
    //   "build-number": env.BUILD_NUMBER,
    //   version: VersionNumber.appVersion.replace(/[\n\t\r]/g, ""),
    //   platform: Platform.OS,
    //   "device-id": DeviceInfo.getUniqueId().toLowerCase(),
    // };

    const token = getToken();

    if (token) {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          // ...metaHeaders,
          "auth-token": token,
        },
      }));
    }

    return forward(operation);
  });

  const refreshTokenLink = onError(
    ({ networkError, graphQLErrors = [], operation, forward }) => {
      const refreshJWT = () => {
        return fromPromise(refreshToken()).flatMap(() => {
          return forward(operation);
        });
      };

      const networkErrors = (networkError as ServerError)?.result?.errors ?? [];
      const serverErrorMessage = (networkError as ServerError)?.result?.message;
      if (
        serverErrorMessage === "Token Error" ||
        networkErrors.find((error: any) => error.message === "Token Error") ||
        graphQLErrors.find((error) => error.message === "Token Error")
      ) {
        return refreshJWT();
      }
    }
  );

  const httpLink = new HttpLink({
    uri: endpoint,
  });

  const retryLink = new RetryLink();

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: from([retryLink, refreshTokenLink, authLink, httpLink]),
  });
};

export { createApolloClient };
