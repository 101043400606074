import { useLocallyStoreCollegeCostsCalcResults } from "@multiply-ai/college-costs-calculator";
import {
  AuthenticatedTemplate,
  Toasts,
  UnauthenticatedTemplate,
  useAnalyticsIfAvailable,
  useAuth,
  useReferral,
} from "@multiply/lib";
import { captureException } from "@sentry/react";
import { useEffect, useRef } from "react";
import { useIdleTimer } from "react-idle-timer";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import {
  BreadcrumbLayout,
  GoalsWizardLayout,
  IntroFactFindLayout,
  ModalLayout,
  ModalWithCrossLayout,
  ModalWithoutCrossLayout,
  PensionsAppointmentWrapper,
  ScrollToTop,
  SidebarLayout,
  UATBanner,
  WebChatButton,
} from "./components";
import TaxReliefContributions from "./components/TaxReliefContributions/TaxReliefContributions";
import { InteractionFlags, UserJourneyTypes } from "./globalTypes";
import {
  useCreateUserFromAuth,
  useGetUserJourney,
  useInteractionFlag,
  useIsIntroComplete,
  useSaveCollegeCostsValuesAndSavingsGoal,
} from "./hooks";
import {
  AddAsset,
  AddAssets,
  AddDebt,
  AddDebts,
  AddGoalFactFind,
  AddGoalType,
  AddPension,
  AddPensions,
  AddPolicies,
  AddPolicy,
  AdviceChanged,
  AdviserCallHistory,
  AllocatePensionContributions,
  AppLoading,
  ApplicationConfirmation,
  AuthIdlePrompt,
  BasicInfoFactFind,
  BookAdviserCall,
  CheckIn,
  CollegeCostsCalculator,
  CollegeCostsCalculatorResults,
  ConfirmAddedAllProducts,
  ConfirmDeleteAsset,
  ConfirmDeleteDebt,
  ConfirmDeletePolicy,
  ConfirmExitEditDetails,
  ConfirmInvestmentFund,
  Contact,
  Continue,
  CreatePlanRightTime,
  Debt,
  DeleteGoal,
  Documents,
  EditAddress,
  EditContactPreferences,
  EditContributionAmount,
  EditContributionsDetails,
  EditGoalName,
  EditPensionsDetails,
  EditPersonalDetails,
  EggGraphDetails,
  EmergencyFund,
  EmploymentDetailsCheck,
  EmploymentStatusTriage,
  FinancialFactfind,
  GenerateLifePlan,
  Health,
  HolisticPersonalDetails,
  HolisticRiskProfile,
  HolisticUpgrade,
  Home,
  Inbox,
  IntroFactFindAddress,
  IntroFactFindMoreInfo,
  IntroFactFindNameDob,
  IntroFactFindPhone,
  IntroRedirect,
  Library,
  LifePlanGraphDetails,
  Login,
  Logout,
  MonthlyOutgoingEssentialsExplanation,
  MonthlyOutgoingNonEssentialsExplanation,
  MonthlyTakeHomeExplanation,
  NameCustomGoal,
  NetworkError,
  OtherIncomeExplanation,
  PageNotFound,
  PensionAdvice,
  PensionFactFind,
  PensionLimitations,
  PensionSimulator,
  PensionVulnerabilityCheck,
  Pensions,
  PensionsChangeDetails,
  PensionsContributionsDetails,
  PensionsDeclaration,
  PensionsEmploymentDetails,
  PensionsExplanation,
  PensionsHome,
  PensionsInvestmentFund,
  PensionsPayment,
  PensionsPersonalDetails,
  PensionsReviewBasicDetails,
  PensionsReviewContributionsDetails,
  PensionsReviewEmploymentDetails,
  PensionsRiskProfileFactFind,
  PensionsYourDetails,
  PepExplanation,
  Plan,
  Priorities,
  Products,
  Protection,
  RecommendationsPriorityExplanation,
  Retirement,
  RetirementGoalsUpdated,
  RiskProfileComplete,
  RiskProfileOptions,
  SavingsAndInvesting,
  Settings,
  SignUp,
  SingleSignOn,
  StartPensionApplication,
  StatePensionEligibilityExplanation,
  SuggestTool,
  SuggestedAddGoal,
  SuggestedGoalChildEducation,
  SuggestedGoalHome,
  SuggestedGoalWedding,
  TaxRateExplanation,
  Tools,
  UpdateAsset,
  UpdateDebt,
  UpdatePension,
  UpdatePolicy,
  ViewGoals,
  VulnerabilityReminderScheduled,
  WhatIsRiskProfile,
  Welcome,
  AdvisorIntro,
  TeamIntro,
  PickAPlan,
  HolisticPlan,
  PensionPlan
} from "./pages";
import { useIsMIL } from "./utils";

function App() {
  const [searchParams] = useSearchParams();
  const advisor = searchParams.get("advisor");
  const teamName = searchParams.get("teamName");
  const referrer = searchParams.get("page_land");
  const userType = searchParams.get("UserType");
  const userJourney = searchParams.get("userJourney");
  const sourceBranchName = searchParams.get("sourcebranchname");

  const { saveReferralDetails } = useReferral();
  useEffect(() => {
    saveReferralDetails({
      advisor,
      teamName,
      referrer,
      userType,
      userJourney,
      sourceBranchName,
    });
  }, [
    advisor,
    referrer,
    saveReferralDetails,
    teamName,
    userType,
    userJourney,
    sourceBranchName,
  ]);

  const collegeCosts = searchParams.get("collegeCosts");
  const { saveCalculationDetails } = useLocallyStoreCollegeCostsCalcResults();

  useEffect(() => {
    saveCalculationDetails(collegeCosts);
  }, [collegeCosts, saveCalculationDetails]);

  return <Pages />;
}

const AuthenticatedRoutes = () => {
  const { logOut } = useAuth();
  const isMil = useIsMIL();

  const handleLogout = () => {
    if (isMil) {
      if (!process.env.REACT_APP_MY_IRISH_LIFE_URI) {
        captureException(
          new Error(`REACT_APP_MY_IRISH_LIFE_URI env var not configured`)
        );
      }
      logOut(process.env.REACT_APP_MY_IRISH_LIFE_URI);
    } else {
      logOut();
    }
  };

  // const navigate = useNavigate();
  const introFlowComplete = useIsIntroComplete();
  const userCreated = useCreateUserFromAuth();
  const userJourney = useGetUserJourney();

  let location = useLocation();
  let state = location.state as { backgroundLocation?: Location };
  const CompletedGenerateLifePlan = useInteractionFlag(
    InteractionFlags.CompletedGenerateLifePlan
  );

  const CompletedGeneratedPensionAdvice = useInteractionFlag(
    InteractionFlags.CompletedPensionsOnboarding
  );

  const idleTimer = useIdleTimer({
    timeout: 1000 * 60 * 5,
    promptTimeout: 1000 * 60 * 0,
    onPrompt: () => {
      // const nextState =
      //   typeof location.state === "object" &&
      //   hasOwnProperty(location.state ?? {}, "backgroundLocation")
      //     ? location.state
      //     : { backgroundLocation: location };
      // navigate(`/idle-timeout`,  {
      //   state: nextState,
      // });
    },
    onIdle: process.env.NODE_ENV === "development" ? () => {} : handleLogout,
  });

  useSaveCollegeCostsValuesAndSavingsGoal();

  return (
    <>
      <Routes location={state?.backgroundLocation || location}>
        {userJourney.error || userCreated.error || introFlowComplete.error ? (
          <Route
            path="*"
            element={
              <NetworkError
                error={
                  userCreated.error?.message ?? introFlowComplete.error?.message
                }
              />
            }
          />
        ) : userJourney.loading ||
          introFlowComplete.loading ||
          !userCreated.complete ? (
          <Route path="*" element={<AppLoading />} />
        ) : !introFlowComplete?.complete ? (
          <Route path="/" element={<IntroFactFindLayout />}>
            <Route path="name" element={<IntroFactFindNameDob />} />
            <Route path="address" element={<IntroFactFindAddress />} />
            <Route path="phone" element={<IntroFactFindPhone />} />
            <Route index element={<IntroRedirect />} />
            <Route path="*" element={<IntroRedirect />} />
          </Route>
        ) : (
          <>
            <Route path="/continue" element={<Continue />} />
            <Route path="/" element={<SidebarLayout />}>
              <Route
                path="phone"
                element={<Navigate to={userJourney.defaultRoute} replace />}
              />
              <Route
                path="name"
                element={<Navigate to={userJourney.defaultRoute} replace />}
              />
              <Route
                path="address"
                element={<Navigate to={userJourney.defaultRoute} replace />}
              />
              <Route
                index
                element={<Navigate to={userJourney.defaultRoute} replace />}
              />
              <Route path="products" element={<Products />} />
              <Route path="inbox" element={<Inbox />} />
              <Route path="tools" element={<Tools />} />
              <Route path="library" element={<Library />} />
              <Route path="check-in" element={<CheckIn />} />
              <Route path="advice" element={<Home />} />
              <Route path="advice/plan" element={<Plan />} />
              <Route
                path="pensions/pension-details"
                element={<PensionAdvice />}
              />
              <Route
                path="pensions/holistic-upgrade"
                element={<HolisticUpgrade />}
              />
            </Route>

            <Route
              path="/advice/plan"
              element={
                <BreadcrumbLayout
                  title={
                    <>
                      <Link to="/advice">Financial Plan</Link>/
                      <span className="text-font-primary">Create a plan</span>
                    </>
                  }
                  backButton={
                    CompletedGenerateLifePlan.value
                      ? {
                          mobile: true,
                          desktop: true,
                        }
                      : null
                  }
                />
              }
            >
              <Route path="basic-info" element={<BasicInfoFactFind />} />
              <Route
                path="financial-situation"
                element={<FinancialFactfind />}
              />
              <Route path="generate" element={<GenerateLifePlan />} />
              <Route path="view-goals" element={<ViewGoals />} />

              <Route path="goals-wizard" element={<GoalsWizardLayout />}>
                <Route index element={<SuggestedGoalHome />} />
                <Route path="wedding" element={<SuggestedGoalWedding />} />
                <Route
                  path="child-education"
                  element={<SuggestedGoalChildEducation />}
                />
                <Route
                  path="add-goal/:hasSuggestedGoals"
                  element={<SuggestedAddGoal />}
                />
                <Route
                  path="create-plan-right-time"
                  element={<CreatePlanRightTime />}
                />
              </Route>
            </Route>

            <Route path="/onboarding">
              <Route path="welcome" element={<Welcome />} />
              <Route path="adviser-intro" element={<AdvisorIntro />} />
              <Route path="team-intro" element={<TeamIntro />} />
              <Route path="pick-a-plan" element={<PickAPlan />} />
              <Route path="pick-holistic" element={<HolisticPlan />} />
              <Route path="pick-pension" element={<PensionPlan />} />
            </Route>

            <Route
              path="/advice/plan"
              element={
                <BreadcrumbLayout
                  title={
                    <>
                      <Link to="/advice">Recommendations</Link>/
                      <span className="text-font-primary">Protection</span>
                    </>
                  }
                  backButton={
                    CompletedGenerateLifePlan.value
                      ? {
                          mobile: true,
                          desktop: true,
                        }
                      : null
                  }
                />
              }
            >
              <Route
                path="protection"
                element={
                  <ScrollToTop>
                    <Protection />
                  </ScrollToTop>
                }
              />
            </Route>

            <Route
              path="/advice/plan"
              element={
                <BreadcrumbLayout
                  title={
                    <>
                      <Link to="/advice">Recommendations</Link>/
                      <span className="text-font-primary">Retirement</span>
                    </>
                  }
                  backButton={
                    CompletedGenerateLifePlan.value
                      ? {
                          mobile: true,
                          desktop: true,
                        }
                      : null
                  }
                />
              }
            >
              <Route
                path="retirement"
                element={
                  <ScrollToTop>
                    <Retirement />
                  </ScrollToTop>
                }
              />
            </Route>

            <Route
              path="/advice/plan"
              element={
                <BreadcrumbLayout
                  title={
                    <>
                      <Link to="/advice">Recommendations</Link>/
                      <span className="text-font-primary">Debt</span>
                    </>
                  }
                  backButton={
                    CompletedGenerateLifePlan.value
                      ? {
                          mobile: true,
                          desktop: true,
                        }
                      : null
                  }
                />
              }
            >
              <Route
                path="debt"
                element={
                  <ScrollToTop>
                    <Debt />
                  </ScrollToTop>
                }
              />
            </Route>

            <Route
              path="/advice/plan"
              element={
                <BreadcrumbLayout
                  title={
                    <>
                      <Link to="/advice">Recommendations</Link>/
                      <span className="text-font-primary">
                        Savings & investing
                      </span>
                    </>
                  }
                  backButton={
                    CompletedGenerateLifePlan.value
                      ? {
                          mobile: true,
                          desktop: true,
                        }
                      : null
                  }
                />
              }
            >
              <Route
                path="savings-and-investing"
                element={
                  <ScrollToTop>
                    <SavingsAndInvesting />
                  </ScrollToTop>
                }
              />
            </Route>

            <Route
              path="/advice/plan"
              element={
                <BreadcrumbLayout
                  title={
                    <>
                      <Link to="/advice">Recommendations</Link>/
                      <span className="text-font-primary">Emergency fund</span>
                    </>
                  }
                  backButton={
                    CompletedGenerateLifePlan.value
                      ? {
                          mobile: true,
                          desktop: true,
                        }
                      : null
                  }
                />
              }
            >
              <Route
                path="emergency-fund"
                element={
                  <ScrollToTop>
                    <EmergencyFund />
                  </ScrollToTop>
                }
              />
            </Route>

            <Route
              path="/advice/plan"
              element={
                <BreadcrumbLayout
                  title={
                    <>
                      <Link to="/advice">Recommendations</Link>/
                      <span className="text-font-primary">Health</span>
                    </>
                  }
                  backButton={
                    CompletedGenerateLifePlan.value
                      ? {
                          mobile: true,
                          desktop: true,
                        }
                      : null
                  }
                />
              }
            >
              <Route
                path="health"
                element={
                  <ScrollToTop>
                    <Health />
                  </ScrollToTop>
                }
              />
            </Route>

            <Route
              path="/advice/recommendations-priority"
              element={
                <BreadcrumbLayout
                  backButton={{ mobile: true, desktop: true }}
                />
              }
            >
              <Route index element={<RecommendationsPriorityExplanation />} />
            </Route>

            <Route
              path="/advice/priorities"
              element={
                <BreadcrumbLayout
                  title={
                    <>
                      <Link to="/advice">Plan</Link>/
                      <span className="text-font-primary">Recommendations</span>
                    </>
                  }
                  backButton={{ mobile: true, desktop: true }}
                />
              }
            >
              <Route index element={<Priorities />} />
            </Route>

            <Route
              path="/appointments"
              element={
                <BreadcrumbLayout
                  title={
                    <>
                      <Link to="/advice">Financial Plan/</Link>
                      <span className="text-font-primary">Book a call</span>
                    </>
                  }
                  backButton={{ mobile: true, desktop: true }}
                />
              }
            >
              <Route
                index
                element={
                  <ScrollToTop>
                    <BookAdviserCall />
                  </ScrollToTop>
                }
              />
            </Route>

            <Route
              path="/appointments/history"
              element={
                <BreadcrumbLayout
                  backButton={{ mobile: true, desktop: true }}
                />
              }
            >
              <Route index element={<AdviserCallHistory />} />
            </Route>

            <Route
              path="/advice/risk"
              element={
                <BreadcrumbLayout
                  title={
                    <>
                      <Link to="/advice">Financial Plan/</Link>
                      <span className="text-font-primary">Risk Profile</span>
                    </>
                  }
                  backButton={{ mobile: true, desktop: true }}
                />
              }
            >
              <Route
                path="assess/:redirectPage"
                element={
                  <ScrollToTop>
                    <HolisticRiskProfile />
                  </ScrollToTop>
                }
              />

              <Route
                path="accept-investment-fund/:redirectPage"
                element={
                  <ScrollToTop>
                    <ConfirmInvestmentFund to="/advice/risk/complete" />
                  </ScrollToTop>
                }
              />

              <Route
                path="complete/:redirectPage"
                element={<RiskProfileComplete />}
              />
            </Route>

            <Route
              path="settings"
              element={
                <BreadcrumbLayout
                  title={
                    <>
                      <Link to="/settings">Settings/</Link>
                      <span className="text-font-primary">Home</span>
                    </>
                  }
                  backButton={{ mobile: true, desktop: false }}
                />
              }
            >
              <Route index element={<Settings />} />
            </Route>

            <Route
              path="settings/personal-details"
              element={
                <BreadcrumbLayout
                  title={
                    <>
                      <Link to="/settings">Settings/</Link>
                      <span className="text-font-primary">
                        Personal details
                      </span>
                    </>
                  }
                  backButton={{ mobile: true, desktop: false }}
                />
              }
            >
              <Route
                index
                element={
                  <>
                    {userJourney.value === UserJourneyTypes.FocusedPension ? (
                      <PensionsPersonalDetails />
                    ) : (
                      <HolisticPersonalDetails />
                    )}
                  </>
                }
              />

              <Route path="edit-address" element={<EditAddress />} />
            </Route>

            <Route
              path="settings/edit-personal-details"
              element={
                <BreadcrumbLayout
                  title={
                    <>
                      <Link
                        to="/settings/personal-details"
                        className="text-font-links"
                      >
                        Settings/
                      </Link>
                      <span className="text-font-primary">Update details</span>
                    </>
                  }
                  backButton={{ mobile: true, desktop: false }}
                />
              }
            >
              <Route
                path="edit/:fieldName"
                element={
                  <ScrollToTop>
                    <EditPersonalDetails
                      cta="Done"
                      onDoneRoute="/settings/personal-details"
                    />
                  </ScrollToTop>
                }
              />
            </Route>

            <Route
              path="settings/contact-preferences"
              element={
                <BreadcrumbLayout
                  title={
                    <>
                      <Link to="/settings">Settings/</Link>
                      <span className="text-font-primary">
                        Contact preferences
                      </span>
                    </>
                  }
                  backButton={{ mobile: true, desktop: false }}
                />
              }
            >
              <Route index element={<EditContactPreferences />} />
            </Route>

            <Route
              path="settings/documents"
              element={
                <BreadcrumbLayout
                  title={
                    <>
                      <Link to="/settings">Settings/</Link>
                      <span className="text-font-primary">Documents</span>
                    </>
                  }
                  backButton={{ mobile: true, desktop: true }}
                />
              }
            >
              <Route index element={<Documents />} />
            </Route>

            <Route
              path="settings/contact"
              element={
                <BreadcrumbLayout
                  title={
                    <>
                      <Link to="/settings">Settings/</Link>
                      <span className="text-font-primary">Contact</span>
                    </>
                  }
                  backButton={{ mobile: true, desktop: true }}
                />
              }
            >
              <Route index element={<Contact />} />
            </Route>

            <Route path="/products">
              <Route
                path="add"
                element={
                  <BreadcrumbLayout
                    title={
                      <>
                        <Link to="/products">Products</Link>/
                        <span className="text-font-primary">Add asset</span>
                      </>
                    }
                    backButton={{ mobile: true, desktop: true }}
                  />
                }
              >
                <Route path="assets" element={<AddAssets />} />
                <Route path="asset" element={<AddAsset />} />
                <Route path="asset/:assetId" element={<UpdateAsset />} />
              </Route>

              <Route
                path="add"
                element={
                  <BreadcrumbLayout
                    title={
                      <>
                        <Link to="/products">Products</Link>/
                        <span className="text-font-primary">Add debt</span>
                      </>
                    }
                    backButton={{ mobile: true, desktop: true }}
                  />
                }
              >
                <Route path="debts" element={<AddDebts />} />
                <Route path="debt" element={<AddDebt />} />
                <Route path="debt/:debtId" element={<UpdateDebt />} />
              </Route>

              <Route
                path="add"
                element={
                  <BreadcrumbLayout
                    title={
                      <>
                        <Link to="/products">Products</Link>/
                        <span className="text-font-primary">Add policy</span>
                      </>
                    }
                    backButton={{ mobile: true, desktop: true }}
                  />
                }
              >
                <Route path="policies" element={<AddPolicies />} />
                <Route path="policy" element={<AddPolicy />} />
                <Route path="policy/:policyId" element={<UpdatePolicy />} />
              </Route>
            </Route>

            <Route
              path="pensions"
              element={
                <BreadcrumbLayout
                  backButton={
                    CompletedGeneratedPensionAdvice.value
                      ? { mobile: true, desktop: true }
                      : null
                  }
                />
              }
            >
              <Route
                index
                element={
                  <ScrollToTop>
                    <Pensions />
                  </ScrollToTop>
                }
              />
            </Route>

            <Route path="/pensions/home" element={<PensionsHome />} />

            <Route
              path="/pensions/appointments"
              element={<PensionsAppointmentWrapper />}
            />

            <Route
              path="/pensions/appointments/history"
              element={
                <BreadcrumbLayout
                  backButton={{ mobile: true, desktop: true }}
                />
              }
            >
              <Route index element={<AdviserCallHistory />} />
            </Route>

            <Route path="/pensions">
              <Route
                path="add"
                element={
                  <BreadcrumbLayout
                    title={
                      <>
                        <Link to="/pensions">Pensions</Link>/
                        <span className="text-font-primary">Add pension</span>
                      </>
                    }
                    backButton={
                      CompletedGeneratedPensionAdvice.value
                        ? { mobile: true, desktop: true }
                        : null
                    }
                  />
                }
              >
                <Route path="pensions" element={<AddPensions />} />
                <Route path="pension" element={<AddPension />} />
                <Route path="pension/:pensionId" element={<UpdatePension />} />
              </Route>

              <Route
                path="pension-details/pension-calculator"
                element={
                  <BreadcrumbLayout
                    backButton={
                      CompletedGeneratedPensionAdvice.value
                        ? { mobile: true, desktop: true }
                        : null
                    }
                  />
                }
              >
                <Route
                  index
                  element={
                    <ScrollToTop>
                      <PensionSimulator />
                    </ScrollToTop>
                  }
                />
              </Route>

              <Route
                path="start-application"
                element={<StartPensionApplication />}
              />

              <Route path="advice-changed" element={<AdviceChanged />} />

              <Route
                path="application-confirmation"
                element={<ApplicationConfirmation />}
              />

              <Route path="declaration" element={<PensionsDeclaration />} />

              <Route
                path="edit-contribution"
                element={<EditContributionAmount />}
              />

              <Route
                path="employment-details-check"
                element={<EmploymentDetailsCheck />}
              />

              <Route
                path="edit-employment-details/:fieldName"
                element={
                  <ScrollToTop>
                    <EditPensionsDetails
                      cta="Continue"
                      onDoneRoute="/pensions/employment-details-check"
                    />
                  </ScrollToTop>
                }
              />

              <Route
                path="edit-details/:fieldName"
                element={
                  <ScrollToTop>
                    <EditPensionsDetails cta="Save" />
                  </ScrollToTop>
                }
              />

              <Route
                path="edit-contributions/:fieldName"
                element={
                  <ScrollToTop>
                    <EditContributionsDetails cta="Save" />
                  </ScrollToTop>
                }
              />

              <Route
                path="factfind"
                element={
                  <ScrollToTop>
                    <PensionFactFind />
                  </ScrollToTop>
                }
              />

              <Route path="direct-debit" element={<PensionsPayment />} />

              <Route
                path="limitations"
                element={
                  <ScrollToTop>
                    <PensionLimitations />
                  </ScrollToTop>
                }
              />

              <Route
                path="vulnerable"
                element={
                  <ScrollToTop>
                    <PensionVulnerabilityCheck />
                  </ScrollToTop>
                }
              />

              <Route
                path="risk/assess"
                element={
                  <ScrollToTop>
                    <PensionsRiskProfileFactFind />
                  </ScrollToTop>
                }
              />

              <Route
                path="accept-investment-fund"
                element={
                  <ScrollToTop>
                    <PensionsInvestmentFund to="/pensions/pension-details" />
                  </ScrollToTop>
                }
              />

              <Route
                path="add-basic-details"
                element={
                  <ScrollToTop>
                    <PensionsYourDetails />
                  </ScrollToTop>
                }
              />
              <Route
                path="add-employment-details"
                element={
                  <ScrollToTop>
                    <PensionsEmploymentDetails />
                  </ScrollToTop>
                }
              />
              <Route
                path="add-contributions-details"
                element={
                  <ScrollToTop>
                    <PensionsContributionsDetails />
                  </ScrollToTop>
                }
              />
              <Route
                path="investment-fund"
                element={
                  <PensionsInvestmentFund
                    canRecommend={false}
                    to="/pensions/review-basic-details"
                  />
                }
              />
              <Route
                path="review-basic-details"
                element={<PensionsReviewBasicDetails />}
              />
              <Route
                path="review-employment-details"
                element={<PensionsReviewEmploymentDetails />}
              />
              <Route
                path="review-contributions-details"
                element={<PensionsReviewContributionsDetails />}
              />
              <Route
                path="start-application"
                element={<StartPensionApplication />}
              />
              <Route path="advice-changed" element={<AdviceChanged />} />
              <Route
                path="application-confirmation"
                element={<ApplicationConfirmation />}
              />
              <Route
                path="employment-details-check"
                element={<EmploymentDetailsCheck />}
              />
              <Route
                path="allocate-contributions"
                element={<AllocatePensionContributions />}
              />
            </Route>

            <Route path="/tools">
              <Route
                path="college-costs-calculator/"
                element={
                  <BreadcrumbLayout
                    title={
                      <>
                        <Link to="/tools">Tools</Link>/
                        <span className="text-font-primary">
                          College costs calculator
                        </span>
                      </>
                    }
                    backButton={{ mobile: true, desktop: true }}
                    backToDestination="/tools"
                  />
                }
              >
                <Route
                  index
                  element={
                    <CollegeCostsCalculator className="py-32 w-full px-max-screen lg:px-max-screen-lg" />
                  }
                />
                <Route
                  path="start-over"
                  element={
                    <CollegeCostsCalculator
                      className="py-32 w-full px-max-screen lg:px-max-screen-lg"
                      startOver={true}
                    />
                  }
                />
              </Route>
              <Route
                path="college-costs-calculator/"
                element={
                  <BreadcrumbLayout
                    title={
                      <>
                        <Link to="/tools">Tools</Link>/
                        <span className="text-font-primary">
                          College costs calculator
                        </span>
                      </>
                    }
                    backButton={{ mobile: true, desktop: true }}
                    backToDestination="/tools/college-costs-calculator/start-over"
                  />
                }
              >
                <Route
                  path="results"
                  element={
                    <CollegeCostsCalculatorResults className="py-32 sm:py-0 w-full px-max-screen lg:px-max-screen-lg" />
                  }
                />
                <Route
                  path="results/:calculationId"
                  element={
                    <CollegeCostsCalculatorResults className="py-32 sm:py-0 w-full px-max-screen lg:px-max-screen-lg" />
                  }
                />
              </Route>

              <Route
                path="college-costs-calculator/make-changes/:calculationId"
                element={
                  <BreadcrumbLayout
                    title={
                      <>
                        <Link to="/tools">Tools</Link>/
                        <span className="text-font-primary">
                          College costs calculator
                        </span>
                      </>
                    }
                    backButton={{ mobile: true, desktop: true }}
                  />
                }
              >
                <Route
                  index
                  element={
                    <CollegeCostsCalculator
                      className="py-32 w-full px-max-screen lg:px-max-screen-lg"
                      makeChanges={true}
                    />
                  }
                />
              </Route>
            </Route>

            <Route path="login" element={<Login />} />
            <Route path="signup" element={<SignUp />} />

            <Route
              path="reminder-scheduled/:reminderDate"
              element={<VulnerabilityReminderScheduled />}
            />
            <Route path="/more-info" element={<IntroFactFindMoreInfo />} />
            <Route
              path="/essential-outgoing"
              element={<MonthlyOutgoingEssentialsExplanation />}
            />
            <Route
              path="/non-essential-outgoing"
              element={<MonthlyOutgoingNonEssentialsExplanation />}
            />
          </>
        )}
        <Route path="*" element={<PageNotFound />} />
      </Routes>

      {/* Modal routes */}
      {state?.backgroundLocation && (
        <Routes>
          <Route
            path="/idle-timeout"
            element={<AuthIdlePrompt idleTimer={idleTimer} />}
          />

          <Route path="/" element={<ModalLayout />}>
            <Route path="more-info" element={<IntroFactFindMoreInfo />} />
            <Route
              path="essential-outgoing"
              element={<MonthlyOutgoingEssentialsExplanation />}
            />
            <Route
              path="non-essential-outgoing"
              element={<MonthlyOutgoingNonEssentialsExplanation />}
            />
            <Route
              path="monthly-take-home"
              element={<MonthlyTakeHomeExplanation />}
            />
            <Route path="life-plan-graph" element={<LifePlanGraphDetails />} />
            <Route path="retirement-graph" element={<EggGraphDetails />} />
            <Route path="other-income" element={<OtherIncomeExplanation />} />
            <Route path="tax-rate" element={<TaxRateExplanation />} />
            <Route
              path="state-pension"
              element={<StatePensionEligibilityExplanation />}
            />
          </Route>

          <Route
            path="/no-cross"
            element={<ModalWithoutCrossLayout maxCardWidth="sm" />}
          >
            <Route
              path="retirement-goals-updated"
              element={<RetirementGoalsUpdated />}
            />
          </Route>

          <Route path="/cross" element={<ModalWithCrossLayout />}>
            <Route
              path="pensions-explanation"
              element={<PensionsExplanation />}
            />
            <Route
              path="employment-triage/:statusString"
              element={<EmploymentStatusTriage />}
            />
            <Route
              path="delete-policy/:policyId"
              element={<ConfirmDeletePolicy />}
            />
            <Route
              path="delete-asset/:assetId"
              element={<ConfirmDeleteAsset />}
            />
            <Route path="delete-debt/:debtId" element={<ConfirmDeleteDebt />} />
            <Route
              path="exit-edit-details"
              element={<ConfirmExitEditDetails />}
            />
            <Route
              path="risk-profile-options"
              element={<RiskProfileOptions />}
            />
            <Route
              path="what-is-a-risk-assessment"
              element={<WhatIsRiskProfile />}
            />
            <Route
              path="confirm-all-products-added"
              element={<ConfirmAddedAllProducts />}
            />
            <Route path="pep" element={<PepExplanation />} />
          </Route>

          <Route
            path="/cross"
            element={<ModalWithCrossLayout maxCardWidth="sm" />}
          >
            <Route path="add-goal-type" element={<AddGoalType />} />
            <Route path="add-goal/:goalType" element={<AddGoalFactFind />} />
            <Route path="edit-goal-name/:goalId" element={<EditGoalName />} />
            <Route path="delete-goal/:goalId" element={<DeleteGoal />} />
            <Route path="change-details" element={<PensionsChangeDetails />} />
          </Route>

          <Route
            path="/cross"
            element={<ModalWithCrossLayout maxCardWidth="md" />}
          >
            <Route path="tools/suggest" element={<SuggestTool />} />

            <Route path="name-custom-goal" element={<NameCustomGoal />} />

            <Route
              path="retirement-goals-updated"
              element={<RetirementGoalsUpdated />}
            />
            <Route
              path="tax-relief-contributions"
              element={<TaxReliefContributions />}
            />
          </Route>

          <Route
            path="/cross"
            element={
              <ModalWithCrossLayout maxCardWidth="md" cardClassName="h-full" />
            }
          >
            <Route
              path="tools/college-costs-calculator/make-changes/:calculationId"
              element={
                <CollegeCostsCalculator
                  className="mt-40 w-full h-full"
                  modal={true}
                  makeChanges={true}
                />
              }
            />
            <Route
              path="tools/college-costs-calculator/start-over"
              element={
                <CollegeCostsCalculator
                  className="mt-40 w-full h-full"
                  modal={true}
                  startOver={true}
                />
              }
            />
            <Route
              path="tools/college-costs-calculator"
              element={
                <CollegeCostsCalculator
                  className="mt-40 w-full h-full"
                  modal={true}
                />
              }
            />
            <Route
              path="tools/college-costs-calculator/results/:calculationId"
              element={
                <CollegeCostsCalculatorResults
                  className="mt-40 h-fit"
                  modal={true}
                />
              }
            />
          </Route>
          <Route path="/logout" element={<Logout />} />
        </Routes>
      )}
    </>
  );
};

function Pages() {
  const analytics = useAnalyticsIfAvailable();
  let location = useLocation();
  const currentPathName = location.pathname;
  const previousPathName = useRef(currentPathName);

  useEffect(() => {
    analytics?.page(currentPathName, {
      url: currentPathName,
      referrer: previousPathName.current,
    });
    previousPathName.current = currentPathName;
  }, [currentPathName, analytics]);

  return (
    <UATBanner>
      <Toasts />
      <AuthenticatedTemplate>
        {!(process.env.REACT_APP_TESTING === "true") && <WebChatButton />}
        <AuthenticatedRoutes />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="*" element={<SingleSignOn />} />
        </Routes>
      </UnauthenticatedTemplate>
    </UATBanner>
  );
}

export default App;
