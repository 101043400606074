import { Spinner } from "@multiply/lib";
import { useResumeFactFindLink } from "../../hooks";
import { Navigate } from "react-router-dom";

const Continue = () => {
  const { loading, link } = useResumeFactFindLink();

  if (loading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return <Navigate to={link} />;
};

export { Continue };
