import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { MarkdownBlock } from "../../graphqlTypes";
import { CollapsibleSection } from "../CollapsibleSection";

type MarkdownSectionProps = {
  contentBlock: MarkdownBlock;
};

const MarkdownSection = ({ contentBlock }: MarkdownSectionProps) => {
  return (
    <CollapsibleSection title={contentBlock.title}>
      <article className="prose prose-brand max-w-full">
        <ReactMarkdown
          children={contentBlock.body ?? ""}
          remarkPlugins={[remarkGfm]}
        />
      </article>
    </CollapsibleSection>
  );
};

export { MarkdownSection };
