import { ContainerMaxSize, Spinner, useAuth, useToaster } from "@multiply/lib";
import { useMemo } from "react";
import {
  useGetConsentQuery,
  useUpdateConsentMutation,
} from "../../graphqlTypes";
import {
  EditContactPreferencesForm,
  EditContactPreferencesFormValues,
} from "./EditContactPreferencesForm";
import debounce from "lodash/debounce";

const EditContactPreferences = () => {
  const { userId, loggedIn } = useAuth();
  const { addToast } = useToaster();

  const [consentResult] = useGetConsentQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const [updateConsentResult, updateConsent] = useUpdateConsentMutation();

  const handleSwitch = useMemo(
    () =>
      debounce(async (values: EditContactPreferencesFormValues) => {
        await await updateConsent({
          input: {
            userId: userId ?? "",
            consent: values,
          },
        });
        addToast({ message: "Contact preferences updated" });
      }, 2000),
    [addToast, updateConsent, userId]
  );

  const { contactServicesIrishLifeGroup, userResearch, checkInEmails } =
    consentResult.data?.consent ?? {};

  const defaultValues: EditContactPreferencesFormValues = {
    contactServicesIrishLifeGroup: contactServicesIrishLifeGroup ?? false,
    userResearch: userResearch ?? false,
    checkInEmails: checkInEmails ?? false,
  };

  return (
    <ContainerMaxSize>
      <section className="max-w-full sm:max-w-800">
        <h1 className="text-t21 text-font-primary font-semibold pb-24">
          Contact preferences
        </h1>
        <div className="w-full flex flex-col justify-center gap-y-20">
          {consentResult.fetching ? (
            <Spinner />
          ) : (
            <EditContactPreferencesForm
              defaultValues={defaultValues}
              onChange={handleSwitch}
              loading={updateConsentResult.fetching}
            />
          )}
        </div>
      </section>
    </ContainerMaxSize>
  );
};
export { EditContactPreferences };
