import { Card, ContainerSmallSize, Spinner, useAuth } from "@multiply/lib";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SpotlightCard } from "../../components";
import { InteractionFlags } from "../../globalTypes";
import { useRequestPlanMutation } from "../../graphqlTypes";
import { useInteractionFlag } from "../../hooks";

const GenerateLifePlan = () => {
  const { userId } = useAuth();
  const navigate = useNavigate();
  const [requestPlanResult, requestPlan] = useRequestPlanMutation();
  const [loading, setLoading] = useState(false);

  const completedGenerateLifePlan = useInteractionFlag(
    InteractionFlags.CompletedGenerateLifePlan
  );

  const error =
    requestPlanResult?.error ??
    requestPlanResult?.data?.requestPlan?.error ??
    completedGenerateLifePlan.error;

  const onClickGenerate = async () => {
    setLoading(true);

    try {
      const request = requestPlan({ input: { userId: userId ?? "" } });

      // wait at least 4 seconds
      await Promise.all([
        request,
        new Promise<void>((resolve) => {
          setTimeout(() => resolve(), 4000);
        }),
      ]);

      const result = await request;

      if (result.data?.requestPlan?.success) {
        if (
          !completedGenerateLifePlan.loading &&
          !completedGenerateLifePlan.value
        ) {
          const generateLifePlanResult = await completedGenerateLifePlan.update(
            true
          );
          if (generateLifePlanResult?.data?.updateInteractionFlag.success) {
            navigate("/advice/plan");
          }
        } else {
          navigate("/advice/plan");
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <ContainerSmallSize>
      <div className="mt-36">
        {loading ? (
          <Card className="py-80 px-60 text-center">
            <h1 className="text-brand-primary text-t27 mb-24">
              Generating your Financial Plan
            </h1>

            <p className="text-font-secondary text-t16 sm:text-t20 mb-48">
              This may take a few moments
            </p>

            <Spinner data-testid="spinner" />
          </Card>
        ) : (
          <SpotlightCard
            title="Your Financial Plan is ready to be generated"
            subtitle={
              <>
                If you've got nothing else to edit, it's time to see your
                recommendations
                <Link
                  to="/settings/personal-details"
                  className="block text-font-links mt-36"
                >
                  Edit profile
                </Link>
                <Link to="/products" className="block text-font-links mt-18">
                  Edit products
                </Link>
              </>
            }
            onClick={onClickGenerate}
            error={error?.message}
            disabled={completedGenerateLifePlan.loading}
            cta="Continue"
            tip="Remember: you can always edit your goals or change information later"
          />
        )}
      </div>
    </ContainerSmallSize>
  );
};

export { GenerateLifePlan };
