import { useContext } from "react";

import { ToasterContext } from "./ToasterContext";

const useToaster = () => {
  const context = useContext(ToasterContext);

  if (context === undefined) {
    throw new Error("useToaster must be used within a ToasterContextProvider");
  }

  return context;
};

export { useToaster };
