import classNames from "classnames";
import { VictoryStack, VictoryBar } from "victory";
import { BreakdownSlice } from "../../graphqlTypes";
import { useWindowDimensions } from "@multiply/lib";

type DescriptionProps = {
  title: string;
  amount: string;
  index: number;
};
const Description = ({ title, amount, index }: DescriptionProps) => {
  return (
    <div className="flex gap-8">
      <div
        className={classNames("w-12 h-12 sm:w-24 sm:h-24 pr-4", {
          "bg-brand-tertiary": index === 0,
          "bg-brand-secondary": index === 1,
          "bg-brand-primary": index === 2,
        })}
      />
      <div>
        <p className="text-font-primary text-t10 sm:text-t21">
          {title} Pension
        </p>
        <p className="text-font-secondary text-t10 sm:text-t21">
          {amount} per month
        </p>
      </div>
    </div>
  );
};

type EggChartProps = {
  slices: BreakdownSlice[];
};

const EggChart = ({ slices }: EggChartProps) => {
  const { isPhone } = useWindowDimensions();
  const newSlicesOrder = [...slices].reverse();

  return (
    <section className="flex sm:h-288 h-216 items-center justify-center gap-32 w-full">
      <div className="space-y-16">
        {newSlicesOrder.map((slice, i) => (
          <Description
            index={i}
            key={slice.name}
            title={slice.name}
            amount={slice.amount.display}
          />
        ))}
      </div>
      <div className="max-w-120 sm:max-w-none">
        <svg
          viewBox="0 0 228 274"
          style={{ width: "100%", height: isPhone ? "216" : "288" }}
        >
          <defs>
            <mask id="eggmask">
              <rect width="100%" height="100%" fill="#000" />
              <path
                d="M9.9662e-06 157.448C1.74017e-05 70.4917 51.0396 -1.58167e-05 114 -1.01893e-05C176.96 -4.56191e-06 228 70.4918 228 157.448C228 244.404 176.96 274 114 274C51.0395 274 2.53073e-06 244.404 9.9662e-06 157.448Z"
                fill="#FFF"
              />
            </mask>
          </defs>
          <g mask="url(#eggmask)">
            <VictoryStack
              standalone={false}
              padding={{ top: 0, bottom: 0, right: 0, left: 0 }}
              colorScale={["#5261AC", "#B1BCF6", "#DCE2FF", "#F1F3FF"]}
              width={228}
              height={274}
            >
              {slices.map((portion) => (
                <VictoryBar
                  key={portion.name}
                  barWidth={228}
                  data={[{ x: "a", y: portion.amount.float }]}
                />
              ))}
            </VictoryStack>
          </g>
        </svg>
      </div>
    </section>
  );
};

export { EggChart };
