import classNames from "classnames";
import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import {
  IconProps,
  getColorFromVariant,
  getDimensionsFromSize,
} from "./shared";

const WalletCircleIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);
  return (
    <svg
      data-testid="wallet icon"
      aria-hidden={true}
      width={width}
      height={height}
      className={classNames(defaultClassnames, className)}
      viewBox="0 0 81 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="40.676" cy="41.2101" r="34.2385" fill={fill} />
      <path
        opacity="0.2"
        d="M56.7267 33.6689V53.5657C56.7267 53.9426 56.5769 54.3041 56.3104 54.5706C56.0439 54.8371 55.6824 54.9869 55.3055 54.9869H26.8815C26.1276 54.9869 25.4046 54.6874 24.8716 54.1544C24.3385 53.6213 24.0391 52.8983 24.0391 52.1445V29.4053C24.0391 30.1591 24.3385 30.8821 24.8716 31.4152C25.4046 31.9482 26.1276 32.2477 26.8815 32.2477H55.3055C55.6824 32.2477 56.0439 32.3974 56.3104 32.6639C56.5769 32.9305 56.7267 33.2919 56.7267 33.6689Z"
        fill="#717FE2"
      />
      <path
        d="M55.3048 30.8225H26.8808C26.5039 30.8225 26.1424 30.6728 25.8758 30.4062C25.6093 30.1397 25.4596 29.7782 25.4596 29.4013C25.4596 29.0244 25.6093 28.6629 25.8758 28.3964C26.1424 28.1298 26.5039 27.9801 26.8808 27.9801H51.0412C51.4181 27.9801 51.7796 27.8304 52.0461 27.5638C52.3127 27.2973 52.4624 26.9358 52.4624 26.5589C52.4624 26.182 52.3127 25.8205 52.0461 25.554C51.7796 25.2874 51.4181 25.1377 51.0412 25.1377H26.8808C25.75 25.1377 24.6655 25.5869 23.866 26.3865C23.0664 27.1861 22.6172 28.2705 22.6172 29.4013V52.1405C22.6172 53.2713 23.0664 54.3557 23.866 55.1553C24.6655 55.9549 25.75 56.4041 26.8808 56.4041H55.3048C56.0586 56.4041 56.7816 56.1046 57.3147 55.5716C57.8477 55.0385 58.1472 54.3156 58.1472 53.5617V33.6649C58.1472 32.911 57.8477 32.1881 57.3147 31.655C56.7816 31.122 56.0586 30.8225 55.3048 30.8225ZM55.3048 53.5617H26.8808C26.5039 53.5617 26.1424 53.412 25.8758 53.1454C25.6093 52.8789 25.4596 52.5174 25.4596 52.1405V33.4215C25.9159 33.5833 26.3966 33.6656 26.8808 33.6649H55.3048V53.5617ZM46.7776 42.9027C46.7776 42.4811 46.9026 42.0689 47.1369 41.7183C47.3711 41.3678 47.704 41.0945 48.0936 40.9332C48.4831 40.7718 48.9118 40.7296 49.3253 40.8119C49.7388 40.8941 50.1187 41.0972 50.4168 41.3953C50.7149 41.6934 50.918 42.0733 51.0002 42.4868C51.0825 42.9003 51.0403 43.329 50.8789 43.7185C50.7176 44.108 50.4443 44.441 50.0938 44.6752C49.7432 44.9095 49.331 45.0345 48.9094 45.0345C48.344 45.0345 47.8018 44.8099 47.402 44.4101C47.0022 44.0103 46.7776 43.4681 46.7776 42.9027Z"
        fill="#717FE2"
      />
      <circle
        cx="40.4275"
        cy="40.9617"
        r="38.9554"
        stroke="#E1E5FF"
        strokeWidth="2.58488"
      />
    </svg>
  );
};

export { WalletCircleIcon };
