import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export type IntroAddressFormValues = {
  line1: string | null;
  line2: string | null;
  line3: string | null;
  county: string | null;
  country: string | null;
};

export const defaultIntroAddressValidationSchema: yup.SchemaOf<IntroAddressFormValues> =
  yup
    .object()
    .shape({
      line1: yup.string().required("Please provide an address").nullable(),
      line2: yup.string().nullable(),
      line3: yup.string().nullable(),
      county: yup.string().required("Please provide a county").nullable(),
      country: yup
        .string()
        .oneOf(
          ["ireland", "Ireland", "Republic of Ireland", "republic of ireland"],
          "This app is only available to residents of the Republic of Ireland"
        )
        .required("Please provide a country")
        .nullable(),
    })
    .defined();

type UseIntroAddressFormArgs = {
  defaultValues?: Partial<IntroAddressFormValues>;
  validationSchema?: yup.SchemaOf<IntroAddressFormValues>;
};

const useIntroAddressForm = (args?: UseIntroAddressFormArgs) => {
  const defaultValues = {
    line1: "",
    line2: "",
    line3: "",
    county: "",
    country: "Ireland",
    ...(args?.defaultValues ?? {}),
  };
  const validationSchema =
    args?.validationSchema ?? defaultIntroAddressValidationSchema;

  const form = useForm<IntroAddressFormValues>({
    defaultValues: {
      line1: defaultValues?.line1,
      line2: defaultValues?.line2,
      line3: defaultValues?.line3,
      county: defaultValues?.county,
      country: defaultValues?.country,
    },
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  return form;
};

export { useIntroAddressForm };
