import classNames from "classnames";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Card } from "../Card";

type RecommendationTileProps = {
  explanation: string;
  srcset: string;
  sizes: string;
  src: string;
  className?: string;
};

const RecommendationTile = ({
  explanation,
  srcset,
  sizes,
  src,
  className,
}: RecommendationTileProps) => {
  return (
    <Card className={classNames("flex flex-col sm:flex-row h-full", className)}>
      <div className="relative w-full sm:w-1/3 max-h-160 sm:max-h-full sm:order-last">
        <img
          srcSet={srcset ?? ""}
          sizes={sizes ?? ""}
          src={src ?? ""}
          alt=""
          className="rounded-t-lg sm:rounded-r-lg sm:rounded-l-none max-h-160 sm:max-h-full w-full h-full object-cover object-right"
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          style={{ width: "33%", height: "100%" }}
          className="absolute top-0 -left-1 opacity-0 sm:opacity-100"
        >
          <polygon stroke="white" fill="white" points="0,0 100,0 0,100" />
        </svg>
      </div>
      <div className="w-full sm:w-2/3 py-20 px-18 sm:pl-32 sm:pr-4 overflow-auto overscroll-y-auto">
        <article className="prose prose-headings:mt-12 prose-headings:mb-2 prose-headings:lg:my-12 prose-headings:text-font-primary prose-headings:font-semibold prose-headings:text-t18 prose-headings:sm:text-t21 prose-ul:text-font-secondary prose-ul:font-normal prose-ul:text-t16 prose-ul:sm:text-t18 prose-ul:pt-6 prose-ul:pb-12 prose-li:my-2 prose-p:my-2 prose-brand">
          <ReactMarkdown
            children={explanation ?? ""}
            remarkPlugins={[remarkGfm]}
          />
        </article>
      </div>
    </Card>
  );
};

export { RecommendationTile };
