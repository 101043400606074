import { Button, useAuth, useWindowDimensions } from "@multiply/lib";
import { format } from "date-fns";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConversationCardTemplate } from "../../components/ConversationCardTemplate";
import { InteractionFlags, UserJourneyTypes } from "../../globalTypes";
import { useDeleteVulnerabilityReminderMutation } from "../../graphqlTypes";
import { useGetUserJourney, useInteractionFlag } from "../../hooks";
import { Loading } from "../Loading";

type ReminderVulnerabilityCheckProps = {
  reminderDate: any | null;
};

const ReminderVulnerabilityCheck = ({
  reminderDate,
}: ReminderVulnerabilityCheckProps) => {
  const { userId } = useAuth();
  const { isPhone } = useWindowDimensions();

  const navigate = useNavigate();

  const userJourney = useGetUserJourney();

  const [deleteReminderResult, deleteReminder] =
    useDeleteVulnerabilityReminderMutation();

  const [loading, setLoading] = useState(false);

  const completedFactFindIntro = useInteractionFlag(
    InteractionFlags.CompletedFactFindIntro
  );
  const completedPensionsVulnerability = useInteractionFlag(
    InteractionFlags.CompletedPensionsVulnerability
  );

  const handleContinue = async () => {
    setLoading(true);

    try {
      const result = await deleteReminder({ input: { userId: userId ?? "" } });
      if (result.data?.deleteVulnerabilityReminder?.success) {
        if (userJourney.value === UserJourneyTypes.FocusedPension) {
          if (!completedPensionsVulnerability.value)
            await completedPensionsVulnerability.update(true);
        } else {
          if (!completedFactFindIntro.value) {
            await completedFactFindIntro.update(true);
          }
        }
      }
      navigate(userJourney.planRoute);
    } finally {
      setLoading(false);
    }
  };

  const error =
    deleteReminderResult?.error ??
    deleteReminderResult?.data?.deleteVulnerabilityReminder.error;

  const formattedReminderDate =
    format(new Date(reminderDate), "dd/MM/yyyy") || "";

  return (
    <>
      {userJourney?.loading ||
      completedFactFindIntro.loading ||
      completedPensionsVulnerability.loading ? (
        <Loading />
      ) : (
        <ConversationCardTemplate
          title="Are you ready to continue?"
          subtitle={
            <>
              <p>
                You previously told us it wasn't the right time to create your
                plan.
              </p>{" "}
              <br />
              <p>{`If you're now ready, cancel the reminder scheduled for ${formattedReminderDate} and continue.`}</p>
              <br />
              <p>
                If you're still unsure, you should speak to one of our advisers.
              </p>
            </>
          }
          className="mb-40 h-auto sm:p-56"
        >
          <fieldset className="flex flex-col items-end gap-16 w-full space-x-8 mt-80">
            <Button
              className="max-w-fit whitespace-normal h-fit px-40 text-right sm:text-center"
              variant="ghost"
              disabled={loading}
              onClick={handleContinue}
            >
              Continue, and cancel {!isPhone ? "email " : ""}reminder
            </Button>
            <Button
              className="max-w-fit whitespace-normal h-fit px-40 text-right sm:text-center"
              variant="ghost"
              disabled={loading}
              onClick={() => {
                navigate(userJourney.callRoute);
              }}
            >
              Book a call with an adviser
            </Button>
            <Button
              className="max-w-fit whitespace-normal h-fit px-40 text-right sm:text-center"
              disabled={loading}
              variant="ghost"
              onClick={() => {
                navigate(userJourney.defaultRoute);
              }}
            >
              Go back
            </Button>
          </fieldset>

          {error && (
            <p className="text-t16 text-action-error mt-12">error.message</p>
          )}
        </ConversationCardTemplate>
      )}
    </>
  );
};

export { ReminderVulnerabilityCheck };
