import {
  Card,
  Spinner,
  useAuth,
  useToaster,
  ContainerMidSize,
} from "@multiply/lib";
import { useNavigate, useParams } from "react-router-dom";
import { ModalNavLink } from "../../components";
import { PolicyFieldName } from "../../globalTypes";
import {
  PolicyType,
  useGetPolicyQuery,
  useUpdatePolicyMutation,
} from "../../graphqlTypes";
import { PolicyForm } from "./PolicyForm";
import { parseProductDatesToISO } from "../../utils";

const UpdatePolicy = () => {
  const { policyId } = useParams();
  const { userId } = useAuth();
  const { addToast } = useToaster();
  const navigate = useNavigate();

  const [getPolicyResult] = useGetPolicyQuery({
    variables: {
      userId: userId ?? "",
      policyId,
    },
  });

  const policy = getPolicyResult?.data?.policy;

  const disclaimer =
    policy?.productType === PolicyType.HealthInsurance
      ? "Please include your renewal date if you would like to be contacted by Irish Life Health closer to that date about their range of health insurance options"
      : null;

  const [updatePolicyResult, updatePolicy] = useUpdatePolicyMutation();

  const handleUpdatePolicy = async (values: any) => {
    const { provider, ...otherValues } = values;
    const newPolicy = {
      productType: policy?.productType,
      ...otherValues,
      providerName: values.provider,
      ...parseProductDatesToISO(otherValues),
    };

    try {
      const result = await updatePolicy({
        input: {
          userId: userId ?? "",
          policyId,
          policy: newPolicy,
        },
      });
      if (result.data?.updatePolicy?.success) {
        navigate("/products");
      }
    } catch (error) {}
    addToast({ message: "Success" });
  };

  const updateMutationError =
    updatePolicyResult?.error || updatePolicyResult?.data?.updatePolicy?.error;

  const defaultValues: { [fieldName in PolicyFieldName]: any } = {
    owners: (policy?.owners ?? []).map((person) => person?.id ?? ""),
    provider: policy?.provider?.name ?? null,
    renewalDate: policy?.renewalDate ?? null,
    isOnePlan: policy?.isOnePlan ?? null,
    amountOfCover: policy?.amountOfCover?.float ?? null,
    yearsRemaining: policy?.yearsRemaining ?? null,
    premium: policy?.premium?.float ?? null,
    name: policy?.name ?? null,
    paymentFrequency: policy?.paymentFrequency ?? null,
    isIrishLifePolicy: policy?.isIrishLifePolicy ?? null,
    throughCurrentEmployer: policy?.throughCurrentEmployer ?? null,
    coverType: policy?.coverType ?? null,
  };

  return (
    <ContainerMidSize>
      <div className="flex-1 flex flex-col items-center justify-center">
        <Card className="p-32 pb-18 w-full flex flex-col items-center">
          {getPolicyResult.fetching ? (
            <Spinner />
          ) : !policy ? (
            <p className="text-t21">Policy not found</p>
          ) : (
            <>
              <PolicyForm
                policy={policy}
                onSubmit={handleUpdatePolicy}
                defaultValues={defaultValues}
              />

              {policyId && (
                <ModalNavLink
                  to={`/cross/delete-policy/${policyId}`}
                  className="w-240 text-action-error text-center py-18 mt-8"
                >
                  Delete
                </ModalNavLink>
              )}
            </>
          )}
          <p className="text-font-secondary self-start">{disclaimer}</p>
          {updateMutationError && (
            <p className="mt-12 text-action-error text-t16">
              {updateMutationError.message}
            </p>
          )}
        </Card>
      </div>{" "}
    </ContainerMidSize>
  );
};

export { UpdatePolicy };
