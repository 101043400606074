import isEqual from "lodash/isEqual";
import { Dropdown } from "@multiply/lib";
import { AccommodationType, WhatToIncludeType } from "../../graphqlTypes";

type InputWhatToIncludeTypeProps = {
  value: (WhatToIncludeType | null)[] | null | undefined;
  onChange: (value: WhatToIncludeType[] | null) => void;
  onBlur: () => void;
  error?: string;
  className?: string;
  homeAccommodation: boolean | null | undefined;
};

const InputCostsInclude = ({
  value,
  onChange,
  onBlur,
  error,
  className,
  homeAccommodation,
}: InputWhatToIncludeTypeProps) => {
  let items = [
    { value: [WhatToIncludeType.Fees], label: "Fees only" },
    {
      value: [WhatToIncludeType.Fees, WhatToIncludeType.Accommodation],
      label: "Fees & accommodation",
    },
    {
      value: [
        WhatToIncludeType.Fees,
        WhatToIncludeType.Accommodation,
        WhatToIncludeType.OtherExpenses,
      ],
      label: "Fees, accommodation & other expenses",
    },
  ];

  if (homeAccommodation) {
    items = [
      { value: [WhatToIncludeType.Fees], label: "Fees only" },
      {
        value: [WhatToIncludeType.Fees, WhatToIncludeType.OtherExpenses],
        label: "Fees & other expenses",
      },
    ];
  }

  const selectedValue =
    items.find((item) => isEqual(item.value, value)) ?? null;

  return (
    <Dropdown
      error={error}
      label="What do you want to include?"
      items={items}
      placeholder="Select an option"
      value={selectedValue ?? undefined}
      onChange={(item) => {
        onChange(item?.value ?? null);
      }}
      onBlur={onBlur}
      className={className}
    />
  );
};

export { InputCostsInclude };
