import classNames from "classnames";

type DotPaginationProps = {
  currentPage: number;
  totalPages: number;
  setPage: (pageNumber: number) => void;
  className?: string;
};

const DotPagination = ({
  currentPage,
  totalPages,
  setPage,
  className,
}: DotPaginationProps) => {
  let dots: boolean[] = [];
  for (let page = 0; page < totalPages; page++) {
    dots.push(page === currentPage ? true : false);
  }

  return (
    <div
      className={classNames(
        "flex justify-center items-start h-full gap-16",
        className
      )}
    >
      {dots.map((dot, index) => (
        <div
          key={`dot${index}`}
          className={`w-16 h-16 rounded-full ring-2 ring-brand-primary ${
            dot ? "bg-brand-primary" : ""
          }`}
          onClick={() => setPage(index >= totalPages ? 0 : index)}
        ></div>
      ))}
    </div>
  );
};

export { DotPagination };
