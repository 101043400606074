import { Outlet } from "react-router-dom";

const GoalsWizardLayout = () => {
  return (
    <div className="w-full h-full flex flex-col items-center pt-12 sm:pt-56">
      <Outlet />
    </div>
  );
};

export { GoalsWizardLayout };
