import { Spinner, useAuth } from "@multiply/lib";
import { useNavigate } from "react-router-dom";
import {
  Relationship,
  useGetPersonQuery,
  useUpdatePersonMutation,
} from "../../graphqlTypes";
import { getIntroPathFromPerson } from "../../utils";
import { IntroFactfindAddressForm } from "./IntroFactfindAddressForm";
import { IntroAddressFormValues } from "./useIntroAddressForm";

function IntroFactFindAddress() {
  const navigate = useNavigate();

  const { userId } = useAuth();

  const [personResult] = useGetPersonQuery({
    variables: { userId: userId ?? "" },
    pause: !userId,
  });
  const person = personResult.data?.person;
  const personId = person?.id;
  const defaultValues = {
    line1: person?.address?.buildingName ?? null,
    line2: person?.address?.street ?? null,
    line3: person?.address?.town ?? null,
    county: person?.address?.county ?? null,
    country: person?.address?.country ?? "Ireland",
  };

  const [updatePersonResult, updatePerson] = useUpdatePersonMutation();

  const updatePersonError =
    updatePersonResult.data?.updatePerson?.error ?? updatePersonResult.error;

  const onSubmit = async (formValues: IntroAddressFormValues) => {
    try {
      const result = await updatePerson({
        input: {
          userId: userId ?? "",
          personId,
          person: {
            relationship: Relationship.User,
            address: {
              buildingName: formValues.line1,
              street: formValues.line2,
              town: formValues.line3,
              county: formValues.county,
              country: formValues.country,
            },
          },
        },
      });

      if (result?.data?.updatePerson?.success) {
        if (result?.data?.updatePerson?.success) {
          const nextPath = getIntroPathFromPerson(
            result.data?.updatePerson.person
          );

          if (nextPath) {
            navigate(nextPath);
          }
        }
      }
    } catch (error) {}
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-col justify-between">
        <section className="text-center">
          <h1 className="text-t21 sm:text-t27 text-font-primary pb-16">
            Next, your address
          </h1>
          <p className="text-t12 sm:text-t16 text-font-secondary pb-48 sm:pb-36">
            To create your account and provide financial advice, we need some
            basic information.
          </p>
        </section>

        {personResult.fetching ? (
          <div className="flex flex-col items-center">
            <Spinner />
          </div>
        ) : (
          <IntroFactfindAddressForm
            defaultValues={defaultValues}
            error={updatePersonError?.message}
            onSubmit={onSubmit}
          />
        )}
      </div>
    </div>
  );
}

export { IntroFactFindAddress };
