import { FactfindFieldValue } from "../../utils";

type EditFieldProps = {
  label: string;
  value: FactfindFieldValue;
  fieldName: string;
};

const EditField = ({ label, value, fieldName }: EditFieldProps) => {
  const convertEnumToLowercaseString = (enumString: string) => {
    return enumString.split("_").join(" ").toLowerCase();
  };

  return (
    <div className="max-w-320">
        <p className="text-font-primary font-semibold pb-8">{label}</p>
        {typeof value === "string" ? (
          <p className="text-t16 text-brand-secondary capitalize">
            {convertEnumToLowercaseString(value) ?? "Not added"}
          </p>
        ) : (
          <p className="text-t16 text-brand-secondary">
            {value ?? "Not added"}
          </p>
        )}
    </div>
  );
};

export { EditField };
