import { useFlags } from "flagsmith/react";
import {
  ContainerMidSize,
  RecommendationTile,
  Spinner,
  useAuth,
} from "@multiply/lib";
import {
  BookAdviserCall,
  PlanStatusCard,
  RecommendationCarousel,
  YourProducts,
  YouToldUs,
} from "../../components";
import {
  AuthorisationStatus,
  ProtectionSummary,
  RecommendationType,
  useGetPersonQuery,
  useGetPlanRecommendationsQuery,
} from "../../graphqlTypes";
import { ProtectionScenarios } from "./ProtectionScenarios";

const Protection = () => {
  const { userId, loggedIn } = useAuth();
  const [planResult] = useGetPlanRecommendationsQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const [personResult] = useGetPersonQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const name = personResult?.data?.person?.firstName ?? null;

  const recommendationGroups =
    planResult?.data?.plan?.recommendationGroups ?? [];

  const protectionRecommendation = recommendationGroups.find(
    (group) => group.recommendationType === RecommendationType.Protection
  );
  const summary = protectionRecommendation?.summary as ProtectionSummary;

  const notApproved =
    planResult?.data?.plan?.authorisationStatus !==
    AuthorisationStatus.AdviceApproved;

  const errorGenerated = planResult?.error && personResult?.error;

  const flags = useFlags(["context_protection"]);
  const showContext =
    flags.context_protection.enabled &&
    flags.context_protection.value === "show";

  return (
    <div className="w-full flex justify-center">
      {planResult.fetching ? (
        <Spinner className="w-60 h-60 my-32" />
      ) : errorGenerated || notApproved ? (
        <PlanStatusCard
          planResult={planResult.data?.plan}
          error={planResult?.error?.message || null}
        />
      ) : (
        <ContainerMidSize className="flex flex-col">
          <h1 className="text-t21 sm:text-t34 text-font-primary mb-4 sm:mb-6 font-semibold">
            Protection
          </h1>

          {!showContext && (
            <p className="text-t16 sm:text-t21 font-normal text-font-secondary mb-20 sm:mb-18">
              Protect your loved ones in case the worst happens
            </p>
          )}

          <div className=" space-y-24 lg:space-y-60">
            {showContext && (
              <section className="mt-20">
                {planResult.fetching ? (
                  <div className="w-full h-full flex justify-center items-center">
                    <Spinner />
                  </div>
                ) : protectionRecommendation?.explanation ? (
                  <RecommendationTile
                    explanation={protectionRecommendation?.explanation ?? ""}
                    src={protectionRecommendation?.responsiveImage?.src ?? ""}
                    srcset={
                      protectionRecommendation?.responsiveImage?.srcset ?? ""
                    }
                    sizes={
                      protectionRecommendation?.responsiveImage?.sizes ?? ""
                    }
                  />
                ) : null}
              </section>
            )}

            <section>
              <h1 className="sm:text-t27 text-font-primary my-32">
                {`${name ? name + ", y" : "Y"}ou told us`}
              </h1>

              <div className="sm:flex space-y-40 sm:space-y-0 items-stretch gap-x-48">
                <YouToldUs
                  className="flex-1"
                  text={protectionRecommendation?.factfindSummary}
                />

                <YourProducts
                  productSummary={protectionRecommendation?.relatedProducts}
                  productType="Protection"
                />
              </div>
            </section>

            <section>
              <ProtectionScenarios scenarios={summary?.scenarios} />
            </section>

            <section className="space-y-32">
              {protectionRecommendation?.recommendations && (
                <RecommendationCarousel
                  recommendations={protectionRecommendation?.recommendations}
                  title="But don't worry, there are things you can do"
                />
              )}
            </section>

            <section>
              <BookAdviserCall hasPlan={true} />
            </section>
          </div>
        </ContainerMidSize>
      )}
    </div>
  );
};

export { Protection };
