import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";
import {
  getColorFromVariant,
  getDimensionsFromSize,
  IconProps,
} from "./shared";

const SproutIcon: FC<IconProps> = ({ size, variant, className, ...props }) => {
  const { width, height } = getDimensionsFromSize(size);
  const color = getColorFromVariant(variant);
  return (
    <svg
      aria-hidden={true}
      data-testid="sprout icon"
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.842 10.997C1.237 12.017.667 4.951.667 4.951c4.524-.699 6.873 2 7.252 4.39"
        stroke={color}
        strokeWidth={0.75}
      />
      <path
        d="M7.929 14.513V7.887S7.233 1 15.089 1c0 0 .786 7.724-6.982 7.724M14.835 1.251l-6.598 7.28M.667 4.951l7.19 5.589"
        stroke={color}
        strokeWidth={0.75}
      />
    </svg>
  );
};

export { SproutIcon };
