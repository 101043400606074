import classNames from "classnames";

type ThingsToDoPillProps = {
  count: number;
};

const ThingsToDoPill = ({ count }: ThingsToDoPillProps) => {
  return (
    <div
      className={classNames(
        "rounded-full py-4 sm:py-8 px-8 sm:px-12 inline-block",
        "font-bold text-t10 sm:text-t12 tracking-wider",
        {
          "text-todos-multiple bg-todos-multiple/[.1]": count > 0,
          "text-todos-none-label bg-todos-none": count <= 0,
        }
      )}
    >
      {count} {count === 1 ? "THING" : "THINGS"} TO DO
    </div>
  );
};

export { ThingsToDoPill };
