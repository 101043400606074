import { Button, Card } from "@multiply/lib";
import { useNavigate } from "react-router-dom";
import holistic from "../../assets/images/holistic-plan.png";
import pension from "../../assets/images/pension-plan.png";
import { IrishLifeLogoLeftCorner } from "../../components";
import { motion } from "framer-motion";

const PickAPlan = () => {
  const navigate = useNavigate();

  const variants = {
    hidden: { opacity: 0, x: 1000, y: 0 },
    enter: { opacity: 1, x: 0, y: 0 },
  };

  return (
    <motion.main
      variants={variants}
      initial="hidden"
      animate="enter"
      transition={{ type: "linear" }}
      className="min-h-screen"
    >
      <div className="bg-white">
        <IrishLifeLogoLeftCorner className="max-w-120 h-fit md:max-w-fit" />
      </div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 3 }}
        className="flex flex-col justify-center items-center px-24"
      >
        <h1 className="text-font-primary font-bold text-t23 md:text-t32 pb-22 pt-34">
          Pick a plan to get started
        </h1>
        <section className="flex flex-col md:flex-row gap-20 md:gap-28">
          <Card className="md:w-[60%]">
            <div className="max-w-460 mb-20">
              <img
                src={holistic}
                aria-label="holistic plan"
                alt="holistic plan"
                height="auto"
                className="rounded-t-lg"
              />
            </div>
            <div className="px-24 pb-36 pt-24">
              <p className="text-font-primary font-bold text-t21 md:text-t23 pb-6">
                Get a full financial review
              </p>
              <p className="text-font-primary text-t16 md:text-t18 pb-18">
                Make sure you’re doing everything you should be
              </p>
              <Button
                size="sm"
                className="text-center text-t16 md:text-t21"
                variant="primary"
                onClick={() => navigate("/pick-holistic")}
              >
                Find out more
              </Button>
            </div>
          </Card>
          <Card className="md:w-[60%] mb-65 md:mb-0">
            <div className="max-w-460 mb-20">
              <img
                src={pension}
                aria-label="pension plan"
                alt="pension plan"
                height="auto"
                className="rounded-t-lg"
              />
            </div>
            <div className="px-24 pb-36 pt-24">
              <p className="text-font-primary font-bold text-t21 md:text-t23 pb-6">
                Get your pension sorted
              </p>
              <p className="text-font-primary text-t16 md:text-t18 pb-18">
                Make your dream retirement happen
              </p>
              <Button
                size="sm"
                className="text-center text-t16 md:text-t21"
                variant="primary"
                onClick={() => navigate("/pick-pension")}
              >
                Find out more
              </Button>
            </div>
          </Card>
        </section>
      </motion.div>
    </motion.main>
  );
};

export { PickAPlan };
