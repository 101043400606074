import classNames from "classnames";
import { SVGProps, FC } from "react";
import { defaultClassnames } from "./defaultClassNames";

const DragHandle: FC<SVGProps<SVGSVGElement>> = ({ className, ...props }) => {
  return (
    <svg
      aria-hidden={!props["aria-label"]}
      width={13}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={classNames(defaultClassnames, className)}
    >
      <circle cx={2.5} cy={2.5} r={2.5} fill="#EEE" />
      <circle cx={2.5} cy={12.5} r={2.5} fill="#EEE" />
      <circle cx={2.5} cy={22.5} r={2.5} fill="#EEE" />
      <circle cx={10.5} cy={2.5} r={2.5} fill="#EEE" />
      <circle cx={10.5} cy={12.5} r={2.5} fill="#EEE" />
      <circle cx={10.5} cy={22.5} r={2.5} fill="#EEE" />
    </svg>
  );
};

export { DragHandle };
