import { useAuth } from "@multiply/lib";
import { PensionsReview, PensionsReviewCardProps } from "../../components";
import { useGetPrsaPensionApplicationQuery } from "../../graphqlTypes";

const PensionsReviewContributionsDetails = () => {
  const { userId, loggedIn } = useAuth();

  const [factFindResult] = useGetPrsaPensionApplicationQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const {
    pensionSelfContribution,
    pensionContributionStartDate
,
    pensionInflationProtection,
    singleEmployeeContribution,
  } = factFindResult?.data?.prsaPensionApplication ?? {};

  const loading = factFindResult?.fetching;


  const reviewData: PensionsReviewCardProps[] = [
    {
      label: "Monthly contribution",
      fieldName: "pension_self_contribution",
      value: pensionSelfContribution ? pensionSelfContribution.display : null,
    },
    {
      label: "Date starting",
      fieldName: "pension_day_of_direct_debits",
      value: pensionContributionStartDate
      ,
    },
    {
      label: "Inflation protection",
      fieldName: "pension_inflation_protection",
      value: pensionInflationProtection === true ? "Yes" : pensionInflationProtection === false ? "No" : null,
    },
    {
      label: "One-off contribution",
      fieldName: "single_employer_contribution",
      value: singleEmployeeContribution ? singleEmployeeContribution.display : null,
    },
  ];

  return (
    <PensionsReview
      title="Please review and confirm your contribution details"
      subtitle="Contribution and portfolio details"
      loading={loading}
      reviewData={reviewData}
      onDonePath="/pensions/direct-debit"
      isPrsaFactfind={true}
      backButton={true}
    />
  );
};

export { PensionsReviewContributionsDetails };
