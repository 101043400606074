import { useAuth, useReferral } from "@multiply/lib";
import { useEffect } from "react";
import type { CombinedError } from "urql";
import {
  ErrorType,
  useGetUserQuery,
  useSignUpMutation,
  useSyncUserMutation,
} from "../../graphqlTypes";

const useCreateUserFromAuth = () => {
  const { userId, username, loggedIn } = useAuth();

  const [userResult] = useGetUserQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });
  const [signUpResult, signUp] = useSignUpMutation();
  const [syncUserResult, syncUser] = useSyncUserMutation();

  const userExists = Boolean(userResult.data?.user?.id);

  const signUpComplete = signUpResult.data?.signUp?.success;

  const syncUserComplete = syncUserResult.data?.syncUser?.success;

  const loading =
    signUpResult.fetching || userResult.fetching || syncUserResult.fetching;

  const error: CombinedError | ErrorType | null | undefined =
    userResult.error ??
    signUpResult.error ??
    signUpResult.data?.signUp?.error ??
    syncUserResult.error ??
    syncUserResult.data?.syncUser?.error;

  const { getReferralDetails, clearReferralDetails } = useReferral();

  useEffect(() => {
    if (!loading && !error && loggedIn && userId) {
      if (!userExists && !signUpComplete) {
        const {
          teamName,
          advisor,
          referrer,
          userType,
          userJourney,
          sourceBranchName,
        } = getReferralDetails();
        signUp({
          input: {
            userId,
            email: username,
            advisorId: advisor,
            teamName,
            userType,
            userJourney,
            referralSource: referrer,
            sourceBranchName,
          },
        });
      } else if (userExists && !syncUserComplete && !signUpComplete) {
        const { userJourney, userType, sourceBranchName } =
          getReferralDetails();
        syncUser({
          input: {
            userId,
            userType,
            userJourney,
            sourceBranchName,
          },
        }).then(() => {
          clearReferralDetails();
        });
      }
    }
  }, [
    error,
    loading,
    loggedIn,
    signUp,
    userId,
    username,
    getReferralDetails,
    clearReferralDetails,
    signUpComplete,
    syncUserComplete,
    syncUser,
    userExists,
  ]);

  return {
    error,
    loading,
    complete: signUpComplete || syncUserComplete,
  };
};

export { useCreateUserFromAuth };
