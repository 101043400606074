import { Link } from "react-router-dom";
import { Card } from "@multiply/lib";
import { useGetUserJourney } from "../../hooks";
import { Loading } from "../../pages";

type ReferralCardProps = {
  reason?: string | null;
};

const ReferralCard = ({ reason = null }: ReferralCardProps) => {
  const userJourney = useGetUserJourney();
  return (
    <>
      {userJourney.loading ? (
        <Loading />
      ) : (
        <Card className="p-24 mb-48">
          <h1 className="text-font-primary text-t24 sm:text-t27 pb-12 font-bold">
            Book a call with our advisers
          </h1>
          <p className="text-font-primary text-t12 sm:text-t16 pb-16">
            {`Due to your circumstances, we recommend talking to one of our advisers
        instead of generating your plan${reason ? ", this is because:" : "."}`}
          </p>
          {reason && (
            <ul className="pb-16 ml-12 list-disc list-inside">
              <li>{reason}</li>
            </ul>
          )}
          <p className="text-font-primary text-t12 sm:text-t16 pb-16">
            So we'd like to give you some extra financial guidance and support.
          </p>
          <Link
            to={userJourney.callRoute}
            className="text-font-links sm:text-t21 pb-16 block"
          >
            Book a call
          </Link>
        </Card>
      )}
    </>
  );
};

export { ReferralCard };
