import { useAuth } from "@multiply/lib";
import { PensionsReview, PensionsReviewCardProps } from "../../components";
import { useGetFactFindQuery } from "../../graphqlTypes";

const PensionsReviewEmploymentDetails = () => {
  const { userId, loggedIn } = useAuth();
  const [factFindResults] = useGetFactFindQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const {
    employer,
    employerAddressLine1,
    employerAddressLine2,
    employerAddressLine3,
  } = factFindResults?.data?.factfind?.userSpecificFactfind ?? {};

  const loading = factFindResults?.fetching;

  const reviewData: PensionsReviewCardProps[] = [
    {
      label: "Employer’s name",
      fieldName: "employer",
      value: employer,
    },
    {
      label: "Employer’s address",
      fieldName: "employer_address_line1",
      value: employerAddressLine1
        ? `${employerAddressLine1} ${employerAddressLine2} ${employerAddressLine3}`
        : "Not added",
    },
  ];

  return (
    <PensionsReview
      backButton={true}
      title="Please review and confirm your employer’s details"
      subtitle="Employer details"
      loading={loading}
      reviewData={reviewData}
      onDonePath="/pensions/review-contributions-details"
    />
  );
};

export { PensionsReviewEmploymentDetails };
