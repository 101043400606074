import { Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import {
  IrishLifeDisclaimerText,
  IrishLifeLogo,
  IrishLifeLogoLeftCorner,
  PasswordInput,
  TextInput,
} from "../../components";
import { useLoginForm } from "../../hooks";
import { Button, ButtonLink, Card, useWindowDimensions } from "@multiply/lib";

export function Login() {
  const { control, formState, handleSubmit } = useLoginForm();
  const { isDirty, isSubmitting } = formState;
  const { isPhone } = useWindowDimensions();
  const navigate = useNavigate();

  const onSubmit = () => {
    navigate("/settings");
  };

  return (
    <main className="h-screen w-screen flex flex-col">
      <div className="flex justify-between items-start">
        {isPhone ? (
          <IrishLifeLogo
            aria-label="irish life logo"
            className="ml-16 mt-16 mb-36"
          />
        ) : (
          <IrishLifeLogoLeftCorner
            aria-label="irish life logo"
            className=" flex place-self-start"
          />
        )}
        {isPhone ? (
          <Link
            aria-label="signup"
            to="/signup"
            className="text-t16 text-font-secondary mt-26 mr-32"
          >
            Sign up
          </Link>
        ) : (
          <ButtonLink className="mt-24 mr-32" to="/signup">
            Sign up
          </ButtonLink>
        )}
      </div>
      <section className="flex-1 flex items-center justify-center">
        <Card className="px-32 pt-24 pb-40 sm:px-160 sm:pt-80 sm:pb-60 self-center">
          <div id="api"></div>
          <form className="flex flex-col max-w-520">
            <h1 className="mb-40 text-t20 sm:text-t27 text-font-primary text-center sm:mb-60">
              Log in to your existing account
            </h1>
            <Controller
              control={control}
              name="email"
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
              }) => (
                <TextInput
                  className="mb-36"
                  label="Email"
                  placeholder="Enter your email"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
            <Controller
              control={control}
              name="password"
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
              }) => (
                <PasswordInput
                  label="Password"
                  placeholder="Enter your password"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
            {/* <Link
              aria-label="forgot password"
              to="/forgotPassword"
              className="mt-16 mb-32 text-center text-t16 text-font-links sm:mt-24 sm:mb-60"
            >
              I've forgotten my password
            </Link> */}
            <Button
              className="mt-56"
              disabled={!isDirty || isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              Login
            </Button>
          </form>
        </Card>
      </section>

      <IrishLifeDisclaimerText className="mb-8 text-t10 sm:text-t16 text-center sm:mb-60" />
    </main>
  );
}
