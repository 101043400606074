import { Card, ContainerMidSize, Spinner, useAuth } from "@multiply/lib";
import {
  BookAdviserCall,
  PlanStatusCard,
  RecommendationCarousel,
  RiskProfileCard,
  YourProducts,
  YouToldUs,
} from "../../components";
import {
  AuthorisationStatus,
  RecommendationType,
  SavingsInvestingSummary,
  useGetPlanRecommendationsQuery,
  useGetRiskQuery,
} from "../../graphqlTypes";
import { CURRENCY_SYMBOL } from "../../utils";
import { ThisMeansSavingsAndInvesting } from "./ThisMeansSavingsAndInvesting";

const SavingsAndInvesting = () => {
  const { userId, loggedIn } = useAuth();
  const [planResult] = useGetPlanRecommendationsQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const [riskResult] = useGetRiskQuery({ variables: { userId: userId ?? "" } });
  const calculatedProfile = riskResult.data?.risk.calculatedProfile;

  const recommendationGroups =
    planResult?.data?.plan?.recommendationGroups ?? [];

  const savingsAndInvestingRecommendation = recommendationGroups.find(
    (group) => group.recommendationType === RecommendationType.SavingsInvesting
  );

  const summary = savingsAndInvestingRecommendation?.summary as
    | SavingsInvestingSummary
    | null
    | undefined;

  const currentMonthsSaved = summary?.emergencyFundProgress.monthsProtected;
  const recommendedProtectedMonths =
    summary?.emergencyFundProgress.monthsProtected;
  const currentProvision =
    summary?.emergencyFundProgress.currentProvision.display;
  const recommendedProvision =
    summary?.emergencyFundProgress.recommendedProvision.display;
  const progress = summary?.emergencyFundProgress.progress;
  const goals = summary?.goalProgress;

  const cashAndSavingsData = summary?.breakdown.find(
    (slice) => slice.name === "Cash and Savings"
  );
  const investmentsData = summary?.breakdown.find(
    (slice) => slice.name === "Investments"
  );
  const otherData = summary?.breakdown.find((slice) => slice.name === "Other");

  const notApproved =
    planResult?.data?.plan?.authorisationStatus !==
    AuthorisationStatus.AdviceApproved;

  const errorGenerated = planResult?.error;

  return (
    <div className="w-full flex justify-center">
      {planResult.fetching ? (
        <Spinner className="w-60 h-60 my-32" />
      ) : errorGenerated || notApproved ? (
        <PlanStatusCard />
      ) : (
        <ContainerMidSize>
          <h1 className="text-t21 sm:text-t34 text-font-primary mb-12">
            Savings & Investing
          </h1>

          <h2 className="text-t12 sm:text-t27 text-font-tertiary mb-56">
            Save for your goals and build your wealth
          </h2>

          <div className="bg-input-border w-full h-2 mb-56" />

          <h1 className="sm:text-t27 text-font-primary mb-32">Overview</h1>

          <section className="sm:flex space-y-40 sm:space-y-0 items-stretch gap-x-48 mb-32">
            <YouToldUs
              title="You told us"
              editPath="/advice/plan/view-goals"
              className="flex-1"
              text={savingsAndInvestingRecommendation?.factfindSummary}
            />

            <YourProducts
              title="Your assets"
              productSummary={
                savingsAndInvestingRecommendation?.relatedProducts
              }
            />
          </section>
          <section className="mb-60">
            <p className="text-t12 sm:text-t21 text-font-secondary mb-32">
              This means
            </p>

            <ThisMeansSavingsAndInvesting
              cashAndSavingsData={cashAndSavingsData}
              investmentsData={investmentsData}
              otherData={otherData}
              currentMonthsSaved={currentMonthsSaved ?? 0}
              recommendedProtectedMonths={recommendedProtectedMonths ?? 0}
              currentProvision={currentProvision ?? `${CURRENCY_SYMBOL}0.00`}
              recommendedProvision={
                recommendedProvision ?? `${CURRENCY_SYMBOL}0.00`
              }
              progress={progress}
              goals={goals}
            />
          </section>
          {savingsAndInvestingRecommendation?.riskProfileQuestionnaireRequired && (
            <section className="mb-60 space-y-32">
              <h1 className="sm:text-t27 text-font-primary mb-32">
                Risk profile
              </h1>

              {riskResult.fetching ? (
                <Card className="flex items-center justify-center p-40">
                  <Spinner className="w-60 h-60 my-32" />
                </Card>
              ) : calculatedProfile === null ? (
                <RiskProfileCard />
              ) : (
                <Card className="p-40">
                  <p className="text-font-secondary text-t12 sm:text-t16 mb-8">
                    Your profile is:
                  </p>
                  <h1 className="text-font-primary text-t21 sm:text-t27 mb-24">
                    {calculatedProfile?.name}
                  </h1>
                  <p className="text-font-primary text-t12 sm:text-t16 mb-24">
                    {calculatedProfile?.description}
                  </p>
                  {/* TODO link to book adviser */}
                  <p className="text-font-primary text-t12 sm:text-t16 ">
                    If at any point you believe your attitude to risk has
                    changed, we recommend speaking to one of our advisers.
                  </p>
                </Card>
              )}
            </section>
          )}

          <section className="mb-60 space-y-32">
            {savingsAndInvestingRecommendation?.recommendations && (
              <RecommendationCarousel
                recommendations={
                  savingsAndInvestingRecommendation?.recommendations
                }
                title="Recommendations"
              />
            )}
          </section>

          <section>
            <BookAdviserCall hasPlan={true} />
          </section>
        </ContainerMidSize>
      )}
    </div>
  );
};

export { SavingsAndInvesting };
