import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";
import {
  IconProps,
  getDimensionsFromSize,
  getColorFromVariant,
} from "./shared";

const HeartHealthOutlineIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);

  return (
    <svg
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="health icon"
      {...props}
    >
      <path
        d="M7.99344 3.88169C7.99344 3.88169 8.80746 1.66699 11.4987 1.66699C14.1898 1.66699 17.034 4.44499 14.4767 9.31954C12.3131 13.4437 8.71721 14.9199 8.01149 15.1797H8.01014C7.30443 14.9199 3.67079 13.4437 1.50717 9.31954C-1.05015 4.44499 1.75499 1.66699 4.44617 1.66699C7.13733 1.66699 7.99044 3.88169 7.99044 3.88169"
        stroke={fill}
        strokeWidth="0.750014"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.06641 7.98471H3.80164L4.95383 10.0661L6.86843 5.90527L9.12812 10.591L11.2909 6.24553L12.194 8.13647H14.826"
        stroke={fill}
        strokeWidth="0.750014"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { HeartHealthOutlineIcon };
