import {
  BackArrowIcon,
  HeartHealthOutlineIcon,
  NestOutlineIcon,
  SirenOutlineIcon,
  SproutIcon,
  UmbrellaOutlineIcon,
} from "@multiply/lib";
import { RecommendationType } from "../../graphqlTypes";

type RecommendationIconProps = {
  type: RecommendationType;
};

const iconProps = {
  width: "55%",
  height: "55%",
  className:
    "fill-recommendation-icon-fill/[.2] text-recommendation-icon-text stroke-1",
} as const;

const RecommendationIcon = ({ type }: RecommendationIconProps) => {
  switch (type) {
    case RecommendationType.Debt:
      return <BackArrowIcon {...iconProps} />;
    case RecommendationType.EmergencyFund:
      return <SirenOutlineIcon {...iconProps} />;
    case RecommendationType.Health:
      return <HeartHealthOutlineIcon {...iconProps} />;
    case RecommendationType.Protection:
      return <UmbrellaOutlineIcon {...iconProps} />;
    case RecommendationType.Retirement:
      return <NestOutlineIcon {...iconProps} />;
    case RecommendationType.SavingsInvesting:
      return <SproutIcon {...iconProps} />;
    default:
      const unreachable: never = type;
      return unreachable;
  }
};

export { RecommendationIcon };
