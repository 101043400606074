import { SectionHeading, Spinner, useAuth } from "@multiply/lib";
import {
  BookAdviserCall,
  IrishLifeDisclaimerText,
  PlanStatusCard,
  RecommendationsSummaryCard,
} from "../../components";
import { InteractionFlags } from "../../globalTypes";
import { useGetPlanQuery } from "../../graphqlTypes";
import { useInteractionFlag, useUserPastAdviceAge } from "../../hooks";
import { GetStartedCard } from "./GetStartedCard";

const PrePlan = () => {
  const adviceAge = useUserPastAdviceAge();

  const { userId, loggedIn } = useAuth();

  const [planResult] = useGetPlanQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const completedGenerateLifePlan = useInteractionFlag(
    InteractionFlags.CompletedGenerateLifePlan
  );

  const userHasGeneratedPlan =
    !completedGenerateLifePlan.loading && completedGenerateLifePlan.value;

  if (
    adviceAge.loading ||
    completedGenerateLifePlan.loading ||
    planResult.fetching
  ) {
    return <Spinner />;
  }

  return (
    <>
      <SectionHeading title="Your Financial Plan" />

      {userHasGeneratedPlan || adviceAge.userPastAdviceAge ? (
        <PlanStatusCard
          planResult={planResult.data?.plan}
          error={planResult?.error?.message}
          userPastAdviceAge={adviceAge.userPastAdviceAge}
        />
      ) : (
        <GetStartedCard />
      )}

      <section className="sm:w-min sm:max-w-full mt-56">
        <SectionHeading
          title="Recommendations"
          subtitle="Once you've created your plan, your recommendations will show you what
          you should be doing with your money"
        />

        <RecommendationsSummaryCard
          hasPlan={false}
          className="sm:w-max sm:max-w-full mb-56"
        />

        <SectionHeading title="Speak to an adviser" />
        <BookAdviserCall hasPlan={false} />
      </section>

      <IrishLifeDisclaimerText className="text-t14 mx-12 text-font-secondary mt-40" />
    </>
  );
};

export { PrePlan };
