import { DebtType } from "../graphqlTypes";

const sortedDebtTypes = [
  DebtType.CreditCard,
  DebtType.Loan,
  DebtType.CarLoan,
  DebtType.Mortgage,
  DebtType.HirePurchaseAgreement,
  DebtType.PersonalLoan,
  DebtType.OtherDebt,
];

export { sortedDebtTypes };
