import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export type PensionSimulatorFormValues = {
  targetMonthlyIncome: number | null;
  monthlyContribution: number | null;
  oneOffContribution: number | null;
  retirementAge: number | null;
};

export const defaultPensionSimulatorValidationSchema: yup.SchemaOf<PensionSimulatorFormValues> =
  yup
    .object()
    .shape({
      targetMonthlyIncome: yup
        .number()
        .required("Please provide your target monthly income")
        .nullable(),
      monthlyContribution: yup.number().nullable(),
      oneOffContribution: yup.number().nullable(),
      retirementAge: yup
        .number()
        .required("This must be at least your current age")
        .nullable(),
    })
    .defined();

type UsePensionSimulatorFormArgs = {
  defaultValues?: Partial<PensionSimulatorFormValues>;
  validationSchema?: yup.SchemaOf<PensionSimulatorFormValues>;
};

const usePensionSimulatorForm = (args?: UsePensionSimulatorFormArgs) => {
  const defaultValues = {
    targetMonthlyIncome: null,
    monthlyContribution: null,
    oneOffContribution: null,
    retirementAge: null,
    ...(args?.defaultValues ?? {}),
  };
  const validationSchema =
    args?.validationSchema ?? defaultPensionSimulatorValidationSchema;

  const form = useForm<PensionSimulatorFormValues>({
    defaultValues: {
      targetMonthlyIncome: defaultValues?.targetMonthlyIncome,
      monthlyContribution: defaultValues?.monthlyContribution,
      oneOffContribution: defaultValues?.oneOffContribution,
      retirementAge: defaultValues?.retirementAge,
    },
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  return form;
};

export { usePensionSimulatorForm };
