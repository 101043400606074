import { useAuth, ChatIcon } from "@multiply/lib";
import { useGetPersonQuery } from "../../graphqlTypes";
import { useEffect, useState } from "react";

const WebChatButton = () => {
  const { userId, loggedIn } = useAuth();

  const [personResult] = useGetPersonQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const { firstName, lastName } = personResult?.data?.person ?? {};
  const [webChatWindowOpen, setWebChatWindowOpen] = useState(false);

  // Register the new web chat button with Genesys
  useEffect(() => {
    const registerWebChat = async () => {
      const buttonState = {
        current: false,
        setter: (state: boolean) => setWebChatWindowOpen(state),
      };

      try {
        await window.CXBus.command(
          "MultiplyWCExtension.registerButton",
          buttonState
        );
      } catch (error) {
        console.log(error);
      }
    };
    registerWebChat();
  }, []);

  const handleCancel = (e: Event) => {
    if (window.CXBus) {
      window.CXBus.command("WebChat.close")
        .done(() => {
          document
            .getElementById("cx_overlay_cancel")
            ?.removeEventListener("click", handleCancel);

          document
            .getElementById("cx_overlay_continue")
            ?.removeEventListener("click", handleContinue);
        })
        .fail(() => console.log("Error when user cancels webChat"));
    }
  };

  const handleContinue = (e: Event) => {
    document
      .getElementById("cx_overlay_continue")
      ?.removeEventListener("click", handleContinue);

    document
      .getElementById("cx_overlay_cancel")
      ?.removeEventListener("click", handleCancel);

    window.CXBus.command("WebChat.hideOverlay");
  };

  const openWebChat = () => {
    if (!webChatWindowOpen && window.CXBus) {
      window.CXBus.command("WebChat.open", {
        form: {
          firstname: firstName || null,
          lastname: lastName || null,
          autoSubmit: firstName && lastName ? true : false,
          emojis: false,
        },
      })
        .done(() => {
          // Set up the intitial disclaimer overlay
          window.CXBus.command("WebChat.showOverlay", {
            html: "<div id='cx_chat_information'><div class='cx-notice'>Please note all our chats are recorded for training and quality purposes. Please read our Irish Life Financial Services Data Privacy Notice at <span><a href='https://www.irishlife.ie/privacy-notices/' target='_blank'>www.irishlife.ie/privacy-notice</a></span></div><div class='cx-webchat-chat-button'><button id='cx_overlay_cancel' class='cx-btn-default'>Cancel</button><button id='cx_overlay_continue' class='cx-btn-primary'>Continue</button></div></div>",
            hideFooter: false,
          }).done(() => {
            document
              .getElementById("cx_overlay_cancel")
              ?.addEventListener("click", handleCancel);
            document
              .getElementById("cx_overlay_continue")
              ?.addEventListener("click", handleContinue);
          });
        })
        .fail(() => console.log("WebChat failed to open"));
    }
  };

  return (
    <>
      {!personResult.fetching && (
        <div
          id="mai_chat_button"
          className={`fixed z-40 top-[10vh] -right-24 cursor-pointer bg-brand-primary text-white font-bold fontSize-t73 -rotate-90 scale-[102%] origin-bottom-right flex gap-8 p-8 pb-4 md:py-8 md:px-12 rounded-t-sm
      transition ease-in-out delay-150 duration-300 -translate-x-24 ${
        webChatWindowOpen && "translate-x-24"
      }
      `}
          onClick={() => openWebChat()}
        >
          <div className="w-24 h-24 md:w-24 md:h-24 rotate-90 md:rotate-0">
            <ChatIcon />
          </div>
          <p className="hidden md:block">Live Chat</p>
        </div>
      )}
    </>
  );
};

export { WebChatButton };

declare global {
  interface Window {
    CXBus: any;
  }
}
