import { useAuth, useWindowDimensions } from "@multiply/lib";
import { FC, useState } from "react";
import {
  XAxis,
  Bar,
  ComposedChart,
  Line,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  Money,
  PensionCalculatorInput,
  useGetPensionCalculationQuery,
} from "../../graphqlTypes";
import { PensionTooltip } from "./PensionTooltip";

type ShowData = {
  __typename?: string;
  age: number;
  date?: Date;
  employmentIncome: Money;
  personalPensionAmount: Money;
  statePensionAmount: Money;
  targetIncome: Money | null;
  totalPensionAmount: Money;
};

type PensionsGraphProps = {
  inputValues: Partial<PensionCalculatorInput>;
};
const PensionsGraph = ({ inputValues }: PensionsGraphProps) => {
  const { userId } = useAuth();

  // Get graph projection data
  const [pensionResults] = useGetPensionCalculationQuery({
    variables: {
      input: {
        ...inputValues,
        userId: userId ?? "",
      },
    },
    pause: !userId,
  });
  const pensionProjection =
    pensionResults.data?.calculatePension?.pensionProjection ?? [];

  const { isPhone } = useWindowDimensions();

  const [mouseOn, setMouseOn] = useState<string | null>(null);

  const height = isPhone ? 100 : 200;

  // Remove zero points from the target line graph
  let zerosToNull: ShowData[] = (pensionProjection ?? []) as ShowData[];
  (pensionProjection ?? []).forEach((value, index) => {
    if (value?.targetIncome?.float === 0) {
      zerosToNull[index].targetIncome = null;
    }
  });

  // Change number of points shown on mobile
  const showData = zerosToNull.filter(
    (value) => value && value.age % (isPhone ? 2 : 1) === 0
  );

  // Generate array for tick values
  const delimitedTickValues = [
    showData[0]?.age,
    ...(showData ?? [])
      .filter((value) => (value.age % 10 === 0 ? value.age : null))
      .map((value) => value.age),
    showData[showData.length - 1]?.age,
  ];
  // Remove duplicates caused by remainder operator
  const tickValues = delimitedTickValues.filter(
    (tick, index) => delimitedTickValues.indexOf(tick) === index
  );

  const CustomizedAxisTick: FC = (props: any) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dx={8}
          dy={8}
          textAnchor="end"
          fill="#000"
          transform={`scale(${isPhone ? "0.6" : "0.8"})`}
          className="stroke-brand-primary stroke-[0.75]"
        >
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={height}>
      <ComposedChart
        data={showData}
        margin={{
          top: 0,
          right: 10,
          left: 10,
          bottom: 0,
        }}
      >
        <XAxis
          dataKey="age"
          type="number"
          domain={["dataMin", "dataMax"]}
          tickLine={false}
          axisLine={false}
          tick={<CustomizedAxisTick />}
          ticks={tickValues}
          interval={0}
        />

        <Bar
          dataKey="employmentIncome.float"
          stackId="a"
          fill="#717FB8"
          onMouseOver={() => setMouseOn("employmentIncome.float")}
          onMouseLeave={() => setMouseOn(null)}
        />
        <Bar
          dataKey="statePensionAmount.float"
          stackId="a"
          fill="#0C1E68"
          onMouseOver={() => setMouseOn("statePensionAmount.float")}
          onMouseLeave={() => setMouseOn(null)}
        />
        <Bar
          dataKey="personalPensionAmount.float"
          stackId="a"
          fill="#55CBB7"
          onMouseOver={() => setMouseOn("personalPensionAmount.float")}
          onMouseLeave={() => setMouseOn(null)}
        />
        <Line
          type="monotone"
          dataKey="targetIncome.float"
          stroke="#D9145B"
          strokeWidth={1.5}
          dot={false}
          onMouseOver={() => setMouseOn("targetIncome.float")}
          onMouseLeave={() => setMouseOn(null)}
        />
        <Tooltip
          content={
            <PensionTooltip
              plotLabel={mouseOn}
              retirementAge={inputValues.retirementAge}
            />
          }
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export { PensionsGraph };
