import { FC } from "react";
import { Spinner } from "@multiply/lib";

type LoadingProps = {
  children?: any;
};

const Loading: FC<LoadingProps> = ({ children }) => {
  return <Spinner />;
};

export { Loading };
