import { Button, ContainerMidSize, useAuth } from "@multiply/lib";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConversationCardTemplate, RadioButtonGroup } from "../../components";
import { DebtType, useUpdateDebtMutation } from "../../graphqlTypes";
import { getDebtNameFromType, sortedDebtTypes } from "../../utils";

function AddDebt() {
  const { userId } = useAuth();
  const [debtType, setDebtType] = useState<DebtType | null>(null);
  const [addDebtResult, addDebt] = useUpdateDebtMutation();
  const navigate = useNavigate();

  const handleAddDebt = async () => {
    try {
      if (debtType) {
        const newDebt = {
          productType: debtType,
        };
        const result = await addDebt({
          input: { userId: userId ?? "", debt: newDebt },
        });

        if (result?.data?.updateDebt?.success) {
          const debtId = result?.data?.updateDebt?.debt?.id;
          navigate(`/products/add/debt/${debtId}`);
        }
      }
    } catch (error) {}
  };

  const error = addDebtResult.error ?? addDebtResult.data?.updateDebt?.error;

  return (
    <ContainerMidSize>
      <ConversationCardTemplate title="Which debt do you want to add?">
        <RadioButtonGroup
          value={debtType}
          onChange={(nextValue) => {
            setDebtType(nextValue);
          }}
          options={Object.values(sortedDebtTypes).map((debtType) => ({
            value: debtType,
            label: getDebtNameFromType(debtType),
          }))}
        />
        <Button
          onClick={handleAddDebt}
          disabled={addDebtResult.fetching || !debtType}
        >
          Next
        </Button>
        {error && <p className="mt-16 text-action-error">{error.message}</p>}
      </ConversationCardTemplate>
    </ContainerMidSize>
  );
}

export { AddDebt };
