import classNames from "classnames";
import { ArrowHeadDownIcon, ArrowHeadUpIcon } from "@multiply/lib";

type GoalsPriorityCounterProps = {
  value: number;
  onChange: (value: number) => void;
  minValue?: number;
  maxValue: number;
  className?: string;
};

const GoalsPriorityCounter = ({
  minValue = 0,
  onChange,
  maxValue,
  value,
  className,
}: GoalsPriorityCounterProps) => {
  const handleAdd = () => {
    onChange(value + 1);
  };

  const handleSub = () => {
    onChange(value - 1);
  };

  const isMax = value <= minValue;
  const isMin = value >= maxValue;

  return (
    <div
      data-testid="priority-counter"
      className={classNames("flex flex-col items-center", className)}
    >
      <button
        data-testid="increase priority"
        onClick={handleSub}
        disabled={isMax}
      >
        <ArrowHeadUpIcon
          aria-label="increase priority"
          className={`${isMax ? "invisible" : ""}`}
        />
      </button>

      <div className="border border-input-border rounded-lg py-12 px-8 bold bg-input-background my-12">
        {value + 1}
      </div>

      <button
        data-testid="decrease priority"
        onClick={handleAdd}
        disabled={isMin}
      >
        <ArrowHeadDownIcon
          aria-label="decrease priority"
          className={`${isMin ? "invisible" : ""}`}
        />
      </button>
    </div>
  );
};

export { GoalsPriorityCounter };
