import { useNavigate, useParams } from "react-router-dom";
import { FactfindPage } from "../FactfindPage";
import { PensionsPageTemplate } from "../../components";

type EditPensionsDetailsProps = {
  onDoneRoute?: string;
  cta: string;
};

const EditPensionsDetails = ({
  onDoneRoute,
  cta,
}: EditPensionsDetailsProps) => {
  const fieldName = useParams().fieldName;
  const navigate = useNavigate();

  const onDone = async () => {
    if (onDoneRoute) {
      return navigate(onDoneRoute);
    } else {
      return navigate(-1)
    }
  };

  const context = `settings_${fieldName}`;

  return (
    <PensionsPageTemplate>
      <FactfindPage context={context} onDone={onDone} cta={cta} />
    </PensionsPageTemplate>
  );
};

export { EditPensionsDetails };
