import classNames from "classnames";
import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import { IconProps, getDimensionsFromSize } from "./shared";

const OpenBookIcon: FC<IconProps> = ({ size, className, ...props }) => {
  const { width, height } = getDimensionsFromSize(size);
  return (
    <svg
      width={width}
      height={height}
      data-testid="book icon"
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      viewBox="0 0 35 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.873 29.9357C15.4014 29.6448 14.9125 29.3828 14.409 29.1514C13.151 28.5696 11.4724 28.0221 9.65832 28.0221C7.46319 28.0221 5.47402 28.8253 4.20053 29.5015C3.79861 29.712 3.35061 29.819 2.89696 29.8131C2.44331 29.8071 1.9983 29.6882 1.60208 29.4672C1.18595 29.2428 0.837903 28.9106 0.594496 28.5053C0.351088 28.1 0.221296 27.6367 0.21875 27.1639V5.71038C0.21875 4.64457 0.725054 3.54271 1.75997 2.91455C3.08838 2.1079 6.07815 0.561523 9.65832 0.561523C12.3718 0.561523 15.056 1.43683 17.3816 2.80986C19.7072 1.43683 22.3914 0.561523 25.1049 0.561523C28.6851 0.561523 31.6748 2.10618 33.0032 2.91455C34.0382 3.54271 34.5445 4.64457 34.5445 5.71038V27.1639C34.5445 28.2504 33.894 29.0622 33.1611 29.4672C32.7647 29.6885 32.3194 29.8075 31.8654 29.8135C31.4114 29.8195 30.9631 29.7123 30.561 29.5015C29.2892 28.8253 27.3 28.0221 25.1049 28.0221C23.2908 28.0221 21.6122 28.5713 20.3542 29.1514C19.8507 29.3828 19.3618 29.6448 18.8902 29.9357C18.4028 30.2395 17.9909 30.5965 17.3833 30.5965C16.7723 30.5965 16.3621 30.2395 15.873 29.9357ZM3.65132 25.9522V5.78246C4.80638 5.09595 7.08389 3.99409 9.65832 3.99409C11.7694 3.99409 13.8598 4.7115 15.6653 5.78246V25.9522C14.1464 25.2726 12.0388 24.5895 9.65832 24.5895C7.28813 24.5895 5.18053 25.2675 3.65132 25.9522ZM19.0979 25.9522C20.6168 25.2726 22.7244 24.5895 25.1049 24.5895C27.4751 24.5895 29.5827 25.2675 31.1119 25.9522V5.78246C29.9568 5.09595 27.6793 3.99409 25.1049 3.99409C22.9939 3.99409 20.9034 4.7115 19.0979 5.78246V25.9522Z"
        fill="#717fe3"
      />
    </svg>
  );
};

export { OpenBookIcon };
