import { FC, ReactNode } from "react";

type ExpectationProps = { icon: ReactNode; title: string };

const Expectation: FC<ExpectationProps> = ({ icon, title }) => {
  return (
    <div>
      <div className="p-18 bg-recommendation-icon-roundel h-65 w-60 rounded-xsm flex items-center">
        {icon}
      </div>
      <p className="text-font-primary">{title}</p>
    </div>
  );
};

export { Expectation };
