import { Button, ContainerMaxSize, TrackedLink } from "@multiply/lib";
import { Link } from "react-router-dom";
import { useGetUserJourney } from "../../hooks";

export function Contact() {
  const userJourney = useGetUserJourney();
  return (
    <ContainerMaxSize>
      <div className="w-full flex flex-col sm:pt-60">
        <div className="lg:flex flex-col gap-120">
          <div className="lg:flex gap-48">
            <section className="mt-24 mb-36 sm:w-400 lg:w-full">
              <h1 className="text-t21 sm:text-t27 text-font-primary pb-8 sm:pb-16">
                Live Chat
              </h1>

              <div className="text-t16 sm:text-t21 text-font-secondary">
                <p className="pb-18">
                  If you have any questions about using this site or something
                  about bugs, contact our customer support team.
                </p>

                {!userJourney.loading ? (
                  <p className="pb-40">
                    For any advice or questions about your plan, please go to
                    the
                    <span className="text-font-links">
                      <Link to={userJourney.callRoute}>
                        {" "}
                        book a call with an adviser{" "}
                      </Link>
                    </span>{" "}
                    section instead.
                  </p>
                ) : null}

                <h2 className="pb-24 text-font-primary">
                  Our Customer Service Opening Hours
                </h2>

                <div className="pb-24">
                  <p>
                    Monday to Thursday:{" "}
                    <span className="text-font-primary">8am - 6pm</span>
                  </p>
                  <p>
                    Friday:{" "}
                    <span className="text-font-primary">10am - 6pm</span>
                  </p>
                </div>
              </div>

              <Button className="py-32 px-44">
                <div
                  className="text-t27"
                  onClick={() => {
                    const webChatButton =
                      document.getElementById("mai_chat_button");
                    if (webChatButton) webChatButton.click();
                  }}
                >
                  Start live chat
                </div>
              </Button>

              {/* TODO Add cookie bot link */}

              <h2 className="text-t16 sm:text-t21 text-font-primary pt-144 pb-24">
                Alternatively, you can contact us via email:
              </h2>
              <TrackedLink
                href="mailto:digitaladvicesupport@irishlife.ie?subject=Website%20Enquiry"
                target="_blank"
                rel="noopener noreferrer"
                className="text-t16 sm:text-t21 text-font-links pb-24"
              >
                digitaladvicesupport@irishlife.ie
              </TrackedLink>
              {/* TODO waiting on IL */}
              {/* <p className="text-t16 sm:text-t21 text-font-secondary">
              Replies usually take between X days.{" "}
            </p> */}
            </section>
          </div>
        </div>
      </div>
    </ContainerMaxSize>
  );
}
