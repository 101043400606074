import { yupResolver } from "@hookform/resolvers/yup";
import { startOfDay, subYears } from "date-fns";
import pick from "lodash/pick";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { AssetField } from "../../globalTypes";
import {
  AssetOrigin,
  AssetType,
  PaymentFrequency,
  PensionType,
  PropertyType,
  ValuationMethod,
} from "../../graphqlTypes";
import { transformDate } from "../../utils";

export type DefaultAssetFormValues = {
  owners: string[] | null;
  balance: number | null;
  provider: string | null;
};

const employerPensionTypes = [
  PensionType.DefinedContribution,
  PensionType.DefinedBenefit,
  PensionType.CompanyPension,
  PensionType.AvcPension,
  PensionType.ExecutivePension,
];

const assetValidationFields = {
  accessible: yup.boolean().required("Please provide an answer").nullable(),
  balance: yup
    .number()
    .min(0, "Amount must be at least 0")
    .required("Please provide an answer")
    .nullable(),
  frequency: yup
    .string()
    .oneOf([...Object.values(PaymentFrequency), null])
    .required("Please provide an answer")
    .nullable(),
  income: yup
    .number()
    .min(0, "Amount must be at least 0")
    .required("Please provide an answer")
    .nullable(),
  increasing: yup.boolean().required("Please provide an answer").nullable(),
  isOverdrawn: yup.boolean().required("Please provide an answer").nullable(),
  maturityDate: yup
    .date()
    .transform((value, originalValue) => transformDate(originalValue))
    .typeError('Please enter a valid date in the format "DD-MM-YYYY"')
    .min(new Date(), "The maturity date must be in the future")
    .nullable(),
  name: yup.string().nullable(),
  overdrawnBy: yup
    .number()
    .min(0, "Amount must be at least 0")
    .required("Please provide an answer")
    .nullable(),
  owners: yup
    .array()
    .of(yup.string())
    .min(1, "Please provide an answer")
    .required("Please provide an answer")
    .nullable(),
  productType: yup
    .string()
    .oneOf([...Object.values(AssetType), null])
    .required("Please provide an answer")
    .nullable(),
  provider: yup.string().nullable(),
  regularPaymentAmount: yup
    .number()
    .min(0, "Amount must be at least 0")
    .required("Please provide an answer")
    .nullable(),
  startDate: yup
    .date()
    .min(subYears(startOfDay(new Date()), 50), "Please provide a valid date")
    .transform((value, originalValue) => transformDate(originalValue))
    .typeError('Please enter a valid date in the format "DD-MM-YYYY"')
    .required("Please provide an answer")
    .nullable(),
  valuationMethod: yup
    .string()
    .oneOf([...Object.values(ValuationMethod), null])
    .required("Please provide an answer")
    .nullable(),
  whereFrom: yup.string().when("propertyType", {
    is: PropertyType.MainResidence,
    then: yup.string().nullable(),
    otherwise: yup
      .string()
      .oneOf([...Object.values(AssetOrigin), null])
      .required("Please provide an answer")
      .nullable(),
  }),
  propertyType: yup
    .string()
    .oneOf([...Object.values(PropertyType), null])
    .required("Please provide an answer")
    .nullable(),
  pensionType: yup
    .string()
    .oneOf([...Object.values(PensionType), null])
    .required("Please provide an answer")
    .nullable(),
  active: yup.boolean().when("pensionType", {
    is: PensionType.DefinedBenefit,
    then: yup.boolean().required("Please provide an answer").nullable(),
    otherwise: yup.boolean().nullable(),
  }),
  throughCurrentEmployer: yup.boolean().when("pensionType", {
    is: (pensionType: PensionType) =>
      employerPensionTypes.includes(pensionType),
    then: yup.boolean().required("Please provide an answer").nullable(),
    otherwise: yup.boolean().nullable(),
  }),
  isIrishLifePension: yup.boolean().when("pensionType", {
    is: (pensionType: PensionType) => pensionType !== null,
    then: yup.boolean().required("Please provide an answer").nullable(),
    otherwise: yup.boolean().nullable(),
  }),
};

type UseAssetFormArgs = {
  fields: AssetField[];
  defaultValues?: any;
  focusedPensionFlow?: boolean;
};

const useAssetForm = ({
  fields,
  defaultValues,
  focusedPensionFlow,
}: UseAssetFormArgs) => {
  const validationSchema = useMemo(() => {
    const validationFields = fields.reduce((validators, field, index) => {
      const fieldName = typeof field === "string" ? field : field.fieldName;
      let required = typeof field === "string" ? undefined : field.required;

      let validation = assetValidationFields[fieldName];
      if (fieldName === "owners" && focusedPensionFlow) {
        validation = yup.array().of(yup.string()).notRequired().nullable();
      } else if (required === false) {
        validation = validation.optional() as typeof validation;
      } else if (required === true) {
        validation = validation.required("Please provide an answer");
      }

      return { ...validators, [fieldName]: validation };
    }, {});

    return yup.object().shape(validationFields).defined();
  }, [fields, focusedPensionFlow]);

  const fieldNames = fields.map((field) =>
    typeof field === "string" ? field : field.fieldName
  );

  return useForm({
    delayError: 1000,
    mode: "onChange",
    defaultValues: pick(defaultValues, fieldNames),
    resolver: yupResolver(validationSchema),
  });
};

export { useAssetForm, employerPensionTypes, assetValidationFields };
