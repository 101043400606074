import { useNavigate, useParams } from "react-router-dom";
import { PensionsPageTemplate } from "../../components";
import { PrsaFactfindPage } from "../PrsaFactfindPage";

type EditContributionsDetailsProps = {
  onDoneRoute?: string;
  cta: string;
};

const EditContributionsDetails = ({
  onDoneRoute,
  cta,
}: EditContributionsDetailsProps) => {
  const fieldName = useParams().fieldName;
  const navigate = useNavigate();

  const onDone = async () => {
    if (onDoneRoute) {
      return navigate(onDoneRoute);
    } else {
      return navigate(-1)
    }
  };

  const context = `settings_${fieldName}`;

  return (
    <PensionsPageTemplate>
      <PrsaFactfindPage context={context} onDone={onDone} cta={cta} />
    </PensionsPageTemplate>
  );
};

export { EditContributionsDetails };
