import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";
import {
  IconProps,
  getDimensionsFromSize,
  getColorFromVariant,
} from "./shared";

const CrossIcon: FC<IconProps> = ({ size, variant, className, ...props }) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);

  return (
    <svg
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="cross icon"
      {...props}
    >
      <path
        d="M3.88 10.548a1.111 1.111 0 1 0 1.572 1.571l-1.571-1.571Zm8.239-5.096a1.111 1.111 0 1 0-1.572-1.571l1.572 1.571ZM5.452 12.12l6.667-6.667-1.572-1.571-6.666 6.667 1.571 1.571Z"
        fill={fill}
      />
      <path
        d="M5.452 3.881a1.111 1.111 0 0 0-1.57 1.571l1.57-1.571Zm5.096 8.238a1.111 1.111 0 0 0 1.571-1.571l-1.571 1.571ZM3.88 5.452l6.667 6.667 1.571-1.571L5.452 3.88l-1.57 1.571Z"
        fill={fill}
      />
    </svg>
  );
};

export { CrossIcon };
