import isEqual from "lodash/isEqual";
import { PropertyType } from "../../graphqlTypes";
import { Dropdown } from "@multiply/lib";

type InputPropertyTypeProps = {
  value: PropertyType | null;
  onChange: (value: PropertyType | null) => void;
  onBlur: () => void;
  error?: string;
};

const InputPropertyType = ({
  value,
  onChange,
  onBlur,
  error,
}: InputPropertyTypeProps) => {
  const toItem = (propertyType: PropertyType) => {
    switch (propertyType) {
      case PropertyType.CommercialProperty:
        return {
          value: PropertyType.CommercialProperty,
          label: "Commercial Property",
        };
      case PropertyType.MainResidence:
        return { value: PropertyType.MainResidence, label: "Main Residence" };
      case PropertyType.ResidentialInvestmentProperty:
        return {
          value: PropertyType.ResidentialInvestmentProperty,
          label: "Residential Investment Property",
        };

      default:
        const unreachable: never = propertyType;
        return unreachable;
    }
  };

  const items = Object.values(PropertyType).map(toItem);

  const selectedValue =
    items.find((item) => isEqual(item.value, value)) ?? null;

  return (
    <Dropdown
      aria-label="dropdown to see list of property types"
      error={error}
      label="Property type"
      items={items}
      placeholder="Select property type"
      value={selectedValue}
      onChange={(item) => {
        onChange(item?.value ?? null);
      }}
      onBlur={onBlur}
    />
  );
};

export { InputPropertyType };
