import { useAuth } from "@multiply/lib";
import { useGetDebtsQuery } from "../../graphqlTypes";
import { getDebtNameFromType } from "../../utils";
import { AssetOrDebtCardGroup } from "./AssetOrDebtCardGroup";

type AssetCardGroupProps = {
  className?: string;
};

const DebtList = ({ className }: AssetCardGroupProps) => {
  const { userId, loggedIn } = useAuth();
  const [debtResult] = useGetDebtsQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const debts = debtResult?.data?.debts ?? [];
  const loading = debtResult?.fetching;

  const result = debts.map((debt) => {
    return {
      balance: debt?.balance?.display,
      productType: "debt",
      name: getDebtNameFromType(debt?.productType) ?? "",
    } as const;
  });

  return (
    <AssetOrDebtCardGroup
      className={className}
      result={result}
      loading={loading}
      manageText="Manage debts"
      addText="Add a debt"
    />
  );
};

export { DebtList };
