import { Card, ContainerMidSize, Spinner, useAuth } from "@multiply/lib";
import {
  BookAdviserCall,
  PlanStatusCard,
  RecommendationCarousel,
  RiskProfileCard,
  YourProducts,
  YouToldUs,
} from "../../components";
import {
  AuthorisationStatus,
  RecommendationType,
  RetirementSummary,
  useGetPlanRecommendationsQuery,
  useGetRiskQuery,
} from "../../graphqlTypes";
import { ThisMeansRetirement } from "./ThisMeansRetirement";

const Retirement = () => {
  const { userId, loggedIn } = useAuth();
  const [planResult] = useGetPlanRecommendationsQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const [riskResult] = useGetRiskQuery({ variables: { userId: userId ?? "" } });

  const recommendationGroups =
    planResult?.data?.plan?.recommendationGroups ?? [];

  const retirementRecommendation = recommendationGroups.find(
    (group) => group.recommendationType === RecommendationType.Retirement
  );

  const summary = retirementRecommendation?.summary as
    | RetirementSummary
    | null
    | undefined;

  const calculatedProfile = riskResult.data?.risk.calculatedProfile;

  const notApproved =
    planResult?.data?.plan?.authorisationStatus !==
    AuthorisationStatus.AdviceApproved;

  const errorGenerated = planResult?.error;

  return (
    <div className="w-full flex justify-center">
      {planResult.fetching ? (
        <Spinner className="w-60 h-60 my-32" />
      ) : errorGenerated || notApproved ? (
        <PlanStatusCard
          planResult={planResult.data?.plan}
          error={planResult?.error?.message || null}
        />
      ) : (
        <ContainerMidSize>
          <h1 className="text-t21 sm:text-t34 text-font-primary mb-12">
            Retirement
          </h1>

          <h2 className="text-t12 sm:text-t27 text-font-tertiary mb-56">
            Plan ahead so you can afford the lifestyle you want when you retire
          </h2>

          <div className="bg-input-border w-full h-2 mb-56" />

          <h1 className="sm:text-t27 text-font-primary mb-32">Overview</h1>

          <section className="sm:flex space-y-40 sm:space-y-0 items-stretch gap-x-48 mb-32">
            <YouToldUs
              title="You told us"
              className="flex-1"
              text={retirementRecommendation?.factfindSummary}
            />

            <YourProducts
              title="Your retirement assets"
              productSummary={retirementRecommendation?.relatedProducts}
            />
          </section>

          <section className="mb-60">
            <p className="text-t12 mb-24 sm:text-t21 text-font-secondary">
              This means
            </p>
            {summary && (
              <ThisMeansRetirement
                currentProvision={summary?.currentProvision}
                realisticProvision={summary?.realisticProvision}
                targetProvision={summary?.targetProvision}
                breakdown={summary?.breakdown}
              />
            )}
          </section>

          <section className="mb-60 space-y-32">
            <h3 className="sm:text-t27 text-font-primary mb-32">
              Risk profile
            </h3>

            {riskResult.fetching ? (
              <Card className="flex items-center justify-center p-40">
                <Spinner className="w-60 h-60 my-32" />
              </Card>
            ) : calculatedProfile === null ? (
              <RiskProfileCard />
            ) : (
              <Card className="p-40">
                <p className="text-font-secondary text-t12 sm:text-t16 mb-8">
                  Your profile is:
                </p>
                <h1 className="text-font-primary text-t21 sm:text-t27 mb-24">
                  {calculatedProfile?.name}
                </h1>
                <p className="text-font-primary text-t12 sm:text-t16 mb-24">
                  {calculatedProfile?.description}
                </p>
                {/* TODO link to book adviser */}
                <p className="text-font-primary text-t12 sm:text-t16 ">
                  If at any point you believe your attitude to risk has changed,
                  we recommend speaking to one of our advisers.
                </p>
              </Card>
            )}
          </section>

          {(retirementRecommendation?.recommendations?.length ?? 0) > 0 && (
            <section className="mb-60 space-y-32">
              {retirementRecommendation?.recommendations && (
                <RecommendationCarousel
                  recommendations={retirementRecommendation?.recommendations}
                  title="Recommendations"
                />
              )}
            </section>
          )}

          <section>
            <BookAdviserCall hasPlan={true} />
          </section>
        </ContainerMidSize>
      )}
    </div>
  );
};

export { Retirement };
