import { useAuth } from "@multiply/lib";
import { useGetPersonQuery } from "../../graphqlTypes";
import { dateToAge } from "../../utils";

const useUserPastAdviceAge = () => {
  const { userId, loggedIn } = useAuth();

  const [personResult] = useGetPersonQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const userPastAdviceAge = personResult.data?.person?.dateOfBirth
    ? dateToAge(personResult.data?.person?.dateOfBirth) > 59
    : false;

  return {
    userPastAdviceAge,
    loading: personResult.fetching,
    error: personResult.error,
  };
};

export { useUserPastAdviceAge };
