import { useNavigate } from "react-router-dom";
import { PensionsPaymentFactfindPage } from "../PensionsPaymentFactfindPage";
import { PensionsPageTemplate } from "../../components";

const PensionsPayment = () => {
  const navigate = useNavigate();
  const onDone =  () => {
    return navigate("/pensions/application-confirmation");
  };

  return (
    <PensionsPageTemplate backButton={true}>
      <PensionsPaymentFactfindPage
        onDone={onDone}
        context="pensions_execution_payment_review"
        title="Almost there, we just need a few more details"
      />
    </PensionsPageTemplate>
  );
};

export { PensionsPayment };
