import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";
import {
  IconProps,
  getDimensionsFromSize,
  getColorFromVariant,
} from "./shared";
import { useId } from "../../hooks";

const NotificationIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);

  const id = useId();

  return (
    <svg
      aria-label={props["aria-label"] ?? "notification icon"}
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <mask id={id}>
          <rect fill="#000" width="100%" height="100%" />
          <path
            d="M14.0055 11.9317V12.6135H1.73279V11.9317L3.09642 10.5681V6.47718C3.09642 4.36354 4.48051 2.50218 6.50551 1.90218V1.70446C6.50551 1.3428 6.64918 0.995951 6.90491 0.74022C7.16064 0.484489 7.50749 0.34082 7.86915 0.34082C8.23081 0.34082 8.57765 0.484489 8.83338 0.74022C9.08911 0.995951 9.23278 1.3428 9.23278 1.70446V1.90218C11.2578 2.50218 12.6419 4.36354 12.6419 6.47718V10.5681L14.0055 11.9317ZM9.23278 13.2954C9.23278 13.657 9.08911 14.0039 8.83338 14.2596C8.57765 14.5153 8.23081 14.659 7.86915 14.659C7.50749 14.659 7.16064 14.5153 6.90491 14.2596C6.64918 14.0039 6.50551 13.657 6.50551 13.2954M13.1532 1.15218L12.1851 2.12036C12.7617 2.68997 13.2196 3.36838 13.5321 4.11627C13.8446 4.86415 14.0055 5.66663 14.0055 6.47718H15.3691C15.3691 4.47945 14.5782 2.55673 13.1532 1.15218M0.369154 6.47718H1.73279C1.73279 4.84082 2.38733 3.27264 3.55324 2.12036L2.58506 1.15218C1.88134 1.84871 1.32306 2.67814 0.942659 3.59228C0.562255 4.50642 0.36731 5.48705 0.369154 6.47718V6.47718Z"
            fill="#fff"
          />
        </mask>
      </defs>
      <rect width={16} height={16} rx={8} fill={fill} mask={`url(#${id})`} />
    </svg>
  );
};

export { NotificationIcon };
