import classNames from "classnames";
import {
  eachDayOfInterval,
  endOfWeek,
  format,
  formatISO,
  isWithinInterval,
  startOfDay,
  startOfWeek,
} from "date-fns";
import chunk from "lodash/chunk";
import isEqual from "lodash/isEqual";

const startOnMonday = (index: number) => (index + 6) % 7;

const DAY_HEADERS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export type DayPickerProps = {
  className?: string;
  onChange: (value: Date) => void;
  value: Date | null;
  /**
   * An array of selectable dates
   */
  days: Date[];
  start: Date;
  end: Date;
  loading?: boolean;
  label?: string;
};

const DayPicker = ({
  days,
  className,
  onChange,
  value,
  start,
  end,
  loading,
  label,
}: DayPickerProps) => {
  const daysWithoutTimes = days.map((day) => startOfDay(day));

  const largestDayOfWeek = 4;
  const firstDay = startOfDay(start);
  const lastDay = startOfDay(end);

  const firstMonday = firstDay
    ? startOfWeek(firstDay, { weekStartsOn: 1 })
    : null;
  const lastSunday = endOfWeek(lastDay, { weekStartsOn: 1 });

  const weekDays =
    firstMonday && lastSunday
      ? eachDayOfInterval({ start: firstMonday, end: lastSunday })
      : [];

  const nonEmptyWeekDays = weekDays.filter(
    (day) => startOnMonday(day.getDay()) <= largestDayOfWeek
  );

  const weeks = chunk(nonEmptyWeekDays, largestDayOfWeek + 1);

  return (
    <fieldset aria-label="day options" className={className}>
      <table className="border-spacing-16 border-separate">
        <thead>
          <tr className="text-font-secondary-grey uppercase text-t12 font-bold">
            {Array(largestDayOfWeek + 1)
              .fill(null)
              .map((v, index) => (
                <th key={DAY_HEADERS[index]}>{DAY_HEADERS[index]}</th>
              ))}
          </tr>
        </thead>

        <tbody className="text-center text-t16 text-font-primary">
          {weeks.map((week, index) => (
            <tr key={index}>
              {week.map((weekDay) => {
                const key = formatISO(weekDay);

                if (!isWithinInterval(weekDay, { start, end })) {
                  return <td key={key}></td>;
                }

                const formattedDay = format(weekDay, "d");
                const isAnOption =
                  daysWithoutTimes.findIndex((day) => isEqual(weekDay, day)) >
                  -1;
                const isSelected = isEqual(weekDay, value);

                return (
                  <td key={key}>
                    <button
                      disabled={!isAnOption || loading}
                      className={classNames(
                        "bg-picker text-brand-900 font-semibold w-32 h-32 flex items-center justify-center rounded",
                        {
                          "animate-pulse": loading,
                          "text-radio-disabled bg-picker-unavailable":
                            !isAnOption,
                          " hover:bg-picker-selected hover:text-white":
                            isAnOption,
                          "text-white bg-picker-selected":
                            isSelected && isAnOption,
                        }
                      )}
                      onClick={() => onChange(weekDay)}
                    >
                      {formattedDay}
                    </button>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </fieldset>
  );
};

export { DayPicker };
