import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Button,
  ButtonLink,
  Card,
  Spinner,
  InputYesNo,
  NumberInput,
  ArrowRightCircle,
} from "@multiply/lib";
import { Controller, useForm } from "react-hook-form";
import { InputAccommodation } from "./InputAccommodation";
import { InputCostsInclude } from "./InputCostsInclude";
import { InputCourseDuration } from "./InputCourseDuration";
import { AccommodationType, WhatToIncludeType } from "../../graphqlTypes";
import classNames from "classnames";

export type CollegeCostsValues = {
  ageOfChild: number;
  courseDuration: number;
  freeFees: boolean;
  accommodation: AccommodationType;
  costsInclude: WhatToIncludeType[];
};

const validationSchema = yup.object().shape({
  ageOfChild: yup
    .number()
    .integer("Please round down to their last birthday")
    .min(0)
    .max(18, "Must be no more than 18 years old")
    .nullable()
    .required("Please provide an answer"),
  courseDuration: yup.number().nullable().required("Please provide an answer"),
  freeFees: yup.boolean().nullable().required("Please provide an answer"),
  accommodation: yup
    .string()
    .oneOf(Object.values(AccommodationType))
    .nullable()
    .required("Please provide an answer"),
  costsInclude: yup
    .array()
    .of(yup.string().oneOf(Object.values(WhatToIncludeType)))
    .min(1)
    .required("Please provide an answer"),
});

type InputCollegeCostsFormProps = {
  defaultValues: Partial<CollegeCostsValues>;
  onSubmit: (values: any) => void;
  modal?: boolean | false;
};

const InputCollegeCostsForm = ({
  defaultValues,
  onSubmit,
  modal,
}: InputCollegeCostsFormProps) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting, isValid },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const watchAccommodation = watch("accommodation");
  const homeAccommodation = watchAccommodation === AccommodationType.Home;

  return (
    <section>
      <Card
        variant={modal ? "flat" : "floating"}
        className={classNames("w-full space-y-24 mb-60 ", {
          "p-40 sm:w-704": !modal,
        })}
      >
        <Controller
          control={control}
          name="ageOfChild"
          render={({ field: { value, onChange, onBlur }, fieldState }) => (
            <NumberInput
              className="w-full sm:w-420"
              label="How old is your child?"
              placeholder="Enter age"
              value={value ?? undefined}
              onChange={onChange}
              onBlur={onBlur}
              error={fieldState.error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="courseDuration"
          render={({ field: { value, onChange, onBlur }, fieldState }) => (
            <InputCourseDuration
              className="w-full sm:w-420"
              value={value ?? null}
              onChange={onChange}
              onBlur={onBlur}
              error={fieldState.error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="freeFees"
          render={({ field: { value, onChange, onBlur }, fieldState }) => (
            <InputYesNo
              className="w-full sm:w-420"
              label={
                <>
                  <p>Is your child likely to be eligible for free fees?</p>
                  <ButtonLink
                    variant="plain"
                    className="pl-0 py-0 font-normal"
                    to="https://www.citizensinformation.ie/en/education/third_level_education/fees_and_supports_for_third_level_education/fees.html"
                    target="_blank"
                    size="sm"
                  >
                    I'm not sure
                  </ButtonLink>
                </>
              }
              value={value ?? null}
              onChange={onChange}
              onBlur={onBlur}
              error={fieldState.error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="accommodation"
          render={({ field: { value, onChange, onBlur }, fieldState }) => (
            <InputAccommodation
              className="w-full sm:w-420"
              value={value ?? null}
              onChange={onChange}
              onBlur={onBlur}
              error={fieldState.error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="costsInclude"
          render={({ field: { value, onChange, onBlur }, fieldState }) => (
            <InputCostsInclude
              homeAccommodation={homeAccommodation}
              className="w-full sm:w-420"
              value={value ?? null}
              onChange={onChange}
              onBlur={onBlur}
              error={fieldState.error?.message}
            />
          )}
        />

        {isSubmitting ? (
          <Spinner />
        ) : (
          <Button
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid || isSubmitting}
            iconRight={<ArrowRightCircle />}
            className="sm:text-t18 w-fit min-w-0 mt-18"
            id="input-college-costs-form-submit"
          >
            Calculate
          </Button>
        )}
      </Card>
    </section>
  );
};

export { InputCollegeCostsForm };
