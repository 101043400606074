import { ToolProps } from "./Tools";
import { Button, Card, NotificationIcon } from "@multiply/lib";

type ToolTileProps = {
  tool: ToolProps;
  onClick: (toolType: string) => void;
};

const ToolTile = ({ tool, onClick }: ToolTileProps) => {
  const {
    title,
    subTitle,
    imageUrl,
    buttonDetails,
    notificationSet = false,
  } = tool;
  return (
    <Card className="flex flex-col sm:flex-row h-400 sm:h-288 w-full sm:max-w-620">
      <div
        className="sm:hidden h-216 w-full rounded-t-lg bg-cover bg-center"
        style={{ backgroundImage: `url(${imageUrl ?? ""})` }}
      ></div>

      <div
        className="w-full sm:w-7/12 px-32 py-32 sm:my-auto"
        aria-label={`Tool for ${title}`}
      >
        <h1 className="text-font-primary text-t21 sm:text-t27 pb-8 sm:pb-16">
          {title}
        </h1>
        <p className="text-t16 text-font-secondary mb-24">{subTitle}</p>

        {buttonDetails ? (
          <>{buttonDetails}</>
        ) : (
          <Button
            size="sm"
            aria-label={`Notify me when ${title} tool becomes available`}
            onClick={() => onClick(title)}
            disabled={notificationSet ?? false}
            className="font-semibold"
            iconRight={<NotificationIcon size="sm" />}
          >
            {notificationSet ? "Notification set" : "Notify me when available"}
          </Button>
        )}
      </div>

      <div
        className="hidden sm:inline-block w-5/12 rounded-t-lg sm:rounded-r-lg sm:rounded-tl-none bg-cover bg-center"
        style={{ backgroundImage: `url(${imageUrl ?? ""})` }}
      ></div>
    </Card>
  );
};

export { ToolTile };
