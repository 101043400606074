import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { GoalType } from "../../graphqlTypes";
import { goalValidationSchema } from "../useGoalsForm";

export type GoalFormValues = {
  name: string | null;
  goalType?: GoalType | null;
  targetDate: string | undefined;
  valueToSave: number | null;
};

const addGoalValidationSchema: yup.SchemaOf<GoalFormValues> =
  goalValidationSchema
    .concat(
      yup.object().shape({
        name: yup.string().required("Please provide a name").nullable(),
      })
    )
    .defined();

type UseGoalsFormValuesArgs = {
  defaultValues?: Partial<GoalFormValues>;
};

const useAddGoalForm = (args?: UseGoalsFormValuesArgs) => {
  const defaultValues = args?.defaultValues;

  const form = useForm<GoalFormValues>({
    defaultValues: {
      name: defaultValues?.name ?? "",
      goalType: defaultValues?.goalType ?? GoalType.Custom,
      targetDate: defaultValues?.targetDate,
      valueToSave: defaultValues?.valueToSave ?? null,
    },
    mode: "all",
    resolver: yupResolver(addGoalValidationSchema),
    delayError: 1000,
  });

  return form;
};

export { useAddGoalForm, addGoalValidationSchema };
