import { FC } from "react";
import classNames from "classnames";
import { defaultClassnames } from "../defaultClassNames";
import {
  getColorFromVariant,
  getDimensionsFromSize,
  IconProps,
} from "../shared";
import { useId } from "../../../hooks";

const PinPiggyBankIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);
  const radialGradient = useId();
  return (
    <svg
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 39 49"
      fill="none"
      data-testid="pin piggy bank icon"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={fill}
        stroke={`url(#${radialGradient})`}
        strokeWidth={2.016}
        d="M37.12 19.185c0 2.419-.851 5.391-2.267 8.546-1.409 3.138-3.34 6.377-5.412 9.302-2.075 2.928-4.272 5.515-6.201 7.358-.966.923-1.844 1.64-2.59 2.118-.78.503-1.28.655-1.522.655s-.742-.152-1.523-.655c-.745-.479-1.623-1.195-2.589-2.118-1.929-1.843-4.126-4.43-6.2-7.358-2.074-2.925-4.004-6.164-5.413-9.302-1.416-3.155-2.267-6.127-2.267-8.546 0-9.944 8.056-18.005 17.992-18.005 9.936 0 17.992 8.06 17.992 18.005Z"
      />
      <path
        stroke="#fff"
        strokeWidth={1.134}
        d="M21.003 14.48c-2.063-.689-7.5.375-7.5 4.315 0 1.876.937 2.814 1.687 3.19 0 0-.562 1.313.563 1.876s2.062-.75 2.062-.75.657.187 1.313.187c.656 0 1.312-.188 1.312-.188s.563 1.314 1.875.75c.938-.562.563-1.688.563-1.688s1.125-.563 1.312-1.5c1.313 0 1.313-.376 1.313-.751v-2.064c0-.669 0-.938-1.125-.938-.188-.563-.75-1.126-.938-1.314 0-.938 0-1.313.188-2.251-1.125 0-2.625 1.125-2.625 1.125Zm-4.688.492c-.75-.375-.937-3.12 1.313-3.307 2.437 0 2.195 2.622 1.938 2.62-1.125.007-2.313.312-3.25.687Z"
      />
      <path
        stroke="#fff"
        strokeWidth={1.134}
        d="M13.69 17.857c-.687-.063-1.874-.563-1.124-1.689"
      />
      <circle cx={22.69} cy={18.232} r={0.563} fill="#fff" />
      <defs>
        <radialGradient
          id={radialGradient}
          cx={0}
          cy={0}
          r={1}
          gradientTransform="matrix(0 19.013 -19 0 19.128 19.185)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.839} stopColor="#fff" />
          <stop offset={1} stopColor="#ECEFFF" />
        </radialGradient>
      </defs>
    </svg>
  );
};
export { PinPiggyBankIcon };
