import { yupResolver } from "@hookform/resolvers/yup";
import pick from "lodash/pick";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { PolicyField } from "../../globalTypes";
import { CoverType, PaymentFrequency, PolicyType } from "../../graphqlTypes";
import { transformDate } from "../../utils";

export type DefaultPolicyFormValues = {
  owners: string[] | null;
  renewalDate: Date | null;
};

const policyValidationFields = {
  renewalDate: yup
    .date()
    .transform((value, originalValue) => transformDate(originalValue))
    .typeError('Please enter a valid date in the format "DD-MM-YYYY"')
    .min(new Date(), "The renewal date must be in the future")
    .nullable(),
  name: yup.string().nullable(),
  owners: yup
    .array()
    .of(yup.string())
    .min(1, "Please provide an answer")
    .required("Please provide an answer")
    .nullable(),
  productType: yup
    .string()
    .oneOf(Object.values(PolicyType))
    .required("Please provide an answer")
    .nullable(),
  provider: yup.string().nullable(),
  isOnePlan: yup.boolean().required("Please provide an answer").nullable(),
  isIrishLifePolicy: yup
    .boolean()
    .required("Please provide an answer")
    .nullable(),
  throughCurrentEmployer: yup
    .boolean()
    .required("Please provide an answer")
    .nullable(),
  amountOfCover: yup
    .number()
    .min(0, "Amount must be at least 0")
    .required("Please provide an answer")
    .nullable(),
  yearsRemaining: yup
    .number()
    .when("coverType", {
      is: (coverType: string) => coverType !== CoverType.WholeOfLife,
      then: (schema) => schema.required("Please provide an answer"),
    })
    .nullable(),
  premium: yup
    .number()
    .min(0, "Amount must be at least 0")
    .when("throughCurrentEmployer", {
      is: false,
      then: (schema) => schema.required("Please provide an answer"),
    })
    .nullable(),
  paymentFrequency: yup
    .string()
    .oneOf([...Object.values(PaymentFrequency), null])
    .when("throughCurrentEmployer", {
      is: false,
      then: (schema) => schema.required("Please provide an answer"),
    })
    .nullable(),
  coverType: yup
    .string()
    .oneOf(Object.values(CoverType))
    .required("Please provide an answer")
    .nullable(),
};

type UsePolicyFormArgs = {
  fields: PolicyField[];
  defaultValues?: any;
};

const usePolicyForm = ({ fields, defaultValues }: UsePolicyFormArgs) => {
  const fieldNames = fields.map((field) =>
    typeof field === "string" ? field : field.fieldName
  );

  const validationSchema = useMemo(() => {
    const validationFields = fieldNames.reduce((validators, fieldName) => {
      return { ...validators, [fieldName]: policyValidationFields[fieldName] };
    }, {});

    return yup.object().shape(validationFields).defined();
  }, [fieldNames]);

  return useForm({
    delayError: 1000,
    mode: "onChange",
    defaultValues: pick(defaultValues, fieldNames),
    resolver: yupResolver(validationSchema),
  });
};

export { usePolicyForm };
