import React from "react";

const DowntimeWrapper = ({
  children,
  isDown,
}: {
  isDown: boolean;
  children: React.ReactNode;
}) => {
  if (isDown) {
    return (
      <main className="h-screen w-full flex flex-col items-center justify-center">
        <h1 className="text-font-primary text-t21 sm:text-t34 mb-12">
          Maintenance in progress
        </h1>
        <p className="text-font-secondary text-t12 sm:text-t18">
          Sorry, we're currently performing maintenance on our systems. Please
          try again later.
        </p>
      </main>
    );
  }

  return <>{children}</>;
};

export { DowntimeWrapper };
