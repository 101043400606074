import { FC } from "react";
import { defaultClassnames } from "../defaultClassNames";
import classNames from "classnames";
import {
  IconProps,
  getDimensionsFromSize,
  getColorFromVariant,
} from "../shared";
import { useId } from "../../../hooks";

const PinHomeIcon: FC<IconProps> = ({ size, variant, className, ...props }) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);
  const radialGradient = useId();
  return (
    <svg
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 39 49"
      fill="none"
      data-testid="pin home icon"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M37.1262 19.1851C37.1262 21.6051 36.2748 24.5785 34.8585 27.7341C33.45 30.8723 31.5193 34.111 29.4459 37.0372C27.3714 39.9647 25.1736 42.552 23.2443 44.3953C22.2781 45.3184 21.3996 46.0354 20.6544 46.5148C19.8727 47.0177 19.3722 47.1704 19.1279 47.1704C18.8837 47.1704 18.3832 47.0177 17.6015 46.5148C16.8563 46.0354 15.9777 45.3184 15.0115 44.3953C13.0823 42.552 10.8845 39.9647 8.81001 37.0372C6.73653 34.111 4.80582 30.8723 3.39735 27.7341C1.9811 24.5785 1.12964 21.6051 1.12964 19.1851C1.12964 9.23711 9.1884 1.17383 19.1279 1.17383C29.0675 1.17383 37.1262 9.23711 37.1262 19.1851Z"
        fill={fill}
        stroke={`url(#${radialGradient})`}
        strokeWidth="2.00343"
      />
      <path
        d="M16.8107 25.7783H14.9034C14.3767 25.7783 13.9497 25.3511 13.9497 24.824V20.0525"
        stroke="white"
        strokeWidth="1.52729"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0425 19.0984L17.6252 14.3714C17.9808 14.0702 18.5017 14.0702 18.8573 14.3714L23.4851 18.2897"
        stroke="white"
        strokeWidth="1.52729"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5791 14.3267H23.4864V16.2353"
        stroke="white"
        strokeWidth="1.52729"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.509 24.9355C21.5772 25.0037 21.5976 25.1064 21.5608 25.1956C21.5239 25.2847 21.4369 25.3429 21.3405 25.3429C21.244 25.3429 21.1571 25.2847 21.1202 25.1956C21.0833 25.1064 21.1037 25.0037 21.172 24.9355C21.2166 24.8907 21.2772 24.8655 21.3405 24.8655C21.4037 24.8655 21.4643 24.8907 21.509 24.9355"
        stroke="white"
        strokeWidth="1.52729"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7002 24.1657L26.0442 21.82C26.448 21.416 26.448 20.7608 26.0442 20.3568C25.6404 19.9527 24.9858 19.9527 24.582 20.3568L22.2398 22.7025C21.1019 22.2566 19.8075 22.6702 19.1385 23.6935C18.4695 24.7168 18.6093 26.0694 19.4734 26.9341C20.3374 27.7987 21.6891 27.9386 22.7117 27.2691C23.7343 26.5997 24.1477 25.3044 23.7021 24.1657H23.7002Z"
        stroke="white"
        strokeWidth="1.52729"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <radialGradient
          id={radialGradient}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(19.1279 19.1851) rotate(90) scale(19.013 19)"
        >
          <stop offset="0.838542" stopColor="white" />
          <stop offset="1" stopColor="#ECEFFF" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export { PinHomeIcon };
