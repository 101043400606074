import classNames from "classnames";
import { forwardRef } from "react";

type CardProps = {
  id?: string;
  className?: string;
  variant?: "flat" | "floating";
  children: React.ReactNode;
  testId?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  ariaLabel?: string;
};

const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      children,
      className,
      testId,
      onClick,
      id,
      variant = "floating",
      style,
      ariaLabel,
    },
    ref
  ) => {
    const bgInClassName = className?.includes("bg-");

    return (
      <div
        id={id}
        data-testid={testId}
        aria-label={ariaLabel}
        ref={ref}
        className={classNames(
          { "rounded-lg shadow-primary": variant === "floating" },
          className,
          // default setting for bg
          { "bg-white": !bgInClassName }
        )}
        onClick={onClick}
        style={style}
      >
        {children}
      </div>
    );
  }
);

export { Card };
