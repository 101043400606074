import { GoalIcon, GoalProgress as GoalProgressChart } from "../../components";
import { BreakdownSlice, GoalProgress } from "../../graphqlTypes";
import { Card, useWindowDimensions, PiggyBankOutlineIcon } from "@multiply/lib";
import { SavingsGraph } from "./SavingsGraph";

type ThisMeansSavingsAndInvestingProps = {
  cashAndSavingsData?: BreakdownSlice;
  investmentsData?: BreakdownSlice;
  otherData?: BreakdownSlice;
  currentMonthsSaved: number;
  recommendedProtectedMonths: number;
  currentProvision: string;
  recommendedProvision: string;
  progress: number;
  goals?: any[];
};

const ThisMeansSavingsAndInvesting = ({
  cashAndSavingsData,
  investmentsData,
  otherData,
  currentMonthsSaved,
  recommendedProtectedMonths,
  currentProvision,
  recommendedProvision,
  progress,
  goals,
}: ThisMeansSavingsAndInvestingProps) => {
  const { isPhone } = useWindowDimensions();

  return (
    <Card className="p-60">
      <SavingsGraph
        portions={[
          cashAndSavingsData?.amount.float ?? 0,
          investmentsData?.amount?.float ?? 0,
          otherData?.amount?.float ?? 0,
        ]}
      />
      <div className="flex gap-16 sm:gap-60 my-36">
        <div>
          <div className="bg-brand-primary w-12 h-12 sm:w-24 sm:h-24 mb-12" />
          <p className="text-font-primary text-t12 sm:text-t27">
            {cashAndSavingsData?.name}
          </p>
          <p className="text-font-secondary text-t10 sm:text-t27">
            {cashAndSavingsData?.amount.display}
          </p>
        </div>
        <div>
          <div className="bg-brand-secondary w-12 h-12 sm:w-24 sm:h-24 mb-12" />
          <p className="text-font-primary text-t12 sm:text-t27">
            {investmentsData?.name}
          </p>
          <p className="text-font-secondary text-t10 sm:text-t27">
            {investmentsData?.amount.display}
          </p>
        </div>
        <div>
          <div className="bg-brand-tertiary w-12 h-12 sm:w-24 sm:h-24 mb-12" />
          <p className="text-font-primary text-t12 sm:text-t27">
            {otherData?.name}
          </p>
          <p className="text-font-secondary text-t10 sm:text-t27">
            {otherData?.amount.display}
          </p>
        </div>
      </div>
      <div className="flex justify-between items-center py-60 border-y-2 border-background-primary">
        <div className="flex items-center">
          <p className="text-font-primary sm:font-bold text-t12 sm:text-t21 pr-8">
            Emergency fund
          </p>
        </div>
        <GoalProgressChart
          variant="flat"
          icon={
            !isPhone ? (
              <PiggyBankOutlineIcon size="lg" className="text-icon-display" />
            ) : undefined
          }
          title={`${currentMonthsSaved} of ${recommendedProtectedMonths} month${
            recommendedProtectedMonths !== 1 ? "s" : ""
          }`}
          subtitle={`${currentProvision} out of ${recommendedProvision}`}
          progress={progress}
        />
      </div>
      <p className="mt-36 text-font-primary sm:font-bold mb-24 text-t12 sm:text-t21">
        Goals
      </p>
      <div className="flex gap-32 flex-wrap">
        {(goals ?? []).map((goal: GoalProgress) => {
          return (
            <GoalProgressChart
              variant="flat"
              className="w-fit"
              icon={<GoalIcon size="lg" goalType={goal.goalType} />}
              title={goal?.name ?? ""}
              subtitle={`${goal?.amountSaved.display} out of ${goal?.targetAmount.display}`}
              progress={goal?.progress}
            />
          );
        })}
      </div>
    </Card>
  );
};

export { ThisMeansSavingsAndInvesting };
