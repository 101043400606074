import { v4 as uuid } from "@lukeed/uuid";
import { Card } from "@multiply/lib";
import classNames from "classnames";
import React, { forwardRef, ReactElement, ReactNode, useRef } from "react";

type ConversationCardTemplateProps = {
  title?: string;
  preTitle?: string;
  subtitle?: string | ReactElement;
  error?: string;
  helperText?: React.ReactNode;
  className?: string;
  id?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  footer?: string | ReactNode;
};

const ConversationCardTemplate = forwardRef(
  (
    {
      children,
      title,
      preTitle,
      subtitle,
      helperText,
      footer,
      error,
      className,
      id,
    }: ConversationCardTemplateProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const labelId = useRef(uuid()).current;

    return (
      <Card
        id={id}
        ref={ref}
        className={classNames(
          `conversation-card w-full relative py-32 px-20 sm:py-56 sm:px-52 flex flex-col`,
          className
        )}
      >
        <fieldset aria-labelledby={labelId}>
          <div>
            {preTitle ? (
              <div className="text-font-secondary text-t16 sm:text-t21 pb-8">
                {preTitle}
              </div>
            ) : null}

            {title ? (
              <h1
                id={labelId}
                className="text-font-primary text-t21 sm:text-t27 pb-20"
              >
                {title}
              </h1>
            ) : null}

            {subtitle ? (
              <div className="text-font-secondary text-t16 sm:text-t18">
                {subtitle}
              </div>
            ) : null}

            {helperText ? (
              <div className="text-action-error text-t16 sm:text-t18">
                {helperText}
              </div>
            ) : null}

            {error ? (
              <div className="text-action-error text-t16 sm:text-t18">
                {error}
              </div>
            ) : null}
          </div>

          <div className="relative mt-32 flex flex-col justify-end flex-1 gap-y-14">
            {children}
          </div>

          {footer && (
            <div className="mt-32 text-font-secondary text-t16 sm:text-t18">
              {footer}
            </div>
          )}
        </fieldset>
      </Card>
    );
  }
);

export { ConversationCardTemplate };
