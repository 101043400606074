import { Button, Slider } from "@multiply/lib";

const DEFAULT_RETIREMENT_AGE = 66;

type SliderInputProps = {
  value: number | null;
  onChange: (value: number | null) => void;
  onBlur: () => void;
  onSubmit?: (element?: Element) => void;
  error?: string;
  confirmButton?: boolean;
  min: number;
  max: number;
  disabled?: boolean;
};

const SliderInput = ({
  onChange,
  onBlur,
  onSubmit,
  value,
  error,
  confirmButton = true,
  min,
  max,
  disabled,
}: SliderInputProps) => {
  return (
    <>
      <div className="w-full px-32">
        <p className="font-bold text-t27 text-center mb-60">
          {value ?? DEFAULT_RETIREMENT_AGE}
        </p>

        <Slider
          min={min}
          max={max}
          value={value}
          onChange={(value) => onChange(Number(value))}
          disabled={disabled}
        />

        <div className="flex justify-between w-full mt-16 mb-32">
          <p className="block">{min}</p>
          <p className="block">{max}</p>
        </div>

        {confirmButton && (
          <div className="w-full flex justify-start">
            <Button
              className="px-40"
              onClick={(event) => {
                if (event.target instanceof Element && onSubmit) {
                  onSubmit(event.target);
                }
              }}
              onBlur={() => {
                if (onBlur) onBlur();
              }}
              variant="ghost"
              disabled={disabled}
            >
              Confirm
            </Button>
          </div>
        )}
      </div>
      {error ? <p className="text-action-error">{error}</p> : null}
    </>
  );
};

export { SliderInput };
