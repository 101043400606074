import { CalendarIcon, HouseIcon, MoreIcon, MenuLink } from "@multiply/lib";

const PensionsNav = () => {
  return (
    <>
      <MenuLink
        isPill
        aria-label="go to pension plan page"
        to="/pensions/pension-details"
      >
        <HouseIcon className="hidden lg:block h-auto w-24 lg:w-32" />
        <span>Financial plan</span>
      </MenuLink>

      <MenuLink
        isPill
        aria-label="go to holistic advice plan page"
        to="/pensions/holistic-upgrade"
        end={true}
      >
        <MoreIcon className="hidden lg:block h-auto w-24 lg:w-32" />
        <span>More advice</span>
      </MenuLink>

      <MenuLink
        isPill
        aria-label="go to appointments page"
        to="/pensions/appointments"
      >
        <CalendarIcon className="hidden lg:block h-auto w-24 lg:w-32" />
        <span>Appointments</span>
      </MenuLink>
    </>
  );
};

export { PensionsNav };
