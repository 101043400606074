import { formatISO } from "date-fns";

const parseProductDatesToISO = (productFields: any): Date => {
  let parseDateFilteredValues: any = {};
  for (const [key, value] of Object.entries(productFields)) {
    if (value instanceof Date) {
      parseDateFilteredValues[key] = formatISO(value as unknown as Date, {
        representation: "date",
      });
    }
  }
  return parseDateFilteredValues;
};

export { parseProductDatesToISO };
