import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";
import {
  getColorFromVariant,
  getDimensionsFromSize,
  IconProps,
} from "./shared";

const SirenInCircleIcon: FC<IconProps> = ({
  size,
  variant,
  className,
  ...props
}) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);

  return (
    <svg
      width={width}
      height={height}
      aria-hidden={true}
      className={classNames(defaultClassnames, className)}
      viewBox="0 0 59 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={29.676} cy={29.406} r={24.672} fill={fill} />
      <path
        d="M35.427 36.646V27.78a5.7 5.7 0 0 0-5.703-5.702 5.7 5.7 0 0 0-5.703 5.702v8.862h-3.183v3.204H36.12"
        fill="#5463D6"
        fillOpacity={0.2}
      />
      <path
        d="M35.427 36.646V27.78a5.7 5.7 0 0 0-5.703-5.702 5.7 5.7 0 0 0-5.703 5.702v8.862h-3.183v3.204H36.12"
        stroke="#5463D6"
        strokeWidth={1.863}
        strokeMiterlimit={10}
      />
      <path
        d="M27.25 27.786a2.477 2.477 0 0 1 2.478-2.478M29.729 14.99v3.2M16.934 27.787h3.2M39.328 27.787h3.2M36.52 20.99l2.262-2.258M22.934 20.99l-2.262-2.258M38.588 40.575v-3.913h-14.56"
        stroke="#5463D6"
        strokeWidth={1.863}
        strokeMiterlimit={10}
      />
      <circle
        cx={29.497}
        cy={29.238}
        r={28.071}
        stroke="#BEC7FF"
        strokeWidth={1.863}
      />
    </svg>
  );
};

export { SirenInCircleIcon };
