import { FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";
import {
  IconProps,
  getDimensionsFromSize,
  getColorFromVariant,
} from "./shared";

const SurveyIcon: FC<IconProps> = ({ size, variant, className, ...props }) => {
  const { width, height } = getDimensionsFromSize(size);
  const fill = getColorFromVariant(variant);

  return (
    <svg
      aria-label={props["aria-label"] ?? "survey icon"}
      className={classNames(defaultClassnames, className)}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.333 1.333v1.334h2.005c.365 0 .662.296.662.662v10.675a.663.663 0 0 1-.662.662H2.662A.663.663 0 0 1 2 14.004V3.33c0-.366.297-.662.662-.662h2.005V1.333h6.666ZM4.667 4H3.333v9.333h9.334V4h-1.334v1.333H4.667V4ZM6 10.666V12H4.667v-1.334H6Zm0-2V10H4.667V8.667H6Zm0-2V8H4.667V6.667H6Zm4-4H6V4h4V2.667Z"
        fill={fill}
      />
    </svg>
  );
};

export { SurveyIcon };
