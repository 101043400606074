import { FieldError } from "react-hook-form";
import { TextInput } from "../TextInput";

type InputReferenceProps = {
  value: string | null;
  onChange: (value: string | null) => void;
  onBlur: () => void;
  className?: string;
  error?: FieldError | undefined;
};

const InputReference = ({
  value,
  onChange,
  onBlur,
  className,
  error,
}: InputReferenceProps) => {
  return (
    <TextInput
      className={className}
      label="Reference (optional)"
      placeholder="Enter reference"
      error={error?.message}
      value={value ?? ""}
      onChange={(e) => onChange(e.target.value)}
      onBlur={onBlur}
    />
  );
};
export { InputReference };
