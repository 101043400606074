import { Link } from "react-router-dom";
import { GoalProgress } from "../GoalProgress";
import { GoalPinIcon } from "../GoalPinIcon";
import {
  GoalType,
  RecommendationType,
  SavingsInvestingSummary,
  useGetPlanAdviceInfoQuery,
} from "../../graphqlTypes";
import { CURRENCY_SYMBOL } from "../../utils";
import { EmptyAddCard, useAuth, Spinner } from "@multiply/lib";

const GoalCardGroup = () => {
  const { userId, loggedIn } = useAuth();
  const [planResult] = useGetPlanAdviceInfoQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const recommendationGroups =
    planResult?.data?.plan?.recommendationGroups ?? [];

  const savingsAndInvestingRecommendation = recommendationGroups.find(
    (group) => group.recommendationType === RecommendationType.SavingsInvesting
  );

  const summary = savingsAndInvestingRecommendation?.summary as
    | SavingsInvestingSummary
    | null
    | undefined;

  const loading = planResult.fetching;
  const goals = summary?.goalProgress;

  const getGoalsError = planResult.error;

  if (getGoalsError) {
    return (
      <h1 className="text-action-error text-t16">{getGoalsError.message}</h1>
    );
  }

  return (
    <div className="w-full overflow-x-auto no-scrollbar flex flex-col shadow-none max-h-240 sm:max-h-full">
      {!loading ? (
        goals && goals.length > 0 ? (
          <>
            <div className="flex sm:flex-col gap-y-12 gap-x-12 sm:gap-y-12 sm:gap-x-0 ">
              {goals.map((goal) => (
                <Link key={goal?.name} to="/advice/plan/view-goals">
                  <GoalProgress
                    variant="flat"
                    icon={
                      <GoalPinIcon
                        className="-ml-6"
                        size="xl"
                        goalType={goal?.goalType ?? GoalType.Custom}
                      />
                    }
                    title={goal?.name ?? ""}
                    subtitle={` ${
                      goal?.amountSaved.display ?? `${CURRENCY_SYMBOL}0`
                    } saved out of ${
                      goal?.targetAmount?.display ?? `${CURRENCY_SYMBOL}0`
                    }`}
                    progress={goal?.progress ?? 0}
                    className="flex justify-center items-center py-16 w-280 sm:min-w-full sm:max-w-full bg-input-background rounded-lg"
                  />
                </Link>
              ))}
            </div>
            <div className="flex-1 flex items-start justify-start mt-16">
              <Link
                to="/advice/plan/view-goals"
                className="whitespace-nowrap text-font-links self-start text-t14 sm:text-t16"
              >
                Manage goals
              </Link>
            </div>
          </>
        ) : (
          <EmptyAddCard
            className="bg-[#f7f8fb]"
            to="/advice/plan/view-goals"
            title="Add a savings goal"
          />
        )
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export { GoalCardGroup };
