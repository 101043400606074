import { Link } from "react-router-dom";
import { Card } from "../Card";
import { ArrowHeadDownIcon } from "../Icons";
import { Roundel } from "../Roundel";
import { ThingsToDoPill } from "../ThingsToDoPill";

type RecommendationSummaryCardProps = {
  to: string;
  icon: React.ReactNode;
  thingsToDo: number;
  title: string;
  className?: string;
};

const RecommendationSummaryCard = ({
  to,
  icon,
  thingsToDo,
  title,
  className,
}: RecommendationSummaryCardProps) => {
  return (
    <Link to={to} className={className}>
      <Card className="pl-18 sm:pl-34 pr-34 flex items-center">
        <Roundel className="my-12 sm:my-18">{icon}</Roundel>

        <div className="ml-12 sm:ml-28 my-16 sm:my-32 flex-1">
          <h3 className="text-t16 sm:text-t25 font-semibold text-font-primary mb-6 sm:mb-12">
            {title}
          </h3>
          <ThingsToDoPill count={thingsToDo} />
        </div>

        <ArrowHeadDownIcon className="-rotate-90 sm:scale-150" />
      </Card>
    </Link>
  );
};

export { RecommendationSummaryCard };
