import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { TextInput } from "../../components";
import { Button, ContainerSmallSize, Spinner, useAuth } from "@multiply/lib";
import { useGetGoalQuery, useUpdateGoalMutation } from "../../graphqlTypes";

const EditGoalName = () => {
  const { goalId } = useParams();
  const { userId } = useAuth();

  const [goalResult] = useGetGoalQuery({
    variables: { userId: userId ?? "", goalId: goalId ?? "" },
  });
  const [updateGoalResult, updateGoal] = useUpdateGoalMutation();
  const error =
    goalResult.error?.message || updateGoalResult.error?.message || "";

  const navigate = useNavigate();

  const updateGoalName = async (name: string) => {
    try {
      const result = await updateGoal({
        input: {
          userId: userId ?? "",
          goalId,
          goal: {
            name,
          },
        },
      });

      if (result?.data?.updateGoal?.success) navigate(-1);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ContainerSmallSize>
      {goalResult?.fetching || !goalResult?.data?.goal ? (
        <div className="flex justify-center">
          <Spinner />
        </div>
      ) : (
        <EditGoalNameForm
          goalName={goalResult.data?.goal?.name ?? ""}
          onSubmit={updateGoalName}
        />
      )}
      {error ? <p className="text-action-error text-center">{error}</p> : null}
    </ContainerSmallSize>
  );
};

const schema = yup.object().shape({
  goalName: yup.string().required("Please provide a name").nullable(),
});

type EditGoalNameFormProps = {
  goalName: string;
  onSubmit: (name: string) => void;
};
const EditGoalNameForm = ({ goalName, onSubmit }: EditGoalNameFormProps) => {
  const { control, formState, handleSubmit } = useForm({
    defaultValues: { goalName },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const { isSubmitting, isValid } = formState;

  return (
    <form
      onSubmit={handleSubmit((data) => onSubmit(data.goalName))}
      className="flex flex-col w-full items-center sm:max-w-520"
    >
      <div className="space-y-24 sm:space-y-40 mt-0 sm:my-32 w-full sm:w-480">
        <h1 className="text-t21 sm:text-t27 text-font-primary text-center">
          Edit goal name
        </h1>

        <Controller
          control={control}
          name="goalName"
          render={({ field: { value, onChange, onBlur }, fieldState }) => (
            <TextInput
              placeholder="Enter name"
              error={fieldState?.error?.message}
              value={value ?? ""}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
      </div>

      <Button
        className="my-40 max-w-200 w-full"
        disabled={!isValid || isSubmitting}
        onClick={handleSubmit((data) => onSubmit(data.goalName))}
      >
        Update
      </Button>
    </form>
  );
};

export { EditGoalName };
