import {
  Button,
  ContainerMaxSize,
  Spinner,
  useAuth,
  useToaster,
} from "@multiply/lib";
import { Link } from "react-router-dom";
import babyCosts from "../../assets/images/baby-costs.png";
import firstTimeBuyer from "../../assets/images/first-time-buyer.png";
import retirementLifestyle from "../../assets/images/retirement-lifestyle.png";
import thirdLevelEducation from "../../assets/images/third-level-education.png";
import { ModalNavLink } from "../../components";
import {
  useGetToolsQuery,
  useSubscribeToToolMutation,
} from "../../graphqlTypes";
import { ToolTile } from "./ToolTile";

export type ToolProps = {
  title: string | "";
  subTitle: string | "";
  imageUrl: string | null;
  buttonDetails?: React.ReactNode;
  notificationSet?: boolean;
};

let toolsAvailable: ToolProps[] = [
  {
    title: "Third-level education calculator",
    subTitle:
      "Calculate what it could cost you to put a child through higher education.",
    imageUrl: thirdLevelEducation,
    buttonDetails: (
      <Link to="/tools/college-costs-calculator">
        <Button
          size="sm"
          aria-label="Go to college costs calculator"
          className="font-semibold"
        >
          Go to calculator
        </Button>
      </Link>
    ),
  },
  {
    title: "First time buyer calculator",
    subTitle: "Discover what type of property you could afford and when.",
    imageUrl: firstTimeBuyer,
  },
  {
    title: "Baby costs calculator",
    subTitle: "Work out what you'll need for your baby's arrival.",
    imageUrl: babyCosts,
  },
  {
    title: "Retirement lifestyle planner",
    subTitle: "Find out how much you'll need in retirement.",
    imageUrl: retirementLifestyle,
  },
];

const Tools = () => {
  const { userId } = useAuth();

  const [toolsResult] = useGetToolsQuery({
    variables: { userId: userId ?? "" },
    requestPolicy: "cache-and-network",
  });

  const { addToast } = useToaster();

  const [subscribeToToolResult, subscribeToTool] = useSubscribeToToolMutation();
  let error = subscribeToToolResult?.error?.message ?? null;

  const getNotificationStatus = (toolTitle: string) => {
    const toolsPreviouslySelected = toolsResult.data?.tools || null;
    if (!toolsPreviouslySelected || toolsPreviouslySelected === undefined)
      return false;
    const toolType = toolTitle.replaceAll(" ", "-").toLowerCase();
    if (toolsPreviouslySelected.includes(toolType)) return true;
    return false;
  };

  const setToolNotification = async (toolTitle: string) => {
    const toolType = toolTitle.replaceAll(" ", "-").toLowerCase();

    try {
      const result = await subscribeToTool({
        input: {
          userId: userId ?? "",
          tool: toolType ?? "",
        },
      });

      if (result.data?.subscribeToTool?.success) {
        addToast({ message: "Notification set", duration: 5000 });
      } else {
        error = result.error?.message ?? "";
      }
    } catch (error) {}
  };

  return (
    <ContainerMaxSize>
      <div className="flex gap-12 items-center mb-4">
        <h1 className="text-t20 sm:text-t27 text-font-primary">Tools</h1>
      </div>
      <p className="text-t16 sm:text-t21 text-font-secondary pb-40">
        Set and achieve your life goals with our tools
      </p>

      <section className="flex flex-wrap gap-40">
        {toolsResult.fetching ? (
          <Spinner />
        ) : (
          <>
            {toolsAvailable.map((tool) => {
              const { title } = tool;

              return (
                <ToolTile
                  key={tool.title}
                  tool={{
                    ...tool,
                    notificationSet: getNotificationStatus(title),
                  }}
                  onClick={setToolNotification}
                />
              );
            })}
          </>
        )}
      </section>

      {error ? <p className="text-action-error mt-12">{error}</p> : null}

      <p className="text-t16 sm:text-t21 text-font-secondary mt-36">
        Something else you'd like to see?{" "}
        <span>
          <ModalNavLink to="/cross/tools/suggest" className="text-font-links">
            Suggest a tool to add
          </ModalNavLink>
        </span>
      </p>
    </ContainerMaxSize>
  );
};

export { Tools };
