import classNames from "classnames";
import { ComponentProps } from "react";
import { Button, ButtonLink, Card } from "@multiply/lib";

interface SpotlightCardProps {
  className?: string;
  variant?: ComponentProps<typeof Card>["variant"];
  image?: React.ReactNode;
  title: React.ReactNode;
  subtitle: React.ReactNode;
  cta: string;
  disabled?: boolean;
  tip?: React.ReactNode;
  error?: string;
}

interface CardWithLinkProps extends SpotlightCardProps {
  to: string;
  onClick?: () => void;
}

interface CardWithButtonProps extends SpotlightCardProps {
  onClick: () => void;
  to?: undefined;
}

const SpotlightCard = ({
  className,
  variant,
  image,
  title,
  subtitle,
  to,
  onClick,
  cta,
  disabled,
  tip,
  error,
}: CardWithLinkProps | CardWithButtonProps) => {
  return (
    <Card
      variant={variant}
      className={classNames(
        "py-36 px-24 text-center flex flex-col items-center",
        className
      )}
    >
      <div className="w-full sm:max-w-3/4 flex flex-col items-center">
        {image && <div className="mb-24">{image}</div>}

        {typeof title === "string" ? (
          <h1 className="text-t27 text-font-primary sm:w-4/5">{title}</h1>
        ) : (
          <div className="text-t27 text-font-primary sm:w-4/5">{title}</div>
        )}

        {typeof subtitle === "string" ? (
          <h2 className="text-t16 text-font-tertiary mt-16">{subtitle}</h2>
        ) : (
          <div className="text-t16 text-font-tertiary mt-16">{subtitle}</div>
        )}

        {to && (
          <ButtonLink
            to={to}
            disabled={disabled}
            className="mt-32"
            onClick={onClick}
          >
            <span className="text-t21">{cta}</span>
          </ButtonLink>
        )}

        {!to && onClick && (
          <Button
            onClick={onClick}
            disabled={disabled}
            className="mt-32 w-fit min-w-200"
          >
            <span className="text-t21">{cta}</span>
          </Button>
        )}

        {error && <p className="mt-24 text-action-error test-t16">{error}</p>}

        {tip && <p className="text-t12 text-font-tertiary mt-24">{tip}</p>}
      </div>
    </Card>
  );
};

export { SpotlightCard };
