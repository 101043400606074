import { Button, useWindowDimensions, LockIcon } from "@multiply/lib";
import { Controller } from "react-hook-form";
import { IntroPhoneFormValues, useIntroPhoneForm } from "../../hooks";
import { InputCountryCode } from "./InputCountryCode";
import { InputMobileNumber } from "./InputMobileNumber";

type IntroFactfindPhoneFormProps = {
  defaultValues: IntroPhoneFormValues;
  error?: string;
  onSubmit: (values: IntroPhoneFormValues) => Promise<void>;
};

const IntroFactfindPhoneForm = ({
  defaultValues,
  error,
  onSubmit,
}: IntroFactfindPhoneFormProps) => {
  const { control, formState, handleSubmit } = useIntroPhoneForm({
    defaultValues,
  });
  const { isDirty, isSubmitting, isValid } = formState;
  const { isPhone } = useWindowDimensions();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      className="flex flex-col max-w-520"
    >
      <div className="max-h-fit w-full flex gap-8">
        <Controller
          control={control}
          name="countryCode"
          render={({ field, fieldState }) => {
            return (
              <InputCountryCode
                onBlur={() => {}}
                onChange={field.onChange}
                value={field.value ?? ""}
                error={fieldState?.error?.message}
              />
            );
          }}
        />

        <Controller
          control={control}
          name="mobileNumber"
          render={({ field, fieldState }) => {
            return (
              <InputMobileNumber
                onBlur={() => {}}
                onChange={field.onChange}
                value={field.value ?? ""}
                error={fieldState?.error?.message}
              />
            );
          }}
        />
      </div>

      <section className="w-full flex flex-col items-center mt-32 sm:mt-48">
        <div className="flex items-center justify-center gap-8">
          <LockIcon aria-label="lock icon" />
          <p className="text-t16 sm:text-t21 text-font-secondary">
            Data is encrypted and stored securely
          </p>
        </div>
        <Button
          disabled={!isValid || !isDirty || isSubmitting}
          onClick={handleSubmit(onSubmit)}
          aria-label="continue"
          className={`mt-32 sm:mt-48 ${isPhone ? "w-full" : "w-1/2"}`}
        >
          Continue
        </Button>
        {error ? (
          <p className="mt-12 text-action-error">
            Something went wrong, please contact support
          </p>
        ) : null}
      </section>
    </form>
  );
};

export { IntroFactfindPhoneForm };
