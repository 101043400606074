import { SVGProps } from "react";

const HealthyMinds = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M18.008 36c9.94 0 18-8.059 18-18s-8.06-18-18-18c-9.941 0-18 8.059-18 18s8.059 18 18 18Z"
        fill="url(#b)"
      />
      <path
        d="M14.75 20.677s-.229-.458-.648-.744c-.342-.233-.992-.229-1.45.134-.458.362-.677.96-.534 1.602.19.859.82 1.621 1.526 2.251.706.63.877.649 1.106.649"
        stroke="#50C9B5"
        strokeWidth={1.063}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.738 20.677s.23-.458.649-.744c.341-.233.992-.229 1.45.134.458.362.676.96.534 1.602-.19.859-.82 1.621-1.526 2.251-.706.63-.878.649-1.107.649"
        stroke="#50C9B5"
        strokeWidth={1.063}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.225 33.849v-2.577H8.972s-1.024 0-1.024-1.134v-3.297H6.82s-.819-.413-.41-1.032c.41-.618 1.844-2.473 1.844-2.473s-.164-5.02 3.69-6.497c3.635-1.394 7.962.115 8.906 4.54.977 4.57-2.969 8.863-2.969 8.863v3.607"
        stroke="#50C9B5"
        strokeWidth={1.063}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.227 31.272H8.974s-1.023 0-1.023-1.134V26.84H6.824s-.82-.413-.41-1.03c.41-.62 1.843-2.474 1.843-2.474s-.164-5.02 3.691-6.498l.087-.028c-1.593-.632-3.453-.628-5.163.028-3.856 1.478-3.692 6.498-3.692 6.498s-1.433 1.855-1.843 2.473c-.409.618.41 1.031.41 1.031h1.126v3.298c0 1.134 1.024 1.134 1.024 1.134h2.254v2.577"
        stroke="#50C9B5"
        strokeWidth={1.063}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={3.157}
        y1={32.319}
        x2={32.258}
        y2={3.233}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#EFF4FA" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);

export { HealthyMinds };
