import React from "react";
import classNames from "classnames";

export type TabProps = {
  children: React.ReactNode;
  className?: string;
};

const Tab: React.FC<TabProps> = ({ children, className }) => {
  return (
    <div
      className={classNames(
        "sm:overflow-auto sm:overflow-hidden h-fit sm:max-h-[360px]",
        className
      )}
    >
      {children}
    </div>
  );
};

export { Tab };
