import {
  AvatarIcon,
  InboxIcon,
  IrishLifeLogoText,
  MenuLink,
  Spinner,
} from "@multiply/lib";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { IrishLifeLogoLeftCorner } from "../IrishLifeLogoLeftCorner";
import { useGetUserJourney } from "../../hooks";
import { HolisticNav } from "./HolisticNav";
import { PensionsNav } from "./PensionsNav";
import { UserJourneyTypes } from "../../globalTypes";

type SidebarProps = {
  className?: string;
};

const Sidebar = ({ className }: SidebarProps) => {
  const userJourney = useGetUserJourney();
  const loading = userJourney?.loading ?? false;
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <nav
          className={classNames(
            "flex lg:flex-col flex-wrap lg:flex-nowrap lg:h-screen lg:overflow-y-auto lg:w-130 text-brand-primary lg:text-white lg:bg-menu-background",
            className
          )}
        >
          <Link
            to="/"
            aria-label="Link to home page"
            className={classNames(
              "bg-white lg:bg-transparent",
              "flex lg:justify-center lg:mt-60 lg:mb-40 h-56 lg:h-auto"
            )}
          >
            <IrishLifeLogoText
              width={105}
              height={34}
              className="hidden lg:block"
            />
            <IrishLifeLogoLeftCorner
              className="lg:hidden"
              width={undefined}
              height={60}
            />
          </Link>

          <section
            className={classNames(
              "bg-white lg:bg-transparent",
              "flex w-full lg:flex-col gap-x-18 gap-y-22 justify-end items-center lg:justify-center",
              "lg:mb-53 pr-18 lg:pr-0",
              "flex-1 lg:flex-none"
            )}
          >
            <MenuLink aria-label="settings" to="/settings">
              <AvatarIcon className="h-auto w-24 lg:w-32" />
              <span>Profile</span>
            </MenuLink>

            <MenuLink to="/inbox" aria-label="inbox">
              <InboxIcon className="h-auto w-24 lg:w-32" />
              <span>Inbox</span>
            </MenuLink>
          </section>

          <section
            className={classNames(
              "bg-transparent lg:bg-transparent",
              "w-full flex lg:flex-col lg:items-center",
              "mt-24 sm:mt-53 lg:my-0 gap-y-22 gap-x-15 sm:gap-x-22 px-max-screen lg:px-0"
            )}
          >
            {userJourney?.value === UserJourneyTypes.FocusedPension ? (
              <PensionsNav />
            ) : (
              <HolisticNav />
            )}
          </section>
        </nav>
      )}
    </>
  );
};

export { Sidebar };
