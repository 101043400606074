import { AccommodationType, WhatToIncludeType } from "../../graphqlTypes";
import { Tooltip } from "@multiply/lib";
import React from "react";

const tooltipsText = (currencySymbol: string) => {
  const result = {
    freeFeeEligible: {
      body: `The total cost for fees each year includes a ${currencySymbol}3,000 student contribution fee and an additional ${currencySymbol}200 for student levies & charges.`,
      source:
        "Source: www.citizensinformation.ie/en/education/third_level_education/fees_and_supports_for_third_level_education/fees.html",
    },
    freeFeeNotEligible: {
      body: `The total cost for fees each year includes ${currencySymbol}20,000 for tuition fees, a ${currencySymbol}3,000 student contribution fee and an additional ${currencySymbol}200 for student levies & charges.`,
      source:
        "Source: www.mastersportal.com/articles/1708/tuition-fees-and-living-costs-for-studying-in-ireland-in-2023.html",
    },
    accommodationRented: {
      body: `The cost for rented accommodation per year is ${currencySymbol}7,691.`,
      source:
        "Source: https://www.zurich.ie/savings-and-investments/education-costs/",
    },
    accommodationStudent: {
      body: `The cost for student accommodation per year is ${currencySymbol}7,518.`,
      source:
        "Source: https://www.zurich.ie/savings-and-investments/education-costs/",
    },
    otherExpensesRentedAccommodation: {
      body: `The cost for other expenses each year includes ${currencySymbol}900 for utility bills, ${currencySymbol}2,328 for food, ${currencySymbol}576 for travel, ${currencySymbol}888 for books/materials, ${currencySymbol}468 for clothes, ${currencySymbol}180 for mobile phone costs and ${currencySymbol}900 for social activities.`,
      source:
        "Source: https://www.tudublin.ie/for-students/student-life/cost-of-living-guide/",
    },
    otherExpensesStudentAccommodation: {
      body: `The cost for other expenses each year includes ${currencySymbol}2,328 for food, ${currencySymbol}576 for travel, ${currencySymbol}888 for books/materials, ${currencySymbol}468 for clothes, ${currencySymbol}180 for mobile phone costs and ${currencySymbol}900 for social activities.`,
      source:
        "Source: https://www.tudublin.ie/for-students/student-life/cost-of-living-guide/",
    },
    otherExpensesHomeAccommodation: {
      body: `The cost for other expenses each year includes ${currencySymbol}576 for travel, ${currencySymbol}888 for books/materials, ${currencySymbol}468 for clothes, ${currencySymbol}180 for mobile phone costs and ${currencySymbol}900 for social activities.`,
      source:
        "Source: https://www.tudublin.ie/for-students/student-life/cost-of-living-guide/",
    },
  } as const;

  return result;
};

const generateTooltipElement = (
  field: keyof ReturnType<typeof tooltipsText>,
  currencySymbol: string
): JSX.Element => {
  return (
    <Tooltip
      className="inline-block"
      aria-label={tooltipsText(currencySymbol)[field].body.concat(
        tooltipsText(currencySymbol)[field].source
      )}
    >
      <>
        <p className="text-t14">{tooltipsText(currencySymbol)[field].body}</p>
        <p className="text-t12 mt-12 ">
          {tooltipsText(currencySymbol)[field].source}
        </p>
      </>
    </Tooltip>
  );
};

const accommodationTooltip = (
  accommodation: AccommodationType | null | undefined,
  currencySymbol: string
): JSX.Element => {
  switch (accommodation) {
    case AccommodationType.Student:
      return generateTooltipElement("accommodationStudent", currencySymbol);
    case AccommodationType.Rented:
      return generateTooltipElement("accommodationRented", currencySymbol);
    default:
      return <></>;
  }
};

const otherExpensesTooltip = (
  accommodation: AccommodationType | null | undefined,
  currencySymbol: string
): JSX.Element => {
  switch (accommodation) {
    case AccommodationType.Student:
      return generateTooltipElement(
        "otherExpensesStudentAccommodation",
        currencySymbol
      );
    case AccommodationType.Rented:
      return generateTooltipElement(
        "otherExpensesRentedAccommodation",
        currencySymbol
      );
    case AccommodationType.Home:
      return generateTooltipElement(
        "otherExpensesHomeAccommodation",
        currencySymbol
      );
    default:
      return <></>;
  }
};

const whatToIncludeLabel = (
  whatToInclude?: (WhatToIncludeType | null)[] | null,
  accommodationType?: AccommodationType | null
) => {
  if (!!whatToInclude) {
    if (
      whatToInclude.includes(WhatToIncludeType.OtherExpenses) &&
      accommodationType !== AccommodationType.Home
    ) {
      return "Fees, accommodation & other expenses";
    } else if (
      whatToInclude.includes(WhatToIncludeType.OtherExpenses) &&
      accommodationType === AccommodationType.Home
    ) {
      return "Fees & other expenses";
    } else if (whatToInclude.includes(WhatToIncludeType.Accommodation)) {
      return "Fees & accommodation";
    } else {
      return "Fees";
    }
  }
};

export {
  generateTooltipElement,
  accommodationTooltip,
  otherExpensesTooltip,
  whatToIncludeLabel,
};
