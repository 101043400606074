import { Link } from "react-router-dom";
import { FactfindFieldValue } from "../../utils";
import { Card } from "@multiply/lib";

export type PensionsReviewCardProps = {
  label: string;
  value: FactfindFieldValue;
  fieldName: string;
  isPrsaFactfind?: boolean;
};

const PensionsReviewCard = ({
  label,
  value,
  fieldName,
  isPrsaFactfind = false,
}: PensionsReviewCardProps) => {
  const convertEnumToLowercaseString = (enumString: string) => {
    return enumString.split("_").join(" ").toLowerCase();
  };

  return (
    <Link
      to={
        isPrsaFactfind
          ? `/pensions/edit-contributions/${fieldName}`
          : `/pensions/edit-details/${fieldName}`
      }
    >
      <Card className="p-18 flex justify-between w-300 gap-10 bg-background-primary hover:bg-background-hover">
        <div className="w-[80%]">
          <p className="text-font-primary">{label}</p>
          {typeof value === "string" ? (
            <p className="text-t12 text-font-secondary capitalize truncate">
              {convertEnumToLowercaseString(value) ?? "Not added"}
            </p>
          ) : Array.isArray(value) ? (
            <p className="text-t12 text-font-secondary">
              {value.length > 0
                ? value.map(({ age }) => age).join(", ")
                : "Not added"}
            </p>
          ) : (
            <p className="text-t12 text-font-secondary">
              {value ?? "Not added"}
            </p>
          )}
        </div>
        <p className="text-font-links text-t12">Edit</p>
      </Card>
    </Link>
  );
};

export { PensionsReviewCard };
