import { SVGProps } from "react";

const CheckboxIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={16}
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m2 9.713 6 5L19.68 2.097"
      stroke="currentColor"
      strokeWidth={4}
      strokeLinecap="round"
    />
  </svg>
);

export { CheckboxIcon };
