import { VictoryPie, VictoryLabel } from "victory";

type CircularProgressGraphProps = {
  percentage: number;
  text?: string;
};

const CircularProgressGraph = ({
  percentage,
  text,
}: CircularProgressGraphProps) => {
  const data = [
    { x: 1, y: percentage },
    { x: 2, y: 100 - percentage },
  ];
  return (
    <svg aria-label="progress chart" viewBox="0 0 200 200" className="">
      <VictoryPie
        standalone={false}
        width={200}
        height={200}
        data={data}
        innerRadius={60}
        cornerRadius={25}
        labels={() => null}
        style={{
          data: {
            fill: ({ datum }) => {
              const color = "#5261AC";
              return datum.x === 1 ? color : "#E8E8E8";
            },
          },
        }}
      />
      <VictoryLabel
        textAnchor="middle"
        verticalAnchor="middle"
        x={100}
        y={100}
        text={text}
        style={{ fontSize: 40, fontWeight: 600 }}
      />
    </svg>
  );
};

export { CircularProgressGraph };
