import { ContainerIndentSize } from "@multiply/lib";
import recommendations_priority from "../../assets/images/recommendations_priority.png";

const RecommendationsPriorityExplanation = () => {
  return (
    <ContainerIndentSize>
      <div className="flex flex-col items-center">
        <div className="w-full h-full">
          <h1 className="text-t21 sm:text-t27 text-font-primary mb-56">
            Your priorities
          </h1>

          <p className="sm:text-t21 text-font-secondary pb-16">
            Our advice engine considers your information and works out the areas
            you need to work on and how much money you can afford over time to
            improve your financial wellbeing.
          </p>
          <p className="sm:text-t21 text-font-secondary pb-24">
            Everyone's financial plan (and the exact amounts and products
            considered) will be different depending on their circumstances, but
            they all follow our advice principles; first, protect yourself and
            your dependants, and then continue to address each priority in turn.
          </p>

          <img
            src={recommendations_priority}
            aria-label="recommendations priority"
            alt="recommendations priority"
            height="auto"
          />

          <h2 className="text-t21 sm:text-t27 text-font-primary pt-48 pb-16">
            Why are the priorities ordered in this way?
          </h2>
          <p className="sm:text-t21 text-font-secondary pb-60">
            We believe protecting yourself and your family is priority number 1
            in planning for the unexpected. We then work out the priority of the
            rest of your needs on a waterfall basis, so we suggest you pay off
            high-interest debt next as the amount can easily pile up and can
            hold you back from becoming more financially resilient and achieving
            your goals.
          </p>

          <h3 className="text-t21 sm:text-t27 text-font-primary pb-16">
            Can I do the actions in a different order?
          </h3>
          <p className="sm:text-t21 text-font-secondary pb-60">
            There's nothing stopping you from ticking off the actions in a
            different order, however we don't advise straying from the
            recommended ordering as this could put you at financial risk
            depending on your circumstances. Everything on your financial plan
            is based on the information you've provided and straying from the
            recommended ordering may result in different outcomes to what your
            plan has set out.
          </p>

          <h4 className="text-t21 sm:text-t27 text-font-primary pb-16">
            What happens if my income or expenses change?
          </h4>
          <p className="sm:text-t21 text-font-secondary pb-60">
            As your plan relies on up-to-date information, you should update
            your profile regularly so that your financial plan can factor in any
            changes to your circumstances. In general, we recommend reviewing
            the information you've entered every few months, however if you move
            jobs or aquire any new assets or debts, you should update at that
            time too.
          </p>
        </div>
      </div>
    </ContainerIndentSize>
  );
};

export { RecommendationsPriorityExplanation };
