import { useAuth } from "@multiply/lib";
import { useState } from "react";
import {
  useGetInteractionFlagQuery,
  useUpdateInteractionFlagMutation,
} from "../../graphqlTypes";

const useInteractionFlag = (flagKey: string) => {
  const { userId, loggedIn } = useAuth();

  const [interactionFlagResult] = useGetInteractionFlagQuery({
    variables: { userId: userId ?? "", flagKey },
    pause: !loggedIn,
  });

  const [optimisticValue, setOptimisticValue] = useState<
    boolean | null | undefined
  >(undefined);
  const serverValue = interactionFlagResult.data?.interactionFlag?.value;

  const [updateInteractionFlagResult, updateIteractionFlag] =
    useUpdateInteractionFlagMutation();

  const update = async (value: boolean) => {
    if (loggedIn) {
      setOptimisticValue(value);
      return updateIteractionFlag({
        input: { userId: userId ?? "", flagKey, value },
      });
    }
  };

  const loading =
    interactionFlagResult.fetching || updateInteractionFlagResult.fetching;

  return {
    value: loading ? optimisticValue : serverValue,
    loading,
    error:
      updateInteractionFlagResult.error ??
      interactionFlagResult.error ??
      updateInteractionFlagResult.data?.updateInteractionFlag?.error,
    update,
  };
};

export { useInteractionFlag };
