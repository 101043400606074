import { useAuth } from "@multiply/lib";
import { useNavigate } from "react-router-dom";
import { InteractionFlags } from "../../globalTypes";
import {
  Factfind,
  MaritalStatus,
  PlanScope,
  useGetAllCollegeCostsQuery,
} from "../../graphqlTypes";
import { useInteractionFlag } from "../../hooks";
import { FactfindPage } from "../FactfindPage";

const BasicInfoFactFind = () => {
  const { userId } = useAuth();
  const navigate = useNavigate();
  const [collegeCostsResult] = useGetAllCollegeCostsQuery({
    variables: {
      userId: userId ?? "",
    },
    pause: !userId,
  });

  const completedBasicFactFind = useInteractionFlag(
    InteractionFlags.CompletedBasicFactFind
  );

  const onDone = async (factfind: Factfind) => {
    if (!completedBasicFactFind.value) {
      await completedBasicFactFind.update(true);
    }

    const showSuggestedChildEducationGoal =
      factfind?.hasChildren && !collegeCostsResult?.data?.collegeCosts?.length;

    if (factfind?.ownsHome === false) {
      navigate("/advice/plan/goals-wizard/");
    } else if (
      factfind?.planningFor === PlanScope.UserAndPartner &&
      factfind?.maritalStatus !== MaritalStatus.Married
    ) {
      navigate("/advice/plan/goals-wizard/wedding");
    } else if (showSuggestedChildEducationGoal) {
      navigate("/advice/plan/goals-wizard/child-education");
    } else {
      navigate("/advice/plan/goals-wizard/add-goal/false");
    }
  };

  return (
    <FactfindPage
      context="factfind_part_1"
      onDone={onDone}
      title="Tell us a bit about yourself..."
      subTitle="You can scroll up and down to edit any of your answers"
    />
  );
};

export { BasicInfoFactFind };
