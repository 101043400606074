import {
  CollegeCostsValues,
  CollegeCostsCalculatorResultDetails,
  InputCollegeCostsForm,
} from "@multiply-ai/college-costs-calculator";
import { useState } from "react";
import {
  useCalculateCollegeCostsMutation,
  CollegeCost,
  WhatToIncludeType,
  useUpdateGoalMutation,
  GoalType,
  GoalFragmentFragment,
} from "../../graphqlTypes";
import { Spinner, useAuth } from "@multiply/lib";

type EmbeddedCollegeCostsCalculatorProps = {
  currencySymbol: string;
  className?: string;
  onComplete: (goal: GoalFragmentFragment | null | undefined) => void;
  goalId: string;
  goalName: string;
};

const EmbeddedCollegeCostsCalculator = ({
  currencySymbol,
  className,
  onComplete,
  goalId,
  goalName,
}: EmbeddedCollegeCostsCalculatorProps) => {
  const { userId } = useAuth();
  const [showResults, setShowResults] = useState<boolean>(false);

  const [updateGoalResults, updateGoal] = useUpdateGoalMutation();

  const [calculateCollegeCostResult, calculateCollegeCosts] =
    useCalculateCollegeCostsMutation();

  const [calculatedCollegeCosts, setCalculatedCollegeCosts] =
    useState<CollegeCost | null>(null);

  const handleCalculate = async (values: CollegeCostsValues) => {
    const result = await calculateCollegeCosts({
      input: {
        userId,
        accommodation: values?.accommodation,
        ageOfChild: values?.ageOfChild,
        courseDuration: values?.courseDuration,
        eligibleForFreeTuitionFees: values?.freeFees,
        whatToInclude: values?.costsInclude,
      },
    });

    if (result?.data?.updateCollegeCosts) {
      setCalculatedCollegeCosts(result?.data?.updateCollegeCosts);
      setShowResults(true);
    }
  };

  const handleMakeChanges = (calculatedCollegeCost: CollegeCost | null) => {
    setCalculatedCollegeCosts(calculatedCollegeCost);
    setShowResults(false);
  };

  const handleStartAgain = () => {
    setCalculatedCollegeCosts(null);
    setShowResults(false);
  };

  const error =
    updateGoalResults?.error?.message ||
    updateGoalResults?.data?.updateGoal?.error?.message ||
    calculateCollegeCostResult?.error?.message;

  const loading = updateGoalResults?.fetching;

  const handleAddGoal = async () => {
    try {
      const result = await updateGoal({
        input: {
          userId: userId ?? "",
          goalId: goalId ?? null,
          goal: {
            goalType: GoalType.ChildEducation,
            name: goalName ?? "Child's education",
            targetDate: calculatedCollegeCosts?.targetDate,
            valueToSave: calculatedCollegeCosts?.total?.float,
          },
          collegeCostId: calculatedCollegeCosts?.id ?? undefined,
        },
      });

      if (result.data?.updateGoal?.success) {
        const goal = result.data.updateGoal.goal;
        onComplete(goal);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={className} id="college-costs-calculator">
      {loading ? (
        <Spinner />
      ) : showResults && calculatedCollegeCosts ? (
        <CollegeCostsCalculatorResultDetails
          collegeCostValues={calculatedCollegeCosts}
          onMakeChanges={() => handleMakeChanges(calculatedCollegeCosts)}
          onStartAgain={handleStartAgain}
          onAddGoal={handleAddGoal}
          savingsGoalText="Update your goal"
          currencySymbol={currencySymbol}
        />
      ) : (
        <div>
          {error && (
            <p className="text-red-500 text-t16 m-12">
              Something went wrong, please try again.
            </p>
          )}
          <InputCollegeCostsForm
            defaultValues={{
              accommodation:
                calculatedCollegeCosts?.accommodationType ?? undefined,
              ageOfChild: calculatedCollegeCosts?.ageOfChild ?? undefined,
              costsInclude:
                calculatedCollegeCosts?.whatToInclude as WhatToIncludeType[],
              courseDuration:
                calculatedCollegeCosts?.courseDuration ?? undefined,
              freeFees:
                calculatedCollegeCosts?.eligibleForFreeTuitionFees ?? undefined,
            }}
            onSubmit={handleCalculate}
          />
        </div>
      )}
    </div>
  );
};

export { EmbeddedCollegeCostsCalculator };
