import classNames from "classnames";
import { StepperTitle } from "./StepperTitle";
import { Current } from "./Current";
import { Completed } from "./Completed";
import { Link } from "react-router-dom";
import { Uncompleted } from "./Uncompleted";

type StepperProps = {
  steps: Array<{ title: string; link: string }>;
  currentStep: number;
  className?: string;
};

const Stepper = ({ steps, currentStep, className }: StepperProps) => {
  return (
    <div className={classNames("w-3/4 sm:w-1/2", className)}>
      <div className="flex items-center gap-2 pb-24 text-t12 sm:text-t16">
        {steps.map((step) => (
          <StepperTitle title={step.title} className="flex-1 text-center" />
        ))}
      </div>
      <div className="w-full flex justify-around items-center">
        {steps.map((step, index) => {
          const stepNumber = index + 1;
          const first = index === 0;
          const last = index === steps.length - 1;
          const backgroundClasses = classNames(
            "h-4 bg-tools-table-outline absolute -z-10",
            {
              "w-full": !first && !last,
              "w-1/2": first || last,
              "right-0": first,
              "left-0": last,
            }
          );

          return stepNumber === currentStep ? (
            <div
              key={index}
              className="flex-1 flex items-center justify-center relative"
            >
              <div className={backgroundClasses} />
              <Current currentStep={stepNumber} />
            </div>
          ) : index < currentStep ? (
            <Link
              key={index}
              to={step.link}
              className="flex-1 flex items-center justify-center relative"
            >
              <div className={backgroundClasses} />
              <Completed />
            </Link>
          ) : (
            <div
              key={index}
              className="flex-1 flex items-center justify-center relative"
            >
              <div className={backgroundClasses} />
              <Uncompleted step={stepNumber} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { Stepper };
