import { ButtonLink, Card } from "@multiply/lib";

const PensionsChangeDetails = () => {
  return (
    <Card variant="flat" className="w-full flex flex-col pb-40">
      <h1 className="text-t20 sm:text-t27 text-font-primary text-center mt-32">
        Changing your basic details
      </h1>
      <h2 className="text-t18 text-font-secondary text-center mt-16">
        You’ll need to go back to your financial plan to update these details.
      </h2>
      <div className="self-center">
        <ButtonLink to="/settings/personal-details" className="mt-32" size="sm">
          Go to update my details
        </ButtonLink>
      </div>
    </Card>
  );
};

export { PensionsChangeDetails };
