import { SVGProps } from "react";

const MinorInjury = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
    {...props}
  >
    <path
      d="M18 36c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18Z"
      fill="url(#a)"
    />
    <path
      clipRule="evenodd"
      d="M22.57 20.262h-2.845v-2.846a.68.68 0 0 0-.68-.68h-2.238a.68.68 0 0 0-.68.68v2.846h-2.845a.68.68 0 0 0-.68.678v2.24c0 .375.303.68.68.68h2.845v2.844c0 .376.305.681.68.681h2.239a.68.68 0 0 0 .68-.681V23.86h2.844a.682.682 0 0 0 .681-.68v-2.24a.68.68 0 0 0-.681-.678Z"
      stroke="#FFCF30"
      strokeWidth={0.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.177 33.12c6.461 0 11.7-5.238 11.7-11.7 0-6.462-5.239-11.7-11.7-11.7-6.462 0-11.7 5.239-11.7 11.7 0 6.462 5.238 11.7 11.7 11.7Z"
      stroke="#FFCF30"
      strokeWidth={0.799}
    />
    <defs>
      <linearGradient
        id="a"
        x1={18}
        y1={29.211}
        x2={18}
        y2={-1.601}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#EFF4FA" />
      </linearGradient>
    </defs>
  </svg>
);

export { MinorInjury };
