import { DebtType } from "../graphqlTypes";

const getDebtNameFromType = (type: DebtType | null | undefined) => {
  switch (type) {
    case DebtType.CreditCard:
      return "Credit Card";
    case DebtType.Loan:
      return "Loan";
    case DebtType.CarLoan:
      return "Car Loan";
    case DebtType.Mortgage:
      return "Mortgage";
    case DebtType.HirePurchaseAgreement:
      return "Hire Purchase Agreement";
    case DebtType.PersonalLoan:
      return "Personal Loan";
    case DebtType.OtherDebt:
      return "Other";
    case undefined:
    case null:
      return "Unknown";
    default:
      const unreachable: never = type;
      return unreachable;
  }
};
export { getDebtNameFromType };
