import { RefObject, useEffect, useState } from "react";
import { FieldError, FieldValues, UseFormGetFieldState } from "react-hook-form";
import { QuestionGroup } from "../../graphqlTypes";
import get from "lodash/get";

const scrollToField = (element: HTMLElement, fieldName?: string) => {
  let input: HTMLElement | null = null;
  if (fieldName) {
    input = element?.querySelector(`[name="${fieldName}"]`);
  }
  if (!input) {
    input =
      element?.querySelector("input") ??
      element?.querySelector("button") ??
      element?.querySelector("[role=slider]") ??
      element?.querySelector("[role=group]");
  }

  if (input) {
    input.focus({ preventScroll: true });
  }

  element?.scrollIntoView({
    behavior: "smooth",
    block: "center",
  });
};

const useScrollToNextField = ({
  submitRef,
  cardRefs,
  syncedWithApiStatus,
  getFieldState,
  questionGroups,
}: {
  submitRef: RefObject<HTMLElement>;
  cardRefs: RefObject<Map<string, HTMLElement>>;
  syncedWithApiStatus: "WAITING" | "UPDATING" | "UPDATED";
  getFieldState: UseFormGetFieldState<FieldValues>;
  questionGroups: QuestionGroup[];
}) => {
  const [lastSubmittedField, setLastSubmittedField] = useState<
    string | undefined
  >();
  const [lastSubmittedElement, setLastSubmittedElement] = useState<
    HTMLElement | undefined
  >();

  /**
   * onSubmit should trigger the next field to scroll into view
   */
  const handleSubmitField = (field: string) => {
    setTimeout(() => {
      const fieldState = getFieldState(field);

      // If field is part of array can we move to the next field in the array?
      if (!fieldState.invalid || fieldState.error?.type === "server") {
        setLastSubmittedField(field);

        if (document.activeElement instanceof HTMLInputElement) {
          setLastSubmittedElement(document.activeElement as HTMLElement);
        } else {
          setLastSubmittedElement(undefined);
        }
      }
    });
  };

  /**
   * Scroll to first validation error on submit
   */
  const handleSubmitError = (validationErrors: any) => {
    setTimeout(() => {
      questionGroups.every((questionGroup) => {
        const error = get(validationErrors, questionGroup.id) as FieldError;
        if (error) {
          const card = cardRefs.current?.get(questionGroup.id);
          if (card) {
            scrollToField(card);
            return false;
          }
        }

        return questionGroup.questions.every((question) => {
          const error = get(validationErrors, question.key) as FieldError;
          if (error) {
            const card = cardRefs.current?.get(questionGroup.id);
            if (card) {
              scrollToField(card, question.key);
              return false;
            }
          }
          return true;
        });
      });
    });
  };

  /**
   * if we have a submitted field and we are not waiting for question groups to update
   * then scroll to the next field, only scroll if:
   *   - there is a submitted field
   *   - the focussed element has not changed since the last submit
   */
  useEffect(() => {
    if (
      syncedWithApiStatus === "UPDATED" &&
      lastSubmittedField &&
      (lastSubmittedElement === undefined ||
        lastSubmittedElement === document.activeElement)
    ) {
      const allFields = questionGroups.flatMap(
        (questionGroup) => questionGroup.questions
      );
      const currentFieldIndex = allFields.findIndex(
        (question) => question.key === lastSubmittedField
      );
      const nextField = allFields[currentFieldIndex + 1];

      if (nextField) {
        questionGroups.every((questionGroup) =>
          questionGroup.questions.every((question) => {
            if (nextField.key === question.key) {
              const card = cardRefs.current?.get(questionGroup.id);
              if (card) {
                scrollToField(card, question.key);
                return false;
              }
            }
            return true;
          })
        );
      } else if (submitRef.current) {
        scrollToField(submitRef.current);
      }

      setLastSubmittedField(undefined);
      setLastSubmittedElement(undefined);
    }
  }, [
    lastSubmittedElement,
    lastSubmittedField,
    syncedWithApiStatus,
    questionGroups,
    submitRef,
    cardRefs,
  ]);

  return {
    handleSubmitField,
    handleSubmitError,
  };
};

export { useScrollToNextField };
