import { SVGProps, FC } from "react";
import { defaultClassnames } from "./defaultClassNames";
import classNames from "classnames";

const CircledPlusIcon: FC<SVGProps<SVGSVGElement>> = ({
  className,
  ...props
}) => {
  return (
    <svg
      aria-label={props["aria-label"] ?? "add icon"}
      width={25}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(defaultClassnames, className)}
      {...props}
    >
      <rect width={24.914} height={24.159} rx={12.079} fill="#5261AC" />
      <path
        stroke="#fff"
        strokeWidth={1.887}
        strokeLinecap="round"
        d="M8.871 11.892h7.173M12.646 8.494v7.173"
      />
    </svg>
  );
};

export { CircledPlusIcon };
