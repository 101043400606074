import { SVGProps } from "react";

export type Size = "xsm" | "sm" | "md" | "lg" | "xl" | "xxl" | "xxxl";
type IconVariant = "primary" | "secondary" | "grey" | "icon" | "green" | "white";

export type IconProps = {
  size?: Size;
  variant?: IconVariant;
} & SVGProps<SVGSVGElement>;

const getDimensionsFromSize = (size?: Size) => {
  const dimensions = {
    xsm: { width: 12, height: 12 },
    sm: { width: 16, height: 16 },
    md: { width: 24, height: 24 },
    lg: { width: 32, height: 32 },
    xl: { width: 48, height: 48 },
    xxl: { width: 88, height: 88 },
    xxxl: { width: 93, height: 93 },
  };

  return size ? dimensions[size] : dimensions["md"];
};

const getColorFromVariant = (variant?: IconVariant) => {
  const colors = {
    primary: "#3B3160",
    secondary: "#615A80",
    grey: "#615A80",
    icon: "#E1E5FF",
    green: "#55CBB7",
    white: "#FFFFFF",
  };

  return variant ? colors[variant] : "currentColor";
};

export { getDimensionsFromSize, getColorFromVariant };
