import { useAuth } from "@multiply/lib";
import { CheckInType, useCheckInQuery } from "../../graphqlTypes";

const useGetCheckIns = (checkInType?: CheckInType) => {
  const { userId, loggedIn } = useAuth();

  const [result] = useCheckInQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const staleCheckIns = result.data
    ? result.data?.checkIn?.checkIns?.filter((checkIn) => checkIn.stale)
    : null;
  const lastCheckIn =
    staleCheckIns && staleCheckIns?.length === 1 ? true : false;

  let checkInRequired = false;
  if (checkInType) {
    checkInRequired =
      staleCheckIns &&
      staleCheckIns.find((checkIn) => checkIn.checkInType === checkInType)
        ? true
        : false;
  }

  return {
    error: result.error,
    fetching: result.fetching,
    checkIns: result.data?.checkIn?.checkIns,
    staleCheckIns,
    checkInRequired,
    lastCheckIn,
  };
};

export { useGetCheckIns };
