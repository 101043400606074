import { PersonFragmentFragment } from "../graphqlTypes";

const getIntroPathFromPerson = (person?: PersonFragmentFragment | null) => {
  const nameDobComplete = Boolean(
    person?.firstName && person?.lastName && person?.dateOfBirth && person?.sex
  );

  const addressComplete = Boolean(person?.address);

  const phoneNumberComplete = Boolean(person?.phoneNumber);

  if (!nameDobComplete) {
    return "/name";
  } else if (!addressComplete) {
    return "/address";
  } else if (!phoneNumberComplete) {
    return "/phone";
  }

  return null;
};

export { getIntroPathFromPerson };
