import { useNavigate } from "react-router-dom";
import { PensionsPageTemplate } from "../../components";
import { PrsaFactfindPage } from "../PrsaFactfindPage";

const EditContributionAmount = () => {
  const navigate = useNavigate();

  const onDone = async () => {
    navigate("/pensions/start-application");
  };

  const context = `pension_self_contribution_amount`;

  return (
    <PensionsPageTemplate>
      <PrsaFactfindPage context={context} onDone={onDone} cta="Continue" />
    </PensionsPageTemplate>
  );
};

export { EditContributionAmount };
