import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

const useNavigateWithState = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (url: string, search?: {}) =>
    navigate(
      {
        pathname: url,
        search: createSearchParams(search).toString(),
      },
      { state: location.state }
    );
};

export { useNavigateWithState };
