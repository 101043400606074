import { useAuth } from "@multiply/lib";
import { Link } from "react-router-dom";
import { ProductCard } from "../../components";
import { useGetPoliciesQuery } from "../../graphqlTypes";
import { getPolicyNameFromType } from "../../utils";
import { EmptyProductCard } from "./EmptyProductCard";
import { ProductSection } from "./ProductSection";

const PoliciesSection = () => {
  const { userId } = useAuth();

  const [policiesResult] = useGetPoliciesQuery({
    variables: { userId: userId ?? "" },
  });
  const policies = policiesResult?.data?.policies ?? [];

  return (
    <div className="flex flex-col">
      <ProductSection
        loading={policiesResult.fetching}
        title="Policies"
        subtitle="Insurance or Health plans etc..."
      >
        {policies.length === 0 ? (
          <EmptyProductCard to="add/policies" aria-label="add policies" />
        ) : (
          policies.map((policy) => {
            if (policy) {
              return (
                <ProductCard
                  to={`add/policy/${policy.id}`}
                  key={policy.id}
                  title={getPolicyNameFromType(policy.productType)}
                  subtitle={
                    policy.isComplete
                      ? policy?.name ?? policy?.provider?.name ?? ""
                      : "Add details"
                  }
                  balance={null}
                />
              );
            }
            return null;
          })
        )}
      </ProductSection>

      {policies.length > 0 && (
        <Link to="add/policy" className="text-t16 text-font-links mt-24">
          Add another policy
        </Link>
      )}
    </div>
  );
};

export { PoliciesSection };
