import { Button } from "@multiply/lib";
import { GetAssetQuery } from "../../graphqlTypes";
import { useAssetForm } from "../../hooks";
import { getAssetNameFromType } from "../../utils";
import { AssetField } from "./AssetField";
import { getAssetFieldsFromType } from "./getAssetFieldsFromType";

type AssetFormProps = {
  asset: NonNullable<GetAssetQuery["asset"]>;
  onSubmit: (values: any) => Promise<void>;
  defaultValues: any;
};

const AssetForm = ({ asset, onSubmit, defaultValues }: AssetFormProps) => {
  const fields = getAssetFieldsFromType(asset?.productType);
  const name = getAssetNameFromType(asset?.productType);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useAssetForm({ fields, defaultValues });

  return (
    <form className="flex flex-col items-center w-full">
      <h1 className="text-t27 self-start mb-60 text-font-primary">{name}</h1>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-x-32 gap-y-32 self-stretch">
        {fields.map((field) => (
          <AssetField
            key={typeof field === "string" ? field : field.fieldName}
            field={field}
            control={control}
          />
        ))}
      </div>

      <Button
        className="mt-96 w-240"
        onClick={handleSubmit(onSubmit)}
        disabled={isSubmitting || !isValid}
      >
        Save
      </Button>
    </form>
  );
};

export { AssetForm };
