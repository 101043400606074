import classNames from "classnames";
import { Link } from "react-router-dom";

type EmptyProductCardProps = {
  to: string;
  "aria-label": string;
};

const EmptyProductCard = ({ to, ...props }: EmptyProductCardProps) => {
  type EmptyProps = {
    className?: string;
  };

  const Empty = ({ className, ...props }: EmptyProps) => (
    <div
      className={classNames(
        className,
        "bg-state-empty rounded-md w-full h-full"
      )}
    />
  );

  return (
    <Link to={to} aria-label={props["aria-label"]}>
      <div
        className={classNames(
          "bg-white inline-flex p-16 sm:p-32 hover:bg-background-hover w-full sm:w-420"
        )}
      >
        <div className="w-40 h-40 sm:w-48 sm:h-48 flex items-center justify-center shrink-0">
          <div className="w-40 h-40 sm:w-48 sm:h-48 flex items-center justify-center shrink-0">
            <div className="relative bg-action-primary rounded-lg flex items-center justify-center w-full h-full">
              <div className="absolute right-[10] bg-white w-4 h-20 rounded"></div>
              <div className="absolute top-[10] bg-white w-20 h-4 rounded-full"></div>
            </div>
          </div>
        </div>

        <div className="flex flex-col ml-16 mr-40 flex-1 overflow-hidden">
          <Empty className="h-16 sm:h-24" />

          <Empty className="w-60 mt-8 h-12 sm:h-16" />
        </div>

        <Empty className="w-40 sm:w-80 h-16 sm:h-24 shrink-0" />
      </div>
    </Link>
  );
};

export { EmptyProductCard };
