import {
  Card,
  ContainerMidSize,
  ContainerSmallSize,
  SectionHeading,
  Spinner,
  useAuth,
} from "@multiply/lib";
import {
  BookAdviserCall,
  IrishLifeDisclaimerText,
  PlanStatusCard,
  RecommendationCarousel,
  YourPensions,
  YouToldUs,
} from "../../components";
import {
  AuthorisationStatus,
  RecommendationType,
  RetirementSummary,
  useGetPlanRecommendationsQuery,
} from "../../graphqlTypes";
import { ThisMeansPensions } from "./ThisMeansPensions";
import { PensionCalculatorCard } from "./PensionCalculatorCard";

enum CTAType {
  PRSA = "Open a pension",
  AdviceCall = "Speak to an adviser",
  SmartInvest = "Get started with Smart Invest",
  TalkToEmployer = "Talk to your employer",
}

const PensionAdvice = () => {
  const { userId, loggedIn } = useAuth();

  const [planResult] = useGetPlanRecommendationsQuery({
    variables: { userId: userId ?? "" },
    pause: !loggedIn,
  });

  const recommendationGroups =
    planResult?.data?.plan?.recommendationGroups ?? [];

  const retirementRecommendation = recommendationGroups.find(
    (group) => group.recommendationType === RecommendationType.Retirement
  );

  const summary = retirementRecommendation?.summary as
    | RetirementSummary
    | null
    | undefined;

  const notApproved =
    planResult?.data?.plan?.authorisationStatus !==
    AuthorisationStatus.AdviceApproved;

  const errorGenerated = planResult?.error;

  const getCTATypes = () => {
    const ctasTypes: string[] = [];
    const recommendations = retirementRecommendation?.recommendations ?? null;

    if (recommendations) {
      const ctas = recommendations[0].ctas;

      ctas.forEach((cta) => {
        switch (cta.text) {
          case CTAType.PRSA:
            ctasTypes.push(CTAType.PRSA);
            break;
          case CTAType.AdviceCall:
            ctasTypes.push(CTAType.AdviceCall);
            break;
          case CTAType.SmartInvest:
            ctasTypes.push(CTAType.SmartInvest);
            break;
          case CTAType.TalkToEmployer:
            ctasTypes.push(CTAType.TalkToEmployer);
            break;
        }
      });
    }
    return ctasTypes;
  };

  const ctas = getCTATypes();

  return (
    <div className="w-full flex justify-center mt-20">
      {planResult.fetching ? (
        <ContainerSmallSize>
          <div className="mt-36">
            <Card className="py-80 px-60 text-center">
              <h1 className="text-brand-primary text-t27 mb-24">
                Generating your pension advice
              </h1>

              <p className="text-font-secondary text-t16 sm:text-t20 mb-48">
                This may take a few moments
              </p>

              <Spinner className="w-60 h-60 my-32" data-testid="spinner" />
            </Card>
          </div>
        </ContainerSmallSize>
      ) : errorGenerated || notApproved ? (
        <PlanStatusCard
          planResult={planResult.data?.plan}
          error={planResult?.error?.message || null}
        />
      ) : (
        <ContainerMidSize className="sm:mt-80 space-y-20 sm:space-y-60">
          <SectionHeading title="Your pension projection" />

          {summary && (
            <ThisMeansPensions
              currentProvision={summary?.currentProvision}
              realisticProvision={summary?.realisticProvision}
              targetProvision={summary?.targetProvision}
              breakdown={summary?.breakdown}
            />
          )}

          {!ctas.includes(CTAType.PRSA) && <PensionCalculatorCard />}

          {(retirementRecommendation?.recommendations?.length ?? 0) > 0 &&
            retirementRecommendation?.recommendations && (
              <RecommendationCarousel
                recommendations={retirementRecommendation?.recommendations}
                title="Recommendations"
              />
            )}

          {/* {ctas.includes(CTAType.PRSA) && <PensionCalculatorCard />} */}

          <section className="grid grid-cols-1 sm:grid-cols-2 gap-y-20 sm:gap-x-60">
            <YouToldUs
              text={retirementRecommendation?.factfindSummary}
              title="You told us"
            />

            <YourPensions title="Your pensions" />
          </section>

          <BookAdviserCall hasPlan={true} />
          <p className="text-font-primary text-t16 ring-2 ring-font-primary px-32 py-24 mb-14">
            <span className="font-semibold">Warning: </span>These figures are
            estimates only. They are not a reliable guide to the future
            performance of your investment.
          </p>
          <IrishLifeDisclaimerText className="pb-60" />
        </ContainerMidSize>
      )}
    </div>
  );
};

export { PensionAdvice };
