import isEqual from "lodash/isEqual";
import { ValuationMethod } from "../../graphqlTypes";
import { Dropdown, Tooltip } from "@multiply/lib";

type InputValuationMethodTypes = {
  label?: React.ReactNode;
  tooltip?: React.ReactNode;
  value: ValuationMethod | null;
  onChange: (value: ValuationMethod | null) => void;
  onBlur: () => void;
  error?: string;
};

const InputValuationMethod = ({
  label,
  tooltip,
  value,
  onChange,
  onBlur,
  error,
}: InputValuationMethodTypes) => {
  const toItem = (valuationMethod: ValuationMethod) => {
    switch (valuationMethod) {
      case ValuationMethod.EstimatedCurrentValue:
        return {
          value: ValuationMethod.EstimatedCurrentValue,
          label: "Estimated Current Value",
        };
      case ValuationMethod.EstimatedValueAtRetirement:
        return {
          value: ValuationMethod.EstimatedValueAtRetirement,
          label: "Estimated Value at Retirement",
        };
      case ValuationMethod.MonthlyIncomeAtRetirement:
        return {
          value: ValuationMethod.MonthlyIncomeAtRetirement,
          label: "Monthly Income at Retirement",
        };

      default:
        const unreachable: never = valuationMethod;
        return unreachable;
    }
  };

  const items = Object.values(ValuationMethod).map(toItem);

  const selectedValue =
    items.find((item) => isEqual(item.value, value)) ?? null;

  const combinedLabel = (
    <div className="flex items-center">
      {label ? label : "Valuation method"}
      {tooltip ? (
        <span>
          <Tooltip className="inline-block">{tooltip}</Tooltip>
        </span>
      ) : (
        ""
      )}
    </div>
  );

  return (
    <Dropdown
      aria-label="dropdown to see list of valuation methods"
      error={error}
      label={combinedLabel}
      items={items}
      placeholder="Select valuation method"
      value={selectedValue}
      onChange={(item: any) => {
        onChange(item?.value ?? null);
      }}
      onBlur={onBlur}
    />
  );
};

export { InputValuationMethod };
